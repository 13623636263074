
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import {
  IoIosRefresh,
} from "react-icons/io";

import { unarchiveDevice } from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import Private from '../../../components/Private';
import { getDevice } from '../selectors';

function UnarchiveDeviceModal({
  device,
  unarchiveDevice,
  onSuccess,
  onError,
  ...props
}) {

  const onClick = useCallback(async () => {
    try {
      await unarchiveDevice(device);
      if (onSuccess) {
        onSuccess(device);
      }
    }
    catch(e) {
      if (onError) {
        onError(device);
      }
    }
  }, [unarchiveDevice, device]);

  return (
    <Private minUserType="Super Admin">
      <ConfirmModal
        confirmText="Unarchive"
        {...props}
      >
        <Button onClick={onClick} variant="danger">
          <IoIosRefresh size="1.5em" /> Unarchive
        </Button>
      </ConfirmModal>
    </Private>
  );
}

const mapStateToProps = (state, { deviceId } ) => ({
  device: getDevice(state, deviceId),
});
const mapDispatchToProps = { unarchiveDevice };

export default connect(mapStateToProps, mapDispatchToProps)(UnarchiveDeviceModal);
