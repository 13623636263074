
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, { match }) => {

  // get vars from path location
  const organisationPathType = match && match.params && match.params['editType'];
  const organisationPathString = match && match.params && match.params['id'];
  const organisationPathId = parseInt(organisationPathString) || undefined;

  return {
    // note that the organisation id's existence/authorisation isn't validated
    organisationId: organisationPathId,
    organisationIsNew: organisationPathType === 'new',
  };
};

export default compose(
  // pass router props to redux to make "match" available
  withRouter,
  connect(mapStateToProps),
);
