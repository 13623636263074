import createReducer from '../../lib/createReducer';
import * as ACTION_TYPES from './types/ActionTypes';
import * as USER_ACTION_TYPES from '../user/types/ActionTypes';
import {
  upsertListItems,
  upsertObjectItems,
  upsertObjectItem,
} from '../../lib/reducerUtils';
import { GatewayStoreState, GatewayResponse, Gateway } from './types';

export const DEFAULT_STATE: GatewayStoreState = {
  loading: false, // the list of gateways is being loaded
  lastFetch: null, // the last time we did a fetch
  error: null, // any error loading the list

  // list of gateways in list
  gateways: [],
  // gateways keyed by the API id, for appending relations to
  gatewaysById: {},
};

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),

  [ACTION_TYPES.REQUEST_GATEWAY_LIST](state: GatewayStoreState): GatewayStoreState {
    return {
      ...state,
      loading: true
    };
  },
  [ACTION_TYPES.GATEWAY_LIST_FAILURE](state: GatewayStoreState, payload: { response: string }): GatewayStoreState {
    const { response } = payload;
    return {
      ...state,
      loading: false,
      error: (response ? response : "Unknown error")
    };
  },
  [ACTION_TYPES.RECEIVE_GATEWAY_LIST](state: GatewayStoreState, payload: { response: GatewayResponse }) {
    const { response } = payload;
    const gateways = response && response._embedded && response._embedded.gateways;

    return {
      ...state,
      // update the gateways list
      gateways: upsertListItems(state.gateways, gateways),
      // update gateway objects by merging list into the gateway lookup object
      // (this object may have more gateways than the gateways list)
      gatewaysById: upsertObjectItems(state.gatewaysById, gateways),
      loading: false,
      error: null,
      lastFetch: new Date(),
    };
  },

  [ACTION_TYPES.REQUEST_GATEWAY](state: GatewayStoreState, payload: { gateway: Pick<Gateway, "id">}) {
    const { gateway } = payload;
    return {
      ...state,
      gatewaysById: upsertObjectItem(state.gatewaysById, {
        ...gateway,
        _state_: {
          loading: true,
        }
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_GATEWAY](state: GatewayStoreState, payload: { gateway: Pick<Gateway, "id">, response: Gateway }) {
    const { response } = payload;
    return {
      ...state,
      gatewaysById: upsertObjectItem(state.gatewaysById, {
        ...response,
        _state_: {
          loading: false,
          lastFetch: Date.now(),
          error: null,
        }
      }),
    };
  },

  [ACTION_TYPES.GATEWAY_FAILURE](state: GatewayStoreState, payload: { gateway: Pick<Gateway, "id">, response: string }) {
    const { gateway, response } = payload;
    return {
      ...state,
      gatewaysById: upsertObjectItem(state.gatewaysById, {
        ...gateway,
        _state_: {
          loading: false,
          error: response,
        },
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_UPDATE_GATEWAY](state: GatewayStoreState, payload: { id: number, gateway: Partial<Gateway> }) {
    const { id, gateway } = payload;
    return {
      ...state,
      gatewaysById: upsertObjectItem(state.gatewaysById, {
        ...gateway,
        id,
        _state_: {
          loading: false,
          lastFetch: Date.now(),
        },
      }),
    };
  },
});
