import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosAddCircleOutline, IoIosCheckbox } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { useColumnsWithVisibility } from '../../../components/table/utils';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

import {
  groupName,
} from '../columns';

import {
  userName,
  userEmail,
} from '../../user/columns';
import {
  equipmentName,
  siteName,
  subAreaName,
} from '../../equipment/columns';

import Table, {
  renderPaginationSize,
  renderPaginationBar,
} from '../../../components/Table';

import { checkboxColumns as accessGroupColumns } from './EditGroupAccessGroups';
import { checkboxColumns as accessUserColumns } from './EditGroupAccessUsers';

import { hasAdminRightsInActiveGroup } from '../../user/selectors';

import './groupAccess.scss';

function itemHasPermissionOf(permission='') {
  const permissionLowerCase = permission.toLowerCase();
  return (value, { rights=[] }={}) => {
    return rights.includes(permissionLowerCase);
  };
}
function createCheckboxFormatter(permission='') {
  const itemHasPermission = itemHasPermissionOf(permission);
  return (...args) => {
    return itemHasPermission(...args) ? <IoIosCheckbox size="1.1em"/> : null;
  };
}

function transformColumns({ text, ...column }) {
  const permission = text.split(' ')[0];
  return {
    ...column,
    text: permission,
    formatter: createCheckboxFormatter(permission),
    headerFormatter: null,
    sort: true,
    sortValue: itemHasPermissionOf(permission),
  };
}

const accessGroupPermissionColumns = accessGroupColumns.map(transformColumns);
const accessUserPermissionColumns = accessUserColumns.map(transformColumns);

const pagination = {
  sizePerPage: 5,
};

function hasItemLength(list) {
  return list && list.length;
}

function TableCard({ loading, title, link, userCanEdit, children }) {
  const { t } = useTranslation();
  // check if table will render any rows
  const list = children &&
    children.props &&
    children.props.data;

  return (
    <Card className="shadow-sm">
      <Card.Header className="d-block">
        <Row className="small-gutters">
          <Col className="d-flex justify-content-between align-items-start">
            <h2 className="mt-1">{title}</h2>
          </Col>
          <Col xs="auto">
            {link && list ? (
              <LinkContainer to={link}>
                <Button className="mb-0 align-self-start">
                  {userCanEdit ? hasItemLength(list) ? t('Edit') : (
                    <>
                      <IoIosAddCircleOutline size="1.4em" /> {t('common.add')}
                    </>
                  ) : t('view-all')}
                </Button>
              </LinkContainer>
            ) : t('loading') ? (
              <div className="my-1">
                <LoadingSpinner size={1.85} />
              </div>
            ) : null}
          </Col>
        </Row>
      </Card.Header>
      <ListGroup className="list-group-flush">
        {children}
      </ListGroup>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    // userCanRead: hasReadRightsInActiveGroup(state),
    userCanEdit: hasAdminRightsInActiveGroup(state),
  };
};

const ConnectedTableCard = connect(mapStateToProps)(TableCard);

const renderPaginationFooter = ({ paginationProps, setPaginationSizePerPage }) => (
  <Row className="p-1 small-gutters">
    <Col xs="auto">
      {renderPaginationSize({ paginationProps, setPaginationSizePerPage })}
    </Col>
    <Col xs="auto" className="react-bootstrap-table2-pagination-align-right">
      {renderPaginationBar({ paginationProps })}
    </Col>
  </Row>
);

export function GroupAccessTableCard({ loading, groups }) {
  const { t } = useTranslation();
  const localisedColumns = useLocalisedColumns([
    groupName,
    ...accessGroupPermissionColumns,
  ]);
  return (
    <ConnectedTableCard
      title={t('components.organisations.group-access.group-access-table-card.title')}
      link="/group/access/groups"
    >
      <Table
        paginationOptions={pagination}
        data={groups}
        defaultSorted={[{ dataField: groupName.dataField, order: 'asc' }]}
        columns={localisedColumns}
        noDataIndication={() => {
          return t('components.organisations.group-access.group-access-table-card.no-data');
        }}
        loading={loading}
        headerWrapperClasses={!hasItemLength(groups) ? 'd-none' : undefined}
        renderFooter={hasItemLength(groups) > pagination.sizePerPage
          ? renderPaginationFooter
          : null}
      />
    </ConnectedTableCard>
  );
}

export function UserAccessTableCard({ loading, users, isOrganisationGroup }) {
  const { t } = useTranslation();
  const visibleColumns = useColumnsWithVisibility([
    userName,
    userEmail,
    ...accessUserPermissionColumns,
  ], {
    // remove 'login' access on normal group, it only applies to orgs
    '_meta_.login': isOrganisationGroup,
  });
  const localisedColumns = useLocalisedColumns(visibleColumns);

  return (
    <ConnectedTableCard
      title={t('components.organisations.group-access.user-access-table-card.title')}
      link="/group/access/users"
      loading={loading}
    >
      <Table
        paginationOptions={pagination}
        data={users}
        defaultSorted={[{ dataField: userName.dataField, order: 'asc' }]}
        columns={localisedColumns}
        noDataIndication={() => {
          return t('components.organisations.group-access.user-access-table-card.no-data');
        }}
        loading={loading}
        headerWrapperClasses={!hasItemLength(users) ? 'd-none' : undefined}
        renderFooter={hasItemLength(users) > pagination.sizePerPage
          ? renderPaginationFooter
          : null}
      />
    </ConnectedTableCard>
  );
}

export function UserMembersTableCard({ loading, users }) {
  const { t } = useTranslation();
  const localisedColumns = useLocalisedColumns([
    userName,
    userEmail,
  ]);
  return (
    <ConnectedTableCard
      title={t('components.organisations.group-access.user-members-table-card.title')}
      link="/group/users"
      loading={loading}
    >
      <Table
        paginationOptions={pagination}
        data={users}
        defaultSorted={[{ dataField: userName.dataField, order: 'asc' }]}
        columns={localisedColumns}
        noDataIndication={() => {
          return t('components.organisations.group-access.user-members-table-card.no-data');
        }}
        loading={loading}
        headerWrapperClasses={!hasItemLength(users) ? 'd-none' : undefined}
        renderFooter={hasItemLength(users) > pagination.sizePerPage
          ? renderPaginationFooter
          : null}
      />
    </ConnectedTableCard>
  );
}

export function DeviceMembersTableCard({ loading, devices }) {
  const { t } = useTranslation();
  const localisedColumns = useLocalisedColumns([
    equipmentName,
    siteName,
    subAreaName,
  ]);
  return (
    <ConnectedTableCard
      title={t('components.organisations.group-access.device-members-table-card.title')}
      link="/group/devices"
      loading={loading}
    >
      <Table
        paginationOptions={pagination}
        data={devices}
        defaultSorted={[{ dataField: equipmentName.dataField, order: 'asc' }]}
        columns={localisedColumns}
        noDataIndication={() => t('components.organisations.group-access.device-members-table-card.no-data')}
        loading={loading}
        headerWrapperClasses={!hasItemLength(devices) ? 'd-none' : undefined}
        renderFooter={hasItemLength(devices) > pagination.sizePerPage
          ? renderPaginationFooter
          : null}
      />
    </ConnectedTableCard>
  );
}
