import React, { useState } from 'react';

import Header from '../components/Header.js';
import Sidebar from '../components/Sidebar.js';
import Footer from '../components/Footer.js';

import './root.scss';
import AccountStatus from './AccountStatus.js';
import useSidebar from '../modules/app/hooks/useSidebar.js';

export default function Root({ userIsLoggedIn, header=true, children }) {
  const userIsLoggedInClassName = userIsLoggedIn ? 'user-is-logged-in' : '';

  const { expanded, toggleExpanded, closeExpanded } = useSidebar();

  const expandedClassName = 'sidebar-' + (expanded === true ? 'expanded' : expanded === false ? 'collapsed' : 'default');

  // allow multiple AccountStatus components to be dismissed at once
  const [showAccountStatus, setShowAccountStatus] = useState(true);

  return (
    <div id="app" className={[userIsLoggedInClassName, expandedClassName].filter(Boolean).join(' ')}>
      <div id="sidebar">
        <AccountStatus show={showAccountStatus} floating />
        <Sidebar expanded={expanded} closeExpanded={closeExpanded} />
      </div>
      <div id="main" style={{marginLeft: userIsLoggedIn && expanded ? 'var(--sidebar-width)' : 0, transition: 'var(--body-transition)'}}>
        {/* add fixed and inline version of account status */}
        <AccountStatus show={showAccountStatus} setShow={setShowAccountStatus}/>
        <AccountStatus show={showAccountStatus} floating />
        <div id="header">
          {header && <Header expanded={expanded} toggleExpanded={toggleExpanded} />}
        </div>
        <div id="body">
          {children}
        </div>
        <div id="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
