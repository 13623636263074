import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosAddCircleOutline, IoIosRadio } from 'react-icons/io';
import { Container, Button, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import IoIosMotor from '../../../components/IoIosMotor';

import { fetchDevices } from '../actions';

import Private from '../../../components/Private';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import { headerFormatter } from '../../../components/table/formatters';
import EmptyCard from '../../../components/EmptyCard';

import AddFitMachineModal from '../components/AddFitmachineModal';

import {
  siteName,
  subAreaName,
  equipmentName,
  running,
  type,
  serial,
  organisationName,
  onboardedName,
  fmLastHeard,
  fmLastHeardHoursWarning,
  fmLastHeardHoursDanger,
  batteryScore,
  wifiScore,
} from '../columns';

import { getDevices, getDeviceListState } from '../selectors';
import EquipmentDropdown from '../components/EquipmentDropdown';
import { getActiveSubGroupId } from '../../organisation/selectors';
import { ApiRequestCanceller } from '../../../lib/utils';
import useIncludeSubGroup from '../../../hooks/useIncludeSubGroup';
import { SENSOR_TYPE } from '../constants';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

function AddDevicesButton() {
  const { t } = useTranslation();
  return (
    <AddFitMachineModal key="add">
      <Button variant="primary" className="pl-1">
        <IoIosRadio className="mr-1" size="1.4em" /> <span>{t('screens.equipment.devices-admin.add-device')}</span>
      </Button>
    </AddFitMachineModal>
  );
}

function AddButton() {
  const { t } = useTranslation();
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="pl-1">
        <IoIosAddCircleOutline size="1.4em" /> {t('common.add')}{' '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <AddFitMachineModal key="add">
          <Dropdown.Item>
            <IoIosRadio size="1.4em" /> <span>{t('screens.equipment.devices-admin.add-device')}</span>
          </Dropdown.Item>
        </AddFitMachineModal>
        <Private minUserType="Admin">
          <LinkContainer to="/group/devices">
            <Dropdown.Item >
              <IoIosMotor size="1.4em" /> <span>{t('common.manage-group-equipment')}</span>
            </Dropdown.Item>
          </LinkContainer>
        </Private>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function NoDataIndication({ hasActiveSubGroup }) {
  const { t } = useTranslation();
  return !hasActiveSubGroup ? (
    // admin on org group
    <EmptyCard Icon={IoIosRadio}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          {t('screens.equipment.devices-admin.no-data.started-adding-first-heading')}
        </EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.devices-admin.no-data.started-adding-first-body')}
        </EmptyCard.Text>
        <div className="text-center">
          <AddDevicesButton />
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  ) : (
    // admin on sub group
    <EmptyCard Icon={IoIosRadio}>
      <EmptyCard.Body>
        <EmptyCard.Title>{t('common.started-adding-group-heading')}</EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.devices-admin.no-data.started-adding-group-body')}
        </EmptyCard.Text>
        <div className="text-center">
          <LinkContainer to="/group/devices">
            <Button variant="primary">
              {t('common.manage-group-equipment')}
            </Button>
          </LinkContainer>
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

export const DeviceAdminTableToolbar = connect(getDeviceListState)(props => {
  const { loading, lastFetch, error, activeSubGroupId, tableProps, onToggleIncludeChildren, includeChildren } = props;
  const { t } = useTranslation();
  return (
    <TableToolbar
      searchable
      title={t('screens.equipment.devices-admin.title')}
      loading={loading}
      lastFetch={lastFetch}
      error={error}
      buttonGroups={[
        [<AddButton key="add" />],
        !activeSubGroupId && [
          <LinkContainer key="active" to="/devices/admin" exact>
            <Button variant="outline-secondary">{t('active')}</Button>
          </LinkContainer>,
          <LinkContainer key="archived" to="/devices/admin/archived" exact>
            <Button variant="outline-secondary">{t('archived')}</Button>
          </LinkContainer>,
        ],
        activeSubGroupId && [
          <Button
            key={2}
            variant={includeChildren ? 'secondary' : 'outline-secondary'}
            onClick={onToggleIncludeChildren}
          >
            {!includeChildren ? t('common.show'): t('common.hide') } {t('screens.equipment.devices-admin.subgroup-sensors') }
          </Button>
        ]
      ]}
      tableProps={tableProps}
    />
  );
});

export const renderDeviceAdminTimeColours = (value) => {
  if (moment(value).add({ hours: fmLastHeardHoursWarning }).isAfter()) {
    return {
      backgroundColor: '#81c784'
    };
  } else if (moment(value).add({ hours: fmLastHeardHoursDanger }).isAfter()) {
    return {
      backgroundColor: '#fff176'
    };
  } else {
    return {
      backgroundColor: '#e57373'
    };
  }
};

// Render different background color for battery and wifi based on what condition score(0-4) they have.
export const renderConditionScoreColours = (value) => {
  if (value <= 1) {
    return {
      backgroundColor: '#e57373'
    };
  } else if (value >= 2 && value <= 3 ) {
    return {
      backgroundColor: '#fff176'
    };
  } else if (value >= 4) {
    return {
      backgroundColor: '#81c784'
    };
  }
};

export const defaultSorted = [{
  dataField: 'equipment_name',
  order: 'asc'
}];

const actions = {
  dataField: 'actions',
  text: 'Actions',
  headerFormatter,
  formatter: (value, device={}) => (
    <EquipmentDropdown deviceId={device.id} />
  ),
  events: {
    onClick: (e) => e.stopPropagation()
  }
};

export const columns = [
  siteName,
  subAreaName,
  equipmentName,
  type,
  serial,
  organisationName,
  onboardedName,
  // add styles to columns
  {
    ...fmLastHeard,
    style: renderDeviceAdminTimeColours,
  },
  batteryScore,
  wifiScore,
  running,
  actions,
];

function DevicesAdmin({ loading, devices=[], activeSubGroupId, fetchDevices }) {

  const dispatch = useDispatch();

  const { includeSubGroup, setIncludeSubGroup } = useIncludeSubGroup('devices_admin');

  // fetch devices on mount
  useEffect(() => {
    const canceller = new ApiRequestCanceller();
    fetchDevices({ includeChildren: includeSubGroup, sensorType: SENSOR_TYPE.ALL }, canceller);
    return () => {
      dispatch(canceller.cancel());
    };
  }, [activeSubGroupId, fetchDevices, includeSubGroup]);

  // memoise callback
  const renderHeader = useCallback(props => {
    return (
      <DeviceAdminTableToolbar
        activeSubGroupId={activeSubGroupId}
        tableProps={props}
        includeChildren={includeSubGroup}
        onToggleIncludeChildren={() => setIncludeSubGroup(!includeSubGroup)}
      />
    );
  }, [activeSubGroupId, includeSubGroup]);

  const noDataIndication = useCallback(() => {
    return (
      <NoDataIndication hasActiveSubGroup={!!activeSubGroupId}/>
    );
  }, [activeSubGroupId]);

  const history = useHistory();
  const localisedColumns = useLocalisedColumns(columns);
  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        data={devices}
        defaultSorted={defaultSorted}
        loading={loading}
        noDataIndication={noDataIndication}
        columns={localisedColumns}
        rowEvents = {{
          onClick: (e, equipment) => {
            if(window.getSelection().toString().length > 0) return;
            // when returned in relations device id may be keyed under device_id
            history.push(`/devices/${equipment.device_id || equipment.id}`);
          }
        }}
        rowStyle={{
          cursor: 'pointer'
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => ({
  activeSubGroupId: getActiveSubGroupId(state),
  devices: getDevices(state),
  loading: getDeviceListState(state).loading,
});
const mapDispatchToProps = { fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(DevicesAdmin);
