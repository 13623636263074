import { usePoll } from "../../../hooks/usePoll";
import useGroup from "./useGroup";
import { useGroupReportData } from './useGroupReportData';
import { getIntervalMilliSeconds } from "../../../components/lib/utils";

const { REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES = 5 } = process.env;
const checkPeriods = getIntervalMilliSeconds(REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES);

export function useGroupMonitoringTrend() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'monitoringtrend'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}

export function useGroupVibrationOrder() {
  const { group } = useGroup();
  const { reportData, fetchData } =
    useGroupReportData(group, {type: 'measurementsummary', filter: 'recent', limit: 'top5', order: 'vibration'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}

export function useGroupTemperatureOrder() {
  const { group } = useGroup();
  const { reportData, fetchData } =
    useGroupReportData(group, {type: 'measurementsummary', filter: 'recent', limit: 'top5', order: 'temperature'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}

export function useGroupNonReporting() {
  const { group } = useGroup();
  const { reportData, fetchData } =
    useGroupReportData(group, {type: 'status', filter: 'nonreporting', limit: 'top5'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}