import { Dispatch } from 'redux';
import { CALL_API } from '../../lib/apiMiddleware';
import * as ACTION_TYPES from './types/ActionTypes';
import * as BillingAction from './types/Action';

export function getPlanRequest(): BillingAction.GetPlanRequestAction {
  return {
    type: ACTION_TYPES.GET_PLAN_REQUEST
  };
}

export function getPlanSuccess(): BillingAction.GetPlanSuccessAction {
  return {
    type: ACTION_TYPES.GET_PLAN_SUCCESS
  };
}

export function getPlanFailure(): BillingAction.GetPlanFailureAction {
  return {
    type: ACTION_TYPES.GET_PLAN_FAILURE
  };
}

export function fetchPlan(id: number): (dispatch: Dispatch<BillingAction.FetchPlanAction>) => BillingAction.FetchPlanAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `groups/${id}/billing/plan`,
      requestAction: getPlanRequest(),
      successAction: getPlanSuccess(),
      errorAction: getPlanFailure(),
    });
  };
}

export function getUsageRequest(): BillingAction.GetUsageRequestAction {
  return {
    type: ACTION_TYPES.GET_USAGE_REQUEST
  };
}

export function getUsageSuccess(): BillingAction.GetUsageSuccessAction {
  return {
    type: ACTION_TYPES.GET_USAGE_SUCCESS
  };
}

export function getUsageFailure(): BillingAction.GetUsageFailureAction {
  return {
    type: ACTION_TYPES.GET_USAGE_FAILURE
  };
}

export function fetchUsage(id: number): (dispatch: Dispatch<BillingAction.FetchUsageAction>) => BillingAction.FetchUsageAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `groups/${id}/billing/usage`,
      requestAction: getUsageRequest(),
      successAction: getUsageSuccess(),
      errorAction: getUsageFailure(),
    });
  };
}

export function getTransactionsRequest(): BillingAction.GetTransactionsRequestAction {
  return {
    type: ACTION_TYPES.GET_TRANSACTIONS_REQUEST
  };
}

export function getTransactionsSuccess(): BillingAction.GetTransactionsSuccessAction {
  return {
    type: ACTION_TYPES.GET_TRANSACTIONS_SUCCESS
  };
}

export function getTransactionsFailure(): BillingAction.GetTransactionsFailureAction {
  return {
    type: ACTION_TYPES.GET_TRANSACTIONS_FAILURE
  };
}

export function fetchTransactions(id: number): (dispatch: Dispatch<BillingAction.FetchTransactionsAction>) => BillingAction.FetchTransactionsAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `groups/${id}/billing/transactions`,
      requestAction: getTransactionsRequest(),
      successAction: getTransactionsSuccess(),
      errorAction: getTransactionsFailure(),
    });
  };
}

export function getInvoicesRequest(): BillingAction.GetInvoicesRequestAction {
  return {
    type: ACTION_TYPES.GET_INVOICES_REQUEST
  };
}

export function getInvoicesSuccess(): BillingAction.GetInvoicesSuccessAction {
  return {
    type: ACTION_TYPES.GET_INVOICES_SUCCESS
  };
}

export function getInvoicesFailure(): BillingAction.GetInvoicesFailureAction {
  return {
    type: ACTION_TYPES.GET_INVOICES_FAILURE
  };
}

export function fetchInvoices(id: number): (dispatch: Dispatch<BillingAction.FetchInvoicesAction>) => BillingAction.FetchInvoicesAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `groups/${id}/billing/invoices`,
      requestAction: getInvoicesRequest(),
      successAction: getInvoicesSuccess(),
      errorAction: getInvoicesFailure(),
    });
  };
}