
import React, { Component } from 'react';
import { connect } from 'react-redux';
import objectFitImages from 'object-fit-images';
import withNavigationGatewayProps from './withNavigationGatewayProps';
import { IoIosWifi } from 'react-icons/io';

// Components
import NotFound from '../../../components/NotFound';

// Other
import moment from 'moment';

import { capitaliseFirstChar } from '../../../lib/utils';

import Carousel, { Modal, ModalGateway } from 'react-images';

import ProfilePageLayout from '../../../components/ProfilePageLayout';
import GatewayNav from './GatewayNav';
import GatewaySidebar from './GatewaySidebar';

import { fetchDeviceImages } from '../../equipment/actions';
import { fetchGateways } from '../actions';
import LoadingSpinner from '../../../components/LoadingSpinner';

import {
  getGateway,
  getGatewayConnections,
  getGatewayListState,
  getGatewayPlaceholderImage,
} from '../selectors';

function getSortedImages(images=[]) {
  // try to find the latest image by name type
  const imageTypeOrder = [
    'gateway_images', // react dash uses gateway_images :(
    'gateway_image', // ruby dash uses gateway_image :(
  ];
  return [...images].filter(({ name }) => {
    // filter to relevant images
    return imageTypeOrder.includes(name);
  }).sort((a, b) => {
    // find image name type order
    const aIndex = imageTypeOrder.indexOf(a.name);
    const bIndex = imageTypeOrder.indexOf(b.name);

    // if image name type is the same then prioritise latest updated image
    if (aIndex === bIndex) {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }
    else {
      // de-prioritise unfound name
      if (aIndex === -1) {
        return 1;
      }
      // de-prioritise unfound name
      else if (bIndex === -1) {
        return -1;
      }
      // sort by imageTypeOrder
      else {
        return aIndex - bIndex;
      }
    }
  });
};

class DevicePageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImagesModalOpen: false
    };

    this.fetchDeviceImages = this.fetchDeviceImages.bind(this);
  }

  fetchDeviceImages() {
    const { connections=[], fetchDeviceImages } = this.props;
    // fetch info and images of connected devices
    if (connections.length > 0) {
      // fetch images from max 3 devices
      // as too many requests (~100) will freeze the UI
      connections.slice(0, 2).forEach(([, device]) => {
        fetchDeviceImages(device);
      });
    }
  }

  componentDidMount() {
    this.fetchDeviceImages();

    // if the cloud devices list has not been yet loaded
    // then try to fetch a list of other devices
    const { gatewaysFetched, fetchGateways } = this.props;
    if (!gatewaysFetched) {
      fetchGateways();
    }
    objectFitImages('img.img-thumbnail');
  }

  componentDidUpdate(prevProps) {
    const { connections: prevConnections = [] } = prevProps;
    const { connections: nextConnections = [] } = this.props;
    if (
      // compare id list change (as the array prop changes on every update)
      prevConnections.map(([device]) => device.id).join(',') !==
      nextConnections.map(([device]) => device.id).join(',')
    ) {
      this.fetchDeviceImages();
    }
    // update device profile image on IE if the images change
    if ((this.props.device || {}).images !== (prevProps.device || {}).images) {
      objectFitImages('img.img-thumbnail');
    }
  }

  toggleImagesModal = e => {
    e.preventDefault();
    this.setState(({ isImagesModalOpen }) => ({ isImagesModalOpen: !isImagesModalOpen }));
  };

  render() {
    const { gatewaysFetched, connections=[], gateway={}, children } = this.props;

    // show loading spinner if the gateway isn't found
    // but also the gateways list hasn't been fetched yet
    // (only show NotFound if there is a list, and this gateway isn't in it)
    if (!gateway.id && !gatewaysFetched) {
      return (
        <div className="my-4 mx-auto">
          <LoadingSpinner />
        </div>
      );
    }

    // ensure that the device actually contains information in its props
    // if the images are loaded first, device will be { images: [...] }
    // without any other props
    if (!gateway || !gateway.id) {
      // Does not exist
      return <NotFound />;
    }

    // get images of the connected devices
    const connectedDeviceImages = connections.reduce((acc, [, device]) => {
      if (device.images) {
        return [...acc, ...device.images];
      }
      return acc;
    }, []);

    const sortedImages = getSortedImages(connectedDeviceImages);
    const isMaybeOurGateway = sortedImages && sortedImages.length > 0;

    return (
      <ProfilePageLayout
        image={(
          <div
            // add button accessibility props
            role="button"
            tabIndex="0"
            onKeyDown={e => e.key === 'i' && this.toggleImagesModal(e)}
            onClick={this.toggleImagesModal}
          >
            {isMaybeOurGateway ? (
              // has a gateway photo
              <img
                className="img-thumbnail img-fluid shadow-sm object-fit-cover"
                // always show placeholder
                // (we can't be sure of the gateway images being correct)
                src={this.props.placeholder}
                onError={e => e.target.src = this.props.placeholder}
                alt="Overall Location"
              />
            ) : (
              // has not gateway photo (maybe customer wifi)
              <IoIosWifi className="img-thumbnail" />
            )}
          </div>
        )}
        navbar={<GatewayNav />}
        sidebar={<GatewaySidebar />}
      >
        {children}
        <ModalGateway>
          {sortedImages && sortedImages.length && this.state.isImagesModalOpen ? (
            <Modal
              closeOnBackdropClick={false}
              onClose={this.toggleImagesModal}
              allowFullscreen={true}
            >
              <Carousel
                hideControlsWhenIdle={false}
                views={sortedImages.map(({ url, name, updated_at }) => ({
                  source: url,
                  // remove underscores and trailing s chars from name, for a caption
                  caption: `Possible gateway match image. Uploaded: ${
                    moment(updated_at).format('ll h:mm a')
                  }, Type: ${
                    capitaliseFirstChar(name.replace(/(_|s$)/g, ' '))
                  }`,
                }))}
                styles={carouselStyles}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </ProfilePageLayout>
    );
  }
}

const navigationStyle = (base, { isFullscreen }) => isFullscreen ? {
  ...base,
  // make fullscreen navigation buttons very obvious
  boxShadow: '0 0 6px -2px rgba(0, 0, 0, 7)',
  background: 'rgba(180, 180, 180, 0.6)',
  '&:active': {
    background: 'rgba(180, 180, 180, 0.7)',
  },
  '&:hover': {
    background: 'rgba(180, 180, 180, 0.8)',
  },
} : {
  ...base,
  // make non-fullscreen navigation buttons more obvious
  background: 'rgba(255, 255, 255, 0.3)',
};

const carouselStyles = {
  navigationPrev: navigationStyle,
  navigationNext: navigationStyle,
  header: base => ({ ...base, opacity: 1 }),
  footer: (base, { isFullscreen }) => isFullscreen ? {
    ...base,
    textShadow: '0px 1px 6px #000',
    color: '#fff',
    opacity: 1,
  } : {
    ...base,
    opacity: 1,
  },
};

const mapStateToProps = (state, { gatewayId }) => {
  return {
    placeholder: getGatewayPlaceholderImage(state, gatewayId),
    gatewaysFetched: (getGatewayListState(state) || {}).lastFetch,
    gateway: getGateway(state, gatewayId),
    connections: getGatewayConnections(state, gatewayId),
  };
};
const mapDispatchToProps = {
  fetchGateways,
  fetchDeviceImages,
};

export default withNavigationGatewayProps(
  connect(mapStateToProps, mapDispatchToProps)(DevicePageLayout)
);
