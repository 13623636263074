
import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  IoIosCreate,
} from "react-icons/io";

// Other
import Private from '../../../components/Private';
import DeviceContextMenu from './DeviceContextMenu';

export default function EquipmentDropdown({ deviceId }) {

  const { t } = useTranslation();
  // skip if device has not yet been loaded
  if (!deviceId) {
    return null;
  }

  return (
    <ButtonGroup>
      <Private minUserType="Admin">
        <LinkContainer to={`/devices/${deviceId}/edit`}>
          <Button size="md" variant="outline-secondary">
            <IoIosCreate size="1.2em" /> <span>{t('components.equipment-dropdown.edit')}</span>
          </Button>
        </LinkContainer>
      </Private>
      <DeviceContextMenu deviceId={deviceId} />
    </ButtonGroup>
  );
}
