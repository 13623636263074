import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import PageLayout from '../components/PageLayout';
import { fetchPlan } from '../actions';
import { getPlanState, getPlan, getIsEmptyPlan, getCurrentBalance } from '../selectors';
import { formatNumber } from '../../../components/lib/utils';
import { AppStoreState } from '../../types';
import { StateProps as PlanStateProps, PlanResponse as BillingPlan } from '../types';
import { FetchPlanAction } from '../types/Action';
import { getActiveGroupId, getRootGroupId } from '../../organisation/selectors';
import NoDataIndication from '../components/NoDataIndication';
import LoadingSpinner from '../../../components/LoadingSpinner';
interface StateProps {
  planState: PlanStateProps;
  plan: BillingPlan;
  isEmptyPlan: boolean;
  activeGroupId?: number;
  rootGroupId?: number;
  currentBalance: number;
}

interface DispatchProps {
  fetchPlan: (id: number) => (dispatch: Dispatch<FetchPlanAction>) => FetchPlanAction;
}

const mapStateToProps = (state: AppStoreState): StateProps => {
  return {
    planState: getPlanState(state),
    plan: getPlan(state),
    isEmptyPlan: getIsEmptyPlan(state),
    activeGroupId: getActiveGroupId(state),
    rootGroupId: getRootGroupId(state),
    currentBalance: getCurrentBalance(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchPlan,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {};

function Plan({
  planState,
  plan,
  isEmptyPlan,
  fetchPlan,
  activeGroupId,
  rootGroupId,
  currentBalance,
}: Props) {

  const { t } = useTranslation();

  useEffect(() => {
    if(activeGroupId) {
      fetchPlan(activeGroupId);
    } else if(rootGroupId) {
      fetchPlan(rootGroupId);
    }
  }, [activeGroupId, rootGroupId]);

  return (
    <PageLayout
      title={t('screens.billing.plan.title')}
      loading={planState.loading}
      searchProps={{
        hide: true
      }}
      showRowCount={false}
    >
      {planState.loading && isEmptyPlan ?
        <div className="d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div> :
        isEmptyPlan ?
          <NoDataIndication title={t('screens.billing.plan.no-data')} /> :
          <>
            <h5 className="py-2 text-capitalize">{plan.plan.name}</h5>
            <div className="my-4"></div>
            <Row>
              <Col sm={12} md={5} xl={3} className="pr-4">
                <div dangerouslySetInnerHTML={{__html: plan.plan.details}}></div>
                {plan.value_adds && <>
                  {plan.value_adds.map((value, index) => (
                    <div key={index}>
                      <div className="font-weight-bold">{value.name}</div>
                      <div dangerouslySetInnerHTML={{ __html: value.details || '' }}></div>
                    </div>
                  ))}
                </>}
              </Col>
              <Col md={7} xl={9}>
                <h5>{t('screens.billing.plan.account-balance')}</h5>
                <div style={{marginBottom: '2rem'}}>
                  <h2
                    className={`d-inline ${currentBalance < 0 ? 'color-movus-green' : ''}`}
                  >{formatNumber(Math.abs(currentBalance))}
                  </h2>&nbsp;
                  <p className="d-inline">{currentBalance < 0 ?
                    t('screens.billing.plan.hours-in-credit') :
                    t('screens.billing.plan.hours-to-be-invoiced')
                  }
                  </p>
                </div>
                <h5>{t('screens.billing.plan.hours-for-period')}</h5>
                <div style={{marginBottom: '2rem'}}>
                  <h2 className="d-inline">{formatNumber(plan.current_usage || 0)}</h2>&nbsp;
                  <p
                    className="d-inline"
                  >{t('screens.billing.plan.hours-estimated')} {formatNumber(plan.estimated_usage || 0)}
                  </p>
                </div>
                <p>{t('screens.billing.plan.invoiced-date', {
                  date: moment(plan.billing_period?.end).format('DD MMM YYYY')
                }) }</p>
              </Col>
            </Row>
          </>}
    </PageLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
