import createReducer from '../../lib/createReducer';
import moment from 'moment';

import * as USER_ACTION_TYPES from '../user/types/ActionTypes';
import * as DEVICE_ACTION_TYPES from '../equipment/types/ActionTypes';
import * as ACTION_TYPES from './types/ActionTypes';
import {
  upsertListItems,
  upsertObjectItem,
  upsertObjectItems,
} from '../../lib/reducerUtils';
import { sortAlarmsDefault } from './selectors';
import {
  AlarmStoreState,
  AlarmResponse,
  Alarm,
  EventResponse,
  AlarmReasons,
  ImageResponse,
  ImpactResponse,
  Impact,
  ImpactSummaryResponse,
} from './types';

export const DEFAULT_STATE: AlarmStoreState = {
  alarms: [],
  alarmsById: {},
  childAlarms: {},
  events: [],
  loading: false,
  lastFetch: null,
  error: null,
  showTags: [],
};

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.REQUEST_BEGIN](state: AlarmStoreState) {
    return {
      ...state,
      loading: true,
    };
  },

  [ACTION_TYPES.REQUEST_FAIL](state: AlarmStoreState, { response }: {response?: string}) {
    return {
      ...state,
      loading: false,
      error: (response ? response : "Unknown error"),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARMS_LIST](state: AlarmStoreState, { response, alarmType, view }: { response: AlarmResponse, alarmType: string, view: string }) {

    const alarms = (response && response._embedded && response._embedded.alarms) || [];
    const showTags = response?._config?.show_tags || [];
    if(view === 'event') {
      return {
        ...state,
        events: upsertListItems(state.events, alarms),
        alarmsById: upsertObjectItems(state.alarmsById, alarms),
        loading: false,
        error: null,
        lastFetch: moment(),
        showTags,
      };
    }

    return {
      ...state,
      // update the alarms list (which is currently limited to 500 most recent)
      alarms: (upsertListItems(state.alarms, alarms, {
        // filter out old versions of only the same type of alarm
        // (preserve other types of this alarm)
        filter: alarmType !== 'all'
          ? () => ({ alarm_source }: Record<string, any>) => alarm_source !== alarmType
          // or by default, clear out all previous alarms
          : undefined,
      }) as Alarm[]).sort(sortAlarmsDefault),
      // update alarm objects by merging list into the alarm lookup object
      // (this object may have more alarms than the alarms list)
      alarmsById: upsertObjectItems(state.alarmsById, alarms),
      loading: false,
      error: null,
      lastFetch: moment(),
      showTags,
    };
  },

  // add device alarm lists into the alarm store
  [DEVICE_ACTION_TYPES.RECEIVE_DEVICE_ALARMS](state: AlarmStoreState, { response }: { response: AlarmResponse }) {
    const alarms = response && response._embedded && response._embedded.alarms;
    return {
      ...state,
      alarmsById: upsertObjectItems(state.alarmsById, alarms || []),
    };
  },

  // Add device event lists into alarm store.
  [DEVICE_ACTION_TYPES.RECEIVE_DEVICE_ALARM_EVENTS](state: AlarmStoreState, { response }: { response: AlarmResponse }) {
    const alarms = response && response._embedded && response._embedded.alarms;
    return {
      ...state,
      alarmsById: upsertObjectItems(state.alarmsById, alarms || []),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM](state: AlarmStoreState, { response }: { response: Alarm } ) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, response),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM_EVENTS](state: AlarmStoreState, { alarm, response }: { alarm: Pick<Alarm, "id">, response: EventResponse }) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: response._embedded,
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM_IMAGES](state: AlarmStoreState, { alarm, response }: { alarm: Pick<Alarm, "id">, response: ImageResponse}) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: response._embedded,
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM_SOUNDS](state: AlarmStoreState, { alarm, response }: { alarm: Pick<Alarm, "id">, response: ImageResponse}) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: response._embedded,
      }),
    };
  },

  [ACTION_TYPES.REQUEST_ALARM_VALID_REASONS](state: AlarmStoreState) {
    return {
      ...state,
      alarmValidReasonOptions: {
        loading: true,
        error: false,
        items: state.alarmValidReasonOptions &&
          state.alarmValidReasonOptions.items,
      },
    };
  },
  [ACTION_TYPES.RECEIVE_ALARM_VALID_REASONS](state: AlarmStoreState, { response }: { response: AlarmReasons }) {
    return {
      ...state,
      alarmValidReasonOptions: {
        loading: false,
        error: false,
        items: response._embedded.items,
      },
    };
  },
  [ACTION_TYPES.ALARM_VALID_REASONS_FAILURE](state: AlarmStoreState, { error }: { error: any }) {
    return {
      ...state,
      alarmValidReasonOptions: {
        loading: false,
        error,
        items: state.alarmValidReasonOptions &&
          state.alarmValidReasonOptions.items,
      },
    };
  },

  [ACTION_TYPES.REQUEST_ALARM_INVALID_REASONS](state: AlarmStoreState) {
    return {
      ...state,
      alarmInvalidReasonOptions: {
        loading: true,
        error: false,
        items: state.alarmInvalidReasonOptions &&
          state.alarmInvalidReasonOptions.items,
      },
    };
  },
  [ACTION_TYPES.RECEIVE_ALARM_INVALID_REASONS](state: AlarmStoreState, { response }: { response: AlarmReasons }) {
    return {
      ...state,
      alarmInvalidReasonOptions: {
        loading: false,
        error: false,
        items: response._embedded.items,
      },
    };
  },
  [ACTION_TYPES.ALARM_INVALID_REASONS_FAILURE](state: AlarmStoreState, { error }: { error: any }) {
    return {
      ...state,
      alarmInvalidReasonOptions: {
        loading: false,
        error,
        items: state.alarmInvalidReasonOptions &&
          state.alarmInvalidReasonOptions.items,
      },
    };
  },

  [ACTION_TYPES.RECEIVE_ALARM_DELETE](state: AlarmStoreState, { alarm }: {alarm: Pick<Alarm, 'id'>}) {
    delete state.alarmsById[alarm.id];
    return {
      ...state,
      alarms: state.alarms.filter(a => a.id !== alarm.id),
      alarmsById: { ...state.alarmsById }
    };
  },

  [ACTION_TYPES.REQUEST_ALARM_IMPACT](state: AlarmStoreState, { alarm }: { alarm: Pick<Alarm, 'id'>}) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: {
          impact: {
            _state_: {
              loading: true,
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_ALARM_IMPACT](state: AlarmStoreState, { alarm, response }: { alarm: Pick<Alarm, 'id'>, response: ImpactResponse }) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: {
          impact: {
            items: response._embedded?.items || [],
            _state_: {
              loading: false,
              lastFetch: Date.now(),
              error: null,
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.ALARM_IMPACT_FAILURE](state: AlarmStoreState, { alarm, response = '' }: { alarm: Pick<Alarm, 'id'>, response: string}) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: {
          impact: {
            _state_: {
              loading: false,
              error: response,
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_ADD_ALARM_IMPACT](state: AlarmStoreState, { alarm }: { alarm: Pick<Alarm, 'id'> }) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: {
          impact: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_ALARM_IMPACT](state: AlarmStoreState, { alarm }: { alarm: Pick<Alarm, 'id'> }) {
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: {
          impact: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_DELETE_ALARM_IMPACT](state: AlarmStoreState, { alarm, impact }: {alarm: Pick<Alarm, 'id'>, impact: Pick<Impact, 'id'>}) {
    const newImpactData = state.alarmsById[alarm.id]?._embedded?.impact?.items?.filter(item => item.id !== impact.id);
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: {
          impact: {
            items: newImpactData,
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_ALARM_IMPACT_SUMMARY](state: AlarmStoreState, { alarm, response }: { alarm: Pick<Alarm, 'id'>, response: ImpactSummaryResponse }) {
    delete response?._links;
    return {
      ...state,
      alarmsById: upsertObjectItem(state.alarmsById, {
        id: alarm.id,
        _embedded: {
          impact: {
            summary: response,
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }),
    };
  },
  [ACTION_TYPES.REQUEST_CHILD_ALARMS](state: AlarmStoreState, { alarmId }: { alarmId: number }) {
    return {
      ...state,
      childAlarms: {
        ...state.childAlarms,
        [alarmId]: {
          _state_: {
            loading: true,
          },
          items: state.childAlarms[alarmId]?.items || []
        },
      },
    };
  },
  [ACTION_TYPES.CHILD_ALARMS_FAILURE](state: AlarmStoreState, { alarmId, response = ''}: { alarmId: number, response?: string }) {
    return {
      ...state,
      childAlarms: {
        ...state.childAlarms,
        [alarmId]: {
          _state_: {
            loading: false,
            error: response,
          },
          items: state.childAlarms[alarmId]?.items || []
        },
      },
    };
  },
  [ACTION_TYPES.RECEIVE_CHILD_ALARMS](state: AlarmStoreState, { alarmId, response }: { alarmId: number, response: AlarmResponse }) {
    return {
      ...state,
      childAlarms: {
        ...state.childAlarms,
        [alarmId]: {
          _state_: {
            loading: false,
            error: null,
            lastFetch: Date.now(),
          },
          items: response._embedded?.alarms || []
        },
      },
    };
  }
});
