
import React, { Fragment, useState, useCallback } from 'react';

import { Form } from 'react-bootstrap';

import './fileUpload.scss';
import { escapeSpecialCharacters } from '../lib/utils';

export default function FileUpload(props) {

  const {
    getUploadS3FileUrlFromFile,
    uploadS3File,
    onSuccess,
    Container = Fragment,
    context,
    ...rest
  } = props;

  const [state, setState] = useState({
    touched: null,
    status: null,
    valid: null,
    invalid: null,
  });

  const onChange = useCallback(e => {
    const files = e.target.files;
    const originalFile = files && files.item && files.item(0);
    // exit if no new file is set
    if (!originalFile) {
      setState({ touched: true, warning: null, error: null });
      return null;
    }
    const file = new File([originalFile], escapeSpecialCharacters(originalFile.name), { type: originalFile.type });
    // todo, wrap each in their own promise, with their own state
    setState({
      touched: true,
      status: 'Checking...',
      valid: null,
      invalid: null,
    });

    return getUploadS3FileUrlFromFile(file, context)
      // get the S3 pre-signed url from the platform
      .then(response => {
        if (!response || !response.data || typeof response.data !== 'object') {
          throw new Error('Could not find image upload details');
        }
        return typeof response.data.message === 'string'
          ? JSON.parse(response.data.message)
          : response.data;
      })
      // use the pre-signed url to make a file upload request
      .then(({ url, fields }) => {

        // create body as a FormData object
        // as FormData will use an automatic multi-part upload request
        // when given a file to upload
        const body = new FormData();

        // add all required fields given from S3
        Object.entries(fields).forEach(([key, value]) => {
          body.append(key, value);
        });

        // append the file
        body.append('file', file);

        setState({
          touched: true,
          status: 'Uploading...',
          valid: null,
          invalid: null,
        });

        // todo: do a CORS request when there are CORS headers available from the S3 bucket
        // this is part of Jira task PD-568 and DASH-457
        return fetch(url, { method: 'POST', mode: 'no-cors', body })
          // set state
          .then(response => {
            if (response.ok) {
              setState({
                touched: true,
                status: null,
                valid: 'Uploaded',
                invalid: null,
              });
              return response;
            }
            else {
              throw new Error(response.statusText
                ? `Status: ${response.statusText}`
                : 'No response');
            }
          })
          // call success hook
          .then(async response => {

            // get known headers
            // note: the following is done for maximum IE compatibility,
            // there is no great cross-browser way to reduce all available
            // response headers into one object
            const headers = {
              location: response.headers.get('location'),
              etag: response.headers.get('etag'),
            };

            await onSuccess({ key: fields.key, headers, response });
            setState({
              touched: true,
              status: null,
              valid: null,
              invalid: null,
            });
            return response;
          })
          // fetch error
          // note: while the S3 bucket may not be returning CORS headers
          // we return the context of the upload for checking using the request
          .catch(async err => {
            if (err.message === 'No response' || (
              // IE11 has a weird error when failing a CORS check
              // this is the most specific error available
              err.message === 'Network request failed' && err.name === 'TypeError'
            )) {
              setState({
                touched: true,
                status: null,
                valid: 'Uploaded',
                invalid: null,
              });
              await onSuccess({ key: fields.key });
              setState({
                touched: true,
                status: null,
                valid: null,
                invalid: null,
              });
            }
            else {
              throw err;
            }
          });
      })
      .catch((err = new Error('Unknown Error')) => {
        setState({
          touched: true,
          status: null,
          valid: null,
          invalid: err.message,
        });
      });

  }, [setState]);

  return (
    <Fragment>
      <Container>
        <Form.Control
          // defaults
          type="file"
          className="form-control-file"
          // given
          {...rest}
          // overrides
          onChange={onChange}
          isValid={state.valid}
          isInvalid={state.invalid}
          hidden={Container !== Fragment}
        />
      </Container>
      {/* apply feedback styling only if feedback is available, as the style is a bit intrusive */}
      <Form.Text muted className={state.status ? "file-upload__feedback" : ""}>
        {state.status}
      </Form.Text>
      <Form.Control.Feedback type="valid" className={state.valid ? "file-upload__feedback" : ""}>
        {state.valid}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="invalid" className={state.invalid ? "file-upload__feedback" : ""}>
        {state.invalid}
      </Form.Control.Feedback>
    </Fragment>
  );
}
