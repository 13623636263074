import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { fetchUsers } from '../../user/actions';
import {
  fetchGroup,
  fetchGroupAccess,
  addGroupAccessUsers,
  deleteGroupAccessUsers,
} from '../actions';

import TableListEdit, {
  included,
  createSelectAllHeaderFormatter,
  createSelectAllFormatter,
  createCheckboxFormatter,
  sortByActiveThenString,
  sortByActiveThenNumber,
} from '../../../components/TableListEdit';

import { useColumnsWithVisibility } from '../../../components/table/utils';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';
import { addToast } from '../../../components/Toaster';
import {
  userName,
  userEmail,
  userTypeDisplayName,
  department,
  createdAt,
} from '../../user/columns';

import {
  getUsersList,
  hasAdminRightsInActiveGroup,
} from '../../user/selectors';
import {
  getGroupAccessUsers,
  isTopLevelGroupActive,
  isStandardOrganisation,
  getNearestGroupOrganisationAncestor,
  getNearestOrganisationAncestorId,
  getActiveGroupNodeInfo,
} from '../selectors';

import { capitaliseFirstChar } from '../../../lib/utils';

const defaultSorted = [{
  dataField: userName.dataField,
  order: 'asc'
}];

const userColumns = [{
  ...userName,
  sortFunc: sortByActiveThenString,
}, {
  ...userEmail,
  sortFunc: sortByActiveThenString,
}, {
  ...userTypeDisplayName,
  sortFunc: sortByActiveThenNumber,
}, {
  ...department,
  sortFunc: sortByActiveThenString,
}, {
  ...createdAt,
  sortFunc: sortByActiveThenString,
}];

const linearPermissionList = [
  'read',
  'write',
  'admin',
];

const permissionList = [
  ...linearPermissionList,
  'login',
];

export const checkboxColumns = [
  ...linearPermissionList.map((permission, index, permissions) => ({
    ...included,
    dataField: `_meta_.${permission}`,
    text: `${capitaliseFirstChar(permission)} access`,
    headerFormatter: createSelectAllHeaderFormatter(permission, {
      alsoSet: permissions.slice(0, index),
      alsoUnset: permissions.slice(index),
    }),
    formatter: createCheckboxFormatter(permission, {
      alsoSet: permissions.slice(0, index),
      alsoUnset: permissions.slice(index),
    }),
  })),
  // // add 'login access' controls
  // {
  //   ...included,
  //   dataField: `_meta_.login`,
  //   text: `${capitaliseFirstChar('login')} access`,
  //   headerFormatter: createSelectAllHeaderFormatter('login'),
  //   formatter: createCheckboxFormatter('login'),
  // },
];

const checkAllColumn = {
  dataField: `_meta_._all_`,
  text: `All access`,
  headerFormatter: createSelectAllHeaderFormatter('_all_', {
    alsoSet: linearPermissionList,
  }),
  formatter: createSelectAllFormatter('_all_', {
    alsoSet: linearPermissionList,
  }),
};

const columns = [
  ...userColumns,
  checkAllColumn,
  ...checkboxColumns,
];

const searchKeys = [
  userName.dataField,
  userEmail.dataField,
  department.dataField,
];

// parse form data before receiving it in onSubmit
function parse(user) {
  // include full user object only if any of the permissions are true
  if (user && user._meta_ && permissionList.find(permission => {
    return user._meta_[permission] === true;
  })) {
    return user;
  }
}

function EditGroupAccessUsers({
  groupId,
  isUserGroup,
  isOrganisationGroup,
  topLevelGroupActive,
  userCanViewGroups,
  userCanEditGroups,
  organisationIsStandard,
  nearestOrganisationGroup = {},
  rootUsers,
  groupUsers,
  availableUsers,
  fetchGroup,
  fetchGroupAccess,
  fetchUsers,
  addGroupAccessUsers,
  deleteGroupAccessUsers,
}) {
  const { t } = useTranslation();
  // fetch root users when group id is not set.
  useEffect(() => {
    if(!groupId) {
      fetchUsers({ forOrg: true });
    }
  }, []);

  // construct table users from root user objects filtered to access list
  const filteredUsers = useMemo(() => {
    const userIds = (availableUsers || []).map(({ id }) => id);
    // filter root level users to the users available here
    return (rootUsers || []).filter(({ id }) => userIds.includes(id));
  }, [rootUsers, availableUsers]);

  const encodeMeta = useMemo(() => {
    return user => {
      const groupUser = (groupUsers || []).find(({ id }) => id === user.id);
      const rights = (groupUser && groupUser.rights && groupUser.rights) || [];
      return permissionList.reduce((acc, key) => {
        return {
          ...acc,
          [key]: rights.includes(key),
        };
      }, {});
    };
  }, [groupUsers]);

  const [loadingUsers, setLoadingUsers] = useState({});
  // load users list
  useEffect(() => {
    const group = { id: groupId };
    // fetch group's users if needed
    // add already fetching state
    async function loadUsersLists() {
      const requestedAt = Date.now();
      setLoadingUsers({ requestedAt });
      try {
        // this logic should follow that of the selector logic
        // if on top level, fetch all users
        await Promise.all([
          // get current access
          fetchGroupAccess(group),
          // get current group members
          fetchUsers(),
        ]);
        setLoadingUsers(state => {
          return state.requestedAt === requestedAt
            ? { succeededAt: new Date() }
            : state;
        });
      }
      catch (error) {
        setLoadingUsers(state => {
          return state.requestedAt === requestedAt
            ? { error: error.message || 'Error' }
            : state;
        });
      }
    }
    // execute async code
    if (group.id) {
      loadUsersLists();
    }
  }, [groupId]);

  const [loadingGroups, setLoadingGroups] = useState({});
  // load organisation detail
  useEffect(() => {
    const group = { id: groupId };
    // fetch group's users if needed
    // add already fetching state
    async function loadGroups() {
      const submittedAt = Date.now();
      setLoadingGroups({ submittedAt });
      try {
        await Promise.all([
          // get group details
          fetchGroup(group),
          // if viewing a sub organisation's group
          // fetch that organisation's details
          ...(!topLevelGroupActive && nearestOrganisationGroup.id) ? [
            fetchGroup({ id: nearestOrganisationGroup.id }),
            fetchGroupAccess({ id: nearestOrganisationGroup.id }),
          ] : [],
        ]);
        setLoadingGroups(state => {
          return state.submittedAt === submittedAt
            ? { succeededAt: new Date() }
            : state;
        });
      }
      catch (error) {
        setLoadingGroups(state => {
          return state.submittedAt === submittedAt
            ? { error: error.message || 'Error' }
            : state;
        });
      }
    }
    // execute async code
    if (group.id) {
      loadGroups();
    }
  }, [groupId, topLevelGroupActive, nearestOrganisationGroup.id]);

  const onSubmit = useCallback(async includedUsers => {
    const group = { id: groupId };

    function parseItems({ id, _meta_: { active, ...rights }={}}) {
      return {
        id,
        // add rights keys if they have matching meta keys
        rights: Object.entries(rights)
          .filter(([key]) => permissionList.includes(key))
          .filter(([, value]) => !!value)
          .map(([key]) => key),
      };
    }

    // get users list from current form state
    const newUsers = (includedUsers || []).map(parseItems).filter(Boolean);
    const oldUsers = (groupUsers || []);
    const addUsers = newUsers.filter(newUser => (
      // include if this user was not known before
      (!oldUsers.find(oldUser => newUser.id === oldUser.id)) ||
      // or include if the user was known but their rights have changed
      (oldUsers.find(oldUser => {
        return (newUser.id === oldUser.id) &&
          ((newUser.rights || []).sort().join('-') !== (oldUser.rights || []).sort().join('-'));
      }))
    ));
    const delUsers = oldUsers.filter(oldUser => !newUsers.find(newUser => newUser.id === oldUser.id));

    if (addUsers.length || delUsers.length) {
      await Promise.all([
        addUsers.length && addGroupAccessUsers(group, addUsers),
        delUsers.length && deleteGroupAccessUsers(group, delUsers.map(({ id }) => id)),
      ]);
      addToast({ variant: 'success', header: 'Users updated successfully' });
      await fetchGroupAccess(group);
    } else {
      addToast({ variant: 'warning', header: 'No changes made' });
    }
  }, [
    addGroupAccessUsers,
    deleteGroupAccessUsers,
    fetchGroupAccess,
    groupId,
    groupUsers,
  ]);

  const visibleColumns = useColumnsWithVisibility(columns, {
    // apply hidden status to hide organisation field for standard orgs
    'organisation_name': !organisationIsStandard,
    // if at least one device is marked as archived, show the archive status column
    'archived': !!(filteredUsers || []).find(({ archived }) => archived),
    // remove 'login' access on normal group, it only applies to orgs
    '_meta_.login': isOrganisationGroup,
  });
  const localisedColumns = useLocalisedColumns(visibleColumns);

  const loadingState = useMemo(() => {
    return {
      submittedAt: loadingUsers.submittedAt || loadingGroups.submittedAt,
      // only show last time users were fetched (not groups)
      succeededAt: loadingUsers.succeededAt,
      error: loadingUsers.error || loadingGroups.error,
    };
  }, [loadingUsers, loadingGroups]);

  const noDataIndication = useCallback(() => {
    return t('components.organisations.edit-group-access-users.no-permissions');
  }, []);

  // if this is not a device group:
  // the user should be guided towards the appropriate access editing page
  if (isUserGroup) {
    return <Redirect to="/group/access" />;
  }

  if (!userCanViewGroups) {
    return null;
  }

  return (
    <TableListEdit
      before={organisationIsStandard ? null : (
        <Alert variant="warning">
          <p>
            {t('components.organisations.edit-group-access-users.cannot-modify')}
          </p>
          <p className="mb-0">
            {t('components.organisations.edit-group-access-users.sign-in')}
          </p>
        </Alert>
      )}
      title={t('components.organisations.edit-group-access-users.title')}
      columns={localisedColumns}
      defaultSorted={defaultSorted}
      loading={loadingState}
      noDataIndication={noDataIndication}
      list={filteredUsers}
      encodeMeta={encodeMeta}
      parse={parse}
      isItemDisabled={undefined}
      searchKeys={searchKeys}
      onSubmit={onSubmit}
      userCanEdit={!!userCanEditGroups}
    />
  );
}

const mapStateToProps = (state, { groupId }) => {
  const {
    isUserGroup,
    isOrganisationGroup,
  } = getActiveGroupNodeInfo(state);
  const topLevelGroupActive = isTopLevelGroupActive(state);
  const userIsAdmin = !!hasAdminRightsInActiveGroup(state);
  const organisationIsStandard = !!isStandardOrganisation(state);
  const nearestOrganisationGroup = getNearestGroupOrganisationAncestor(state, groupId);
  const nearestOrganisationId = getNearestOrganisationAncestorId(state, groupId);
  return {
    isUserGroup,
    isOrganisationGroup,
    topLevelGroupActive,
    userCanViewGroups: userIsAdmin,
    userCanEditGroups: userIsAdmin && organisationIsStandard,
    organisationIsStandard,
    nearestOrganisationGroup,
    nearestOrganisationId,
    // get full user items (they include user type information)
    rootUsers: getUsersList(state),
    groupUsers: getGroupAccessUsers(state, groupId),
    availableUsers: topLevelGroupActive
      // at top level, all users are group users
      ? getGroupAccessUsers(state, groupId)
      // at other levels, display users from group membership
      : getGroupAccessUsers(state, nearestOrganisationGroup && nearestOrganisationGroup.id),
  };
};
const mapDispatchToProps = {
  fetchUsers,
  fetchGroup,
  fetchGroupAccess,
  addGroupAccessUsers,
  deleteGroupAccessUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroupAccessUsers);
