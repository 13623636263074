import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { getAlarmEvents } from "../selectors";
import { createAlarmEvent, fetchAlarm, fetchAlarmEvents, deleteAlarmEvent, updateAlarmEvent } from "../actions";

export default function useAlarmEvent(alarmId, {fetch = false, eventId = ''} = {}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const alarmEvents = useSelector(state => getAlarmEvents(state, alarmId));

  useEffect(() => {
    setLoading(false);
    setError(null);
    setSuccess(false);
  }, [alarmId]);

  const postEvent = useCallback(async (payload) => {
    for(const key in payload) {
      if(!payload[key]) delete payload[key];  // Do not post empty value.
    }
    try {
      setLoading(true);
      await dispatch(createAlarmEvent({id: alarmId}, payload));
      if(eventId) {
        // If eventId provided, it means the alarm is grouped to this event, need to update events list for this event.
        await dispatch(fetchAlarmEvents({id: eventId}));
      } else {
        await Promise.all([
          dispatch(fetchAlarm({id: alarmId})),
          dispatch(fetchAlarmEvents({id: alarmId}))
        ]);
      }
      setSuccess(true);
      setError(null);
    } catch(e) {
      setSuccess(false);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [alarmId, fetchAlarm, fetchAlarmEvents, createAlarmEvent]);

  const editEvent = useCallback(async (eventId, payload) => {
    try {
      setLoading(true);
      await dispatch(updateAlarmEvent({id: alarmId}, {id: eventId}, payload));
      await dispatch(fetchAlarmEvents({id: alarmId}));
      setSuccess(true);
      setError(null);
    } catch(e) {
      setSuccess(false);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [alarmId, fetchAlarmEvents, updateAlarmEvent]);

  const deleteEvent = useCallback(async (eventId) => {
    try {
      setLoading(true);
      await dispatch(deleteAlarmEvent({id: alarmId}, {id: eventId}));
      await dispatch(fetchAlarmEvents({id: alarmId}));
      setSuccess(true);
      setError(null);
    } catch(e) {
      setSuccess(false);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [alarmId, fetchAlarmEvents, deleteAlarmEvent]);

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      await dispatch(fetchAlarmEvents({id: alarmId}));
      setSuccess(true);
      setError(null);
    } catch(e) {
      setSuccess(false);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, [alarmId]);

  useEffect(() => {
    if(fetch && alarmId) fetchEvents();
  }, [fetch, alarmId]);

  return { alarmEvents, deleteEvent, postEvent, editEvent, loading, error, success };
}