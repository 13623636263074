import { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import HomeCard from './HomeCard';
import { useEventImpactTrendData } from '../../modules/organisation/hooks/useGroupEventsReport';
import { getChartLabelColor, alignTwoYAxises } from '../lib/utils';

function EventImpactTrend() {
  const { t } = useTranslation();
  const eventImpactTrend = useEventImpactTrendData();

  const {_state_: { loading, error } = {}, items = []} = eventImpactTrend || {};
  const itemsSortedByDate = items.sort((a, b) => a.date < b.date ? -1 : 1);
  const avoidedCosts = useMemo(() => itemsSortedByDate.map(i => i.avoided_costs), [items]);
  const eventCosts = useMemo(() => itemsSortedByDate.map(i => i.event_costs === 0 ? 0 : i.event_costs * -1), [items]);
  const equipmentCount = useMemo(() => itemsSortedByDate.map(i => i.equipment_cnt), [items]);
  const preventedCosts = useMemo(() => itemsSortedByDate.map(i => i.total_prevented), [items]);
  const monitoringCosts = useMemo(() => itemsSortedByDate.map(i => i.monitoring_costs * -1), [items]);
  const sumEventMonitoringCosts = useMemo(() => monitoringCosts.map((c, i) => c + eventCosts[i]), [eventCosts, monitoringCosts]); // The sum of values in eventCosts and monitoringCosts.
  const [interval1, interval2, positiveTicks, negativeTicks] = useMemo(() => alignTwoYAxises([...sumEventMonitoringCosts, ...avoidedCosts, ...preventedCosts], equipmentCount), [items]);

  if(!eventImpactTrend) return null;

  return (
    <HomeCard
      header={t('home.event-impact-trend.header')}
      headerLink={{to: '/group/impact_summary', text: t('home.common.all-areas')}}
      loading={loading}
      error={error}
    >
      <ReactEcharts
        option = {{
          grid: {
            top: 10,
            bottom: 60,
            left: 85,
            right: 60,
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            textStyle: {
              fontSize: 10
            },
            bottom: -5,
            data: [
              t('home.common.monitoring-costs'),
              t('home.common.avoided-costs'),
              t('home.common.event-costs'),
              t('home.event-impact-trend.total-prevented-costs'),
              t('home.common.assets-monitored'),
            ]
          },
          color: ['orange', 'green', 'red', 'grey', 'blue'].map(color => getChartLabelColor(color)),
          xAxis: {
            type: 'category',
            data: itemsSortedByDate.map(i => moment(i.date).format('MMM-YY'))
          },
          yAxis: [
            {
              type: 'value',
              name: t('home.event-impact-trend.costs-incurred-prevented'),
              nameLocation: 'middle',
              position: 'left',
              nameTextStyle: {
                padding: [0, 0, 55, 0]
              },
              max: interval1 * positiveTicks,
              interval: interval1,
              min: interval1 * negativeTicks,
            },
            { type: 'value' },
            {
              type: 'value',
              name: t('home.common.quantity-of-assets-monitored'),
              nameLocation: 'middle',
              position: 'right',
              nameTextStyle: {
                padding: [30, 0, 0, 0]
              },
              axisLabel: {
                formatter: function(value) {
                  return value < 0 ? '' : value;
                }
              },
              max:  interval2 * positiveTicks,
              interval:  interval2,
              min: interval2 * negativeTicks,
            }
          ],
          series: [{
            name: t('home.common.monitoring-costs'),
            type: 'bar',
            stack: 'one',
            data: monitoringCosts,
          }, {
            name: t('home.common.avoided-costs'),
            type: 'bar',
            stack: 'one',
            data: avoidedCosts,
          }, {
            name: t('home.common.event-costs'),
            type: 'bar',
            stack: 'one',
            data: eventCosts,
          }, {
            name: t('home.event-impact-trend.total-prevented-costs'),
            type: 'line',
            data: preventedCosts,
          }, {
            name: t('home.common.assets-monitored'),
            type: 'line',
            data: equipmentCount,
            yAxisIndex: 2,
          }]
        }}
      />
    </HomeCard>
  );
}

export default EventImpactTrend;