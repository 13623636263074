
import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getFormValues } from '../../../lib/utils';

import FormModal from '../../../components/FormModal';
import { useFocusableRef } from '../../../components/form/useFocusableRef';

import {
  editOrganisationToken,
  fetchOrganisationTokens,
} from '../actions';

const confirmButtonProps = {
  variant: 'success',
};

const formDescriptionText = "token_description";

function EditOrganisationTokenFormModal({
  organisationId,
  token,
  description = '',
  editOrganisationToken,
  fetchOrganisationTokens,
  children,
  ...props
}) {

  const { t } = useTranslation();
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [pristineDescription, setPristineDescription] = useState(true);
  const [valid, setValid] = useState(true);

  const formRef = useRef(null);
  const [descriptionRef, setShown] = useFocusableRef(null);

  const handleSubmit = useCallback(async e => {
    const { token_description } = getFormValues(e, formRef.current) || {};
    try {
      await editOrganisationToken({ id: organisationId }, token, token_description);
      await fetchOrganisationTokens({ id: organisationId });
    }
    catch(err) {
      // allow error to prevent closing of the modal
      throw new Error(err);
    }
  }, [formRef.current, organisationId, token]);

  const editText = t('components.organisations.edit-organisation-token-form-modal.header');
  return (
    <FormModal
      // set defaults
      header={editText}
      confirmText={editText}
      confirmButtonProps={confirmButtonProps}
      // add given props
      {...props}
      // override given props
      size="md"
      valid={!pristineDescription && valid}
      onShow={useCallback(() => {
        setShown(true);
      }, [])}
      onClose={useCallback((saved) => {
        setShown(false);
        if (!saved) {
          setUpdatedDescription(description);
        }
      }, [description])}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId={formDescriptionText}>
            <Form.Label column sm={5}>
              {t('components.organisations.edit-organisation-token-form-modal.token-description')}
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                ref={descriptionRef}
                type="text"
                name={formDescriptionText}
                isInvalid={!pristineDescription && !valid}
                value={updatedDescription}
                onChange={useCallback(e => {
                  const entered = e.target.value;
                  setPristineDescription(entered === description);
                  setUpdatedDescription(entered);
                  setValid(!!entered);
                }, [description])}
              />
            </Col>
          </Form.Group>
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const mapDispatchToProps = {
  editOrganisationToken,
  fetchOrganisationTokens,
};

export default connect(null, mapDispatchToProps)(EditOrganisationTokenFormModal);
