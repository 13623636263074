import createReducer from "../../lib/createReducer";
import * as ACTION_TYPES from './types/ActionTypes';
import * as USER_ACTION_TYPES from '../user/types/ActionTypes';
import { DomainStoreState, AccountStatusResponse, DomainKey } from "./types";

export const DEFAULT_STATE: DomainStoreState = {
  loading: false,
  error: null,
  lastFetch: null,
  data: {},
};

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => DEFAULT_STATE,
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => DEFAULT_STATE,
  [ACTION_TYPES.GET_DOMAIN_DATA_REQUEST](state: DomainStoreState) {
    return {
      ...state,
      loading: true,
    };
  },
  [ACTION_TYPES.GET_DOMAIN_DATA_FAILURE](state: DomainStoreState, { response }: { response: string }) {
    return {
      ...state,
      loading: false,
      error: response,
    };
  },
  [ACTION_TYPES.GET_DOMAIN_DATA_SUCCESS](state: DomainStoreState, { response, key }: { response: AccountStatusResponse, key: DomainKey }) {
    const newState = {
      ...state,
      loading: false,
      error: null,
    };
    newState.data[key] = response?._embedded?.items;
    return newState;
  },
});