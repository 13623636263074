import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceWatchers } from '../actions';
import { getDeviceWatchers, getDeviceWatchersState } from '../selectors';

function useDeviceWatchers(deviceId, { fetch = false } = {}) {
  const dispatch = useDispatch();
  const watchers = useSelector(state => getDeviceWatchers(state, deviceId)) || [];
  const { loading, error, lastFetched } = useSelector(state => getDeviceWatchersState(state, deviceId)) || {};

  const fetchWatchers = useCallback(async () => {
    dispatch(fetchDeviceWatchers({id: deviceId}));
  }, [deviceId]);

  useEffect(() => {
    if(fetch && deviceId) {
      fetchWatchers();
    }
  }, [deviceId]);

  return {loading, error, lastFetched, watchers};
}

export default useDeviceWatchers;