import { usePoll } from '../../../hooks/usePoll';
import useGroup from './useGroup';
import { useGroupReportData } from './useGroupReportData';
import { getIntervalMilliSeconds } from "../../../components/lib/utils";

const { REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES = 5 } = process.env;
const checkPeriods = getIntervalMilliSeconds(REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES);

export function useGroupRecentEventsReport() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'events', filter: 'recent', limit: 'top5'});

  usePoll(fetchData, [group.id], {interval: checkPeriods});

  return reportData;
}

export function useGroupAcknowledgedEventsReport() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'events', filter: 'acknowledged', limit: 'top5'});

  usePoll(fetchData, [group.id], {interval: checkPeriods});

  return reportData;
}

export function useGroupValidatedEventsReport() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'events', filter: 'validated', limit: 'top5'});

  usePoll(fetchData, [group.id], {interval: checkPeriods});

  return reportData;
}

export function useGroupPendingEventsReport() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'events', filter: 'pending', limit: 'top5'});

  usePoll(fetchData, [group.id], {interval: checkPeriods});

  return reportData;
}

export function useReviewEventsManagementReport() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, { type: 'events', view: 'management', filter: 'pendingreview' });

  usePoll(fetchData, [group.id], {interval: checkPeriods});

  return reportData;
}

export function useValidatedEventsManagementReport() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, { type: 'events', view:'management', filter: 'validated' });

  usePoll(fetchData, [group.id], {interval: checkPeriods});

  return reportData;
}

export function usePendingEventsManagementReport() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, { type: 'events', view: 'management', filter: 'pending' });

  usePoll(fetchData, [group.id], {interval: checkPeriods});

  return reportData;
}

export function useEventImpactPreventedData() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, { type: 'eventimpact', view: 'prevented'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}

export function useEventImpactTrendData() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, { type: 'eventimpact', view: 'trend'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}

export function useEventImpactMonitoringData() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, { type: 'eventimpact', view: 'monitoring'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}