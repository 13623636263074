import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip, Button, ButtonGroup } from 'react-bootstrap';
import { AiOutlineMail } from 'react-icons/ai';
import {
  fetchAllNotifications,
  fetchUnreadNotifications,
  updateOneNotificationReadStatus,
  deleteOneNotification,
  setAllNotificationsReadStatus,
  deleteAllNotifications,
} from '../actions';
import { useTrackDispatch } from '../../app/hooks';
import {
  getNotifications,
  // getReadNotificationsCountLocal,
  getUnreadNotificationsCount,
  getUnreadNotificationsCountLocal,
} from '../selectors';
import './notificationCenter.scss';
import { displayDateTime, getIntervalMilliSeconds } from '../../../components/lib/utils';
import { usePoll } from '../../../hooks/usePoll';
import ConfirmModal from '../../../components/ConfirmModal';
import { NotificationState } from '../types';

const { REACT_APP_CHECK_NOTIFICATION_INTERVAL_MINUTES = 2 } = process.env;
const INTERVAL = getIntervalMilliSeconds(REACT_APP_CHECK_NOTIFICATION_INTERVAL_MINUTES);

function NotificationCenter() {
  const dispatch = useDispatch();
  const trackDispatch = useTrackDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const openDropdownRef = useRef(openDropdown);
  // const [show, setShow] = useState(false);  // If notifications list dropdown is open.
  const [firstShow, setFirstShow] = useState(true); // If notifications list dropdown is open first time.
  const history = useHistory();

  const notifications = useSelector(getNotifications);
  const unreadCount = useSelector(getUnreadNotificationsCount);
  const unreadCountLocal = useSelector(getUnreadNotificationsCountLocal);
  // const readCountLocal = useSelector(getReadNotificationsCountLocal);

  usePoll(() => dispatch(fetchUnreadNotifications()), [], { interval: INTERVAL });

  useEffect(() => {
    if ((notifications.length === 0 || unreadCount !== unreadCountLocal) && openDropdown) {
      dispatch(fetchAllNotifications());
    }
  }, [unreadCount, unreadCountLocal, openDropdown]);

  useEffect(() => {
    const closeDropdown = () => {
      if (openDropdownRef) {
        setOpenDropdown(false);
      }
    };
    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, []);

  async function handleReadNotification(notification: NotificationState) {
    const { id, url = '' } = notification;
    try {
      trackDispatch({ location: 'Notifications', notification_id: id, action: 'clicked' });
      if (notification.unread) {
        await dispatch(updateOneNotificationReadStatus(notification.id, true));
      }
    } catch (e) {  // Slient the exception. Don't let exception block the URL redirection.
    } finally {
      if (!url) return;
      if (url.toLowerCase().startsWith('http')) { // External link
        window.location.href = url;
      } else {
        history.push(url);
        setOpenDropdown(false);
      }
    }
  }

  function handleToggleReadStatus(notification: NotificationState) {
    if (notification.loading) return;
    if (notification.unread) {
      dispatch(updateOneNotificationReadStatus(notification.id, true)); // Set read.
    } else {
      dispatch(updateOneNotificationReadStatus(notification.id, false));  // Set unread.
    }
  }

  function handleDeleteNotification(notification: NotificationState) {
    dispatch(deleteOneNotification(notification.id));
  }

  function handleClickToggle(e: React.SyntheticEvent) {
    e.stopPropagation();
    if (firstShow) {
      dispatch(fetchAllNotifications());
      setFirstShow(false);
    }
    setOpenDropdown(!openDropdown);
  }

  return (
    <Dropdown className="nc__dropdown" show={openDropdown}>
      <Dropdown.Toggle
        className="btn btn-light btn-sm"
        style={{ lineHeight: 0 }}
        onClick={handleClickToggle}
      >
        <AiOutlineMail size="2em" style={{ width: '2em', marginRight: 0 }} />
        {unreadCount > 0 && <span className="badge badge-pill badge-danger position-absolute nc__unread-count">
          {unreadCount}
        </span>}
      </Dropdown.Toggle>
      <Dropdown.Menu className="shadow bg-white rounded"
        onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
        <Dropdown.Header className="d-flex justify-content-end flex-wrap">
          <ButtonGroup>
            <ConfirmModal
              body="You are going to mark all notifications as read."
            >
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() => dispatch(setAllNotificationsReadStatus(true))}
              >
                Mark All Read
              </Button>
            </ConfirmModal>
            <ConfirmModal
              body="You are going to clear all read notifications."
            >
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() => dispatch(deleteAllNotifications(true))}
              >Clear All Read</Button>
            </ConfirmModal>
            <ConfirmModal
              body="You are going to clear all the notifications."
            >
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() => dispatch(deleteAllNotifications())}
              >Clear All</Button>
            </ConfirmModal>
          </ButtonGroup>
        </Dropdown.Header>
        {notifications?.length > 0 ? notifications.map((notification) => {
          return (
            <Dropdown.Item
              key={notification.id}
              className="nc__dropdown-item"
              as="div"
            >
              <div className="d-flex align-items-start">
                <OverlayTrigger overlay={<Tooltip id="read-status">Set as {notification.unread ? 'read' : 'unread'}</Tooltip>}>
                  <div aria-hidden="true" onClick={() => { handleToggleReadStatus(notification); }}>
                    <span className={`nc__read-circle mr-2 ${notification.unread ? 'nc__unread' : 'nc__read'} ${notification.high_priority ? "danger" : "normal"}`}></span>
                  </div>
                </OverlayTrigger>
                <div className="flex-grow-1 border-bottom nc__meta-data pb-2">
                  <div className="nc__timestamp d-flex justify-content-between align-items-center">
                    <span>{displayDateTime(notification.timestamp)}</span>
                    {notification.unread && notification.high_priority ?
                      <ConfirmModal // Require confirmation if notification is unread and high priority.
                        body="You are going to delete the notification."
                        confirmText="Confirm"
                      >
                        <Button
                          variant="light"
                          size="sm"
                          className="px-1 close nc__button-delete"
                          onClick={() => { handleDeleteNotification(notification); }}
                          disabled={notification.loading}
                        >
                          <span>&times;</span>
                        </Button>
                      </ConfirmModal>
                      :
                      // If notification is read, delete it directly.
                      <Button
                        variant="light"
                        size="sm"
                        className="px-1 close nc__button-delete"
                        onClick={() => { handleDeleteNotification(notification); }}
                        disabled={notification.loading}
                      >
                        <span>&times;</span>
                      </Button>
                    }
                  </div>
                  <div onClick={() => { handleReadNotification(notification); }} aria-hidden="true" className="mt-1" >
                    <div
                      dangerouslySetInnerHTML={{ __html: notification.html_content }}
                      className="nc__notification-text text-wrap"
                    />
                  </div>
                </div>
              </div>
            </Dropdown.Item>
          );
        }) : <Dropdown.Item className="text-black-50 nc__dropdown-item" as="div">
          <div className="nc__no-notification-text">
            No notifications
          </div>
        </Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NotificationCenter;