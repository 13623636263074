
import React, { useCallback, useState, useRef, useLayoutEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { IoIosCreate, IoIosTrash, IoIosAddCircleOutline } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import objectFitImages from 'object-fit-images';

import FileUpload from './FileUpload';
import ConfirmModal from '../ConfirmModal';

import './imageUpload.scss';

let count = 0;

function ImageUpload(props) {

  const {
    image = {},
    onEdit,
    onDelete,
    canEdit = !!onEdit,
    canDelete = !!onDelete,
    ...rest
  } = props;

  const { t } = useTranslation();
  const imageEl = useRef();

  // on DOM load (and element references becoming available),
  // and whenever the image source changes
  // fix image fit in IE before the element is painted
  // (will only cause an effect to elements that have an object-fit font-family attached)
  useLayoutEffect(() => {
    if (imageEl.current) {
      objectFitImages(imageEl.current);
    }
  }, [imageEl.current, image.src]);

  const [instanceId] = useState(count += 1);

  return (
    <div className="image-upload__img-container">
      <img ref={imageEl} src={image.src} alt={image.alt} className="image-upload__img"/>
      <div className="image-upload__img-toolbar">
        {canEdit && (
          <Form.Group as="div" controlId={`image-upload-edit-${instanceId}`} className="mb-0">
            <FileUpload {...rest} context={image} Container={({ children }) => (
              <Form.Label className="btn btn-sm btn-primary image-upload__img-edit-button mb-0">
                <IoIosCreate size="1.5em"/>
                {children}
              </Form.Label>
            )}/>
          </Form.Group>
        )}
        {canDelete && (
          <ConfirmModal
            header={t('components.form.image-upload.delete-image')}
            body={t('components.form.image-upload.cannot-be-undone')}
            confirmText={t('common.delete')}
          >
            <Button
              variant="danger"
              size="sm"
              onClick={() => onDelete(image)}
            >
              <IoIosTrash size="1.5em"/>
            </Button>
          </ConfirmModal>
        )}
      </div>
    </div>
  );
}

export default function ImagesUpload(props) {

  const {
    images,
    image,
    canAdd = true,
    buttonIcon = <IoIosAddCircleOutline size="1.4em" />,
    buttonText = t('components.form.image-upload.add-image'),
    // ignore the following props in the Bootstrap component
    onEdit,
    onDelete,
    canEdit,
    canDelete,
    // pass the rest of the props onwards
    ...rest
  } = props;

  const ImageUploadContainer = useCallback(({ children }) => (
    <div className="square-button-card image-upload__add-button">
      <Form.Label className="btn btn-outline-primary">
        {buttonText && <div className="image-upload__add-image">{buttonIcon} {buttonText}</div>}
        {children}
      </Form.Label>
    </div>
  ), [buttonIcon, buttonText]);

  return images ? (
    // multiple edit images and add image button
    <div className="image-upload image-upload__images-container d-flex flex-wrap">
      {(images || []).map((image, index) => (
        <ImageUpload
          key={(image && image.id) || index}
          image={image}
          onEdit={onEdit}
          onDelete={onDelete}
          canEdit={canEdit}
          canDelete={canDelete}
          {...rest}
        />
      ))}
      {canAdd && (
        <div className="image-upload__add-button-container">
          <FileUpload {...rest} Container={ImageUploadContainer}/>
        </div>
      )}
    </div>
  ) : image ? (
    // single edit image
    <ImageUpload
      image={image}
      onEdit={onEdit}
      onDelete={onDelete}
      canEdit={canEdit}
      canDelete={canDelete}
      {...rest}
    />
  ) : canAdd ? (
    // single add image button
    <div className="image-upload__add-button-container">
      <FileUpload {...rest} Container={ImageUploadContainer}/>
    </div>
  ) : null; // display nothing
}
