
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
  IoIosAddCircleOutline,
  IoIosCloud,
  IoIosRadio,
  IoIosLink,
} from 'react-icons/io';

import TableToolbar from '../../../components/TableToolbar';

import AddOrganisationTokenFormModal from './AddOrganisationTokenFormModal';
import AddStreamingConfigurationFormModal from './AddStreamingConfigurationFormModal';
import EndpointFormModal from './EndpointFormModal';
import { getCurrentOrganisationHasProductCode } from '../selectors';

const t = i18n.t;
const buttonProps = {
  target: "_blank",
  rel: "noopener noreferrer",
  variant: "outline-secondary",
};

const AddButton = ({ organisationId }) => {
  const { t } = useTranslation();
  const hasStreamingFeature = useSelector(state => {
    return getCurrentOrganisationHasProductCode(state, 'streaming_api');
  });
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="pl-1">
        <IoIosAddCircleOutline size="1.4em" /> {t('common.add')}{' '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <AddOrganisationTokenFormModal organisationId={organisationId}>
          <Dropdown.Item>
            <IoIosCloud size="1.2em" /> <span>{
              t('components.organisations.developer-centre-toolbar.add-api-token')
            }</span>
          </Dropdown.Item>
        </AddOrganisationTokenFormModal>
        {hasStreamingFeature && (
          <AddStreamingConfigurationFormModal organisationId={organisationId}>
            <Dropdown.Item>
              <IoIosRadio size="1.2em" /> <span>{
                t('components.organisations.developer-centre-toolbar.add-streaming')
              }</span>
            </Dropdown.Item>
          </AddStreamingConfigurationFormModal>
        )}
        <EndpointFormModal>
          <Dropdown.Item>
            <IoIosLink size="1.2em" /> <span>{
              t('components.organisations.developer-centre-toolbar.add-webhook')
            }</span>
          </Dropdown.Item>
        </EndpointFormModal>
      </Dropdown.Menu>
    </Dropdown>
  );
};

function DeveloperCentreToolbar({
  title=t('components.organisations.developer-centre-toolbar.title'),
  tableProps,
  loading,
  organisationId,
  subTitle,
}) {

  const { t } = useTranslation();
  const hasStreamingFeature = useSelector(state => {
    return getCurrentOrganisationHasProductCode(state, 'streaming_api');
  });
  return (
    <TableToolbar
      searchable
      title={title}
      subTitle={subTitle}
      loading={loading}
      buttonGroups={[
        [<AddButton key="add" organisationId={organisationId} />],
        [
          <LinkContainer key="tokens" to="/developer/admin/tokens">
            <Button variant="outline-secondary">
              {t('components.organisations.developer-centre-toolbar.api-tokens')}
            </Button>
          </LinkContainer>,
          hasStreamingFeature && <LinkContainer key="streaming" to="/developer/admin/streaming">
            <Button variant="outline-secondary">
              {t('components.organisations.developer-centre-toolbar.streams')}
            </Button>
          </LinkContainer>,
          <LinkContainer key="webhooks" to="/developer/admin/webhooks">
            <Button variant="outline-secondary">
              {t('components.organisations.developer-centre-toolbar.webhooks')}
            </Button>
          </LinkContainer>,
          <Button
            key="documentation"
            href="https://w3.movus.com.au/apidocs/index.html"
            {...buttonProps}
          >
            {t('components.organisations.developer-centre-toolbar.api-documentation')}
          </Button>,
          <Button
            key="support"
            href="https://learn.movus.com.au/knowledge/understanding-developer-centre"
            {...buttonProps}
          >
            {t('components.organisations.developer-centre-toolbar.support')}
          </Button>,
        ].filter(Boolean),
      ]}
      tableProps={tableProps}
    />
  );
}

export default DeveloperCentreToolbar;
