import { useEffect, useCallback, useRef, useState } from 'react';
import useSidebar, { EXPANDED_SIDEBAR_SIZE } from '../modules/app/hooks/useSidebar';
import { debounce } from '../lib/utils';

function SidebarResizer() {

  const rootRef = useRef(document.getElementById('root'));
  const resizerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const {setSidebarWidth: setSidebarWidthPreference, sidebarWidth: sidebarWidthPreference} = useSidebar();
  const [sidebarWidth, setSidebarWidth] = useState(sidebarWidthPreference || EXPANDED_SIDEBAR_SIZE);
  const handleMouseDown = useCallback((event) => {
    event.preventDefault();
    setIsDragging(true);
  }, [setIsDragging]);

  useEffect(() => {
    document.documentElement.style.setProperty('--sidebar-width', sidebarWidth + 'px');
    if(!isDragging) {
      setSidebarWidthPreference(sidebarWidth);
    }
  }, [sidebarWidth, isDragging]);

  const handleMouseMove = useCallback((event) => {
    if(!isDragging) return;
    const newWidth = event.clientX;
    const currentMaxWidth = parseInt(rootRef.current.clientWidth / 2);
    if(newWidth <= EXPANDED_SIDEBAR_SIZE || newWidth >= currentMaxWidth) return;
    setSidebarWidth(event.clientX + 5); // 5 is the half width of resizer(10). It is to make sure cursor is in the middle of the resizer.
  }, [isDragging, setSidebarWidth]);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  const handleMouseOut = useCallback(() => {
    setIsDragging(false);
  }, [setIsDragging]);

  const handleSelectStart = useCallback((event) => {
    // Now allow select text when dragging.
    event.preventDefault();
  }, []);

  const handleWindowResize = useCallback(debounce(() => {
    const currentMaxWidth = parseInt(rootRef.current.clientWidth / 2);
    if(sidebarWidth >= currentMaxWidth) {
      setSidebarWidth(Math.max(currentMaxWidth - 1, EXPANDED_SIDEBAR_SIZE));
    }
  }, 300), [sidebarWidth, setSidebarWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [sidebarWidth]);

  useEffect(() => {
    if(resizerRef.current) {
      resizerRef.current.addEventListener('mousedown', handleMouseDown);
    }
    return () => {
      if(resizerRef.current) {
        resizerRef.current.removeEventListener('mousedown', handleMouseDown);
      }
    };
  }, []);

  useEffect(() => {
    if(isDragging && rootRef.current) {
      document.documentElement.style.setProperty('--sidebar-transition', 'none');
      document.documentElement.style.setProperty('--body-transition', 'none');
      rootRef.current.addEventListener('mousemove', handleMouseMove);
      rootRef.current.addEventListener('mouseup', handleMouseUp);
      rootRef.current.addEventListener('selectstart', handleSelectStart);
      rootRef.current.addEventListener('mouseleave', handleMouseOut);
      rootRef.current.style.cursor = 'col-resize';
    } else {
      document.documentElement.style.setProperty('--sidebar-transition', 'width 0.5s');
      document.documentElement.style.setProperty('--body-transition', 'margin-left 0.5s');
      rootRef.current.style.cursor = 'default';
    }
    return () => {
      if(rootRef.current) {
        rootRef.current.removeEventListener('mousemove', handleMouseMove);
        rootRef.current.removeEventListener('mouseup', handleMouseUp);
        rootRef.current.removeEventListener('selectstart', handleSelectStart);
        rootRef.current.removeEventListener('mouseleave', handleMouseOut);
      }
    };
  }, [isDragging]);

  return (
    <div
      className="sidebar__resizer"
      style={{cursor: 'col-resize', background: isDragging ? '#ccc' : ''}}
      ref={resizerRef}
    >
    </div>
  );
}

export default SidebarResizer;