
import React from 'react';
import { connect } from 'react-redux';
import { IoIosPulse } from 'react-icons/io';

import { InlineHeaderCell, RowCell } from '../../../components/table/formatters';
import { numericSortFunc } from '../../../components/table/utils';
import { getUserDisplayPreference } from '../../../components/values/Rms';

const ConnectedHeaderCell = connect(getUserDisplayPreference)(InlineHeaderCell);
const ConnectedRowCell = connect(getUserDisplayPreference)(RowCell);

// export a column definition
// todo: remove rms + mm2 -> DASH-1018
export const column = {
  dataField: 'rms',
  text: 'Vibration (RMS)',
  icon: <IoIosPulse size="1.4em" />,
  headerFormatter: (columnProps, colIndex, components) => (
    <ConnectedHeaderCell {...columnProps} {...components} />
  ),
  formatter: value => <ConnectedRowCell value={value} />,
  filterValue: value => <ConnectedRowCell value={value} />,
  sort: true,
  sortFunc: numericSortFunc,
  __canChangeVisibility: false,
  __csvTextFormatter: (value, row, options) => {
    const { unitSystemValue } = options;
    if(unitSystemValue === 'US') {
      return value / 25.4;
    }
    return value;
  }
};
