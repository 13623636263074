export const GET_PLAN_REQUEST = 'billing/GET_PLAN_REQUEST';
export const GET_PLAN_SUCCESS = 'billing/GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'billing/GET_PLAN_FAILURE';

export const GET_USAGE_REQUEST = 'billing/GET_USAGE_REQUEST';
export const GET_USAGE_SUCCESS = 'billing/GET_USAGE_SUCCESS';
export const GET_USAGE_FAILURE = 'billing/GET_USAGE_FAILURE';

export const GET_TRANSACTIONS_REQUEST = 'billing/GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'billing/GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILURE = 'billing/GET_TRANSACTIONS_FAILURE';

export const GET_INVOICES_REQUEST = 'billing/GET_INVOICES_REQUEST';
export const GET_INVOICES_SUCCESS = 'billing/GET_INVOICES_SUCCESS';
export const GET_INVOICES_FAILURE = 'billing/GET_INVOICES_FAILURE';