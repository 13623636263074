
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import withNavigationGatewayProps from './withNavigationGatewayProps';

// Components
import { InfoCard, InfoCardItem } from '../../../components/InfoCard';

// Other
import {
  latLngFormatter,
} from '../../../components/table/formatters';

import { getGateway } from '../selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
} from '../../organisation/selectors';

function GatewaySidebar({ gateway={} }) {

  const { t } = useTranslation();

  if (!gateway || !gateway.id) {
    return null;
  }

  return (
    <Fragment>
      <h5 className="montserrat" style={{ marginTop: 10, marginBottom: 1 }}>
        {gateway.mac}
      </h5>
      <InfoCard
        collapsible
        heading={t('components.gateways.gateway-sidebar.access-point-details.heading')}
      >
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.access-point-details.name')}>
          {gateway.name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.access-point-details.access-point-type')}>
          {gateway.type}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.access-point-details.ssid')}>
          {gateway.ssid}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.access-point-details.mac-address')}>
          {gateway.mac}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.access-point-details.last-access')}>
          {moment(gateway.fitmachine_last_heard).format("ll")}
        </InfoCardItem>
      </InfoCard>
      <InfoCard
        collapsible
        heading={t('components.gateways.gateway-sidebar.location-details.header')}
        initialCollapsed
      >
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.location-details.organisation-name')}>
          {gateway.organisation_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.location-details.site-name')}>
          {gateway.site_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.location-details.sub-area-name')}>
          {gateway.sub_area_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.location-details.latitude')}>
          {latLngFormatter(parseFloat(gateway.latitude))}
        </InfoCardItem>
        <InfoCardItem heading={t('components.gateways.gateway-sidebar.location-details.longitude')}>
          {latLngFormatter(parseFloat(gateway.longitude))}
        </InfoCardItem>
      </InfoCard>
    </Fragment>
  );
}

const mapStateToProps = (state, { gatewayId }) => ({
  gateway: getGateway(state, gatewayId),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
  mm2Available: getOrganisationMm2AvailablePreference(state),
});

export default withNavigationGatewayProps(
  connect(mapStateToProps)(GatewaySidebar)
);
