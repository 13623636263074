
import { createStore, applyMiddleware, compose } from 'redux';

import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import storage from 'localforage';

import reducers from '../modules/reducers';
import middleware from './middleware';
import sanitiser from './sanitiser';
import migrations from './migrations';

const debug = process.env.NODE_ENV === 'development';

// configure persistent root reducer
const persistConfig = {
  key: 'root',
  version: Object.keys(migrations).pop(), // default to latest version
  storage,
  debug,
  migrate: createMigrate(migrations, { debug }),
};
const persistedReducer = persistReducer(persistConfig, reducers);

// configure middleware enhancers
const enhancers = [applyMiddleware(...middleware), sanitiser].filter(v => v);
const enhancer = compose(...enhancers);

const store = createStore(persistedReducer, undefined, enhancer);
const persistor = persistStore(store);

export { store, persistor };
