import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { fetchDeviceImpactSummary } from '../actions';
import { getDeviceImpactSummary, getDeviceImpactSummaryState } from '../selectors';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import EquipmentPageLayout from '../components/EquipmentPageLayout';
import {
  alarmTime,
  reason,
  lostHours,
  avoidedHours,
  getEquipmentEventCostsColumn,
  getEquipmentAvoidedCostsColumn,
  getEquipmentTotalReductionColumn,
} from '../columns';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';
import useLocale from '../../../hooks/useLocale';

export default function EquipmentImpactReport() {
  const { t } = useTranslation();
  const { id } = useParams();
  const deviceId = Number(id);
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => getDeviceImpactSummaryState(state, deviceId) || {});
  const impactSummary = useSelector(state => getDeviceImpactSummary(state, deviceId) || []);
  const { currency } = useLocale({ deviceId });

  const columns = useMemo(() => [
    alarmTime,
    reason,
    lostHours,
    avoidedHours,
    getEquipmentEventCostsColumn(currency),
    getEquipmentAvoidedCostsColumn(currency),
    getEquipmentTotalReductionColumn(currency)
  ], [currency]);

  useEffect(() => {
    dispatch(fetchDeviceImpactSummary({id: deviceId}));
  }, [deviceId]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        title={t('screens.equipment.equipment-impact-report.title')}
        loading={loading}
        error={error}
        tableProps={props}
      />
    );
  }, [loading, error]);

  const localisedColumns = useLocalisedColumns(columns);

  return (
    <EquipmentPageLayout>
      <div className="my-4">
        <Table
          keyField="alarm_id"
          renderHeader={renderHeader}
          noDataIndication={() => t('screens.equipment.equipment-impact-report.no-data')}
          data={impactSummary}
          loading={loading}
          columns={localisedColumns}
          defaultSorted={[{
            dataField: 'alarm_time',
            order: 'desc'
          }]}
        />
      </div>
    </EquipmentPageLayout>
  );
}
