import { useTranslation } from 'react-i18next';
import { showTranslations } from 'translation-check';
import { Badge, Button } from 'react-bootstrap';

function ShowTranslations() {
  const { i18n } = useTranslation();

  if (process.env.REACT_APP_TRANSLATION_INSPECT !== 'true') {
    return null;
  }

  return (
    <Button variant="link" className="p-0">
      <Badge onClick={() => showTranslations(i18n)} variant="warning">Translation</Badge>
    </Button>
  );
}

export default ShowTranslations;
