import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAlarm } from "../selectors";
import { fetchAlarm } from "../actions";

export default function useAlarm(alarmId) {
  const dispatch = useDispatch();
  const alarm = useSelector(state => getAlarm(state, alarmId));
  const fetchAlarmById = useCallback(() => {
    dispatch(fetchAlarm(alarmId));
  }, [alarmId]);

  useEffect(() => {
    if(!alarm) {
      fetchAlarmById();
    }
  }, [alarmId]);

  return alarm;
}