
export function getTimezoneOffset() {
  const timezoneOffsetMins = -new Date().getTimezoneOffset();
  return `GMT${
    // set sign
    timezoneOffsetMins > 0 ? '+' : '-'
  }${
    // set hours
    `${Math.floor(Math.abs(timezoneOffsetMins/60))}`.padStart(2, '0')
  }:${
    // set mins
    `${Math.abs(timezoneOffsetMins%60)}`.padStart(2, '0')
  }`;
}

function getTimezoneLocation() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function getTimezoneDescription() {
  // get full time string: e.g. 23:15:30 GMT+1000 (Australian Eastern Standard Time)
  // and return only the timezone information inside the brackets
  const timezoneDescription = new Date().toTimeString().match(/\((.+?)\)/);
  return timezoneDescription && timezoneDescription[1];
}

function getTimezoneDescriptionExtended() {
  const timezoneLocationString = getTimezoneLocation();
  const timezoneDescriptionString = getTimezoneDescription();
  // concat available strings
  // eg: (Australia/Brisbane: Australian Eastern Standard Time)
  return [timezoneLocationString, timezoneDescriptionString].filter(v => v).join(': ');
}

export function getTimezoneDescriptionFull() {
  const timezoneOffset = getTimezoneOffset();
  const timezoneExtendedString = getTimezoneDescriptionExtended();
  // append available timezone information to the timezone offset
  return `${timezoneOffset}${timezoneExtendedString ? ` (${timezoneExtendedString})` : ''}`;
}

// export a display component to render the user's value
export default function Timezone() {
  return getTimezoneDescriptionFull();
};
