
import React from 'react';
import { connect } from 'react-redux';

import { getUserPreferenceValue } from '../../modules/user/selectors';
import { getNormalisedPropsSelector } from './utils/selectorUtils';
import { FormattedValue } from './utils/displayUtils';
import { convertKwToHp, convertHpToKw } from './utils/converterUtils';

const displayProps = {
  default: {
    units: ' kW',
    digits: 2,
    convert: parseFloat,
    convertBack: parseFloat,
  },
  US: {
    units: ' hp',
    digits: 2,
    // power rating is often as a string
    // todo: something about that
    convert: value => convertKwToHp(parseFloat(value)),
    convertBack: value => convertHpToKw(parseFloat(value))
  },
};

const mapStateToProps = state => ({
  ...displayProps.default,
  ...displayProps[getUserPreferenceValue(state, 'units_system')],
  ...displayProps[getUserPreferenceValue(state, 'units_power')],
});

// export selectors
export const getUserDisplayPreference = getNormalisedPropsSelector(mapStateToProps);

const ConnectedValue = connect(mapStateToProps)(FormattedValue);
const ConnectedUnits = connect(mapStateToProps)(({ units }) => units.trim());

// export a display component to render the preferred value with default units displayed
export default props => (
  <ConnectedValue
    minimumFractionDigits={0}
    {...props}
    // convert value
    value={parseFloat(props.value)}
  />
);

// display just the units and not the value
export const Units = ConnectedUnits;
