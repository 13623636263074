import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import ContactSupportLink from './ContactSupportLink';
import Title from './Title';
import { InternalLink } from '../lib/utils';

export default class NotFound extends Component {
  render() {
    return (
      <Container fluid>
        <Title title={t('components.not-found.title')} />
        <p className="lead">
          <Trans
            i18nKey="components.not-found.requested-url"
            components={{
              continueLink: <InternalLink
                to={'/equipment/list'}
                text={t('components.not-found.continue-to')}
              />,
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="components.not-found.contact-support"
            components={{
              supportLink: <ContactSupportLink
                subject="Page not found"
              />
            }}
          />
        </p>
      </Container>
    );
  }
}
