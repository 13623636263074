
import React from 'react';
import { connect } from 'react-redux';
import { getUser } from '../modules/user/selectors';

function ContactSupportLink({
  subject,
  userEmail,
  userName,
  children,
  Component = 'a',
  ...props
}) {

  const queryParams = new URLSearchParams();
  // set the subject of the support request to be about the Dashboard
  // (users may submit general requests from the marketing or support
  // website, so this context should be useful to support staff)
  if (subject) {
    queryParams.set('subject', `Dashboard Enquiry: ${subject}`);
  }
  else {
    queryParams.set('subject', 'Dashboard Enquiry');
  }
  if (userEmail) {
    queryParams.set('email', userEmail);
  }
  if (userName) {
    // note: this is a terribly simple and inaccurate name splitter
    // see links like the following
    // link: https://stackoverflow.com/questions/259634/splitting-a-persons-name-into-forename-and-surname/259694#259694
    const [firstName, ...lastNames] = userName.split(' ');
    queryParams.set('firstname', firstName);
    if (lastNames.length > 0) {
      queryParams.set('lastname', lastNames.join(' '));
    }
  }
  const queryString = queryParams.toString();

  return (
    <Component
      {...props}
      href={`https://learn.movus.com.au/knowledge/kb-tickets/new${
        queryString ? `?${queryString}` : ''
      }`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Component>
  );
}

const mapStateToProps = (state) => {
  const user = getUser(state);
  return {
    userEmail: user && user.email,
    userName: user && user.name,
  };
};

export default connect(mapStateToProps)(ContactSupportLink);
