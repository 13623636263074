import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDeviceAlarmEvents, getDeviceAlarmEventsState } from '../selectors';
import { fetchDeviceAlarmEvents } from '../actions';

function useDeviceEvents(deviceId) {
  const dispatch = useDispatch();
  const deviceEvents = useSelector(state => getDeviceAlarmEvents(state, deviceId)) || [];
  const {loading, error, lastFetch} = useSelector(state => getDeviceAlarmEventsState(state, deviceId)) || {};
  const fetchDeviceEvents = useCallback(() => {
    dispatch(fetchDeviceAlarmEvents({id: deviceId}));
  }, [deviceId]);

  useEffect(() => {
    fetchDeviceEvents();
  }, [deviceId]);

  return {
    loading,
    error,
    lastFetch,
    deviceEvents,
  };
}

export default useDeviceEvents;