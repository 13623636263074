import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWebhooks } from '../actions';
import { getOrganisationWebhooks } from '../selectors';
import { getOrganisationId } from '../selectors';

export default function useWebhooks(params = {}) {
  const { fetch = true } = params;
  const dispatch = useDispatch();

  const organisationId = useSelector(getOrganisationId);
  const webhooks = useSelector(state => getOrganisationWebhooks(state, organisationId));
  const { endpoints = [], available = {} } = webhooks || {};
  const endpointsData = useMemo(() => endpoints.map(ep => ({
    ...ep,
    webhook: ep.webhook.map(value => ({
      key: value,
      ...available[value],
    })),
  })), [endpoints, available]);

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await dispatch(fetchWebhooks({ id: organisationId }));
    setLoading(false);
  }, [organisationId]);

  useEffect(() => {
    if(fetch && organisationId && !webhooks) {
      fetchData();
    }
  }, [organisationId]);

  return { loading, webhooks, endpointsData, fetchWebhooks: fetchData };
}
