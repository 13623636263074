
import { error } from '../lib/log';

/*
 * middleware to send and receive redux actions across same-origin tabs
 * add meta: { crossTab: true } flag to any actions that you want to send
 * to all listening tabs. The reducers work independently on each tab.
 */

/*
 * note: beware, never send redux state across tabs directly
 * redux-persist will have issues with migrations if you are sending state
 * between two tabs of different { _persist } versions. By just sending actions
 * (which you should try to not change the payloads of), the redux state can be
 * resolved correctly for each version in each tab. Whichever version is the last
 * saved is the one used for new tabs. If that happens to be the older version
 * redux-persist will run migrations as normal, and the tab will be fine.
 */

/*
 * Note: we use localStorage as the communication medium here because of compatibility reasons.
 * https://stackoverflow.com/questions/28230845/communication-between-tabs-or-windows#28230846
 * If performance becomes a factor, consider using a backwards compatible BroadcastChannel
 * library: https://github.com/pubkey/broadcast-channel. this was tested to work fine
 * in IE11 and Edge, both which default back to using IndexedDB as the communication medium,
 * but the library will use the native BroadcastChannel channel object if it is available.
 */

const messagingStorageKey = 'MOVUS:redux-actions';

export default store => {

  // create a listener for cross tab redux actions
  window.addEventListener('storage', handleStorageEvent, false);

  // handle messages from the storage area
  function handleStorageEvent(e) {

    // check if a value is passed that matches our namespace
    if (e && e.newValue && e.key === messagingStorageKey) {

      try {
        // parse may fail somehow under odd and maybe malicious conditions
        const action = JSON.parse(e.newValue);

        // check if this is a crossTab action
        if (action.type && action.meta && action.meta.crossTab) {

          // dispatch received action
          store.dispatch({
            ...action,
            meta: {
              ...action.meta,
              // but ensure this action doesn't generate new crossTab messages
              crossTab: false,
            },
          });
        }
      }
      catch(e) {
        error(new Error(`Could not parse ${messagingStorageKey} event`, e));
      }
    }
  }

  // return middleware handler
  return next => action => {

    // send a message if asked to
    if (action.meta && action.meta.crossTab) {
      // each localStorage of the same origin will receive the event generated by setItem
      // including the newValue of the item, even though the item was removed immediately
      localStorage.setItem(messagingStorageKey, JSON.stringify(action));
      localStorage.removeItem(messagingStorageKey);
    }

    // continue
    return next(action);
  };
};
