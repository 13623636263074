import { useSelector } from 'react-redux';
import { getCurrentOrganisationHasProductCode } from '../../organisation/selectors';
import useHomeView, { HOME_VIEW } from '../../../hooks/useHomeView';
import HomeStandardView from './HomeStandardView';
import HomePlantView from './HomePlantView';
import HomeManagementView from './HomeManagementView';
import HomeEnergyView from './HomeEnergyView';
// import Home from './Home';

function HomePage() {
  const hasSelectedableDash = useSelector(state => getCurrentOrganisationHasProductCode(state, 'select_dash'));
  const hasFitPower = useSelector(state => getCurrentOrganisationHasProductCode(state, 'fitpower'));

  const { view } = useHomeView();
  if(hasFitPower && view === HOME_VIEW.FITPOWER) return <HomeEnergyView />;
  if(hasSelectedableDash && view === HOME_VIEW.PLANT) return <HomePlantView />;
  if(hasSelectedableDash && view === HOME_VIEW.MANAGEMENT) return <HomeManagementView />;
  return <HomeStandardView />;
}

export default HomePage;