import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLocalUserPreference } from "../modules/user/actions";
import { getUser, getUserPreference } from '../modules/user/selectors';

export default function useIncludeSubGroup(key: string) {
  const preferenceKey = `includeSubGroup_${key || ''}`;
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const includeSubGroup = useSelector(state => !!getUserPreference(state, preferenceKey));
  const setIncludeSubGroup = useCallback((include: boolean) => {
    user && dispatch(setLocalUserPreference(user, preferenceKey, include));
  }, [user, dispatch]);

  return { includeSubGroup, setIncludeSubGroup };
}