
import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';

export default function NumberInput({ defaultValue, min, max, ...props }) {

  // handle numbers and strings as numbers
  function parse(value='') {
    // use Number as parseInt('10a') === 10
    const numberValue = Number(value);
    // remove invalid numbers and account for empty strings: Number('') === 0
    if (isNaN(numberValue) || value === '' || value === null || value === undefined || value === false) {
      return '';
    }
    else if (min !== undefined && min > numberValue) {
      return `${min}`;
    }
    else if (max !== undefined && max < numberValue) {
      return `${max}`;
    }
    else {
      return `${value}`;
    }
  }

  // handle both controlled and uncontrolled inputs
  const givenValue = props.value || defaultValue;

  // start value from givenValue
  const [value, setValue] = useState(() => parse(givenValue));

  // update value if a new givenValue is given
  // (happen often in controlled inputs)
  useEffect(() => setValue(parse(givenValue)), [givenValue]);

  // set value if changed by the user
  const onChange = useCallback(e => {
    // only update valid numbers from the user
    const validatedValue = parse(e.target.value);
    if (validatedValue === e.target.value) {
      // handle in a controlled fashion
      if (props.onChange) {
        // send both validated value and original event back
        props.onChange(e, validatedValue);
      }
      // handle in an uncontrolled fashion
      else {
        setValue(validatedValue);
      }
    }
  }, [setValue, props.onChange]);

  // note: this does not allow negative numbers as it does not handle the - sign.
  // we need to be able to check the text cursor position to decide that
  // and that is not available when using the type="number" input
  // recommended: stop using this component and switch to a form library to easily
  // allow bad data to be written, but have it easily understandable that the
  // current value has put the input into an error state.
  const onKeyPress = useCallback(e => {
    // check if the key is not a digit
    if (!e.key.match(/^[0-9]$/)) {
      // allow decimal separators
      if (e.key.match(/[,.]/)) {
        // but don't allow duplicate decimal separators
        if (value.match(/[,.]/)) {
          e.preventDefault();
        }
      // prevent characters that aren't digits or decimal separators
      } else {
        e.preventDefault();
      }
    }
  }, [value]);

  return (
    <Form.Control
      // defaults
      type="text"
      inputMode="numeric"
      // given
      {...props}
      // overrides
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  );
}
