
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Routes from './Routes';
import { trackPage } from './modules/app/actions';

const urlIdRegex = /(\/)([\d]+)(\/?)/g;
const urlMacRegex = /(\/)((?:[0-9A-Fa-f]{2}[:-]){5}[0-9A-Fa-f]{2})(\/?)/g;

function scrubPathName(pathname) {
  return pathname
    .replace(urlIdRegex, '$1[id]$3')
    .replace(urlMacRegex, '$1[mac]$3');
}

function RoutesWithTracking({ location, trackPage }) {

  // fire whenever the page location changes
  useEffect(() => {
    trackPage(scrubPathName(location.pathname));
  }, [location.pathname]);

  return (
    <Routes />
  );
}

// add location information for the useEffect hook
export default withRouter(connect(null, { trackPage })(RoutesWithTracking));
