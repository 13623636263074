
import React, { useState, useCallback } from 'react';
import { Card, Collapse, ListGroup, Col, Row, Dropdown } from 'react-bootstrap';
import './infoCard.scss';

export function InfoCardHeader({ collapsed, setCollapsed, children, buttons, headerClassName }) {

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  if (!children) {
    return null;
  }

  return (
    <Card.Header>
      {/* Header has flex justified content */}
      <h6 className={"info-card__header " + headerClassName}>{children}</h6>
      {/* nest content under a div to allow inline space */}
      {(setCollapsed || buttons) && <div className="info-card__header-buttons">
        {buttons}
        {setCollapsed && (
          <Dropdown
            onToggle={toggleCollapsed}
            drop={collapsed ? 'down' : 'up'}
            // make styling always appear as "not actively selected"
            show={false}
          >
            <Dropdown.Toggle
              size="sm"
              variant="outline-secondary"
              className="info-card__dropdown"
            />
          </Dropdown>
        )}
      </div>}
    </Card.Header>
  );
}

export function InfoCard(props) {

  const {
    collapsible,
    children,
    heading,
    HeaderComponent = InfoCardHeader,
    info,
    initialCollapsed,
    headerClassName,
  } = props;

  // set up state
  const [collapsed, setCollapsed] = useState(!!initialCollapsed);

  return collapsible ? (
    <Card className="mb-3 shadow-sm info-card info-card--collapsible">
      <HeaderComponent
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        infoCardChildren={children}
      >
        {heading}
      </HeaderComponent>
      <Collapse in={!collapsed}>
        <ListGroup variant="flush">
          {children}
        </ListGroup>
      </Collapse>
    </Card>
  ) : (
    <Card className="mb-3 shadow-sm info-card">
      <InfoCardHeader info={info} headerClassName={headerClassName}>
        {heading}
      </InfoCardHeader>
      <ListGroup variant="flush">
        {children}
      </ListGroup>
    </Card>
  );
}

export function InfoCardItem({ className, heading, info, children }) {
  return (
    <ListGroup.Item className={className}>
      <Row>
        {heading && (
          // note: that <Col> with no size specifications attempts to auto-calculate the
          // number of columns needed, then place the columns on multiple "row lines" if needed
          // This behaviour fails on IE, IE will always put a <Col> in the same "row line".
          //             ——————————————     ————————
          // eg. Chrome: |aaa   eee   | and | aaa  |
          //             ——————————————     | eee  |
          //                                ————————
          //             ——————————————     ————————
          //     IE:     |aaa   eee   | and | aa ee|
          //             ——————————————     | a  e |
          //                                ————————
          // with no text wrapping
          //             ——————————————     ————————
          //     IE:     |aaa   eee   | and | aaæee|
          //             ——————————————     ————————
          <Col>{heading}</Col>
        )}
        <Col>{children}</Col>
      </Row>
    </ListGroup.Item>
  );
}
