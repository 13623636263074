import React from 'react';
import withNavigationUserProps from '../components/withNavigationUserProps';

import EditUserPreferences from '../components/EditUserPreferences';
import UserPageLayout from '../components/UserPageLayout';

function EditUserPreferencesPage({ userId }) {
  return (
    <UserPageLayout>
      <EditUserPreferences />
    </UserPageLayout>
  );
}

export default withNavigationUserProps(EditUserPreferencesPage);
