import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Title from '../../../components/Title';
import RecentEvents from '../../../components/home/RecentEvents';
import HighestVibratingEquipment from '../../../components/home/HighestVibratingEquipment';
import HighestTemperatureEquipment from '../../../components/home/HighestTemperatureEquipment';
import NonReportingDevice from '../../../components/home/NonReportingDevice';
import EventStatus from '../../../components/home/EventStatus';
import HoursMonitored from '../../../components/home/HoursMonitored';
import DeviceStatus from '../../../components/home/DeviceStatus';
import "./home.scss";
import { SENSOR_TYPE } from '../../equipment/constants';

function HomeStandardView() {
  const { t } = useTranslation();
  return (
    <div className="home">
      <Container fluid>
        <Title title={t('Home')} />
        <Row noGutters>
          <Col xl={{span: 3, order: 1}} lg={6}>
            <EventStatus />
          </Col>
          <Col xl={{span: 6, order: 2}} lg={{span: 12, order: 'last'}}>
            <HoursMonitored />
          </Col>
          <Col xl={{span: 3, order: 3}} lg={6}>
            <DeviceStatus />
          </Col>
        </Row>
        <Row noGutters className="smaller-font-size-table-container">
          <Col xl={3} lg={6}>
            <RecentEvents />
          </Col>
          <Col xl={3} lg={6}>
            <HighestVibratingEquipment />
          </Col>
          <Col xl={3} lg={6}>
            <HighestTemperatureEquipment />
          </Col>
          <Col xl={3} lg={6}>
            <NonReportingDevice sensorType={SENSOR_TYPE.FITMACHINE} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeStandardView;