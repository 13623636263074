
/*
 * Track the basic anonymised info around what actions
 * the user was taking before an error happened
 */

import bugsnag, { scrubContext } from '../bugsnag';
import log from './log';

export const leaveActionBreadcrumb = (title, action, opts) => {
  // tag other keys by type
  const undefinedKeys = Object.entries(action).reduce((acc, [key, value]) => {
    if (typeof value === 'undefined') {
      acc.push(key);
    }
    return acc;
  }, []);
  bugsnag.leaveBreadcrumb(title, {
    // cannot use the key 'type' it is reserved:
    // https://docs.bugsnag.com/platforms/react-native/react-native/customizing-error-reports/#adding-detailed-breadcrumbs
    // rename action 'type'
    action: action.type,
    // passthrough API key strings
    ...(typeof action.endpoint === 'string' && {
      endpoint: `${`${action.method}`.toUpperCase()}${action.endpoint}`,
    }),
    // passthrough BLE command strings
    ...(typeof action.command === 'string' && {
      command: action.command,
    }),
    // add whitelisted scrubbed context
    ...scrubContext(action),
    // describe the top level shape of the action
    ...(undefinedKeys.length && {
      undefinedKeys: JSON.stringify(undefinedKeys)
    }),
    // add any given options
    ...opts,
  });
};

export default store => next => async action => {
  // tag only this breadcrumb as a 'request' type breadcrumb
  // so each request Redux Action can be seen as a consistent 'user action' in the breadcrumbs
  leaveActionBreadcrumb('Redux Action', action);

  // wait for an error or not
  try {
    return await next(action);
  }
  catch(e) {
    log.error(new Error(`Redux action error: ${action.type}`), e);
  }
};

