import moment from 'moment';
import { MonitoredHour } from '../../types';

const getDaysInPeriod = (startDate: string, endDate: string, dateFormatStr="YYYY-MM-DD", fromOldToNew = true): string[] => {
  const days: string[] = [];
  const diffDays = moment(endDate).diff(moment(startDate), 'days');
  for(let i = 0; i <= diffDays; i++) {
    const day = moment(endDate).subtract(i, 'days');
    days.push(day.format(dateFormatStr));
  }
  if(fromOldToNew) return days.reverse();
  return days;
};

// Convert the date array from API to a key-value pair, where key is the date and value is hours
// e.g. dateArr = [{date: '2021-11-29', hours: 1}, {date: '2021-11-30', hours: 2}]
// convertUsage(dateArr) -> { {'2021-11-29': 1}, {'2021-11-30': 2} }
const convertUsage = (dateArr: MonitoredHour[], dateFormatStr = "YYYY-MM-DD"): Record<string, number> => {
  const dateObj: Record<string, number> = {};
  for(const el of dateArr) {
    dateObj[moment(el.date).format(dateFormatStr)] = el.hours;
  }
  return dateObj;
};

export const getUsageInPeriod = (usage: MonitoredHour[], startDate: string, endDate: string, dateFormatStr: string, fromOldToNew: boolean): MonitoredHour[] => {
  const dateUsage: MonitoredHour[] = [];
  const days = getDaysInPeriod(startDate, endDate, dateFormatStr, fromOldToNew);
  const dateObj = convertUsage(usage, dateFormatStr);
  for(const day of days) {
    dateUsage.push({date: day, hours: dateObj[day] || 0});
  }
  return dateUsage;
};

// Give start date and end date, work out what day today is during the period and how many days in total during the period
// e.g. startDate = '2021-12-1', endDate = '2021-12-31' today is '2021-12-10' , it returns 10(The 10th day during the period) and 31
export const getDayInPeriod = (startDate: string, endDate: string, day: string = ''): [number, number] => {
  const today = day ? moment(day) : moment();
  return [today.diff(moment(startDate), 'days') + 1, moment(endDate).diff(moment(startDate), 'days') + 1];
};