import { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Dropdown, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { fetchDeviceStatus, setDeviceSelectedStatus } from '../actions';
import { getDeviceStatus, getDeviceStatusOptions, getDeviceSelectedStatusOptions } from '../selectors';
import { setLocalUserPreference } from '../../user/actions';
import { getUser, getUserPreferenceByKey } from '../../user/selectors';

function StatusFilter({onFilter}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const status = useSelector(getDeviceStatus);
  const statusOptions = useSelector(getDeviceStatusOptions, shallowEqual);
  const selectedOptions = useSelector(getDeviceSelectedStatusOptions, shallowEqual);
  const [ openDropdown, setOpenDropdown ] = useState(false);
  const user = useSelector(getUser);
  const userPreferenceFilteredOptions = useSelector(state => getUserPreferenceByKey(state, 'filtered_options'));

  const fetchData = async () => {
    if(status.length === 0) {
      try {
        await dispatch(fetchDeviceStatus());
      } catch {}
    }
    if(userPreferenceFilteredOptions) {
      dispatch(setDeviceSelectedStatus(userPreferenceFilteredOptions));
    }
  };

  useEffect(() => { fetchData(); }, []);
  useEffect(() => {
    if(statusOptions && statusOptions.length > 0) {
      onFilter(selectedOptions);
      persistUsersSelection(selectedOptions);
    }
  }, [selectedOptions]);

  const isSelectedAll = useMemo(() => {
    if(statusOptions.length !== selectedOptions.length) {
      return false;
    }
    for(const statusOption of statusOptions) {
      if(!selectedOptions.includes(statusOption)) {
        return false;
      }
    }
    return true;
  }, [statusOptions, selectedOptions]);

  useEffect(() => {
    const closeDropdown = () => {
      setOpenDropdown(false);
    };
    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, []);

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const persistUsersSelection = selectedOptions => dispatch(setLocalUserPreference(user, 'filtered_options', selectedOptions));

  const toggleFilterAll = (e) => {
    e.stopPropagation();
    if(e.target.checked) {
      dispatch(setDeviceSelectedStatus([...statusOptions]));
    } else {
      dispatch(setDeviceSelectedStatus([]));
    }
  };

  const toggleFilter = (e) => {
    e.stopPropagation();
    const option = e.target.getAttribute('data-option');
    const newSelectedOptions = [...selectedOptions];
    if(e.target.checked) {
      newSelectedOptions.push(option);
      dispatch(setDeviceSelectedStatus(newSelectedOptions));
    } else {
      const foundIndex = newSelectedOptions.indexOf(option);
      if(foundIndex >= 0) {
        newSelectedOptions.splice(foundIndex, 1);
      }
      dispatch(setDeviceSelectedStatus(newSelectedOptions));
    }
  };

  if(!statusOptions || statusOptions.length === 0) return null;

  return (
    <>
      <Dropdown
        show={openDropdown}
        onClick={(e) =>{ e.stopPropagation(); toggleDropdown(); }}
        style={{ position: 'static' }}
      >
        <Dropdown.Toggle size="sm" variant={isSelectedAll ? "outline-secondary" : "primary"}>
          {t('components.status-filter.filter-by-status')}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{paddingLeft: '10px'}}>
          <div aria-hidden="true" onClick={e => e.stopPropagation()}>
            <Form.Check
              type="checkbox"
              label={`(${t('components.status-filter.select-all')})`}
              id="checkbox-all"
              onChange={toggleFilterAll}
              checked={isSelectedAll}
              style={{fontSize: '.8rem'}}
            />
          </div>
          {status.map((item, index) => (
            <div aria-hidden="true" key={index} onClick={e => e.stopPropagation()}>
              <Form.Check
                key={index}
                type="checkbox"
                id={`checkbox-${item.option}`}
                data-option={item.option}
                label={item.option}
                onChange={toggleFilter}
                checked={item.selected}
                style={{fontSize: '.8rem'}}
              />
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default StatusFilter;
