import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ControlledCarousel from './Carousel';

export default function AlarmPossibleCausesImages({
  alarm,
  handleChildClick,
}) {

  return (
    <Row>
      <Col xs={12}>
        <ControlledCarousel
          handleChildClick={handleChildClick}
          images={alarm.possible_causes}
        />
      </Col>
    </Row>
  );
}
