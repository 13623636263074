import React, { Fragment, useState, useCallback, useMemo, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, OverlayTrigger, Tooltip, Dropdown, Row, Col, Form, Badge } from 'react-bootstrap';
import { IoIosResize, IoIosMove, IoIosArrowRoundForward, IoIosCog } from 'react-icons/io';
import { clipRectByRect } from 'echarts/lib/util/graphic';

import useBaseChart, {
  defaultOptions,
  getSymbolOfIcon,
  convertToTimestamp,
} from './BaseChart.js';
import BaseChartEditToolbar from './BaseChartEditToolbar.js';
import { popperConfigUpdateOnChange } from './BaseChartToolbar.js';
import RelearnModalForm from '../../modules/equipment/components/RelearnModalForm.js';
import ConfirmModal from '../ConfirmModal';

import { FaSignal } from 'react-icons/fa';
import { getFormattedValue } from '../values/utils/displayUtils.js';
import {
  getUserDisplayPreference as getUserTemperatureDisplayPreference,
} from '../values/Temperature.js';
import {
  getUserDisplayPreference as getUserRmsDisplayPreference,
} from '../values/Rms.js';

import { useComponentViewTracking } from '../../modules/app/hooks.js';

import {
  recalibrateAndRefetch,
  fetchDeviceNotificationThresholds,
  saveDeviceNotificationThreshold,
  deleteDeviceNotificationThreshold,
} from '../../modules/equipment/actions.js';

import {
  getDevice,
  getDeviceNotificationThresholds,
  getDeviceHasProductCode,
  getDeviceDisplayNotRunningRanges,
} from '../../modules/equipment/selectors.js';

import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
  getOrganisationRmsTitle,
  getOrganisationMm2Title,
  getCurrentOrganisationHasProductCode,
} from '../../modules/organisation/selectors';
import useDeviceAxisOptions from '../../modules/equipment/hooks/useDeviceAxisOptions.js';

import { isAdmin, getUserPreferenceValue } from '../../modules/user/selectors.js';
import ISOTableModal from './ISOTableModal.js';
import AxisOptionsForm from '../../modules/equipment/components/AxisOptionsForm.js';
import { convertCelsiusToFarenheit, convertMmToIn, convertFarenheitToCelsius, convertInToMm } from '../values/utils/converterUtils.js';
import { countLeadingZero } from '../lib/utils';
import { addToast } from '../Toaster.js';
import {
  addSensorTypeToSamples,
  addVibrationRms,
  convertRunningSamplesToBars,
} from '../../modules/equipment/utils';
import {
  DEFAULT_MODE_SETTINGS,
  displayReducer,
  initialDisplayModeStates,
} from './utils/displayModeUtils';
import { getRegressionPoints } from './utils/trendlinesUtils';
const IoIosResizePath = getSymbolOfIcon(IoIosResize);
const IoIosMovePath = getSymbolOfIcon(IoIosMove);
const IoIosArrowRoundForwardPath = getSymbolOfIcon(IoIosArrowRoundForward);

const colours = {
  black: '#434348', // black
  blue: '#7cb5ec', // blue
  blueLight: '#d6e9f9', // blue but less
  blueHeavy: '#2667a9', // blue but more
  grey: '#efefef', // grey
  darkGrey: '#cccccc', // slightly darker grey
  red: '#ff0000', // red
};

export const iconSize = 16;

export const whitePointBackground = {
  symbol: 'circle',
  itemStyle: {
    color: 'white',
  },
  label: {
    formatter: '{normal|}', // empty rich text to enable width and height
    rich: {
      normal: {
        backgroundColor: 'white',
        height: iconSize * 2,
        width: iconSize * 2,
        borderRadius: iconSize * 2,
        borderWidth: 0.75,
        borderColor: colours.red,
      }
    },
  },
  silent: true,
};

export const whiteNormalPointBackground = {
  ...whitePointBackground,
  label: {
    ...whitePointBackground.label,
    rich: {
      normal: {
        ...whitePointBackground.label.rich.normal,
        borderColor: colours.blueHeavy,
      }
    },
  },
};

export const whiteAlarmPointBackground = {
  ...whitePointBackground,
  label: {
    ...whitePointBackground.label,
    rich: {
      normal: {
        ...whitePointBackground.label.rich.normal,
        borderColor: colours.red,
      }
    },
  },
};

export const thresholdLine = {
  silent: true,
  symbol: 'none',
  lineStyle: {
    type: 'dotted',
    color: colours.red,
    width: 1,
  },
};

export const thresholdNormalLine = {
  ...thresholdLine,
  lineStyle: {
    ...thresholdLine.lineStyle,
    color: colours.blueHeavy,
  },
};

export const thresholdAlarmLine = {
  ...thresholdLine,
  lineStyle: {
    ...thresholdLine.lineStyle,
    color: colours.red,
  },
};

export const thresholdHighAlarmLine = {
  ...thresholdLine,
  lineStyle: {
    ...thresholdLine.lineStyle,
    color: colours.black,
  },
};

export const thresholdAlarmLineDisabled = {
  ...thresholdLine,
  lineStyle: {
    ...thresholdLine.lineStyle,
    color: colours.darkGrey,
  },
};

const thresholdArea = {
  silent: true,
  itemStyle: {
    opacity: 0.15,
  },
};

export const thresholdNormalArea = {
  ...thresholdArea,
  itemStyle: {
    ...thresholdArea.itemStyle,
    color: colours.blueHeavy,
  },
};

export const thresholdAlarmArea = {
  ...thresholdArea,
  itemStyle: {
    ...thresholdArea.itemStyle,
    color: colours.red,
  },
};

export const thresholdPoint = {
  symbolSize: iconSize,
};

export const thresholdNormalPoint = {
  ...thresholdPoint,
  itemStyle: {
    ...thresholdPoint.itemStyle,
    color: colours.blueHeavy,
  },
};

export const thresholdAlarmPoint = {
  ...thresholdPoint,
  itemStyle: {
    ...thresholdPoint.itemStyle,
    color: colours.red,
  },
};

export const thresholdHighAlarmPoint = {
  ...thresholdPoint,
  itemStyle: {
    ...thresholdPoint.itemStyle,
    color: colours.black,
  },
};

export const thresholdAlarmPointDisabled = {
  ...thresholdPoint,
  itemStyle: {
    ...thresholdPoint.itemStyle,
    color: colours.darkGrey,
  },
};

const dimmedRmsLineOpacity = 0.6;

// times in ms
const oneHour = 1000 * 60 * 60;
const oneDay = oneHour * 24;
const oneWeek = oneDay * 7;

export function clipValue(value, [min=-Infinity, max=Infinity]) {
  // return value between min and max, or min, or max
  return value > max ? max : value < min ? min : value;
}

function getInitialOptions(options=defaultOptions) {
  return {
    ...options,
    yAxis: [
      {
        id: 'temperature',
        type: 'value',
        name: 'Temperature',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 42,
        nameTextStyle: {
          color: colours.black,
        },
        color: colours.black,
      },
      {
        id: 'equipment_running',
        show: false,
        type: 'value',
        name: 'Running status',
      },
      {
        id: 'rms',
        type: 'value',
        name: 'RMS',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 43,
        nameTextStyle: {
          color: colours.blue,
        },
        color: colours.blue,
      },
      {
        id: 'mm2',
        show: false, // off by default
        type: 'value',
        name: 'RMS2',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 43,
        nameTextStyle: {
          color: colours.blueHeavy,
        },
        color: colours.blueHeavy,
      },
      {
        id: 'rms_trendline',
        type: 'value',
        name: 'RMS Trendline',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 43,
        nameTextStyle: {
          color: colours.blueHeavy,
        },
        color: colours.blueHeavy,
      },
      {
        id: 'running_cutoff',
        show: false, // Off on default/initial screen
        type: 'value',
        name: 'Running Cut-Off',
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 60,
        nameTextStyle: {
          color: colours.red,
        },
        color: colours.red,
        axisLabel: {
          show: false,
        },
      },
      {
        id: 'not_running_ranges',
        show: false,
        type: 'value',
        name: 'Not running ranges',
      },
    ],
    series: [
      {
        id: 'temperature',
        yAxisId: 'temperature',
        name: 'Temperature',
        type: 'line',
        lineStyle: { width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 2,
        // add editable hint
        markPoint: {
          symbol: IoIosArrowRoundForwardPath,
          // rotate the icon as needed dynamically
          symbolRotate: 0,
          symbolSize: iconSize,
          itemStyle: {
            color: colours.red,
          },
        },
        color: colours.black,
      },
      {
        id: 'equipment_running',
        yAxisId: 'equipment_running',
        name: 'Running status',
        type: 'line',
        itemStyle: {
          color: colours.black,
        },
        lineStyle: { width: 0 },
        showSymbol: false,
      },
      {
        id: 'rms',
        yAxisId: 'rms',
        name: 'RMS',
        type: 'line',
        lineStyle: { width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 1,
        yAxisIndex: 1,
        // add editable hint
        markPoint: {
          symbol: IoIosArrowRoundForwardPath,
          // rotate the icon as needed dynamically
          symbolRotate: 0,
          symbolSize: iconSize,
          itemStyle: {
            color: colours.red,
          },
        },
        color: colours.blue,
      },
      {
        id: 'mm2',
        yAxisId: 'mm2',
        name: 'RMS2',
        type: 'line',
        lineStyle: { width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 1,
        yAxisIndex: 1,
        // add editable hint
        markPoint: {
          symbol: IoIosArrowRoundForwardPath,
          // rotate the icon as needed dynamically
          symbolRotate: 0,
          symbolSize: iconSize,
          itemStyle: {
            color: colours.red,
          },
        },
        color: colours.blueHeavy,
      },
      {
        id: 'rms_trendline',
        yAxisId: 'rms_trendline',
        name: 'RMS Trendline',
        type: 'line',
        lineStyle: { width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 1,
        yAxisIndex: 1,
        // add editable hint
        markPoint: {
          symbol: IoIosArrowRoundForwardPath,
          // rotate the icon as needed dynamically
          symbolRotate: 0,
          symbolSize: iconSize,
          itemStyle: {
            color: colours.blueHeavy,
          },
        },
        color: colours.blueHeavy,
      },
      {
        id: 'running_cutoff',
        yAxisId: 'running_cutoff',
        name: 'Running Cut-Off',
        type: 'line',
        yAxisIndex: 2,
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: 'red',
            type: 'solid',
            width: 1,
          },
        },
        // add editable hint
        markPoint: {
          symbol: IoIosResizePath,
          // the resize icon is tilted diagonally, we rotate it back here
          symbolRotate: 45,
          symbolSize: iconSize,
          itemStyle: {
            color: colours.red,
          },
        },
        color: colours.red,
      },
      {
        id: 'not_running_ranges',
        yAxisId: 'not_running_ranges',
        name: 'Not running ranges',
        type: 'custom',
        // Don't switch to white on mouseover
        // https://stackoverflow.com/questions/63412235/echarts-how-to-prevent-changing-background-color-on-hover
        silent: true,
        // Place behind grid
        z: -1,
      },
    ],
    graphic: {
      elements: [],
    },
    dataZoom: {
      ...options.dataZoom,
      // If 'filtered' is used, this means not_running_ranges and rms_trendline won't render unless
      // start and end are both on chart.
      filterMode: 'none',
    }
  };
}

export function StatusBadge({ alarmState }) {
  const stateToVariantMap = {
    alarm: 'danger',
    normal: 'success',
  };
  const variant = stateToVariantMap[alarmState] || 'light';
  return <Badge variant={variant} className="mr-2 text-capitalize">{alarmState || 'unknown'}</Badge>;
}

function MeasuredDataChart({
  deviceId,
  device,
  deviceLearningStart,
  deviceRunningCutOff: deviceRunningCutOffProp,
  deviceNotificationThresholds: deviceNotificationThresholdsProp = [],
  displayTemperature,
  displayRms,
  rmsAvailable,
  mm2Available,
  rmsTitle = '', // by default hide the RMS data series to avoid name clash
  mm2Title = '',
  samples: samplesProp = [],
  recalibrateAndRefetch,
  fetchDeviceNotificationThresholds,
  saveDeviceNotificationThreshold,
  deleteDeviceNotificationThreshold,
  dateRange,
  setDateRange,
  userCanEditThresholds,
  hasHighAlarm,
  unitPreference,
  displayNotRunningRanges=true,
  ...props
}) {
  // todo: remove rms + mm2 -> DASH-1018
  rmsAvailable = true;
  mm2Available = false; // Temporary override during development phase. Remove with above.
  const displayTemperatureWithoutConversion = {...displayTemperature, convert: null};
  const displayRmsWithoutConversion = {...displayRms, convert: null};
  samplesProp = addSensorTypeToSamples(device, samplesProp);
  samplesProp = addVibrationRms(samplesProp);
  const samples = useMemo(() => {
    if(unitPreference === 'US') {
      return (samplesProp || []).map((sample) => ({
        ...sample,
        temperature: convertCelsiusToFarenheit(sample.temperature),
        vibration_rms: convertMmToIn(sample.vibration_rms),
        rms: convertMmToIn(sample.rms),
        rms2: convertMmToIn(sample.rms2),
      }));
    }
    return samplesProp || [];
  }, [samplesProp, unitPreference]);

  // Section for generating a range where equipment is not running. This is based on iterating over
  // the samples array and comparing between adjacent elements. From here, co-ordinates are
  // generated based on the graph size. Finally, these are used to render light grey 'rect' graphics
  // to show when the machine was not running.
  const [notRunningRanges, setNotRunningRanges] = useState([]);
  useEffect(() => {
    if (displayNotRunningRanges) setNotRunningRanges(convertRunningSamplesToBars(samples));
  }, [samples]);
  const [notRunningGraphics, setNotRunningGraphics] = useState([{}]);
  useEffect(() => {
    const newCoordinates = getCoordinates(notRunningRanges);
    const newGraphics = newCoordinates.map(coOrd => [
      coOrd.startTime,
      coOrd.x,
      coOrd.y,
      coOrd.width,
      coOrd.height,
    ]);
    setNotRunningGraphics(newGraphics);
  }, [notRunningRanges, dateRange]); // Re-calculate when the samples or date window changes

  const deviceRunningCutOff = useMemo(() => {
    if(unitPreference === 'US') return convertMmToIn(deviceRunningCutOffProp);
    return deviceRunningCutOffProp;
  }, [unitPreference, deviceRunningCutOffProp]);

  const deviceNotificationThresholds = useMemo(() => {
    if(unitPreference === 'US') {
      return (deviceNotificationThresholdsProp || []).map(threshold => ({
        ...threshold,
        alarm_value: threshold.attribute === 'tmp' ?
          convertCelsiusToFarenheit(threshold.alarm_value) :
          threshold.attribute === 'rms' ?
            convertMmToIn(threshold.alarm_value) :
            threshold.alarm_value,
        normal_value: threshold.attribute === 'tmp' ?
          convertCelsiusToFarenheit(threshold.normal_value) :
          threshold.attribute === 'rms' ?
            convertMmToIn(threshold.normal_value) :
            threshold.normal_value,
      }));
    }
    return deviceNotificationThresholdsProp || [];
  }, [deviceNotificationThresholdsProp, unitPreference]);

  const attributeSeriesIdsByAttribute = useMemo(() => {
    return {
      'rms': [rmsAvailable && 'rms', mm2Available && 'mm2'].filter(Boolean),
      'tmp': ['temperature'],
    };
  }, [
    rmsAvailable,
    mm2Available,
  ]);

  // fetch device notification thresholds on new device
  useEffect(() => {
    if (deviceId) {
      fetchDeviceNotificationThresholds({ id: deviceId });
    }
  }, [fetchDeviceNotificationThresholds, deviceId]);

  const [editRunningCutoffMode, setEditRunningCutoffMode] = useState(false);
  const exitEnterRunningCutoffMode = useCallback(() => setEditRunningCutoffMode(false), []);
  const toggleRunningCutoffMode = useCallback(() => {
    setEditRunningCutoffMode(editRunningCutoffMode => !editRunningCutoffMode);
  }, []);

  const [modalRunningCutoff, setModalRunningCutoff] = useState(deviceRunningCutOff);
  useEffect(() => setModalRunningCutoff(deviceRunningCutOff), [deviceRunningCutOff]);

  // reset edit running cut-off value if the edit mode is exited
  useEffect(() => {
    if (!editRunningCutoffMode) {
      setModalRunningCutoff(deviceRunningCutOff);
    }
  }, [deviceRunningCutOff, editRunningCutoffMode]);

  const [editLearningStartMode, setEditLearningStartMode] = useState(false);
  const exitEnterLearningStartMode = useCallback(() => setEditLearningStartMode(false), []);
  const toggleLearningStartMode = useCallback(() => {
    setEditLearningStartMode(editLearningStartMode => !editLearningStartMode);
  }, [setEditLearningStartMode]);

  const [modalLearningStart, setModalLearningStart] = useState(deviceLearningStart);
  useEffect(() => setModalLearningStart(deviceLearningStart), [deviceLearningStart]);

  // reset edit learning start value if the edit mode is exited
  useEffect(() => {
    if (!editLearningStartMode) {
      setModalLearningStart(deviceLearningStart);
    }
  }, [deviceLearningStart, editLearningStartMode]);

  const exitAllEditModes = useCallback(() => {
    setEditRunningCutoffMode(false);
    setEditLearningStartMode(false);
  }, []);

  // track usage
  useComponentViewTracking('Device Chart Edit Running Cutoff', editRunningCutoffMode && 'deviceId', { deviceId });
  useComponentViewTracking('Device Chart Edit Learning Start', editLearningStartMode && 'deviceId', { deviceId });

  // if the learning start mode is started, ensure the chart can see that date value
  useEffect(() => {
    if (editLearningStartMode && modalLearningStart) {
      if (modalLearningStart - oneWeek < dateRange.startTime) {
        setDateRange({ startTime: modalLearningStart - oneWeek });
      }
      else if (modalLearningStart + oneWeek > dateRange.endTime) {
        setDateRange({ endTime: modalLearningStart + oneWeek });
      }
    }
  }, [
    dateRange.startTime,
    dateRange.endTime,
    setDateRange,
    editLearningStartMode,
    modalLearningStart,
  ]);

  const [showRunningCutoffModal, setShowRunningCutoffModal] = useState(false);
  const openRunningCutoffModal = useCallback(() => setShowRunningCutoffModal(true), []);
  const [showLearningStartModal, setShowLearningStartModal] = useState(false);
  const openLearningStartModal = useCallback(() => setShowLearningStartModal(true), []);
  const openAllModals = useCallback(() => {
    setShowRunningCutoffModal(true);
    setShowLearningStartModal(true);
  }, []);

  const closeModal = useCallback(success => {
    // close all modals
    setShowRunningCutoffModal(false);
    setShowLearningStartModal(false);
    // but also exit edit mode if closed with a successful response
    if (success) {
      setEditRunningCutoffMode(false);
      setEditLearningStartMode(false);
    }
  }, []);

  // setup notification threshold editing
  const [editNotificationThresholdValue, setEditNotificationThresholdValue] = useState();
  // find currently selected notification threshold
  const selectedNotificationThresholdValue = useMemo(() => {
    const findId = editNotificationThresholdValue && editNotificationThresholdValue.id;
    return findId
      ? deviceNotificationThresholds.find(({ id }) => id === findId)
      : undefined;
  }, [deviceNotificationThresholds, editNotificationThresholdValue]);

  const [editNotificationThresholdMode, setEditNotificationThresholdMode] = useState(false);
  const exitNotificationThresholdMode = useCallback(() => setEditNotificationThresholdMode(false), []);
  const [focusValue, setFocusValue] = useState();

  // start editing if a threshold is selected
  useEffect(() => {
    setEditNotificationThresholdMode(Boolean(editNotificationThresholdValue));
  }, [editNotificationThresholdValue]);

  // stop editing if a different device is selected
  useEffect(exitNotificationThresholdMode, [deviceId]);

  // deselect a threshold if the edit mode is deselected
  useEffect(() => {
    if (!editNotificationThresholdMode) {
      setEditNotificationThresholdValue();
    }
  }, [editNotificationThresholdMode]);

  // restrict values of thresholds
  useEffect(() => {
    setEditNotificationThresholdValue(threshold => {
      const {
        attribute,
        alarm_value,
        normal_value,
      } = threshold || {};
      // if RMS is less than 0, enforce it to be 0
      if (attribute === 'rms' && (alarm_value < 0 || normal_value < 0)) {
        return {
          ...threshold,
          alarm_value: alarm_value > 0 ? alarm_value : 0,
          // only adjust normal_value if it is defined
          normal_value: normal_value !== undefined
            ? normal_value > 0 ? normal_value : 0
            : undefined,
        };
      }
      return threshold;
    });
  }, [editNotificationThresholdValue]);

  // track usage of the alarm threshold edit mode
  useComponentViewTracking('Device Chart Edit Notification Thresholds', editNotificationThresholdMode && 'deviceId', { deviceId, editNotificationThresholdValue });
  // console.log(editNotificationThresholdValue);
  const saveThreshold = useCallback(() => {
    // pick parts from threshold value
    const {
      id,
      enabled,
      attribute,
      // default to greater than
      alarm_comparison = 'gt',
      alarm_value,
      // default to opposite of alarm comparison
      normal_comparison = alarm_comparison === 'gt' ? 'lt' : 'gt',
      normal_value,
      high_alarm,
    } = editNotificationThresholdValue || {};

    if(alarm_comparison === 'gt' && normal_value > alarm_value) {
      return addToast({
        header: `Normal value(${normal_value}) cannot be bigger than alarm value(${alarm_value})`,
      });
    }
    if(alarm_comparison === 'lt' && normal_value < alarm_value) {
      return addToast({
        header: `Alarm value(${alarm_value}) cannot be bigger than normal value(${normal_value})`
      });
    }

    const payload = {
      id,
      enabled,
      attribute,
      alarm_comparison,
      alarm_value: unitPreference === 'US' ?
        (attribute === 'tmp' ?
          parseFloat(convertFarenheitToCelsius(alarm_value).toFixed(3)) :
          attribute === 'rms' ?
            parseFloat(convertInToMm(alarm_value).toFixed(3)) :
            alarm_value
        ) :
        alarm_value,
      high_alarm,
      // only set normal values if they are not the alarm value
      // this will allow the user to move both values together
      // which seems expected when presented with this
      ...normal_value !== alarm_value && {
        normal_comparison,
        normal_value: unitPreference === 'US' && normal_value !== undefined ?
          (attribute === 'tmp' ?
            parseFloat(convertFarenheitToCelsius(normal_value).toFixed(3)) :
            attribute === 'rms' ?
              parseFloat(convertInToMm(normal_value).toFixed(3)) :
              normal_value
          ) :
          normal_value,
      },
    };

    return saveDeviceNotificationThreshold({ id: deviceId }, payload)
      .then(() => Promise.all([
        // fetch new thresholds
        fetchDeviceNotificationThresholds({ id: deviceId }),
      ]))
      .then(() => {
        // exit editing
        exitNotificationThresholdMode();
      });
  }, [
    deviceId,
    editNotificationThresholdValue,
    exitNotificationThresholdMode,
  ]);

  const deleteThreshold = useCallback(() => {
    return deleteDeviceNotificationThreshold({ id: deviceId }, editNotificationThresholdValue)
      .then(() => Promise.all([
        // fetch new thresholds
        fetchDeviceNotificationThresholds({ id: deviceId }),
      ]))
      .then(() => {
        // exit editing
        exitNotificationThresholdMode();
      });
  }, [
    deviceId,
    editNotificationThresholdValue,
    exitNotificationThresholdMode,
  ]);

  const [axisOptions, setAxisOptions] = useState({});
  const hasCustomAxisOptions = Object.keys(axisOptions).length > 0;
  const [axisOptionsMode, setAxisOptionsMode] = useState(false);
  const toggleAxisOptionsMode = useCallback(() => {
    setAxisOptionsMode(!axisOptionsMode);
  }, [setAxisOptionsMode, axisOptionsMode]);
  const exitSetAxisOptionsMode = useCallback(() => setAxisOptionsMode(false), []);
  const { axisOptions: userAxisOptions } = useDeviceAxisOptions(deviceId, { fetch: true });
  useEffect(() => {
    if(userAxisOptions) {
      setAxisOptions(userAxisOptions);
    }
  }, [userAxisOptions]);

  // Section for managing states of showing/hiding Series and Legends
  const [displayMode, dispatchDisplay] = useReducer(
    displayReducer,
    initialDisplayModeStates['initialDisplayState']
  );

  const handleDisplay = (displayState) => {
    dispatchDisplay({
      type: DEFAULT_MODE_SETTINGS,
      options: {
        current: displayState,
      }
    });
  };

  useEffect(() => {
    if (editNotificationThresholdValue) {
      if (editNotificationThresholdValue.attribute === 'rms') handleDisplay('rmsDisplayState');
      else if (editNotificationThresholdValue.attribute === 'tmp') handleDisplay('tmpDisplayState');
    }
    else handleDisplay('initialDisplayState');
  }, [editNotificationThresholdValue]);
  useEffect(() => {
    if (editRunningCutoffMode) handleDisplay('rcoDisplayState');
    else if (editLearningStartMode && !editRunningCutoffMode) handleDisplay('learningDisplayState');
    else handleDisplay('initialDisplayState');
  }, [editRunningCutoffMode, editLearningStartMode]); // Both can be enabled at once
  useEffect(() => {
    if (axisOptionsMode) handleDisplay('axisDisplayState');
    else handleDisplay('initialDisplayState');
  }, [axisOptionsMode]);

  useEffect(() => {
    const chart = getChart();
    if (chart) {
      chart.on('legendselectchanged', (params) => {
        const saveSelected = chart.getOption().legend[0].selected;
        if (params.name === 'RMS Trendline') {
          // Preserve current legend choices for unrelated. No consideration of display mode. Only
          // cares about what happens when 'RMS Trendline' is changed.
          const trendlineOn = params.selected['RMS Trendline'];
          dispatchDisplay({
            type: 'selectRmsTrendline',
            options: {
              selectRms: saveSelected['RMS'],
              selectRmsTrendline: trendlineOn,
              selectRco: saveSelected['Running Cut-Off'],
            },
          });
        } else {
          // Save all choices to state.
          dispatchDisplay({
            type: 'selectOthers',
            options: {
              selectTemperature: saveSelected['Temperature'],
              selectRms: saveSelected['RMS'],
              selectRmsTrendline: saveSelected['RMS Trendline'],
              selectRco: saveSelected['Running Cut-Off'],
            },
          });
        }
      });
    }
  }, []);

  // Section for calculating and displaying RMS trendlines.
  const [regressionPoints, setRegressionPoints] = useState([]);
  useEffect(() => {
    const onScreenSamples = filterOffScreenSamples(samples);
    const newRegressionPoints = getRegressionPoints(onScreenSamples);
    setRegressionPoints(newRegressionPoints);
  }, [samples, dateRange]); // Re-calculate when the samples or date window changes

  const [BaseChart, {
    getChart,
    useChartUpdateEffect,
    useChartUpdateOnCustomEvent,
  }] = useBaseChart(getInitialOptions);

  const updateSeriesAndUnits = useCallback(chartOptions => {
    return {
      legend: {
        data: [
          // Per table above, some Legends can be shown/hidden based on Series state e.g. TMP, RCO.
          // todo: remove rms + mm2 -> DASH-1018
          displayMode.displayTemperatureData && { name: 'Temperature', textStyle: { color: colours.black } },
          { name: 'RMS', textStyle: { color: colours.blue } },
          { name: 'RMS Trendline', textStyle: { color: colours.blueHeavy } },
          displayMode.displayRunningCutoffData && { name: 'Running Cut-Off', textStyle: { color: colours.red } },
        ].filter(v => v),
        selected: {
          'Temperature': displayMode.selectTemperature,
          'RMS': displayMode.selectRms,
          'RMS Trendline': displayMode.selectRmsTrendline,
          'Running Cut-Off': displayMode.selectRco,
        }
      },
      tooltip: {
        formatter: function(series) {
          const running_status = series.find(({ seriesId }) => seriesId === 'equipment_running');
          const temperature = series.find(({ seriesId }) => seriesId === 'temperature');
          const rms = series.find(({ seriesId }) => seriesId === 'rms');
          const mm2 = series.find(({ seriesId }) => seriesId === 'mm2');
          return (
            `${moment(series[0].value[0]).format('LLLL')}${
              '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
            }${
              running_status
                ? `Running status: ${
                  running_status.value[1] ? "ON" : "OFF"
                }<br/>`
                : ''
            }${
              temperature
                ? `Temperature: ${
                  getFormattedValue(temperature.value[1], displayTemperatureWithoutConversion)
                }<br/>`
                : ''
            }${
              rms
                ? `RMS: ${
                  getFormattedValue(rms.value[1], displayRmsWithoutConversion)
                }<br/>`
                : ''
            }${
              mm2 && mm2.value[1]
                ? `${mm2Title}: ${
                  mm2.value[1].toFixed(2)
                }<br/>`
                : ''
            }`
          );
        },
      },
      yAxis: [
        {
          show: displayMode.displayTemperatureData,
          id: 'temperature',
          name: `Temperature (${displayTemperature.units.trim()})`,
          axisLabel: {
            formatter: value => getFormattedValue(value, {
              ...displayTemperatureWithoutConversion,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              displayUnits: false,
            }),
          },
        },
        {
          show: !!rmsAvailable,
          id: 'rms',
          name: `RMS (${displayRms.units.trim()})`,
          nameTextStyle: {
            padding: [0, 0, 0, mm2Available ? 90 : 0],
          },
          axisLabel: {
            formatter: value => getFormattedValue(value, {
              ...displayRmsWithoutConversion,
              // there is only space for two digits here :/ dictated by nameGap
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
              displayUnits: false,
            }),
          },
        },
        {
          show: !!mm2Available,
          id: 'mm2',
          name: mm2Title,
          nameTextStyle: {
            padding: [0, rmsAvailable ? 80 : 0, 0, 0],
          },
          axisLabel: !rmsAvailable ? {
            formatter: value => getFormattedValue(value, {
              // mm2 is in "mm/s" but we don't display that
              // units: ' mm/s',
              digits: 2,
              // there is only space for two digits here :/ dictated by nameGap
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
              displayUnits: false,
            }),
          } : {
            show: false,
          },
        },
        {
          show: false,
          id: 'rms_trendline',
        },
      ].filter(Boolean),
      series: [
        {
          id: 'rms',
          name: rmsTitle,
        },
        {
          id: 'mm2',
          name: mm2Title,
        },
      ],
    };
  }, [
    rmsAvailable,
    mm2Available,
    rmsTitle,
    mm2Title,
    ...Object.values(displayRms),
    ...Object.values(displayTemperature),
    editNotificationThresholdValue && editNotificationThresholdValue.attribute,
    displayMode.displayRunningCutoffData,
    displayMode.displayTemperatureData,
    displayMode.displayRmsSelectedData,
    displayMode.selectTemperature,
    displayMode.selectRms,
    displayMode.selectRmsTrendline,
    displayMode.selectRco,
  ]);

  // update when series and units change
  useChartUpdateEffect(updateSeriesAndUnits);

  const thresholdExtents = useMemo(() => {
    const { attribute, alarm_value, normal_value } = editNotificationThresholdValue || {};
    return !attribute ? {} : {
      [attribute]: [
        // 10% either way (works for negative or positive values)
        alarm_value * 0.9,
        alarm_value * 1.1,
        normal_value * 0.9,
        normal_value * 1.1,
      ].filter(v => !isNaN(v))
    }; // filter out NaN values
  }, [editNotificationThresholdValue]);

  const minTemp = useMemo(() => {
    const temp = Math.min(
      // default to 0°C if no samples are found
      ...samples.length > 0 ? samples.map(s => s.temperature) : [0],
      ...thresholdExtents['tmp'] || [],
      ...deviceNotificationThresholds
        .filter(({ attribute }) => attribute === 'tmp')
        .map(({ alarm_value }) => alarm_value),
    );
    // allow minimum to go below 0, but default to 0 otherwise
    return isNaN(axisOptions.minTemp) ? Math.floor(temp) : axisOptions.minTemp;
  }, [samples, thresholdExtents, deviceNotificationThresholds, axisOptions.minTemp]);

  const maxTemp = useMemo(() => {
    const temp = Math.max(
      // default to 20°C if no samples are found
      ...samples.length > 0 ? samples.map(s => s.temperature) : [20],
      ...thresholdExtents['tmp'] || [],
      ...deviceNotificationThresholds
        .filter(({ attribute }) => attribute === 'tmp')
        .map(({ alarm_value }) => alarm_value),
    );
    return isNaN(axisOptions.maxTemp) ? Math.ceil(temp) : axisOptions.maxTemp;
  }, [samples, thresholdExtents, deviceNotificationThresholds, axisOptions.maxTemp]);

  const minRms = useMemo(() => isNaN(axisOptions.minRms) ? 0 : axisOptions.minRms, [axisOptions.minRms]);
  const maxRms = useMemo(() => {
    // Calculate max rms and max vibration_rms separately
    const maxRmsTemp = Math.max(
      ...samples.map(s => s.rms)
    );
    const maxRmsVibrationTemp = Math.max(
      ...samples.map(s => s.vibration_rms)
    );
    // todo: remove rms + mm2 -> DASH-1018
    // const maxRms2Temp = 0; mm2Available ? Math.max(
    //   ...samples.map(s => s.rms2)
    // ) : 0;
    const maxRmsInSamples = Math.max(
      displayMode.displayRmsData ? maxRmsTemp : maxRmsVibrationTemp,
    );
    const leadingZero = countLeadingZero(maxRmsInSamples);
    const ratio = Math.pow(10, leadingZero + 1);
    const rms = Math.max(
      maxRmsInSamples,
      // if no samples, show running cut-off on the chart (but not on top line)
      // or a small non-zero backup value
      (modalRunningCutoff || 0) * 1.1 || 1 / ratio,
      ...thresholdExtents['rms'] || [],
      ...deviceNotificationThresholds
        .filter(({ attribute }) => attribute === 'rms')
        .map(({ alarm_value }) => alarm_value),
    );
    return isNaN(axisOptions.maxRms) ? Math.ceil(rms*ratio)/ratio : axisOptions.maxRms;
  }, [samples, thresholdExtents, deviceNotificationThresholds, modalRunningCutoff, axisOptions.maxRms, mm2Available, displayMode.displayRmsData]);
  const tempInterval = useMemo(() => isNaN(axisOptions.intTemp) ? undefined : axisOptions.intTemp, [axisOptions.intTemp]);
  const rmsInterval = useMemo(() => isNaN(axisOptions.intRms) ? undefined: axisOptions.intRms, [axisOptions.intRms]);

  const updateSamplesAndAxes = useCallback(() => {
    return {
      yAxis: [
        {
          id: 'equipment_running',
          min: minRms,
          max: maxRms,
          interval: rmsInterval,
        },
        {
          id: 'temperature',
          min: minTemp,
          max: maxTemp,
          interval: tempInterval,
        },
        {
          id: 'rms',
          // todo: remove rms + mm2 -> DASH-1018
          // show: !!rmsAvailable,
          min: minRms,
          max: maxRms,
          interval: rmsInterval,
        },
        {
          id: 'mm2',
          show: !!mm2Available,
          min: minRms,
          max: maxRms,
          interval: rmsInterval,
        },
        {
          id: 'rms_trendline',
          min: minRms,
          max: maxRms,
          interval: rmsInterval,
        },
        {
          id: 'running_cutoff',
          show: displayMode.displayRunningCutoffData,
          min: minRms,
          max: maxRms,
          interval: rmsInterval,
        },
        {
          id: 'not_running_ranges',
          min: minRms,
          max: maxRms,
          interval: rmsInterval,
        },
      ],
      series: [
        {
          id: 'equipment_running',
          yAxisId: 'equipment_running',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.equipment_running,
          ]),
        },
        {
          id: 'temperature',
          yAxisId: 'temperature',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.temperature,
          ]),
        },
        displayMode.displayRmsData && {
          id: 'rms',
          yAxisId: 'rms',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.rms,
          ]),
          lineStyle: {
            opacity: displayMode.selectRmsTrendline ? dimmedRmsLineOpacity : 1,
          },
        },
        // todo: remove rms + mm2 -> DASH-1018
        // {
        //   id: 'mm2',
        //   data: !mm2Available ? [] : samples.map(sample => [
        //     convertToTimestamp(sample.sample_time),
        //     sample.rms2,
        //   ]),
        // },
        displayMode.displayRmsSelectedData && {
          id: 'rms',
          yAxisId: 'rms',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.vibration_rms,
          ]),
          lineStyle: {
            opacity: displayMode.selectRmsTrendline ? dimmedRmsLineOpacity : 1,
          },
        },
        {
          id: 'rms_trendline',
          yAxisId: 'rms_trendline',
          data: regressionPoints,
        },
        {
          id: 'not_running_ranges',
          yAxisId: 'not_running_ranges',
          // Don't switch to white on mouseover
          tooltip: {
            show: false
          },
          renderItem: renderItem,
          // Set the first dimension explicitly, or it gets a NaN for all of them.
          // https://github.com/apache/echarts/issues/9962#issuecomment-468886340
          // https://echarts.apache.org/en/option.html#series-custom.dimensions
          dimensions: [
            {'type': 'time'},
          ],
          data: notRunningGraphics,
        },
      ],
    };
  }, [
    minTemp,
    maxTemp,
    minRms,
    maxRms,
    rmsAvailable,
    mm2Available,
    // recompute when samples or running cutoff changes
    samples,
    notRunningGraphics,
    modalRunningCutoff,
    editNotificationThresholdValue,
    axisOptions,
    tempInterval,
    rmsInterval,
    displayMode.displayRunningCutoffData,
    displayMode.displayRmsData,
    displayMode.displayRmsSelectedData,
    displayMode.selectRmsTrendline,
    regressionPoints,
  ]);

  // update when samples change
  useChartUpdateEffect(updateSamplesAndAxes);

  function getCoordinates(ranges) {
    return ranges.map((range => {
      const startTime = range['start'].sample_time;
      const x = getXFromData(startTime);
      const width = getXFromData(range['end'].sample_time) - x;
      return {
        // Need to set the time so that the dimensions in renderItem know what to deal with.
        // Using the four coordinates ints/floats alone doesn't work. It gets a NaN for api.value.
        startTime: startTime,
        x: x,
        y: getYMinPoint(),
        width: width,
        height: getYFromData(minRms),
      };
    }));
  }

  function renderItem(params, api) {
    // Starting point example:
    // https://echarts.apache.org/examples/en/editor.html?c=custom-profit
    // Doco:
    // https://echarts.apache.org/en/tutorial.html#Custom%20Series
    // Using array indices is ugly but api.value only works with scalar values.
    // https://echarts.apache.org/en/option.html#series-custom.dimensions
    const x = api.value(1);
    const y = api.value(2);
    const width = api.value(3);
    const height = api.value(4);
    const rectShape = clipRectByRect(
      {
        x: x,
        y: y,
        width: width,
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );

    return rectShape && {
      type: 'rect',
      // Speeds up transition animation because Y never changes.
      // https://echarts.apache.org/en/option.html#series-custom.renderItem.return_group.transition
      transition: ['x'],
      shape: {
        ...rectShape
      },
      style: {
        fill: colours.grey,
      },
    };
  }

  function filterOffScreenSamples(samples) {
    const XMinPoint = getXMinPoint();
    const XMaxPoint = getXMaxPoint();
    return samples.filter((sample => {
      const x = getXFromData(sample.sample_time);
      return x >= XMinPoint && x <= XMaxPoint;
    }));
  }

  function getXMinPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return grid.left;
      }
    }
  }

  function getXMidPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        // position x directly in the middle of the chart (average of left and right pixel)
        return (grid.left + chart.getWidth() - grid.right) / 2;
      }
    }
  }

  function getXMaxPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return chart.getWidth() - grid.right;
      }
    }
  }

  function getYMidPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        // position x directly in the middle of the chart (average of left and right pixel)
        return (grid.top + chart.getHeight() - grid.bottom) / 2;
      }
    }
  }

  function getYMinPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return grid.top;
      }
    }
  }

  function getXFromData(dataX, seriesId='running_cutoff', chart=getChart()) {
    if (chart) {
      // position y as the current deviceRunning Cutoff
      return chart.convertToPixel({ seriesId }, [dataX, 0])[0];
    }
  }

  function getYFromData(dataY, seriesId='running_cutoff', chart=getChart()) {
    if (chart) {
      // position y as the current device Running Cutoff
      return chart.convertToPixel({ seriesId }, [0, dataY])[1];
    }
  }

  function convertRunningCutoffFromPixel(pixel, seriesId='running_cutoff', chart = getChart()) {
    const ratio = unitPreference === 'US' ? 10000 : 100;
    if (chart) {
      const point = chart.convertFromPixel({ seriesId }, pixel);
      const runningCutoff = Math.round(ratio * point[1])/ratio;
      if(!isNaN(axisOptions.maxRms) && runningCutoff > axisOptions.maxRms) {
        return axisOptions.maxRms;
      }
      if(!isNaN(axisOptions.minRms) && runningCutoff < axisOptions.minRms) {
        return axisOptions.minRms;
      }
      // ensure no negative running cutoff values are used
      return runningCutoff > 0 ? runningCutoff : 0;
    }
  }

  function convertLearningStartFromPixel(pixel, seriesId='running_cutoff', chart = getChart()) {
    if (chart) {
      const point = chart.convertFromPixel({ seriesId }, pixel);
      return Math.round(point[0]);
    }
  }

  function convertNotificationThresholdFromPixel(pixel, seriesId, chart = getChart()) {
    if (chart) {
      const point = chart.convertFromPixel({ seriesId }, pixel);
      const value = Math.round(1000 * point[1])/1000;
      if(seriesId === 'rms') {
        if(!isNaN(axisOptions.maxRms) && value > axisOptions.maxRms) return axisOptions.maxRms;
        if(!isNaN(axisOptions.minRms) && value < axisOptions.minRms) return axisOptions.minRms;
      }

      if(seriesId === 'temperature') {
        if(!isNaN(axisOptions.maxTemp) && value > axisOptions.maxTemp) return axisOptions.maxTemp;
        if(!isNaN(axisOptions.minTemp) && value < axisOptions.minTemp) return axisOptions.minTemp;
      }
      // round the number a bit
      return value;
    }
  }

  const updateRunningCutoff = useCallback(() => {
    return {
      yAxis: [
        {
          id: 'running_cutoff',
          name: `Running Cut-Off${
            modalRunningCutoff >= 0
              ? `: ${getFormattedValue(modalRunningCutoff, {
                ...displayRmsWithoutConversion,
                // add more significant digits if relevant
                minimumFractionDigits: 1,
                maximumFractionDigits: displayRms.maximumFractionDigits + 3,
              })}`
              : ''
          }`,
        },
      ],
      series: [
        {
          id: 'running_cutoff',
          markLine: {
            data: modalRunningCutoff >= 0 ? [
              editLearningStartMode && [
                { xAxis: modalLearningStart, yAxis: 'min' },
                { xAxis: modalLearningStart, yAxis: 'max' },
              ],
              [
                { xAxis: 'min', yAxis: modalRunningCutoff },
                { xAxis: 'max', yAxis: modalRunningCutoff },
              ],
            ].filter(Boolean) : [],
          },
          // add editable hint
          markPoint: {
            data: [
              // add lowest priority points first for a lower z-index
              ...editRunningCutoffMode && editLearningStartMode ? [
                {
                  coord: [modalLearningStart, modalRunningCutoff],
                  symbol: IoIosMovePath,
                  symbolRotate: 0,
                  symbolSize: 20,
                  ...whitePointBackground,
                },
                {
                  coord: [modalLearningStart, modalRunningCutoff],
                  symbol: IoIosMovePath,
                  symbolRotate: 0,
                  symbolSize: 20,
                },
              ] : [],
              ...editLearningStartMode ? [
                { xAxis: modalLearningStart, y: getYMidPoint(), symbolRotate: -45, ...whitePointBackground },
                { xAxis: modalLearningStart, y: getYMidPoint(), symbolRotate: -45 },
              ] : [],
              ...editRunningCutoffMode ? [
                { x: getXMidPoint(), yAxis: modalRunningCutoff, ...whitePointBackground },
                { x: getXMidPoint(), yAxis: modalRunningCutoff },
              ] : [],
            ].filter(Boolean),
          },
        },
      ],
    };
  }, [
    ...Object.values(displayRms),
    editRunningCutoffMode,
    modalRunningCutoff,
    editLearningStartMode,
    modalLearningStart,
  ]);

  // update when running cut-off changes
  useChartUpdateEffect(updateRunningCutoff);
  // and when chart is resized
  useChartUpdateOnCustomEvent('resize', updateRunningCutoff);

  const updateThresholdSeries = useCallback((additions={}) => {

    const {
      attribute,
      alarm_value,
      // if normal value is undefined, set to alarm value
      normal_value = alarm_value,
      alarm_comparison = 'gt',
      normal_comparison = alarm_comparison === 'gt' ? 'lt' : 'gt',
    } = { ...editNotificationThresholdValue, ...additions };

    const allSeriesIds = Object.values(attributeSeriesIdsByAttribute).flat();
    const theseSeriesIds = attributeSeriesIdsByAttribute[attribute] || [];
    const otherSeriesIds = allSeriesIds.filter(v => !theseSeriesIds.includes(v));
    return {
      series: [
        // clear other attributes
        ...otherSeriesIds.map(id => ({
          id,
          markArea: { data: [] },
          markLine: { data: [] },
          markPoint: { data: [] },
        })),
        // draw this attribute
        ...theseSeriesIds.map(id => ({
          id,
          markArea: {
            silent: true,
            data: [
              // draw normal area
              normal_comparison === 'gt' ? [
                // mark above a line
                { yAxis: normal_value, ...thresholdNormalArea },
                { yAxis: Infinity },
              ] : [
                // mark below a line
                { yAxis: -Infinity, ...thresholdNormalArea },
                { yAxis: normal_value },
              ],
              // draw alarm area
              alarm_comparison === 'gt' ? [
                // mark above a line
                { yAxis: alarm_value, ...thresholdAlarmArea },
                { yAxis: Infinity },
              ] : [
                // mark below a line
                { yAxis: -Infinity, ...thresholdAlarmArea },
                { yAxis: alarm_value },
              ],
            ],
          },
          markLine: {
            silent: true,
            data: [[
              // draw normal line
              { x: getXMinPoint(), yAxis: normal_value, ...thresholdNormalLine },
              { x: getXMaxPoint(), yAxis: normal_value, ...thresholdNormalLine },
            ], [
              // draw alarm line
              { x: getXMinPoint(), yAxis: alarm_value, ...thresholdAlarmLine },
              { x: getXMaxPoint(), yAxis: alarm_value, ...thresholdAlarmLine },
            ]],
          },
          markPoint: {
            silent: true,
            data: [
              // draw normal point
              { x: getXMidPoint() + iconSize, yAxis: normal_value, ...whiteNormalPointBackground },
              { x: getXMidPoint() + iconSize, yAxis: normal_value,
                symbolRotate: normal_comparison === 'gt' ? 90 : -90,
                symbolOffset: [0, 0],
                ...thresholdNormalPoint,
              },
              // draw alarm point
              { x: getXMidPoint() - iconSize, yAxis: alarm_value, ...whiteAlarmPointBackground },
              { x: getXMidPoint() - iconSize, yAxis: alarm_value,
                symbolRotate: alarm_comparison === 'gt' ? 90 : -90,
                symbolOffset: [0, 0],
                ...thresholdAlarmPoint,
              },
            ],
          },
        })),
      ],
    };
  }, [
    editNotificationThresholdValue,
    attributeSeriesIdsByAttribute,
  ]);

  // update when running cut-off changes
  useChartUpdateEffect(updateThresholdSeries);
  // and when chart is resized
  useChartUpdateOnCustomEvent('resize', updateThresholdSeries);

  const updateThresholdMarkerLines = useCallback(() => {
    if (
      !editNotificationThresholdValue &&
      !editRunningCutoffMode &&
      !editLearningStartMode
    ) {
      return {
        series: [
          ...attributeSeriesIdsByAttribute['rms'].map(id => ({
            id,
            // mark alarm threshold indication line on axes
            markLine: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'rms')
                .map(({ alarm_value, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmLine : thresholdAlarmLine) : thresholdAlarmLineDisabled;
                  return [
                    // mark left of the right axes
                    { yAxis: alarm_value, x: getXMaxPoint() - 25, ...style },
                    { yAxis: alarm_value, x: getXMaxPoint() + 5, ...style },
                  ];
                }),
            },
            // mark alarm threshold indication arrow direction on axes
            markPoint: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'rms')
                // sort with disable on bottom, enabled drawn on top
                .sort((a, b) => Number(a.enabled || 0) - Number(b.enabled || 0))
                .map(({ alarm_value, alarm_comparison, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmPoint : thresholdAlarmPoint) : thresholdAlarmPointDisabled;  // @TODO: Possibly need different style for emergency threshold values.
                  // mark left of the right axes
                  const gt = alarm_comparison === 'gt';
                  return {
                    yAxis: alarm_value,
                    x: getXMaxPoint() - 10,
                    ...style,
                    // point arrow in the right direction
                    symbolRotate: gt ? 90 : -90,
                    // nudge arrow a little in the right direction
                    symbolOffset: [0, gt ? -2.5 : 2.5],
                  };
                }),
            },
          })),
          ...attributeSeriesIdsByAttribute['tmp'].map(id => ({
            id,
            // mark alarm threshold indication line on axes
            markLine: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'tmp')
                .map(({ alarm_value, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmLine : thresholdAlarmLine) : thresholdAlarmLineDisabled;
                  return [
                    // mark left of the right axes
                    { yAxis: alarm_value, x: getXMinPoint() + 25, ...style },
                    { yAxis: alarm_value, x: getXMinPoint() - 5, ...style },
                  ];
                }),
            },
            // mark alarm threshold indication arrow direction on axes
            markPoint: {
              silent: true,
              data: deviceNotificationThresholds
                .filter(({ attribute }) => attribute === 'tmp')
                .map(({ alarm_value, alarm_comparison, enabled, high_alarm }) => {
                  const style = enabled ? (high_alarm ? thresholdHighAlarmPoint : thresholdAlarmPoint) : thresholdAlarmPointDisabled;
                  // mark left of the right axes
                  const gt = alarm_comparison === 'gt';
                  return {
                    yAxis: alarm_value,
                    x: getXMinPoint() + 10,
                    ...style,
                    // point arrow in the right direction
                    symbolRotate: gt ? 90 : -90,
                    // nudge arrow a little in the right direction
                    symbolOffset: [0, gt ? -2.5 : 2.5],
                  };
                }),
            },
          })),
        ],
      };
    }
  }, [
    deviceNotificationThresholds,
    editNotificationThresholdValue,
    editRunningCutoffMode,
    editLearningStartMode,
    attributeSeriesIdsByAttribute,
  ]);

  // update when running cut-off changes
  useChartUpdateEffect(updateThresholdMarkerLines); // Update arror marker on chart.
  // and when chart is resized
  useChartUpdateOnCustomEvent('resize', updateThresholdMarkerLines);

  const updateDraggablePoints = useCallback(() => {

    const normalIsCustomised = editNotificationThresholdValue && editNotificationThresholdValue.normal_value;
    const {
      attribute,
      alarm_value,
      // if normal value is undefined, set to alarm value
      normal_value = alarm_value,
      alarm_comparison,
      normal_comparison,
    } = { ...editNotificationThresholdValue };

    const [attributeSeriesId] = attributeSeriesIdsByAttribute[attribute] || [];

    return {
      graphic: {
        elements: [
          editRunningCutoffMode
            ? {
              id: 'new_running_cutoff',
              type: 'circle',
              position: [getXMidPoint(), getYFromData(modalRunningCutoff)],
              shape: { cx: 0, cy: 0, r: iconSize },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart.containPixel({ seriesId: 'running_cutoff' }, [offsetX, offsetY])) {
                  const newRunningCutOff = convertRunningCutoffFromPixel([offsetX, offsetY]);
                  chart.setOption({ // Update running cut-off value while dragging the red line
                    yAxis: [{
                      id: 'running_cutoff',
                      name: `Running Cut-Off: ${getFormattedValue(newRunningCutOff, {
                        ...displayRmsWithoutConversion,
                        minimumFractionDigits: 1,
                        maximumFractionDigits: displayRms.maximumFractionDigits + 3,
                      })}`,
                    }],
                    series: [{
                      id: 'running_cutoff',
                      markLine: {
                        data: [[
                          { x: getXMinPoint(), yAxis: newRunningCutOff },
                          { x: getXMaxPoint(), yAxis: newRunningCutOff },
                        ]],
                      },
                      markPoint: {
                        data: editRunningCutoffMode ? [
                          { x: getXMidPoint(), yAxis: newRunningCutOff, ...whitePointBackground },
                          { x: getXMidPoint(), yAxis: newRunningCutOff },
                        ] : [],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // reset the draggable graphic
                if (chart) {
                  // update (retarget) draggable points
                  chart.setOption(updateDraggablePoints());
                  // clip running cut-off value, it should never be negative
                  const newRunningCutOff = clipValue(
                    convertRunningCutoffFromPixel([offsetX, offsetY]),
                    [0, Number.Infinity],
                  );
                  setModalRunningCutoff(newRunningCutOff);
                }
              },
            } : {
              // or remove
              id: 'new_running_cutoff',
              $action: 'remove',
            },
          editLearningStartMode
            ? {
              id: 'new_learning_start',
              type: 'circle',
              position: [getXFromData(modalLearningStart), getYMidPoint()],
              shape: { cx: 0, cy: 0, r: iconSize },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart.containPixel({ seriesId: 'running_cutoff' }, [offsetX, offsetY])) {
                  const newLearningStart = convertLearningStartFromPixel([offsetX, offsetY]);
                  chart.setOption({
                    series: [{
                      id: 'running_cutoff',
                      markLine: {
                        data: [[
                          // learning-start line
                          { xAxis: newLearningStart, yAxis: 'max' },
                          { xAxis: newLearningStart, yAxis: 'min' },
                        ], [
                          // old running-cutoff line
                          { xAxis: newLearningStart, yAxis: modalRunningCutoff },
                          { x: getXMaxPoint(), yAxis: modalRunningCutoff },
                        ]],
                      },
                      markPoint: {
                        data: [
                          {
                            x: offsetX,
                            y: getYMidPoint(),
                            symbolRotate: -45,
                            ...whitePointBackground,
                          },
                          {
                            x: offsetX,
                            y: getYMidPoint(),
                            symbolRotate: -45,
                          },
                        ],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // reset the draggable graphic
                if (chart) {
                  // update (retarget) draggable points
                  chart.setOption(updateDraggablePoints());
                  // clip value: value should not be negative or after now
                  const newLearningStart = clipValue(
                    convertLearningStartFromPixel([offsetX, offsetY]),
                    [0, Date.now()]
                  );
                  setModalLearningStart(newLearningStart);
                }
              },
            } : {
              // or remove
              id: 'new_learning_start',
              $action: 'remove',
            },
          editRunningCutoffMode && editLearningStartMode
            ? {
              id: 'new_running_cutoff_and_learning_start',
              type: 'circle',
              position: [getXFromData(modalLearningStart), getYFromData(modalRunningCutoff)],
              shape: { cx: 0, cy: 0, r: iconSize },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart.containPixel({ seriesId: 'running_cutoff' }, [offsetX, offsetY])) {
                  const newRunningCutOff = convertRunningCutoffFromPixel([offsetX, offsetY]);
                  const newLearningStart = convertLearningStartFromPixel([offsetX, offsetY]);
                  chart.setOption({
                    yAxis: [{
                      id: 'running_cutoff',
                      name: `Running Cut-Off: ${getFormattedValue(newRunningCutOff, {
                        ...displayRmsWithoutConversion,
                        minimumFractionDigits: 1,
                        maximumFractionDigits: displayRms.maximumFractionDigits + 3,
                      })}`,
                    }],
                    series: [{
                      id: 'running_cutoff',
                      markLine: {
                        data: [[
                          // learning-start line
                          { xAxis: newLearningStart, yAxis: 'max' },
                          { xAxis: newLearningStart, yAxis: 'min' },
                        ], [
                          // old running-cutoff line
                          { xAxis: newLearningStart, yAxis: newRunningCutOff },
                          { x: getXMaxPoint(), yAxis: newRunningCutOff },
                        ]],
                      },
                      markPoint: {
                        data: [
                          // x,y-axes point
                          {
                            x: offsetX,
                            y: offsetY,
                            symbol: IoIosArrowRoundForwardPath,
                            symbolRotate: 0,
                            symbolSize: [18, 12],
                            // position the arrow to point towards the cursor
                            symbolOffset: [-12, 0],
                          },
                        ],
                      },
                    }],
                  });
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // reset the draggable graphic
                if (chart) {
                  // update (retarget) draggable points
                  chart.setOption(updateDraggablePoints());
                  // clip running cut-off value, it should never be negative
                  const newRunningCutOff = clipValue(
                    convertRunningCutoffFromPixel([offsetX, offsetY]),
                    [0, Number.Infinity],
                  );
                  setModalRunningCutoff(newRunningCutOff);
                  // clip value: value should not be negative or after now
                  const newLearningStart = clipValue(
                    convertLearningStartFromPixel([offsetX, offsetY]),
                    [0, Date.now()]
                  );
                  setModalLearningStart(newLearningStart);
                }
              },
            } : {
              // or remove
              id: 'new_running_cutoff_and_learning_start',
              $action: 'remove',
            },
          editNotificationThresholdValue
            ? {
              id: 'new_notification_threshold_normal',
              type: 'circle',
              position: [
                getXMidPoint() + iconSize,
                getYFromData(normal_value, attributeSeriesId),
              ],
              shape: { cx: 0, cy: 0, r: iconSize },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart) {
                  const newNotificationThresholdValue = clipValue(
                    convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                    // clip to above or below alarm
                    normal_comparison === 'gt'
                      ? alarm_value
                        ? [alarm_value]
                        : []
                      : alarm_value
                        ? [undefined, alarm_value]
                        : [],
                  );
                  chart.setOption(updateThresholdSeries({ normal_value: newNotificationThresholdValue }));
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const newNotificationThresholdValue = clipValue(
                  convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                  // clip to above or below alarm
                  normal_comparison === 'gt'
                    ? alarm_value
                      ? [alarm_value]
                      : []
                    : alarm_value
                      ? [undefined, alarm_value]
                      : [],
                );
                setEditNotificationThresholdValue(threshold => {
                  return {
                    ...threshold,
                    // move thresholds together if values are identical
                    normal_value: newNotificationThresholdValue !== threshold.alarm_value
                      ? newNotificationThresholdValue
                      : undefined,
                  };
                });
              },
            } : {
              // or remove
              id: 'new_notification_threshold_normal',
              $action: 'remove',
            },
          editNotificationThresholdValue
            ? {
              id: 'new_notification_threshold_alarm',
              type: 'circle',
              position: [
                getXMidPoint() - iconSize,
                getYFromData(alarm_value, attributeSeriesId),
              ],
              shape: { cx: 0, cy: 0, r: iconSize },
              z: 100,
              invisible: true,
              draggable: true,
              ondrag: ({ offsetX, offsetY }) => {
                const chart = getChart();
                // only update if the drag point is still on the graph
                if (chart) {
                  const newNotificationThresholdValue = clipValue(
                    convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                    // clip to above or below normal
                    normalIsCustomised
                      ? alarm_comparison === 'gt' ? [normal_value] : [undefined, normal_value]
                      : [],
                  );
                  chart.setOption(updateThresholdSeries({ alarm_value: newNotificationThresholdValue }));
                }
              },
              ondragend: ({ offsetX, offsetY }) => {
                const newNotificationThresholdValue = clipValue(
                  convertNotificationThresholdFromPixel([offsetX, offsetY], attributeSeriesId),
                  // clip to above or below normal
                  normalIsCustomised
                    ? alarm_comparison === 'gt' ? [normal_value] : [undefined, normal_value]
                    : [],
                );
                setEditNotificationThresholdValue(threshold => {
                  return {
                    ...threshold,
                    alarm_value: newNotificationThresholdValue,
                    // move thresholds together if values are identical
                    normal_value: threshold.normal_value !== newNotificationThresholdValue
                      ? threshold.normal_value
                      : undefined,
                  };
                });
              },
            } : {
              // or remove
              id: 'new_notification_threshold_alarm',
              $action: 'remove',
            },
        ],
      },
    };
  }, [
    editRunningCutoffMode,
    modalRunningCutoff,
    editLearningStartMode,
    modalLearningStart,
    editNotificationThresholdValue,
    updateThresholdSeries,
    attributeSeriesIdsByAttribute,
    ...Object.values(displayRms),
  ]);

  // handle updates when values change
  useChartUpdateEffect(updateDraggablePoints);
  // update after samples have changed (the scale of the RMS axis may have changed)
  useChartUpdateEffect(updateDraggablePoints, [samples]);
  // and when chart is resized
  useChartUpdateOnCustomEvent('resize', updateDraggablePoints);
  // on date range change the draggable points will need an update
  // (from the auto-date-range adjust effect earlier in this component)
  // if the learning start mode is started, ensure the chart can see that date value
  useChartUpdateOnCustomEvent('dateRange', updateDraggablePoints);

  return (<>
    <BaseChart
      header={<Fragment><FaSignal /> Measured Data</Fragment>}
      namespace="measured-data"
      deviceId={deviceId}
      samples={samples}
      dateRange={dateRange}
      setDateRange={setDateRange}
      {...props}
      // pass memoised buttons
      toolbarButtons={useMemo(() => [
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-dark"
            size="lg"
          >
            <IoIosCog size="1.3em" title="Edit" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            popperConfig={popperConfigUpdateOnChange}
          >
            {userCanEditThresholds && !(editRunningCutoffMode || editLearningStartMode || axisOptionsMode) && (
              editNotificationThresholdMode ? (
                <Dropdown.Item onClick={exitNotificationThresholdMode}>
                  Cancel {(
                    editNotificationThresholdValue &&
                    editNotificationThresholdValue.id
                  ) ? 'edit' : 'add'} alarm threshold
                </Dropdown.Item>
              ) : (
                <>
                  <Dropdown.Item
                    onClick={() => {
                      // start editing a default threshold
                      setEditNotificationThresholdValue({
                        enabled: true,
                        attribute: 'rms',
                        alarm_comparison: 'gt',
                        alarm_value: parseFloat(((minRms + 2 * maxRms) / 3).toFixed(2)),
                        high_alarm: false,
                      });
                    }}
                  >
                    Add RMS alarm threshold
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      // start editing a default threshold
                      setEditNotificationThresholdValue({
                        enabled: true,
                        attribute: 'tmp',
                        alarm_comparison: 'gt',
                        alarm_value: parseFloat(((minTemp + 2 * maxTemp) / 3).toFixed(2)),
                        high_alarm: false,
                      });
                    }}
                  >
                    Add temperature alarm threshold
                  </Dropdown.Item>
                </>
              )
            )}
            {!(editNotificationThresholdMode || axisOptionsMode) && (<>
              <Dropdown.Item
                onClick={toggleRunningCutoffMode}
              >
                {!editRunningCutoffMode ? (
                  'Edit'
                ) : (
                  'Cancel edit'
                )} running cut-off value
              </Dropdown.Item>
              <Dropdown.Item
                onClick={toggleLearningStartMode}
              >
                {!editLearningStartMode ? (
                  'Edit'
                ) : (
                  'Cancel edit'
                )} learning start date
              </Dropdown.Item>
            </>)}
            {!(editRunningCutoffMode || editLearningStartMode || editNotificationThresholdMode) &&
              <Dropdown.Item onClick={toggleAxisOptionsMode}>
                {!axisOptionsMode ? 'Edit' : 'Cancel edit'} RMS/temperature axis
              </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
      ], [
        minTemp,
        maxTemp,
        minRms,
        maxRms,
        editRunningCutoffMode,
        toggleRunningCutoffMode,
        editLearningStartMode,
        toggleLearningStartMode,
        editNotificationThresholdMode,
        exitNotificationThresholdMode,
        editNotificationThresholdValue,
        setEditNotificationThresholdValue,
        axisOptionsMode,
        setAxisOptionsMode,
      ])}
      BelowChart={useCallback(() => (
        <div className="d-flex" style={{marginLeft: '56px'}}>  {/** 55px references to the default options of base chart (specified in defaultOptions.grid.left(BaseChart.js)) */}
          <ISOTableModal device={device} />
        </div>
      ), [deviceId])}
      AboveChart={useCallback(() => (
        <Fragment>
          {editRunningCutoffMode && (
            // Edit running cut-off value.
            <BaseChartEditToolbar className="pt-2 pb-1">
              <Row className="small-gutters d-flex align-items-center">
                <Col className="mb-1" xs="auto">The running cut-off helps distinguish when your asset is on or off, drag the vertical slider to adjust it. <a href="https://learn.movus.com.au/knowledge/adjusting-the-running-cut-off" target="_blank" rel="noopener noreferrer">Learn more</a>.
                </Col>
                The data you see here may be different to what is usually shown in the chart as it is unfiltered.
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      {getFormattedValue(modalRunningCutoff, displayRmsWithoutConversion)}
                    </Col>
                    {!editLearningStartMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          size="sm"
                          disabled={deviceRunningCutOff === modalRunningCutoff}
                          onClick={openRunningCutoffModal}
                        >
                          Save
                        </Button>
                      </Col>
                    )}
                    {!editLearningStartMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={exitEnterRunningCutoffMode}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editLearningStartMode && (
            // Edit learning start date.
            <BaseChartEditToolbar className="pt-2 pb-1">
              <Row className="small-gutters d-flex align-items-center">
                <Col className="mb-1" xs="auto">
                  Drag the horizontal slider to adjust the learning start date.
                </Col>
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      {new Date(modalLearningStart).toLocaleString()}
                    </Col>
                    {!editRunningCutoffMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          size="sm"
                          disabled={deviceLearningStart === modalLearningStart}
                          onClick={openLearningStartModal}
                        >
                          Save
                        </Button>
                      </Col>
                    )}
                    {!editRunningCutoffMode && (
                      <Col className="mb-1" xs="auto">
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          onClick={exitEnterLearningStartMode}
                        >
                          Cancel
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editRunningCutoffMode && editLearningStartMode && (
            // How come editRunningCutoffMode and editLearningStartMode can be true at the same time?
            <BaseChartEditToolbar className="pt-2 pb-1">
              <Row className="small-gutters d-flex align-items-center">
                {/* everything in the following column is right-aligned */}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      <Button
                        size="sm"
                        disabled={(
                          (deviceRunningCutOff === modalRunningCutoff) &&
                          (deviceLearningStart === modalLearningStart)
                        )}
                        onClick={openAllModals}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={exitAllEditModes}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {axisOptionsMode && (
            <>
              <BaseChartEditToolbar variant="danger">
                <p className="font-weight-bold">Edit RMS/temperature axis</p>
                <p>Changing the axis settings will fix the scale to the values set in the fields below.&nbsp;
                  <span className="font-weight-bold">Auto</span> indicates that the axis value is set to scale automatically.
                </p>
                <p className="font-weight-bold">When the axis settings are manually set, the value will remain fixed irrespective of the values of
                  the data which may lead to values being off the chart.
                </p>
              </BaseChartEditToolbar>
              <BaseChartEditToolbar>
                <AxisOptionsForm
                  axisOptions={axisOptions}
                  onUpdateAxisOptions={(data) => setAxisOptions(data)}
                  onCancel={exitSetAxisOptionsMode}
                  unitPreference={unitPreference}
                  deviceId={deviceId}
                />
              </BaseChartEditToolbar>
            </>)
          }
          {!editNotificationThresholdMode && !editRunningCutoffMode && !editLearningStartMode && !axisOptionsMode && deviceNotificationThresholds.length > 0 && (
            // List the existing notification threshold values.
            <BaseChartEditToolbar className="pb-2">
              {deviceNotificationThresholds.filter(({attribute}) => attribute === 'rms' || attribute === 'tmp').sort((a={}, b={}) => {
                return (
                  // sort by attribute ascending
                  `${a.attribute}`.localeCompare(`${b.attribute}`)
                ) || (
                  // then by alarm value ascending
                  Number(a.alarm_value) - Number(b.alarm_value)
                ) || (
                  // then by normal value ascending
                  Number(a.alarm_value) - Number(b.alarm_value)
                );
              }).map((threshold={}) => (
                <Row className="small-gutters d-flex" key={threshold.id}>
                  <Col className="mb-1" xs="auto">
                    <StatusBadge alarmState={threshold.alarm_state} />
                    <span className={threshold.high_alarm && hasHighAlarm ? "font-weight-bold" : ""}>
                      {
                        threshold.attribute === 'rms'
                          ? 'RMS'
                          : 'TMP'
                      }  {threshold.alarm_comparison === 'lt' ? `Low${threshold.high_alarm && hasHighAlarm ? '-Low' : ''}` : `High${threshold.high_alarm && hasHighAlarm ? '-High' : ''}`} alarm
                    </span>
                  </Col>
                  <Col xs="auto" className="ml-auto">
                    {
                      threshold.enabled !== true ? '(disabled) ': ''
                    } alarm {
                      threshold.alarm_comparison === 'gt' ? '>' : '<'
                    } {threshold.attribute === 'rms' && (
                      getFormattedValue(threshold.alarm_value, displayRmsWithoutConversion)
                    )}{threshold.attribute === 'tmp' && (
                      getFormattedValue(threshold.alarm_value, displayTemperatureWithoutConversion)
                    )}, { threshold.normal_value === threshold.alarm_value ? 'no return to normal alert' : (
                      'normal ' +
                      (threshold.normal_comparison === 'gt' ? '> ' : '< ') +
                      (threshold.attribute === 'rms' ? (
                        getFormattedValue(threshold.normal_value, displayRmsWithoutConversion)) : '' ) +
                      (threshold.attribute === 'tmp' ? (
                        getFormattedValue(threshold.normal_value, displayTemperatureWithoutConversion)) : '')
                    )}
                    {' '}
                    {userCanEditThresholds && (
                      <Button
                        size="xs"
                        style={{
                          marginTop: '-0.25em', // offset padding height of button
                          paddingBottom: '0.125em', // make button just a little smaller
                        }}
                        onClick={() => {

                          // pick parts from threshold (don't use '_links' or 'title')
                          const {
                            id,
                            enabled,
                            attribute,
                            alarm_comparison,
                            alarm_value,
                            normal_comparison,
                            normal_value,
                            high_alarm,
                          } = threshold;

                          const payload = {
                            id,
                            enabled,
                            attribute,
                            alarm_comparison,
                            alarm_value,
                            // only set normal values if they are not the alarm value
                            // this will allow the user to move both values together
                            // which seems expected when presented with this
                            ...normal_value !== alarm_value && {
                              normal_comparison,
                              normal_value,
                            },
                            high_alarm,
                          };

                          // start editing a clone of this threshold
                          setEditNotificationThresholdValue(payload);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Col>
                </Row>
              ))}
            </BaseChartEditToolbar>
          )}
          {editNotificationThresholdValue && (
            // RMS/Temperature alarm notification checkbox.
            <BaseChartEditToolbar variant="danger" className="pb-2">
              <Row className="small-gutters d-flex">
                <Col className="mb-1">
                  <Row className="small-gutters d-flex">
                    <Col className="mb-1 font-weight-bold" xs="auto">
                      {
                        editNotificationThresholdValue.attribute === 'rms'
                          ? 'RMS'
                          : 'Temperature'
                      } alarm notification
                    </Col>
                    <Col className="mb-1" xs="auto">
                      Alarm notifications will be sent to users that normally
                      receive alert emails for this equipment.
                      <p>
                        <strong>When the alarm and normal settings are the same, no alert will be sent when the monitored value returns to normal.</strong>
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end">
                    <Col className="mb-1" xs="auto">
                      <Form.Group className="mb-0">
                        <Form.Check>
                          <Form.Check.Input
                            id="alarm-notification-enable"
                            type="checkbox"
                            checked={editNotificationThresholdValue.enabled}
                            onChange={() => {
                              setEditNotificationThresholdValue(({ enabled, ...threshold }) => {
                                return {
                                  ...threshold,
                                  enabled: !enabled,
                                };
                              });
                            }}
                            style={{ marginTop: '0.375em' }}
                          />
                          <Form.Check.Label htmlFor="alarm-notification-enable">
                            enabled
                          </Form.Check.Label>
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editNotificationThresholdValue && hasHighAlarm && (
            <BaseChartEditToolbar variant="dark" className="pb-2">
              <Row className="small-gutters d-flex">
                <Col className="mb-1">
                  <div className="mb-1 font-weight-bold">{editNotificationThresholdValue.alarm_comparison === 'lt' ? 'Low-Low' : 'High-high'} alarm notification</div>
                  <div className="mb-1">{editNotificationThresholdValue.alarm_comparison === 'lt' ? 'Low-Low' : 'High-high'} alarm notifications will be sent to all users subscribed to the alarm channel and those subscribed to escalations.
                    <p>
                      <strong>
                        This alarm is optional and should only be activated if a machine is
                        anticipated to degrade rapidly and a delayed response time could be fatal.
                      </strong>
                    </p>
                  </div>
                </Col>
                <Col className="mb-1" xs="auto">
                  <Form.Group className="mb-0">
                    <Form.Check
                      id="alarm-notification-emergency"
                      type="checkbox"
                      label="enabled"
                      checked={editNotificationThresholdValue.high_alarm}
                      onChange={() => {
                        setEditNotificationThresholdValue(({ high_alarm, ...threshold }) => {
                          return {
                            ...threshold,
                            high_alarm: !high_alarm,
                          };
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editNotificationThresholdValue && (
            // The message to set the value to trigger alarm notification.
            <BaseChartEditToolbar className="pb-2">
              <Row className="small-gutters d-flex">
                <Col className="mb-1">
                  Drag the red slider or input a value to adjust when alarm notifications are triggered.
                  <Form.Control
                    autoFocus={focusValue === 'focusAlarmValue'}
                    onFocus={() => setFocusValue('focusAlarmValue')}
                    min={
                      editNotificationThresholdValue.alarm_comparison === 'gt' ? editNotificationThresholdValue.normal_value :
                        editNotificationThresholdValue.alarm_comparison === 'lt' ? (editNotificationThresholdValue.attribute === 'rms' ? 0 : undefined) :
                          undefined
                    }
                    max={
                      editNotificationThresholdValue.alarm_comparison === 'lt' ? editNotificationThresholdValue.normal_value : undefined
                    }
                    type="number"
                    step="0.1"
                    value={editNotificationThresholdValue.alarm_value}
                    onChange={e => {
                      // https://stackoverflow.com/a/36115815/8590017
                      // https://legacy.reactjs.org/docs/legacy-event-pooling.html
                      e.persist();
                      const decimal = e.target.value.split('.')[1];
                      if(decimal?.length > 3) return;
                      setEditNotificationThresholdValue(threshold => {
                        return {
                          ...threshold,
                          alarm_value: Number(e.target.value)
                        };
                      });
                    }}
                    style={{maxWidth: '200px'}}
                  />
                </Col>
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end">
                    <Col className="mb-1" xs="auto">
                      {
                        editNotificationThresholdValue.alarm_comparison === 'gt' ? '>' : '<'
                      }{getFormattedValue(
                        editNotificationThresholdValue.alarm_value,
                        editNotificationThresholdValue.attribute === 'rms'
                          ? displayRmsWithoutConversion
                          : displayTemperatureWithoutConversion,
                      )}
                    </Col>
                  </Row>
                  <Row className="small-gutters d-flex justify-content-end">
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => setEditNotificationThresholdValue(threshold => {
                          const alarm_comparison = threshold.alarm_comparison === 'gt'
                            ? 'lt'
                            : 'gt';
                          const normal_comparison = alarm_comparison === 'gt'
                            ? 'lt'
                            : 'gt';
                          return {
                            ...threshold,
                            alarm_comparison,
                            alarm_value: threshold.normal_value !== undefined
                              ? threshold.normal_value
                              : threshold.alarm_value,
                            normal_comparison,
                            normal_value: threshold.normal_value !== undefined
                              ? threshold.alarm_value
                              : undefined,
                          };
                        })}
                      >
                        Switch direction
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editNotificationThresholdValue && (
            // 'Back to normal' message.
            <BaseChartEditToolbar className="pb-2">
              <Row className="small-gutters d-flex">
                {editNotificationThresholdValue.normal_value !== undefined ? (
                  <Col className="mb-1">
                    Drag the blue slider or input a value to adjust when 'back to normal' notifications are triggered.
                    <Form.Control
                      autoFocus={focusValue === 'focusNormalValue'}
                      onFocus={() => setFocusValue('focusNormalValue')}
                      min={
                        editNotificationThresholdValue.alarm_comparison === 'lt' ? editNotificationThresholdValue.alarm_value :
                          (editNotificationThresholdValue.attribute === 'rms' ? 0 : undefined)
                      }
                      max={
                        editNotificationThresholdValue.alarm_comparison === 'gt' ? editNotificationThresholdValue.alarm_value : undefined
                      }
                      type="number"
                      step="0.1"
                      value={editNotificationThresholdValue.normal_value}
                      onChange={e => {
                        // https://stackoverflow.com/a/36115815/8590017
                        // https://legacy.reactjs.org/docs/legacy-event-pooling.html
                        e.persist();
                        const decimal = e.target.value.split('.')[1];
                        if(decimal?.length > 3) return;
                        setEditNotificationThresholdValue(threshold => {
                          return {
                            ...threshold,
                            normal_value: Number(e.target.value)
                          };
                        });
                      }}
                      style={{maxWidth: '200px'}}
                    />
                  </Col>
                ) : (
                  <Col className="mb-1">
                    'Back to normal' notifications are triggered at:
                  </Col>
                )}
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end">
                    <Col className="mb-1" xs="auto">
                      {
                        editNotificationThresholdValue.normal_value === undefined
                          ? editNotificationThresholdValue.alarm_comparison === 'gt' ? '<' : '>'
                          : editNotificationThresholdValue.normal_comparison === 'gt' ? '>' : '<'
                      }{getFormattedValue(
                        editNotificationThresholdValue.normal_value || editNotificationThresholdValue.alarm_value,
                        editNotificationThresholdValue.attribute === 'rms'
                          ? displayRmsWithoutConversion
                          : displayTemperatureWithoutConversion,
                      )}
                    </Col>
                  </Row>
                  <Row className="small-gutters d-flex justify-content-end">
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => setEditNotificationThresholdValue(threshold => {
                          // remove normal
                          if (threshold.normal_value !== undefined) {
                            return {
                              ...threshold,
                              normal_comparison: undefined,
                              normal_value: undefined,
                            };
                          }
                          // create normal less than
                          else if (threshold.alarm_comparison === 'gt') {
                            return {
                              ...threshold,
                              normal_comparison: 'lt',
                              normal_value: threshold.alarm_value > 0
                                ? parseFloat((threshold.alarm_value / 1.1).toFixed(2))
                                : parseFloat((threshold.alarm_value * 1.1).toFixed(2)),
                            };
                          }
                          // create normal greater than
                          else if (threshold.alarm_comparison === 'lt') {
                            return {
                              ...threshold,
                              normal_comparison: 'gt',
                              normal_value: threshold.alarm_value > 0
                                ? parseFloat((threshold.alarm_value * 1.1).toFixed(2))
                                : parseFloat((threshold.alarm_value / 1.1).toFixed(2)),
                            };
                          }
                          return threshold;
                        })}
                      >
                        {editNotificationThresholdValue.normal_value === undefined ? (
                          'Custom'
                        ) : (
                          'Reset'
                        )} normal notification
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {editNotificationThresholdValue && (
            // Action buttons.
            <BaseChartEditToolbar className="pb-2">
              <Row className="small-gutters d-flex align-items-center">
                <Col xs="auto" className="ml-auto">
                  <Row className="small-gutters d-flex justify-content-end align-items-center">
                    <Col className="mb-1" xs="auto">
                      <Button
                        size="sm"
                        disabled={
                          Object.entries(editNotificationThresholdValue).every(([key, value]) => {
                            // test that every key pair value is the same
                            return (selectedNotificationThresholdValue || {})[key] === value;
                          })
                        }
                        onClick={saveThreshold}
                      >
                        Save
                      </Button>
                    </Col>
                    {editNotificationThresholdValue.id && (
                      <Col className="mb-1" xs="auto">
                        <ConfirmModal
                          body="Alternatively if you plan to use it again later, you can just disable (not delete) an alarm notification"
                          confirmText="Delete alarm notification"
                        >
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={deleteThreshold}
                          >
                            Delete
                          </Button>
                        </ConfirmModal>
                      </Col>
                    )}
                    <Col className="mb-1" xs="auto">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={exitNotificationThresholdMode}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          )}
          {!editNotificationThresholdMode && !editRunningCutoffMode && !editLearningStartMode && !axisOptionsMode && hasCustomAxisOptions &&
            <BaseChartEditToolbar variant="danger">
              <p className="font-weight-bold">
                Your settings to RMS/temperature axis
              </p>
              <Row>
                <Col sm="6">
                  <div>Temperature axis options</div>
                  <div>Maximum: <span className="font-weight-bold">{axisOptions.maxTemp !== undefined ? getFormattedValue(axisOptions.maxTemp, displayTemperatureWithoutConversion) : 'auto'}</span></div>
                  <div>Minimum: <span className="font-weight-bold">{axisOptions.minTemp !== undefined ? getFormattedValue(axisOptions.minTemp, displayTemperatureWithoutConversion) : 'auto'}</span></div>
                </Col>
                <Col sm="6">
                  <div>RMS axis options</div>
                  <div>Maximum: <span className="font-weight-bold">{axisOptions.maxRms !== undefined ? getFormattedValue(axisOptions.maxRms, displayRmsWithoutConversion) : 'auto'}</span></div>
                  <div>Minimum: <span className="font-weight-bold">{axisOptions.minRms !== undefined ? getFormattedValue(axisOptions.minRms, displayRmsWithoutConversion) : 'auto'}</span></div>
                </Col>
              </Row>
            </BaseChartEditToolbar>
          }
        </Fragment>
      ), [
        deviceId,
        editRunningCutoffMode,
        deviceRunningCutOff,
        modalRunningCutoff,
        editLearningStartMode,
        deviceLearningStart,
        modalLearningStart,
        deviceNotificationThresholds,
        editNotificationThresholdMode,
        editNotificationThresholdValue,
        setEditNotificationThresholdValue,
        selectedNotificationThresholdValue,
        saveThreshold,
        deleteThreshold,
        ...Object.values(displayRms),
        // shouldn't change
        openRunningCutoffModal,
        openLearningStartModal,
        openAllModals,
        exitEnterRunningCutoffMode,
        exitEnterLearningStartMode,
        exitAllEditModes,
        axisOptionsMode,
        axisOptions,
        focusValue,
      ])}
    />
    {(showRunningCutoffModal || showLearningStartModal) && (
      <RelearnModalForm
        deviceId={deviceId}
        showPreviousValue={true}
        // add customised recalibration action to refetch RCO value
        recalibrate={recalibrateAndRefetch}
        header="Are you sure you want to restart learning?"
        // pass learning start date only if it is being edited
        {...showRunningCutoffModal && ({
          runningCutoff: unitPreference === 'US' ? convertInToMm(modalRunningCutoff) : modalRunningCutoff,
          body: (<>
            <p>
              Changing the running cutoff from {
                getFormattedValue(deviceRunningCutOff, displayRmsWithoutConversion)
              } to {
                getFormattedValue(modalRunningCutoff, displayRmsWithoutConversion)
              }
            </p>
            <p>
              Changing the running cutoff requires the device to relearn.
              {' '}
              <OverlayTrigger
                placement="auto"
                overlay={(
                  <Tooltip>
                    The running status of historical data may have changed.
                  </Tooltip>
                )}
              >
                <Button variant="light" size="sm">
                  Why?
                </Button>
              </OverlayTrigger>
            </p>
            <p>
              By default, MachineCloud will start learning from the same date &amp; time
              this device used previously.
              Learning may take some time, but alarms can still be raised.
              Historical equipment data will remain available after learning completes.
            </p>
          </>),
          confirmText: "Update running cut-off and restart learning",
        })}
        // pass learning start date only if it is being edited
        {...showLearningStartModal && ({
          initialDisplayOption: 'custom',
          learningStart: modalLearningStart,
        })}
        onClose={closeModal}
      />
    )}
  </>);
}

const mapStateToProps = (state, { deviceId }) => {
  const foundDevice = getDevice(state, deviceId);
  return {
    deviceId,
    device: foundDevice,
    deviceLearningStart: foundDevice &&
      foundDevice.calibration_start &&
      foundDevice.calibration_start.valueOf(),
    deviceRunningCutOff: foundDevice && foundDevice.running_cutoff,
    deviceNotificationThresholds: getDeviceNotificationThresholds(state, deviceId),
    displayTemperature: getUserTemperatureDisplayPreference(state),
    displayRms: getUserRmsDisplayPreference(state),
    rmsAvailable: getOrganisationRmsAvailablePreference(state),
    mm2Available: getOrganisationMm2AvailablePreference(state),
    rmsTitle: getOrganisationRmsTitle(state),
    mm2Title: getOrganisationMm2Title(state),
    userCanEditThresholds: isAdmin(state) && getDeviceHasProductCode(state, deviceId, 'rms_tmp_alarms'),
    hasHighAlarm: getCurrentOrganisationHasProductCode(state, 'highalarm'),
    unitPreference: getUserPreferenceValue(state, 'units_system'),
    displayNotRunningRanges: getDeviceDisplayNotRunningRanges(state, deviceId),
    // userCanEditThresholds: true,
  };
};
const mapDispatchToProps = {
  recalibrateAndRefetch,
  fetchDeviceNotificationThresholds,
  saveDeviceNotificationThreshold,
  deleteDeviceNotificationThreshold,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeasuredDataChart);
