import ecStat from 'echarts-stat';
import { convertToTimestamp } from '../BaseChart';

export function getRegressionPoints(samples) {
  if (samples.length < 2) return [];
  return visualCalculation(samples);
}

function visualCalculation(samples) {
  const startSample = samples[0];
  const endSample = samples[samples.length - 1];

  const runningPoints = getRunningPoints(samples);
  // Fallback for none running on screen
  if (runningPoints.length < 2) { // Can't do a linear regression without 2 points.
    const newStartPoint = [startSample.sample_time, startSample.vibration_rms];
    const newEndPoint = [endSample.sample_time, endSample.vibration_rms];
    return [newStartPoint, newEndPoint];
  }

  const runningRegression = getLinearRegression(runningPoints);
  let allPoints = removeHelpersIndices(runningRegression.points);

  const firstRegressionPoint = allPoints[0];
  const firstRegressionTime = convertToTimestamp(firstRegressionPoint[0]);
  const firstSampleTime = convertToTimestamp(startSample.sample_time);
  if (firstSampleTime < firstRegressionTime) {
    const newEndPoint = [startSample.sample_time, firstRegressionPoint[1]];
    allPoints = [newEndPoint, ...allPoints];
  }
  const lastRegressionPoint = allPoints[allPoints.length - 1];
  const lastRegressionTime = convertToTimestamp(lastRegressionPoint[0]);
  const lastSampleTime = convertToTimestamp(endSample.sample_time);
  if (lastSampleTime > lastRegressionTime) {
    const newEndPoint = [endSample.sample_time, lastRegressionPoint[1]];
    allPoints = [...allPoints, newEndPoint];
  }

  return allPoints;
}

function getLinearRegression(runningSamplesWithNewIndex) {
  return ecStat.regression('linear', runningSamplesWithNewIndex, 1);
}

function getRunningPoints(samples) {
  return samples.filter((sample) => sample.equipment_running).map((sample, index) => [
    // The order of the array matters for regression calculation
    index,
    sample.vibration_rms,
    sample.sample_time,
  ]);
}

function removeHelpersIndices(allPoints) {
  return allPoints.map((point) => [point[2], point[1]]);
}
