import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { fetchGateway } from '../actions';
import { getGateway } from '../selectors';
import GatewayPageLayout from '../components/GatewayPageLayout';
import withNavigationGatewayProps from "../components/withNavigationGatewayProps";
import Title from '../../../components/Title';
import EditGatewayForm from '../components/EditGatewayForm';

function EditGateway({ gatewayId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gateway = useSelector(state => getGateway(state, gatewayId));
  const { _state_: { loading, error, lastFetch } = {}} = gateway || {};

  useEffect(() => {
    dispatch(fetchGateway({ id: gatewayId }));
  }, [gatewayId]);

  return (
    <GatewayPageLayout>
      <Container className="my-4">
        <Title
          title={t('screens.gateways.edit-gateway.title')}
          loading={loading}
          error={error}
          lastFetch={lastFetch}
        />
        <EditGatewayForm gateway={gateway} />
      </Container>
    </GatewayPageLayout>
  );
}

export default withNavigationGatewayProps(EditGateway);
