import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Title from '../../../components/Title';
import PendingReviewEvents from '../../../components/home/PendingReviewEvents';
import ValidatedEventsMgnt from '../../../components/home/ValidatedEventsMgnt';
import PendingActionEvents from '../../../components/home/PendingActionEvents';
import PreventedLosses from '../../../components/home/PreventedLosses';
import EventImpactTrend from '../../../components/home/EventImpactTrend';
import MonitoringCost from '../../../components/home/MonitoringCost';
import "./home.scss";
import useGroup from '../../organisation/hooks/useGroup';
import useLocale from '../../../hooks/useLocale';

function HomeManagementView() {
  const { t } = useTranslation();
  const { group } = useGroup();
  const { currency } = useLocale({ groupId: group.id });

  return (
    <div className="home">
      <Container fluid>
        <Title
          title={`${t('Management')} ${t('Home')}`}
        />
        <Row noGutters>
          <Col xl={{span: 3, order: 1}} lg={6}>
            <PreventedLosses />
          </Col>
          <Col xl={{span: 6, order: 2}} lg={{span: 12, order: 'last'}}>
            <EventImpactTrend />
          </Col>
          <Col xl={{span: 3, order: 3}} lg={6}>
            <MonitoringCost />
          </Col>
        </Row>
        <Row noGutters>
          <Col xl="3">
            <PendingReviewEvents />
          </Col>
          <Col xl="9">
            <Row noGutters>
              <Col lg="6">
                <ValidatedEventsMgnt currency={currency}/>
              </Col>
              <Col lg="6">
                <PendingActionEvents currency={currency} />
              </Col>
            </Row>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeManagementView;