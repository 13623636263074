
import React from 'react';
import { Image } from 'react-bootstrap';

import RunningFan from '../../../images/running-fan-working.gif';
import NotRunningFan from '../../../images/running-fan-not-working.png';

import './runningStatus.scss';

const copy = {
  true: 'Running',
  false: 'Not running',
  default: 'N/A',
};

function RunningStatusIcon({ value }) {
  switch (value) {
    case true:
    case false:
      return (
        <Image
          className="equipment_running_status"
          src={value ? RunningFan : NotRunningFan}
          alt={value ? copy.true : copy.false}
        />
      );
    case null:
    default:
      return null;
  }
};

function RunningStatusText({ value }) {
  return copy[value] || copy.default;
};

// expose a function to retrieve just the text value of the component
export function getRunningStatusText(value) {
  return RunningStatusText({ value });
}

export default function RunningStatus({ value }) {
  // only show RunningStatusIcon if the value is boolean
  if (value === true || value === false) {
    return <RunningStatusIcon value={value} />;
  }
  // show text if the value is not boolean
  else {
    return <RunningStatusText value={value} />;
  }
};
