import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/*
 * Example: (
 *   <BasicTooltip text="in tooltip">
 *     hover over me
 *   </BasicTooltip>
 * )
 */
export default function BasicTooltip({
  text,
  body = text, // allow 'text' or 'body' to be set
  children,
  ...props
}) {
  return (
    <OverlayTrigger
      // add any overlay props
      {...props}
      // override overlay to basic tooltip body
      overlay={(
        <Tooltip>
          {body}
        </Tooltip>
      )}
    >
      {children}
    </OverlayTrigger>
  );
}
