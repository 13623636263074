import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDeviceListState, getDevices } from '../selectors';
import { fetchDevices as fetchDevicesAction } from '../actions';
import useSensorType from './useSensorType';

function useDevices() {
  const sensorType = useSensorType();
  const dispatch = useDispatch();
  const { loading, lastFetch, error } = useSelector(getDeviceListState);
  const devices = useSelector(state => getDevices(state, { sensorType }));
  const fetchDevices = useCallback(({ includeChildren = false }, canceller) => {
    dispatch(fetchDevicesAction({ sensorType, includeChildren }, canceller));
  }, [dispatch, fetchDevicesAction, sensorType]);

  return {
    devices,
    loading,
    lastFetch,
    error,
    fetchDevices,
  };
}

export default useDevices;