import EmptyCard from '../../../components/EmptyCard';

type Props = {
  title: string;
};

function NoDataIndication(props: Props) {
  return (
    <EmptyCard Icon={null}>
      <EmptyCard.Body>
        <EmptyCard.Title style={{marginBottom: 0}}>{props.title}</EmptyCard.Title>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

export default NoDataIndication;