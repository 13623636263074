
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Table from '../../../components/Table';

import { useComponentViewTracking } from '../../app/hooks';

import {
  fetchStreamingConfiguration,
  fetchGroup,
} from '../actions';

import {
  getNearestOrganisationAncestorId,
  getStreamingConfigurations,
} from '../selectors';

import {
  streamingDescription,
  enabled,
  streamType,
  resourceType,
  resourceLocation,
  resourceKey,
} from '../columns';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

import DeveloperCentreToolbar from '../components/DeveloperCentreToolbar';
import StreamingConfigurationActions from '../components/StreamingConfigurationActions';
import withNavigationGroupProps from '../components/withNavigationGroupProps';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc'
}];

const streamingActions = {
  dataField: 'actions',
  text: 'Actions',
  formatter: (value, {
    organisation_id,
    id,
  } = {}) => {
    return (
      <StreamingConfigurationActions
        organisationId={organisation_id}
        streamId={id}
      />
    );
  }
};

const columns = [
  streamingDescription,
  enabled,
  streamType,
  resourceType,
  resourceLocation,
  resourceKey,
  streamingActions,
];

function StreamingConfiguration({
  organisationId,
  streamingConfigurations=[],
  groupId,
  group={ id: groupId },
  fetchStreamingConfiguration,
  fetchGroup,
}) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // memoise callback
  const renderHeader = useCallback(props => {
    return (
      <DeveloperCentreToolbar
        tableProps={props}
        loading={loading}
        organisationId={organisationId}
      />
    );
  }, [organisationId]);

  // organisationId changes if a user has access to multiple organisation
  // groups e.g. they are partner or super admin
  // whenever this changes get the relevant organisation tokens
  useEffect(() => {
    if (organisationId) {
      (async () => {
        setLoading(true);
        await fetchStreamingConfiguration({ id: organisationId });
        setLoading(false);
      })();
    }
  }, [organisationId]);

  // when user changes to another organisation group e.g. they are
  // partner or super admin
  // look up details of that group
  // used to determine which organisationId to fetch tokens for
  useEffect( () => {
    if (group.id) {
      (async () => {
        await fetchGroup(group);
      })();
    }
  }, [group.id]);

  useComponentViewTracking('Streaming Configuration', 'organisationId', {
    organisationId,
  });

  const localisedColumns = useLocalisedColumns(columns);

  return (
    <Container fluid>
      <Table
        renderHeader={renderHeader}
        data={streamingConfigurations}
        keyField="id"
        defaultSorted={defaultSorted}
        columns={localisedColumns}
        noDataIndication={() => t('screens.organisations.streaming-configuration.no-data')}
        loading={loading}
      />
    </Container>
  );
}

const mapStateToProps = (state, { groupId }) => {
  const nearestOrganisationId = getNearestOrganisationAncestorId(state, groupId);
  return {
    groupId,
    organisationId: nearestOrganisationId,
    streamingConfigurations: getStreamingConfigurations(state, nearestOrganisationId),
  };
};
const mapDispatchToProps = {
  fetchStreamingConfiguration,
  fetchGroup,
};

export default withNavigationGroupProps(
  connect(mapStateToProps, mapDispatchToProps)(StreamingConfiguration)
);
