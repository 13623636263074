import { Dispatch } from "redux";
import { CALL_API } from "../../lib/apiMiddleware";
import * as ACTION_TYPES from './types/ActionTypes';
import * as DomainAction from './types/Action';
import { DomainKey } from "./types";

export function getDomainDataRequest(): DomainAction.GetDomainDataRequestAction {
  return {
    type: ACTION_TYPES.GET_DOMAIN_DATA_REQUEST,
  };
}

export function getDomainDataFailure(): DomainAction.GetDomainDataFailureAction {
  return {
    type: ACTION_TYPES.GET_DOMAIN_DATA_FAILURE,
  };
}

export function getDomainDataSuccess(key: DomainKey): DomainAction.GetDomainDataSuccessAction {
  return {
    type: ACTION_TYPES.GET_DOMAIN_DATA_SUCCESS,
    key,
  };
}

export function fetchDomainDataAccountStatus(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/domains/accountstatus',
      requestAction: getDomainDataRequest(),
      errorAction: getDomainDataFailure(),
      successAction: getDomainDataSuccess('accountStatus'),
    });
  };
}

export function fetchDomainDataUserTypes(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/usertypes',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('userTypes'),
  });
}

export function fetchDomainDataNotificationOptions(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/notifications',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('notificationOptions'),
  });
}

export function fetchDomainDataDeviceStatus(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/devicestatus',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('deviceStatus'),
  });
}

export function fetchDomainDataDeviceRoles(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/deviceroles',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('deviceRoles'),
  });
}

export function fetchDomainDataConditionCurves(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/conditioncurves',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('conditionCurves'),
  });
}

export function fetchDomainDataAlarmValidReasons(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/validalarms',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('alarmValidReasons'),
  });
}

export function fetchDomainDataAlarmInvalidReasons(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/invalidalarms',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('alarmInvalidReasons'),
  });
}

export function fetchDomainDataImpactCostCategories(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/impactcostcategories',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('impactCostCategories'),
  });
}

export function fetchDomainDataGatewayTypes(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/aptypes',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('gatewayTypes'),
  });
}

export function fetchDomainDataIsoTable(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/isotable',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('isotable'),
  });
}

export function fetchDomainDataThemes(): (dispatch: Dispatch<DomainAction.FetchDomainDataAction>) => DomainAction.FetchDomainDataAction {
  return dispatch => dispatch({
    type: CALL_API,
    method: 'get',
    endpoint: '/domains/theme',
    requestAction: getDomainDataRequest(),
    errorAction: getDomainDataFailure(),
    successAction: getDomainDataSuccess('themes'),
  });
}
