
import React, { Component } from 'react';

import log from '../lib/log';

function DefaultFallbackComponent({ error }) {
  return (
    // attempt to give some spacing around the default message
    <div className="align-self-center m-auto p-4">
      An error occured {error && error.id && (
        // display error id if known
        <span>(reference: <code>{error.id}</code>)</span>
      )}
    </div>
  );
};

function randomHexaDecimalStrings(...sizes) {
  return sizes.map(size => {
    return [...Array(size)]
      .map(() => Math.floor(Math.random() * 16).toString(16))
      .join('');
  }).join('-');
}

// API is consistent with BugSnag ErrorBoundary
// has `FallbackComponent` and `onError`
// link: https://web.archive.org/web/20201128044819/https://docs.bugsnag.com/platforms/javascript/react/#capturing-react-render-errors
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error) {
    // append an id to the error, so a user can let us know about it
    error.id = error.id || randomHexaDecimalStrings(4, 4);
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    // pass error to lifecycle handle if present
    const { onError } = this.props;
    // and allow onError to cancel the logging by returning false
    if (!onError || onError(error, errorInfo) !== false) {
      // log the error to an error reporting service
      log.error(error, { id: error.id, ...errorInfo });
    }
  }

  render() {
    const { error } = this.state;
    const {
      FallbackComponent = DefaultFallbackComponent,
      children,
    } = this.props;

    // render fallback on error
    if (error) {
      return <FallbackComponent error={error} />;
    }

    // or render children
    return children;
  }
}
