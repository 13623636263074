import React, { Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { FaHeartbeat } from 'react-icons/fa';

import useBaseChart, { defaultOptions } from './BaseChart.js';

import { fetchDeviceOverview } from '../../modules/equipment/actions.js';
import {
  getDeviceTimezone,
  getDevice,
  getDeviceOverview,
} from '../../modules/equipment/selectors';
import {
  oneMinute,
  oneHour,
  oneDay,
  oneWeek,
  dayLabels,
  hourLabels,
  getDateString,
} from '../../lib/utils.js';

function formatCondition(value) {
  switch (true) {
    case value > 2: return 'Serious';
    case value > 1: return 'Warning';
    case value > 0: return 'Healthy';
    default: return 'Not Running';
  }
}

export function getInitialOptions({ dataZoom, xAxis }=defaultOptions) {

  const now = Date.now();
  return {
    grid: {
      top: 120,
      bottom: 80,
      left: 55,
      right: 72,
    },
    dataZoom,
    xAxis: [{
      ...xAxis,
      show: false,
    }, {
      id: 'xAxisDays',
      type: 'category',
      position: 'bottom',
      // dynamically change the date labels
      data: [],
      axisTick: {
        show: true,
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        fontSize: 12,
        padding: [0, 4],
      },
      splitLine: {
        show: true,
        interval: 0,
        lineStyle: {
          // make lines a little transparent
          color: '#00000011',
          width: 1,
        },
      },
      // put lines over data
      z: 3,
    }],
    yAxis: [{
      // add non-inverted yAxis for display in the dataZoom
      id: 'yAxisMain',
      type: 'category',
      data: hourLabels,
      show: false,
    }, {
      id: 'yAxisCalendar',
      position: 'left',
      offset: 0,
      type: 'category',
      data: hourLabels,
      inverse: true,
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        fontSize: 12,
        margin: 10,
      },
      splitLine: {
        show: true,
        interval: 0,
        lineStyle: {
          // make lines a little transparent
          color: '#00000033',
          width: 1,
        },
      },
      // put lines over data
      z: 3,
    }],
    calendar: {
      id: 'months',
      top: 20,
      height: 80,
      left: 55,
      right: 72,
      range: [
        // show at least a month
        getDateString(now - 32 * oneDay),
        // until now
        getDateString(now),
      ],
      dayLabel: {
        show: true,
        fontSize: 12,
        firstDay: 0, // ensure Sunday is the first day
        margin: 10,
      },
      monthLabel: {
        show: true,
        fontSize: 12,
        margin: 5,
      },
      yearLabel: {
        show: true,
        fontSize: 18,
        margin: 30,
      },
      itemStyle: {
        borderWidth: 1,
        // make lines a little transparent
        borderColor: '#00000011',
      },
    },
    // visual map is a gradient legend of the value
    // and is used to set the colour range values
    visualMap: {
      min: 0,
      max: 3,
      show: false,
      // colour gradient for heatmap 0-3
      color: [
        '#ff7878', // 3
        '#eadf6c', // 2
        '#2bae23', // 1
        '#ffffff', // 0 (don't distinguish from "not running")
      ],
    },
    tooltip: {
      position: 'top',
      formatter: function({ seriesId, data=[] }={}) {
        if (seriesId === 'days') {
          const [dateString, value] = data;
          // value needs to be translated from [0,1,2,3] scale to 0-1 scale
          return `${dateString} (${
            dayLabels[new Date(dateString).getDay()]
          }): ${formatCondition(value)}`;
        }
        if (seriesId === 'hours') {
          const [dateString, invertedHourIndex, value] = data;
          // need to re-invert the hour index to get the correct label
          // as discussed in this commit, this is probably a bug in eCharts
          return `${dateString} (${
            dayLabels[new Date(dateString).getDay()]
          }) ${hourLabels[23-invertedHourIndex]}: ${formatCondition(value)}`;
        }
      },
    },
    series: [
      {
        id: 'hours-for-data-zoom',
        type: 'line',
        step: true,
        // hide line from main grid
        // todo: try to ensure line is never on grid to start with
        symbol: 'none',
        lineStyle: { width: 0 },
      },
      {
        id: 'hours',
        type: 'heatmap',
        xAxisId: 'xAxisDays',
      },
      {
        id: 'days',
        type: 'heatmap',
        coordinateSystem: 'calendar',
        xAxisId: 'xAxisDays',
      },
    ],
    graphic: {
      elements: [{
        id: 'timezone_text',
        type: 'text',
        right: 0,
        top: 120,
        style: {
          textAlign: 'center',
          // additional options documented in:
          // link: https://ecomfe.github.io/zrender-doc/public/api.html#zrenderdisplayable
          fontSize: 12,
          textLineHeight: 16,
        },
      }],
    },
  };
}

// bucketification helpers
const bucketifyTimeseries = getBucket => {
  return timeseries => {

    // group values into time buckets
    const buckets = timeseries.reduce((acc, [timestamp, condition]) => {
      // group to maximum value in the bucket
      const timeBucket = getBucket(timestamp);
      acc[timeBucket] = acc[timeBucket]
        ? Math.max(acc[timeBucket], condition)
        : condition;
      return acc;
    }, {});

    // return new timeseries (change key strings to numbers)
    return Object.entries(buckets).map(([timeBucket, value]) => {
      return [Number(timeBucket), value];
    });
  };
};

export const groupByHour = bucketifyTimeseries(timestamp => {
  // name bucket by rounding down minutes, seconds, ms to zero
  return new Date(timestamp).setUTCMinutes(0, 0, 0).valueOf();
});

export const groupByDay = bucketifyTimeseries(timestamp => {
  // name bucket by rounding down minutes, seconds, ms to zero
  return new Date(timestamp).setUTCHours(0, 0, 0, 0).valueOf();
});

function AICalendar({
  deviceId,
  maximumViewedDateRange,
  overview = [],
  dateRange = {},
  deviceTimezone,
  deviceCreatedAtTimestamp,
  fetchDeviceOverview,
  ...props
}) {

  const [fetching, setFetching] = useState(false);

  // fetch overview on device load or change
  useEffect(() => {
    if (deviceId) {
      (async () => {
        try {
          setFetching(true);
          await fetchDeviceOverview({ id: deviceId });
          setFetching(false);
        }
        catch(e) {
          setFetching(false);
        }
      })();
    }
  }, [deviceId]);

  const [BaseChart, {
    getChart,
    useChartUpdateEffect,
    useChartUpdateOnChartEvent,
  }] = useBaseChart(getInitialOptions);

  const updateTimezone = useCallback(() => {
    return {
      graphic: {
        elements: [{
          id: 'timezone_text',
          style: {
            text: `\nEquipment\ntimezone:\n\n${
              deviceTimezone
                ? deviceTimezone
                  .replace(/\//g, '/\n') // break on slash
                  .replace(/_/g, '\n') // new line for each word
                : 'unknown'
            }`,
          },
        }]
      },
    };
  }, [deviceTimezone]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateTimezone);

  const updateVisualMapDateRange = useCallback(({ dataZoom=[] }={}) => {

    const {
      // get start and end from dataZoom (update on dataZoom change)
      // default to passed dateRange values if needed
      startValue: startTime = dateRange.startTime,
      endValue: endTime = dateRange.endTime,
    } = dataZoom.find(({ id }) => id === 'xAxisMain') || {};

    const axisDataSet = new Set();
    let looptime = startTime;
    // loop through days from end to start (so it is sorted vertically old↓new)
    // don't move 24h per loop: ensure no 23 hour days are skipped due to DST
    do {
      const dateString = getDateString(looptime);
      axisDataSet.add(dateString);
      looptime = new Date(dateString).valueOf() + 30 * oneHour; // move next date
    } while(looptime < endTime);
    const axisData = Array.from(axisDataSet);
    const dateRangeInDays = Math.ceil((endTime - startTime)/oneDay);
    // convert the timeseries into histogram bins
    return {
      xAxis: [{
        id: 'xAxisDays',
        data: axisData,
        axisLabel: {
          formatter: dateRangeInDays > 365
            // print year
            ? dateString => dateString.replace(/-\d{2}-\d{2}$/, '')
            : dateRangeInDays > 31
              // print month
              ? dateString => dateString.replace(/-\d{2}$/, '')
              // print day
              : dateString => dateString,
          // todo: fix interval labels to not overlap text when chart width is small
          interval: dateRangeInDays > 365
            // mark first day of each year
            ? (index, dateString) => (
              new Date(dateString).getMonth() === 0 &&
              new Date(dateString).getDate() === 1
            )
            : dateRangeInDays > 31
              // mark first day of each month
              ? (index, dateString) => new Date(dateString).getDate() === 1
              : dateRangeInDays > 7
                // mark every Sunday
                ? (index, dateString) => new Date(dateString).getDay() === 0
                : 'auto',
        },
        splitLine: {
          // show no lines if there will be far too many
          show: dateRangeInDays >= 3 * 365 ? false : true,
          // adjust interval if a large date range covered
          interval: dateRangeInDays >= 100
            // show weeks (mark interval at day 3, Wednesday: the week's middle
            // which eCharts uses to draw the category bounds around)
            ? (index, dateString) => new Date(dateString).getDay() === 3
            // show days
            : 0,
        },
      }],
    };
  }, [dateRange.startTime, dateRange.endTime]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateVisualMapDateRange);
  useChartUpdateOnChartEvent('dataZoom', updateVisualMapDateRange);

  const updateSamples = useCallback(() => {

    // get "now" but in the equipment's timezone (represented as UTC)
    const nowDate = new Date();
    const nowAtEquipment = nowDate.valueOf() - nowDate.getTimezoneOffset() * oneMinute;
    // transform continuous values into discrete values
    const conditionSeries = overview
      .flatMap(({ date, overall_condition_state=[] }) => {
        // spread out hourly condition with generated timestamps
        return overall_condition_state.map((overall_condition, hourIndex) => {
          return [
            // by setting the date with a date string (eg "2020-01-01")
            // JS assumes this is UTC time
            // so here we convert all overview times to UTC for processing simplicity
            new Date(date).setUTCHours(hourIndex),
            overall_condition,
          ];
        });
      })
      // filter out future sample data
      .filter(([timestamp]) => timestamp < nowAtEquipment);

    // get hourly for all, and daily from hourly
    // so we're not re-bucketing the whole series for both
    const hourlyConditionSeries = groupByHour(conditionSeries);
    const dailyConditionSeries = groupByDay(hourlyConditionSeries);

    return {
      series: [
        {
          id: 'hours-for-data-zoom',
          data: hourlyConditionSeries.sort((a, b) => a[0] - b[0]),
        },
        {
          id: 'hours',
          data: hourlyConditionSeries
            // remove not running condition hour
            .filter(([, condition]) => condition > 0)
            .map(([timestamp, value]) => {
              // make an [xCategoryIndex, yCategoryValue, value] map
              const date = new Date(timestamp);
              // note: we need to invert the hour value to display it on yAxis
              // in the correct position, this is probably a bug in eCharts
              // and may need to be re-fixed after upgrading to > eCharts v4.2
              return [getDateString(date), 23 - date.getUTCHours(), value];
            }),
        },
        {
          id: 'days',
          data: dailyConditionSeries
            // remove not running condition day
            .filter(([, condition]) => condition > 0)
            .map(([timestamp, value]) => {
              return [getDateString(timestamp), value];
            }),
        },
      ],
    };
  }, [overview]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateSamples);

  // update calendar range
  const updateCalendarDateRange = useCallback(({ calendar: [calendar] }) => {
    const chart = getChart();
    const chartWidth = chart && chart.getWidth();
    if (chartWidth && deviceCreatedAtTimestamp) {
      // compute how much room the month labels have
      const calendarWidth = chartWidth - calendar.left - calendar.right;
      const calendarWeeks = Math.ceil((Date.now() - deviceCreatedAtTimestamp) / oneWeek);
      return {
        calendar: [{
          id: 'months',
          itemStyle: {
            // make calendar day borders smaller when then are more days to display
            // but with maximum width of 1
            borderWidth: Math.min(1, Math.round(25 * calendarWidth / calendarWeeks )/100),
          },
          monthLabel: {
            nameMap: calendarWidth / calendarWeeks > 7.5
              ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              : ['Jan', '', '', 'Apr', '', '', 'Jul', '', '', 'Oct', '', '']
          },
          range: [
            // show at least the day of device creation
            getDateString(deviceCreatedAtTimestamp),
            // until now
            getDateString(Date.now()),
          ],
        }],
      };
    }
  }, [getChart, deviceCreatedAtTimestamp]);

  useChartUpdateEffect(updateCalendarDateRange);

  // update dataZoom range
  const firstOverviewTimestamp = useMemo(() => overview.length > 0 ? (new Date(overview[0].date)).getTime() : Date.now(), [overview.length]);
  const [altMaximumViewedDateRange, setMaximumViewedDateRange] = useState(maximumViewedDateRange);
  useEffect(() => {
    if (deviceCreatedAtTimestamp) {
      setMaximumViewedDateRange({
        startTime: Math.min(maximumViewedDateRange.startTime, deviceCreatedAtTimestamp, firstOverviewTimestamp),
        endTime: maximumViewedDateRange.endTime,
      });
    }
  }, [deviceCreatedAtTimestamp, maximumViewedDateRange.endTime, firstOverviewTimestamp]);

  return (
    <BaseChart
      // colour here is FitMachine-like red
      header={<Fragment><FaHeartbeat color="#cc5847" /> Health Overview</Fragment>}
      namespace="health-overview"
      dateRange={dateRange}
      maximumViewedDateRange={altMaximumViewedDateRange}
      // set 'has more data' indicator to false on this chart if data is available
      // as there is no pagination on the device overview endpoint at present
      hasMore={overview.length > 0}
      // ensure that the setDateRange throttle mode
      // doesn't fire the update continuously while dragging the dataZoom
      // why? this dataZoom can have more data (overviews) than other charts (samples)
      // and firing a date change should trigger those other charts to downloads more data
      // so we should do this sparingly
      setDateThrottleMode="at_end"
      // pass through original props
      deviceId={deviceId}
      {...props}
      // override loading indicator
      stillFetching={fetching}
    />
  );
}

const mapStateToProps = (state, { deviceId }) => {
  const device = getDevice(state, deviceId);
  return {
    deviceCreatedAtTimestamp: device && device.created_at && new Date(device.created_at).getTime(),
    deviceTimezone: getDeviceTimezone(state, deviceId),
    overview: getDeviceOverview(state, deviceId),
  };
};
const mapDispatchToProps = {
  fetchDeviceOverview,
};

export default connect(mapStateToProps, mapDispatchToProps)(AICalendar);
