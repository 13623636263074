import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import HomeCard from './HomeCard';
import Table from '../Table';
import { usePendingEventsManagementReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import {
  equipmentNameLink,
  scheduledDateBadge,
  estHoursLost,
  estHoursLostAvoided,
  estEventCosts,
  estEventCostsAvoided,
  estEventCostsPrevented,
} from './columns';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';

function PendingActionEvents({ currency }) {
  const { t } = useTranslation();
  const pendingEventsManagementReport = usePendingEventsManagementReport();

  const columns = useMemo(() => [
    equipmentNameLink,
    scheduledDateBadge,
    estHoursLost,
    estHoursLostAvoided,
    estEventCosts(currency),
    estEventCostsAvoided(currency),
    estEventCostsPrevented(currency),
  ], [currency]);
  const localisedColumns = useLocalisedColumns(columns);
  if(!pendingEventsManagementReport) return null;

  const {_state_: { loading, error } = {}, items = []} = pendingEventsManagementReport || {};
  return (
    <HomeCard
      header={t('home.common.pending-action-events')}
      headerLink={{
        to: '/events?status=scheduled,in_progress',
        text: t('home.common.all-pending-action-events')
      }}
      loading={loading}
      error={error}
    >
      <div className="smaller-font-size-table-container">
        <Table
          data={items}
          keyField="alarm_id"
          loading={loading}
          columns={localisedColumns}
          condensed
        />
      </div>
    </HomeCard>
  );
}

export default PendingActionEvents;
