
import createReducer from '../../lib/createReducer';
import moment from 'moment';

import * as USER_ACTION_TYPES from '../user/types/ActionTypes';
import * as ACTION_TYPES from './types/ActionTypes';
import { BLUETOOTH_STATES } from './constants';
import { cleanTemplate, mapDeviceFromApi, isWifiOnlyFitMachine } from './utils';
import { DEFAULT_STATE, DEFAULT_DEVICE_STATE, SENSOR_TYPE } from './constants.ts';

import {
  incrementCounter,
  decrementCounter,
  upsertObjectItems,
  upsertObjectItemById,
  upsertListItem,
  upsertItem,
} from '../../lib/reducerUtils';

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.REQUEST_BEGIN](state, { device }) {
    if (device) {
      // apply changes onto the found device or draft or default state
      const { cloudDevices={}, drafts=[] } = state;
      // search drafts by serial
      const previousDraft = drafts
        .find(({ serial }) => serial === device.serial);
      const previousDevice = cloudDevices[device.id]
        // or search by serial
        || Object.values(cloudDevices)
          .find(({ serial }) => serial === device.serial)
        // or return new device
        || {};
      const deviceId = device.id || previousDevice.id;
      if (deviceId) {
        return {
          ...state,
          cloudDevices: {
            ...state.cloudDevices,
            [deviceId]: upsertItem(previousDevice, {
              // merge embedded list items by id field by default
              loading: incrementCounter(previousDevice.loading),
            }),
          },
        };
      }
      else if (previousDraft) {
        return {
          ...state,
          drafts: upsertListItem(drafts, {
            // merge list item
            ...previousDraft,
            loading: incrementCounter(previousDevice.loading),
          }, {
            // merge by serial
            match: (item, newItem) => item['serial'] === newItem['serial'],
          }),
        };
      }
      else {
        return state;
      }
    } else {
      // no device - set root loading flag
      return {
        ...state,
        loading: true,
      };
    }
  },

  [ACTION_TYPES.REQUEST_FAIL](state, { device, response }) {
    if (device) {
      // apply changes onto the found device or draft or default state
      const { cloudDevices={}, drafts=[] } = state;
      // search drafts by serial
      const previousDraft = drafts
        .find(({ serial }) => serial === device.serial);
      const previousDevice = cloudDevices[device.id]
        // or search by serial
        || Object.values(cloudDevices)
          .find(({ serial }) => serial === device.serial)
        // or return new device
        || {};
      const deviceId = device.id || previousDevice.id;
      if (deviceId) {
        return {
          ...state,
          cloudDevices: {
            ...state.cloudDevices,
            [deviceId]: upsertItem(previousDevice, {
              // merge embedded list items by id field by default
              loading: decrementCounter(previousDevice.loading),
              error: response,
            }),
          },
        };
      }
      else if (previousDraft) {
        return {
          ...state,
          drafts: upsertListItem(drafts, {
            // merge list item
            ...previousDraft,
            loading: incrementCounter(previousDevice.loading),
          }, {
            // merge by serial
            match: (item, newItem) => item['serial'] === newItem['serial'],
          })
        };
      }
      else {
        return state;
      }
    } else {
      // no device - set root loading flag
      return {
        ...state,
        loading: false,
        error: response
      };
    }
  },
  [ACTION_TYPES.RECEIVE_CLOUD_DEVICE_LIST](state, { response, options = {}, }) {
    const { cloudDevices = {} } = state;
    const { _embedded: { devices=[] } = {} } = response;
    const { filter, sensorType = SENSOR_TYPE.FITMACHINE } = options;
    const archived = filter === 'archived';
    const mappedDevices = devices.map(mapDeviceFromApi);
    const devicesIds = devices.map(({id}) => id);
    return {
      ...state,
      // store a reference to all devices in the root-level list
      ...!archived ? {
        // for active ids
        idsActive: devicesIds,
      } : {
        // and archived ids
        idsArchived: devicesIds,
      },
      ...sensorType === SENSOR_TYPE.ALL ? {
        idsAll: devicesIds,
      } : sensorType === SENSOR_TYPE.FITPOWER ? {
        idsFitpower: devicesIds,
      } : {
        idsFitmachine: devicesIds,
      },
      // create a copy of the devices as an array, then use the reducer helpers
      // to upsert the relevant device props, filtered to either the active or archived devices
      // then return the result in the expected object keyed by id format
      // todo: change this to a list when we come to removing technical debt
      // and combining the app and dashboard redux states
      cloudDevices: upsertObjectItems(cloudDevices, mappedDevices, 'id', {
        // don't filter items out of the root entity object
        filter: false,
        // overwrite any previous data, and fallback to default state props if no match is found
        merge: (previousDevice = DEFAULT_DEVICE_STATE, device) => {
          return {
            ...previousDevice,
            ...device,
            // if the serial has changed, then set the bluetooth state to unknown
            ...(previousDevice.serial && previousDevice.serial !== device.serial) && {
              bluetoothState: BLUETOOTH_STATES.UNKNOWN,
            },
          };
        },
      }),
      drafts: state.drafts && state.drafts.filter(draft => {
        // filter out drafts that have a corresponding cloud device
        return !mappedDevices.find(({ serial }) => serial === draft.serial);
      }),
      // todo: separate out loading state for active vs archived device lists
      loading: false,
      error: null,
      lastFetch: moment(),
    };
  },

  // allow group devices to add to the root-level device map
  // RECEIVE_GROUP_MEMBERS is not defined in action types.
  // [ACTION_TYPES.RECEIVE_GROUP_MEMBERS](state, { response }) {
  //   const { cloudDevices = {} } = state;
  //   const devices = (response._embedded && response._embedded.devices) || [];
  //   const mappedDevices = devices.map(mapDeviceFromApi);
  //   return {
  //     ...state,
  //     cloudDevices: upsertObjectItems(cloudDevices, mappedDevices, 'id', {
  //       // don't remove any existing devices
  //       filter: false,
  //     }),
  //   };
  // },

  [ACTION_TYPES.RECEIVE_DEVICE_INFO](state, { device, response }) {
    // apply changes onto the found device or the default device state
    const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
    const idsActive = state.idsActive || [];
    const idsArchived = state.idsArchived || [];
    if(device.archived) {
      if(idsArchived.indexOf(device.id) < 0) idsArchived.push(device.id);
    } else {
      if(idsActive.indexOf(device.id) < 0) idsActive.push(device.id);
    }
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        // add new device props
        ...mapDeviceFromApi(response),
        // add other things about state that shouldn't be lumped in here but are
        // todo: add these props under a specific namespace: "_state"
        loading: decrementCounter(previousDevice.loading),
        error: null,
        lastFetch: moment(),
      }, device.id),
      idsActive,
      idsArchived,
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_TAGS](state, { device={}, response={} }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // merge embedded list items by id field by default
          _embedded: {
            tags: response,
          },
        }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_OVERVIEW](state, { device, response={} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          overview: (response._embedded && response._embedded.overview) || [],
          // add embedded metadata object
          overviewMetadata: {
            timezone: response.timezone,
          },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_RUNTIME](state, { device, response={} }) {
    // apply changes onto the found device or the default device state
    const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          runtime: (response._embedded && response._embedded.runtime) || [],
          // add embedded metadata object
          runtimeMetadata: {
            timezone: response.timezone,
          },
        },
      }, {
        upsertRelated: {
          runtime: (
            previousDevice._embedded &&
            previousDevice._embedded.runtimeMetadata &&
            previousDevice._embedded.runtimeMetadata.timezone
          ) === response.timezone
            ? {
              // timezone is equivalent, merge data
              match: (oldItem, newItem) => oldItem.date === newItem.date, // merge by date
            } : {
              // timezone is different, replace data
              match: () => false,
            },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_NOTIFICATION_THRESHOLDS](state, { device, response=[] }) {
    // apply changes onto the found device or the default device state
    const { [device.id]: previousDevice = {} } = state.cloudDevices;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem({
          ...DEFAULT_DEVICE_STATE,
          ...device,
          ...previousDevice,
        }, {
          // merge embedded list items by id field by default
          _embedded: {
            alarmsettings: (response._embedded && response._embedded.alarmsettings) || [],
          },
        }),
      },
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_EVENTS](state, { device, response={} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          events: (response._embedded && response._embedded.events) || [],
        },
      }, device.id, {
        // do not filter logs (merge multiple filtered lists here)
        // we don't expect any entries here to be removed
        upsertRelated: {
          events: {
            filter: false,
          },
        },
      }),
    };
  },

  [ACTION_TYPES.REQUEST_DEVICE_ALARMS](state, { device={} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        // add flag to read fetching status
        _embedded: {
          alarms_state: {
            loading: true,
          },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.DEVICE_ALARMS_FAILURE](state, { device={}, response='' }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        // add flag to read fetching status change
        _embedded: {
          alarms_state: {
            loading: false,
            error: `${response}`,
          },
        },
      }, device.id)
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_ALARMS](state, { device={}, response={} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          alarms: (response._embedded && response._embedded.alarms) || [],
          // add flag to read fetching status change
          alarms_state: {
            loading: false,
            error: null,
            lastFetch: Date.now(),
          },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.REQUEST_DEVICE_ALARM_EVENTS](state, { device={} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          alarm_events_state: {
            loading: true,
          },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.DEVICE_ALARM_EVENTS_FAILURE](state, { device={}, response='' }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          alarm_events_state: {
            loading: false,
            error: `${response}`,
          },
        },
      }, device.id)
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_ALARM_EVENTS](state, { device={}, response={} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          alarm_events: (response._embedded && response._embedded.alarms) || [],
          alarm_events_state: {
            loading: false,
            error: null,
            lastFetch: Date.now(),
          },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_IMAGES](state, { device={}, response={} }) {
    const { _embedded: { images=[] }={} } = response;
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        images,
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_IMAGE_DELETE](state, { device={}, image={} }) {

    const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
    const { images: previousImages } = previousDevice;

    // skip if device is not found
    if (!device.id || !previousDevice.id) {
      return state;
    }

    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        // filter the current device images to not include this device
        images: previousImages && previousImages.filter(({ id }) => id !== image.id),
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_ERRORS](state, { device={}, response={} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          fmerrors: response && response._embedded && response._embedded.fmerrors,
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_MUTE_STATUS_UPDATE](state, { device={}, mute_advisory_for }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        mute_advisory_for,
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_FIRMWARE_VERSION](state, { device={}, response }) {
    const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
    return {
      ...state,
      cloudDevices: {
        ...state.cloudDevices,
        [device.id]: upsertItem(previousDevice, {
          loading: decrementCounter(previousDevice.loading),
          error: null,
          firmwareVersion: response,
        }, { match: item => item.id === device.id }),
      },
    };
  },

  // The actions are commented, so there is no need to leave the corresponding reducers here.
  // [ACTION_TYPES.REQUEST_WIFI_PROFILES](state, { device={} }) {
  //   const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
  //   return {
  //     ...state,
  //     cloudDevices: {
  //       ...state.cloudDevices,
  //       [device.id]: upsertItem(previousDevice, {
  //         loading: incrementCounter(previousDevice.loading),
  //         wifiProfiles: null,
  //       }, { match: item => item.id === device.id }),
  //     },
  //   };
  // },

  // [ACTION_TYPES.RECEIVE_WIFI_PROFILES](state, { device={}, response=[] }) {
  //   const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
  //   return {
  //     ...state,
  //     cloudDevices: {
  //       ...state.cloudDevices,
  //       [device.id]: upsertItem(previousDevice, {
  //         loading: decrementCounter(previousDevice.loading),
  //         error: null,
  //         wifiProfiles: response.sort((a, b) => a.priority - b.priority),
  //       }, { match: item => item.id === device.id }),
  //     },
  //   };
  // },

  // [ACTION_TYPES.REQUEST_WIFI_CONNECTIVITY](state, { device={} }) {
  //   const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
  //   return {
  //     ...state,
  //     cloudDevices: {
  //       ...state.cloudDevices,
  //       [device.id]: upsertItem(previousDevice, {
  //         loading: incrementCounter(previousDevice.loading),
  //         wifiConnectivity: null,
  //       }, { match: item => item.id === device.id }),
  //     },
  //   };
  // },

  // [ACTION_TYPES.RECEIVE_WIFI_CONNECTIVITY](state, { device={}, response }) {
  //   const { cloudDevices: { [device.id]: previousDevice={} }={} } = state;
  //   return {
  //     ...state,
  //     cloudDevices: {
  //       ...state.cloudDevices,
  //       [device.id]: upsertItem(previousDevice, {
  //         loading: decrementCounter(previousDevice.loading),
  //         error: null,
  //         wifiConnectivity: response,
  //       }, { match: item => item.id === device.id }),
  //     },
  //   };
  // },

  [ACTION_TYPES.UPDATE_DRAFT](state, { index, draft, overwrite }) {
    const newState = {
      ...state,
      drafts: [ ...state.drafts || [] ]
    };

    if (index === newState.drafts.length) {
      newState.drafts.push(draft);
    } else if (overwrite) {
      newState.drafts[index] = { ...draft };
    } else {
      newState.drafts[index] = { ...newState.drafts[index], ...draft };
    }

    return newState;
  },

  [ACTION_TYPES.DISCARD_DRAFT](state, { index }) {
    const newState = {
      ...state,
      drafts: [ ...state.drafts || [] ]
    };

    if (index < newState.drafts.length) {
      newState.drafts.splice(index, 1);
    }

    return newState;
  },

  [ACTION_TYPES.CREATE_TEMPLATE](state, { name, template }) {
    const newState = {
      ...state,
      templates: { ...state.templates }
    };

    newState.templates[name] = cleanTemplate(template);

    return newState;
  },

  [ACTION_TYPES.DELETE_TEMPLATE](state, { name }) {
    const newState = {
      ...state,
      templates: { ...state.templates }
    };

    delete newState.templates[name];

    return newState;
  },

  [ACTION_TYPES.UPDATE_NEARBY](state, { devices }) {
    const newState = { ...state };

    const cloneAndAssignState = device => {
      const newDevice = { ...device };

      // check first if it's a non wifi
      if (
        // don't check this list over and over if we already know
        newDevice.bluetoothState === BLUETOOTH_STATES.UNKNOWN &&
        isWifiOnlyFitMachine(newDevice.serial)
      ) {
        newDevice.bluetoothState = BLUETOOTH_STATES.NOT_AVAILABLE;
      } else if (devices.hasOwnProperty(newDevice.serial)) {
        newDevice.bluetoothState = BLUETOOTH_STATES.NEARBY;
      } else if (device.bluetoothState !== BLUETOOTH_STATES.NOT_AVAILABLE) {
        newDevice.bluetoothState = BLUETOOTH_STATES.NOT_NEARBY;
      }

      return newDevice;
    };

    // update drafts
    newState.drafts = state.drafts && state.drafts.map(cloneAndAssignState);

    // update devices
    newState.cloudDevices = {};
    Object.keys(state.cloudDevices).forEach(id => {
      newState.cloudDevices[id] = cloneAndAssignState(state.cloudDevices[id]);
    });

    return newState;
  },

  [ACTION_TYPES.RECEIVE_ARCHIVE_DEVICE](state, { device }) {
    const {
      idsActive,
      idsArchived,
    } = state;
    return {
      ...state,
      // remove from active list
      idsActive: idsActive && idsActive.filter(id => id !== device.id),
      // add to archived list
      idsArchived: [...idsArchived || [], device.id],
    };
  },

  [ACTION_TYPES.RECEIVE_UNARCHIVE_DEVICE](state, { device }) {
    const {
      idsActive,
      idsArchived,
    } = state;
    return {
      ...state,
      // add to active list
      idsActive: [...idsActive || [], device.id],
      // remove from archived list
      idsArchived: idsArchived && idsArchived.filter(id => id !== device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_STATUS](state, { response }) {
    const statusOptions = response?._embedded?.items || [];
    const currentStatusOptions = state.status || [];
    if(currentStatusOptions.length === 0) return {
      ...state,
      status: statusOptions.map(option => ({ option, selected: true }))
    };
    return {
      ...state,
      status: statusOptions.map(option => {
        const foundStatusOption = currentStatusOptions.find(item => item.option === option);
        if(!foundStatusOption) return undefined;
        // Keep the original 'selected' option unchanged if exists when device status comes.
        return { option: foundStatusOption.option, selected: foundStatusOption.selected };
      }),
    };
  },

  [ACTION_TYPES.SET_DEVICE_SELECTED_STATUS](state, { selected }) {
    const newStatus = (state.status || []).map(item => ({ option: item.option, selected: !!selected.includes(item.option) }));
    return {
      ...state,
      status: newStatus
    };
  },

  [ACTION_TYPES.REQUEST_DEVICE_CONDITION_CURVES](state) {
    return {
      ...state,
      conditionCurves: {
        loading: true,
        error: null,
        items: state.conditionCurves?.items || {},
      }
    };
  },
  [ACTION_TYPES.DEVICE_CONDITION_CURVES_FAILURE](state, { error }) {
    return {
      ...state,
      conditionCurves: {
        loading: false,
        error,
        items: state.conditionCurves?.items || {},
      }
    };
  },
  [ACTION_TYPES.RECEIVE_DEVICE_CONDITION_CURVES](state, { response }) {
    return {
      ...state,
      conditionCurves: {
        loading: false,
        error: null,
        items: response._embedded?.items || {},
      }
    };
  },
  [ACTION_TYPES.REQUEST_DEVICE_ROLES](state) {
    return {
      ...state,
      deviceRoles: {
        loading: true,
        error: null,
        items: state.deviceRoles?.items || [],
      }
    };
  },
  [ACTION_TYPES.DEVICE_ROLES_FAILURE](state, { error }) {
    return {
      ...state,
      deviceRoles: {
        loading: false,
        error,
        items: state.deviceRoles?.items || [],
      }
    };
  },
  [ACTION_TYPES.RECEIVE_DEVICE_ROLES](state, { response }) {
    return {
      ...state,
      deviceRoles: {
        loading: false,
        error: null,
        items: response._embedded?.items || [],
      }
    };
  },
  [ACTION_TYPES.REQUEST_DEVICE_IMPACT_CONFIG](state, { device = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_config: {
            _state_: {
              loading: true,
            },
          },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.DEVICE_IMPACT_CONFIG_FAILURE](state, { device = {}, response = '' }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_config: {
            _state_: {
              loading: false,
              error: response,
            },
          },
        },
      }, device.id),
    };
  },

  [ACTION_TYPES.RECEIVE_DEVICE_IMPACT_CONFIG](state, { device = {}, response = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_config: {
            items: response._embedded?.settings || [],
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_DELETE_DEVICE_IMPACT_CONFIG](state, { device = {}, config={} }) {
    const newConfigData = state.cloudDevices[device.id]?._embedded?.impact_config?.items?.filter(item => item.id !== config.id);
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_config: {
            items: newConfigData,
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_ADD_DEVICE_IMPACT_CONFIG](state, { device = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_config: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_DEVICE_IMPACT_CONFIG](state, { device = {}, config = {}, data = {} }) {
    const configData = state.cloudDevices[device.id]?._embedded?.impact_config?.items || [];
    const newConfigData = configData.map(item => (item.id === config.id ? {...item, ...data} : item));
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_config: {
            items: newConfigData,
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
          },
        },
      }, device.id),
    };
  },
  [ACTION_TYPES.REQUEST_DEVICE_IMPACT_SUMMARY](state, { device = {}}) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_summary: {
            _state_: {
              loading: true,
              error: null
            }
          }
        }
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_DEVICE_IMPACT_SUMMARY](state, { device = {}, response = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_summary: {
            items: response._embedded?.events || [],
            _state_: {
              loading: false,
              error: null
            }
          }
        }
      }, device.id),
    };
  },
  [ACTION_TYPES.DEVICE_IMPACT_SUMMARY_FAILURE](state, { device = {}, response = '' }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          impact_summary: {
            _state_: {
              loading: false,
              error: response,
              lastFetch: Date.now(),
            }
          }
        }
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_DEVICE_LOCALE](state, {device = {}, response}) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          locale: response,
        }
      }, device.id)
    };
  },
  [ACTION_TYPES.REQUEST_DEVICE_WATCHERS](state, { device = {}}) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          watchers: {
            _state_: {
              loading: true,
              error: null
            }
          }
        }
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_DEVICE_WATCHERS](state, { device = {}, response = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          watchers: {
            items: response._embedded?.watchers || [],
            _state_: {
              loading: false,
              error: null
            }
          }
        }
      }, device.id),
    };
  },
  [ACTION_TYPES.DEVICE_WATCHERS_FAILURE](state, { device = {}, response = '' }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          watchers: {
            _state_: {
              loading: false,
              error: response,
              lastFetch: Date.now(),
            }
          }
        }
      }, device.id),
    };
  },
  [ACTION_TYPES.REQUEST_DEVICE_SETTINGS](state, { device = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          settings: {
            _state_: {
              loading: true,
              error: null,
            },
          }
        }
      }, device.id)
    };
  },
  [ACTION_TYPES.DEVICE_SETTINGS_FAILURE](state, { device = {}, response = '' }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          settings: {
            _state_: {
              loading: false,
              error: response,
            },
          }
        }
      }, device.id)
    };
  },
  [ACTION_TYPES.RECEIVE_DEVICE_SETTINGS](state, { device = {}, response = {} }) {
    for(const key of Object.keys(response)) {
      if(key[0] === '_') delete response[key];
    }
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          settings: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            items: response,
          }
        }
      }, device.id)
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_DEVICE_SETTINGS](state, { device = {}, data = {} }) {
    const settingItems = { ...(state.cloudDevices[device.id]?._embedded?.settings?.items || {}) };
    for(const [key, value] of Object.entries(data)) {
      settingItems[key].value = value;
    }
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          settings: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            items: settingItems,
          }
        }
      }, device.id)
    };
  },
  [ACTION_TYPES.REQUEST_DEVICE_LINKS](state, { device = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          links: {
            _state_: {
              loading: true,
              error: null,
            },
          },
        },
      }, device.id),
    };
  },
  [ACTION_TYPES.DEVICE_LINKS_FAILURE](state, { device = {}, response = '' }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          links: {
            _state_: {
              loading: false,
              error: response,
            },
          },
        },
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_DEVICE_LINKS](state, { device ={}, response = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          links: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            items: response._embedded?.links || [],
          },
        },
      }, device.id),
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_DEVICE_LINKS](state, { device = { }, data = {} }) {
    return {
      ...state,
      cloudDevices: upsertObjectItemById(state.cloudDevices, {
        _embedded: {
          links: {
            _state_: {
              loading: false,
              error: null,
              lastFetch: Date.now(),
            },
            // @TODO: update items.
          },
        },
      }, device.id),
    };
  }
});
