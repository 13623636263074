import { useState, useRef, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { Dropdown, Form, Row, Col } from 'react-bootstrap';
import { IoIosNotifications } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import FormModal from '../../../components/FormModal';
import { getFormValues, resetFormValues } from '../../../lib/utils';
import { createAlarm } from '../../alarm/actions';
import { fetchDeviceAlarms } from '../actions';
import useActivityView from "../hooks/useActivityView";

function ReportEventModal({ device }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [submitting, setSubmitting] = useState(false);
  const [valid, setValid] = useState(false);
  const view = useActivityView();

  const handleChange = (e) => {
    const formValues = getFormValues(e, formRef.current);
    const { eventName, eventDescription } = formValues;
    setValid(!!(eventName.trim() && eventDescription.trim()));
  };

  const handleSubmit = useCallback(async (e) => {
    const formValues = getFormValues(e, formRef.current);
    setSubmitting(true);
    try {
      const { eventName, eventDescription } = formValues;
      if(!eventName.trim() || !eventDescription.trim()) return;
      await dispatch(createAlarm(device, { eventName, eventDescription }));
      await dispatch(fetchDeviceAlarms(device, { view: view === 'events' && 'event' }));
      resetFormValues(formRef);
    } catch(e) {
    } finally {
      setSubmitting(false);
    }
  }, [device, createAlarm, fetchDeviceAlarms]);

  const renderForm = () => {
    return (
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        onChange={handleChange}
      >
        <Row>
          <Col xs={12}>
            <Form.Group as={Row}>
              <Form.Label column sm="4">
                {t('components.equipment.report-event-modal.summary')}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  name="eventName"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4">
                {t('components.equipment.report-event-modal.detailed-description')}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="textarea"
                  name="eventDescription"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    );
  };

  const renderFormHeader = () => {
    return (
      <>
        <h3>{t('components.equipment.report-event-modal.manual-alarm')}</h3>
        <div>{device.equipment_name}
        </div>
      </>
    );
  };

  return (
    <FormModal
      header={renderFormHeader()}
      confirmText="Confirm"
      size="md"
      valid={valid && !submitting}
      form={renderForm()}
    >
      <Dropdown.Item>
        <IoIosNotifications size="1.2em" /> <span>
          {t('components.equipment.report-event-modal.manual-alarm')}
        </span>
      </Dropdown.Item>
    </FormModal>
  );
}

export default ReportEventModal;
