import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupEmbeddedDataByKey } from '../selectors';
import { fetchGroupReportData } from '../actions';
import { createReportDataKey } from '../utils';

export function useGroupReportData(group, params) {
  const dispatch = useDispatch();
  const reportData = useSelector(state => getGroupEmbeddedDataByKey(state, group.id, createReportDataKey(params)));

  const fetchData = useCallback(() => {
    if(group.id) dispatch(fetchGroupReportData(group, params));
  }, [dispatch, fetchGroupReportData, group.id]);

  return { reportData, fetchData };
}