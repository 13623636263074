import { useCallback, useState, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment';
import { FaHeartbeat } from 'react-icons/fa';
import { getDevice } from '../../modules/equipment/selectors';
import useBaseChart from "./BaseChart";
import {
  groupByHour,
  groupByDay,
  getInitialOptions as getAICalendarInitialOptions
} from './AICalendar';
import {
  oneMinute,
  oneHour,
  oneDay,
  oneWeek,
  dayLabels,
  hourLabels,
  getDateString,
} from '../../lib/utils';

function formatCondition(value) {
  switch (true) {
    case value === 4: return 'Serious';
    case value === 3: return 'Warning';
    case value === 2: return 'Healthy';
    case value === 1: return 'Not Running';
    default: return '';
  }
}

function getInitialOptions() {
  const aiCalendarInitialOptions = getAICalendarInitialOptions();
  return {
    ...aiCalendarInitialOptions,
    visualMap: {
      min: 0,
      max: 4,
      show: false,
      // colour gradient for heatmap 0-3
      color: [
        '#ff7878', // 4 - Serious
        '#eadf6c', // 3 - Warning
        '#2bae23', // 2 - healthy
        '#097BFF', // 1 - blue
        '#ffffff', // 0 - white
      ],
    },
    tooltip: {
      position: 'top',
      formatter: function({ seriesId, data=[] }={}) {
        if (seriesId === 'days') {
          const [dateString, value] = data;
          // value needs to be translated from [0,1,2,3] scale to 0-1 scale
          return `${dateString} (${
            dayLabels[new Date(dateString).getDay()]
          }): ${formatCondition(value)}`;
        }
        if (seriesId === 'hours') {
          const [dateString, invertedHourIndex, value] = data;
          // need to re-invert the hour index to get the correct label
          // as discussed in this commit, this is probably a bug in eCharts
          return `${dateString} (${
            dayLabels[new Date(dateString).getDay()]
          }) ${hourLabels[23-invertedHourIndex]}: ${formatCondition(value)}`;
        }
      },
    },
    dataZoom: {
      ...aiCalendarInitialOptions.dataZoom,
      realtime: false,
      throttle: 0,
    }
  };
}

function EnergyOverview({
  deviceId,
  samples,
  timezone,
  fetchingOverview,
  ...props
}) {
  const device = useSelector(state => getDevice(state, deviceId), shallowEqual);
  const deviceCreatedAtTimestamp = device?.created_at && new Date(device.created_at).getTime();
  const [BaseChart, { getChart, useChartUpdateEffect, }] = useBaseChart(getInitialOptions);
  const [dateRange, setDateRange] = useState({
    startTime: Date.now() - oneWeek,
    endTime: Date.now(),
  });

  const maximumViewedDateRange = useMemo(() => {
    return {
      startTime: samples[0]?.date ? Date.parse(samples[0].date) : Date.now(),
      endTime: samples[samples.length - 1]?.date ? Date.parse(samples[samples.length - 1].date) : Date.now(),
    };
  }, [samples.length]);

  const updateVisualMapDateRange = useCallback(({ dataZoom=[] }={}) => {
    const {
      // get start and end from dataZoom (update on dataZoom change)
      // default to passed dateRange values if needed
      startValue: startTime = dateRange.startTime,
      endValue: endTime = dateRange.endTime,
    } = dataZoom.find(({ id }) => id === 'xAxisMain') || {};

    const axisDataSet = new Set();
    let looptime = startTime;
    // loop through days from end to start (so it is sorted vertically old↓new)
    // don't move 24h per loop: ensure no 23 hour days are skipped due to DST
    do {
      const dateString = getDateString(looptime);
      axisDataSet.add(dateString);
      looptime = new Date(dateString).valueOf() + 30 * oneHour; // move next date
    } while(looptime < endTime + oneDay);
    const axisData = Array.from(axisDataSet);
    const dateRangeInDays = Math.ceil((endTime - startTime)/oneDay);
    // convert the timeseries into histogram bins
    return {
      xAxis: [{
        id: 'xAxisDays',
        data: axisData,
        axisLabel: {
          formatter: dateRangeInDays > 365
            // print year
            ? dateString => dateString.replace(/-\d{2}-\d{2}$/, '')
            : dateRangeInDays > 31
              // print month
              ? dateString => dateString.replace(/-\d{2}$/, '')
              // print day
              : dateString => dateString,
          // todo: fix interval labels to not overlap text when chart width is small
          interval: dateRangeInDays > 365
            // mark first day of each year
            ? (index, dateString) => (
              new Date(dateString).getMonth() === 0 &&
              new Date(dateString).getDate() === 1
            )
            : dateRangeInDays > 31
              // mark first day of each month
              ? (index, dateString) => new Date(dateString).getDate() === 1
              : dateRangeInDays > 7
                // mark every Sunday
                ? (index, dateString) => new Date(dateString).getDay() === 0
                : 'auto',
        },
        splitLine: {
          // show no lines if there will be far too many
          show: dateRangeInDays >= 3 * 365 ? false : true,
          // adjust interval if a large date range covered
          interval: dateRangeInDays >= 100
            // show weeks (mark interval at day 3, Wednesday: the week's middle
            // which eCharts uses to draw the category bounds around)
            ? (index, dateString) => new Date(dateString).getDay() === 3
            // show days
            : 0,
        },
      }],
    };
  }, [dateRange.startTime, dateRange.endTime]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateVisualMapDateRange);
  // useChartUpdateOnChartEvent('dataZoom', clearVisualMapDateRange);

  const updateSamples = useCallback(() => {
    // get "now" but in the equipment's timezone (represented as UTC)
    const nowDate = new Date();
    const nowAtEquipment = nowDate.valueOf() - nowDate.getTimezoneOffset() * oneMinute;
    // transform continuous values into discrete values
    const conditionSeries = samples
      .flatMap(({ date, energy_overview_state=[] }) => {
        // spread out hourly condition with generated timestamps
        return energy_overview_state.map((overall_condition, hourIndex) => {
          return [
            // by setting the date with a date string (eg "2020-01-01")
            // JS assumes this is UTC time
            // so here we convert all overview times to UTC for processing simplicity
            new Date(date).setUTCHours(hourIndex),
            overall_condition,
          ];
        });
      })
      // filter out future sample data
      .filter(([timestamp]) => timestamp < nowAtEquipment);

    // get hourly for all, and daily from hourly
    // so we're not re-bucketing the whole series for both
    const hourlyConditionSeries = groupByHour(conditionSeries);
    const dailyConditionSeries = groupByDay(hourlyConditionSeries);

    return {
      series: [
        { // Data series to show for data zoom, which is the bottom part.
          id: 'hours-for-data-zoom',
          data: hourlyConditionSeries.sort((a, b) => a[0] - b[0]),
        },
        { // Data series for hourly condition chart (middle part).
          id: 'hours',
          data: hourlyConditionSeries
            // remove not running condition hour
            .filter(([, condition]) => condition > 0)
            .map(([timestamp, value]) => {
              // make an [xCategoryIndex, yCategoryValue, value] map
              const date = new Date(timestamp);
              // note: we need to invert the hour value to display it on yAxis
              // in the correct position, this is probably a bug in eCharts
              // and may need to be re-fixed after upgrading to > eCharts v4.2
              return [getDateString(date), 23 - date.getUTCHours(), value];
            }),
        },
        { // Data series for calendar chart (top part)
          id: 'days',
          data: dailyConditionSeries
            // remove not running condition day
            .filter(([, condition]) => condition > 0)
            .map(([timestamp, value]) => {
              return [getDateString(timestamp), value];
            }),
        },
      ],
    };
  }, [samples]);
  useChartUpdateEffect(updateSamples);

  // update calendar range
  const updateCalendarDateRange = useCallback(({ calendar: [calendar] }) => {
    const startOfYear = moment(dateRange.startTime).startOf('year').valueOf();
    const endOfYear = moment(dateRange.endTime).endOf('year').valueOf();
    const chart = getChart();
    const chartWidth = chart && chart.getWidth();
    if (chartWidth && startOfYear && endOfYear) {
      // compute how much room the month labels have
      const calendarWidth = chartWidth - calendar.left - calendar.right;
      const calendarWeeks = Math.ceil((endOfYear - startOfYear) / oneWeek);
      return {
        calendar: [{
          id: 'months',
          itemStyle: {
            // make calendar day borders smaller when then are more days to display
            // but with maximum width of 1
            borderWidth: Math.min(1, Math.round(25 * calendarWidth / calendarWeeks )/100),
          },
          monthLabel: {
            nameMap: calendarWidth / calendarWeeks > 7.5
              ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
              : ['Jan', '', '', 'Apr', '', '', 'Jul', '', '', 'Oct', '', '']
          },
          range: [
            // show at least the day of device creation
            // getDateString(deviceCreatedAtTimestamp),
            startOfYear,
            // until now
            // getDateString(Date.now()),
            getDateString(endOfYear),
          ],
        }],
      };
    }
  }, [getChart, deviceCreatedAtTimestamp, dateRange.startTime, dateRange.endTime]);

  useChartUpdateEffect(updateCalendarDateRange);

  // Update timezone.
  const updateTimezone = useCallback(() => {
    return {
      graphic: {
        elements: [{
          id: 'timezone_text',
          style: {
            text: `\nEquipment\ntimezone:\n\n${
              timezone
                ? timezone
                  .replace(/\//g, '/\n') // break on slash
                  .replace(/_/g, '\n') // new line for each word
                : 'unknown'
            }`,
          },
        }]
      },
    };
  }, [timezone]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateTimezone);

  return (
    <BaseChart
      header={<><FaHeartbeat color="#cc5847" /> Energy Overview</>}
      namespace="fitpower-energy-overview"
      maximumViewedDateRange={maximumViewedDateRange}
      deviceId={deviceId}
      samples={samples}
      {...props}
      hasMore={false}
      dateRange={dateRange}
      setDateRange={setDateRange}
      stillFetching={fetchingOverview}
    />
  );
}

export default EnergyOverview;