import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { IoIosGlobe } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

import { setLocalAnonymousPreference, submitUserPreferences } from '../modules/user/actions';
import i18n from '../i18n';

export const restrictedDomain = 'movus.com.au';

// Edit this object to control the available languages. These map to public/locale/<language>.
export const languages = {
  'en-AU': {shortName: 'English (AU)', longName: 'English (Australian)'},
  'es-MX': {shortName: 'Español (MX)', longName: 'Español (mexicano)'},
};

export function setLanguageAndLocale(language) {
  i18n.changeLanguage(language);
  moment.locale(language);
}

export async function updateLanguage(dispatch, user, anonymousLanguage, language) {
  if (anonymousLanguage && anonymousLanguage !== language) {
    await dispatch(submitUserPreferences(user, {'preferences:language': anonymousLanguage}))
      .then(async () => {
        await dispatch(setLocalAnonymousPreference('language', null)); // Reset, to set again on logout
      }).finally(() => {});
  }
}

export function LanguageOptions({ nameType }) {
  return (
    Object.keys(languages).map(lng => (
      <option key={lng} value={lng}>{languages[lng][nameType]}</option>
    ))
  );
}

export function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const setAnonymousLanguage = useCallback((language) => {
    dispatch(setLocalAnonymousPreference('language', language));
  }, []);

  return (
    <Form.Group controlId="select-language">
      <Form.Label style={{
        position: 'absolute',
        top: '5px',
        left: '5px'
      }}
      >
        <IoIosGlobe size="1.8em" style={{color: '#666'}} />
      </Form.Label>
      <Form.Control
        as="select"
        onChange={e => setAnonymousLanguage(e.target.value)}
        defaultValue={i18n.resolvedLanguage || 'en-AU'}
        style={{
          width: '160px',
          paddingLeft: '1.8em'
        }}
      >
        <LanguageOptions nameType={'shortName'} />
      </Form.Control>
    </Form.Group>
  );
}
