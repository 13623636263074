import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useLocalPreference from '../../user/hooks/useLocalPreference';
import { SENSOR_TYPE } from '../constants';
import { getCurrentOrganisationHasProductCode } from '../../organisation/selectors';

const DEFAULT_SENSOR_TYPE = SENSOR_TYPE.FITMACHINE;
const ALLOWED_SENSOR_TYPE = [SENSOR_TYPE.FITPOWER, SENSOR_TYPE.FITMACHINE];

function useSensorType() {
  const { query } = useLocation();
  const hasFitPower = useSelector(state => getCurrentOrganisationHasProductCode(state, SENSOR_TYPE.FITPOWER));
  const { preference, setPreference } = useLocalPreference({
    key: 'equipment_list_view',
    defaultValue: DEFAULT_SENSOR_TYPE,
  });
  const initialSensorType = hasFitPower && preference ? preference : DEFAULT_SENSOR_TYPE;
  const [sensorType, setSensorType] = useState(initialSensorType);

  useEffect(() => {
    if(!hasFitPower) return;
    if(query?.type) {
      const querySensorType = query.type.toLowerCase();
      const sensorType = ALLOWED_SENSOR_TYPE.includes(querySensorType) ? querySensorType : DEFAULT_SENSOR_TYPE;
      setSensorType(sensorType);
      setPreference(sensorType);
    } else {
      setSensorType(preference);
    }
  }, [query?.type, hasFitPower, preference]);

  return sensorType;
}

export default useSensorType;