
import React from 'react';
import { Card } from 'react-bootstrap';

import './activityCard.scss';

export default function ActivityCard({ title, className, children, beforeFooter, footer }) {
  return (
    <Card
      className={
        ['mb-2', 'activity-card', className].filter(Boolean).join(' ')
      }
    >
      <Card.Body>
        {title && (
          <Card.Title>
            {title}
          </Card.Title>
        )}
        {children}
      </Card.Body>
      {beforeFooter}
      {footer && (
        <Card.Footer>
          {footer}
        </Card.Footer>
      )}
    </Card>
  );
}
