import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLocalUserPreference } from '../../user/actions';
import { getUser, getUserPreference } from '../../user/selectors';

export const EXPANDED_SIDEBAR_SIZE = 300; // The min width of sidebar, which matches $expanded-size variable in sidebar.scss

// The hook that controls the state of sidebar, like expanded, collapsed, width etc.
function useSidebar() {
  const dispatch = useDispatch();
  // const { width: sidebarWidth, } = useSelector(getSidebar);
  const user = useSelector(getUser);
  const sidebarWidth = useSelector(state => getUserPreference(state, 'sidebar-width'));
  const expandedPreference = useSelector(state => getUserPreference(state, 'sidebar-expanded'));
  const expanded = expandedPreference !== undefined ? expandedPreference === 'expanded' : true;

  const toggleExpanded = useCallback(() => {
    if(user) {
      dispatch(setLocalUserPreference(user, 'sidebar-expanded', !expanded ? 'expanded' : 'collapsed'));
    }
  }, [expanded]);

  const closeExpanded = useCallback(() => {
    if(user) {
      dispatch(setLocalUserPreference(user, 'sidebar-expanded', 'collapsed'));
    }
  }, []);

  const setSidebarWidth = useCallback((width) => {
    if(user) {
      dispatch(setLocalUserPreference(user, 'sidebar-width', Math.max(EXPANDED_SIDEBAR_SIZE, width)));
    }
  }, [dispatch, setLocalUserPreference]);

  return {
    sidebarWidth,
    setSidebarWidth,
    expanded,
    toggleExpanded,
    closeExpanded
  };
}

export default useSidebar;