import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '../../../components/ConfirmModal';
import useAlarmEvent from '../../alarm/hooks/useAlarmEvent';

function DeleteCommentButton({event, ...props}) {
  const { t } = useTranslation();
  const { deleteEvent } = useAlarmEvent(event.alarm_id);

  const handleDeleteEvent = useCallback(() => {
    return deleteEvent(event.event_id);
  }, [event.event_id]);

  return (
    <ConfirmModal
      body={t('components.equipment.delete-comment-button.body')}
      confirmText={t('components.equipment.delete-comment-button.confirm-text')}
    >
      <span
        className="btn btn-link btn-sm p-0" style={{cursor: 'pointer'}}
        {...props}
        onClick={handleDeleteEvent}
        aria-hidden="true">{t('common.delete')}
      </span>
    </ConfirmModal>
  );
}

export default DeleteCommentButton;
