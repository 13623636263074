
import {
  getOrganisation,
  getOrganisations,
  getOrganisationTags,
  getOrganisationProductCodes,
  getOrganisationType,
  getGroupUsers,
  getActiveGroupId,
  getActiveGroupLoadingState,
  getActiveGroupNodeInfo,
  getNearestGroupOrganisationAncestor,
  getActiveGroupNode,
  isTopLevelGroupActive,
} from '../organisation/selectors';

export function getUserListState(state) {
  // if the group is a normal group, the members list is the loaded list
  const { isNormalGroup } = getActiveGroupNodeInfo(state) || {};
  if (isNormalGroup) {
    return getActiveGroupLoadingState(state);
  }
  const { user: { loading, lastFetch, error }={} } = state;
  return { loading, lastFetch, error };
}

export function getUsersList(state) {
  return state && state.user && state.user.users;
}
export function getOrganisationUsers(state, organisationId) {
  const usersList = getUsersList(state);
  if (organisationId) {
    // filter to users that match or are underneath this organisation
    return organisationId && usersList ? usersList.map(user => {
      const userOrganisations = user && user._embedded && user._embedded.organisations;
      const filteredOrganisations = (userOrganisations || []).filter(({ organisation_id }) => {
        return organisation_id === organisationId;
      });
      // return modified user if they match
      return filteredOrganisations.length > 0 && {
        ...user,
        _embedded: {
          organisations: filteredOrganisations,
        },
      };
    }).filter(Boolean) : [];
  }
}

export function getUsers(state) {
  const usersList = getUsersList(state);
  const { isRoot, organisationId } = getActiveGroupNodeInfo(state) || {};
  // allow admins to read from the GET/users list
  if (isRoot && isAdmin(state)) {
    return usersList;
  }
  else if (organisationId) {
    // filter to users that match or are underneath this organisation
    return getOrganisationUsers(state, organisationId);
  }
  else {
    const groupId = getActiveGroupId(state);
    const organisationGroup = getNearestGroupOrganisationAncestor(state, groupId);
    const nearestOrganisationId = organisationGroup && organisationGroup.proxy;
    const users = getGroupUsers(state, groupId);
    // we read users from the top-level users list because
    // the group users payload doesn't contain an organisation last_active prop
    // for the UsersAdmin table
    // todo: if the last_active prop becomes available, revert this commit
    return usersList && users && users.map(user => {
      const matchedUser = usersList.find(({ id }) => id === user.id);
      const matchedUserOrganisations = matchedUser && matchedUser._embedded && matchedUser._embedded.organisations;
      const filteredOrganisations = (matchedUserOrganisations || []).filter(({ organisation_id }) => {
        return organisation_id === nearestOrganisationId;
      });
      // return modified user if they match
      return filteredOrganisations.length > 0 && {
        ...matchedUser,
        _embedded: {
          organisations: filteredOrganisations,
        },
      };
    }).filter(Boolean); // filter out users in group members but not usersList
  }
}

export function getUser(state, id) {
  const currentUser = state && state.user && state.user.user;

  // get this user or another user?
  return id === undefined || id === (currentUser && currentUser.id)
    // return main user: ensure getUser returns either a user or undefined
    // todo: fix reducer to stop returning null upon logging out
    ? currentUser || undefined
    // search other users
    : (getUsersList(state) || []).find(user => user.id === id);
}

export function getUserId(state, id) {
  const user = getUser(state, id);
  return user && user.id;
}

export function isUserSelf(state, id) {
  // does the current user's id match the given id
  return !!id && getUserId(state) === id;
}

// get the path that should be used when making API requests on this user ('me' or a user id)
// id here (and everywhere in these selectors) must be an integer
export function getUserPathString(state, id) {
  // does the current user's id match the given id
  return id ? `${isUserSelf(state, id) ? 'me' : id}` : undefined;
}

export function getUserLastLogin(state) {
  return state && state.user && state.user.lastLogin;
}

export function getUserLastActiveAnywhere(state, userId) {
  const user = getUser(state, userId) || {};
  // get from user
  if (user.last_sign_in_at) {
    return user.last_sign_in_at;
  }
  // else look in organisations
  const organisations = getUserOrganisations(state, userId) || [];
  // this returns an ISO string
  return organisations.map(({ last_active }) => last_active).sort().pop();
}

export function getUserSubDomain(state) {
  return state && state.user && state.user.login && state.user.login.organisation;
}

export function getUserToken(state) {
  return state && state.user && state.user.login && state.user.login.token;
}

// we now have business logic that determine that the user
// should not be shown certain display preferences
// do not consider them logged in until the state requirements are met
// user requires organisation preferences to be loaded before progressing
export function isUserLoggedIn(state) {
  // ensure user id, preferences, organisation preferences and organisation(product codes) are available
  return !!getUserId(state) &&
    !!getUserTags(state) &&
    !!getOrganisationTags(state) &&
    !!getOrganisationProductCodes(state);
}

export function isMfaEnabled(state) {
  return state && state.user && state.user.mfa && state.user.mfa.mfaEnabled;
}

export function isMfaValidated(state) {
  return state && state.user && state.user.mfa && state.user.mfa.mfaValidated;
}

export function getOtpToken(state) {
  return state && state.user && state.user.mfa && state.user.mfa.otpToken;
}

export function getUserDevices(state, id) {
  const user = getUser(state, id);
  return user && user._embedded && user._embedded.devices;
}

export function getUserTags(state, id) {
  const user = getUser(state, id);
  return user && user._embedded && user._embedded.tags;
}

export function getUserTag(state, tag, id) {
  const tags = getUserTags(state, id);
  return tags && tags[tag];
}

export function getUserPicture(state, id) {
  const user = getUser(state, id);
  return user && ((
    // get embedded picture on user (has more detail)
    user._embedded && user._embedded.picture
  ) || (
    // get picture on user without status detail
    user.picture_url && { url: user.picture_url }
  ));
}

export function getUserOrganisations(state, id) {
  if (isUserSelf(state, id)) {
    return getOrganisations(state);
  }
  const user = getUser(state, id);
  return user && user._embedded && user._embedded.organisations;
}

export function getUserOrganisation(state, orgId, userId) {
  if (isUserSelf(state, userId)) {
    return getOrganisation(state);
  }
  // find organisations on another user
  const user = getUser(state, userId);
  const organisations = user && user._embedded && user._embedded.organisations;
  // embedded organisations have a different id
  return organisations && organisations.find(({ organisation_id }) => {
    return organisation_id && organisation_id === orgId;
  });
}

export const userPreferenceModels = [
  {
    key: 'units_system',
    title: 'Units of Measure',
    options: [
      {
        value: 'metric',
        title: 'Metric',
        description: 'user.Metric_description',
        isDefaultOption: true,
      },
      {
        value: 'US',
        title: 'US',
        description: 'user.US_description'
      },
    ],
  }
];

export const mfaPreferenceModels = [
  {
    key: 'delivery',
    title: 'Delivery method',
    options: [
      {
        value: 'sms',
        title: 'SMS',
      },
      {
        value: 'email',
        title: 'Email',
        isDefaultOption: true,
      },
    ],
  },
];

// collect all the default options here (could easily get exported later)
// note: this returns the default option marked with 'setByDefault'
// which assumes that this option was 'selected by default' for the user
const defaultUserPreferenceOptionByKey = userPreferenceModels
  .reduce((acc, { key, options=[] }) => {
    return {
      ...acc,
      // add the default preference set by key
      [key]: {
        ...options.find(({ isDefaultOption }) => isDefaultOption),
        // tag this option as being set on the user as default
        setByDefault: true,
      },
    };
  }, {});

function getLocalAnonymousPreferences(state) {
  return state &&
    state.user &&
    state.user.localPreferences;
}

export function getLocalAnonymousPreference(state, preferenceKey) {
  const localPreferences = getLocalAnonymousPreferences(state);
  return localPreferences && localPreferences[preferenceKey];
}

function getLocalUserPreferences(state, userId) {
  const user = getUser(state, userId);
  if (user && user.id) {
    return state &&
      state.user &&
      state.user.localPreferencesByUserId &&
      state.user.localPreferencesByUserId[user.id];
  }
}

export function getLocalUserPreference(state, preferenceKey, userId) {
  const localPreferences = getLocalUserPreferences(state, userId);
  return localPreferences && localPreferences[preferenceKey];
}

export function getRemoteUserPreferences(state, userId) {
  const user = getUser(state, userId);
  if (user && user.id) {
    return state &&
      state.user &&
      state.user.remotePreferencesByUserId &&
      state.user.remotePreferencesByUserId[user.id];
  }
}

function getOptimisticUserPreferences(state, userId) {
  const user = getUser(state, userId);
  if (user && user.id) {
    return state &&
      state.user &&
      state.user.optimisticPreferences &&
      // get all found preferences applied in order
      state.user.optimisticPreferences.reduce((acc, preferences) => {
        return {
          ...acc,
          ...preferences,
        };
      }, {});
  }
}

function getRemoteUserPreference(state, preferenceKey, userId) {
  // if the app has an optimistic preference, use that while we are waiting for the answer
  const optimisticPreference = (getOptimisticUserPreferences(state, userId) || {})[preferenceKey];
  const foundValue = optimisticPreference !== undefined
    ? optimisticPreference
    : (getRemoteUserPreferences(state, userId) || {})[preferenceKey];

  // if the app has an optimistic preference, use that while we are waiting for the answer
  if (preferenceKey && foundValue !== undefined) {
    const foundModel = userPreferenceModels.find(({ key }) => key === preferenceKey);
    // search preferences model for the option that the user has selected
    if (foundModel && foundModel.options && foundModel.options.length > 0) {
      const foundOption = foundModel.options.find(({ value }) => value === foundValue);
      // return specifically found option
      if (foundOption) {
        return foundOption;
      }
    }
  }

  // return the default option for this key
  return defaultUserPreferenceOptionByKey[preferenceKey];
}

// It's a rewrite of the above getRemoteUserPreference function.
// The original getRemoteUserPreference seems dependent on reference model.
// This one is not, it just looks up the 'preferenceKey' and returns its value if exists.
function getRemoteUserPreferenceByKey(state, preferenceKey, userId) {
  const optimisticPreference = (getOptimisticUserPreferences(state, userId) || {})[preferenceKey];
  const preference = optimisticPreference !== undefined
    ? optimisticPreference
    : (getRemoteUserPreferences(state, userId) || {})[preferenceKey];
  return preference;
}

export function getUserPreferenceByKey(state, preferenceKey, userId) {
  return getLocalUserPreference(state, preferenceKey, userId)
    || getRemoteUserPreferenceByKey(state, preferenceKey, userId);
}

export function getUserPreferenceValue(state, preferenceKey, userId) {
  const preference = getUserPreference(state, preferenceKey, userId);
  return preference && preference.value;
}

export function getUserPreference(state, preferenceKey, userId) {
  // return local preference over remote preference
  return getLocalUserPreference(state, preferenceKey, userId)
    || getRemoteUserPreference(state, preferenceKey, userId);
}

export function getUserPreferenceUnitsOfMeasure(state, userId) {
  return getUserPreference(state, 'units_system', userId);
}

// list userTypes and orgTypes by name, ordered by privilege level
// as of 2019-03-26 current userTypes:
export const userTypes = ['User', 'Admin', 'Partner Admin', 'Super Admin'];
const orgTypes = ['Standard', 'Partner', 'MOVUS'];

export function isAuthorised(state, { minUserType, minOrgType }) {
  const user = getUser(state);
  const organisation = getOrganisation(state) || {};
  const userType = user && user.user_type;
  const orgType = organisation.sub_domain && organisation.sub_domain.startsWith('movus.')
    ? 'MOVUS'
    : organisation.is_parent
      ? 'Partner'
      : 'Standard';
  return !!(
    userType
    && (userTypes.indexOf(userType) >= userTypes.indexOf(minUserType))
    && (orgTypes.indexOf(orgType) >= orgTypes.indexOf(minOrgType))
  );
}

export function isUserEditable(state, { userIsSelf, userIsNew, userId }) {
  // can edit self
  if (userIsSelf) {
    return true;
  }
  // admins can edit other users
  if (isAdmin(state)) {
    // can edit new users
    if (userIsNew) {
      return true;
    }
    // can edit existing users
    return !!getUser(state, userId);
  }
  // default to unauthorised
  return false;
}

// quick selectors for different admins
export function isAdmin(state) {
  return isAuthorised(state, { minUserType: 'Admin' });
}
export function isPartnerAdmin(state) {
  return isAuthorised(state, { minUserType: 'Partner Admin' });
}
export function isSuperAdmin(state) {
  return isAuthorised(state, { minUserType: 'Super Admin' });
}

// selectors for rights in current group
function hasRightsInActiveGroup(state, right) {
  // in top-level group access is determined by 'user type'
  if (isTopLevelGroupActive(state)) {
    return isAdmin(state);
  }
  // else the access rights are determined by group status
  const group = getActiveGroupNode(state);
  return group &&
    group.access_rights &&
    group.access_rights.includes(right);
}
export function hasReadRightsInActiveGroup(state) {
  return hasRightsInActiveGroup(state, 'read');
}
export function hasWriteRightsInActiveGroup(state) {
  return hasRightsInActiveGroup(state, 'write');
}
export function hasAdminRightsInActiveGroup(state) {
  return hasRightsInActiveGroup(state, 'admin');
}

// todo: have this returned by the API
const userTypeOptionsByOrg = {
  "Standard": {
    "manageable_types": [
      "Admin",
      "User"
    ]
  },
  "Partner": {
    "manageable_types": [
      "Partner Admin",
      "Admin",
      "User"
    ]
  },
  "MOVUS": {
    "manageable_types": [
      "Super Admin",
      "Partner Admin",
      "Admin",
      "User"
    ]
  }
};

export function getManageableUserTypes(state) {
  const { user: { userTypeOptions={}, user={} } } = state;

  // find manageable user types by current user type
  const allowedUserTypesByUser = (userTypeOptions[user.user_type] &&
    userTypeOptions[user.user_type].manageable_types) || [];

  // find manageable user types by current organisation type
  const orgType = getOrganisationType(state);
  const allowedUserTypesByOrg = (userTypeOptionsByOrg[orgType] &&
    userTypeOptionsByOrg[orgType].manageable_types) || [];

  // return the cross-section of both manageable user types
  return allowedUserTypesByUser.filter(userType => allowedUserTypesByOrg.includes(userType));
}

export function getUserSubscriptions(state, userId) {
  const user = getUser(state, userId);
  return user?._embedded?.subscriptions?.items;
}

export function getUserSubscriptionsState(state, userId) {
  const user = getUser(state, userId);
  return user?._embedded?.subscriptions?._state_;
}

export function getUserTypeOptions(state) {
  return state.user?.userTypeOptions;
}

export function getUserTypeOptionsState(state) {
  return state.user?.userTypeOptions?._state_ || {};
}