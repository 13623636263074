
import reactGA from 'react-ga';
import * as APP_ACTION_TYPES from '../modules/app/types/ActionTypes';

const trackingOptions = {
  debug: process.env.NODE_ENV !== 'production',
};

// load Google Analytics if available
if (process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) {
  reactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);
}

// export redux middleware
export default store => next => async action => {

  // react to actions
  if (action.type === APP_ACTION_TYPES.APP_PAGE) {

    // track in GA
    reactGA.set({
      page: action.path,
      ...trackingOptions
    });
    reactGA.pageview(action.path);
  }

  // allow redux to continue
  return next(action);
};
