import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import GroupPageLayout from '../components/GroupPageLayout';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import useGroup from '../hooks/useGroup';
import { fetchGroupImpactSummary } from '../actions';
import { getGroupImpactSummaryState, getGroupImpactSummary } from '../selectors';
import {
  alarmTime,
  reason,
  lostHours,
  avoidedHours,
  groupEventCosts,
  groupAvoidedCosts,
  groupTotalReduction,
  equipmentLink,
} from '../../equipment/columns';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';
import { canAccessGroupImpact } from '../components/GroupPageLayout';

const columns = [
  alarmTime,
  reason,
  equipmentLink,
  lostHours,
  avoidedHours,
  groupEventCosts,
  groupAvoidedCosts,
  groupTotalReduction,
];

export default function GroupImpactSummary() {
  const { t } = useTranslation();
  const history = useHistory();
  const { group } = useGroup();
  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => getGroupImpactSummaryState(state, group.id)) || {};
  const impactSummary = useSelector(state => getGroupImpactSummary(state, group.id)) || [];

  useEffect(() => {
    group.id && dispatch(fetchGroupImpactSummary(group));
  }, [group.id]);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        title={t('screens.organisations.group-impact-summary.title')}
        loading={loading}
        error={error}
        tableProps={props}
      />
    );
  }, [loading, error]);

  const localisedColumns = useLocalisedColumns(columns);

  if(!canAccessGroupImpact(group)) {
    history.push('/group/config');
    return null;
  }

  return (
    <GroupPageLayout>
      <Table
        keyField="alarm_id"
        renderHeader={renderHeader}
        noDataIndication={() => t('screens.organisations.group-impact-summary.no-data')}
        data={impactSummary}
        loading={loading}
        columns={localisedColumns}
        defaultSorted={[{
          dataField: 'alarm_time',
          order: 'desc'
        }]}
      />
    </GroupPageLayout>
  );
}
