import { StateProps, PlanResponse as Plan, Transaction, Invoice, UsageResponse as Usage, MonitoredHour } from './types';
import { AppStoreState } from '../types';
import { getUsageInPeriod } from './components/lib/utils';

export function getPlanState(state: AppStoreState): StateProps {
  const {
    billing: {
      plan: {
        loading = false,
        lastFetch = null,
        error = null
      }
    }
  } = state;
  return {
    loading, lastFetch, error,
  };
}

export function getPlan(state: AppStoreState): Plan {
  return state.billing.plan.data as Plan;
}

export function getIsEmptyPlan(state: AppStoreState): boolean {
  const plan = getPlan(state);
  return !plan || !plan.plan || Object.keys(plan).length === 0;
}

export function getUsageState(state: AppStoreState): StateProps {
  const {
    billing: {
      usage: {
        loading = false,
        lastFetch = null,
        error = null
      }
    }
  } = state;
  return {
    loading, lastFetch, error,
  };
}

export function getUsage(state: AppStoreState): Usage {
  return state.billing.usage.data as Usage;
}

export function getIsEmptyUsage(state: AppStoreState): boolean {
  const usage = getUsage(state);
  return !usage || Object.keys(usage).length === 0;
}

export function getTransactionsState(state: AppStoreState): StateProps {
  const {
    billing: {
      transactions: {
        loading = false,
        lastFetch = null,
        error = null
      }
    }
  } = state;
  return {
    loading, lastFetch, error,
  };
}

export function getTransactions(state: AppStoreState): Transaction[] {
  return state.billing.transactions.data;
}

export function getInvoicesState(state: AppStoreState): StateProps {
  const {
    billing: {
      invoices: {
        loading = false,
        lastFetch = null,
        error = null
      }
    }
  } = state;
  return {
    loading, lastFetch, error,
  };
}

export function getInvoices(state: AppStoreState): Invoice[] {
  return state.billing.invoices.data;
}

export function getPeriodUsage(state: AppStoreState): MonitoredHour[] {
  const usageData = state.billing.usage.data as Usage;
  const startDate = usageData.billing_period?.start || '';
  const endDate = usageData.billing_period?.end || '';
  return getUsageInPeriod(usageData.monitored_hours || [], startDate, endDate, 'YYYY-MM-DD', true);
}

export function getCurrentBalance(state: AppStoreState): number {
  if(getIsEmptyPlan(state)) return 0;
  const plan = getPlan(state);
  return plan.balance + plan.current_usage;
}

export function getUsageOpeningBalance(state: AppStoreState): number {
  if(getIsEmptyUsage(state)) return 0;
  const usage = getUsage(state);
  return usage.balance;
}

export function getUsageEstimatedClosingBalance(state: AppStoreState): number {
  if(getIsEmptyUsage(state)) return 0;
  const usage = getUsage(state);
  return usage.balance + usage.estimated_usage;
}