
import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';

import {
  IoIosTrash,
} from "react-icons/io";

import { archiveOrganisation } from '../actions';
import { isLoggedIntoOrganisation, getOrganisation } from '../selectors';
import { isAuthorised } from '../../user/selectors';

import ConfirmModal from '../../../components/ConfirmModal';
import Private from '../../../components/Private';
import ContactSupportLink from '../../../components/ContactSupportLink';

export const minViewUserType = "Partner Admin";
export const minEditUserType = "Super Admin";
export const minViewOrgType = "Partner";
export const minEditOrgType = "MOVUS";

function ArchiveOrganisationModal({
  organisation = {},
  canArchive,
  isLoggedIntoOrganisation,
  archiveOrganisation,
  onSuccess,
  onError,
  ...props
}) {

  const onClick = useCallback(async () => {
    try {
      await archiveOrganisation(organisation);
      if (onSuccess) {
        onSuccess(organisation);
      }
    }
    catch(e) {
      if (onError) {
        onError(organisation);
      }
    }
  }, [archiveOrganisation, organisation]);

  return !isLoggedIntoOrganisation && (
    <Private minUserType={minViewUserType} minOrgType={minViewOrgType}>
      {canArchive ? (
        <ConfirmModal
          confirmText="Archive"
          body={(
            <Fragment>
              <p>
                The following changes will take effect immediately:
              </p>
              <ul>
                <li>The organisation will be archived. It will no longer be visible on the MachineCloud.</li>
                <li>All users will be archived. They will no longer be able to log in or use the MachineCloud Dashboard, FitMachine Mobile App or MachineCloud API. They will no longer receive any system emails.</li>
                <li>All devices will be archived. The MachineCloud will stop processing data it receives from these devices, and alarms will no longer be triggered.</li>
                <li>MachineCloud API tokens will be deleted. Third party applications relying on these tokens may stop functioning.</li>
              </ul>
              <p>
                This action can only be reversed by contacting MOVUS Support.
              </p>
            </Fragment>
          )}
          {...props}
        >
          <Dropdown.Item onClick={onClick}>
            <IoIosTrash size="1.5em" /> Archive
          </Dropdown.Item>
        </ConfirmModal>
      ) : (
        <ConfirmModal
          header="Archive Organisation"
          confirmButtonProps={{ style: { display: 'none' } }}
          cancelText="OK"
          body={(
            <span>
              Please <ContactSupportLink
                subject={`Archive Organisation (${organisation.sub_domain})`}
              >
                contact support
              </ContactSupportLink> to archive: {organisation.name}.
            </span>
          )}
          {...props}
        >
          <Dropdown.Item onClick={false}>
            <IoIosTrash size="1.5em" /> Archive
          </Dropdown.Item>
        </ConfirmModal>
      )}
    </Private>
  );
}

const mapStateTopProps = (state, { organisationId }) => ({
  organisation: getOrganisation(state, organisationId),
  canArchive: isAuthorised(state, { minUserType: minEditUserType, minOrgType: minEditOrgType }),
  isLoggedIntoOrganisation: isLoggedIntoOrganisation(state, organisationId),
});
const mapDispatchToProps = { archiveOrganisation };

export default connect(mapStateTopProps, mapDispatchToProps)(ArchiveOrganisationModal);
