import { useEffect, useState } from 'react';
import { debounce } from '../lib/utils';

// Pass a DOM element as argument and return its latest width.
function useElementWidth(element: HTMLElement) {
  const [width, setWidth] = useState(element?.clientWidth || 0);
  useEffect(() => {
    const getElementWidth = debounce(() => {
      if(element) {
        setWidth(element.clientWidth);
      }
    }, 300);
    if(element) {
      setWidth(element.clientWidth);
    }
    window.addEventListener('resize', getElementWidth);
    return () => window.removeEventListener('resize', getElementWidth);
  }, [element]);

  return width;
}

export default useElementWidth;