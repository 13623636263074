import createReducer from '../../lib/createReducer';

import * as ACTION_TYPES from './types/ActionTypes';

export const DEFAULT_SIDEBAR_WIDTH = 300;  // The default sidebar width, which is identical to $expanded-size in sidebar.scss.

export const DEFAULT_STATE = {
  version: undefined,
  build: undefined,
  headerHeight: 0,
  sidebar: {
    width: DEFAULT_SIDEBAR_WIDTH,
    expanded: true,
    isDragging: false,
  }
};

export default createReducer(DEFAULT_STATE, {
  [ACTION_TYPES.APP_LOAD](state) {
    return {
      ...state,
      version: process.env.REACT_APP_MACHINECLOUD_DASH_VERSION,
      build: process.env.REACT_APP_TEAMCITY_BUILD_NUMBER,
      // capture previous state (just after rehydration)
      previousVersion: state.version,
      previousBuild: state.build,
    };
  },
  [ACTION_TYPES.HEADER_HEIGHT](state, { headerHeight }) {
    return {
      ...state,
      headerHeight
    };
  },
  [ACTION_TYPES.SET_SIDEBAR](state, { payload = {} }) {
    // payload is supposed to have the properties of 'sidebar': width, expanded and isDragging.
    return {
      ...state,
      sidebar: {
        ...state.sidebar,
        ...payload,
      }
    };
  },
});
