
import React, { Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IoIosNotifications, IoIosNotificationsOff } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import withNavigationDeviceProps from './withNavigationDeviceProps';

// Components
import UserPicture from '../../user/components/UserPicture';

import StatusIndicatorBadges, {
  StaleIndicatorBadge,
  CustomAlarmBadge,
  CustomYellowAlarmBadge,
  CustomRedAlarmBadge,
} from '../components/StatusIndicatorBadges';
import { InfoCard, InfoCardItem } from '../../../components/InfoCard';

// Other
import {
  twoDigitsNumberFormatter,
  zeroDigitsNumberFormatter,
  calibrationFormatter,
  latLngFormatter,
} from '../../../components/table/formatters';

import { getDevice } from '../selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
} from '../../organisation/selectors';
import { getSensorName, isFitMachine } from '../utils';

const badgeComponents = [StaleIndicatorBadge, CustomAlarmBadge];

function DeviceSidebar({ device={} }) {

  const { t } = useTranslation();

  // evaluate the badges early for each device
  const hasBadges = useMemo(() => {
    return (
      StatusIndicatorBadges({ device, badgeComponents })
    );
  }, [device]);
  const sensorName = getSensorName(device);
  const isFMSensor = isFitMachine(device);

  if (!device || !device.id) {
    return null;
  }

  return (
    <Fragment>
      <h5 className="montserrat" style={{ marginTop: 10, marginBottom: 1 }}>
        {device.serial}
      </h5>
      <p className="text-muted font-weight-bold">
        {device.fitmachine_type || sensorName}
      </p>
      <p>
        {t('components.equipment.device-sidebar.monitors')}: <Link to={`/equipment/${device.id}`}>
          {device.site_name} - {device.sub_area_name} - {device.equipment_name}
        </Link>
      </p>
      <InfoCard
        collapsible
        heading={`${sensorName} ${t('components.equipment.device-sidebar.device-details.details')}`}
      >
        {device.archived && (
          <InfoCardItem className="list-group-item-danger text-center">
            {t('components.equipment.device-sidebar.device-details.archived')}
          </InfoCardItem>
        )}
        {hasBadges && (
          <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.status')}>
            <StaleIndicatorBadge device={device} expanded />
            <CustomYellowAlarmBadge device={device} expanded />
            <CustomRedAlarmBadge device={device} expanded />
          </InfoCardItem>
        )}
        <InfoCardItem heading={
          t('components.equipment.device-sidebar.device-details.last-sensor-name-sample', { sensorName: sensorName})
        }>
          {moment(device.fitmachine_last_sample_date).format("ll")}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.last-data-processed')}>
          {moment(device.fitmachine_last_heard).format("ll")}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.notifications-status')}>
          {device.mute_advisory_for === 0 ? (
            <IoIosNotifications size="1.4em" />
          ) : (
            <IoIosNotificationsOff size="1.4em" />
          )}
        </InfoCardItem>
        {isFMSensor && <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.wifi-signal')}>
          {zeroDigitsNumberFormatter(device.wifi_signal)}
        </InfoCardItem>}
        {isFMSensor && <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.battery-voltage')}>
          {twoDigitsNumberFormatter(device.battery_voltage)} V
        </InfoCardItem>}
        <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.mac-address')}>
          {device.serial}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.installation-date')}>
          {moment(device.installation_date).format("ll")}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.learning-progress')}>
          {calibrationFormatter(device.calibration, device)}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.learning-start-date')}>
          {moment(device.calibration_start).format("ll")}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.device-details.onboarded-by')}>
          {device.onboarded_by && device.onboarded_name ? (
            <Fragment>
              <Link to={`/users/${device.onboarded_by}`}>
                <UserPicture userId={device.onboarded_by} />
              </Link>
              {' '}
              <Link to={`/users/${device.onboarded_by}`}>
                {device.onboarded_name || "N/A"}
              </Link>
            </Fragment>
          ) : (
            "N/A"
          )}
        </InfoCardItem>
      </InfoCard>
      <InfoCard
        collapsible
        heading={t('components.equipment.device-sidebar.location-details.header')}
        initialCollapsed
      >
        <InfoCardItem heading={t('components.equipment.device-sidebar.location-details.organisation-name')}>
          {device.organisation_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.location-details.site-name')}>
          {device.site_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.location-details.sub-area-name')}>
          {device.sub_area_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.location-details.latitude')}>
          {latLngFormatter(parseFloat(device.latitude))}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment.device-sidebar.location-details.longitude')}>
          {latLngFormatter(parseFloat(device.longitude))}
        </InfoCardItem>
      </InfoCard>
    </Fragment>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
  mm2Available: getOrganisationMm2AvailablePreference(state),
});

export default withNavigationDeviceProps(
  connect(mapStateToProps)(DeviceSidebar)
);
