import { useTranslation } from 'react-i18next';

import { useGroupValidatedEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, identifiedIssueBadge, validatedBy, latestComments } from './columns';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';

const columns = [
  equipmentNameLink,
  identifiedIssueBadge,
  validatedBy,
  latestComments,
];

function ValidatedEvents() {
  const { t } = useTranslation();
  const groupValidatedEventsReport = useGroupValidatedEventsReport();
  const localisedColumns = useLocalisedColumns(columns);
  if(!groupValidatedEventsReport) return null;

  const {_state_: { loading, error } = {}, items = []} = groupValidatedEventsReport || {};
  return (
    <HomeCard
      header={t('home.common.validated-events')}
      headerLink={{to: '/events?status=valid', text: t('home.common.all-validated-events')}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={localisedColumns}
        condensed
      />
    </HomeCard>
  );
}

export default ValidatedEvents;
