import { useState, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { GoAlert } from 'react-icons/go';
import { FaCogs } from 'react-icons/fa';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';

import moment from 'moment';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmModal from '../../../components/ConfirmModal';
import SingleDatePicker from '../../../components/form/SingleDatePicker';
import useAlarm from '../../alarm/hooks/useAlarm';
import useAlarmEvent from '../../alarm/hooks/useAlarmEvent';
import useDomain from '../../domain/hooks/useDomain';

const t = i18n.t;

function isToday(day) {
  if(!moment.isMoment(day)) return false;
  const today = moment();
  return today.date() === day.date() && today.month() === day.month() && today.year() === day.year();
}

function DeviceAlarmStatusRow({variant = 'light', status, action, className}) {
  return (
    <div
      className={[
        `m-0 px-4 py-3 rounded-0 alert alert-${variant}`,
        // allow light variant to have a visible border
        variant === 'light' && 'border-top',
        className,
      ].filter(Boolean).join(' ')}
    >
      <Row>
        <Col className="d-flex align-items-center">{status && status()}</Col>
        <Col xs="auto">{action && action()}</Col>
      </Row>
    </div>
  );
}

function AckConfirmModal({confirm, ...props}) {
  const { t } = useTranslation();
  return (
    <Modal {...props} >
      <Modal.Header closeButton>
        <Modal.Title>
          <GoAlert
            className="text-danger"
          />&nbsp;{t('components.equipment.device-alarm-status.ack-confirm-modal.warning')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Trans
            i18nKey="components.equipment.device-alarm-status.ack-confirm-modal.acknowledging"
            components={{
              newline: <br />
            }}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>{t('cancel')}</Button>
        <Button
          variant="warning"
          onClick={confirm}
        >
          {t('components.equipment.device-alarm-status.acknowledge')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function AckFormModal({onSubmit, loading, alarm, ...props}) {
  const { t } = useTranslation();
  const { register, handleSubmit, watch, setValue, formState } = useForm({
    mode: 'onChange',
  });
  const watchAlarmStatus = watch('alarm_status');

  const { domainData: invalidReasons, fetchAlarmInvalidReasons } = useDomain({
    key: 'alarmInvalidReasons', fetch: false,
  });
  const { domainData: validReasons, fetchAlarmValidReasons } = useDomain({
    key: 'alarmValidReasons', fetch: false,
  });
  const reasons = useMemo(() => {
    return (
      watchAlarmStatus === 'valid' ?
        validReasons :
        watchAlarmStatus === 'invalid' ?
          invalidReasons: reasons) || [];
  }, [invalidReasons, validReasons, watchAlarmStatus]);

  useEffect(() => {
    if(!props.show) return;
    if(watchAlarmStatus === 'valid' && !validReasons) fetchAlarmValidReasons();
    if(watchAlarmStatus === 'invalid' && !invalidReasons) fetchAlarmInvalidReasons();
  }, [props.show, watchAlarmStatus]);

  useEffect(() => {
    if(watchAlarmStatus === 'acknowledged') setValue('reason', undefined);  // When action is switched to acknowledged, set reason as empty value.
    if(watchAlarmStatus === 'valid') setValue('reason', '');
    if(watchAlarmStatus === 'invalid') setValue('reason', '');  // Reset reason dropdown.
  }, [watchAlarmStatus]);

  useEffect(() => {
    if(props.show) setValue('alarm_status', 'acknowledged');
  }, [props.show]);

  const submitData = useCallback((data) => {
    if(typeof onSubmit === 'function') onSubmit(data);
  }, [onSubmit]);

  const actions = [{
    alarm_status: 'acknowledged',
    option: t('components.equipment.device-alarm-status.ack-form-modal.save-acknowledged'),
  }, {
    alarm_status: 'valid',
    option: t('components.equipment.device-alarm-status.ack-form-modal.mark-alarm-valid'),
  }, {
    alarm_status: 'invalid',
    option: t('components.equipment.device-alarm-status.ack-form-modal.mark-alarm-invalid'),
  }];

  return (
    <Modal
      {...props}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FaCogs />&nbsp;{t('components.equipment.device-alarm-status.ack-form-modal.action-required')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('components.equipment.device-alarm-status.ack-form-modal.relevant-comments')}
      </Modal.Body>
      <Form>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.ack-form-modal.reason')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                disabled={watchAlarmStatus!=='valid' && watchAlarmStatus!=='invalid'}
                {...register('reason', { validate: value => value !== '' })}
                defaultValue={''}
              >
                <option hidden value={''}></option>
                {reasons.map((option, index)=> {
                  return <option key={index} value={option}>{option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.ack-form-modal.comment')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                {...register('comment')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.ack-form-modal.action')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                {...register('alarm_status', {required: true})}
              >
                {actions.map((action, index) => {
                  return <option key={action.alarm_status} value={action.alarm_status}>{action.option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>{t('cancel')}</Button>
          <Button
            variant="danger"
            type="button"
            disabled={loading || !formState.isValid}
            onClick={handleSubmit(data => submitData({...data, notify: false}))}
          >
            {t('components.equipment.device-alarm-status.ack-form-modal.proceed')}
          </Button>
          {watchAlarmStatus === 'valid' && alarm?._config?.notify_on_valid &&
            <Button
              variant="warning"
              type="button"
              disabled={loading || !formState.isValid}
              onClick={handleSubmit(data => submitData({...data, notify: true}))}
            >
              {t('components.equipment.device-alarm-status.ack-form-modal.proceed-with-notification')}
            </Button>
          }
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export function AcknowledgeActionButton({
  alarm,
  variant='danger',
  children = t('components.equipment.common.acknowledge')
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { postEvent, loading } = useAlarmEvent(alarm.id, { eventId: alarm.eventId });

  const handleAcknowledge = useCallback((data) => {
    postEvent(data);
  }, [postEvent]);
  // console.log(loading, error, success);
  const handleClickAckButton = useCallback(() => {
    if(alarm.is_legacy) {
      setShowForm(true);
    } else {
      setShowConfirm(true);
    }
  }, []);

  return (
    <>
      <AckConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        confirm={() => { setShowConfirm(false); setShowForm(true); }}
      />
      <AckFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        alarm={alarm}
        onSubmit={handleAcknowledge}
        loading={loading}
      />
      <Button variant={variant} onClick={handleClickAckButton}>{children}</Button>
    </>
  );
}

function CloseActionButton({alarm}) {
  const { t } = useTranslation();
  const { postEvent } = useAlarmEvent(alarm.id);

  const handleClose = useCallback(() => {
    postEvent({ alarm_status: 'closed' });
  }, []);

  return (
    <ConfirmModal
      header={
        <>
          <GoAlert
            className="text-danger"
          />&nbsp;{t('components.equipment.device-alarm-status.close-button-modal.warning')}
        </>
      }
      body={
        <>
          <Trans
            i18nKey="components.equipment.device-alarm-status.close-button-modal.closing"
            components={{
              newline: <br />
            }}
          />
        </>
      }
      confirmText={t('components.equipment.device-alarm-status.close-button-modal.close-event')}
      confirmButtonProps={{variant: 'dark'}}
    >
      <Button variant="dark" onClick={handleClose}>{t('common.close')}</Button>
    </ConfirmModal>
  );
}

function CloseConfirmModal({confirm, loading, ...props}) {
  const { t } = useTranslation();
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          <GoAlert
            className="text-danger"
          />&nbsp;{t('components.equipment.device-alarm-status.close-button-modal.warning')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <Trans
            i18nKey="components.equipment.device-alarm-status.close-button-modal.closing"
            components={{
              newline: <br />
            }}
          />
        </p>
      </Modal.Body>
      <Modal.Footer>
        {loading && <LoadingSpinner inline size={1} />}
        <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>{t('cancel')}</Button>
        <Button
          variant="dark"
          onClick={confirm}
          disabled={loading}
        >
          {t('components.equipment.device-alarm-status.close-button-modal.close-event')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function ValidateFormModal({onSubmit, isValid, loading, alarm, ...props}) {
  const { t } = useTranslation();
  const canClose = alarm.alarm_status === 'acknowledged'; // When alarm status is acknowledged only, you can close it
  const { show } = props;
  const { register, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      alarm_status: isValid ? 'valid' : 'invalid'
    }
  });
  const watchAlarmStatus = watch('alarm_status');
  const [date, setDate] = useState(null);

  const { domainData: invalidReasons, fetchAlarmInvalidReasons } = useDomain({key: 'alarmInvalidReasons', fetch: false, cache: false});
  const { domainData: validReasons, fetchAlarmValidReasons } = useDomain({key: 'alarmValidReasons', fetch: false, cache: false});

  useEffect(() => {
    if(watchAlarmStatus === 'scheduled') setDate(moment().add(7, 'days'));
    else setDate(null);
  }, [watchAlarmStatus]);

  useEffect(() => {
    if(!show) return;
    if(isValid) {
      fetchAlarmValidReasons();
    } else {
      fetchAlarmInvalidReasons();
    }
  }, [show]);

  const reasons = useMemo(() => {
    return isValid ? validReasons || [] : invalidReasons || [];
  }, [invalidReasons, validReasons]);

  const actions = isValid ?
    [
      { option: t('components.equipment.device-alarm-status.validate-form-modal.save-valid'), alarm_status: 'valid' },
      { option: t('components.equipment.device-alarm-status.validate-form-modal.close-valid'), alarm_status: 'closed' },
      { option: t('components.equipment.device-alarm-status.validate-form-modal.schedule-maintenance'), alarm_status: 'scheduled' }] :
    [
      { option: t('components.equipment.device-alarm-status.validate-form-modal.save-invalid'), alarm_status: 'invalid' },
      { option: t('components.equipment.device-alarm-status.validate-form-modal.close-invalid'), alarm_status: 'closed' },
    ];

  const submitData = useCallback((data) => {
    if(date) data.due_date = moment(date).format('YYYY-MM-DD');
    if(data.alarm_status === 'closed') data.can_close_as = isValid ? 'valid' : 'invalid';  // Close alarm as valid or invalid.
    if(typeof onSubmit==='function') {
      onSubmit(data);
    }
  }, [onSubmit, date]);

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FaCogs />&nbsp;{t('components.equipment.device-alarm-status.validate-form-modal.action-required')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('components.equipment.device-alarm-status.validate-form-modal.reason-for')}
      </Modal.Body>
      <Form>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.validate-form-modal.reason')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="select"
                {...register('reason', { validate: value => value !== '' })}
                defaultValue={''}
              >
                <option hidden value={''}></option>
                {reasons.map((option, index) => {
                  return <option key={index} value={option}>{option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.validate-form-modal.comment')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                {...register('comment')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.validate-form-modal.action')}
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select" {...register('alarm_status', { required: true })} disabled={!canClose}>
                {actions.map((action, index) => {
                  return <option key={index} value={action.alarm_status}>{action.option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <div style={{minHeight: '50px'}}>
            {watchAlarmStatus === 'scheduled' && <Form.Group as={Row}>
              <Form.Label column sm="3">Date</Form.Label>
              <Col sm="9">
                <SingleDatePicker
                  date={date}
                  isOutsideRange={day => !isToday(day) && day.isBefore(moment()) }
                  onDateChange={date => setDate(date)}
                />
              </Col>
            </Form.Group>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>{t('cancel')}</Button>
          <Button
            variant="danger"
            type="button"
            disabled={loading || !formState.isValid}
            onClick={handleSubmit(data => submitData({...data, notify: false}))}
          >
            {t('components.equipment.device-alarm-status.validate-form-modal.proceed')}
          </Button>
          {isValid && alarm?._config?.notify_on_valid && <Button
            variant="warning"
            type="button"
            disabled={loading || !formState.isValid}
            onClick={handleSubmit(data => submitData({...data, notify: true}))}
          >
            {t('components.equipment.device-alarm-status.validate-form-modal.proceed-with-notification')}
          </Button>}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function ValidateActionButton({
  alarm,
  variant='warning',
  buttonText= t('components.equipment.common.valid')
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState({});
  const { postEvent, loading } = useAlarmEvent(alarm.id);

  const handleValidate = useCallback(data => {
    if(data.alarm_status === 'closed') {
      // Show confirm modal if user chooses to close event.
      setDataToSubmit(data);
      setShowForm(false);
      setShowConfirm(true);
      return;
    }
    postEvent(data);
  }, [postEvent]);

  const handleConfirm = useCallback(() => {
    postEvent(dataToSubmit);
  }, [postEvent, dataToSubmit]);

  return (
    <>
      <ValidateFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        isValid
        onSubmit={handleValidate}
        loading={loading}
        alarm={alarm}
      />
      <CloseConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        confirm={handleConfirm}
        loading={loading}
      />
      <Button variant={variant} onClick={() => setShowForm(true)}>{buttonText}</Button>
    </>
  );
}

function InvalidateActionButton({alarm, variant="warning"}) {
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState({});
  const { postEvent, loading } = useAlarmEvent(alarm?.id);
  const handleValidate = useCallback(data => {
    if(data.alarm_status === 'closed') {
      // Show confirm modal if user chooses to close event.
      setDataToSubmit(data);
      setShowForm(false);
      setShowConfirm(true);
      return;
    }
    postEvent(data);
  }, [postEvent]);

  const handleConfirm = useCallback(() => {
    postEvent(dataToSubmit);
  }, [postEvent, dataToSubmit]);

  return (
    <>
      <ValidateFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        isValid={false}
        onSubmit={handleValidate}
        loading={loading}
        alarm={alarm}
      />
      <CloseConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        confirm={handleConfirm}
        loading={loading}
      />
      <Button
        variant={variant}
        onClick={() => setShowForm(true)}
      >
        {t('components.equipment.device-alarm-status.invalidate-action-button.invalid')}
      </Button>
    </>
  );
}

function ScheduleFormModal({onSubmit, loading, ...props}) {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const [date, setDate] = useState(moment().add(7, 'days'));

  const submitData = useCallback((data) => {
    data.alarm_status = 'scheduled';
    if(date) {
      data.due_date = moment(date).format('YYYY-MM-DD');
    }
    if(typeof onSubmit === 'function') onSubmit(data);
  }, [onSubmit, date]);

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FaCogs />&nbsp;{t('components.equipment.device-alarm-status.schedule-form-modal.action-required')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('components.equipment.device-alarm-status.schedule-form-modal.schedule-maintenance')}.</Modal.Body>
      <Form onSubmit={handleSubmit(submitData)}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.schedule-form-modal.comment')}
            </Form.Label>
            <Col sm="9">
              <Form.Control as="textarea" {...register('comment')} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.schedule-form-modal.date')}
            </Form.Label>
            <Col sm="9">
              <SingleDatePicker
                date={date}
                isOutsideRange={day => !isToday(day) && day.isBefore(moment())}
                onDateChange={date => setDate(date)}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>{t('cancel')}</Button>
          <Button
            variant="danger"
            type="submit"
            disabled={loading}
          >
            {t('components.equipment.device-alarm-status.schedule-form-modal.proceed')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function ScheduleActionButton({alarm}) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const { postEvent, loading, } = useAlarmEvent(alarm.id);
  const handleValidate = useCallback(data => {
    postEvent(data);
  }, [postEvent]);

  return (
    <>
      <ScheduleFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        onSubmit={handleValidate}
        loading={loading}
      />
      <Button
        variant="info"
        onClick={() => setShowForm(true)}
      >
        {t('components.equipment.device-alarm-status.schedule-action-button.schedule')}
      </Button>
    </>
  );
}

function UpdateFormModal({onSubmit, loading, ...props}) {
  const { t } = useTranslation();
  const statusActions = [
    {
      alarm_status: 'in_progress',
      option: t('components.equipment.device-alarm-status.update-form-modal.maintenance-in-progress')
    },
    {
      alarm_status: 'resolved',
      option: t('components.equipment.device-alarm-status.update-form-modal.maintenance-completed')
    },
    {
      alarm_status: 'scheduled',
      option: t('components.equipment.device-alarm-status.update-form-modal.maintenance-rescheduling')
    }
  ];

  const { register, handleSubmit, watch } = useForm({
    mode: 'onChange',
  });
  const watchAlarmStatus = watch('alarm_status');
  const [date, setDate] = useState(null);

  useEffect(() => {
    if(watchAlarmStatus === 'scheduled') {
      setDate(moment().add(7, 'days'));
    } else {
      setDate(null);
    }
  }, [watchAlarmStatus]);

  const submitData = useCallback((data) => {
    if(date) data.due_date = moment(date).format('YYYY-MM-DD');
    if(typeof onSubmit === 'function') onSubmit(data);
  }, [onSubmit, date]);

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FaCogs />&nbsp;{t('components.equipment.device-alarm-status.update-form-modal.update-required')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('components.equipment.device-alarm-status.update-form-modal.relevant-comments')}
      </Modal.Body>
      <Form onSubmit={handleSubmit(submitData)}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.update-form-modal.status')}
            </Form.Label>
            <Col sm="9">
              <Form.Control as="select" {...register('alarm_status')}>
                {statusActions.map((action, index) => {
                  return <option key={index} value={action.alarm_status}>{action.option}</option>;
                })}
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              {t('components.equipment.device-alarm-status.update-form-modal.comment')}
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                {...register('comment')}
              />
            </Col>
          </Form.Group>
          <div style={{minHeight: '50px'}}>
            {watchAlarmStatus === 'scheduled' &&
              <Form.Group as={Row}>
                <Form.Label column sm="3">Date</Form.Label>
                <Col sm="9">
                  <SingleDatePicker
                    date={date}
                    isOutsideRange={day => !isToday(day) && day.isBefore(moment())}
                    onDateChange={date => setDate(date)}
                  />
                </Col>
              </Form.Group>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {loading && <LoadingSpinner inline size={1} />}
          <Button variant="outline-secondary" onClick={props.onHide} disabled={loading}>{t('cancel')}</Button>
          <Button
            variant="danger"
            type="submit"
            disabled={loading}
          >
            {t('components.equipment.device-alarm-status.update-form-modal.proceed')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function UpdateActionButton({alarm}) {
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const { postEvent, loading, success } = useAlarmEvent(alarm.id);
  const handleUpdate = useCallback(data => {
    postEvent(data);
  }, [postEvent]);

  useEffect(() => {
    if(success && !loading) setShowForm(false);
  }, [success, loading]);

  return (
    <>
      <UpdateFormModal
        show={showForm}
        onHide={() => setShowForm(false)}
        onSubmit={handleUpdate}
        loading={loading}
      />
      <Button variant="danger" onClick={() => setShowForm(true)}>{t('Update')}</Button>
    </>
  );
}

function DeviceAlarmStatusIsNew({ alarm }) {
  const { t } = useTranslation();
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>{t('components.equipment.device-alarm-status.action-required')}.</strong>&nbsp;
        <span>{t('components.equipment.device-alarm-status.acknowledge-alarm')}</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <AcknowledgeActionButton alarm={alarm} variant="warning" />
      </>
    );
  }, []);
  return <DeviceAlarmStatusRow variant="warning" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsAck({ alarm }) {
  const { t } = useTranslation();
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>{t('components.equipment.device-alarm-status.action-required')}.</strong>&nbsp;
        <span>{t('components.equipment.device-alarm-status.mark-valid')}</span>
      </div>
    );
  }, []);
  const renderAction = useCallback(() => {
    return (
      <>
        <ValidateActionButton alarm={alarm} />&nbsp;
        {/* {alarm._config?.notify_on_valid && <ValidateActionButton alarm={alarm} buttonText="Valid with Notification" notify={true} />}&nbsp; */}
        <InvalidateActionButton alarm={alarm} />
      </>
    );
  }, []);
  return <DeviceAlarmStatusRow variant="warning" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsClose({alarm}) {
  const renderStatus = useCallback(() => {
    return (
      <span>Event {alarm.closed_as}</span>
    );
  }, []);

  return <DeviceAlarmStatusRow variant="dark" status={renderStatus} />;
}

function DeviceAlarmStatusIsInvalid({ alarm }) {
  const { t } = useTranslation();
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>{t('components.equipment.device-alarm-status.invalid-event')}</strong>&nbsp;
        <span>{t('components.equipment.device-alarm-status.close-valid')}</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <ValidateActionButton alarm={alarm} variant="danger" />&nbsp;
        <CloseActionButton alarm={alarm} />
      </>
    );
  }, [alarm]);

  return <DeviceAlarmStatusRow variant="secondary" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsValid({ alarm }) {
  const { t } = useTranslation();
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>{t('components.equipment.device-alarm-status.valid-event')}</strong>&nbsp;
        <span>{t('components.equipment.device-alarm-status.schedule-close')}</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <ScheduleActionButton alarm={alarm} />&nbsp;
        <InvalidateActionButton alarm={alarm} variant="secondary" />&nbsp;
        <CloseActionButton alarm={alarm} />
      </>
    );
  }, [alarm]);

  return <DeviceAlarmStatusRow variant="danger" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsScheduled({alarm}) {
  const { t } = useTranslation();
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>
          {alarm.overdue ?
            t('components.equipment.device-alarm-status.overdue-event') :
            t('components.equipment.device-alarm-status.valid-event')
          }
          &nbsp;{t('components.equipment.device-alarm-status.scheduled-for', {
            scheduledDate: moment(alarm.due_at).local().format('DD MMM YYYY')
          })}
        </strong><br />
        <span>{t('components.equipment.device-alarm-status.update-progress')}</span>
      </div>
    );
  }, [alarm]);
  const renderAction = useCallback(() => {
    return (
      <>
        <UpdateActionButton alarm={alarm} />&nbsp;
        <InvalidateActionButton alarm={alarm} />&nbsp;
      </>
    );
  }, [alarm]);
  return <DeviceAlarmStatusRow variant={alarm.overdue ? 'danger' : 'info'} status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsInProgress({alarm}) {
  const { t } = useTranslation();
  const renderStatus = useCallback(() => {
    return (
      <div>
        {alarm.overdue ?
          <strong>
            {t('components.equipment.device-alarm-status.overdue-event')}
            &nbsp;{t('components.equipment.device-alarm-status.scheduled-for', {
              scheduledDate: moment(alarm.due_at).local().format('DD MMM YYYY')
            })}
          </strong> :
          <strong>
            {t('components.equipment.device-alarm-status.valid-event')}
            &nbsp;{t('components.equipment.device-alarm-status.maintenance-in-progress')}.
          </strong>
        }<br />
        <span>{t('components.equipment.device-alarm-status.update-progress')}</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <>
        <UpdateActionButton alarm={alarm} />&nbsp;
        <InvalidateActionButton alarm={alarm} />
      </>
    );
  }, [alarm]);

  return <DeviceAlarmStatusRow variant={alarm.overdue ? 'danger' : 'primary'} status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatusIsResolved({alarm}) {
  const { t } = useTranslation();
  const renderStatus = useCallback(() => {
    return (
      <div>
        <strong>{t('components.equipment.device-alarm-status.resolved-event')}</strong>&nbsp;
        <span>{t('components.equipment.device-alarm-status.finalise-comments')}</span>
      </div>
    );
  }, []);

  const renderAction = useCallback(() => {
    return (
      <CloseActionButton alarm={alarm} />
    );
  }, [alarm]);
  return <DeviceAlarmStatusRow variant="success" status={renderStatus} action={renderAction} />;
}

function DeviceAlarmStatus({ alarmId }) {
  const alarm = useAlarm(alarmId);
  switch(alarm.alarm_status) {
    case 'new': return <DeviceAlarmStatusIsNew alarm={alarm} />;
    case 'acknowledged': return <DeviceAlarmStatusIsAck alarm={alarm} />;
    case 'invalid': return <DeviceAlarmStatusIsInvalid alarm={alarm} />;
    case 'valid': return <DeviceAlarmStatusIsValid alarm={alarm} />;
    case 'scheduled': return <DeviceAlarmStatusIsScheduled alarm={alarm} />;
    case 'in_progress': return <DeviceAlarmStatusIsInProgress alarm={alarm} />;
    case 'resolved': return <DeviceAlarmStatusIsResolved alarm={alarm} />;
    case 'closed': return <DeviceAlarmStatusIsClose alarm={alarm} />;
    default: return null;
  }
}

export default DeviceAlarmStatus;
