// Asset start/stop trend tile for Energy Dash.
import { memo } from 'react';
import HomeCard from './HomeCard';
import { equipmentNameLink } from './columns';
import Table from '../Table';
import RunningStatus from '../../modules/equipment/components/RunningStatus';
import { inlineHeaderFormatter, percentageNumberFormatter } from '../table/formatters';
import { IoIosCog } from 'react-icons/io';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';

const columns = [
  equipmentNameLink,
  // @TODO: column start/stop bars.
  {
    text: 'Starts/Stops',
    dataField: 'starts_stops',
    icon: <RunningStatus value={false} />,
    headerFormatter: inlineHeaderFormatter,
  },
  {
    text: 'Utilisation 30 Day Av',
    dataField: 'utilisation_30day',
    icon: <IoIosCog size="1.4em" />,
    headerFormatter: inlineHeaderFormatter,
    formatter: percentageNumberFormatter,
  }
];

const data = [
  {equipment_name: 'AHU 3 Supply Fan 9/6', device_id: 1, starts_stops: 14, utilisation: 35 },
  {equipment_name: 'MLSpray Booth Recirculation Fan LH', device_id: 2, starts_stops: 12,  utilisation: 43 },
  {equipment_name: 'Main Drive Gearbox', device_id: 3, starts_stops: 9,  utilisation: 47 },
  {equipment_name: 'Refined stock 1 Discharge pump', device_id: 4, starts_stops: 7,  utilisation: 53 },
  {equipment_name: 'Cabuyao Equipment #15', device_id: 5, starts_stops: 4,  utilisation: 77 },
];

function StartStopTrend() {
  const { filterAll } = useDeviceStatusFilter();
  return (
    <HomeCard
      header="Asset start/stop trend"
      headerLink={{to: '/equipment/list?type=fitpower', text: 'All equipment', onClick: filterAll}}
    >
      <Table
        data={data}
        keyField="device_id"
        columns={columns}
      />
    </HomeCard>
  );
}

export default memo(StartStopTrend);