import moment from 'moment';

// Given a number as timestamp, display the date time as rules below:
// If the timestamp is on today, only show time(no date and year).
// If the timestamp is in the same year, only show date(no year).
// If the timestamp is not in the same year, show time, date and year.
// timestamp can be a number for timestamp or Date object.
export const displayDateTime = (timestamp: number | Date) => {
  const momentTime = moment(typeof timestamp === 'number' ? timestamp*1000 : timestamp);
  const momentNow = moment();
  if(momentTime.isSame(momentNow, 'day')) {
    return momentTime.format("h:mma");
  }
  if(momentTime.isSame(momentNow, 'year')) {
    return momentTime.format("D MMM h:mma");
  }
  return momentTime.format("D MMM, YYYY h:mma");
};


// Given the number of minutes, convert it to milliseconds.
// If the minutes is an invalid number or unavailable or too small(less than 10s), use the default minutes (10s).
export const getIntervalMilliSeconds = (minutes: any): number => {
  const DEFAULT_MINUTES = 1/6;
  return Math.max(parseFloat(minutes) || 0, DEFAULT_MINUTES) * 60 * 1000;
};

/**
 * Format a number by commas with maximum decimal places.
 * e.g. 123456789 => 123,456,789
 * e.g. 1234567.89 => 1,234,567.89
 * e.g. 1234.56789 => 1,234.5679
 * e.g. -1234.56789 => -1,234.5679
 */
export const formatNumber = (number: number, maxDecimalPlaces: number = 4) => {
  if(typeof number !== 'number') return 0;
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDecimalPlaces,
  });
};

/**
 * Format a currency by commas with 2 decimal places.
 * e.g. 123456789 => $123,456,789.00
 * e.g. 1234567.89 => $1,234,567.89
 * e.g. 1234.56789 => $1,234.57
 * e.g. -1234.56789 => -$1,234.57
 */
export const formatCurrency = (number: number, currency: string = 'USD', digits = 2) => {
  // enforce US style eg. `-1234.56789` => `-$1,234.57`
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
};

export const getChartLabelColor = (color: string) => {
  let colorHex;
  switch(color) {
    case 'red':
      colorHex = '#e57373'; break;
    case 'grey':
      colorHex = '#6c757d'; break;
    case 'blue':
      colorHex = '#0099ff'; break;
    case 'orange':
      colorHex = '#fd8814'; break;
    case 'green':
      colorHex = '#81c784'; break;
    case 'yellow':
      colorHex = '#fff176'; break;
    default:
      colorHex = '#dddddd';
  }
  return colorHex;
};

/**
 * Given a number, return how many digits it has.
 * e.g. 1 => 1
 * 9999 => 4
 * 12345.67 => 5 (For float, just return the digits on its integer part.)
 */
export const getNumberDigits = (num: number) => {
  const numStr = Math.abs(num).toString();
  const integerNum = numStr.split('.')[0];
  return integerNum.length;
};

/**
 * Given a number, round it up to its closest number that can be divided by 5 or 10.
 * based on how many digits the number has.
 */
export const roundNumber = (num: number, minDigits = 5) => {
  const sign = num < 0 ? -1 : 1;
  num = Math.abs(num);
  if(num <= 5) return 5 * sign; // Round up to 5 if number <= 5.
  if(num <= 10) return 10 * sign;   // Round up to 10 if number <= 10.

  // Now handle the number bigger than 10.
  const numDigits = getNumberDigits(num);
  const digits = Math.min(minDigits, numDigits);
  const factor = Math.pow(10, digits - 1) ;
  const factor2 = numDigits < minDigits && numDigits > 1 ? factor / 2 : factor;
  const numFactoredFloor = Math.floor( num / factor ) * factor + factor2;
  const numFactoredCeil = Math.floor( num / factor ) * factor + factor;
  return num >= numFactoredFloor ? numFactoredCeil * sign : numFactoredFloor * sign;
};

const MAX_ALLOWED_POSITIVE_TICKS = 5;
const MAX_ALLOWED_NEGATIVE_TICKS = 10;
export const alignTwoYAxises = (data1: number[], data2: number[]) => {
  // It is assumed data1[] may have negative numbers, data2[] has positive numbers only.
  // It's not fully tested when data2[] includes negative numbers.
  const max1 = Math.max(...data1);
  const roundedMax1 = roundNumber(max1);
  const min1 = Math.min(...data1);
  const roundedMin1 = Math.min(roundNumber(min1), 0);

  const max2 = Math.max(...data2);
  const roundedMax2 = roundNumber(max2);
  const min2 = Math.min(...data2);
  const roundedMin2 = Math.min(roundNumber(min2), 0);
  const min = Math.min(roundedMin1, roundedMin2);
  const max = Math.max(roundedMax1, roundedMax2);

  let positiveTicks = MAX_ALLOWED_POSITIVE_TICKS;
  let interval1 = roundedMax1 / positiveTicks;
  let interval2 = roundedMax2 / positiveTicks;
  let negativeTicks = Math.floor(min/interval1);

  if(negativeTicks <= -MAX_ALLOWED_NEGATIVE_TICKS) {
    // Adjust the ticks if negative ticks are too big.
    negativeTicks = -MAX_ALLOWED_NEGATIVE_TICKS;
    interval1 = roundedMin1 / negativeTicks;
    positiveTicks = Math.ceil(max / interval1);
    interval2 = roundedMin2 >= 0 ? roundedMax2 / positiveTicks : roundedMin2 /negativeTicks;
  }
  return [interval1, interval2, positiveTicks, negativeTicks];
};

// Given a number, return its leading zero.
// e.g. 2.05 -> 0 (If the number is bigger than 1, always return 0)
// 0.123 -> 0
// 0.000203 -> 3
export const countLeadingZero = (decimal: number) => {
  if(decimal >= 1) return 0;
  const decimalStr = decimal.toString();
  const match = decimalStr.match(/\.0+/);
  if (match) {
    return match[0].length - 1;
  } else {
    return 0;
  }
};

export const escapeSpecialCharacters = (fileName: string) => {
  const specialCharsRegex = /[<>:"/\\|?*#&@]/g;
  const escapedFileName = fileName.replace(specialCharsRegex, '_');
  return escapedFileName;
};