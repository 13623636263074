
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, { match }) => {

  // get vars from path location
  const gatewayPathString = match && match.params && match.params['id'];
  const gatewayPathId = parseInt(gatewayPathString) || undefined;

  return {
    // note that the gateway id's existence/authorisation isn't validated
    gatewayId: gatewayPathId,
  };
};

export default compose(
  // pass router props to redux to make "match" available
  withRouter,
  connect(mapStateToProps),
);
