
import React, { useState, useEffect, useCallback } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

import './singleDatePicker.scss';

export const dateFormat = 'D MMM YYYY';

// startDate and endDate can be specified in ms or be undefined
export default function SingleDatePickerWrapper({ date, onDateChange, ...otherProps }) {

  const maybeValidMoment = moment(date);
  const validMomentOrUndefined = maybeValidMoment.isValid() ? maybeValidMoment : undefined;

  // start value from defaultValues
  const [focused, setFocused] = useState();
  const [momentDate, setDate] = useState(validMomentOrUndefined);

  // update value if new defaultValues are given
  useEffect(() => setDate(validMomentOrUndefined), [date]);

  // set value if changed by the user
  const onFocusChange = useCallback(({ focused }) => setFocused(focused), [setFocused]);

  const handleDateChange = useCallback(date => {
    setDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
  }, [onDateChange]);

  return (
    <SingleDatePicker
      // defaults
      // selectable range is days in the past
      isOutsideRange={day => day.startOf('day').isAfter()}
      showDefaultInputIcon={true}
      displayFormat={dateFormat}

      // given
      {...otherProps}

      // overrides
      readOnly={true} // prevent users from typing invalid strings
      date={momentDate.local(dateFormat)}
      focused={focused}
      onDateChange={handleDateChange}
      onFocusChange={onFocusChange}
    />
  );
}
