import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Translation } from 'react-i18next';

import { fetchAlarms } from '../actions';
import Table from '../../../components/Table';
import { useTableProps } from './Alarms';
import EmptyCard from '../../../components/EmptyCard';
import { IoIosNotificationsOutline } from "react-icons/io";

import {
  siteName,
  subAreaName,
  equipmentName,
  organisationName,
} from '../../equipment/columns';

import {
  userAlarmName,
  alarmTimestamp,
  alarmStatusExtra as statusBadge,
  userAlarmDescription,
  createdBy,
  current,
} from '../columns';

import { getAlarms, getAlarmListState } from '../selectors';
import {
  getActiveSubGroupId,
  getOrganisationUserColumns,
} from '../../organisation/selectors';
import { fetchDevices } from '../../equipment/actions';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

const defaultSorted = [{
  dataField: 'alarm_timestamp',
  order: 'desc'
}];

const alarmTypeFilter = 'user';

const columnsArr = [
  alarmTimestamp,
  current,
  userAlarmName,
  siteName,
  { ...subAreaName, dataField: 'site_subarea_name' },
  equipmentName,
  statusBadge,
  organisationName,
  userAlarmDescription,
  createdBy,
];


function NoDataIndication() {
  return (
    <EmptyCard Icon={IoIosNotificationsOutline}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          <Translation>
            {(t, {i18n}) => t('screens.alarms.not-created-alarms')}
          </Translation>
        </EmptyCard.Title>
        <EmptyCard.Text>
          <Translation>
            {(t, {i18n}) => t('screens.alarms.users-can-trigger')}
          </Translation>
        </EmptyCard.Text>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

function Alarms({
  activeSubGroupId,
  alarms=[],
  fetchAlarms,
  fetchDevices,
  loading,
  lastFetch,
  error,
  organisationUserColumns,
}) {

  const { refresh, renderHeader, filteredAlarms, columns } = useTableProps({
    alarmTypeFilter,
    activeSubGroupId,
    fetchAlarms,
    fetchDevices,
    loading,
    lastFetch,
    error,
    alarms,
    columnsArr,
    organisationUserColumns,
  });
  const history = useHistory();
  const localisedColumns = useLocalisedColumns(columns);

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        defaultSorted={defaultSorted}
        noDataIndication={NoDataIndication}
        loading={loading}
        columns={localisedColumns}
        data={filteredAlarms}
        refreshHandler={refresh}
        rowEvents = {{
          onClick: (e, alarm) => {
            if(window.getSelection().toString().length > 0) return;
            history.push(`/alarms/${alarm.id}`);
          }
        }}
        rowStyle={{
          cursor: 'pointer'
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const alarms = getAlarms(state);
  const { loading, lastFetch, error } = getAlarmListState(state);
  return {
    alarms,
    loading,
    lastFetch,
    error,
    activeSubGroupId: getActiveSubGroupId(state),
    organisationUserColumns: getOrganisationUserColumns(state),
  };
};

const mapDispatchToProps = { fetchAlarms, fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
