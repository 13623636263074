
import React, { cloneElement, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';

import ConfirmModal from '../../../components/ConfirmModal';
import { getOrganisation, getGroup, getGroupTreeNode, getGroupTreeNodeParent, getCurrentOrganisationHasProductCode } from '../selectors';
import { selectActiveGroup, fetchGroup, fetchOrganisationGroups, deleteGroup } from '../actions';

function RemoveGroupConfirmModal({
  groupId,
  groupNode = {},
  // get most current data from group list, default to (presumably stale) data from group tree
  group = { id: groupNode.id, group_name: groupNode.name },
  groupParent = {},
  organisation = {},
  selectActiveGroup,
  fetchGroup,
  deleteGroup,
  fetchOrganisationGroups,
  children,
  ...props
}) {

  const onClick = useCallback(async e => {
    // e.preventDefault();
    if (group.id && groupParent.id) {
      try {
        await deleteGroup(group, { force: true });
        await fetchOrganisationGroups(organisation);
        // switch focus to deleted group parent
        selectActiveGroup({ id: groupParent.id });
      }
      catch(err) {
        // allow error to prevent closing of the modal
        throw new Error(err);
      }
    }
  }, [group.id, groupParent.id, organisation]);

  const onShow = useCallback(() => fetchGroup(group), [group.id]);

  // do not show delete modal or children if the group has no parent
  if (!groupParent.id) {
    return null;
  }

  return (
    <ConfirmModal
      // set defaults
      header="Are you sure?"
      body={(
        <div>
          <p>This will remove the group “{group.group_name}” and all its sub-groups.</p>
          <p>Equipment, devices, and users within the group will not be deleted.</p>
        </div>
      )}
      confirmText="Remove group"
      // add given props
      {...props}
      // override given props
      size="md"
      onShow={onShow}
    >
      {cloneElement(children, { onClick })}
    </ConfirmModal>
  );
}

const mapStateToProps = (state, { groupId }) => {
  return {
    groupId,
    group: getGroup(state, groupId),
    groupNode: getGroupTreeNode(state, groupId),
    groupParent: getGroupTreeNodeParent(state, groupId),
    organisation: getOrganisation(state),
  };
};
const mapDispatchToProps = {
  selectActiveGroup,
  fetchGroup,
  fetchOrganisationGroups,
  deleteGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(props => {
  return useSelector(state => getCurrentOrganisationHasProductCode(state, 'groups_equipment')) ? (
    <RemoveGroupConfirmModal {...props} />
  ) : null;
});
