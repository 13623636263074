import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getOrganisationUserColumns } from "../selectors";
import { stringSortFunc, stringSortValueWithHumanNumbersFunc } from "../../../components/table/utils";

export default function useOrganisationUserColumns(options = {}) {
  const { showTag } = options;
  const organisationUserColumns = useSelector(state => getOrganisationUserColumns(state));

  const columns = useMemo(() => {
    return organisationUserColumns.map((col = {}) => {
      return {
        text: col.label,
        dataField: `tags.${col.tag}`,
        hidden: !col.show?.includes(showTag),
        formatter: value => value || col.default || 'N/A',
        sort: true,
        sortFunc: stringSortFunc,
        sortValue: stringSortValueWithHumanNumbersFunc,
        __csvTextFormatter: (value, column, options) => {
          return column?.tags?.[col?.tag] || col.default;
        },
      };
    });
  }, [showTag, organisationUserColumns]);

  return { columns };
}