import { useMemo, useCallback, useState } from 'react';
import { Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import useDomain from '../../modules/domain/hooks/useDomain';
import BasicModal from '../BasicModal';
import { getFormattedValue } from '../values/utils/displayUtils';
import IoIosMotor from '../IoIosMotor';
import { useComponentViewTracking } from '../../modules/app/hooks';
import { getUserDisplayPreference as getUserRmsDisplayPreference } from '../values/Rms';
import { getUserPreferenceValue } from '../../modules/user/selectors';
import { setVibrationRms } from '../../modules/equipment/utils';

const getBackgroundClass = (colour) => {
  return colour === 'g' ? 'bg-success' :
    colour === 'y' ? 'bg-warning' :
      colour === 'r' ? 'bg-danger' :
        'bg-secondary';
};

function ISOTableMark({device}) {
  const displayRms = useSelector(getUserRmsDisplayPreference);
  return (
    <OverlayTrigger
      overlay={
        <Tooltip className="d-flex align-items-center">
          <ISOIndicator device={device} />&nbsp;
          RMS: {getFormattedValue(device?.vibration_rms, displayRms)}{device?.iso_class && <span>, {device?.iso_class}</span>}&nbsp;
        </Tooltip>
      }>
      <span className="d-inline-block">
        <IoIosMotor size="1.5em" className="text-dark" />
      </span>
    </OverlayTrigger>
  );
}

export function ISOIndicator({device, size = '16px'}) {
  const { domainData } = useDomain({key: 'isotable'});

  const orderedGroupings = useMemo(() => {
    const groupings = domainData?.Groupings ? [...domainData.Groupings] : [];
    return groupings.sort((a, b) => b.mms - a.mms);
  }, [domainData]);

  const isoColour = useMemo(() => {
    if(!device) return;
    const foundGrouping = orderedGroupings.find((value) => {
      return device.vibration_rms >= value.mms;
    });
    return foundGrouping && foundGrouping[device.iso_class];
  }, [orderedGroupings, device]);

  if(!domainData) return null;

  return <span
    className={getBackgroundClass(isoColour)}
    style={{
      display: 'inline-block',
      width: size,
      height: size,
      borderRadius: '50%',
    }}></span>;
}

function ISOTableModal({ device = {}, ...props }) {
  device = setVibrationRms(device);
  const displayRms = useSelector(getUserRmsDisplayPreference);
  const unitPreference = useSelector(state => getUserPreferenceValue(state, 'units_system'));

  const classMap = {
    class1: 'Class I',
    class2: 'Class II',
    class3: 'Class III',
    class4: 'Class IV',
  };

  const { domainData } = useDomain({
    key: 'isotable',
    fetch: true,
    cache: false,
  });

  const orderedGroupings = useMemo(() => {
    const groupings = domainData?.Groupings ? [...domainData.Groupings] : [];
    return groupings.sort((a, b) => a.mms - b.mms);
  }, [domainData?.Groupings]);
  const [openIsoTable, setOpenIsoTable] = useState(false);

  useComponentViewTracking('ISO Table', openIsoTable && 'device_id', {device_id: device.id});

  const renderIsoTable = useCallback(() => {
    return (
      <Table bordered>
        <thead>
          <tr>
            <th colSpan={1}>Velocity</th>
            <th colSpan={4}>Velocity Range Limits and Machine Class</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{unitPreference === 'US' ? 'in/s' : 'mm/s'}<br />RMS</td>
            <td>{domainData[`${classMap.class1}`].Description}<br />{classMap.class1}</td>
            <td>{domainData[`${classMap.class2}`].Description}<br />{classMap.class2}</td>
            <td>{domainData[`${classMap.class3}`].Description}<br />{classMap.class3}</td>
            <td>{domainData[`${classMap.class4}`].Description}<br />{classMap.class4}</td>
          </tr>
          {
            orderedGroupings.map((grouping, index) => (
              <tr key={index}>
                <td>{getFormattedValue(grouping.mms, {...displayRms, units: ''})}</td>
                <td className={`text-center ${getBackgroundClass(grouping[classMap.class1])}`}>
                  {device.iso_class === classMap.class1 &&
                    device.vibration_rms >= grouping.mms &&
                      device.vibration_rms < orderedGroupings[index+1]?.mms && <ISOTableMark device={device} />}
                </td>
                <td className={`text-center ${getBackgroundClass(grouping[classMap.class2])}`}>
                  {device.iso_class === classMap.class2 &&
                    device.vibration_rms >= grouping.mms &&
                      device.vibration_rms < orderedGroupings[index+1]?.mms && <ISOTableMark device={device} />}
                </td>
                <td className={`text-center ${getBackgroundClass(grouping[classMap.class3])}`}>
                  {device.iso_class === classMap.class3 &&
                    device.vibration_rms >= grouping.mms &&
                      device.vibration_rms < orderedGroupings[index+1]?.mms && <ISOTableMark device={device} />}
                </td>
                <td className={`text-center ${getBackgroundClass(grouping[classMap.class4])}`}>
                  {device.iso_class === classMap.class4 &&
                    device.vibration_rms >= grouping.mms &&
                      device.vibration_rms < orderedGroupings[index+1]?.mms && <ISOTableMark device={device} />}
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  }, [domainData?.Groupings, device, orderedGroupings]);

  if(!domainData) return null;

  return (
    <div className="d-flex flex-wrap border p-2">
      <div className="d-flex align-items-center mr-4">
        <ISOIndicator device={device} />&nbsp;
        <span>RMS: {getFormattedValue(device?.vibration_rms, displayRms)}{device?.iso_class && <span>, {device.iso_class}</span>}</span>
      </div>
      <div>
        <BasicModal
          size="lg"
          header="ISO table"
          body={renderIsoTable()}
          footer={({ close }) => (
            <Button onClick={close} variant="secondary">OK</Button>
          )}
          onShow={() => setOpenIsoTable(true)}
          onClose={() => setOpenIsoTable(false)}
        >
          <Button size="sm" variant="outline-secondary" style={{minWidth: '80px'}}>ISO Table</Button>
        </BasicModal>
      </div>
    </div>
  );
}

export default ISOTableModal;
