import { useTranslation } from 'react-i18next';

import { useGroupAcknowledgedEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, initialAlarmBadge, acknowledgedBy, latestComments } from './columns';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';

const columns = [
  equipmentNameLink,
  initialAlarmBadge,
  acknowledgedBy,
  latestComments,
];

function AcknowledgedEvents() {
  const { t } = useTranslation();
  const groupAcknowledgedEventsReport = useGroupAcknowledgedEventsReport();
  const localisedColumns = useLocalisedColumns(columns);
  if(!groupAcknowledgedEventsReport) return null;

  const { _state_: { loading, error } = {}, items = [] } = groupAcknowledgedEventsReport || {};
  return (
    <HomeCard
      header={t('home.acknowledged-events')}
      headerLink={{to: '/events?status=acknowledged', text: t('home.all-acknowledged-events')}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={localisedColumns}
        condensed
      />
    </HomeCard>
  );
}

export default AcknowledgedEvents;
