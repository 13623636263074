import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addAlarmImpact, fetchAlarmImpact, deleteAlarmImpact, updateAlarmImpact, fetchAlarmImpactSummary } from '../actions';
import { getAlarmImpact, getAlarmImpactState, getAlarmImpactSummary } from '../selectors';

export default function useAlarmImpact(alarmId) {
  const dispatch = useDispatch();
  const alarmImpact = useSelector(state => getAlarmImpact(state, alarmId));
  const alarmImpactState = useSelector(state => getAlarmImpactState(state, alarmId));
  const alarmImpactSummary = useSelector(state => getAlarmImpactSummary(state, alarmId));
  const { loading, lastFetch, error } = alarmImpactState || {};

  const fetchImpact = useCallback(() => {
    return dispatch(fetchAlarmImpact({ id: alarmId }));
  }, [alarmId, fetchAlarmImpact]);

  const deleteImpact = useCallback((impactId) => {
    return dispatch(deleteAlarmImpact({ id: alarmId }, { id: impactId })).then(() => { fetchImpact(); fetchImpactSummary(); });
  }, [alarmId, deleteAlarmImpact]);

  const addImpact = useCallback((impactData) => {
    return dispatch(addAlarmImpact({id: alarmId}, impactData)).then(() => { fetchImpact(); fetchImpactSummary(); });
  }, [alarmId, addAlarmImpact]);

  const updateImpact = useCallback((impactId, impactData) => {
    return dispatch(updateAlarmImpact({ id: alarmId }, { id: impactId }, impactData)).then(() => { fetchImpact(); fetchImpactSummary(); });
  }, [alarmId, updateAlarmImpact]);

  const fetchImpactSummary = useCallback(() => {
    return dispatch(fetchAlarmImpactSummary({id: alarmId}));
  }, [alarmId, fetchAlarmImpactSummary]);

  return {
    loading,
    lastFetch,
    error,
    alarmImpact,
    alarmImpactSummary,
    fetchImpact,
    deleteImpact,
    addImpact,
    updateImpact,
    fetchImpactSummary,
  };
}