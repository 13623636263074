
import { createSelector } from "reselect";
import {
  isTopLevelGroupActive,
  getGroupDevices,
  getActiveGroupId,
  getGroupDevicesAvailable,
  getActiveGroupLoadingState,
} from "../organisation/selectors";
import { SENSOR_TYPE } from "./constants";

import { getPlaceholderImageForDevice } from './utils';

const getDevicesById = createSelector(
  state => state,
  state => state.device && state.device.cloudDevices
);

const getActiveDeviceIds = createSelector(
  state => state,
  state => state.device && state.device.idsActive
);

const getArchivedDeviceIds = createSelector(
  state => state,
  state => state.device && state.device.idsArchived
);

const getFitPowerDeviceIds = createSelector(
  state => state,
  state => state.device && state.device.idsFitpower
);

const getFitMachineDeviceIds = createSelector(
  state => state,
  state => state.device && state.device.idsFitmachine
);

const getAllDeviceIds = createSelector(
  state => state,
  state => state.device && state.device.idsAll
);

// basic selector cache
// todo: remove when cloudDevices is refactored and this is no longer of use
const devicesCache = {
  idsActive: [],
  idsArchived: [],
  idsFitmachine: [],
  idsFitpower: [],
  idsAll: [],
  devicesById: {},
  // devices is the cached representation of combination of ids and devicesById
  // ie: ids.map(id => devicesById[id])
  devices: [],
};
export function getDevices(state, { archived=false, forOrg=false, sensorType }={}) {
  // return group devices
  if (!forOrg && !isTopLevelGroupActive(state)) {
    return getGroupDevices(state, getActiveGroupId(state));
  }
  // return organisation devices
  const devicesById = getDevicesById(state);
  // devices are available
  if (devicesById) {
    const idsActive = getActiveDeviceIds(state) || [];
    const idsArchived = getArchivedDeviceIds(state) || [];
    const idsFitmachine = getFitMachineDeviceIds(state) || [];
    const idsFitpower = getFitPowerDeviceIds(state) || [];
    const idsAll = getAllDeviceIds(state) || [];
    // return cached array to reduce react render work by passing shallow comparison when appropriate
    if (
      (devicesCache.devicesById !== devicesById) ||
      (devicesCache.idsActive !== idsActive) ||
      (devicesCache.idsArchived !== idsArchived) ||
      (devicesCache.idsFitmachine !== idsFitmachine) ||
      (devicesCache.idsFitpower !== idsFitpower) ||
      (devicesCache.idsAll !== idsAll)
    ) {
      // else get the new devices array
      // ensure only found devices are returned
      // devices shouldn't be removed form the state (although they may
      // become stale) but we should check here again anyway just in case
      const devicesActive = idsActive.map(id => devicesById[id]).filter(Boolean);
      const devicesArchived = idsArchived.map(id => devicesById[id]).filter(Boolean);
      const devicesFitmachine = idsFitmachine.map(id => devicesById[id]).filter(Boolean);
      const devicesFitpower = idsFitpower.map(id => devicesById[id]).filter(Boolean);
      const allDevices = idsAll.map(id => devicesById[id]).filter(Boolean);
      // and restock the devices cache
      devicesCache.idsActive = idsActive;
      devicesCache.idsArchived = idsArchived;
      devicesCache.devicesById = devicesById;
      devicesCache.devicesActive = devicesActive;
      devicesCache.devicesArchived = devicesArchived;
      devicesCache.devicesFitmachine = devicesFitmachine;
      devicesCache.devicesFitpower = devicesFitpower;
      devicesCache.allDevices = allDevices;
    }
    if(sensorType === SENSOR_TYPE.FITMACHINE) return devicesCache.devicesFitmachine;
    if(sensorType === SENSOR_TYPE.FITPOWER) return devicesCache.devicesFitpower;
    if(sensorType === SENSOR_TYPE.ALL) return devicesCache.allDevices;
    // 'idsActive' actually stores the active sensors returned as per current sensor type.
    // Unless sensor type is specified, use the default value, which works in most cases.
    return !archived ? devicesCache.devicesActive : devicesCache.devicesArchived;
  }
}

export function getArchivedDevices(state) {
  return getDevices(state, { archived: true });
}

export function hasAvailableDevices(state) {
  // get available devices of subgroup or org group
  const availableDevices = !isTopLevelGroupActive(state)
    ? getGroupDevicesAvailable(state, getActiveGroupId(state))
    : getDevices();
  return !!(availableDevices && availableDevices.length > 0);
}

export function getDeviceListState(state) {
  if (!isTopLevelGroupActive(state)) {
    return getActiveGroupLoadingState(state) || {};
  };
  const { device: { loading, lastFetch, error }={} } = state;
  return { loading, lastFetch, error };
}

export function getDevice(state, deviceId) {
  // don't look up getDevicesById directly, as the user may be in a group
  // without access to the device in question

  // restrict access to devices when inside a group
  if (!isTopLevelGroupActive(state)) {
    const devices = [...getDevices(state) || [], ...getArchivedDevices(state) || []];
    const foundDevice = devices && devices.find(device => device.id === deviceId);
    if (!foundDevice) {
      return undefined;
    }
  };

  // return root-level device
  const devicesById = getDevicesById(state);
  return devicesById?.[deviceId];
}

export function getDeviceTags(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device?._embedded?.tags;
};

export function getDeviceProductCodes(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device?.product_codes;
};

export function getDeviceHasProductCode(state, deviceId, productCode) {
  const productCodes = getDeviceProductCodes(state, deviceId);
  return productCodes?.includes(productCode);
};

export function getDeviceHasTimeseriesData(state, deviceId) {
  const device = getDevice(state, deviceId) || {};
  const [versionMajorMinor] = `${device.fitmachine_type}`.match(/\d+\.\d+/) || [];
  // if version is known and more than v3
  // we assume the device hase timeseries data
  // function may return `true`, `false`, or `undefined` (for no version found)
  return versionMajorMinor && Number(versionMajorMinor) >= 2.1;
}

export function getDevicePlaceholderImage(state, deviceId) {
  return getPlaceholderImageForDevice(getDevice(state, deviceId));
}

export function getDeviceTimezone(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.overviewMetadata && device._embedded.overviewMetadata.timezone;
};

export function getDeviceOverview(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.overview;
}

export function getDeviceRuntime(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.runtime;
}

export function getDeviceRuntimeTimezone(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device &&
    device._embedded &&
    device._embedded.runtimeMetadata &&
    device._embedded.runtimeMetadata.timezone;
}

export function getDeviceEvents(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.events;
}

export const getDeviceAlarms = createSelector(
  getDevice,
  device => device?._embedded?.alarms
);

export const getDeviceAlarmListState = createSelector(
  getDevice,
  device => device?._embedded?.alarms_state
);

export const getDeviceAlarmEvents = createSelector(
  getDevice,
  device => device?._embedded?.alarm_events
);

export const getDeviceAlarmEventsState = createSelector(
  getDevice,
  device => device?._embedded?.alarm_events_state
);

export function getDeviceNotificationThresholds(state, deviceId) {
  const device = getDevice(state, deviceId);
  return device && device._embedded && device._embedded.alarmsettings;
}

export function getFirstSampleDateFromDevice(device={}) {
  // convert everything to epochs
  // the mapDeviceFromApi function return moments
  const fitmachine_install_date = !!device.installation_date && device.installation_date.valueOf();
  const fitmachine_onboard_date = !!device.calibration_start && device.calibration_start.valueOf();
  // calculate a best guess for a devices first sample date
  return fitmachine_onboard_date && fitmachine_install_date
    ? new Date(
      Math.min(
        new Date(fitmachine_onboard_date).valueOf(),
        new Date(fitmachine_install_date).valueOf()
      )
    )
    : fitmachine_onboard_date || fitmachine_install_date
      ? new Date(fitmachine_onboard_date || fitmachine_install_date)
      : new Date('2015-01-01'); // MOVUS founded year
}

export function getDeviceStatus(state) {
  return state.device.status || [];
}

export function getDeviceStatusOptions(state, selected) {
  const deviceStatus = state.device.status || [];
  if(selected === undefined) {
    return deviceStatus.map(status => status.option);
  }
  return deviceStatus.filter(status => status.selected === !!selected).map(status => status.option);
}

export function getDeviceSelectedStatusOptions(state) {
  return getDeviceStatusOptions(state, true);
}

export function getDeviceConditionCurves(state) {
  return state.device?.conditionCurves?.items || {};
}

export function getDeviceConditionCurvesKeys(state) {
  return Object.keys(getDeviceConditionCurves(state) || {});
}

export function getDeviceRoles(state) {
  return state.device?.deviceRoles?.items;
}

export function getDeviceRoleName(state, deviceId) {
  const device = getDevice(state, deviceId);
  const roles = getDeviceRoles(state) || [];
  const role = roles.find(r => r.id === device?.role);
  return role?.name;
}

export function getDeviceDisplayNotRunningRanges(state, deviceId) {
  const device = getDevice(state, deviceId);
  const deviceSettings = device?._embedded?.settings?.items;
  const notRunningBoxesEnabled = deviceSettings?.fv_not_running_enabled.value;
  // Default is true in API so fallback to true if not found
  let displayNotRunningRanges = true;
  if (typeof notRunningBoxesEnabled !== 'undefined') displayNotRunningRanges = notRunningBoxesEnabled;
  return displayNotRunningRanges;
}

export const getDeviceImpactConfigValues = createSelector(
  getDevice,
  device => device?._embedded?.impact_config?.items
);

export const getDeviceImpactConfigState = createSelector(
  getDevice,
  device => device?._embedded?.impact_config?._state_
);

export const getDeviceImpactSummary = createSelector(
  getDevice,
  device => device?._embedded?.impact_summary?.items
);

export const getDeviceImpactSummaryState = createSelector(
  getDevice,
  device => device?._embedded?.impact_summary?._state_
);

export const getDeviceLocale = createSelector(
  getDevice,
  device => device?._embedded?.locale
);

export const getDeviceWatchers = createSelector(
  getDevice,
  device => device?._embedded?.watchers?.items
);

export const getDeviceWatchersState = createSelector(
  getDevice,
  device => device?._embedded?.watchers?._state_
);

export const getDeviceSettingsState = createSelector(
  getDevice,
  device => device?._embedded?.settings?._state_
);

export const getDeviceSettings = createSelector(
  getDevice,
  device => device?._embedded?.settings?.items,
);

export const getDeviceLinksState = createSelector(
  getDevice,
  device => device?._embedded?.links?._state_,
);

export const getDeviceLinks = createSelector(
  getDevice,
  device => device?._embedded?.links?.items,
);

export const getDeviceFmerrors = createSelector(
  getDevice,
  device => {
    const fmerrors = device?._embedded?.fmerrors;
    return Array.isArray(fmerrors) ? fmerrors : [];
  }
);

export const getDeviceAxisOptions = createSelector(
  getDevice,
  device => device?.tags?.axis_options
);