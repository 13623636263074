import { useEffect } from 'react';
import { Col, Row, Nav, Button, ButtonGroup, Dropdown, Navbar } from 'react-bootstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { useDispatch } from 'react-redux';
import { IoIosCreate, IoIosConstruct, IoIosListBox } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import { Icon } from '@blueprintjs/core';
import moment from 'moment';

import { fetchGroup } from '../actions';
// import ProfilePageLayout from '../../../components/ProfilePageLayout';
import IoIosMotor from '../../../components/IoIosMotor';
import IoIosFactory from '../../../components/IoIosFactory';
import Private from '../../../components/Private';
import { DropdownTopWindowButton } from '../../../components/DropdownButton';
import { InfoCard, InfoCardItem } from '../../../components/InfoCard';
import useGroup from '../hooks/useGroup';
import './groupPageLayout.scss';

export function canAccessGroupImpact(group) {
  return group && (group.group_type === 'organisation' || group.group_type === 'device' || group.group_type === 'group');
}

function GroupNavbar({group}) {
  const { t } = useTranslation();
  const hasGroupImpactAccess = canAccessGroupImpact(group);

  return (
    <Nav as="ul" className="flex-grow-1">
      <Row className="small-gutters flex-grow-1">
        <Col xs="auto">
        </Col>
        <Col xs="auto" className="ml-auto">
          <Row className="small-gutters">
            <Col xs="auto" className="mb-1">
              <ButtonGroup>
                <Private minUserType="Admin">
                  {group?.group_type !== 'organisation' &&
                    <LinkContainer to="/group/config">
                      <Button variant="outline-secondary">
                        <IoIosCreate size="1.2em" /> <span>{t('Edit')}</span>
                      </Button>
                    </LinkContainer>
                  }
                  {hasGroupImpactAccess && <DropdownTopWindowButton
                    as={ButtonGroup}
                    title={''}
                    // add defaults
                    menuAlign="right"
                    variant="outline-secondary"
                    // allow overrides
                  >
                    {hasGroupImpactAccess &&
                      <>
                        <Dropdown.Header>
                          {t('components.organisations.group-page-layout.event-impact')}
                        </Dropdown.Header>
                        <IndexLinkContainer to={`/group/impact`}>
                          <Dropdown.Item>
                            <IoIosConstruct size="1.2em" /> <span>
                              {t('components.organisations.group-page-layout.configure-rates')}
                            </span>
                          </Dropdown.Item>
                        </IndexLinkContainer>
                        <IndexLinkContainer to={`/group/impact_summary`}>
                          <Dropdown.Item>
                            <IoIosListBox size="1.2em" /> <span>
                              {t('components.organisations.group-page-layout.event-impact-summary')}
                            </span>
                          </Dropdown.Item>
                        </IndexLinkContainer>
                      </>
                    }
                  </DropdownTopWindowButton>}
                </Private>
              </ButtonGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </Nav>
  );
}

// eslint-disable-next-line
function GroupSidebar({ group }) {
  return (
    <>
      <h5 className="montserrat text-truncate" style={{ marginTop: 10, marginBottom: 15 }}>
        {group?.group_name}
      </h5>
      <InfoCard>
        <InfoCardItem heading="Type">
          {group?.group_type}
        </InfoCardItem>
        <InfoCardItem heading="Created">
          {group && moment(group.created_at).format('ll')}
        </InfoCardItem>
        {(group?.group_type === 'device' || group?.group_type === 'organisation') &&
        <>
          <InfoCardItem heading="Alarm Channel Enabled">
            {group.enable_alarm_channel ? 'Yes' : 'No'}
          </InfoCardItem>
          {group.enable_alarm_channel &&
            <InfoCardItem heading="Alarm Channel Name">
              {group.alarm_channel_name}
            </InfoCardItem>}
          <InfoCardItem heading="Alarm Escalation">
            {group.alarm_escalate ? 'Yes' : 'No'}
          </InfoCardItem>
          {group.alarm_escalate && <InfoCardItem heading="Alarm Escalation Timeout">
            {group.alarm_escalate_timeout} hour{group.alarm_escalate_timeout !== 1 && 's'}
          </InfoCardItem>}
        </>}
      </InfoCard>
    </>
  );
}

function GroupTypeIcon({ group }) {
  const groupType = group?.group_type;
  if(!groupType || groupType==='group') return null;
  return (
    <div className="img-thumbnail d-flex justify-content-center align-items-center">
      {
        groupType === 'organisation' ? <IoIosFactory size="70%" /> :
          groupType === 'device' ? <IoIosMotor size="70%" /> :
            groupType === 'user' ? <Icon icon="people" size={120} /> : null
      }
    </div>
  );
}

function GroupPageLayout({
  children
}) {
  const dispatch = useDispatch();
  const { group } = useGroup();

  useEffect(() => {
    group.id && dispatch(fetchGroup({id: group.id}));
  }, [group.id]);

  return (  // @TODO: Refactor to use ProfilePageLayout.
    <>
      <div className="profile-page__header">
        <div className="fluid-container">
          <Row>
            <Col lg={4} xl={3}>
              <div className="position-relative">
                <GroupTypeIcon group={group} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Navbar bg="light" className="profile-page__navbar-row shadow-sm p-0 pt-2 pb-1">
        <div className="p-0 fluid-container">
          <Col lg={{ span: 8, offset: 4 }} xl={{ span: 9, offset: 3 }}>
            <div className="flex-grow-1 justify-content-end profile-page__navbar">
              <GroupNavbar group={group} />
            </div>
          </Col>
        </div>
      </Navbar>
      <div className="my-1"></div>
      <div className="fluid-container my-4">
        {children}
      </div>
    </>
  );
}

export default GroupPageLayout;
