import React from 'react';
import { connect } from 'react-redux';
import { IoIosPulse } from 'react-icons/io';

import { numericSortFunc } from '../../../components/table/utils';
import { getUserDisplayPreference } from '../../../components/values/Rms';
import { InlineHeaderCell, RowCell } from '../../../components/table/formatters';

const ConnectedHeaderCell = connect(getUserDisplayPreference)(InlineHeaderCell);
const ConnectedRowCell = connect(getUserDisplayPreference)(RowCell);

export const column = {
  // Consolidated version of RMS, based on FitMachine type. V2.x uses mms, V3.x uses mm2.
  dataField: 'vibration_rms',
  text: 'Vibration (RMS)',
  icon: <IoIosPulse size="1.4em" />,
  headerFormatter: (columnProps, colIndex, components) => (
    <ConnectedHeaderCell {...columnProps} {...components} />
  ),
  formatter: value => <ConnectedRowCell value={value} />,
  filterValue: value => <ConnectedRowCell value={value} />,
  sort: true,
  sortFunc: numericSortFunc,
  __canChangeVisibility: false,
  __csvTextFormatter: (value, row, options) => {
    const {unitSystemValue} = options;
    if (unitSystemValue === 'US') {
      return value / 25.4;
    }
    return value;
  }
};
