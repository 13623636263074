
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import i18n from 'i18next';

import {
  IoIosCreate,
  IoIosTrash,
  IoIosCopy,
} from 'react-icons/io';

import copy from 'copy-to-clipboard';

import { deleteOrganisationToken } from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import { addToast } from '../../../components/Toaster';

import EditOrganisationTokenFormModal from './EditOrganisationTokenFormModal';
import { useTranslation } from 'react-i18next';

const copyItemToClipboard = (item) => {
  const t = i18n.t;
  const result = copy(item, {message: t('components.common.copy-button.message')});
  if (result) {
    addToast({variant: 'success', header: t('components.common.copy-button.header')});
  }
};

function OrganisationTokenActions({
  deleteOrganisationToken,
  organisationId,
  token,
  description,
}) {
  const { t } = useTranslation();

  return (
    <ButtonGroup>
      <EditOrganisationTokenFormModal
        organisationId={organisationId}
        token={token}
        description={description}
      >
        <Button
          size="md"
          variant="outline-secondary"
        >
          <IoIosCreate size="1.2em" /> {t('Edit')}
        </Button>
      </EditOrganisationTokenFormModal>
      <Button
        size="md"
        variant="outline-secondary"
        onClick={() => copyItemToClipboard(token)}
      >
        <IoIosCopy size="1.2em" /> {t('components.common.copy-button.text')}
      </Button>
      <ConfirmModal
        header={t('screens.organisations.organisation-tokens.delete-token')}
        body={t('common.cannot-be-undone')}
        confirmText={t('common.delete')}
      >
        <Button
          size="md"
          variant="danger"
          onClick={useCallback( () => {
            deleteOrganisationToken({ id: organisationId }, token);
          }, [organisationId, token])}
        >
          <IoIosTrash size="1.2em" /> {t('common.delete')}
        </Button>
      </ConfirmModal>
    </ButtonGroup>
  );
}

const mapDispatchToProps = {
  deleteOrganisationToken,
};

export default connect(null, mapDispatchToProps)(OrganisationTokenActions);
