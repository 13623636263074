import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChildAlarms } from '../actions';
import { getChildAlarms, getChildAlarmState } from '../selectors';

export default function useChildAlarms(alarmId, { fetch = false } = {}) {
  const dispatch = useDispatch();
  const childAlarms = useSelector(state => getChildAlarms(state, alarmId)) || [];
  const { loading, error, lastFetch } = useSelector(state => getChildAlarmState(state, alarmId)) || {};

  useEffect(() => {
    if(fetch && alarmId) {
      dispatch(fetchChildAlarms(alarmId));
    }
  }, [alarmId, fetch]);

  return { childAlarms, loading, error, lastFetch };
}