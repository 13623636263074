
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { getCurrentOrganisationHasProductCode } from '../modules/organisation/selectors';

import { isAuthorised } from '../modules/user/selectors';
import NotFound from './NotFound';

// define Route visible to only specific roles, else render NotFound
function PrivateRoute({
  component: Component,
  isAuthorised,
  redirectTo,
  ...rest
}) {
  return (
    <Route {...rest} render={props => {
      // if the user passes the minimum user type and organisation type
      // then render the component, else redirect or render not found
      return isAuthorised
        ? <Component {...props} />
        : redirectTo
          ? <Redirect to={redirectTo} />
          : <Route component={NotFound} />;
    }}/>
  );
}

// isAuthorised is determined by the user, organisation, and ownProps
export default connect((state, { minUserType, minOrgType, productCode }) => {
  return {
    // restrict by user type
    isAuthorised: isAuthorised(state, { minUserType, minOrgType }) && (
      // and restrict by product code
      !productCode || getCurrentOrganisationHasProductCode(state, productCode)
    ),
  };
})(PrivateRoute);
