import React, { Fragment, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { IoIosResize, IoIosCog, IoIosWarning } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import useBaseChart, {
  defaultOptions,
  getSymbolOfIcon,
  convertToTimestamp,
} from './BaseChart.js';
import { popperConfigUpdateOnChange } from './BaseChartToolbar.js';
import RelearnModalForm from '../../modules/equipment/components/RelearnModalForm.js';
import {
  getDeviceAlarms,
} from '../../modules/equipment/selectors';

import {
  fetchDeviceInfo,
  fetchDeviceAlarms,
} from '../../modules/equipment/actions.js';

import aiIcon from '../../images/aiImage.ico';

const IoIosResizePath = getSymbolOfIcon(IoIosResize);
const IoIosWarningPath = getSymbolOfIcon(IoIosWarning);

const colours = {
  black: '#434348', // black
  blue: '#7cb5ec', // blue
  grey: '#efefef', // grey
  darkGrey: '#cccccc', // slightly darker grey,
  green: '#2bae23', // green
  yellow: '#dfa907', // Bootstrap alert border yellow with boosted lightness
  yellowLight: '#f6c73c',
  red: 'red', // red
  redLight: '#ff6666',
};

const thresholdSeriesStyle = {
  // make style as unobtrusive as possible
  lineStyle: { width: 0 },
  symbol: 'none',
  showSymbol: false,
  hoverAnimation: false,
  silent: true,
  animation: false,
  // mark step data as the changes
  step: 'end', // end for the line turns up/down at the 'end' of this point
  // stack these backgrounds
  stack: true,
  // place behind grid
  z: -1,
};

function formatCondition(value) {
  const t = i18n.t;
  switch (true) {
    case value >= 2/3: return t('components.ai-chart.serious');
    case value >= 1/3: return t('components.ai-chart.warning');
    case value > 0: return t('components.ai-chart.healthy');
    default: return t('components.ai-chart.not-running');
  }
}

function getInitialOptions(options=defaultOptions) {
  const t = i18n.t;
  return {
    ...options,
    legend: {
      ...options.legend,
      data: [
        { name: t('components.ai-chart.overall-condition'), textStyle: { color: colours.green } },
        { name: t('components.ai-chart.vibration-noise'), textStyle: { color: colours.blue } },
        { name: t('components.ai-chart.temperature'), textStyle: { color: colours.black } },
      ],
    },
    tooltip: {
      ...options.tooltip,
      formatter: function(series) {
        const overall = series.find(({ seriesId }) => seriesId === 'overall');
        const vibration = series.find(({ seriesId }) => seriesId === 'vibration');
        const temperature = series.find(({ seriesId }) => seriesId === 'temperature');
        return (
          `${moment(series[0].value[0]).format('LLLL')}${
            '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
          }${
            vibration
              ? `${t('components.ai-chart.vibration-noise')}: ${formatCondition(vibration.value[1])}<br/>`
              : ''
          }${
            temperature
              ? `${t('components.ai-chart.temperature')}: ${formatCondition(temperature.value[1])}<br/>`
              : ''
          }${
            overall
              ? `${t('components.ai-chart.overall-condition')}: ${formatCondition(overall.value[1])}<br/>`
              : ''
          }`
        );
      },
    },
    yAxis: [
      {
        type: 'value',
        name: t('components.ai-chart.overall-condition'),
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 38,
        nameTextStyle: {
          color: colours.green,
        },
        min: 0,
        max: 1,
        minInterval: 1/3,
        axisLabel: {
          formatter: value => value.toFixed(2),
        },
      },
      {
        type: 'value',
        name: t('components.ai-chart.vibration-noise'),
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 35,
        nameTextStyle: {
          color: colours.blue,
        },
        min: 0,
        max: 1,
        minInterval: 0.25,
      },
      {
        type: 'value',
        name: t('components.ai-chart.temperature'),
        nameLocation: 'center',
        nameRotate: -90,
        nameGap: 55,
        nameTextStyle: {
          color: colours.black,
        },
        min: 0,
        max: 1,
        minInterval: 0.25,
      },
    ],
    series: [
      {
        id: 'overall',
        name: t('components.ai-chart.overall-condition'),
        type: 'line',
        itemStyle: { color: colours.green },
        lineStyle: { color: colours.green, width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        z: 3, // bump the height of this series
      },
      {
        id: 'temperature',
        name: t('components.ai-chart.temperature'),
        type: 'line',
        itemStyle: { color: colours.black },
        lineStyle: { color: colours.black, width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        yAxisIndex: 2,
      },
      {
        id: 'vibration',
        name: t('components.ai-chart.vibration-noise'),
        type: 'line',
        itemStyle: { color: colours.blue },
        lineStyle: { color: colours.blue, width: 1.5 },
        smooth: 0.25,
        smoothMonotone: 'x',
        showSymbol: false,
        yAxisIndex: 1,
      },
      {
        id: 'overall-healthy',
        name: t('components.ai-chart.overall-condition-healthy'),
        type: 'line',
        ...thresholdSeriesStyle,
        areaStyle: {
          color: 'green',
          opacity: 0.1,
        },
      },
      {
        id: 'overall-warning',
        name: t('components.ai-chart.overall-condition-warning'),
        type: 'line',
        ...thresholdSeriesStyle,
        areaStyle: {
          color: 'yellow',
          opacity: 0.1,
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.yellow,
            type: 'solid',
            width: 1,
          },
        },
        // add editable hint
        markPoint: {
          symbol: IoIosResizePath,
          // the resize icon is tilted diagonally, we rotate it back here
          symbolRotate: 45,
          symbolSize: 16,
          itemStyle: {
            color: colours.yellow,
          },
        },
      },
      {
        id: 'yellow-alarms',
        name: t('components.ai-chart.yellow-alarms'),
        type: 'line',
        lineStyle: { width: 0 },
        showSymbol: true,
        symbol: IoIosWarningPath,
        symbolSize: 15,
        symbolOffset: [0, -10],
        cursor: 'default',
        color: colours.yellow,
        label: {
          show: true,
          position: 'top',
          offset: [0, 20],
          backgroundColor: colours.yellow,
          color: '#fff',
          fontWeight: 'bold',
          fontSize: 11,
          formatter: '!',
        },
        emphasis: {
          itemStyle: {
            color: colours.yellowLight,
          },
          label: {
            backgroundColor: colours.yellowLight,
          },
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.yellow,
            type: 'solid',
            width: 1,
          },
        },
      },
      {
        id: 'overall-serious',
        name: t('components.ai-chart.overall-condition-serious'),
        type: 'line',
        ...thresholdSeriesStyle,
        areaStyle: {
          color: 'red',
          opacity: 0.1,
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: 'red',
            type: 'solid',
            width: 1,
          },
        },
        // add editable hint
        markPoint: {
          symbol: IoIosResizePath,
          // the resize icon is tilted diagonally, we rotate it back here
          symbolRotate: 45,
          symbolSize: 16,
          itemStyle: {
            color: colours.red,
          },
        },
      },
      {
        id: 'red-alarms',
        name: t('components.ai-chart.red-alarms'),
        type: 'line',
        lineStyle: { width: 0 },
        showSymbol: true,
        symbol: IoIosWarningPath,
        symbolSize: 15,
        symbolOffset: [0, -10],
        cursor: 'default',
        color: colours.red,
        label: {
          show: true,
          position: 'top',
          offset: [0, 20],
          backgroundColor: colours.red,
          color: '#fff',
          fontWeight: 'bold',
          fontSize: 11,
          formatter: '!',
        },
        emphasis: {
          itemStyle: {
            color: colours.redLight,
          },
          label: {
            backgroundColor: colours.redLight,
          },
        },
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: {
            color: colours.red,
            type: 'solid',
            width: 1,
          },
        },
      },
    ],
    graphic: {
      elements: [],
    },
  };
}

function AIChart({
  deviceId,
  alarms,
  samples = [],
  fetchDeviceInfo,
  fetchDeviceAlarms,
  ...props
}) {

  const { t } = useTranslation();

  const [BaseChart, {
    getChart,
    useChartUpdateEffect,
    useChartUpdateOnChartEvent,
    useChartUpdateOnCustomEvent,
  }] = useBaseChart(getInitialOptions);

  // update samples
  const updateSamples = useCallback(() => {
    return {
      series: [
        {
          id: 'overall',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.condition_overall || 0,
          ]),
        },
        {
          id: 'temperature',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.condition_temperature || 0,
          ]),
        },
        {
          id: 'vibration',
          data: samples.map(sample => [
            convertToTimestamp(sample.sample_time),
            sample.condition_vibration || 0,
          ]),
        },
      ],
    };
  }, [samples]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateSamples);

  const historicThresholds = [];  // No need to show historic thresholds as AI threshold is disabled.

  function getXMinPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return grid.left;
      }
    }
  }

  function getXMaxPoint(chart=getChart()) {
    if (chart) {
      const options = chart.getOption();
      const grid = options && options.grid && options.grid[0];
      if (grid) {
        return chart.getWidth() - grid.right;
      }
    }
  }

  function getDataFromX(x, chart=getChart()) {
    if (chart) {
      // position y as the current deviceRunning Cutoff
      return chart.convertFromPixel({ seriesId: 'overall' }, [x, 0])[0];
    }
  }

  // update tooltip formatting
  const updateTooltip = useCallback(() => {

    return {
      tooltip: {
        formatter: function(series) {
          const overall = series.find(({ seriesId }) => seriesId === 'overall');
          const vibration = series.find(({ seriesId }) => seriesId === 'vibration');
          const temperature = series.find(({ seriesId }) => seriesId === 'temperature');
          const thresholdYellow = series.find(({ seriesId }) => seriesId === 'overall-warning');
          const thresholdRed = series.find(({ seriesId }) => seriesId === 'overall-serious');
          const alarmYellow = series.find(({ seriesId }) => seriesId === 'yellow-alarms');
          const alarmRed = series.find(({ seriesId }) => seriesId === 'red-alarms');

          const thresholdYellowChange = thresholdYellow && historicThresholds.find(event => {
            return (
              (event.data && (event.data.new_yellow_threshold || event.data.old_yellow_threshold)) &&
              thresholdYellow.value[0] === new Date(event.ref_timestamp).valueOf()
            );
          });
          const thresholdRedChange = thresholdRed && historicThresholds.find(event => {
            return (
              (event.data && (event.data.new_red_threshold || event.data.old_red_threshold)) &&
              thresholdRed.value[0] === new Date(event.ref_timestamp).valueOf()
            );
          });

          const alarmYellowEvent = alarmYellow && alarms.find(alarm => {
            return (
              alarm.alarm_type === 'yellow' &&
              alarmYellow.value[0] === new Date(alarm.alarm_trigger_timestamp).valueOf()
            );
          });
          const alarmRedEvent = alarmRed && alarms.find(alarm => {
            return (
              alarm.alarm_type === 'red' &&
              alarmRed.value[0] === new Date(alarm.alarm_trigger_timestamp).valueOf()
            );
          });

          const formatThreshold = value => {
            return value === null ? 'default' : `${Math.round(value*100)/100}`;
          };

          return (
            `${moment(series[0].value[0]).format('LLLL')}${
              '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
            }${
              vibration
                ? `${t('components.ai-chart.vibration-noise')}: ${formatCondition(vibration.value[1])}<br/>`
                : ''
            }${
              temperature
                ? `${t('components.ai-chart.temperature')}: ${formatCondition(temperature.value[1])}<br/>`
                : ''
            }${
              overall
                ? `${t('components.ai-chart.overall-condition')}: ${formatCondition(overall.value[1])}<br/>`
                : ''
            }${
              thresholdYellowChange
                ? `${t('components.ai-chart.yellow-advisory-threshold-change')}: ${
                  `from ${formatThreshold(thresholdYellowChange.data.old_yellow_threshold)} ` +
                  `to ${formatThreshold(thresholdYellowChange.data.new_yellow_threshold)}<br/>` +
                  (!thresholdRedChange ? `by: ${thresholdYellowChange.user_name}<br/>` : '')
                }`
                : ''
            }${
              thresholdRedChange
                ? `${t('components.ai-chart.red-alarm-threshold-change')}: ${
                  `from ${formatThreshold(thresholdRedChange.data.old_red_threshold)} ` +
                  `to ${formatThreshold(thresholdRedChange.data.new_red_threshold)}<br/>` +
                  `by: ${thresholdRedChange.user_name}<br/>`
                }`
                : ''
            }${
              alarmYellowEvent
                ? `${t('components.ai-chart.yellow-advisory-event')}${
                  alarmYellowEvent.red_threshold_changed
                    ? ` (${t('components.ai-chart.custom-alarm-threshold')}: ${formatThreshold(alarmYellowEvent.red_threshold)}`
                    : ''
                }: ${
                  [
                    (alarmYellowEvent.condition_vibration > 1/3) && t('components.ai-chart.vibration'),
                    (alarmYellowEvent.condition_temperature > 1/3) && t('components.ai-chart.temperature-lower'),
                    (alarmYellowEvent.condition_overall > 1/3) && t('components.ai-chart.overall'),
                  ].filter(Boolean).join(', ')
                }<br/>`
                : ''
            }${
              alarmRedEvent
                ? `${t('components.ai-chart.red-alarm-event')}${
                  alarmRedEvent.red_threshold_changed
                    ? ` (${t('components.ai-chart.custom-alarm-threshold')}: ${formatThreshold(alarmRedEvent.red_threshold)}`
                    : ''
                }: ${
                  [
                    (alarmRedEvent.condition_vibration > 2/3) && t('components.ai-chart.vibration'),
                    (alarmRedEvent.condition_temperature > 2/3) && t('components.ai-chart.temperature-lower'),
                    (alarmRedEvent.condition_overall > 2/3) && t('components.ai-chart.overall'),
                  ].filter(Boolean).join(', ')
                }<br/>`
                : ''
            }`
          );
        },
      },
    };
  }, [historicThresholds, alarms]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateTooltip);

  // update background series
  const updateThresholdBackground = useCallback(() => {

    const xMin = getDataFromX(getXMinPoint());
    const xMax = getDataFromX(getXMaxPoint());

    const { past, current } = [...historicThresholds].sort((a, b) => {
      // ensure threshold changes are chronologically sorted
      return new Date(a.ref_timestamp) - new Date(b.ref_timestamp);
    }).reduce((acc, event) => {
      const timestamp = new Date(event.ref_timestamp).valueOf();
      if (timestamp < xMin) {
        acc.past.push(event);
      }
      else if (timestamp > xMax) {
        acc.future.push(event);
      }
      else {
        acc.current.push(event);
      }
      return acc;
    }, { past: [], current: [], future: [] });

    // add default event to insert threshold defaults at earliest point in time
    const defaultEvent = {
      data: {
        new_yellow_threshold: null,
        new_red_threshold: null,
      },
    };
    const reversePast = [defaultEvent, ...past].reverse();
    const reverseCurrent = [defaultEvent, ...past, ...current].reverse();

    const thresholdSeries = [
      // add left-most point, pinned at the left of the graph
      [
        xMin,
        // get last defined value of property
        reversePast.find(event => {
          return event && event.data && event.data.new_yellow_threshold !== undefined;
        }).data.new_yellow_threshold,
        // get last defined value of property
        reversePast.find(event => {
          return event && event.data && event.data.new_red_threshold !== undefined;
        }).data.new_red_threshold,
      ],
      // add data within current date range
      ...current.map(event => {
        return [
          new Date(event.ref_timestamp).valueOf(),
          event.data.new_yellow_threshold,
          event.data.new_red_threshold
        ];
      }),
      // add right-most point, pinned at the left of the graph
      [
        xMax,
        // get last defined value of property
        reverseCurrent.find(event => {
          return event && event.data && event.data.new_yellow_threshold !== undefined;
        }).data.new_yellow_threshold,
        // get last defined value of property
        reverseCurrent.find(event => {
          return event && event.data && event.data.new_red_threshold !== undefined;
        }).data.new_red_threshold,
      ],
    ].reduce((series, [x, yellow, red], index) => {
      // replace undefined values with last known values
      // replace null values with default values
      series[index] = [
        x,
        yellow !== null && !isNaN(yellow)
          // value is fine
          ? yellow
          : yellow !== null && index
            // value should be previous value
            ? series[index - 1][1]
            // value should be default
            : 1/3,
        red !== null && !isNaN(red)
          // value is fine
          ? red
          : red !== null && index
            // value should be previous value
            ? series[index - 1][2]
            // value should be default
            : 2/3,
      ];
      return series;
    }, []);

    return {
      series: [
        {
          id: 'overall-healthy',
          data: thresholdSeries.map(([x, yellow]) => {
            return [x, yellow];
          }),
        },
        {
          id: 'overall-warning',
          data: thresholdSeries.map(([x, yellow, red]) => {
            return [x, red - yellow];
          }),
        },
        {
          id: 'overall-serious',
          data: thresholdSeries.map(([x, yellow, red]) => {
            return [x, 1 - (red - yellow)];
          }),
        },
      ],
    };
  }, [historicThresholds]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateThresholdBackground);
  useChartUpdateOnChartEvent('dataZoom', updateThresholdBackground);
  useChartUpdateOnCustomEvent('dateRange', updateThresholdBackground);

  // fetch alarms
  useEffect(() => {
    fetchDeviceAlarms({ id: deviceId });
  }, [deviceId]);

  // update alarm series
  const updateAlarms = useCallback(() => {

    // translate alarms into a [x, y1, y2] data series
    const sortedAlarmSeries =  (alarms || [])
      // only show alarms with defined trigger timestamps:
      // new Date(undefined) defaults to "now", which we don't want here
      .filter(alarm => alarm.alarm_trigger_timestamp)
      // ensure all alarms have an alarm type to display
      .filter(alarm => alarm.alarm_type)
      .map(alarm => {
        return {
          timestamp: new Date(alarm.alarm_trigger_timestamp).valueOf(),
          alarm_type: alarm.alarm_type,
        };
      })
      // sort chronologically
      .sort((a, b) => a.timestamp - b.timestamp);

    const yellowAlarms = sortedAlarmSeries.filter(alarm => alarm.alarm_type === 'yellow');
    const redAlarms = sortedAlarmSeries.filter(alarm => alarm.alarm_type === 'red');

    return {
      series: [
        {
          id: 'yellow-alarms',
          data: yellowAlarms.map(({ timestamp }) => [timestamp, 1]),
          markLine: {
            data: yellowAlarms
              .map(({ timestamp }) => {
                return [
                  { coord: [timestamp, 0] },
                  { coord: [timestamp, 1] },
                ];
              }),
          },
        },
        {
          id: 'red-alarms',
          data: redAlarms.map(({ timestamp }) => [timestamp, 1]),
          markLine: {
            data: redAlarms
              .map(({ timestamp }) => {
                return [
                  { coord: [timestamp, 0] },
                  { coord: [timestamp, 1] },
                ];
              }),
          },
        },
      ],
    };
  }, [alarms]);

  // handle updates when dependencies change
  useChartUpdateEffect(updateAlarms);

  // allow mouseover or clicking alarm symbols to focus the
  // chart tooltip on the alarm
  const alarmTooltipFocus = useCallback((option, event) => {
    if (event.componentType === 'series') {
      const chart = getChart();
      if (chart) {
        if (event.seriesId === 'yellow-alarms') {
          const [x, y] = chart.convertToPixel({ seriesId: 'yellow-alarms' }, event.value);
          chart.dispatchAction({ type: 'showTip', x, y });
        }
        if (event.seriesId === 'red-alarms') {
          const [x, y] = chart.convertToPixel({ seriesId: 'red-alarms' }, event.value);
          chart.dispatchAction({ type: 'showTip', x, y });
        }
      }
    }
  }, []);

  useChartUpdateOnChartEvent('mousemove', alarmTooltipFocus);
  useChartUpdateOnChartEvent('click', alarmTooltipFocus);

  return (
    <BaseChart
      header={<Fragment>
        <img src={aiIcon} height="24" width="24" alt=""/> {t('components.ai-chart.header')}
      </Fragment>}
      namespace="ai-output"
      deviceId={deviceId}
      samples={samples}
      {...props}
      // pass memoised buttons
      toolbarButtons={useMemo(() => [
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-dark"
            size="lg"
          >
            <IoIosCog size="1.3em" title="Edit" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            popperConfig={popperConfigUpdateOnChange}
          >
            <RelearnModalForm deviceId={deviceId}>
              <Dropdown.Item eventKey="2">
                <span>{t('components.ai-chart.restart-learning')}</span>
              </Dropdown.Item>
            </RelearnModalForm>
          </Dropdown.Menu>
        </Dropdown>
      ], [])}
    />
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    deviceId,
    alarms: getDeviceAlarms(state, deviceId),
  };
};
const mapDispatchToProps = {
  fetchDeviceInfo,
  fetchDeviceAlarms,
};

export default connect(mapStateToProps, mapDispatchToProps)(AIChart);
