import { createSelector } from "reselect";
import { NotificationStateProps, NotificationState, NotificationOption } from "./types";
import { AppStoreState } from "../types";

export const getNotificationState: (state: AppStoreState) => NotificationStateProps = createSelector(
  (state: AppStoreState) => state,
  state => {
    const { notification: { loading = false, lastFetch = null, error = null } = {}} = state;
    return {
      loading,
      lastFetch,
      error,
    };
  },
);

export const getNotifications: (state: AppStoreState) => NotificationState[] = createSelector(
  (state: AppStoreState) => state,
  state => state.notification.notifications,
);

// Read unreadCount from reducer directly. This data is from API
export const getUnreadNotificationsCount: (state: AppStoreState) => number = createSelector(
  (state: AppStoreState) => state,
  state => state.notification.unreadCount,
);

// Calculate the count of unread notifications from notifications list.
export const getUnreadNotificationsCountLocal: (state: AppStoreState) => number = createSelector(
  getNotifications,
  notifications => notifications.filter(n => n.unread).length,
);

export const getNotificationOptions: (state: AppStoreState) => NotificationOption[] = createSelector(
  (state: AppStoreState) => state,
  state => state.notification?.options || [],
);

export const getReadNotificationsCountLocal: (state: AppStoreState) => number = createSelector(
  getNotifications,
  notifications => notifications.filter(n => !n.unread).length,
);