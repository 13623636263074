
import React, { Fragment } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { IoIosNotifications, IoIosNotificationsOff } from 'react-icons/io';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import withNavigationDeviceProps from './withNavigationDeviceProps';

// Components
import Slider from '../../../components/Bar';
import VolumeImage from '../../alarm/VolumeImage';
import ToneImage from '../../alarm/ToneImage';
import TemperatureImage from '../../alarm/TemperatureImage';
import RunningStatus from '../components/RunningStatus';

import Rms, { Units as RmsUnits } from '../../../components/values/Rms';
import Temperature from '../../../components/values/Temperature';
import Power from '../../../components/values/Power';

import { InfoCard, InfoCardItem, InfoCardHeader } from '../../../components/InfoCard';
import BasicModal from '../../../components/BasicModal';
import CopyDeviceSerialButton from './CopyDeviceSerialButton';

// Other
import {
  calibrationFormatter,
  latLngFormatter,
  wifiScoreFormatter,
  batteryScoreFormatter,
} from '../../../components/table/formatters';

import { getDevice, getDevicePlaceholderImage, getDeviceHasProductCode, getDeviceRoleName, getDeviceConditionCurvesKeys, getDeviceConditionCurves } from '../selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
  getOrganisationUserColumns,
} from '../../organisation/selectors';
import StatusSummaryIndicator from './StatusSummaryIndicator';
import { ISOIndicator } from '../../../components/charts/ISOTableModal';
import useDeviceLinks from '../hooks/useDeviceLinks';
import DeviceLinks from './DeviceLinks';
import { isFitPower, isFitMachine, setVibrationRms } from '../utils';
import { percentageNumberFormatter } from '../../../components/table/formatters';
import { capitaliseFirstChar } from '../../../lib/utils';
import { ExternalLink } from '../../../lib/utils';

export const fmIconStyle = {
  width: '1.3em',
  verticalAlign: '-20%',
};

function FmSamplingConfig({ device, heading, type }) {
  const { t } = useTranslation();
  const hour = device.fmconfig[type]['hour'];
  const minute = device.fmconfig[type]['minute'];
  return (
    <InfoCardItem
      heading={t(heading)}
    >
      <div className="d-flex">
        {hour ? t('hourWithCount', {count: hour}) : ""}
        {" "}
        {minute ? t('minuteWithCount', {count: minute}) : ""}
      </div>
    </InfoCardItem>
  );
}

// add descriptions to the InfoCard header
function DeviceInsightsInfoCardHeader({ infoCardChildren, ...props }) {

  const { t } = useTranslation();
  // filter children props to those with info
  const childrenProps = React.Children
    .map(infoCardChildren, el => React.isValidElement(el) && el.props)
    .filter(props => props && props.info);

  // don't display if no information is found
  if (!childrenProps.length) {
    return null;
  }

  const knowledgeLink = 'https://learn.movus.com.au/knowledge/the-detailed-view';
  const buttons = (
    <BasicModal
      header={props.children}
      body={(
        <div>
          <p>
            {t('components.equipment-sidebar.insights.insights-card')}
          </p>
          {childrenProps.map(({ heading, info }) => (
            <div key={heading}>
              <h5>{heading}</h5>
              <p>{info}</p>
            </div>
          ))}
          <div>
            <Trans
              i18nKey="components.equipment-sidebar.insights.read-more"
              components={{
                knowledgeLink: <ExternalLink to={knowledgeLink} title="Knowledge base link" />,
              }}
            />
          </div>
        </div>
      )}
      footer={({ close }) => (
        <Button onClick={close} variant="secondary">{t('OK')}</Button>
      )}
    >
      <Button size="sm" variant="outline-secondary">{t('Info')}</Button>
    </BasicModal>
  );

  return (
    <InfoCardHeader buttons={buttons} {...props} />
  );
}


function EquipmentSidebarInsightsFitPower({ device = {} }) {

  const { t } = useTranslation();
  return (
    <InfoCard
      collapsible
      heading={t('components.equipment-sidebar.insights.header')}
      HeaderComponent={DeviceInsightsInfoCardHeader}
    >
      {device.archived && (
        <InfoCardItem className="list-group-item-danger text-center">
          {t('archived')}
        </InfoCardItem>
      )}
      <InfoCardItem heading={t('common.status')}>
        <StatusSummaryIndicator status={device.status_summary} />
      </InfoCardItem>
      <InfoCardItem
        heading={t('components.equipment-sidebar.insights.running')}
        info={t('components.equipment-sidebar.insights.running-info')}
      >
        <RunningStatus value={device.running} />
      </InfoCardItem>
      {/* <InfoCardItem
        heading="Current"
        info="" // @TODO: Add info for current
      >
        {device.current}
      </InfoCardItem> */}
    </InfoCard>
  );
}

function EquipmentSidebarInsightsFitMachine({ device = {} }) {

  device = setVibrationRms(device);
  const { t } = useTranslation();
  const conditionCurves = useSelector(getDeviceConditionCurves);
  const conditionCurvesKeys = useSelector(getDeviceConditionCurvesKeys);
  // todo: remove rms + mm2 -> DASH-1018
  // const rmsAvailable = useSelector(getOrganisationRmsAvailablePreference);
  // const mm2Available = useSelector(getOrganisationMm2AvailablePreference);
  const hasCondCurve = useSelector(state => getDeviceHasProductCode(state, device.id, 'condcurve'));

  return (
    <InfoCard
      collapsible
      heading={t('components.equipment-sidebar.insights.header')}
      HeaderComponent={DeviceInsightsInfoCardHeader}
    >
      {device.archived && (
        <InfoCardItem className="list-group-item-danger text-center">
          {t('archived')}
        </InfoCardItem>
      )}
      <InfoCardItem heading={t('common.status')}>
        <StatusSummaryIndicator status={device.status_summary} />
      </InfoCardItem>
      {hasCondCurve && <InfoCardItem
        heading={t('components.equipment-sidebar.insights.condition-correction')}
      >{device.tags?.condition_curve || conditionCurves[conditionCurvesKeys[0]]?.name}</InfoCardItem>}
      {(device.is_calibrating || device.calibration < 100) &&
        // show calibration if either calibration is currently happening
        // or the calibration was progressing as a number and is less than 100%
        <InfoCardItem heading={t('common.learning-progress')}>
          {calibrationFormatter(device.calibration, device)}
        </InfoCardItem>
      }
      <InfoCardItem
        heading={t('components.equipment-sidebar.insights.running')}
        info={t('components.equipment-sidebar.insights.running-info')}
      >
        <RunningStatus value={device.running} />
      </InfoCardItem>
      <InfoCardItem
        heading={t('condition')}
        info={t('components.equipment-sidebar.insights.condition-info')}
      >
        <Slider conditionValue={device.condition_indicated} thumbSizeValue={130} />
      </InfoCardItem>
      <InfoCardItem
        heading={t('common.ai-volume')}
        info={t('components.equipment-sidebar.insights.volume-info')}
      >
        <VolumeImage rms_value={device.condition_vibration} isoClass={device.iso_class}/>
      </InfoCardItem>
      <InfoCardItem
        heading={t('common.ai-tone')}
        info={t('components.equipment-sidebar.insights.tone-info')}
      >
        <ToneImage vibration_condition={device.condition_vibration} />
      </InfoCardItem>
      <InfoCardItem
        heading={t('common.ai-temperature')}
        info={t('components.equipment-sidebar.insights.temperature-ai-info')}
      >
        <TemperatureImage temperature_condition={device.condition_temperature} />
      </InfoCardItem>
      <InfoCardItem
        heading={t('common.vibration-rms')}
        info={(
          <Fragment>
            {t('common.vibration-rms')}
            {" "}
            <Trans
              i18nKey="components.equipment-sidebar.insights.vibration-info-rms"
              components={{
                rmsUnits: <RmsUnits />
              }}
            />
          </Fragment>
        )}
      >
        <Rms value={device.vibration_rms} />
      </InfoCardItem>
      {/* todo: remove rms + mm2 -> DASH-1018 */}
      {/*{!!mm2Available && (!rmsAvailable ? (*/}
      {/*  // user can see only mm2*/}
      {/*  <InfoCardItem*/}
      {/*    heading={t('common.vibration-rms')}*/}
      {/*    info={t('components.equipment-sidebar.insights.vibration-info')}*/}
      {/*  >*/}
      {/*    {twoDigitsNumberFormatter(device.rms2)}*/}
      {/*  </InfoCardItem>*/}
      {/*) : (*/}
      {/*  // user can see both rms and mm2*/}
      {/*  <InfoCardItem*/}
      {/*    heading={(*/}
      {/*      <>*/}
      {/*        {t('common.vibration-rms-filtered')}*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*    info={t('components.equipment-sidebar.insights.vibration-info')}*/}
      {/*  >*/}
      {/*    {twoDigitsNumberFormatter(device.rms2)}*/}
      {/*  </InfoCardItem>*/}
      {/*))}*/}
      <InfoCardItem
        heading={t('Temperature')}
        info={t('components.equipment-sidebar.insights.temperature-info')}
      >
        <Temperature value={device.temperature} />
      </InfoCardItem>
      <InfoCardItem
        heading={t('components.equipment-sidebar.insights.utilisation-4-weeks')}
        info={t('components.equipment-sidebar.insights.utilisation-info')}
      >
        {percentageNumberFormatter(device.utilisation_month)}
      </InfoCardItem>
    </InfoCard>
  );
}

function EquipmentSidebar({ device={}, placeholder, deviceRoleName, organisationUserColumns }) {

  const { t } = useTranslation();
  const { links = [] } = useDeviceLinks(device?.id, { fetch: false });

  if (!device || !device.id) {
    return null;
  }
  const isFMSensor = isFitMachine(device);
  const isFPSensor = isFitPower(device);

  const tags = device?.tags;
  const userColumns = organisationUserColumns.map((col) => col.tag);
  const taggedColumns = Object.keys(tags)
    .filter(key => userColumns.includes(key))
    .reduce((obj, key) => {
      obj[key] = tags[key];
      return obj;
    }, {});

  return (
    <Fragment>
      <h5 className="montserrat" style={{marginTop: "10px", marginBottom: "15px"}}>
        {`${device.site_name} - ${device.sub_area_name} - ${device.equipment_name}`}
      </h5>
      <p>
        {t('common.monitored-by')}: <span className="d-inline-block mr-1">
          <Link to={`/devices/${device.id}`}>
            <img
              src={placeholder}
              style={fmIconStyle}
              alt="FitMachine"
            /> {device.serial}
          </Link>
        </span>
        <CopyDeviceSerialButton device={device} />
      </p>
      {links.length > 0 && <DeviceLinks device={device} />}
      {isFMSensor && <EquipmentSidebarInsightsFitMachine device={device} />}
      {isFPSensor && <EquipmentSidebarInsightsFitPower device={device} />}
      <InfoCard
        collapsible
        heading={t('components.equipment-sidebar.quick-info.header')}
        initialCollapsed
      >
        <InfoCardItem heading={t('components.equipment-sidebar.quick-info.last-data-processed')}>
          {moment(device.fitmachine_last_heard).local().format("ll")}
        </InfoCardItem>
        {isFMSensor && <InfoCardItem
          heading={t('components.equipment-sidebar.quick-info.notifications-status')}
        >
          {device.mute_advisory_for === 0 ? (
            <IoIosNotifications size="1.4em" />
          ) : (
            <IoIosNotificationsOff size="1.4em" />
          )}
        </InfoCardItem>}
        <InfoCardItem heading={t('components.equipment-sidebar.quick-info.network-health')}>
          <div className="d-flex">
            {wifiScoreFormatter(device.wifi_score, device)}
          </div>
        </InfoCardItem>
        {isFMSensor && <InfoCardItem
          heading={t('components.equipment-sidebar.quick-info.battery-health')}
        >
          <div className="d-flex">
            {batteryScoreFormatter(device.battery_score, device)}
          </div>
        </InfoCardItem>}
        {isFMSensor && device?.fmconfig && <>
          <FmSamplingConfig
            device={device}
            heading={'sampling-interval'}
            type={'sample_interval'}
          />
          <FmSamplingConfig
            device={device}
            heading={'reporting-interval'}
            type={'report_interval'}
          />
        </>}
        <InfoCardItem heading={t('components.equipment-sidebar.quick-info.power-rating')}>
          <Power value={device.power_rating} />
        </InfoCardItem>
        <InfoCardItem heading={(
          <Fragment>
            <span>{t('components.equipment-sidebar.quick-info.mac-address')}</span>
            {' '}
            <CopyDeviceSerialButton device={device} />
          </Fragment>
        )}>
          {device.serial}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.quick-info.variable-speed-drive')}>
          {device.variable_speed_drive ? t('yes') : t('no')}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.quick-info.confined-space')}>
          {device.confined_space ? t('yes') : t('no')}
        </InfoCardItem>
        {isFMSensor && <InfoCardItem
          heading={t('components.equipment-sidebar.quick-info.learning-start-date')}
        >
          {moment(device.calibration_start).local().format("ll")}
        </InfoCardItem>}
        <InfoCardItem heading={t('components.equipment-sidebar.quick-info.notes')}>
          {device.note}
        </InfoCardItem>
      </InfoCard>
      <InfoCard
        collapsible
        heading={t('components.equipment-sidebar.equipment-details.header')}
        initialCollapsed
      >
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.equipment-name')}>
          {device.equipment_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.equipment-number')}>
          {device.equipment_number}
        </InfoCardItem>
        {taggedColumns && Object.entries(taggedColumns).map(([k, v]) => {
          return <InfoCardItem key={k} heading={capitaliseFirstChar(k)}>
            {v}
          </InfoCardItem>;
        })}
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.equipment-brand')}>
          {device.equipment_brand}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.equipment-model')}>
          {device.equipment_model}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.equipment-type')}>
          {device.equipment_type}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.equipment-role')}>
          {deviceRoleName || device.role}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.capacity')}>
          {device.equipment_capacity}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.power-rating')}>
          <Power value={device.power_rating} />
        </InfoCardItem>
        {isFMSensor && <InfoCardItem
          heading={t('components.equipment-sidebar.equipment-details.iso-class')}
        >
          <div className="d-flex align-items-center">
            {device.iso_class}&nbsp;&nbsp;
            <ISOIndicator device={device} />
          </div>
        </InfoCardItem>}
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.max-rpm')}>
          {device.max_rpm}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.min-rpm')}>
          {device.min_rpm}
        </InfoCardItem>
        <InfoCardItem
          heading={t('components.equipment-sidebar.equipment-details.variable-speed-drive')}
        >
          {device.variable_speed_drive ? t('yes') : t('no')}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.confined-space')}>
          {device.confined_space ? t('yes') : t('no')}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.running')}>
          <RunningStatus value={device.running} />
        </InfoCardItem>
        {isFMSensor && <InfoCardItem
          heading={t('components.equipment-sidebar.equipment-details.running-cutoff')}
        >
          <Rms value={device.running_cutoff} />
        </InfoCardItem>}
        <InfoCardItem heading={t('components.equipment-sidebar.equipment-details.notes')}>
          {device.note}
        </InfoCardItem>
      </InfoCard>
      <InfoCard
        collapsible
        heading={t('components.equipment-sidebar.location-details.header')}
        initialCollapsed
      >
        <InfoCardItem heading={t('components.equipment-sidebar.location-details.organisation-name')}>
          {device.organisation_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.location-details.site-name')}>
          {device.site_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.location-details.sub-area-name')}>
          {device.sub_area_name}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.location-details.latitude')}>
          {latLngFormatter(parseFloat(device.latitude))}
        </InfoCardItem>
        <InfoCardItem heading={t('components.equipment-sidebar.location-details.longitude')}>
          {latLngFormatter(parseFloat(device.longitude))}
        </InfoCardItem>
      </InfoCard>
      {links.length === 0 && <DeviceLinks device={device} />}
    </Fragment>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
  placeholder: getDevicePlaceholderImage(state, deviceId),
  rmsAvailable: getOrganisationRmsAvailablePreference(state),
  mm2Available: getOrganisationMm2AvailablePreference(state),
  hasCondCurve: getDeviceHasProductCode(state, deviceId, 'condcurve'),
  deviceRoleName: getDeviceRoleName(state, deviceId),
  conditionCurves: getDeviceConditionCurves(state),
  conditionCurvesKeys: getDeviceConditionCurvesKeys(state),
  organisationUserColumns: getOrganisationUserColumns(state),
});

export default withNavigationDeviceProps(
  connect(mapStateToProps)(EquipmentSidebar)
);
