import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosWarning } from 'react-icons/io';
import LoadingSpinner from '../LoadingSpinner';
import useElementWidth from '../../hooks/useElementWidth';

function DefaultHeaderLink({to, text, onClick}) {
  return (
    <Link to={to || '/'} >
      <Button className="mb-0" onClick={() => { typeof onClick === 'function' && onClick(); }} >{text || 'Header Link'}</Button>
    </Link>
  );
}

const WRAP_BREAKDOWN = 360;

function HomeCard({
  header = 'Home Card Header',
  children,
  headerLink,
  loading,
  error,
  ...props
}) {
  const { to, text, onClick } = headerLink || {};
  const cardRef = useRef(null);
  const cardWidth = useElementWidth(cardRef.current);

  return (
    <Card className="mx-1" ref={cardRef} {...props}>
      <Card.Header className={cardWidth > WRAP_BREAKDOWN ? 'flex-nowrap' : 'flex-wrap' }>
        <div className="d-flex align-items-center justify-content-between flex-grow-1 mr-1">
          <h2 className="mt-1 mr-1">{header}</h2>
          <div>
            {loading && <LoadingSpinner inline size={2} />}
            {error && (
              <OverlayTrigger placement="top" overlay={<Tooltip>{error}</Tooltip>}>
                <IoIosWarning size="2em" className="react-icon--inline"/>
              </OverlayTrigger>)
            }
          </div>
        </div>
        {headerLink && <DefaultHeaderLink to={to} text={text} onClick={onClick} />}
      </Card.Header>
      {children}
    </Card>
  );
}

export default HomeCard;