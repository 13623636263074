
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import './baseModal.scss';

export default function BaseModal({
  renderModal,
  Modal = renderModal,
  onShow,
  onClose,
  children,
}) {

  // throw an error warning if child is not a single element
  useEffect(() => {
    if (children && !React.isValidElement(children)) {
      throw new Error('Child is not a single element');
    }
  }, []);

  const [shown, setShown] = useState(!children);

  const show = useCallback(e => {
    e.preventDefault();
    setShown(true);
    if (onShow) {
      onShow();
    }
  }, [onShow]);

  const close = useCallback((...args) => {
    setShown(false);
    if (onClose) {
      onClose(...args);
    }
  }, [onClose]);

  return (
    <Fragment>
      <Modal shown={shown} close={close} />
      {children ? (
        React.cloneElement(children, { onClick: show })
      ) : null}
    </Fragment>
  );
}
