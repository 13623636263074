
import { CALL_API } from '../../lib/apiMiddleware';
import * as ACTION_TYPES from './types/ActionTypes';

// on app load
export function appLoad() {
  return {
    type: ACTION_TYPES.APP_LOAD,
  };
}

// cache last footer values
let lastFooterHeight;
let lastFooterWidth;
export function footerSize({ width, height }) {
  return dispatch => {
    if ((height !== lastFooterHeight) || (width !== lastFooterWidth)) {
      dispatch({
        type: ACTION_TYPES.FOOTER_SIZE,
        meta: {
          width,
          height,
        },
      });
      lastFooterHeight = height;
      lastFooterWidth = width;
    }
  };
}

let lastHeaderHeight;
export function headerHeight(headerHeight) {
  return dispatch => {
    if(headerHeight !== lastHeaderHeight) {
      lastHeaderHeight = headerHeight;
      dispatch({
        type: ACTION_TYPES.HEADER_HEIGHT,
        headerHeight,
      });
    }
  };
}

export function trackEvent(locationName, actionName, properties) {
  return {
    type: ACTION_TYPES.APP_EVENT,
    locationName,
    actionName,
    // enforce snake case on all tracked event properties
    eventProperties: Object.entries(properties).reduce((acc, [key, value]) => {
      const snakeCaseKey = key
        .replace(/([A-Z]+)([a-z]|$)/g, '_$1$2') // add underscore
        .replace(/_+/g, '_') // remove duplicate underscores
        .toLowerCase(); // convert to lowercase
      acc[snakeCaseKey] = value;
      return acc;
    }, {}),
  };
}

export function trackPage(path) {
  return {
    type: ACTION_TYPES.APP_PAGE,
    path,
  };
}

// check app version

function requestAppVersionStatus(user) {
  return {
    type: ACTION_TYPES.REQUEST_APP_VERSION_CHECK,
    user,
  };
}

function receiveAppVersionStatus(user) {
  return {
    type: ACTION_TYPES.RECEIVE_APP_VERSION_CHECK,
    user,
  };
}

function appVersionStatusFailure(user) {
  return {
    type: ACTION_TYPES.APP_VERSION_CHECK_FAILURE,
    user,
  };
}

export function checkAppVersionStatus() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      userMustBeAuthenticated: false,
      method: 'get',
      endpoint: `/appstatus/dashboard/${process.env.REACT_APP_MACHINECLOUD_DASH_VERSION}`,
      requestAction: requestAppVersionStatus(),
      successAction: receiveAppVersionStatus(),
      errorAction: appVersionStatusFailure(),
      errorToast: false,
    });
  };
}

// The payload is the JSON data as below:
// {location: "activity", "alarm_id":34533, "action": "fftsmudge" or "alarmaudio" or "learningaudio" }
export function requestUserActionCreate() {
  return {
    type: ACTION_TYPES.REQUEST_USER_ACTION_CREATE,
  };
}

export function userActionCreateFailure() {
  return {
    type: ACTION_TYPES.USER_ACTION_CREATE_FAILURE,
  };
}

export function receiveUserActionCreate() {
  return {
    type: ACTION_TYPES.RECEIVE_USER_ACTION_CREATE,
  };
}

export function postUserActionCreate(payload) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: '/ack',
      data: payload,
      requestAction: requestUserActionCreate(),
      successAction: receiveUserActionCreate(),
      errorAction: userActionCreateFailure(),
      errorToast: false, // This action is only for analytics. If failed, no need to give feedback to users.
    });
  };
}

export function activityTrack(payload) {
  return {
    type: ACTION_TYPES.ACTIVITY_TRACK,
    payload,
  };
};

export function setSidebar(payload) {
  return {
    type: ACTION_TYPES.SET_SIDEBAR,
    payload,
  };
}