// Highest asset energy consumption tile for Energy Dash.
import { memo } from 'react';
import ReactEcharts from 'echarts-for-react';
import HomeCard from './HomeCard';
import { getChartLabelColor } from '../lib/utils';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';
// import { useGroupHighestConsumption } from '../../modules/organisation/hooks/useGroupFitPowerData.js';

// function breakLine(line) {
//   if(line.length < 20) return line; // If the length is less than 20, no need to break line.
//   const lineNoSpace = line.replaceAll(' ', ''); // Remove space from string.
//   const half = lineNoSpace.length / 2;
//   const words = line.split(' ');
//   let middleWord = lineNoSpace.substring(half);
//   let length = 0;
//   for(let i = 0; i < words.length; i++) {
//     const word = words[i];
//     length += word.length;
//     if(length >= half) {
//       middleWord = words[i];
//       break;
//     }
//   }
//   const breakedLine = line.replace(middleWord, `\n${middleWord}`);
//   return breakedLine;
// }

function truncate(line) {
  if(line.length <= 18) return line;
  const truncated = line.substr(0, 18) + '...';
  return truncated;
}

function HighestEnergy() {
  // const highestConsumption = useGroupHighestConsumption();
  const { filterAll } = useDeviceStatusFilter();

  const chartOptions = {
    color: getChartLabelColor('blue'),
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      show: true,
      bottom: 0,
    },
    grid: {
      top: 0,
      left: -10,
      right: 0,
      bottom: 20,
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
      axisLabel: {
        rotate: 30,
      },
    },
    yAxis: {
      type: 'category',
      data: [
        'Oversize Pulveriser 2A DE BRG',
        'AHU 3 Supply Fan 9/6',
        'MLSpray Booth Recirculation Fan LH',
        'Main Drive Gearbox',
        'Cabuyao Equipment #15',
        'Refined stock 1 Discharge pump',
        'AHU 3 Supply Fan 9/6',
        'ML Spray Booth Recirculation Fan LH',
        'Main Drive Gearbox',
        'Cabuyao Equipment #15',  // @TODO: Dummy data
      ].map(e => ({
        value: truncate(e),
        textStyle: {
          fontSize: 10,
        }
      }))
    },
    series: [
      {
        name: 'Current consumed',
        type: 'bar',
        barWidth: 'auto',
        data: [193, 194, 200, 234, 255, 310, 1341, 2000, 5000, 6818] // @TODO: Dummy data
      }
    ]
  };

  return (
    <HomeCard
      header="Highest asset energy consumption"
      headerLink={{to: '/equipment/list?type=fitpower', text: 'All equipment', onClick: filterAll}}
    >
      <ReactEcharts
        // style={{height: 400}}
        option={chartOptions}
      />
    </HomeCard>
  );
}

export default memo(HighestEnergy);