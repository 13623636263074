import { useCallback, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Container, Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { IoIosAddCircleOutline } from 'react-icons/io';
import filterFactory from 'react-bootstrap-table2-filter';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import IoIosMotor from '../../../components/IoIosMotor';
import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import EmptyCard from '../../../components/EmptyCard';
import Private from '../../../components/Private';
import AddFitMachineModal from '../components/AddFitmachineModal';

import {
  // todo: remove rms + mm2 -> DASH-1018
  // rms,
  // mm2,
  vibrationRms,
  temperature,
  statusSummary,
  statusCaption,
  calibration,
  siteName,
  subAreaName,
  equipmentNameLinkExtra as equipmentName,
  equipmentNumber,
  conditionIndicated,
  equipmentState,
  utilisationMonth,
  running,
  muteAdvisoryFor,
  note,
  serial,
} from '../columns';
import useOrganisationUserColumns from '../../organisation/hooks/useOrganisationUserColumns';
import { getDeviceStatus } from '../selectors';
import { isAdmin } from '../../user/selectors';
import {
  getOrganisationRmsAvailablePreference,
  getOrganisationMm2AvailablePreference,
  getActiveSubGroupId,
  getCurrentOrganisationHasProductCode,
} from '../../organisation/selectors';
import { usePoll } from '../../../hooks/usePoll';
import { getIntervalMilliSeconds } from '../../../components/lib/utils';
import useIncludeSubGroup from '../../../hooks/useIncludeSubGroup';
import useDevices from '../hooks/useDevices';
import { SENSOR_TYPE } from '../constants';
import useSensorType from '../hooks/useSensorType';
import ColumnsManager, { reorderColumns } from '../../../components/ColumnsManager';
import useLocalPreference from '../../user/hooks/useLocalPreference';
import DownloadTableCSVLink from '../../../components/DownloadTableCSVLink';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';
import { addVibrationRms } from '../utils';

const defaultSorted = [{
  dataField: 'status_summary',
  order: 'desc'
}];

const defaultColumns = [
  statusSummary,
  statusCaption,
  calibration,
  siteName,
  subAreaName,
  equipmentName,
  equipmentNumber,
  conditionIndicated,
  // todo: remove rms + mm2 -> DASH-1018
  // rms,
  // mm2,
  vibrationRms,
  temperature,
  equipmentState,
  utilisationMonth,
  running,
  muteAdvisoryFor,
  note,
  serial,
];

export function AddEquipmentButton() {
  const { t } = useTranslation();
  return (
    <AddFitMachineModal key="add">
      <Button variant="primary" className="pl-1">
        <IoIosMotor size="1.4em" /> <span>{t('screens.equipment.equipment-list.add-equipment')}</span>
      </Button>
    </AddFitMachineModal>
  );
}

function AddButton() {
  const { t } = useTranslation();
  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="pl-1">
        <IoIosAddCircleOutline size="1.4em" /> {t('common.add')}{' '}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <AddFitMachineModal key="add">
          <Dropdown.Item>
            <IoIosMotor size="1.4em" /> <span>{t('screens.equipment.equipment-list.add-equipment')}</span>
          </Dropdown.Item>
        </AddFitMachineModal>
        <Private minUserType="Admin">
          <LinkContainer to="/group/devices">
            <Dropdown.Item >
              <IoIosMotor size="1.4em" /> <span>{t('common.manage-group-equipment')}</span>
            </Dropdown.Item>
          </LinkContainer>
        </Private>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function NoDataIndication({ isAdmin, hasActiveSubGroup }) {
  const { t } = useTranslation();
  return isAdmin ? !hasActiveSubGroup ? (
    // admin on org group
    <EmptyCard Icon={IoIosMotor}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          {t('common.started-adding-first-heading')}
        </EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.equipment-list.no-data.started-adding-first-body')}
        </EmptyCard.Text>
        <div className="text-center">
          <AddEquipmentButton />
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  ) : (
    // admin on sub group
    <EmptyCard Icon={IoIosMotor}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          {t('common.started-adding-group-heading')}
        </EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.equipment-list.no-data.started-adding-group-body')}
        </EmptyCard.Text>
        <div className="text-center">
          <LinkContainer to="/group/devices">
            <Button variant="primary">
              {t('common.manage-group-equipment')}
            </Button>
          </LinkContainer>
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  ) : (
    // user
    <EmptyCard Icon={IoIosMotor}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          {t('common.no-equipment-heading')}
        </EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.equipment-list.no-data.no-equipment-body')}
        </EmptyCard.Text>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

const ConnectedNoDataIndication = connect(state => ({ isAdmin: isAdmin(state) }))(NoDataIndication);

const { REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES = 5 } = process.env;
const checkPeriods = getIntervalMilliSeconds(REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES);
function EquipmentList() {

  const { t } = useTranslation();
  const activeSubGroupId = useSelector(getActiveSubGroupId);
  const rmsAvailable = useSelector(getOrganisationRmsAvailablePreference);
  const mm2Available = useSelector(getOrganisationMm2AvailablePreference);
  const deviceStatus = useSelector(getDeviceStatus);
  const hasFitPower = useSelector(state => getCurrentOrganisationHasProductCode(state, 'fitpower'));
  const sensorType = useSensorType();
  const { devices = [], loading, lastFetch, error, fetchDevices } = useDevices();
  const { preference: equipmentListView, setPreference: setEquipmentListView } =
    useLocalPreference({key: 'equipment_list_view', defaultValue: SENSOR_TYPE.FITMACHINE});
  const { preference: unitsSystem } = useLocalPreference({key: 'units_system'});

  const baseTableName = 'equipment_list';
  const { includeSubGroup, setIncludeSubGroup } = useIncludeSubGroup(baseTableName);
  const { columns: organisationUserColumns } = useOrganisationUserColumns({ showTag: baseTableName });

  usePoll(
    (canceller) => fetchDevices({ includeChildren: includeSubGroup }, canceller),
    [fetchDevices, activeSubGroupId, includeSubGroup],
    { cancellable: true, interval: checkPeriods, includeSubGroup }
  );

  const location = useLocation();
  const getSearchTextFromURL = useCallback(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const searchText = urlSearchParams.get('s') || '';
    return searchText;
  }, [location.search]);

  const setSearchTextToURL = useCallback((searchText) => {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set('s', searchText);
    history.push(`?${urlSearchParams.toString()}`);
  }, [location.search]);

  const defaultSearchText = getSearchTextFromURL();
  const [searchText, setSearchText] = useState(getSearchTextFromURL());
  const [initialDataSize, setInitialDataSize] = useState(undefined);

  const fitMachineColumns = useMemo(() => [
    statusSummary,
    calibration,
    siteName,
    subAreaName,
    {...equipmentName, formatExtraData: { searchText }},
    equipmentNumber,
    ...organisationUserColumns,
    conditionIndicated,
    // todo: remove rms + mm2 -> DASH-1018
    // rms,
    // !!mm2Available && mm2,
    vibrationRms,
    temperature,
    utilisationMonth,
    running,
    muteAdvisoryFor,
    note,
  ].filter((col) => !!col), [rmsAvailable, mm2Available, searchText, organisationUserColumns]);

  const fitPowerColumns = useMemo(() => [
    statusSummary,
    siteName,
    subAreaName,
    {...equipmentName, formatExtraData: { searchText }},
    equipmentNumber,
    ...organisationUserColumns,
    conditionIndicated,
    equipmentState,
    utilisationMonth,
    running,
    note,
  ], [searchText, organisationUserColumns]);

  let sensorColumns = sensorType === SENSOR_TYPE.FITPOWER ? fitPowerColumns :
    sensorType === SENSOR_TYPE.FITMACHINE ? fitMachineColumns :
      defaultColumns;
  sensorColumns = useLocalisedColumns(sensorColumns);
  const tableName = `${sensorType}_equipment_list`;
  const { preference: columnsPreference } = useLocalPreference({ key: tableName });
  const columns = useMemo(() => {
    return reorderColumns(columnsPreference, sensorColumns);

    // const orderedColumns = [...sensorColumns];
    // if(columnsPreference?.order) {
    //   for(const col of sensorColumns) {
    //     const index = columnsPreference.order[col.dataField];
    //     if(index >= 0) orderedColumns[index] = col;
    //   }
    // }
    // if(!columnsPreference?.visible) return orderedColumns;
    // return orderedColumns.filter(col => {
    //   return columnsPreference.visible[col.dataField] !== false;
    // });
  }, [sensorColumns, columnsPreference]);

  const onboardedDevices = devices
    // split out conditional_overall into duplicate attributes on each item
    .map(({ condition_overall, ...deviceAttrs }) => {
      return {
        ...deviceAttrs,
        status: condition_overall,
        condition_overall,
      };
    });

  const updatedOnboardedDevices = addVibrationRms(onboardedDevices);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        onSearch={(text) => {
          setSearchText(text);
        }}
        searchable
        title={t('common.equipment-list')}
        subTitle={hasFitPower && <>
          {t('common.monitored-by')}&nbsp;
          <ButtonGroup>
            <IndexLinkContainer to="/equipment/list?type=fitmachine" isActive={match => match && equipmentListView === SENSOR_TYPE.FITMACHINE}>
              <Button variant={equipmentListView === SENSOR_TYPE.FITMACHINE ? 'secondary' : 'outline-secondary'} size="sm" onClick={() => setEquipmentListView(SENSOR_TYPE.FITMACHINE)}>
                FitMachine
              </Button>
            </IndexLinkContainer>
            <IndexLinkContainer to="/equipment/list?type=fitpower" isActive={match => match && equipmentListView === SENSOR_TYPE.FITPOWER}>
              <Button variant={equipmentListView === SENSOR_TYPE.FITPOWER ? 'secondary' : 'outline-secondary'} size="sm" onClick={() => setEquipmentListView(SENSOR_TYPE.FITPOWER)}>
                FitPower
              </Button>
            </IndexLinkContainer>
          </ButtonGroup>
        </>}
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        buttonGroups={[
          [<AddButton key={1} />],
          <Button
            as={DownloadTableCSVLink}
            columns={columns}
            data={updatedOnboardedDevices}
            variant="outline-secondary"
            name={`MOVUS-equipment-list ${lastFetch ? moment(lastFetch).format('YYYY-MMM-DD kk:mm:ss') : ''}`}
            loading={loading}
            namespace="equipment-list"
            options={{unitSystemValue: unitsSystem.value}}
          />,
          [
            <ButtonGroup key={2}>
              <ColumnsManager
                key={0}
                columns={sensorColumns}
                tableName={tableName}
                variant="outline-secondary"
              />
              {activeSubGroupId && <Button
                key={2}
                variant={includeSubGroup ? 'secondary' : 'outline-secondary'}
                onClick={() => setIncludeSubGroup(!includeSubGroup)}
              >
                {!includeSubGroup ? t('common.show'): t('common.hide') } {t('screens.equipment.equipment-list.subgroup-equipment')}
              </Button>}
            </ButtonGroup>
          ],
        ]}
        tableProps={{...props, dataSizeProps: {...props.dataSizeProps, totalCount: devices.length }}}
      />
    );
  }, [loading, lastFetch, error, includeSubGroup, activeSubGroupId, hasFitPower, onboardedDevices, columns]);

  // allow different no data indication for a subgroup
  const noDataIndication = useCallback(() => {
    return (
      <ConnectedNoDataIndication hasActiveSubGroup={!!activeSubGroupId} />
    );
  }, [!!activeSubGroupId]);

  const hasFilter = useMemo(() => {
    let hasFilter = false;
    for(const item of deviceStatus) {
      if(item.selected === false) hasFilter = true;
    }
    return hasFilter;
  }, [deviceStatus]);
  const history = useHistory();

  return (
    <Container fluid>
      <div className="equipment-list-table">
        <Table
          pagination
          renderHeader={renderHeader}
          data={updatedOnboardedDevices}
          defaultSorted={defaultSorted}
          columns={columns}
          noDataIndication={noDataIndication}
          loading={loading}
          filter={filterFactory()}
          hasFilter={hasFilter}
          // refreshHandler={refresh}
          rowEvents = {{
            onClick: (e, {id, sensor_type}) => {
              if(window.getSelection().toString().length > 0) return;
              setSearchTextToURL(searchText);
              const url = `/equipment/${id}/` + (sensor_type === 'fitpower' ? 'fitpower' : '');
              history.push(url);
            }
          }}
          rowStyle={{
            cursor: 'pointer'
          }}
          search={{
            defaultSearch: defaultSearchText,
            afterSearch: (result) => {
              if(!initialDataSize >= 0) setInitialDataSize(result.length);
            }
          }}
          customProps={{
            initialDataSize,
          }}
        />
      </div>
    </Container>
  );
}

export default EquipmentList;
