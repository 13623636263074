import { useMemo } from 'react';

// use a hook to set column visibility inside components
export function useColumnsWithVisibility(columns, columnVisibilityByDataField) {
  // return columns for use
  return useMemo(() => {
    return columns.map(function modifyColumnVisibility(column) {
      const visibility = columnVisibilityByDataField[column.dataField];
      if (visibility !== undefined) {
        // if the column doesn't have the correct hidden status, then change it
        if ((!!column.hidden) !== (!visibility)) {
          return {
            ...column,
            'hidden': !visibility,
          };
        }
      }
      // return column unchanged
      return column;
    });
  }, [...columns, ...Object.entries(columnVisibilityByDataField).flat()]);
}

// sort numeric values as higher than other types
// eg. null and undefined are ranked lower than 0 and -1
export function numericSortFunc(a, b, order) {
  const asc = order === 'asc';
  const aIsNumeric = typeof a === 'number';
  const bIsNumeric = typeof b === 'number';

  // sort numerically
  if (aIsNumeric && bIsNumeric) {
    return asc ? a - b : b - a;
  }
  // or sort numeric values as before non-numeric values
  return aIsNumeric ? -1 : bIsNumeric ? 1 : 0;
}

// sort boolean values as higher than other types
// eg. null and undefined are ranked lower than 0 and -1
export function booleanSortFunc(a, b, order) {
  const asc = order === 'asc';
  const aIsBoolean = typeof a === 'boolean';
  const bIsBoolean = typeof b === 'boolean';

  // sort as boolean
  if (aIsBoolean && bIsBoolean) {
    return asc ? a - b : b - a;
  }
  // or sort boolean values as before non-boolean values
  return aIsBoolean ? -1 : bIsBoolean ? 1 : 0;
}

// sort time values as higher than other types
// eg. null, '', undefined, and falsy values are ranked lower than new Date(0) and new Date(-1)
export function timeSortFunc(a, b, order) {
  const asc = order === 'asc';
  const aIsDate = a instanceof Date;
  const bIsDate = b instanceof Date;

  // sort as time
  if (aIsDate && bIsDate) {
    return asc ? a - b : b - a;
  }

  // try to sort as time strings
  const aIsString = a && typeof a === 'string';
  const bIsString = b && typeof b === 'string';
  if (aIsString && bIsString) {
    return asc ? a.localeCompare(b) : b.localeCompare(a);
  }

  // note: data has been seen in the form of Date, ISOString, null, and ''.
  // data that has been expected to be null according to the redux store
  // has been seen to be '' inside this function.
  // somehow all data may have been transformed to strings somewhere :/

  const aIsObject = a && typeof a === 'object';
  const bIsObject = b && typeof b === 'object';

  // or sort truthy dates or date string values as before everything else
  return (aIsString || aIsObject) ? -1 : (bIsString || bIsObject) ? 1 : 0;
}

// sort string values with 'human context' of what numbers mean
// eg. sort list: pump 1, pump 2, pump 10, pump 21, pump model2-3, pump model12-6, pump model12-12
// as opposed to: pump 1, pump 10, pump 2, pump 21, pump model12-12, pump model12-6, pump model2-3
// by transforming each number in a string to a 10 digit string padded with 0s
// ie. sort: '0000000002' vs '0000000012', as opposed to sorting: '12' vs '2'
export function stringSortValueWithHumanNumbersFunc(str='') {
  // ensure only strings with text are sorted, all falsy or whitespace strings
  // should be considered lower sort value priority
  return str && `${str}`.trim()
    ? `${str}`.replace(/\d+/g, match => match.padStart(10, '0'))
    : undefined; // undefined is the lowest sort value priority
}

// sort string values as higher than other types
// eg. null and undefined are ranked lower than '' and ' '
export function stringSortFunc(a, b, order) {
  const asc = order === 'asc';
  // check that values are non-empty strings
  const aIsString = !!a && typeof a === 'string';
  const bIsString = !!b && typeof b === 'string';

  // sort by string
  if (aIsString && bIsString) {
    return asc ? a.localeCompare(b) : b.localeCompare(a);
  }
  // or sort string values as before non-string values
  return aIsString ? -1 : bIsString ? 1 : 0;
}

// a sort function that can handle a mixture of all types
export function defaultSortFunc(a, b, order) {
  const typeA = typeof a;
  const typeB = typeof b;
  const asc = order === 'asc';

  // try a numeric difference
  if (typeA === 'number' && typeB === 'number') {
    return asc ? a - b : b - a;
  }
  // try a string difference
  if (typeA === 'string' && typeB === 'string') {
    return asc ? a.localeCompare(b) : b.localeCompare(a);
  }
  // sort according to type. remember that null is an object.
  // set order as higher index being more desirable (as an unfound index is -1)
  // link: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
  const typeOrder = [
    // unfound is -1
    'undefined',
    'function',
    'symbol',
    'object',
    'boolean',
    'string',
    'number',
    'bigint',
  ];

  // or return type difference: sorted: ["number", "object", "string", "undefined"]
  // objects should not be passed here
  // also allow non-empty values to be ahead of empty values
  return typeOrder.indexOf(typeB) - typeOrder.indexOf(typeA);
}

// Sort by field of status_summary in /devices endpoint.
export function statusSortFunc(a, b, order) {
  const mapStateToSortingNumber = (state) => {
    const map = {
      ok: 1,
      warning: 2,
      critical: 3,
    };
    return map[state] || 0;
  };
  const sortingNumberA = mapStateToSortingNumber(a.state);
  const sortingNumberB = mapStateToSortingNumber(b.state);
  const aSeverity = a.severity || 0;
  const bSeverity = b.severity || 0;
  if(aSeverity === bSeverity) {
    return order === 'asc' ? sortingNumberA - sortingNumberB : sortingNumberB - sortingNumberA;
  }
  return order === 'asc' ? aSeverity - bSeverity : bSeverity - aSeverity; // Sort by severity first, if severity has the same value, sort by state.
}

export const mapColourToVariant = (colour) => {
  let variant;
  switch(colour) {
    case 'red':
      variant = 'danger'; break;
    case 'green':
      variant='success'; break;
    case 'orange':
    case 'yellow':
      variant='warning'; break;
    case 'blue':
      variant='primary'; break;
    case 'black':
      variant='dark'; break;
    case 'grey':
      variant='secondary'; break;
    case 'white':
      variant='light'; break;
    default:
      variant='secondary';
  }
  return variant;
};

export function columnWithFixedWidth(column, width) {
  return width > 0 ?
    {
      ...column,
      style: {
        ...column.style,
        width: width + 'px'
      },
      headerStyle: {
        ...column.headerStyle,
        width: width + 'px'
      }
    } :
    column;
};