// Rewrite NewOrganisation using hooks and new organisation form.
import { useState } from "react";
import OrganisationPageLayout from "../components/OrganisationPageLayout";
import Title from '../../../components/Title';
import OrganisationForm from "../components/OrganisationForm";

function NewOrganisation() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submittedAt, setSubmittedAt] = useState(null);

  return (
    <OrganisationPageLayout>
      <Title
        title="New Organisation"
        loading={loading}
        lastFetch={submittedAt}
        error={error}
      />
      <OrganisationForm
        onSubmitting={() => setLoading(true)}
        onError={(error) => { setError(error); setLoading(false); }}
        onSuccess={(submittedAt) => { setSubmittedAt(submittedAt); setLoading(false); }}
      />
    </OrganisationPageLayout>
  );
}

export default NewOrganisation;