
import { connect } from 'react-redux';
import { getActiveGroupId, getOrganisationGroup } from '../selectors';

const mapStateToProps = (state) => {
  return {
    // note that the group id's existence/authorisation isn't validated
    // get the active group id, or the top-level group id if no active id has yet been set
    groupId: getActiveGroupId(state) || (getOrganisationGroup(state) || {}).id,
  };
};

export default connect(mapStateToProps);
