import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDevice,
  getDeviceImpactConfigValues,
  getDeviceImpactConfigState,
} from '../../equipment/selectors';
import {
  getGroup,
  getGroupImpactConfigValues,
  getGroupImpactConfigState
} from '../../organisation/selectors';
import {
  fetchDeviceImpactConfig,
  deleteDeviceImpactConfig,
  addDeviceImpactConfig,
  updateDeviceImpactConfig,
} from '../../equipment/actions';
import {
  fetchGroupImpactConfig,
  deleteGroupImpactConfig,
  addGroupImpactConfig,
  updateGroupImpactConfig,
} from '../../organisation/actions';

// Alarm impact settings can be applied to device or group level.
// This hook encapsulates the operations(reading, editing, deleting and adding) on impact settings for device or group
// based on the argument received.
export default function useAlarmImpactSettings({ deviceId, groupId }) {

  const dispatch = useDispatch();
  const impactConfigValues = useSelector(state => {
    return deviceId ? getDeviceImpactConfigValues(state, deviceId) :
      groupId ? getGroupImpactConfigValues(state, groupId) :
        [];
  }) || [];
  const impactConfigState = useSelector(state => {
    return deviceId ? getDeviceImpactConfigState(state, deviceId) :
      groupId ? getGroupImpactConfigState(state, groupId) :
        {};
  });
  const { loading, lastFetch, error } = impactConfigState || {};

  const device = useSelector(state => {
    return deviceId && getDevice(state, deviceId);
  });
  const group = useSelector(state => {
    return groupId && getGroup(state, groupId);
  });

  const fetchImpactSettings = useCallback(() => {
    if(deviceId) return dispatch(fetchDeviceImpactConfig({ id: deviceId }));
    if(groupId) return dispatch(fetchGroupImpactConfig({id: groupId }));
  }, [deviceId, groupId]);

  const deleteImpactSetting = useCallback((setting) => {
    if(deviceId) return dispatch(deleteDeviceImpactConfig(device, setting));
    if(groupId) return dispatch(deleteGroupImpactConfig(group, setting));
  }, [deviceId, groupId]);

  const addImpactSetting = useCallback(setting => {
    if(deviceId) return dispatch(addDeviceImpactConfig(device, setting)).then(() => fetchImpactSettings());
    if(groupId) return dispatch(addGroupImpactConfig(group, setting)).then(() => fetchImpactSettings());
  }, [deviceId, groupId]);

  const updateImpactSetting = useCallback((setting, data) => {
    if(deviceId) return dispatch(updateDeviceImpactConfig(device, setting, data));
    if(groupId) return dispatch(updateGroupImpactConfig(group, setting, data));
  }, [deviceId, groupId]);

  return {
    group,
    device,
    loading,  // loading impact settings.
    lastFetch,
    error,
    impactSettings: impactConfigValues,
    fetchImpactSettings,
    deleteImpactSetting,
    addImpactSetting,
    updateImpactSetting,
  };
}