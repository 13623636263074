import createReducer from '../../lib/createReducer';
import moment from 'moment';

import * as ACTION_TYPES from './types/ActionTypes';

import { upsertListItems, upsertListItem, upsertItem } from '../../lib/reducerUtils';

export const DEFAULT_STATE = {
  user: null,
  users: [],

  loading: false, // the list of users is being loaded
  lastFetch: null, // the last time we did a cloud fetch
  error: null, // any error loading the list

  localPreferencesByUserId: {
    // e.g. show equipment degradation indicators
    // "531": { "showEquipmentDegradation": false, },
  },
  remotePreferencesByUserId: {
    // this is a reflection of the user's saved preferences in the platform
    // e.g. show equipment degradation indicators
    // "531": { "show_equipment_degradation": false, },
  },
  optimisticPreferences: [
    // this is a temporary view of the user's saved preferences while waiting for the platform
    // multiple items may exist here while await answers from the platform
    // e.g. show equipment degradation indicators
    // { "show_equipment_degradation": false, },
  ],
};

function resetUser(state) {
  return {
    ...DEFAULT_STATE,
    // persist anonymous localPreferences
    localPreferences: state.localPreferences,
    // persist user localPreferences
    localPreferencesByUserId: state.localPreferencesByUserId,
    // persist last login information
    lastLogin: state.lastLogin,
  };
}

export default createReducer(DEFAULT_STATE, {
  [ACTION_TYPES.RECEIVE_LOGIN](state, { request, response }) {
    if (response.mfa_enabled) {
      return {
        ...state,
        mfa: {
          mfaEnabled: true,
          mfaValidated: false,
          otpToken: response.token,
        }
      };
    }
    return {
      ...state,
      // record login information
      login: {
        email: request.user_name,
        organisation: request.sub_domain,
        token: response.token
      },
      // record login information for next login
      lastLogin: {
        // but only what is necessary for logging in again
        email: request.user_name,
        organisation: request.sub_domain,
      },
    };
  },
  [ACTION_TYPES.REQUEST_VALIDATE_OTP](state) {
    return {
      ...state,
      loading: true,
    };
  },
  [ACTION_TYPES.RECEIVE_VALIDATE_OTP](state, { response }) {
    return {
      ...state,
      loading: false,
      mfa: {
        mfaEnabled: true,
        mfaValidated: true,
        otpToken: null,
      },
      login: {
        email: state.lastLogin.email,
        organisation: state.lastLogin.organisation,
        token: response.token,
      }
    };
  },
  [ACTION_TYPES.VALIDATE_OTP_FAILURE](state) {
    return {
      ...state,
      loading: false,
      mfa: {
        mfaEnabled: true,
        mfaValidated: false,
        otpToken: state.mfa.otpToken,
      },
    };
  },
  [ACTION_TYPES.RECEIVE_GET_CONFIRM_OTP](state, { response }) {
    return {
      ...state,
      mfa: {
        otpToken: response.otp_token,
      },
    };
  },
  [ACTION_TYPES.RECEIVE_SEND_CONFIRM_OTP](state, { response }) {
    return {
      ...state,
      user: upsertItem(state.user, {
        mfa_sms_confirmed: true,
        mfa_delivery: 'sms',
      })
    };
  },
  [ACTION_TYPES.RESET_MFA_REQUIRED](state) {
    return {
      ...state,
      mfa: {
        mfaEnabled: null,
        mfaValidated: null,
        otpToken: null,
      },
      login: {
        token: null,
      }
    };
  },
  [ACTION_TYPES.REQUEST_USER](state, action) {
    return state;
  },
  [ACTION_TYPES.RECEIVE_USER](state, { request, response }) {
    // note: the current user is never intended to be in the users list
    return {
      ...state,
      user: upsertItem(state.user, response),
    };
  },
  [ACTION_TYPES.RECEIVE_USER_TAGS](state, { user, response }) {
    // set own tags
    if (state.user && state.user.id === user.id) {
      return {
        ...state,
        user: upsertItem(state.user, { _embedded: { tags: response } }),
        // copy over preference tags to the preserved app state space
        remotePreferencesByUserId: {
          // add other user preferences
          ...state.remotePreferencesByUserId,
          // add this user's preferences
          [user.id]: Object.entries(response).reduce((acc, [key, value]) => {
            // copy over preference keys found in user tags
            const match = key.match(/^preferences:(.+)$/);
            if (match) {
              acc[match[1]] = value;
            }
            return acc;
          }, {}),
        },
      };
    }
    // set others tags
    else {
      // to do
      return {
        ...state,
        // overwrite tags for the user
        users: upsertListItem(state.users, { _embedded: { tags: response } }, {
          // upsert the user that matches the request user id
          match: item => item.id === user.id,
        }),
      };
    }
  },
  [ACTION_TYPES.RECEIVE_USER_INFO](state, { response }) {
    const usersList = [...state.users];
    const userId = response.id;
    const index = usersList.findIndex(({ id }) => id === userId);
    // update or insert
    if (index >= 0) {
      const user = usersList[index];
      usersList[index] = {
        ...user,
        ...response,
        // ensure that previously stored relations are kept if not overwritten
        _embedded: {
          ...user._embedded,
          ...response._embedded,
        },
      };
    } else {
      usersList.push(response);
    }
    return {
      ...state,
      users: usersList,
    };
  },
  [ACTION_TYPES.RECEIVE_USER_DEVICES](state, { user, response: { _embedded } }) {
    return {
      ...state,
      // upsert the user with the embedded relations, where the user id matches
      users: upsertListItem(state.users, { _embedded }, {
        match: item => item.id === user.id,
        upsertRelated: {
          devices: {
            matchById: 'device_id',
            filter: (newList, match) => item => {
              // filter to include all archived devices (out of scope for this request)
              // and to active devices included in this list
              return !!item['archived'] || newList.find(newItem => match(item, newItem));
            },
          },
        }
      }),
    };
  },
  [ACTION_TYPES.RECEIVE_ARCHIVED_USER_DEVICES](state, { user, response: { _embedded } }) {
    return {
      ...state,
      // upsert the user with the embedded relations, where the user id matches
      users: upsertListItem(state.users, { _embedded }, {
        match: item => item.id === user.id,
        upsertRelated: {
          devices: {
            matchById: 'device_id',
            filter: (newList, match) => item => {
              // filter to include all active devices (out of scope for this request)
              // and to archived devices included in this list
              return !item['archived'] || newList.find(newItem => match(item, newItem));
            },
          },
        }
      }),
    };
  },
  //RECEIVE_USER_DEVICES
  [ACTION_TYPES.USER_FAILURE](state, { response }) {
    return state;
  },
  [ACTION_TYPES.TOKEN_EXPIRED]: resetUser,
  [ACTION_TYPES.LOGOUT]: resetUser,
  [ACTION_TYPES.SET_LOCAL_PREFERENCE](state={}, { payload: { key, value } }) {
    return {
      ...state,
      localPreferences: {
        // add other user preferences
        ...state.localPreferences,
        // add preferences for all users
        [key]: value,
      },
    };
  },
  [ACTION_TYPES.SET_USER_LOCAL_PREFERENCE](state={}, { meta: { user }, payload: { key, value } }) {
    return user && user.id ? {
      ...state,
      localPreferencesByUserId: {
        // add other user preferences
        ...state.localPreferencesByUserId,
        // add this user's preferences
        [user.id]: {
          ...state.localPreferencesByUserId && state.localPreferencesByUserId[user.id],
          [key]: value,
        },
      },
    } : state;
  },
  [ACTION_TYPES.SET_USER_OPTIMISTIC_REMOTE_PREFERENCE](state={}, { payload: preferences }) {
    return {
      ...state,
      optimisticPreferences: [
        // add previous preferences
        ...state.optimisticPreferences,
        // add this set of preferences
        preferences,
      ],
    };
  },
  [ACTION_TYPES.UNSET_USER_OPTIMISTIC_REMOTE_PREFERENCE](state={}, { payload: preferences }) {
    const { optimisticPreferences=[] } = state;
    return {
      ...state,
      // remove the given set of preferences from the changes
      optimisticPreferences: optimisticPreferences.filter(item => item !== preferences),
    };
  },
  [ACTION_TYPES.REQUEST_USERS](state, { response }) {
    return {
      ...state,
      loading: true,
    };
  },
  [ACTION_TYPES.RECEIVE_USERS](state, { response }) {
    const userTypeOptions = state.userTypeOptions;
    const userList = (response &&
      response._embedded && response._embedded.users) || [];
    const mappedUserList = userTypeOptions ? userList.map(user => ({
      ...user,
      user_type_display_name: userTypeOptions[user.user_type]?.display_name || user.user_type
    })) : userList;
    return {
      ...state,
      users: upsertListItems(state.users, mappedUserList, {
        matchById: 'id',
        upsertRelated: {
          devices: {
            matchById: 'device_id',
          },
        },
      }),
      loading: false,
      error: null,
      lastFetch: moment()
    };
  },
  [ACTION_TYPES.USERS_FAILURE](state, { response }) {
    return {
      ...state,
      loading: false,
      error: response
    };
  },
  [ACTION_TYPES.REQUEST_USER_TYPES](state) {
    return {
      ...state,
      userTypeOptions: {
        ...state.userTypeOptions,
        _state_: {
          loading: true,
        }
      },
    };
  },
  [ACTION_TYPES.RECEIVE_USER_TYPES](state, { response }) {
    return {
      ...state,
      userTypeOptions: {
        ...state.userTypeOptions,
        ...response._embedded.items,
        _state_: {
          loading: false,
          error: null,
        },
      }
    };
  },
  [ACTION_TYPES.USER_TYPES_FAILURE](state, { response }) {
    return {
      ...state,
      userTypeOptions: {
        ...state.userTypeOptions,
        _state_: {
          loading: false,
          error: response,
        },
      },
    };
  },
  [ACTION_TYPES.RECEIVE_ARCHIVE_USER](state, { user }) {
    return {
      ...state,
      users: state.users.filter(({ id }) => id !== user.id),
    };
  },

  // note: this reducer requires organisation property "id" only
  [ACTION_TYPES.RECEIVE_USER_PICTURE](state, { user, response }) {
    const {
      url,
      status,
    } = response;
    return {
      ...state,
      user: state.user && state.user.id === user.id
        ? {
          ...state.user,
          // add selected picture details into object relations
          _embedded: {
            ...state.user._embedded,
            picture: {
              url,
              status,
              lastReceived: Date.now(),
            },
          },
        }
        : state.user,
      users: upsertListItem(state.users, {
        id: user.id,
        // add selected picture details into object relations
        _embedded: {
          picture: {
            url,
            status,
            lastReceived: Date.now(),
          },
        },
      }),
    };
  },

  // note: this reducer requires organisation property "id" only
  [ACTION_TYPES.USER_PICTURE_FAILURE](state, { user }) {
    return {
      ...state,
      user: state.user && state.user.id === user.id
        ? {
          ...state.user,
          // add selected picture details into object relations
          _embedded: {
            ...state.user._embedded,
            picture: {
              lastReceived: Date.now(),
            },
          },
        }
        : state.user,
      users: upsertListItem(state.users, {
        id: user.id,
        // add selected picture details into object relations
        _embedded: {
          picture: {
            lastReceived: Date.now(),
          },
        },
      }),
    };
  },
  [ACTION_TYPES.REQUEST_USER_SUBSCRIPTIONS](state, { user }) {
    const userId = user?.id;
    if(!userId || userId === state.user.id) {
      return {
        ...state,
        user: upsertItem(state.user, {
          _embedded: {
            subscriptions: {
              _state_: {
                loading: true,
              }
            }
          }
        })
      };
    }
    return {
      ...state,
      users: upsertListItem(state.users, {
        id: userId,
        _embedded: {
          subscriptions: {
            _state_: {
              loading: true,
            }
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_USER_SUBSCRIPTIONS](state, { user, response }) {
    const userId = user?.id;
    const channels = response._embedded?.channels || [];
    if(channels.length) channels.sort((a, b) => a.channel_id < b.channel_id ? -1 : 1);  // Sort channel by id to make sure 0 channel(default channel) is always on the top of the list.
    const mappedChannels = channels.map(ch => {
      return {
        ...ch,
        sub_channels: ch.sub_channels.map(subCh => ({...subCh, channel_id: ch.channel_id, escalation: ch.escalation}))  // Add channel id and escalation properties to sub-channels.
      };
    });
    if(!userId || userId === state.user.id) {
      return {
        ...state,
        user: upsertItem(state.user, {
          _embedded: {
            subscriptions: {
              _state_: { loading: false, error: null, lastFetch: Date.now() },
              items: mappedChannels,
            }
          }
        })
      };
    }
    return {
      ...state,
      users: upsertListItem(state.users, {
        id: userId,
        _embedded: {
          subscriptions: {
            _state_: { loading: false, error: null, lastFetch: Date.now() },
            items: mappedChannels,
          }
        }
      })
    };
  },
  [ACTION_TYPES.USER_SUBSCRIPTIONS_FAILURE](state, { user, response }) {
    const userId = user?.id;
    if(!userId || userId === state.user.id) {
      return {
        ...state,
        user: upsertItem(state.user, {
          _embedded: {
            subscriptions: {
              _state_: { loading: false, error: response },
            }
          }
        })
      };
    }
    return {
      ...state,
      users: upsertListItem(state.users, {
        id: userId,
        _embedded: {
          subscriptions: {
            _state_: { loading: false, error: response },
          }
        }
      })
    };
  },
  [ACTION_TYPES.RECEIVE_UPDATE_USER_SUBSCRIPTIONS](state, { user, response, subscriptionData }) {
    const isSelf = !user || user.id === state.user.id ;
    const { subscriptions } = subscriptionData;
    const subscriptionsMap = {};
    subscriptions.forEach(ch => {
      const key = `${ch.channel_id}|${ch.sub_channel_id || 0}|${+ch.escalation}`;
      subscriptionsMap[key] = ch.subscribed;
    });
    const userSubscriptions = isSelf ?
      (state.user?._embedded?.subscriptions?.items || []) :
      (state.users?.find(u => u.id === user.id)?._embedded?.subscriptions?.items || []);
    const updatedSubscriptions = JSON.parse(JSON.stringify(userSubscriptions)); // Deep clone.
    for(const channel of updatedSubscriptions) {
      const key = `${channel.channel_id}|0|${+channel.escalation}`;
      if(subscriptionsMap[key] !== undefined) channel.subscribed = subscriptionsMap[key];
      for(const subChannel of channel.sub_channels) {
        const key = `${channel.channel_id}|${subChannel.sub_channel_id}|${+channel.escalation}`;
        if(subscriptionsMap[key] !== undefined) subChannel.subscribed = subscriptionsMap[key];
      }
    }
    if(isSelf) {
      return {
        ...state,
        user: upsertItem(state.user, {
          _embedded: {
            subscriptions: {
              _state_: { loading: false, error: null, lastFetch: Date.now() },
              items: updatedSubscriptions,
            }
          }
        })
      };
    }
    return {
      ...state,
      users: upsertListItem(state.users, {
        id: user.id,
        _embedded: {
          subscriptions: {
            _state_: { loading: false, error: null, lastFetch: Date.now() },
            items: updatedSubscriptions,
          }
        }
      })
    };
  }
});
