import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import history from '../../../history';
import { submitPasswordResetConfirm, login } from './../actions';

import { Form, Button, Alert } from 'react-bootstrap';
import LoadingSpinner from '../../../components/LoadingSpinner';

import RootUnauthenticated from '../../../components/RootUnauthenticated';
import { PasswordRequirements } from './UpdatePassword';
import { addToast } from '../../../components/Toaster';

import { getFormValues } from '../../../lib/utils';
import { getPathParamFromProps, getQueryParamFromProps } from '../../selectors';

class ResetPassword extends Component {

  state = {
    submit: {},
  };

  navigateToLogin = () => {
    const { email, subDomain } = this.props;

    // build optional search params
    const params = new URLSearchParams();
    if (email) {
      params.append('email', email);
    }
    if (subDomain) {
      params.append('org', subDomain);
    }

    // navigate user to the login page with partial information
    history.push({
      pathname: '/login',
      search: [...params.values()].length ? `?${params.toString()}` : '',
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { new_password, confirm_password } = getFormValues(e);
    const {
      passwordResetToken,
      submitPasswordResetConfirm,
      email,
      subDomain,
      login,
    } = this.props;

    if (!passwordResetToken || !submitPasswordResetConfirm) {
      return;
    }

    if (new_password !== confirm_password) {
      return addToast({
        variant: 'danger',
        header: this.props.t('user.The_given_passwords_do_not_match'),
      });
    }

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // submit form then handle feedback
    submitPasswordResetConfirm({ passwordResetToken }, { new_password })
      .then(async () => {
        // show feedback
        addToast({
          variant: 'success',
          header: this.props.t('user.Your_password_was_reset'),
        });

        // login user only if enough information is available
        if (email && new_password && subDomain && (
          // and also make sure the user is not running Internet Explorer
          // Internet Explorer will not remember the password on this page
          // when presented with the login page, which it appears to consider
          // to be a different concern
          !/MSIE|Trident/.test(window.navigator.userAgent)
        )) {
          // login user
          try {
            await login(email, new_password, subDomain);
          }
          catch(e) {
            this.navigateToLogin();
          }
        }
        else {
          // got to login screen with only partial information
          // this is typical if the user has multiple organisations,
          // and no organisation sub_domain query parameter was forwarded to this URL
          this.navigateToLogin();
        }
      })
      .catch(() => {
        // show feedback
        addToast({
          variant: 'danger',
          header: this.props.t('user.Your_password_was_not_reset'),
        });
        // if still displaying the same submission then update with submission state
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { succeededAt: new Date() } };
          }
        });
      });
  };

  render() {
    const { setPasswordType='reset', email='', t } = this.props;
    const { submit } = this.state;

    return (
      <RootUnauthenticated title="Set a new password">
        <Alert dismissible variant="info">
          <p className="my-0">
            {t('user.Use_form_to_change_password')}&nbsp;{t('user.It_must_include')}:
          </p>
          <PasswordRequirements />
        </Alert>
        <Form className="form-floating-labels" onSubmit={this.handleSubmit}>
          <Form.Group controlId="form__reset-password--new_password">
            <Form.Control
              type="password"
              name="new_password"
              placeholder={t('user.New_Password')}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <Form.Label>{t('user.New_Password')}</Form.Label>
          </Form.Group>
          <div className="d-none">
            <Form.Control
              type="email"
              name="email"
              defaultValue={email}
            />
          </div>
          <Form.Group controlId="form__reset-password--confirm_password">
            <Form.Control
              as="input"
              type="password"
              name="confirm_password"
              placeholder={t('user.New_Password')}
            />
            <Form.Label>{t('user.Confirm_Password')}</Form.Label>
          </Form.Group>
          <Button size="lg" variant="info" block type="submit" disabled={submit.submittedAt}>
            {submit.submittedAt ? (
              <LoadingSpinner size="1.5" />
            ) : (
              setPasswordType === 'set' ? t('user.Set_Password') : t('user.Reset_Password')
            )}
          </Button>
        </Form>
        <br/>
        <Link to="/login">&larr; {t('user.Back_to_login_page')}</Link>
      </RootUnauthenticated>
    );
  }
}

const mapStateToProps = (state, props) => ({
  setPasswordType: getPathParamFromProps(props, 'setPasswordType'),
  passwordResetToken: getQueryParamFromProps(props, 'token'),
  email: getQueryParamFromProps(props, 'email'),
  subDomain: getQueryParamFromProps(props, 'org'),
});
const mapDispatchToProps = { submitPasswordResetConfirm, login };

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ResetPassword));
