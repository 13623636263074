
// export a way to format values in React when values and displayProps are available
export function FormattedValue(props) {
  const {
    // from row formatter function
    value,

    // usually selected from redux state
    digits = 2,
    minimumFractionDigits = digits,
    maximumFractionDigits = digits,
    units = '',
    convert,

    // usually specified as extra options
    displayUnits = true,
    defaultValue = 'N/A',

  } = props;

  // quick exit if inappropriate
  if (typeof value !== 'number') {
    return defaultValue;
  }

  // apply the found display options
  const convertedValue = convert ? convert(value) : value;

  // exit if the conversion did not succeed
  if (isNaN(convertedValue)) {
    return defaultValue;
  }

  const formatOptions = {
    minimumFractionDigits,
    maximumFractionDigits,
  };

  // return a formatted number
  return `${convertedValue.toLocaleString(undefined, formatOptions)}${displayUnits ? units : ''}`;
}

// export a function to display the value given with given display props
// useful for displaying values outside React or for wrapping in React components
export function getFormattedValue(value, displayProps) {
  return FormattedValue({ ...displayProps, value });
}
