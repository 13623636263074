import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { FaSignal } from 'react-icons/fa';
import useBaseChart, { defaultOptions } from "./BaseChart";
import { MOVUS_BADGE_COLOURS, oneDay } from '../../lib/utils';

// Fitpower equipment states chart.
function getInitialOptions({ dataZoom, xAxis } = defaultOptions) {
  return {
    dataZoom,
    xAxis: [{
      ...xAxis,
      show: false,
    }],
    tooltip: {
      position: 'top'
    },
    grid: {
      top: 0,
      left: 100,
      bottom: 50,
      right: 50,
      containLabel: true
    },
    yAxis: {
      type: 'category',
      data: [],
      axisLine: {
        show: false
      },
    },
    series: [
      {
        name: 'Equipment States',
        type: 'scatter',
        symbolSize: function(val) {
          return val[2] * 2;
        },
        data: [],
        animationDelay: function(idx) {
          return idx * 5;
        }
      }
    ],
  };
}

const STATE_HEIGHT = 40;
const oneWeek = oneDay * 7;
const fourWeeks = oneWeek * 4;

function EquipmentStates({
  samples = [],
  equipmentStateDateRange: dateRange,
  setEquipmentStateDateRange: setDateRange,
  ...props
}) {
  const [
    BaseChart,
    {
      useChartUpdateEffect,
      // useChartEvent,
      // useChartUpdateOnChartEvent,
      // useChartUpdateOnCustomEvent,
    }
  ] = useBaseChart(getInitialOptions);

  const timeSpan = useMemo(() => {
    return dateRange.endTime - dateRange.startTime;
  }, [dateRange.startTime, dateRange.endTime]);

  const frequencyKey = useMemo(() => {
    if(timeSpan >= fourWeeks) {
      return 'daily';
    } else if(timeSpan >= oneWeek) {
      return '8hours';
    } else if(timeSpan >= oneDay) {
      return 'hourly';
    } else {
      return 'default';
    }
  }, [timeSpan]);

  const samplesByFrequency = useMemo(() => {
    return samples.filter(sample => !!sample[frequencyKey]).map(sample => {
      return {
        id: sample.id,
        state: {...sample[frequencyKey].state, frequencyKey},
        timestamp: sample.timestamp,
      };
    });
  }, [samples, frequencyKey]);

  const states = useMemo(() => {  // Get all states available as per samples data.
    const states = [];
    samples.forEach(sample => {
      Object.values(sample).forEach(sampleValue => {
        const { state } = sampleValue;
        if(state && state.index >= 0 && !states[state.index]) {
          states[state.index] = state.name;
        }
      });
    });
    states.reverse();
    return states.filter(s => s);
  }, [samples]);

  const statesCount = useMemo(() => {
    return states.filter(s => s).length;
  }, [states]);

  const updateSamples = useCallback(() => {
    const { startTime, endTime } = dateRange;
    const samplesWithinDateRange = samplesByFrequency.filter(sample => Date.parse(sample.timestamp) >= startTime && Date.parse(sample.timestamp) <= endTime);
    const timeRange = Date.parse(samplesWithinDateRange[samplesWithinDateRange.length - 1]?.timestamp || 0) - Date.parse(samplesWithinDateRange[0]?.timestamp || 0);
    const timeSeries = samplesWithinDateRange.length > 0 ? samplesWithinDateRange.map(sample => {
      if(timeRange < 4 * oneDay) {
        return new Date(sample.timestamp).toLocaleTimeString(undefined, {
          day: 'numeric',
          month: 'short',
          hour: 'numeric',
          minute: 'numeric',
        });
      }
      return new Date(sample.timestamp).toLocaleDateString();
    }) : [new Date(startTime).toLocaleDateString(), new Date(endTime).toLocaleDateString()];

    const title = [];
    const singleAxis = [];
    const series = [];
    states.forEach((state, idx) => {
      title.push({
        textBaseline: 'bottom',
        top: STATE_HEIGHT * (idx + 1),
        text: state,
        textStyle: {
          fontWeight: 'normal',
          fontSize: 14,
        },
        padding: 5
      });
      singleAxis.push({
        left: 100,
        type: 'category',
        boundaryGap: false,
        data: timeSeries,
        top: STATE_HEIGHT * idx,
        height: STATE_HEIGHT,
        right: 50,
        axisLabel: {
          show: idx === statesCount - 1 ? true : false,
        },
        splitLine: {
          lineStyle: {
            color: ['#e0e6f1']
          }
        },
        axisTick: {
          lineStyle: {
            width: 1,
          }
        },
        axisLine: {
          lineStyle: {
            color: '#6E7079',
            width: 1,
            type: 'solid'
          }
        },
        tooltip: {
          formatter: (params) => {
            const { value = [], marker = '' } = params;
            return (
              `${moment(value[2]).format('LLLL')}
              <hr style="margin:2px 0 5px;border-top-color: #ccc;"/>
              State: ${marker}${value[3]}`
            );
          }
        }
      });
      series.push({
        singleAxisIndex: idx,
        coordinateSystem: 'singleAxis',
        type: 'scatter',
        data: [],
        symbolSize: function(dataItem) {
          return dataItem[1] * 5;
        },
      });
    });

    samplesWithinDateRange.forEach((sample, idx) => {
      const measuredCurrent = sample?.state;
      if(!measuredCurrent) return;
      const { value, name } = measuredCurrent;
      const index = states.indexOf(measuredCurrent.name);
      series[index]?.data?.push({
        value: [idx, value, sample.timestamp, name, value],
        itemStyle: {
          color: MOVUS_BADGE_COLOURS[measuredCurrent.color].background,
        },
        symbolOffset: [0, '5px']
      });
    });

    return {
      tooltip: {
        position: 'top'
      },
      color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272'],
      title: title,
      singleAxis: singleAxis,
      series: series
    };
  }, [samples, dateRange.startTime, dateRange.endTime, states]);

  useChartUpdateEffect(updateSamples);
  // useChartUpdateOnChartEvent('dataZoom', () => {
  //   updateSamples();
  // });
  const maximumViewedDateRange = useMemo(() => {
    // Use the samples boundary, might be changed.
    return {
      startTime: Date.parse(samples[0]?.timestamp || 0),
      endTime: Date.parse(samples[samples.length - 1]?.timestamp || 0),
    };
  }, [samples.length]);

  return (
    <>
      <BaseChart
        header={<><FaSignal /> Equipment State</>}
        namespace="fitpower-equipment-states"
        samples={samplesByFrequency}
        style={{ height: STATE_HEIGHT * statesCount + 100 + 'px' }}
        {...props}
        dateRange={dateRange}
        setDateRange={setDateRange}
        maximumViewedDateRange={maximumViewedDateRange}
      />
    </>
  );
}

export default EquipmentStates;