import { useState, useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import Table from '../../../components/Table';
import { DropdownTopWindowButton } from '../../../components/DropdownButton';
import { alarmStatusExtra as statusBadge, eventTrigger, alarmTimestamp } from "../columns";
import useChildAlarms from '../hooks/useChildAlarms';

function AlarmCountPopup({
  count,
  alarm,
}) {
  const columns = [
    alarmTimestamp,
    statusBadge,
    {...eventTrigger, text: 'Trigger'},
  ];

  const [fetch, setFetch] = useState(false);
  const handleToggle = useCallback((isOpen) => {
    setFetch(isOpen);
  }, [alarm.id]);
  const { childAlarms, loading } = useChildAlarms(alarm.id, { fetch });

  return (
    <ButtonGroup onClick={e => e.stopPropagation()}>
      <Button variant="outline-secondary">{count - 1}</Button> {/** Minus 1 to exclude the event itself. */}
      <DropdownTopWindowButton
        as={ButtonGroup}
        variant="outline-secondary"
        onToggle={(isOpen) => {
          handleToggle(isOpen, alarm);
        }}
        disabled={count <=1 }
      >
        <div className="px-2" style={{width: '500px', maxHeight: '400px', overflowY: 'auto'}}>
          <Table
            keyField="id"
            condensed
            data={childAlarms}
            columns={columns}
            loading={loading}
          />
        </div>
      </DropdownTopWindowButton>
    </ButtonGroup >
  );
}

export default AlarmCountPopup;