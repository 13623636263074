import React, { Fragment } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap';

import { itemCount as defaultItemCount, renderSearchBar as defaultSearchBar } from './Table';

import "./tableToolbar.scss";
import LoadingSpinner from './LoadingSpinner';
import { IoIosWarning } from 'react-icons/io';

export default function Toolbar(props) {
  const {
    searchable = false,
    showRowCount = true,
    title = "List",
    subTitle = "",
    loading = false,
    error,
    buttons = [],
    buttonGroups = [buttons],
    tableProps = {},
    renderItemCount = defaultItemCount,
    renderSearchBar = defaultSearchBar,
    ButtonGroupContainer = Fragment,
    onSearch,
  } = props;

  /*
   * - align everything to the text baseline, but then adjust all non-text elements from there.
   * - mb-1 attached to each col allows the cols to overflow next to each other with
   *   a decent amount of space between each element
   */
  return (
    <Row className="table-toolbar mb-1 align-items-baseline small-gutters">
      <Col className="mb-1 mr-1 title" xs="auto">
        <h2>{title}</h2>
        {subTitle && <div>{subTitle}</div>}
      </Col>
      {/*
        ensure the following control takes up the remainder of the space of the row
        flex-nowrap: ensures this row never wraps its contents
      */}
      <Col className="flex-grow-1" xs="auto">
        <Row className="align-items-baseline small-gutters flex-nowrap">
          {!!showRowCount && (
            <Col className="mb-1" xs="auto">
              <span>{renderItemCount(tableProps)}</span>
            </Col>
          )}
          <Col className="ml-auto" xs="auto">
            <Row className="align-items-baseline small-gutters">
              {!!loading && (
                <Col className="mb-1" xs="auto">
                  <LoadingSpinner inline size={2} />
                </Col>
              )}
              {!!error && (
                <Col className="mb-1" xs="auto">
                  <OverlayTrigger placement="top" overlay={<Tooltip>{error}</Tooltip>}>
                    <IoIosWarning size="2em" className="react-icon--inline"/>
                  </OverlayTrigger>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      {/*
        ensure the following controls are grouped together when they overflow to the next line:
        ml-auto: allows the overflowed line to take up the entire width by filling its left margin
        table-toolbar__buttons: adjusts inline baseline alignment for non-text bootstrap elements
      */}
      <Col className="ml-auto" xs="auto">
        <Row className="align-items-baseline justify-content-end small-gutters">
          {buttonGroups.length > 0 && buttonGroups.filter(Boolean).map((buttonGroup, index) => (
            <Col key={index} className="mb-1" xs="auto">
              <ButtonGroupContainer>
                <div className="table-toolbar__inputs">
                  <ButtonGroup>
                    {buttonGroup}
                  </ButtonGroup>
                </div>
              </ButtonGroupContainer>
            </Col>
          ))}
          {!!searchable && (
            <Col className="mb-1" xs="auto">
              <div className="table-toolbar__inputs">
                {renderSearchBar({...tableProps, onSearch})}
              </div>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );

};
