// Pass in an initial state and a series of action type handlers
export default function createReducer(initialState, handlers) {
  // Create a new function
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}
