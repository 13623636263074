
import thunkMiddleware from 'redux-thunk';

import apiMiddleware from '../lib/apiMiddleware';
import bugsnagMiddleware from '../lib/bugsnagMiddleware';
import googleAnalyticsMiddleware from '../lib/googleAnalyticsMiddleware';
import crossTabStorageMiddleWare from './crossTabStorageMiddleWare';
import trackMiddleware from '../lib/trackMiddleware';

const requiredMiddleware = [
  thunkMiddleware,
  apiMiddleware,
];

const optionalMiddleware = [
  trackMiddleware,
  googleAnalyticsMiddleware,
  crossTabStorageMiddleWare,
  bugsnagMiddleware,
];

// only load some middlewares in development and production
const usedMiddleware = process.env.NODE_ENV !== 'test'
  ? [...requiredMiddleware, ...optionalMiddleware]
  : requiredMiddleware;

export default usedMiddleware;
