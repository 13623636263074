
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Table from '../../../components/Table';

import { useComponentViewTracking } from '../../app/hooks';

import {
  fetchGroup,
  fetchOrganisationTokens,
} from '../actions';

import {
  getNearestOrganisationAncestorId,
  getOrganisationTokens,
} from '../selectors';

import {
  token,
  tokenDescription,
} from '../columns';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

import OrganisationTokenActions from '../components/OrganisationTokenActions';
import DeveloperCentreToolbar from '../components/DeveloperCentreToolbar';
import withNavigationGroupProps from '../components/withNavigationGroupProps';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc'
}];

const tokenActions = {
  dataField: 'actions',
  text: 'Actions',
  formatter: (value, {organisation_id, token, description} = {}) => (
    <OrganisationTokenActions
      organisationId={organisation_id}
      token={token}
      description={description}
    />
  )
};

const columns = [
  tokenDescription,
  token,
  tokenActions,
];

function OrganisationTokens({
  organisationId,
  apiTokens=[],
  groupId,
  group = { id: groupId },
  fetchOrganisationTokens,
  fetchGroup,
}) {

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  // memoise callback
  const renderHeader = useCallback(props => {
    return (
      <DeveloperCentreToolbar
        tableProps={props}
        loading={loading}
        organisationId={organisationId}
      />
    );
  }, [organisationId]);

  // organisationId changes if a user has access to multiple organisation
  // groups e.g. they are partner or super admin
  // whenever this changes get the relevant organisation tokens
  useEffect(() => {
    if (organisationId) {
      (async () => {
        setLoading(true);
        await fetchOrganisationTokens({ id: organisationId });
        setLoading(false);
      })();
    }
  }, [organisationId]);

  // when user changes to another organisation group e.g. they are
  // partner or super admin
  // look up details of that group
  // used to determine which organisationId to fetch tokens for
  useEffect( () => {
    if (group.id) {
      (async () => {
        await fetchGroup(group);
      })();
    }
  }, [group.id]);

  useComponentViewTracking('API Tokens', 'organisationId', {
    organisationId,
  });

  const localisedColumns = useLocalisedColumns(columns);
  return (
    <Container fluid>
      <Table
        renderHeader={renderHeader}
        data={apiTokens}
        keyField="token"
        defaultSorted={defaultSorted}
        columns={localisedColumns}
        noDataIndication={() => t('screens.organisations.organisation-tokens.no-data')}
        loading={loading}
      />
    </Container>
  );
}

const mapStateToProps = (state, { groupId }) => {
  const nearestOrganisationId = getNearestOrganisationAncestorId(state, groupId);
  return {
    groupId,
    organisationId: nearestOrganisationId,
    apiTokens: getOrganisationTokens(state, nearestOrganisationId),
  };
};
const mapDispatchToProps = {
  fetchOrganisationTokens,
  fetchGroup,
};

export default withNavigationGroupProps(
  connect(mapStateToProps, mapDispatchToProps)(OrganisationTokens)
);
