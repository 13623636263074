import { createSelector } from "reselect";
import { AppStoreState } from "../types";
import { AccountStatus, DomainKey, DomainStateProps, DomainDataType } from "./types";

export const getDomainData: (state: AppStoreState) => DomainDataType = createSelector(
  (state: AppStoreState) => state,
  (state) => state.domain.data,
);

export const getDomainState: (state: AppStoreState) => DomainStateProps = createSelector(
  (state: AppStoreState) => state,
  state => {
    const { loading, lastFetch, error } = state.domain;
    return {
      loading, lastFetch, error
    };
  }
);

export const getDomainDataByKey: (state: AppStoreState, key: DomainKey) => any = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, key: string) => key,
  (state, key) => {
    return state.domain.data[key as DomainKey];
  }
);

export const getAccountStatus: (state: AppStoreState) => AccountStatus = createSelector(
  (state: AppStoreState) => state,
  state => getDomainDataByKey(state, 'accountStatus')
);