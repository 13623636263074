import './AlarmsList.scss';
import tempImage1 from '../../images/Temperature/temp_1.png';
import tempImage2 from '../../images/Temperature/temp_2.png';
import tempImage3 from '../../images/Temperature/temp_3.png';
import tempImage4 from '../../images/Temperature/temp_4.png';
import tempImage5 from '../../images/Temperature/temp_5.png';
import tempImage6 from '../../images/Temperature/temp_6.png';
import tempImage7 from '../../images/Temperature/temp_7.png';
import tempImage8 from '../../images/Temperature/temp_8.png';
import tempImage9 from '../../images/Temperature/temp_9.png';
import tempImage10 from '../../images/Temperature/temp_10.png';

function calculateValueOfImage(temperature_condition) {
  if (!temperature_condition || temperature_condition === 0) {
    return 1;
  } else if (temperature_condition <= (1.0 / 3)) {
    return Math.ceil((temperature_condition / (1.0 / 3) * 3)) + 1;
  } else if (temperature_condition <= (2.0 / 3)) {
    return Math.ceil(((temperature_condition - (1.0 / 3)) / (1.0 / 3) * 3)) + 4;
  } else {
    return Math.ceil(((temperature_condition - (2.0 / 3)) / (1.0 / 3) * 3)) + 7;
  }
}

function getImage(calculatedValue) {
  switch(calculatedValue) {
    case 1:
      return tempImage1;
    case 2:
      return tempImage2;
    case 3:
      return tempImage3;
    case 4:
      return tempImage4;
    case 5:
      return tempImage5;
    case 6:
      return tempImage6;
    case 7:
      return tempImage7;
    case 8:
      return tempImage8;
    case 9:
      return tempImage9;
    case 10:
      return tempImage10;
    default:
      return tempImage1;
  }
}

function TemperatureImage({temperature_condition}) {

  if ( isNaN(temperature_condition) ) return <>N/A</>;
  const calculatedValue = Number.isInteger(temperature_condition) && temperature_condition >= 1 && temperature_condition <= 10 ? // No need to do calculation if the given value is just 1..10.
    temperature_condition :
    calculateValueOfImage(temperature_condition);
  return (
    <img src={getImage(calculatedValue)} alt={temperature_condition} id="temperatureImage" />
  );
}

export default TemperatureImage;
