
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state, { match }) => {

  // get vars from path location
  const devicePathString = match && match.params && match.params['id'];
  const devicePathId = parseInt(devicePathString) || undefined;

  return {
    // note that the device id's existence/authorisation isn't validated
    deviceId: devicePathId,
  };
};

export default compose(
  // pass router props to redux to make "match" available
  withRouter,
  connect(mapStateToProps),
);
