import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchUsers } from './actions';
import { getUserListState } from './selectors';

// fetch users list only if users list has never been fetched before
export function useFetchUsersOnlyOnce() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch((dispatch, getState) => {
      const state = getState();
      // check if user list has never been fetched before
      const { loading, lastFetch } = getUserListState(state);
      if (!lastFetch && !loading) {
        dispatch(fetchUsers());
      }
    });
  }, [dispatch, fetchUsers]);

}
