import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLocalUserPreference } from "../actions";
import { getUser, getUserPreference } from '../selectors';
import { SENSOR_TYPE } from '../../equipment/constants';

type HomeViewValue = 'standard' | 'plant' | 'management';
type EquipmentListViewValue = typeof SENSOR_TYPE;
export type ColumnsValue = {
  visible: Record<string, boolean>,
  order: Record<string, number>,
};
type LocalPreferenceValue = HomeViewValue | EquipmentListViewValue | ColumnsValue;

export default function useLocalPreference({key, defaultValue}: { key: string, defaultValue?: LocalPreferenceValue }) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const preference: LocalPreferenceValue = useSelector(state => getUserPreference(state, key)) || defaultValue;
  const setPreference = useCallback((data: LocalPreferenceValue) => {
    user && dispatch(setLocalUserPreference(user, key, data));
  }, [user, key, dispatch]);

  return { preference, setPreference };
}