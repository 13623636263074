
import { useState } from 'react';

const counters = {};
export default function useUniqueId(prefix='element') {
  const [id] = useState(() => {
    // increment counter and return for use
    counters[prefix] = counters[prefix] || 0;
    counters[prefix] = counters[prefix] < Number.MAX_SAFE_INTEGER
      ? counters[prefix] + 1
      : 1;
    // add prefix to counter
    return `${prefix}-${counters[prefix]}`;
  });
  return id;
}
