import { useTranslation } from 'react-i18next';

import { useGroupRecentEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, initialAlarmLink, alarmTypeBadge, eventDetails } from './columns';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';

const columns = [
  equipmentNameLink,
  initialAlarmLink,
  alarmTypeBadge,
  eventDetails,
];

function RecentEvents() {
  const { t } = useTranslation();
  const groupRecentEventsReport = useGroupRecentEventsReport();
  const localisedColumns = useLocalisedColumns(columns);
  if(!groupRecentEventsReport) return null;

  const {_state_: { loading, error } = {}, items = []} = groupRecentEventsReport || {};
  return (
    <HomeCard
      header={t("home.most-recent-events")}
      headerLink={{to: '/events', text: t('home.all-recent-events')}}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={localisedColumns}
        condensed
      />
    </HomeCard>
  );
}

export default RecentEvents;
