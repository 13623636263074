import { memo, useEffect, useMemo, useCallback, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { GrDrag } from "react-icons/gr";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

import FormModal from "./FormModal";
import useLocalPreference from "../modules/user/hooks/useLocalPreference";
import "./columnsManager.scss";
import { flattenObject } from "../lib/utils";

function ColumnsManager({columns: columnsProp, tableName, ...props}) {
  const { t } = useTranslation();
  const [columns, setColumns] = useState(columnsProp);
  const {
    preference: columnsPreference,
    setPreference: setColumnsPreference,
  } = useLocalPreference({
    key: tableName,
  });

  const defaultVisiblePreference = useMemo(() => {
    const defaultVisibleValues = {};
    for(const col of columnsProp) {
      defaultVisibleValues[col.dataField] = col.hidden === true ? false : true;
    }
    return defaultVisibleValues;
  }, [columnsProp]);

  const defaultOrderPreference = useMemo(() => {
    const defaultOrderValues = {};
    for(let i = 0; i < columnsProp.length; i++) {
      defaultOrderValues[columnsProp[i].dataField] = i;
    }
    return defaultOrderValues;
  }, [columnsProp]);
  // console.log(columnsProp, defaultVisiblePreference, defaultOrderPreference);

  const { register, handleSubmit, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: columnsPreference?.visible || {},
  });

  const resetColumnsPreferenceForm = useCallback(() => {
    if(columnsPreference?.visible) {  // Reset form as per local preference.
      reset({...defaultVisiblePreference, ...columnsPreference.visible});
    }
    if(columnsPreference?.order) {  // Reorder the columns as per local preference.
      const orderedColumns = [];
      let offset = 0;
      for(const col of columnsProp) {
        const index = columnsPreference.order[col.dataField];
        if(index >= 0) {
          orderedColumns[index + offset] = col;
        } else {
          orderedColumns.push(col);
          offset++;
        }
      }
      setColumns(orderedColumns.filter(col => !!col));
    }
  }, [columnsPreference, columnsProp, defaultVisiblePreference]);

  useEffect(() => {
    if(!columnsPreference) {  // Set up initial values to local preference.
      setColumnsPreference({
        visible: defaultVisiblePreference,
        order: defaultOrderPreference,
      });
    }
  }, [columnsProp, tableName]);

  useEffect(() => {
    resetColumnsPreferenceForm();
  }, [columnsPreference]);

  useEffect(() => {
    setColumns(columnsProp);
  }, [columnsProp]);

  const handleShowModal = useCallback(() => {
    resetColumnsPreferenceForm();
  }, [resetColumnsPreferenceForm]);

  const onSubmit = data => {
    const orderPreference = {};
    for(let i = 0; i < columns.length; i++) {
      orderPreference[columns[i].dataField] = i;
    }
    setColumnsPreference({
      visible: flattenObject(data),
      order: orderPreference,
    });
  };

  const [colSelected, setColSelected] = useState(); // The index of selected column.
  const [colTarget, setColTarget] = useState(); // The index of target column.
  const [isDragging, setIsDragging] = useState(false);

  const handleOrderingColumns = useCallback(() => {
    if(colSelected === colTarget || colSelected === undefined || !columns[colSelected] || colTarget === undefined || !columns[colTarget]) return;
    const newColumns = [...columns];
    const temp = newColumns[colTarget];
    newColumns[colTarget] = newColumns[colSelected];
    newColumns[colSelected] = temp;
    setColumns(newColumns);
  }, [colSelected, colTarget, columns]);

  const handleResetColumns = useCallback(() => {
    reset(defaultVisiblePreference);  // Reset columns visibility.
    const orderedColumns = [];
    for(const col of columnsProp) {
      const index = defaultOrderPreference[col.dataField];
      if(index >= 0) orderedColumns[index] = col;
    }
    setColumns(orderedColumns); // Reset columns order.
  }, [columnsProp, defaultOrderPreference, defaultVisiblePreference]);

  return (
    <FormModal
      header={t('columns-manager.edit-columns')}
      body={t('columns-manager.select-columns')}
      confirmText={t('columns-manager.confirm')}
      className="equipment-list-edit-columns-modal"
      valid={true}
      form={
        <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">{
          columns.map((col, index) => {
            return (
              <div
                key={col.dataField}
                className={
                  "d-flex align-items-center draggable-item " +
                  (colSelected === index ? "draggable-hovered " : "") +
                  (colTarget === index ? "draggable-targeted" : "")
                }
                draggable="true"
                onDragStart={e => {
                  e.dataTransfer.effectAllowed = 'move';
                  if(!isDragging) e.preventDefault(); }}
                onDragEnter={e => { e.preventDefault(); setColTarget(index); }}
                onDragEnd={() => { setIsDragging(false); handleOrderingColumns(); setColTarget(undefined); }}
                onDragOver={e => e.preventDefault()}
              >
                <div
                  aria-hidden="true"
                  className="drag-icon pr-3"
                  onMouseDown={() => { setIsDragging(true); }}
                  onMouseUp={() => { setIsDragging(false); }}
                  onFocus={() => {}}
                  onMouseOver={() => setColSelected(index)}
                  onMouseLeave={() => { if(!isDragging) setColSelected(undefined); }}
                >
                  <GrDrag size={18} />
                </div>
                <Form.Group
                  as={Row}
                  controlId={col.dataField}
                  className="mb-0 flex-grow-1"
                >
                  <Form.Label
                    column
                    xs="6"
                    className="d-flex align-items-center"
                  >
                    <span>{col.text}</span>
                  </Form.Label>
                  <Col xs="6" className="d-flex align-items-center justify-content-start">
                    <Form.Check type="checkbox" disabled={col.__canChangeVisibility === false ? true : false} {...register(col.dataField)} />
                  </Col>
                </Form.Group>
              </div>
            );
          })
        }
        </Form>
      }
      onShow={handleShowModal}
      onReset={handleResetColumns}
      resetText={t('columns-manager.default')}
      resetButtonProps={{style: { marginRight: 'auto' }}} // Keep the reset button at the left.
    >
      <Button {...props}>{t('columns-manager.edit-columns')}</Button>
    </FormModal>
  );
}

export function reorderColumns(columnsPreference, columns) {
  if(!columnsPreference) return columns;
  const { visible, order } = columnsPreference;
  const orderedColumns = [];
  let offset = 0;
  for(const col of columns) {
    const { dataField } = col;
    if(visible[dataField] !== false) {
      const index = order[dataField];
      if(index >= 0) {
        orderedColumns[index + offset] = col;
      } else {
        orderedColumns.push(col);
        offset++;
      }
    }
  }
  return orderedColumns.filter(col => !!col);
}

export default memo(ColumnsManager);
