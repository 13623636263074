import { combineReducers } from 'redux';

import app from './app/reducers';
import device from './equipment/reducers';
import gateway from './gateway/reducers';
import alarm from './alarm/reducers';
import user from './user/reducers';
import organisation from './organisation/reducers';
import notification from './notification/reducers';
import billing from './billing/reducers';
import domain from './domain/reducers';

export default combineReducers({
  app,
  device,
  gateway,
  alarm,
  user,
  organisation,
  notification,
  billing,
  domain,
});
