import { Link } from "react-router-dom";
import moment from "moment";
import { IoIosCog } from "react-icons/io";
import { headerFormatter, percentageNumberFormatter, relativeDateFormatter } from "../table/formatters";
import { timeSortFunc } from "../table/utils";
import VolumeImage from "../../modules/alarm/VolumeImage";
import ToneImage from "../../modules/alarm/ToneImage";
import TemperatureImage from "../../modules/alarm/TemperatureImage";
import ColourBadge from "../ColourBadge";
import { formatCurrency, formatNumber } from "../lib/utils";
import { rms, temperature as temperatureCol } from '../../modules/equipment/columns';

export { vibrationRms } from '../../modules/equipment/columns';

export const equipmentNameLink = {
  text: 'Equipment Name',
  dataField: 'equipment_name',
  headerFormatter,
  formatter: (value, { device_id }) => (
    <Link to={`/equipment/${device_id}`}>
      {value}
    </Link>
  ),
  sort: true,
};

export const initialAlarmLink = {
  text: 'Initial Alarm',
  dataField: 'date',
  headerFormatter,
  formatter: (value, { alarm_id }, index, { localise } = {}) => (
    <Link to={`/alarms/${alarm_id}`}>
      {relativeDateFormatter(localise instanceof Function ? localise(value): value) || "N/A"}
    </Link>
  ),
  sort: true,
  sortFunc: timeSortFunc
};

export const alarmTypeBadge = {
  text: 'Alarm Type',
  dataField: 'alarm_type',
  headerFormatter,
  formatter: (value, { button_colour }) => (
    <ColourBadge colour={button_colour}>{value}</ColourBadge>
  ),
  sort: true,
};

export const eventDetails = {
  text: 'Event Details',
  dataField: 'details',
  headerFormatter,
  formatter: (value) => {
    if(value.description) return value.description;
    return (
      <div style={{whiteSpace: 'nowrap'}}>
        <VolumeImage rms_value={value.volume} />{' '}
        <ToneImage vibration_condition={value.tone} />{' '}
        <TemperatureImage temperature_condition={value.temperature} />
      </div>
    );
  },
};

export const initialAlarmBadge = {
  text: 'Initial Alarm',
  dataField: 'date',
  headerFormatter,
  formatter: (value, { button_colour }) => (
    <ColourBadge colour={button_colour}>{relativeDateFormatter(value)}</ColourBadge>
  ),
  sort: true,
  sortFunc: timeSortFunc,
};

export const acknowledgedBy = {
  text: 'Acknowledged by',
  dataField: 'acknowledged_by',
  headerFormatter,
  sort: true,
};

export const latestComments = {
  text: 'Latest comments',
  dataField: 'comments',
  headerFormatter,
  formatter: (value) => (
    <div className="text-wrap home__comments_col">{value}</div>
  ),
  sort: true,
};

export const identifiedIssueBadge = {
  text: 'Identified issue',
  dataField: 'issue',
  headerFormatter,
  formatter: (value, { button_colour }) => (
    <ColourBadge colour={button_colour}>{value}</ColourBadge>
  ),
  sort: true,
};

export const validatedBy = {
  text: 'Validated by',
  dataField: 'validated_by',
  headerFormatter,
  sort: true,
};

export const pendingEventStatusBadge = {
  text: 'Pending event status',
  dataField: 'pending_status',
  headerFormatter,
  formatter: (value, { button_colour }) => (
    <ColourBadge colour={button_colour}>{value}</ColourBadge>
  ),
  sort: true,
};

export const scheduledDate = {
  text: 'Scheduled date',
  dataField: 'scheduled_date',
  headerFormatter,
  formatter: (value) => (moment(value).format('DD/MM/YYYY')),
  sort: true,
  sortFunc: timeSortFunc,
};

export const scheduledDateBadge = {
  ...scheduledDate,
  formatter: (value, { button_colour }) => (
    <ColourBadge colour={button_colour}>{moment(value).format('DD/MM/YYYY')}</ColourBadge>
  )
};

export const identifiedIssue = {
  text: 'Identified issue',
  dataField: 'issue',
  headerFormatter,
  formatter: (value, { button_colour }) => (
    <ColourBadge colour={button_colour}>{value}</ColourBadge>
  ),
  sort: true,
};

const eventImpactAmountColumn = (currency = 'AUD') => ({
  headerFormatter,
  sort: true,
  align: 'right',
  headerAlign: 'right',
  formatter: value => {
    return !!value || typeof value === 'number' ? (
      <span className={((value < 0) ? 'text-danger' : '') + ' text-nowrap'}>
        {formatCurrency(value, currency, 0)}
      </span>
    ) : null;
  }
});

const eventImpactHourColumn = {
  headerFormatter,
  sort: true,
  align: 'right',
  headerAlign: 'right',
  formatter: value => {
    return !!value || typeof value === 'number' ? (
      <span className={((value < 0) ? 'text-danger' : '') + ' text-nowrap'}>
        {formatNumber(Math.abs(value))} hr{Math.abs(value) === 1 ? '' : 's'}
      </span>
    ) : null;
  }
};

export const estHoursLost = {
  ...eventImpactHourColumn,
  text: 'Est Prod Lost',
  dataField: 'est_hrs_lost',
};

export const estHoursLostAvoided = {
  ...eventImpactHourColumn,
  text: 'Est Prod Losses Avoided',
  dataField: 'est_hrs_lost_avoided',
};

export const estEventCosts = currency => ({
  ...eventImpactAmountColumn(currency),
  text: 'Est Event Costs',
  dataField: 'est_event_costs',
});
export const estEventCostsAvoided = currency => ({
  ...eventImpactAmountColumn(currency),
  text: 'Est Avoided Costs',
  dataField: 'est_event_costs_avoided',
});
export const estEventCostsPrevented = currency => ({
  ...eventImpactAmountColumn(currency),
  text: 'Est Prevented Losses',
  dataField: 'est_event_costs_prevented',
});

export const lastDataProcessed = {
  text: 'Last data processed',
  dataField: 'date',
  headerFormatter,
  formatter: (value, { device_id }, index, { localise } = {}) => (
    <Link to={`/equipment/${device_id}`}>
      {relativeDateFormatter(localise instanceof Function ? localise(value) : value) || "N/A"}
    </Link>
  ),
  sort: true
};

export const vibration = {
  ...rms,
  dataField: 'vibration',
};

export const temperature = temperatureCol;

export const utilisation = {
  text: 'Utilisation',
  dataField: 'utilisation',
  headerFormatter,
  icon: <IoIosCog size="1.4em" />,
  tooltip: 'Utilisation',
  formatter: percentageNumberFormatter,
  sort: true,
};

export const lastDataHeard = {
  text: 'Last data processed',
  dataField: 'fitmachine_last_heard',
  headerFormatter,
  formatter: (value, { device_id }) => (
    <Link to={`/equipment/${device_id}`}>
      {relativeDateFormatter(value) || "N/A"}
    </Link>
  ),
  sort: true
};