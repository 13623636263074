
import React from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { DropdownTopWindowButton } from '../../../components/DropdownButton';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosCreate, IoIosCog } from 'react-icons/io';

import Private from '../../../components/Private';
import ArchiveOrganisationModal, {
  minViewUserType as minUserType,
  minViewOrgType as minOrgType,
} from '../screens/ArchiveOrganisationModal';

import { getOrganisation } from '../selectors';
import { isAuthorised } from '../../user/selectors';
import { useTranslation } from 'react-i18next';

function OrganisationDropdown({
  organisation={},
  currentOrganisation,
  userCanArchive,
  userCanEdit,
}) {
  const { t } = useTranslation();
  // don't show this at all for regular users
  if (!userCanEdit) {
    return null;
  }
  // return dropdown
  return (
    <ButtonGroup>
      <LinkContainer to={`/organisations/${organisation.id}/edit`}>
        <Button variant="outline-secondary">
          <IoIosCreate size="1.2em" /> <span>{t('Edit')}</span>
        </Button>
      </LinkContainer>
      <DropdownTopWindowButton as={ButtonGroup} menuAlign="right" variant="outline-secondary" title="">
        <Private minUserType="Admin">
          <LinkContainer to={`/organisations/${organisation.id}/preferences/edit`}>
            <Dropdown.Item>
              <IoIosCog size="1.2em" />&nbsp;
              <span>
                {t('components.organisations.organisations-dropdown.update-preferences')}
              </span>
            </Dropdown.Item>
          </LinkContainer>
        </Private>
        {userCanArchive && organisation !== currentOrganisation && (
          <ArchiveOrganisationModal organisationId={organisation.id} />
        )}
      </DropdownTopWindowButton>
    </ButtonGroup>
  );
}

const mapStateToProps = (state, { organisationId }) => {
  const organisation = organisationId && getOrganisation(state, organisationId);
  const currentOrganisation = getOrganisation(state);
  return {
    organisation,
    currentOrganisation,
    userCanArchive: isAuthorised(state, { minUserType, minOrgType }),
    userCanEdit: isAuthorised(state, { minUserType: 'Admin' }),
  };
};

export default connect(mapStateToProps)(OrganisationDropdown);
