import React from 'react';

export default function IoIosFactory({ className, size="1em" }) {
  return (
    // add SVG similar to BluePrint icons
    <svg
      data-icon="factory"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="currentColor"
      className={className}
    >
      <desc>factory</desc>
      {/* path modified from base: https://thenounproject.com/term/factory/91860/ */}
      {/* (made the stack and roof higher, spaced windows more, rounded numbers) */}
      <path d="M90,80V10H75.25V41L53.5,28V41L31.75,28V41L10,28V80H5V85h90v-5H90z M30,72h-9v-9h9z M50,72h-9v-9h9z M70,72h-9v-9h9z z"></path>
    </svg>
  );
}
