import React, { useState, useEffect } from 'react';

import { DownloadDataURILink } from '../DownloadDataLink';

// note: ensure that the echarts instance passed has:
// { renderer: 'canvas' } if you want to save .png or .jpg files or
// { renderer: 'svg' } if you want to save .svg files

// transform chart prop to a dataURI prop for Download use
export default function DownloadChartImageLink({
  // build from required echart instance
  chart,
  // by default re-compute this export whenever this component renders
  renderId = Math.random(),
  // add echart getDataURL options
  // https://web.archive.org/web/20200414184341/https://echarts.apache.org/en/api.html#echartsInstance.getDataURL
  type = 'png', // default to lossless .png format
  pixelRatio = 2, // increase the exported image size by default to twice height
  backgroundColor = '#fff', // use a white background on export
  excludeComponents,
  // add custom options
  name = 'image',
  ...downloadProps
}) {

  // set loading state for the dataURI while it is generated
  const [loading, setLoading] = useState(true);
  const [dataURI, setDataURI] = useState();

  useEffect(() => {
    // reset the dataURI
    const process = !!chart;
    setDataURI();
    setLoading(process);
    if (process) {
      // wait a tick (otherwise the loading state will never be rendered)
      const timeout = setTimeout(() => {
        // process asynchronously
        const dataURI = chart.getDataURL({
          type,
          pixelRatio,
          backgroundColor,
          excludeComponents,
        });
        // ensure echarts generated the correct type of image
        if (dataURI && dataURI.startsWith(`data:image/${type};base64,`)) {
          setDataURI(dataURI);
        }
        setLoading(false);
      }, 0);
      // cleanup timeout
      return () => clearTimeout(timeout);
    }
  // update the Data URI link when the chart name changes
  // even though this isn't used in the calculation,
  // this represents when the chart data has changed
  // the `chart` var here is the chart instance, which doesn't change
  // as we change the view of the chart
  }, [renderId, chart, type, pixelRatio, backgroundColor, excludeComponents]);

  return (
    <DownloadDataURILink
      // pass given props
      {...downloadProps}
      // add calculated props
      name={name}
      loading={loading}
      dataURI={dataURI}
    />
  );
}
