
import { useEffect, useRef, useState } from 'react';

export function useFocusableRef(initialRef) {

  const [focus, setFocus] = useState(false);
  const ref = useRef(initialRef);

  // ensures element will grab focus once it renders
  useEffect(() => {
    if (focus) {
      // wait a tick to focus (may not be focusable immediately on effect)
      setTimeout(() => {
        if (ref.current) {
          ref.current.focus();
          // allow selectable inputs to select all text
          if (ref.current.select) {
            ref.current.select();
          }
        }
      }, 0);
    }
  }, [focus]);

  return [ref, setFocus];
}
