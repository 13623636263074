
import React from 'react';
import { connect } from 'react-redux';

import { getUserPreferenceValue } from '../../modules/user/selectors';
import { getNormalisedPropsSelector } from './utils/selectorUtils';
import { FormattedValue } from './utils/displayUtils';
import { convertCelsiusToFarenheit, convertFarenheitToCelsius } from './utils/converterUtils';

const displayProps = {
  default: {
    units: ' °C',
    digits: 2,
  },
  US: {
    units: ' °F',
    digits: 2,
    convert: convertCelsiusToFarenheit,
    convertBack: convertFarenheitToCelsius,
  },
};

const mapStateToProps = state => ({
  ...displayProps.default,
  ...displayProps[getUserPreferenceValue(state, 'units_system')],
  ...displayProps[getUserPreferenceValue(state, 'units_temperature')],
});

// export selectors
export const getUserDisplayPreference = getNormalisedPropsSelector(mapStateToProps);

const ConnectedValue = connect(mapStateToProps)(FormattedValue);

// export a display component to render the preferred value with default units displayed
export default props => <ConnectedValue {...props} />;
