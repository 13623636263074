
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  IoIosCreate,
  IoIosTrash,
  IoIosPulse,
} from 'react-icons/io';

import {
  deleteStreamingConfiguration,
  testStreamingConfiguration,
} from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import { addToast } from '../../../components/Toaster';

import EditStreamingConfigurationFormModal from './EditStreamingConfigurationFormModal';

function StreamingConfigurationActions({
  organisationId,
  streamId,
  deleteStreamingConfiguration,
  testStreamingConfiguration,
}) {

  const { t } = useTranslation();

  const submitTest = async (organisationId, streamId) => {
    const response = await testStreamingConfiguration({ id: organisationId }, streamId);
    if (response && response.data && response.data.status_test_passed !== undefined) {
      const {
        id,
        description,
        status_test_passed: result,
      } = response.data;
      // show result to user
      addToast({
        variant: result ? 'success' : 'danger',
        header: t('screens.organisations.streaming-configuration.test-result', {
          id: id,
          description: description,
          result: result ? 'success' : 'failure',
        }),
      });
    }
  };

  return (
    <ButtonGroup>
      <EditStreamingConfigurationFormModal
        organisationId={organisationId}
        streamId={streamId}
      >
        <Button
          size="md"
          variant="outline-secondary"
        >
          <IoIosCreate size="1.2em" /> {t('Edit')}
        </Button>
      </EditStreamingConfigurationFormModal>
      <Button
        size="md"
        variant="outline-secondary"
        onClick={() => submitTest(organisationId, streamId)}
      >
        <IoIosPulse size="1.2em" /> {t('test')}
      </Button>
      <ConfirmModal
        header={t('screens.organisations.streaming-configuration.delete-streaming')}
        body={t('common.cannot-be-undone')}
        confirmText={t('common.delete')}
      >
        <Button
          size="md"
          variant="danger"
          onClick={useCallback( () => {
            deleteStreamingConfiguration({ id: organisationId }, streamId);
          }, [organisationId, streamId])}
        >
          <IoIosTrash size="1.2em" /> {t('common.delete')}
        </Button>
      </ConfirmModal>
    </ButtonGroup>
  );
}

const mapDispatchToProps = {
  deleteStreamingConfiguration,
  testStreamingConfiguration,
};

export default connect(null, mapDispatchToProps)(StreamingConfigurationActions);
