import { Button } from 'react-bootstrap';
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getUserToken } from '../selectors';
import { getConfirmOTP } from '../actions';

export function GetConfirmationOtp(props) {

  const {
    getConfirmOTP,
    userToken,
  } = props;

  const { t } = useTranslation();

  return (
    <Button
      size="sm"
      variant="success"
      onClick={() => getConfirmOTP(userToken)}
    >
      {t('components.user.get-confirmation-otp.send-another-otp')}
    </Button>
  );
}

const mapStateToProps = (state) => {
  return {
    userToken: getUserToken(state),
  };
};

const mapDispatchToProps = {
  getConfirmOTP,
};

export default connect(mapStateToProps, mapDispatchToProps)(GetConfirmationOtp);
