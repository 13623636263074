
import React from 'react';
import appStoreBadge from '../images/badge-app-store.svg';
import googlePlayBadge from '../images/badge-google-play.svg';
import "./storeButtons.scss";

const appStoreLink = 'https://apps.apple.com/au/app/fitmachine/id1241838528';
const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.movusmobileapp';

function AppStoreButton() {
  return (
    <a
      className="store-badges__app-store"
      href={appStoreLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={appStoreBadge} alt="Download on the App Store" />
    </a>
  );
}

function PlayStoreButton() {
  return (
    <a
      className="store-badges__google-play"
      href={googlePlayLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={googlePlayBadge} alt="Get it on Google Play" />
    </a>
  );
}

export default function StoreButtons({ inline=false }) {
  return (
    <div className={`store-badges ${inline ? 'store-badges--inline' : ''}`}>
      <AppStoreButton />
      {!inline && <br />}
      <PlayStoreButton />
    </div>
  );
}
