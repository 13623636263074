
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import LoadingSpinner from '../../../components/LoadingSpinner';

import { fetchDevices } from '../actions';
import { getDevices, getDeviceListState } from '../selectors';

const matchDeviceToQuery = (query={}) => {

  // check match conditions provided in query parameters
  const matchConditions = Object.entries(query);
  if (matchConditions.length) {

    // attempt to find a matching item
    return item => {
      // item must pass all conditions
      return matchConditions.every(([key, valueOrEmptyString]) => {

        // alias empty string value as true (eg. '/?virtual' is shorthand for '/?virtual=true')
        const value = `${valueOrEmptyString}` || 'true';

        // match virtual FMs by a different criteria
        if (key === 'virtual' && `${value}` === 'true') {
          return `${item.serial}`.startsWith('FE:FF:FF:');
        }

        // for all other keys:
        // find item value (may be under an alias field)
        const itemValue = item[key] !== undefined
          ? item[key]
          : item[`equipment_${key}`];

        // if there is no equipment property to match on, don't match on this query parameter
        if (itemValue === undefined) {
          return true;
        }
        // otherwise do a case insensitive string match
        else {
          return `${itemValue}`.trim().toLowerCase() === `${value}`.trim().toLowerCase();
        }
      });
    };
  }
  // if there are no match conditions, do not return a match
  else {
    return () => false;
  }
};

function EquipmentRouter({
  fetchDevices,
  activeDevices = [],
  archivedDevices = [],
  deviceListState: {
    loading: fetching,
    lastFetch,
    error: fetchError,
  } = {},
  location: {
    query,
    search='',
  } = {},
}) {

  // fetch devices if the device list has not yet been fetched without error
  useEffect(() => {
    if (!fetching && !lastFetch) {
      // fetch both active and archived devices
      fetchDevices();
      fetchDevices({ filter: 'archived' });
    }
  }, [fetching, lastFetch]);

  // show device list loading state
  if (fetching || (!lastFetch && !fetchError)) {
    return (
      <div className="m-auto text-center">
        <div className="mb-3">Finding device...</div>
        <LoadingSpinner inline />
      </div>
    );
  }

  // check match conditions provided in query parameters
  if (query) {

    // attempt to find a matching item
    const allDevices = [...activeDevices, ...archivedDevices];
    const found = allDevices.find(matchDeviceToQuery(query));

    // redirect user to matched item found, and pass query parameters down
    // (may include AppCues tracker)
    if (found) {
      return (
        <Redirect to={`/equipment/${found.id}${search}`} />
      );
    }
  }

  return (
    <Redirect to={`/equipment/list${search}`} />
  );
}

const mapStateToProps = state => ({
  activeDevices: getDevices(state),
  archivedDevices: getDevices(state, { archived: true }),
  deviceListState: getDeviceListState(state),
});
const mapDispatchToProps = { fetchDevices };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentRouter)
);
