import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { formatTableColumn } from '../lib/formatTableColumn';

export default function useLocalisedColumns(columns) {
  const { t } = useTranslation();

  // Check type as e.g. equipment/columns.js exports mm2 where text/tooltip are React components.
  const localisedColumns = useMemo(() => columns.map(col => ({
    ...col,
    text: col.text ? (typeof col.text === 'string' ? t(formatTableColumn(col.text)) : col.text) : undefined,
    tooltip: col.tooltip ? (typeof col.tooltip === 'string' ? t(formatTableColumn(col.tooltip)) : col.tooltip) : undefined,
    formatExtraData: {
      ...col.formatExtraData,
      localise: value => moment(value).isValid() ? moment(value).local() : value,
    }
  })), [columns, t]);

  return localisedColumns;
}
