
import React from 'react';
import { Col, Row, Nav, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import { IoIosChatboxes } from 'react-icons/io';

import OrganisationDropdown from './OrganisationDropdown';
import withNavigationOrganisationProps from './withNavigationOrganisationProps';

function OrganisationNavbar({ organisationId, organisationIsNew }) {
  const { t } = useTranslation();
  if (organisationIsNew) {
    return null;
  }
  return (
    <Nav as="ul" className="flex-grow-1">
      <Row className="small-gutters flex-grow-1">
        <Col xs="auto">
          <Row className="small-gutters">
            <Col xs="auto" className="mb-1">
              <LinkContainer to={`/organisations/${organisationId}`} exact>
                <Button size="md" variant="outline-secondary">
                  <IoIosChatboxes size="1.2em" /> <span>{t('common.activity')}</span>
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
        <Col xs="auto" className="ml-auto">
          <Row className="small-gutters">
            <Col xs="auto" className="mb-1">
              <OrganisationDropdown organisationId={organisationId} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Nav>
  );
}

export default withNavigationOrganisationProps(OrganisationNavbar);
