import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiRequestCanceller } from '../lib/utils';

type ConfigProps = {
  skipFirstTime?: boolean;
  interval?: number;
  cancellable?: boolean;
};

const MAX_WAITING_TIME = 30*1000;
const TIMEOUT = 'TIMEOUT';

function wait(timeout: number) {
  return new Promise(function(resolve, reject) {
    setTimeout(resolve, timeout, TIMEOUT);
  });
};

export const usePoll = (handler: Function, deps: any[] = [], config: ConfigProps = {}) => {
  const {
    skipFirstTime = false,
    interval = 1000 * 60,
    cancellable = false,
  } = config;
  const dispatch = useDispatch();
  return useEffect(() => {
    let killed = false;
    let timeout = 0;
    let canceller: ApiRequestCanceller;
    async function poll(skipFirstTime: boolean) {
      if(killed) return;
      if(cancellable) canceller = new ApiRequestCanceller();
      if(!skipFirstTime) {
        try {
          const result = await Promise.race([handler(canceller), wait(MAX_WAITING_TIME)]);
          if(result === TIMEOUT) {
            throw new Error(result);
          }
        } catch(e) {}
      }
      timeout = setTimeout(poll, interval);
    }
    poll(skipFirstTime);
    return () => {
      killed = true;
      clearTimeout(timeout);
      cancellable && dispatch(canceller?.cancel());
    };
  }, [...deps, skipFirstTime, interval, cancellable]);
};