import React, { forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'quill-mention';

import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import "./editor.scss";

const QuillEditor = forwardRef(({ mentionsList = [], readOnly, defaultValue }, ref) => {

  const atValues = mentionsList
    .sort((a, b) => a.name > b.name ? 1 : -1)
    .map((value, index) => ({
      id: index,
      value: `${value.mention_name}`,
      name: value.name,
      mention_name: value.mention_name,
    }));

  const modules = {
    mention: mentionsList && mentionsList.length > 0 && {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: ["@"],
      source: function(searchTerm, renderList, mentionChar) {
        const values = mentionChar === '@' ? atValues : [];
        if(searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = values.filter(value => value.name.toLowerCase().includes(searchTerm.toLowerCase()));
          renderList(matches, searchTerm);
        }
      },
      renderItem: function(item) {
        // https://www.npmjs.com/package/quill-mention/v/4.0.0#upgrade-notes
        // As of 4.0.0, HTML embedded in value will no longer be rendered, due to potential XSS
        // issues. Instead, to adjust rendering of list items, you can return a class implementing
        // the Node interface which will be treated as a sanitized DOM node, and in order to
        // customize blot rendering, you will need to create a custom mention blot.
        // PR with changes to v4.0.0:
        // https://github.com/quill-mention/quill-mention/pull/341#pullrequestreview-1618905964
        // https://github.com/quill-mention/quill-mention/pull/341/files -> docs/index.html:157-160
        const span = document.createElement('span');
        span.appendChild(document.createTextNode('@'));
        const bold = document.createElement('b');
        bold.appendChild(document.createTextNode(item.mention_name));
        span.appendChild(bold);
        span.appendChild(document.createTextNode(` ${item.name} `));
        return span;
      },
      mentionListClass: "editor-mention-list",
      mentionContainerClass: "editor-mention-list-container",
    },
  };

  return (
    <ReactQuill
      style={{ width: '100%' }}
      ref={ref}
      theme="snow"
      value={defaultValue || ''}
      modules={modules}
      readOnly={!!readOnly}
    >
      <div className="editor" />
    </ReactQuill>
  );
});

export default QuillEditor;
