import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserPreferences, submitUserPreferences } from "../../user/actions";
import { getUser, getUserPreferenceValue, getUserTag } from "../../user/selectors";

function useDeviceAxisOptions(deviceId, { fetch = false } = {}) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const unit = useSelector(state => getUserPreferenceValue(state, 'units_system'));
  const key = `${deviceId}-${unit}`;
  const axisOptions = useSelector(state => getUserTag(state, 'axis_options'));

  const updateAxisOptions = useCallback((options) => {
    if(!key) return;
    return dispatch(submitUserPreferences(user, {
      axis_options: {
        ...axisOptions,
        [key]: options
      }
    }));
  }, [key, axisOptions]);

  const fetchUserAxisOptions = useCallback(() => {
    dispatch(fetchUserPreferences(user));
  }, [fetchUserPreferences, user]);

  useEffect(() => {
    if(fetch) {
      fetchUserAxisOptions();
    }
  }, []);

  return {
    axisOptions: axisOptions && axisOptions[key],
    updateAxisOptions,
  };
}

export default useDeviceAxisOptions;