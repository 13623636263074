import { useSelector, shallowEqual } from 'react-redux';
import { getGroup, getActiveGroupNode, getGroupLoadingState, getActiveGroupId, getOrganisationGroup } from '../selectors';

export default function useGroup() {
  const activeGroupId = useSelector(getActiveGroupId);
  const organisationGroup = useSelector(getOrganisationGroup);
  const groupId = activeGroupId || organisationGroup?.id;

  const activeGroupNode = useSelector(getActiveGroupNode, shallowEqual) || {};
  const group = useSelector(state => getGroup(state, groupId), shallowEqual) || {};
  const loadingState = useSelector(state => getGroupLoadingState(state, groupId), shallowEqual) || {};

  return {
    group: {
      id: activeGroupNode.id,
      group_name: activeGroupNode.name,
      group_type: activeGroupNode.type,
      ...group,
    },
    state: loadingState,
  };
}