
import { createSelector } from "reselect";
import { getDevice } from "../equipment/selectors";
import { Device } from "../equipment/types";
import {
  isTopLevelGroupActive,
  getGroupDevices,
  getActiveGroupId,
} from "../organisation/selectors";
import { AppStoreState } from "../types";
import {
  Alarm,
  AlarmStateProps,
  AlarmReasonsState,
  Event,
  Image,
  AlarmById,
  Impact,
  ImpactSummaryResponse,
} from './types';

export const getAlarms: (state: AppStoreState, view?: string) => Alarm[] | undefined = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, view?: string) => view,
  (state, view) => {
    // return alarms filtered to group devices
    if (!isTopLevelGroupActive(state)) {
      const groupDevices = getGroupDevices(state, getActiveGroupId(state)) || [];
      const alarms = (view === 'event' ? state?.alarm?.events : state?.alarm?.alarms) || [];
      return alarms.filter(alarm => groupDevices.find((device: any) => device.id === alarm.device_id));
    }
    return view === 'event' ? state?.alarm?.events : state?.alarm?.alarms;
  }
);

export const getAlarmListState: (state: AppStoreState) => AlarmStateProps = createSelector(
  (state: AppStoreState) => state,
  state => {
    const { alarm: { loading, lastFetch, error } } = state;
    return { loading, lastFetch, error };
  }
);

export const getAlarmsShowTags: (state: AppStoreState) => string[] = createSelector(
  (state: AppStoreState) => state,
  state => state.alarm.showTags || []
);

// get alarm from store with restrictions
export const getAlarm: (state: AppStoreState, id: number, options?: { restrictedToGroup?: boolean }) => AlarmById | undefined = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, id: number, options?: { restrictedToGroup?: boolean }) => ({id, options}),
  (state, {id, options}) => {
    const alarm = state &&
    state.alarm &&
    state.alarm.alarmsById &&
    state.alarm.alarmsById[id];
    const {restrictedToGroup = true} = options || {};

    // pass through without restrictions
    if (!restrictedToGroup) {
      return alarm;
    }
    // add restriction
    // return if alarm is from a device available inside
    // the currently selected user's group device members
    else if (alarm && getDevice(state, alarm.device_id)) {
      return alarm;
    }
  }
);

// warning: this selector does an odd root-level look up *specifically*
// to get around the current alarm visibility circular dependency in the
// selectors. Namely, if an alarm is fetched before its device is fetched
// it may not be visible to the user as it may not pass the `getDevice`
// authorisation check, however to fetch that device into redux, we must
// know the device id, which we already know we cannot get through `getDevice`.
// So this function bypasses those authorisation controls in order to return
// just the device id, which a component can then use to try to fetchDevice.
export function getAlarmDeviceId(state: AppStoreState, id: number): number | undefined {
  return state &&
    state.alarm &&
    state.alarm.alarmsById &&
    state.alarm.alarmsById[id] &&
    state.alarm.alarmsById[id].device_id;
}

export function getAlarmDevice(state: AppStoreState, id: number): Device | undefined {
  const alarm = getAlarm(state, id);
  return getDevice(state, alarm && alarm.device_id);
}

export function getAlarmEvents(state: AppStoreState, id: number): Event[] | undefined {
  // find alarm image by alarm id in list
  const alarm = getAlarm(state, id, {restrictedToGroup: false});
  // Inherit the _config property.
  return (alarm?._embedded?.alarm_events || []).map(item => ({_config: alarm?._config || {}, ...item}));
}

export function getAlarmImages(state: AppStoreState, id: number): Image[] | undefined {
  // find alarm image by alarm id in list
  const alarm = getAlarm(state, id);
  return alarm && alarm._embedded && alarm._embedded.images;
}

export function getAlarmSounds(state: AppStoreState, id: number) {
  // find alarm sound by alarm id in list
  const alarm = getAlarm(state, id);
  // note: audio samples are in the images list for now
  // this may change in the future
  // remember to filter this list inside each component
  return alarm && alarm._embedded && alarm._embedded.images;
}

export function getAlarmValidReasonOptionsState({ alarm }: AppStoreState): AlarmReasonsState | undefined {
  return alarm && alarm.alarmValidReasonOptions;
}
export function getAlarmValidReasonOptions({ alarm }: AppStoreState): string[] | undefined {
  return alarm &&
    alarm.alarmValidReasonOptions &&
    alarm.alarmValidReasonOptions.items;
}

export function getAlarmInvalidReasonOptionsState({ alarm }: AppStoreState): AlarmReasonsState | undefined {
  return alarm && alarm.alarmInvalidReasonOptions;
}
export function getAlarmInvalidReasonOptions({ alarm }: AppStoreState): string[] | undefined {
  return alarm &&
    alarm.alarmInvalidReasonOptions &&
    alarm.alarmInvalidReasonOptions.items;
}
export function getAlarmReasonOptionsState(valid: boolean) {
  return (state: AppStoreState) => valid ? getAlarmValidReasonOptionsState(state) : getAlarmInvalidReasonOptionsState(state);
}
export function getAlarmReasonOptions(valid: boolean) {
  return (state: AppStoreState) => valid ? getAlarmValidReasonOptions(state) : getAlarmInvalidReasonOptions(state);
}

// sort alarms in reverse chronological order
function sortAlarmsReverseChronological(a: Alarm, b: Alarm): number {
  return new Date(b.alarm_timestamp).getTime() - new Date(a.alarm_timestamp).getTime();
}

// set an obvious default sort for alarms
export const sortAlarmsDefault = sortAlarmsReverseChronological;

export const getAlarmImpactState: (state: AppStoreState, id: number) => AlarmStateProps | undefined = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, id: number) => id,
  (state, id) => {
    const alarm = getAlarm(state, id);
    return alarm?._embedded?.impact?._state_;
  }
);

export const getAlarmImpact: (state: AppStoreState, id: number) => Impact[] | undefined = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, id: number) => id,
  (state, id) => {
    const alarm = getAlarm(state, id);
    return alarm?._embedded?.impact?.items;
  }
);

export const getLatestAlarmsForOneDevice: (state: AppStoreState) => Alarm[] = createSelector(
  getAlarms,
  alarms => {
    const devices: number[] = [];
    if(!alarms) return [];
    const sortedAlarms = [...alarms].sort((a, b) => a.alarm_timestamp > b.alarm_timestamp ? -1 : 1); // Sort by alarm timestamp. From latest to oldest.
    const sortedAlarmsForOneDevice = sortedAlarms.filter(alarm => { // Filter out the repetitive device.
      if(devices.includes(alarm.device_id)) return false;
      devices.push(alarm.device_id);
      return true;
    });
    return sortedAlarmsForOneDevice;
  }
);

export const getAlarmImpactSummary: (state: AppStoreState, id: number) => ImpactSummaryResponse | undefined = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, id: number) => id,
  (state, id) => {
    const alarm = getAlarm(state, id);
    return alarm?._embedded?.impact?.summary;
  }
);

export const getChildAlarms: (state: AppStoreState, id: number) => Alarm[] = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, id: number) => id,
  (state, id) => {
    return state.alarm.childAlarms ? (state.alarm.childAlarms[id]?.items || []) : [];
  }
);

export const getChildAlarmState: (state: AppStoreState, id: number) => AlarmStateProps | undefined = createSelector(
  (state: AppStoreState) => state,
  (state: AppStoreState, id: number) => id,
  (state, id) => {
    return state.alarm.childAlarms ? state.alarm.childAlarms[id]?._state_ : undefined;
  }
);