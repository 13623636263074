import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const DATE_RANGE = {
  ALL: 'all',
  LAST_WEEK: 'last_week',
  LAST_MONTH: 'last_month',
  LAST_YEAR: 'last_year',
};

function AlarmDateFilter({
  onDateChange,
  defaultDateRange,
  disabled,
}) {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState(defaultDateRange || DATE_RANGE.ALL);

  useEffect(() => {
    if(typeof onDateChange === 'function') {
      onDateChange(dateRange);
    }
  }, [dateRange]);

  return (
    <Form.Control
      as="select"
      onChange={(e) => setDateRange(e.target.value)}
      value={dateRange}
      disabled={disabled}
    >
      <option value={DATE_RANGE.ALL}>{t('components.alarm-date-filter.all')}</option>
      <option value={DATE_RANGE.LAST_WEEK}>{t('components.alarm-date-filter.last-week')}</option>
      <option value={DATE_RANGE.LAST_MONTH}>{t('components.alarm-date-filter.last-month')}</option>
      <option value={DATE_RANGE.LAST_YEAR}>{t('components.alarm-date-filter.last-year')}</option>
    </Form.Control>
  );
}

export default AlarmDateFilter;