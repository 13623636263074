
const nullConvert = v => v; // doesn't actually convert the value

// normalise selectors
export const getNormalisedPropsSelector = getUserDisplayPreference => {
  // return selector
  return state => {
    const props = getUserDisplayPreference(state);
    const {
      units,
      digits = 2,
      minimumFractionDigits = digits,
      maximumFractionDigits = digits,
      convert = nullConvert,
      convertBack = nullConvert,
    } = props;

    // return normalised whitelisted props
    // todo: memoise this, because it causes many a re-render
    return {
      // always present
      digits,
      minimumFractionDigits,
      maximumFractionDigits,
      convert,
      convertBack,
      // optional
      units, // don't trim units here, this function gets called a lot, it should stay fast
    };
  };
};
