import React, { Fragment } from 'react';
import i18n from 'i18next';
import moment from 'moment';

import "./statusIndicatorBadges.scss";

import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import {
  IoIosTime,
  IoIosArchive,
  IoIosAlarm,
  IoIosArrowRoundUp,
  IoIosArrowRoundDown,
} from 'react-icons/io';

import { fmLastHeardHoursWarning, fmLastHeardHoursDanger } from '../columns';
import { getTimezoneOffset } from '../../../components/values/Timezone';

const oneHour = 1000 * 60 * 60;

function formatAbsoluteDate(date) {
  return `${moment(date).format("LLLL")} ${getTimezoneOffset()}`;
}
function formatRelativeDate(date) {
  return moment(date).fromNow(true);
}

function BadgeWithTooltip({
  tooltip,
  variant,
  icon: Icon,
  text,
  expanded,
  children=text,
  ...iconProps
}) {
  // exit early if there is no variant given from the computed value
  if (!variant) {
    return null;
  }
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip>{tooltip}</Tooltip>
      )}
    >
      <Badge
        variant={variant}
        className={[
          'indicator-badge',
          expanded && 'badge--expanded',
        ].filter(Boolean).join(' ')}
      >
        <Icon {...iconProps} />
        {expanded && children && (
          <span>{children}</span>
        )}
      </Badge>
    </OverlayTrigger>
  );
}

function getEpochMs(value=0) {
  return new Date(value).valueOf();
}

function getDangerLevelVariant({ fitmachine_last_heard }, now=Date.now()) {
  const fmLastHeardMs = getEpochMs(fitmachine_last_heard);
  switch(true) {
    case fmLastHeardMs < (now - fmLastHeardHoursDanger * oneHour): return 'danger';
    case fmLastHeardMs < (now - fmLastHeardHoursWarning * oneHour): return 'warning';
    default: return null;
  }
}

// return normalised time since last heard from as a number relative to danger status
export function getStaleValue({ fitmachine_last_heard }, now=Date.now()) {
  return fitmachine_last_heard
    ? (now - getEpochMs(fitmachine_last_heard)) / (fmLastHeardHoursDanger * oneHour)
    : 0;
}
export function StaleIndicatorBadge({ device={}, now=Date.now(), expanded, }) {
  const t = i18n.t;
  const variant = getDangerLevelVariant(device, now);
  const period = formatRelativeDate(device.fitmachine_last_heard);
  // only compute the tooltip text is a badge is required
  if (variant) {
    return (
      <BadgeWithTooltip
        key="stale"
        icon={IoIosTime}
        variant={variant}
        expanded={expanded}
        text={t('components.equipment.stale-indicator-badge.old')}
        tooltip={
          `${t('components.equipment.stale-indicator-badge.data-is-period-old', { period: period })}
          (${formatAbsoluteDate(device.fitmachine_last_heard)})`
        }
      />
    );
  }
  return null;
}

export function ArchivedIndicatorBadge({ device={}, expanded }) {
  if (device.archived) {
    return (
      <BadgeWithTooltip
        key="archived"
        icon={IoIosArchive}
        variant="secondary"
        expanded={expanded}
        tooltip="Archived"
      />
    );
  }
  return null;
}

export function CustomAlarmBadge({ device={}, expanded }) {
  if (device.yellow_threshold_changed || device.red_threshold_changed) {
    const round = value => Math.round(value * 1000) / 1000;
    return (
      <BadgeWithTooltip
        key="custom-alarm"
        icon={IoIosAlarm}
        variant="secondary"
        expanded={expanded}
        text="Custom"
        tooltip={(
          <div>
            Custom alarm thresholds:
            {device.yellow_threshold_changed && (
              <div>
                Yellow advisory: {round(device.yellow_threshold)}
              </div>
            )}
            {device.red_threshold_changed && (
              <div>
                Red alarm: {round(device.red_threshold)}
              </div>
            )}
          </div>
        )}
      />
    );
  }
  return null;
}

export function CustomYellowAlarmBadge({ device={}, expanded }) {
  const t = i18n.t;
  if (device.yellow_threshold_changed) {
    const round = value => Math.round(value * 1000) / 1000;
    return (
      <BadgeWithTooltip
        key="custom-alarm"
        icon={IoIosAlarm}
        variant="secondary"
        expanded={expanded}
        text={(
          <Fragment>
            {device.yellow_threshold > 1/3 ? (
              <IoIosArrowRoundUp className="indicator-direction" />
            ) : (
              <IoIosArrowRoundDown className="indicator-direction" />
            )} {t('components.equipment.custom-yellow-alarm-badge.advisory')}
          </Fragment>
        )}
        tooltip={(
          <div>
            {t('components.equipment.custom-yellow-alarm-badge.custom-alarm-threshold')}:
            <div>
              {t('components.equipment.custom-yellow-alarm-badge.yellow-advisory')}: {round(device.yellow_threshold)}
            </div>
          </div>
        )}
      />
    );
  }
  return null;
}

export function CustomRedAlarmBadge({ device={}, expanded }) {
  const t = i18n.t;
  if (device.red_threshold_changed) {
    const round = value => Math.round(value * 1000) / 1000;
    return (
      <BadgeWithTooltip
        key="custom-alarm"
        icon={IoIosAlarm}
        variant="secondary"
        expanded={expanded}
        text={(
          <Fragment>
            {device.red_threshold > 2/3 ? (
              <IoIosArrowRoundUp className="indicator-direction" />
            ) : (
              <IoIosArrowRoundDown className="indicator-direction" />
            )} {t('components.equipment.custom-red-alarm-badge.alert')}
          </Fragment>
        )}
        tooltip={(
          <div>
            {t('components.equipment.custom-red-alarm-badge.custom-alarm-threshold')}:
            <div>
              {t('components.equipment.custom-red-alarm-badge.red-alert')}: {round(device.red_threshold)}
            </div>
          </div>
        )}
      />
    );
  }
  return null;
}

export default function StatusIndicatorBadges({
  device = {},
  badgeComponents = [],
  now = Date.now(),
  expanded = false,
}) {

  // evaluate the components early and filter to valid badges
  const badges = badgeComponents
    .map(component => component({ device, now, expanded }))
    .filter(v => v);

  // render the component if any badges are found
  return badges.length > 0 ? (
    <span className="indicator-badges">
      {badges}
    </span>
  ) : null;
}
