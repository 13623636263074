import { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import HomeCard from './HomeCard';
import { useEventImpactMonitoringData } from '../../modules/organisation/hooks/useGroupEventsReport';
import { getChartLabelColor, roundNumber } from '../lib/utils';

function MonitoringCost() {
  const { t } = useTranslation();
  const eventImpactMonitoring = useEventImpactMonitoringData();

  const {_state_: {loading, error} = {}, items = []} = eventImpactMonitoring || {};

  const monitoringCosts = useMemo(() => items.map(i => i.monitoring_costs), [items]);
  const equipmentCounts = useMemo(() => items.map(i => i.equipment_cnt), [items]);
  const maxMonitoringCosts = roundNumber(Math.max(...monitoringCosts));
  const maxEquipmentCounts = roundNumber(Math.max(...equipmentCounts));

  if(!eventImpactMonitoring) return null;

  return (
    <HomeCard
      header={t('home.monitoring-cost.header')}
      headerLink={{to: '/group/impact_summary', text: t('home.common.all-areas')}}
      loading={loading}
      error={error}
    >
      <ReactEcharts
        option={{
          color: ['orange', 'blue'].map(color => getChartLabelColor(color)),
          legend: {
            data: [
              t('home.common.monitoring-costs'),
              t('home.common.assets-monitored'),
            ],
            textStyle: {
              fontSize: 10,
            },
            bottom: -5,
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: [{
            type: 'category',
            data: items.map(i => i.group_name),
            axisLabel: {
              show: true,
              interval: items.length <= 15 ? 0 : 'auto',
              rotate: 30,
            },
            axisTick: {
              alignWithLabel: true
            },
          }],
          yAxis: [{
            type: 'value',
            name: t('home.common.monitoring-costs'),
            nameLocation: 'middle',
            position: 'left',
            nameTextStyle: {
              padding: [0, 0, 40, 0],
            },
            interval: (maxMonitoringCosts) / 5,
            min: 0,
            max: maxMonitoringCosts,
          }, {
            type: 'value',
            name: t('home.common.quantity-of-assets-monitored'),
            nameLocation: 'middle',
            position: 'right',
            nameTextStyle: {
              padding: [25, 0, 0, 0],
            },
            interval: (maxEquipmentCounts) / 5,
            min: 0,
            max: maxEquipmentCounts,
          }],
          grid: {
            top: 10,
            bottom: 100,
            left: 70,
            right: 50,
          },
          series: [
            {
              name: t('home.common.monitoring-costs'),
              type: 'bar',
              stack: 'one',
              data: monitoringCosts,
              yAxisIndex: 1,
            },
            {
              name: t('home.common.assets-monitored'),
              type: 'bar',
              stack: 'two',
              data: equipmentCounts,
              yAxisIndex: 0,
            },
          ]
        }}
      />
    </HomeCard>
  );
}

export default MonitoringCost;