
// use increment and decrement handlers
// because previous codebase had issues with NaN values
export function incrementCounter(value) {
  return !isNaN(value) ? value + 1 : 1;
}
export function decrementCounter(value) {
  // lower limit of zero
  return value && !isNaN(value) ? value - 1 : 0;
}

// allow an easy set of upsert by id methods to be made
export function matchItemById(idAttr='id') {
  return (item, newItem) => item[idAttr] === newItem[idAttr];
}

// merge _embedded relation logic
export function mergeRelations(relations, newRelations={}, opts={}) {
  const {
    upsertRelated={},
  } = opts;
  return Object.entries(newRelations).reduce((acc, [key, value]) => {
    // handle a collection
    if (Array.isArray(value)) {
      // upsert a collection into a previous collection using the given nested settings
      if (upsertRelated[key]) {
        acc[key] = upsertListItems(acc[key], value, upsertRelated[key]);
      }
      // or just overwrite the collection
      else {
        acc[key] = value;
      }
    }
    // merge a normal item, or a collection naively if it does not have a mergeSetting
    // merging undefined into undefined should do nothing
    else if ((acc[key] !== undefined) || (value !== undefined)) {
      acc[key] = mergeItems([acc[key], value], upsertRelated[key]);
    }
    return acc;
  }, { ...relations });
}

export function objectMerge(previousObj, newObj) {
  return { ...previousObj, ...newObj };
}

// this returns a new object with fields overwritten and relations preserved
export function mergeItems(items, opts={}) {
  const {
    merge = objectMerge,
  } = opts;

  // merge normal item props
  // allow the merge function to receive the arguments in a way that resembles
  // merge(previousItem, newItem) {}
  // where previousItem may be undefined, denoting that it does not yet exist
  // note: do not set the initial accumulator as an object, that will fail the tests somehow :/
  const result = items.reduce(merge, undefined);
  // preserve previous local state about the object
  if (result._state_) {
    // item may be undefined
    result._state_ = items.reduce((acc, item) => item ? { ...acc, ...item._state_ } : acc, {});
  }
  // preserve previous links
  if (result._links) {
    // item may be undefined
    result._links = items.reduce((acc, item) => item ? { ...acc, ...item._links } : acc, {});
  }
  // preserve previous units
  if (result._units) {
    // item may be undefined
    result._units = items.reduce((acc, item) => item ? { ...acc, ...item._units } : acc, {});
  }
  // preserve previous embedded items
  if (result._embedded) {
    // item may be undefined
    result._embedded = items.reduce((acc, item) => {
      return item ? mergeRelations(acc, item._embedded, opts) : acc;
    }, {});
  }
  return result;
}

export function upsertItem(oldItem={}, newItem={}, opts={}) {
  return mergeItems([oldItem, newItem], opts);
}

// upsert a new item by 'id' into a listObject, which is like a list, but keyed
export function upsertObjectItemById(givenlistObject={}, newItem={}, id, opts={}) {
  return {
    ...givenlistObject,
    [id]: upsertItem(givenlistObject[id], newItem, {
      ...opts,
      match: item => item.id === id,
    }),
  };
};

// upsert a new item into a listObject, which is like a list, but keyed
export function upsertObjectItem(givenlistObject={}, newItem={}, key='id', opts={}) {
  return {
    ...givenlistObject,
    // only overwrite new key if it exists
    ...newItem[key] && {
      [newItem[key]]: upsertItem(givenlistObject[newItem[key]], newItem, opts),
    }
  };
};

// upsert new items into a listObject, which is like a list, but keyed
export function upsertObjectItems(givenlistObject={}, newItems=[], key='id', opts={}) {
  const {
    clone = true,
    filter = defaultFilterFunction,
    matchById,
    match = matchItemById(matchById),
  } = opts;
  // return new object with upserted items merged by key
  return newItems.reduce((acc, item) => {
    acc[item[key]] = upsertItem(givenlistObject[item[key]], item, opts);
    return acc;
  }, filter ? Object.entries(givenlistObject).reduce((acc, [key, item]) => {
    // add key and item if the filter check has been passed
    if (filter(newItems, match)(item)) {
      acc[key] = item;
    }
    return acc;
  }, {}) : clone ? { ...givenlistObject } : givenlistObject);
};

// by default this will match the item by oldItem.id == newItem.id
// by default this will return a new list
export function upsertListItem(givenList=[], newItem={}, opts={}) {
  const {
    clone = true,
    matchById,
    match = matchItemById(matchById),
  } = opts;

  // allow cloning by default, but allow advanced usage to disable cloning
  // for eg. when iterating through a long list where the list was already cloned
  const list = clone ? givenList.slice() : givenList;

  // find existing item
  const index = list.findIndex(item => match(item, newItem));

  // update
  if (index >= 0) {
    const item = list[index];
    list[index] = mergeItems([item, newItem], opts);
  }
  // or insert
  else if (Object.keys(newItem).length > 0) {
    // use mergeItems so that the merge function will be called with opts
    list.push(mergeItems([newItem], opts));
  }

  return list;
}

export function defaultFilterFunction(newList, match) {
  // filter to those items which have a match from the new list
  return item => {
    return !!newList.find(newItem => match(item, newItem));
  };
}

// by default this will match the items by oldItem.id == newItem.id
// by default this will return a new list
// the only way to not return a new list is to pass filter=false and clone=false
export function upsertListItems(list=[], newList=[], opts={}) {
  const {
    // while there is no pagination it makes sense to filter previous lists
    // to only the items existing in the new list by default
    // if this changes then perhaps the default should be to not filter
    filter = defaultFilterFunction,
    clone = true,
    matchById,
    match = matchItemById(matchById),
  } = opts;

  // filter the current list to only the items in the new list
  // or just clone or leave if the list should not be filtered
  const filteredItems = !filter
    ? clone ? list.slice() : list
    : list.filter(filter(newList, match));

  // upsert each new list item into the filtered list
  return newList.reduce((newList, newItem) => {
    return upsertListItem(newList, newItem, {
      ...opts,
      clone: false, // force false to reduce unnecessary cloning for each item
    });
  }, filteredItems);
}

// intended for use as a filter function builder.
// allows filtering a current list to items that match all the key values of any given matchObject.
export function matchAnyObject(matchObjects=[]) {
  const matchObjectKeyValuesArray = matchObjects.map(matchObject => Object.entries(matchObject));
  // return filter function (see defaultFilterFunction for an example)
  return () => item => {
    // find an item that partially matches any of the objects given
    return matchObjectKeyValuesArray.find(matchObjectKeyValues=> {
      // item must match all of the given key values
      return matchObjectKeyValues.length > 0 &&
        matchObjectKeyValues.every(([key, value]) => item[key] === value);
    });
  };
}
