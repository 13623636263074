
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Title from '../../../components/Title';

import { getAlarm, getAlarmDeviceId, getAlarmImages } from '../selectors';

import { fetchAlarm } from '../actions';
import { fetchDeviceInfo, fetchDeviceWatchers } from '../../equipment/actions';
import LoadingSpinner from '../../../components/LoadingSpinner';
import DeviceAlarmCard from '../../equipment/components/DeviceAlarmCard';

function AlarmPage({
  alarmId,
  deviceId,
  fetchAlarm,
  fetchDeviceInfo,
  fetchDeviceWatchers,
}) {

  const { t } = useTranslation();
  const [alarmError, setAlarmError] = useState();

  // fetch alarm info if the id exists and has changed
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (alarmId) {
      setLoading(true);
      (async () => {
        try {
          await fetchAlarm({ id: alarmId });
        }
        catch {
          setAlarmError('Could not load images for this alarm');
          setSuccess(false);
        }
        setLoading(false);
        setSuccess(true);
      })();
    }
  }, [alarmId]);

  // fetch device info
  useEffect(() => {
    if (deviceId) {
      fetchDeviceInfo({ id: deviceId });
      fetchDeviceWatchers({id: deviceId});
    }
  }, [deviceId]);

  // todo: include device loading state as well as alarm loading state
  if (loading) {
    return (
      <div className="my-4 mx-auto">
        <LoadingSpinner />
      </div>
    );
  }

  if (alarmError) {
    return (
      <Alert variant="danger">
        {alarmError}
      </Alert>
    );
  }

  return success ? (
    <div className="container-fluid">
      <Row>
        <Col xs={12}>
          <Title title={t('Alarm')} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DeviceAlarmCard alarmId={alarmId} />
        </Col>
      </Row>
    </div>
  ) :
    <Alert variant="danger">
      Something went wrong
    </Alert>;
}

const mapStateToProps = (state, { match }) => {
  // get alarmId from route props
  const alarmId = match && match.params && match.params.id;
  const alarm = getAlarm(state, alarmId);
  return {
    alarm,
    alarmId,
    images: getAlarmImages(state, alarmId),
    deviceId: getAlarmDeviceId(state, alarmId),
  };
};

const mapDispatchToProps = { fetchAlarm, fetchDeviceInfo, fetchDeviceWatchers };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AlarmPage));
