import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Container, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { fetchOrganisations } from '../actions';

import {
  IoIosAddCircleOutline
} from "react-icons/io";

import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';

import Private from '../../../components/Private';
import OrganisationDropdown from '../components/OrganisationDropdown';

import { getOrganisations, getOrganisationListState } from '../selectors';

import {
  organisationName,
  subDomain,
  createdAt,
  parentOrganisation,
} from '../columns';

import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc'
}];

const actions = {
  dataField: 'actions',
  text: 'Actions',
  formatter: (value, { id }={}) => (
    id ? (
      <div className="text-right">
        <OrganisationDropdown organisationId={id} />
      </div>
    ) : "N/A"
  ),
  events: {
    onClick: (e) => e.stopPropagation()
  }
};

const columns = [
  organisationName,
  subDomain,
  createdAt,
  parentOrganisation,
  actions,
];

function OrganisationsAdmin(props) {
  const { t } = useTranslation();
  const { fetchOrganisations, organisations, loading, lastFetch, error } = props;
  const parentOrganisationsById = useMemo(() => organisations.reduce((acc, org) => {
    if (org.is_parent) {
      acc[org.id] = org;
    }
    return acc;
  }, {}), [organisations]);

  const organisationsWithParentNames = useMemo(() => organisations.map(org => {
    const parentOrganisation = parentOrganisationsById[org.parent_organisation_id] || {};
    return {
      ...org,
      parent_organisation_name: parentOrganisation.name,
    };
  }), [organisations]);

  useEffect(() => {
    fetchOrganisations();
  }, []);

  const renderHeader = useCallback(props => {
    return (
      <TableToolbar
        searchable
        title={t('screens.organisations.organisations-admin.title')}
        loading={loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
        buttons={[
          <Private key="new" minUserType="Partner Admin" minOrgType="Partner">
            <LinkContainer to={`/organisations/new`}>
              <Button variant="primary" className="pl-1">
                <IoIosAddCircleOutline className="mr-1" size="1.4em"/>Add Organisation
              </Button>
            </LinkContainer>
          </Private>,
        ]}
      />
    );
  }, [loading, lastFetch, error]);
  const history = useHistory();
  const localisedColumns = useLocalisedColumns(columns);

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        data={organisationsWithParentNames}
        defaultSorted={defaultSorted}
        columns={localisedColumns}
        noDataIndication={() => t('screens.organisations.organisations-admin.no-organisations')}
        loading={loading}
        rowEvents = {{
          onClick: (e, org) => {
            if(window.getSelection().toString().length > 0) return;
            history.push(`/organisations/${org.id}`);
          }
        }}
        rowStyle={{
          cursor: 'pointer'
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const { loading, lastFetch, error } = getOrganisationListState(state);
  return {
    loading,
    lastFetch,
    error,
    organisations: getOrganisations(state),
  };
};

const mapDispatchToProps = { fetchOrganisations };

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationsAdmin);
