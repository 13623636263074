
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from 'react-bootstrap';

import LoadingSpinner from '../../../components/LoadingSpinner';

import { debounce } from '../../../lib/utils';

function elementOffsetFromWindow(element) {
  return element.offsetTop + (
    element.offsetParent
      ? elementOffsetFromWindow(element.offsetParent)
      : 0
  );
}

function LoadMore({ loading, onClick }) {
  return (
    <div className="mb-4">
      <Button
        className="d-block mx-auto my-4"
        variant="outline-secondary"
        size="lg"
        disabled={loading}
        onClick={onClick}
      >
        {loading && (
          <LoadingSpinner inline size={1} />
        )} {loading ? 'Loading' : 'Load'} more
      </Button>
    </div>
  );
}

export default function ActivityList({ title, listId, children }) {

  const list = React.Children.toArray(children);

  const containerRef = useRef(null);

  const [loadingMore, setLoadingMore] = useState(false);

  // use a slice of the alarms, so the list can be rendered progressively as needed
  const [listLength, setListLength] = useState(10);

  // reset list length and scroll position if the list changes
  useEffect(() => {
    // reset the list length
    setListLength(10);
    // reset the scroll position (so that the list doesn't continually scroll
    // down: if the last scroll position was far down the page, and the user
    // changes the list, the new list would (without this) continually throw
    // `onContainerBottomReached` events until the same scroll position was
    // reached in the new list)
    // scroll to the position on the page as if the container height was 0
    const container = containerRef.current;
    if (
      container &&
      // window scroll top > container scroll top
      window.scrollY > elementOffsetFromWindow(container)
    ) {
      // reset scroll to the top of the container
      container.scrollIntoView(true);
    }
  }, [listId]);

  const increaseListLength = useCallback(debounce(() => {
    // increase list length
    setListLength(length => {
      return length + 10 > list.length
        // show all items
        ? list.length
        // show 10 more items
        : length + 10;
    });
    // reset loading indicator
    setLoadingMore(false);
  // debounce a bit (scroll to bottom might trigger multiple calls)
  }, 500), [list.length]);

  const showMoreItems = useCallback(() => {
    // set loading indicator
    setLoadingMore(true);
    // set list length after a little delay
    increaseListLength();
  }, [increaseListLength]);

  // render more elements whenever the user hits the bottom of the container
  useEffect(() => {
    const onContainerBottomReached = () => {
      const container = containerRef.current;
      if (
        container &&
        // window scroll bottom (offset top + height)
        (window.scrollY + window.innerHeight) >=
        // container scroll bottom (offset top + height)
        (elementOffsetFromWindow(container) + container.offsetHeight)
      ) {
        // you're at the bottom of the container
        showMoreItems();
      }
    };
    // add listener
    window.addEventListener('scroll', onContainerBottomReached, false);
    // cleanup listener
    return () => window.removeEventListener('scroll', onContainerBottomReached);
  }, [showMoreItems]);

  return (
    <div ref={containerRef} className="activity-list">
      {title}
      {/* show maybe truncated list */}
      {list.slice(0, listLength)}
      {/* show load more button if appropriate */}
      {listLength < list.length && (
        <LoadMore loading={loadingMore} onClick={showMoreItems} />
      )}
    </div>
  );
}
