
// get path param as defined by Routes with react-router
// docs: https://reacttraining.com/react-router/web/api/match
export function getPathParamFromProps({ match }, id) {
  return match && match.params && match.params[id];
}

// return either the found param as an integer or undefined
export function getPathParamIntegerFromProps(props, id) {
  const paramInt = parseInt(getPathParamFromProps(props, id));
  return !isNaN(paramInt) ? paramInt : undefined;
}

export function getQueryParamFromProps({ location }, id) {
  return location && location.query && location.query[id];
}
