import { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useGroupMonitoringTrend } from '../../modules/organisation/hooks/useGroupStandardData';
import HomeCard from './HomeCard';
import { getChartLabelColor, roundNumber } from '../lib/utils';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';

function HoursMonitored() {
  const { t } = useTranslation();
  const monitoringTrend = useGroupMonitoringTrend();

  const {_state_: {loading, error} = {}, items = []} = monitoringTrend || {};
  const itemsSortedByDate = items.sort((a, b) => a.date < b.date ? -1 : 1);
  const hoursMonitored = useMemo(() => itemsSortedByDate.map(i => i.monitored_hrs), [items]);
  const equipmentCount = useMemo(() => itemsSortedByDate.map(i => i.equipment_cnt), [items]);
  const maxHoursMonitored = roundNumber(Math.max(...hoursMonitored));
  const maxEquipmentCount = roundNumber(Math.max(...equipmentCount));
  const { filterAll } = useDeviceStatusFilter();

  if(!monitoringTrend) return null;

  return (
    <HomeCard
      header={t('user.Hours_and_equipment_monitored')}
      headerLink={{to: '/', text: t('home.all-equipment'), onClick: filterAll }}
      loading={loading}
      error={error}
    >
      <ReactECharts
        option={{
          grid: {
            top: 10,
            left: 90,
            right: 90,
          },
          color: ['green', 'orange'].map(color => getChartLabelColor(color)),
          legend: {
            data: [t('Hours Monitored'), t('Devices')],
            bottom: 0,
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: [{
            type: 'category',
            data: itemsSortedByDate.map(i => moment(i.date).local().format('MMM-YY'))
          }],
          yAxis: [{
            type: 'value',
            name: t('Total Hours Monitored'),
            nameLocation: 'middle',
            nameTextStyle: {
              padding: [0, 0, 50, 0],
            },
            position: 'left',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#333'
              }
            },
            min: 0,
            max: maxHoursMonitored,
            interval: maxHoursMonitored / 5,
          }, {
            type: 'value',
            name: t('Total Devices Monitored'),
            nameLocation: 'middle',
            nameTextStyle: {
              padding: [40, 0, 0, 0],
            },
            position: 'right',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#333'
              }
            },
            min: 0,
            max: maxEquipmentCount,
            interval: maxEquipmentCount / 5,
          }],
          series: [
            {
              name: t('Hours Monitored'),
              type: 'bar',
              data: hoursMonitored,
            }, {
              name: t('Devices'),
              type: 'line',
              data: equipmentCount,
              yAxisIndex: 1,
            }
          ]
        }}
      />
    </HomeCard>
  );
}

export default HoursMonitored;
