import * as APP_ACTION_TYPES from '../modules/app/types/ActionTypes';
import * as USER_ACTION_TYPES from '../modules/user/types/ActionTypes';
import reducer from '../modules/reducers';
import * as ORGANISATION_ACTION_TYPES from '../modules/organisation/types/ActionTypes';
import { postUserActionCreate } from '../modules/app/actions';
import { getOrganisation } from '../modules/organisation/selectors';
import { getUser, getUserToken } from '../modules/user/selectors';

const whitelistedUserKeys = [
  'id',
  'email',
  'user_type',
  'name',
  'last_sign_in_at',
  'created_at',
  'location',
  'receive_daily_email',
  'receive_alert_email',
  'receive_alert_sms',
  'mobile_phone',
  'country_code',
  'picture_url',
  'phone',
  'avatar',
];

const whitelistedOrganisationKeys = [
  'id',
  'name',
  'sub_domain',
  'created_at',
  'updated_at',
  'parent_organisation_id',
  'is_parent',
  'logo_url',
  'avatar',
];

const trackReducers = {
  [APP_ACTION_TYPES.ACTIVITY_TRACK](state, action) {
    return action.payload;
  },
  [APP_ACTION_TYPES.APP_LOAD](state) {
    const user = getUser(state);
    let userId, organisationId;
    if(!user) return;
    if(user && user.id) {
      userId = user.id;
    }
    const organisation = getOrganisation(state);
    if(organisation && organisation.id) {
      organisationId = organisation.id;
    }
    return {
      location: 'Application',
      action: 'load',
      user_id: userId,
      organisation_id: organisationId,
    };
  },
  [APP_ACTION_TYPES.APP_PAGE](state, action) {
    if(getUser(state)) {
      return {
        location: 'Any Page',
        action: 'view',
        path: action.path,
      };
    }
  },
  [USER_ACTION_TYPES.RECEIVE_USER](prevState, action) {
    const nextState = reducer(prevState, action);
    const nextUser = getUser(nextState) || {};
    if (nextUser && nextUser.id) {
      // check if any relevant nextUser object values have changed
      const prevUser = getUser(prevState) || {};
      if (!whitelistedUserKeys.every(key => nextUser[key] === prevUser[key])) {
        return {
          location: 'Login',
          action: 'get user data',
          user: nextUser,
        };
      }
    }
  },
  [ORGANISATION_ACTION_TYPES.RECEIVE_ORGANISATION_LIST](prevState, action) {
    const nextState = reducer(prevState, action);
    const nextOrganisation = getOrganisation(nextState) || {};
    if (nextOrganisation && nextOrganisation.id) {
      // check if any relevant organisation object values have changed
      const prevOrganisation = getOrganisation(prevState) || {};
      if (!whitelistedOrganisationKeys.every(key => {
        return nextOrganisation[key] === prevOrganisation[key];
      })) {
        // send relevant changes to segment
        return {
          location: 'Login',
          action: 'get organisation data',
          organisation: nextOrganisation,
        };
      }
    }
  },
  [APP_ACTION_TYPES.APP_EVENT](state, action) {
    if(getUserToken(state)) {
      return {
        location: action.locationName,
        action: action.actionName,
        event_properties: action.eventProperties,
      };
    }
  },
};

export default store => next => async action => {
  const { _persist, ...state } = store.getState();
  const trackReducer = trackReducers[action.type];
  if(trackReducer) {
    const actionData = trackReducer(state, action);
    actionData && store.dispatch(postUserActionCreate(actionData));
  }
  return next(action);
};