import React from 'react';
import { connect } from 'react-redux';

import logo from '../images/logo.svg';
import './rootUnauthenticated.scss';

import NotFound from './NotFound';
import Root from './Root';

import { isUserLoggedIn } from '../modules/user/selectors';

function RootUnauthenticated({ title, children, userIsLoggedIn }) {

  // show not found route to user if they are logged in
  // and have purposefully navigated to this page
  if (userIsLoggedIn) {
    return (
      <Root>
        <NotFound />
      </Root>
    );
  }

  return (
    // We would include public header here.
    <Root header={true}>
      <div className="root-page-centered">
        <div className="root-page-centered__row">
          <div className="root-page-centered__column">
            <div className="text-center">
              <img src={logo} className="MOVUS-logo mb-4" alt="logo" />
              <h1 className="h3 mb-3 font-weight-normal">
                {title}
              </h1>
            </div>
            {children}
          </div>
        </div>
      </div>
    </Root>
  );
}

const mapStateToProps = state => {
  return {
    userIsLoggedIn: !!isUserLoggedIn(state),
  };
};

export default connect(mapStateToProps)(RootUnauthenticated);
