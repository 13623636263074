import createReducer from '../../lib/createReducer';
import * as ACTION_TYPES from './types/ActionTypes';
import * as USER_ACTION_TYPES from '../user/types/ActionTypes';
import { SetAllNotificationsPayload } from './types/Notification';
import { upsertListItem } from '../../lib/reducerUtils';
import {
  NotificationState,
  NotificationStoreState,
  NotificationResponse,
  NotificationUnreadResponse,
  SetNotificationReadStatusResponse,
  DeleteNotificationResponse,
  NotificationOptionsResponse,
} from './types';

export const DEFAULT_STATE: NotificationStoreState = {
  loading: false,
  lastFetch: null,
  error: null,
  notifications: [],
  unreadCount: 0,
  options: [],
};

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.REQUEST_ALL_NOTIFICATIONS]: (state: NotificationStoreState): NotificationStoreState => ({ ...state, loading: true }),
  [ACTION_TYPES.RECEIVE_ALL_NOTIFICATIONS]: (state: NotificationStoreState, payload: { response: NotificationResponse }): NotificationStoreState => {
    const notifications = payload.response?._embedded?.notifications || [];
    return { ...state,
      loading: false,
      lastFetch: Date.now(),
      error: null,
      notifications: notifications.map(n => ({...n, loading: false, lastFetch: null, error: null})),
      unreadCount: notifications.filter(n => !!n.unread).length,
    };
  },
  [ACTION_TYPES.ALL_NOTIFICATIONS_FAILURE]: (state: NotificationStoreState, payload: { response: string }): NotificationStoreState => (
    { ...state,
      loading: false,
      error: payload.response,
    }
  ),

  [ACTION_TYPES.REQUEST_UNREAD_NOTIFICATIONS]: (state: NotificationStoreState): NotificationStoreState => ({ ...state, loading: true }),
  [ACTION_TYPES.UNREAD_NOTIFICATIONS_FAILURE]: (state: NotificationStoreState, payload: { response: string }): NotificationStoreState => ({ ...state, loading: false, error: payload.response}),
  [ACTION_TYPES.RECEIVE_UNREAD_NOTIFICATIONS]: (state: NotificationStoreState, payload: { response: NotificationUnreadResponse }): NotificationStoreState => {
    const unreadCount = payload.response?.unread || 0;
    return { ...state,
      loading: false,
      error: null,
      lastFetch: Date.now(),
      unreadCount,
    };
  },

  [ACTION_TYPES.REQUEST_SET_ONE_NOTIFICATION_READ_STATUS]: (state: NotificationStoreState, payload: { id: string }): NotificationStoreState => {
    const previousNotification = state.notifications.find(n => n.id === payload.id);
    return {
      ...state,
      notifications: upsertListItem(state.notifications, {
        ...previousNotification,
        loading: true,
      }),
    };
  },

  [ACTION_TYPES.SET_ONE_NOTIFICATION_READ_STATUS_FAILURE]: (state: NotificationStoreState, payload: { response: string, id: string }): NotificationStoreState => {
    const { response, id } = payload;
    const previousNotification = state.notifications.find(n => n.id === id);
    return {
      ...state,
      notifications: upsertListItem(state.notifications, {
        ...previousNotification,
        loading: false,
        error: response,
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_SET_ONE_NOTIFICATION_READ_STATUS]:
    (
      state: NotificationStoreState,
      payload: { response: SetNotificationReadStatusResponse, id: string, read: boolean }
    ): NotificationStoreState => {
      const { id, read } = payload;
      const previousNotification = state.notifications.find(n => n.id === id);
      const newNotifications = upsertListItem(state.notifications, {
        ...previousNotification,
        loading: false,
        error: null,
        unread: !read,
      });
      return {
        ...state,
        notifications: newNotifications,
        unreadCount: newNotifications.filter(n => !!n.unread).length,
      };
    },

  [ACTION_TYPES.REQUEST_DELETE_ONE_NOTIFICATION]: (state: NotificationStoreState, payload: { id: string }): NotificationStoreState => {
    const previousNotification = state.notifications.find(n => n.id === payload.id);
    return {
      ...state,
      notifications: upsertListItem(state.notifications, {
        ...previousNotification,
        loading: true,
      }),
    };
  },

  [ACTION_TYPES.RECEIVE_DELETE_ONE_NOTIFICATION]: (state: NotificationStoreState, payload: { response: DeleteNotificationResponse, id: string }): NotificationStoreState => {
    const { id } = payload;
    const newNotifications = state.notifications.filter(n => n.id !== id);
    return {
      ...state,
      notifications: newNotifications,
      unreadCount: newNotifications.filter(n => !!n.unread).length,
    };
  },

  [ACTION_TYPES.DELETE_ONE_NOTIFICATION_FAILURE]: (state: NotificationStoreState, payload: { response: string, id: string }): NotificationStoreState => {
    const previousNotification = state.notifications.find(n => n.id === payload.id);
    return {
      ...state,
      notifications: upsertListItem(state.notifications, {
        ...previousNotification,
        loading: false, // Reset loading status.
        // If deleting notification failed, no need to record error.
      })
    };
  },

  [ACTION_TYPES.RECEIVE_NOTIFICATION_OPTIONS]: (state: NotificationStoreState, payload: { response: NotificationOptionsResponse}): NotificationStoreState => {
    return {
      ...state,
      options: payload.response?._embedded?.items || [],
    };
  },

  [ACTION_TYPES.RECEIVE_SET_ALL_NOTIFICATIONS]: (state: NotificationStoreState, payload: { setData: SetAllNotificationsPayload}): NotificationStoreState => {
    const { setData: { deleted, read_only, unread } } = payload;
    let newNotifications: NotificationState[] = [];
    if(deleted === true) {
      // Delete all notifications or delete all READ notifications(read_only = true)
      newNotifications = state.notifications.filter(n => read_only === true && n.unread);
    }
    if(typeof unread === 'boolean') {
      // Set all notifications as read or unread.
      newNotifications = state.notifications.map(n => ({...n, unread}));
    }
    return {
      ...state,
      loading: false,
      lastFetch: Date.now(),
      error: null,
      notifications: newNotifications,
      unreadCount: newNotifications.filter((n: NotificationState) => n?.unread === true).length,
    };
  }
});