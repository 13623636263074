
import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { getFormValues } from '../../../lib/utils';

import FormModal from '../../../components/FormModal';

import {
  editStreamingConfiguration,
  fetchStreamingConfiguration,
} from '../actions';

import { useFocusableRef } from '../../../components/form/useFocusableRef';
import { StreamingConfigurationFormGroups } from './form/StreamingConfigurationFormGroups';

import { getStreamingConfiguration } from '../selectors';

const confirmButtonProps = {
  variant: 'success',
};

function EditStreamingConfigurationFormModal({
  organisationId,
  streamId,
  description,
  enabled,
  streamType,
  resourceType,
  resourceLocation,
  resourceKey,
  editStreamingConfiguration,
  fetchStreamingConfiguration,
  children,
  ...props
}) {

  const { t } = useTranslation();
  const [valid, setValid] = useState(true);

  const formRef = useRef(null);

  const handleSubmit = useCallback(async e => {
    const data = getFormValues(e, formRef.current) || {};
    try {
      await editStreamingConfiguration({ id: organisationId }, streamId, data);
      await fetchStreamingConfiguration({ id: organisationId });
    }
    catch(err) {
      // allow error to prevent closing of the modal
      throw new Error(err);
    }
  }, [formRef.current, organisationId, streamId]);

  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [updatedEnabled, setUpdatedEnabled] = useState(enabled);
  const [updatedStreamType, setUpdatedStreamType] = useState(streamType);
  const [updatedResourceType, setUpdatedResourceType] = useState(resourceType);
  const [updatedResourceLocation, setUpdatedResourceLocation] = useState(resourceLocation);
  const [updatedResourceKey, setUpdatedResourceKey] = useState(resourceKey);

  const [pristineDescription, setPristineDescription] = useState(true);
  const [pristineEnabled, setPristineEnabled] = useState(true);
  const [pristineStreamType, setPristineStreamType] = useState(true);
  const [pristineResourceType, setPristineResourceType] = useState(true);
  const [pristineResourceLocation, setPristineResourceLocation] = useState(true);
  const [pristineResourceKey, setPristineResourceKey] = useState(true);

  const [descriptionRef, setDescriptionShown] = useFocusableRef(null);
  const enabledRef = useRef(null);
  const streamTypeRef = useRef(null);
  const resourceTypeRef = useRef(null);
  const resourceLocationRef = useRef(null);
  const resourceKeyRef = useRef(null);

  // instead of having multiple handlers which do the same thing, look up functions based on element
  const mapElementToProps = {
    description: description,
    stream_type: streamType,
    resource_type: resourceType,
    resource_location: resourceLocation,
    resource_key: resourceKey,
  };
  const mapElementToPristine = {
    description: setPristineDescription,
    stream_type: setPristineStreamType,
    resource_type: setPristineResourceType,
    resource_location: setPristineResourceLocation,
    resource_key: setPristineResourceKey,
  };
  const mapElementToName = {
    description: setUpdatedDescription,
    stream_type: setUpdatedStreamType,
    resource_type: setUpdatedResourceType,
    resource_location: setUpdatedResourceLocation,
    resource_key: setUpdatedResourceKey,
  };

  const handleEnabledChange = useCallback(e => {
    const checked = e.target.checked;
    setPristineEnabled(checked === enabled);
    setUpdatedEnabled(checked);
    setValid(true); // either choice is valid as it is a boolean
  }, []);

  const handleTextChange = useCallback(e => {
    const element = e.target.id;
    const entered = e.target.value;

    const prop = mapElementToProps[element];
    const pristine = mapElementToPristine[element];
    const name = mapElementToName[element];

    pristine(entered === prop);
    name(entered);
    setValid(!!entered);
  }, []);

  const close = (saved, description, enabled, streamType, resourceType, resourceLocation, resourceKey) => {
    setDescriptionShown(false);
    if (!saved) {
      setUpdatedDescription(description);
      setUpdatedEnabled(enabled);
      setUpdatedStreamType(streamType);
      setUpdatedResourceType(resourceType);
      setUpdatedResourceLocation(resourceLocation);
      setUpdatedResourceKey(resourceKey);
    }
  };

  const editText = t('components.organisations.edit-streaming-configuration-form-modal.header');
  return (
    <FormModal
      // set defaults
      header={editText}
      confirmText={editText}
      confirmButtonProps={confirmButtonProps}
      // add given props
      {...props}
      // override given props
      size="md"
      valid={(
        !pristineDescription ||
        !pristineEnabled ||
        !pristineStreamType ||
        !pristineResourceType ||
        !pristineResourceLocation ||
        !pristineResourceKey
      ) && valid}
      onClose={useCallback((saved) => {
        close(saved, description, enabled, streamType, resourceType, resourceLocation, resourceKey);
      }, [description, enabled, streamType, resourceType, resourceLocation, resourceKey])}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <StreamingConfigurationFormGroups
            valid={valid}
            handlers={{ handleTextChange, handleEnabledChange }}
            description={{ descriptionRef, pristineDescription, updatedDescription }}
            enabled={{ enabledRef, pristineEnabled, updatedEnabled }}
            streamType={{ streamTypeRef, pristineStreamType, updatedStreamType }}
            resourceType={{ resourceTypeRef, pristineResourceType, updatedResourceType }}
            resourceLocation={{ resourceLocationRef, pristineResourceLocation, updatedResourceLocation }}
            resourceKey={{ resourceKeyRef, pristineResourceKey, updatedResourceKey }}
          />
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const mapStateToProps = (state, { organisationId, streamId }) => {
  const {
    description,
    enabled,
    stream_type,
    resource_type,
    resource_location,
    resource_key,
  } = getStreamingConfiguration(state, organisationId, streamId) || {};
  return {
    description,
    enabled,
    streamType: stream_type,
    resourceType: resource_type,
    resourceLocation: resource_location,
    resourceKey: resource_key,
  };
};
const mapDispatchToProps = {
  editStreamingConfiguration,
  fetchStreamingConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStreamingConfigurationFormModal);
