import React from 'react';
import { Container } from 'react-bootstrap';

import withNavigationGroupProps from '../components/withNavigationGroupProps';
import EditGroupUsers from '../components/EditGroupUsers';
import EditGroupSummary from '../components/EditGroupSummary';

function EditGroupUsersPage(props) {
  return (
    <Container fluid>
      <EditGroupSummary {...props} />
      <EditGroupUsers {...props} />
    </Container>
  );
}

export default withNavigationGroupProps(EditGroupUsersPage);
