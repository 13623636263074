import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLocalUserPreference } from '../modules/user/actions.js';
import { getUser, getUserPreference } from '../modules/user/selectors.js';

export enum HOME_VIEW {
  STANDARD = 'standard',
  PLANT = 'plant',
  MANAGEMENT = 'management',
  FITPOWER = 'fitpower'
};

export default function useHomeView() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const view: HOME_VIEW = useSelector(state => getUserPreference(state, 'homeView')) || HOME_VIEW.STANDARD;
  const setView = useCallback((view: HOME_VIEW) => {
    user && dispatch(setLocalUserPreference(user, 'homeView', view));
  }, [user, dispatch]);

  return { view, setView };
}