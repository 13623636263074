
function createImageSrc(props) {

  const {
    fill = "#999999",
    innerFill = "#eeeeee",
    stroke = "#eeeeee",
  } = props;

  const svg = `<?xml version="1.0"?>
    <svg width="2200" height="2100" xmlns="http://www.w3.org/2000/svg">
      <title>map pin</title>
      <g>
        <path stroke-width="40" stroke-miterlimit="10" stroke="${stroke}" fill-rule="evenodd" fill="${fill}" clip-rule="evenodd" d="m1077.939941,1959.630005c-38.769897,-190.300049 -107.115967,-348.670044 -189.902954,-495.440063c-61.406982,-108.869995 -132.544006,-209.359985 -198.364014,-314.939941c-21.971985,-35.23999 -40.93396,-72.47998 -62.046997,-109.050049c-42.216003,-73.139954 -76.44397,-157.937927 -74.268982,-267.934937c2.125,-107.473022 33.208008,-193.684021 78.030029,-264.172028c73.718994,-115.934998 197.200989,-210.988983 362.883972,-235.968994c135.468994,-20.423996 262.479004,14.082001 352.539063,66.748016c73.599976,43.037994 130.599976,100.526978 173.919922,168.279999c45.219971,70.715973 76.359985,154.259979 78.969971,263.231964c1.340088,55.830017 -7.799927,107.532043 -20.679932,150.41803c-13.030029,43.408997 -33.98999,79.697998 -52.640015,118.458008c-36.410034,75.660034 -82.050049,144.97998 -127.859985,214.339966c-136.440063,206.609985 -264.5,417.310059 -320.580078,706.030029z"/>
        <circle r="183.332" cy="740.047" cx="1080.55" fill-rule="evenodd" fill="${innerFill}" clip-rule="evenodd" />
      </g>
    </svg>
  `;

  return `data:image/svg+xml;charset=utf-8;base64,${btoa(svg)}`;
}

export const greenMarkerSrc = createImageSrc({ fill: '#26EF0E' });
export const yellowMarkerSrc = createImageSrc({ fill: '#FAF719' });
export const redMarkerSrc = createImageSrc({ fill: '#FD2D20' });
export const greyMarkerSrc = createImageSrc({ fill: '#999999' });
export const gatewayMarkerSrc = createImageSrc({ fill: '#A734AF' });

export const defaultMarkerSrc = greyMarkerSrc;
