
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import UserPicture from './UserPicture';

export default function UserLink({ userId, userName }) {

  return userId ? (
    <Fragment>
      <Link to={`/users/${userId}`}><UserPicture userId={userId} /></Link>
      {' '}
      <Link to={`/users/${userId}`}>{userName || "N/A"}</Link>
    </Fragment>
  ) : "N/A";
}
