import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { fetchDevices } from '../actions';

import Table from '../../../components/Table';

import { DeviceAdminTableToolbar } from './DevicesAdmin';

import {
  siteName,
  subAreaName,
  equipmentName,
  serial,
  organisationName,
} from '../columns';

import { getArchivedDevices, getDeviceListState } from '../selectors';
import { getActiveSubGroupId } from '../../organisation/selectors';
import { Router, Redirect } from 'react-router-dom';
import history from '../../../history';
import { ApiRequestCanceller } from '../../../lib/utils';
import { SENSOR_TYPE } from '../constants';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

const defaultSorted = [{
  dataField: 'equipment_name',
  order: 'asc'
}];

const columns = [
  siteName,
  subAreaName,
  equipmentName,
  serial,
  organisationName,
];

function DevicesAdminArchived(props) {
  const { t } = useTranslation();
  const { loading, activeSubGroupId, archivedDevices=[], fetchDevices } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    const canceller = new ApiRequestCanceller();
    fetchDevices({ filter: 'archived', sensorType: SENSOR_TYPE.ALL }, canceller);
    return () => {
      dispatch(canceller.cancel());
    };
  }, []);

  const renderHeader = useCallback(props => {
    return (
      <DeviceAdminTableToolbar
        tableProps={props}
      />
    );
  }, [loading]);

  const localisedColumns = useLocalisedColumns(columns);

  if (activeSubGroupId) {
    return (
      <Router history={history}>
        <Redirect to="/devices/admin" />
      </Router>
    );
  }

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        data={archivedDevices}
        defaultSorted={defaultSorted}
        columns={localisedColumns}
        loading={loading}
        noDataIndication={() => t('screens.equipment.devices-admin-archived.no-archived-devices')}
      />
    </Container>
  );
}

const mapStateToProps = state => ({
  activeSubGroupId: getActiveSubGroupId(state),
  archivedDevices: getArchivedDevices(state),
  // todo: separate out archived device fetching into its own loading state
  loading: getDeviceListState(state).loading,
});
const mapDispatchToProps = { fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(DevicesAdminArchived);
