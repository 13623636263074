export const REQUEST_UNREAD_NOTIFICATIONS = 'notifications/REQUEST_UNREAD_NOTIFICATIONS';
export const RECEIVE_UNREAD_NOTIFICATIONS = 'notifications/RECEIVE_UNREAD_NOTIFICATIONS';
export const UNREAD_NOTIFICATIONS_FAILURE = 'notifications/UNREAD_NOTIFICATIONS_FAILURE';

export const REQUEST_ALL_NOTIFICATIONS = 'notifications/REQUEST_ALL_NOTIFICATIONS';
export const RECEIVE_ALL_NOTIFICATIONS = 'notifications/RECEIVE_ALL_NOTIFICATIONS';
export const ALL_NOTIFICATIONS_FAILURE = 'notifications/ALL_NOTIFICATIONS_FAILURE';

export const REQUEST_SET_ONE_NOTIFICATION_READ_STATUS = 'notifications/REQUEST_SET_ONE_NOTIFICATION_READ_STATUS';
export const RECEIVE_SET_ONE_NOTIFICATION_READ_STATUS = 'notifications/RECEIVE_SET_ONE_NOTIFICATION_READ_STATUS';
export const SET_ONE_NOTIFICATION_READ_STATUS_FAILURE = 'notifications/SET_ONE_NOTIFICATION_READ_STATUS_FAILURE';

export const REQUEST_DELETE_ONE_NOTIFICATION = 'notifications/REQUEST_DELETE_ONE_NOTIFICATION';
export const RECEIVE_DELETE_ONE_NOTIFICATION = 'notifications/RECEIVE_DELETE_ONE_NOTIFICATION';
export const DELETE_ONE_NOTIFICATION_FAILURE = 'notifications/DELETE_ONE_NOTIFICATION_FAILURE';

export const REQUEST_SET_NOTIFICATIONS_READ_STATUS = 'notifications/REQUEST_SET_NOTIFICATIONS_READ_STATUS';
export const RECEIVE_SET_NOTIFICATIONS_READ_STATUS = 'notifications/RECEIVE_SET_NOTIFICATIONS_READ_STATUS';
export const SET_NOTIFICATIONS_READ_STATUS_FAILURE = 'notifications/SET_NOTIFICATIONS_READ_STATUS_FAILURE';

export const REQUEST_ONE_NOTIFICATION = 'notifications/REQUEST_ONE_NOTIFICATION';
export const RECEIVE_ONE_NOTIFICATION = 'notifications/RECEIVE_ONE_NOTIFICATION';
export const ONE_NOTIFICATION_FAILURE = 'notifications/ONE_NOTIFICATION_FAILURE';

export const REQUEST_NOTIFICATION_OPTIONS = 'notifications/REQUEST_NOTIFICATION_OPTIONS';
export const RECEIVE_NOTIFICATION_OPTIONS = 'notifications/RECEIVE_NOTIFICATION_OPTIONS';
export const NOTIFICATION_OPTIONS_FAILURE = 'notifications/NOTIFICATION_OPTIONS_FAILURE';

export const RECEIVE_SET_ALL_NOTIFICATIONS = 'notifications/RECEIVE_SET_ALL_NOTIFICATIONS';