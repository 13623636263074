import React, { Component, Fragment } from 'react';
import { Row, Col, Form, Button, Dropdown }  from 'react-bootstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import withNavigationUserProps from '../components/withNavigationUserProps';

import history from '../../../history';
import { fetchOrganisations } from '../../organisation/actions';
import { getFormValues } from '../../../lib/utils';
import Title from '../../../components/Title';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NotFound from '../../../components/NotFound';

import  {
  fetchUser,
  fetchUserWithId,
  fetchUserTypeOptions,
  fetchUserPreferences,
  fetchUserDevices,
  submitUserDetails,
  submitUserWithIdDetails,
  submitNewUserDetails,
  submitUserPreferences,
} from './../actions';

import UserPageLayout from '../components/UserPageLayout';

import {
  getUser,
  getUserTags,
  getManageableUserTypes,
  isUserEditable,
  getUserTypeOptions,
} from '../selectors';
import { getOrganisations } from '../../organisation/selectors';

const defaultUserType = 'User';

function getSelectableOrganisationsByUserType(organisations, userType) {
  const sortOrganisationsByName = (a, b) => `${a.name}`.localeCompare(`${b.name}`);
  switch (userType) {
    case "Super Admin":
      return [];
    case "Partner Admin":
      return organisations.filter(org => org.is_parent).sort(sortOrganisationsByName);
    default:
      return organisations.filter(org => !org.is_parent).sort(sortOrganisationsByName);
  }
}

class EditUser extends Component {

  constructor(props) {
    super(props);
    const { userIsNew, userToEdit: { user_type = defaultUserType } = {} } = this.props;
    this.state = {
      fetchingUser: true,
      currentUserType: user_type,
      // have optional hidden by default for new users
      showOptional: !userIsNew,
      submit: {}
    };
    this.toggleOptional = this.toggleOptional.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // fetch most recent details of the user
  fetchUserDetails = async () => {
    const {
      fetchUser,
      fetchUserPreferences,
      fetchUserWithId,
      fetchUserDevices,
      userIsSelf,
      userToEdit,
      userToEditId,
    } = this.props;
    this.setState({ fetchingUser: true });
    // fetch self
    if (userIsSelf) {
      await Promise.all([
        fetchUser(),
        userToEdit && userToEdit.id && fetchUserPreferences(userToEdit),
      ]);
    }
    // or fetch other user
    else if (userToEditId) {
      try {
        const response = await fetchUserWithId(userToEdit || { id: userToEditId });
        // if the user is a User then fetch their devices list
        if (response && response.data && response.data.user_type === 'User') {
          fetchUserDevices(userToEdit || { id: userToEditId });
        }
      }
      catch(e) {
        // user probably does not have access to this user
      }
    }
    this.setState({ fetchingUser: false });
  };

  componentDidMount() {
    const { fetchOrganisations } = this.props;
    fetchOrganisations();
    this.fetchUserDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { userToEditId, userToEdit: { user_type = defaultUserType } = {} } = this.props;
    const { userToEdit: { user_type: prev_user_type = defaultUserType } = {} } = prevProps;
    // update user type state if necessary
    // case: user's user_type from API was different than kept in redux
    if ((prev_user_type !== user_type) && (prevState.currentUserType !== user_type)) {
      this.setState({ currentUserType: user_type });
    }
    // reset submit form if the user is changed
    if (prevProps.userToEditId !== userToEditId) {
      this.setState({ submit: {}, currentUserType: user_type });
      this.fetchUserDetails();
    }
  }

  toggleOptional() {
    this.setState(({ showOptional }) => {
      return {
        showOptional: !showOptional,
      };
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const formValues = getFormValues(e);
    const {
      submitUserDetails,
      submitUserWithIdDetails,
      submitNewUserDetails,
      submitUserPreferences,
      userToEdit = {},
      userIsSelf,
      userIsNew,
      fetchUserWithId,
    } = this.props;

    // transform organisation ids
    if (formValues.organisation_ids && formValues.organisation_ids.length) {
      formValues.organisation_ids = formValues.organisation_ids.map(id => parseInt(id));
    }

    // transform LinkedIn URL if needed
    if (formValues.linkedin) {
      // add https if a non-protocol link was given
      // (if you go to https://www.linkedin.com/public-profile/settings,
      // there is a relative link which you might copy-paste into this form)
      if (!formValues.linkedin.match(/^https?:\/\//)) {
        formValues.linkedin = `https://${formValues.linkedin}`;
      }
      // strip query parameters
      try {
        const linkedInUrl = new URL(formValues.linkedin);
        formValues.linkedin = `${linkedInUrl.origin}${linkedInUrl.pathname}`;
      }
      catch(e) {
        // allow Platform validation to send errors about improperly formatted URLs
      }
    }
    // remove empty linkedIn URL to prevent invalid payload errors
    else {
      formValues.linkedin = null;
    }

    const submittedAt = Date.now();
    this.setState({ submit: { submittedAt } });

    // todo: put use a form library to handle this, this is a workaround
    // it transforms { "orgs[4]": true, "orgs[5]": false } to { "orgs": [4] }
    // pick out array fields from the payload and attach them as arrays
    const checkboxInputNameRegex = /^(\w+)\[(\d+)\]$/;
    Object.entries(formValues).forEach(([key, value]) => {
      // get the identifiers from the name format
      const [match, name, id] = key.match(checkboxInputNameRegex) || [];
      if (match) {
        if (value) {
          // initialise as array if not already an array
          formValues[name] = formValues[name] || [];
          // remove this key from the main formValues payload
          formValues[name].push(parseInt(id));
        }
        delete formValues[key];
      }
    });

    const preferenceForm = {};
    // todo: put use a form library to handle this, this is a workaround
    // strip out the user preferences
    const preferenceInputNameRegex = /^(preferences:\w+)$/;
    Object.entries(formValues).forEach(([key, value]) => {
      // get the identifiers from the name format
      const [match, name] = key.match(preferenceInputNameRegex) || [];
      if (match) {
        if (value) {
          preferenceForm[`${name}`] = value;
        }
        // remove this key from the main formValues payload
        delete formValues[key];
      }
    });

    // submit form then handle feedback
    userToEdit && (
      userToEdit.id
        ? userIsSelf
          ? submitUserDetails(userToEdit, formValues)
          : submitUserWithIdDetails(userToEdit, formValues)
        : submitNewUserDetails(formValues)
    )
      .then(async response => {

        if (userIsSelf && Object.keys(preferenceForm).length) {
          try {
            await submitUserPreferences(userToEdit, preferenceForm);
          }
          catch (err) {
            // allow user edit to pass even if edit preferences fails
            // they should get a toast
          }
        }

        if (userIsNew && response && response.headers && response.headers.location) {
          const matches = `${response.headers.location}`.match(/(\d+)$/);
          if (matches && matches[1]) {
            if(Object.keys(preferenceForm).length) {
              await submitUserPreferences({ id: matches[1] }, preferenceForm);
            }
            await fetchUserWithId({ id: matches[1] });
            // push user to user devices page if relevant, or user activity page
            if (formValues['user_type'] === 'User') {
              history.push(`/users/${matches[1]}/equipment/edit`);
            }
            else {
              history.push(`/users/${matches[1]}`);
            }
          }
          else {
            history.push('/users/admin');
          }
        }
        else {
          // refetch the new user data
          this.fetchUserDetails();
          // if still displaying the same submission then update with success
          this.setState(({ submit }) => {
            if (submit.submittedAt === submittedAt) {
              return { submit: { succeededAt: new Date() } };
            }
          });
        }
      })
      .catch((error) => {
        // if still displaying the same submission then update with failure
        this.setState(({ submit }) => {
          if (submit.submittedAt === submittedAt) {
            return { submit: { error: error.message || 'Error' } };
          }
        });
      });
  }

  render() {
    const {
      userIsNew = false,
      userIsSelf = false,
      userIsEditable = false,
      userToEdit: userObject = {},
      organisations = [],
      manageableUserTypes = [],
      userTypeOptions = {},
      userTags = {},
      t
    } = this.props;
    const {
      organisations: userOrganisations=[],
    } = userObject._embedded || {};
    const organisationIds = organisations.map(({ id }) => id);
    const previousUserOrganisationIds = userOrganisations
      .map(org => org.organisation_id)
      .filter(id => organisationIds.includes(id));

    const { showOptional, fetchingUser, submit, currentUserType } = this.state;

    const selectableOrganisations = getSelectableOrganisationsByUserType(organisations, currentUserType);

    if (!userIsEditable && !fetchingUser) {
      return (
        <NotFound />
      );
    }

    return (
      <UserPageLayout>
        <Title
          title={
            userIsNew
              ? t('header.invite-user')
              : userIsSelf
                ? t('user.Edit_my_profile')
                : t('user.Editing_user') + `${userObject ? `: ${userObject.name}` : ''}`
          }
          loading={submit.submittedAt}
          lastFetch={submit.succeededAt}
          error={submit.error}
        />
        {!userObject || !userIsEditable ? <LoadingSpinner /> : <Form
          id="edit-user-form"
          className="form-container"
          onSubmit={ this.handleSubmit }
        >
          <Form.Group as={Row} controlId="form__edit_user--name">
            <Form.Label column sm="2" xs="4">
              {t('Name')}
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`name-${userObject.name}`}
                type="text"
                placeholder={t('Name')}
                name="name"
                defaultValue={ userObject.name || "" }
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId={userIsNew ? "form__edit_user--email" : undefined}>
            <Form.Label column sm="2" xs="4">
              {t('Email')}
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`email-${userObject.email}`}
                plaintext={!userIsNew}
                readOnly={!userIsNew}
                placeholder={t('Email')}
                name={userIsNew ? 'email' : undefined}
                defaultValue={ userObject.email || "" }
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="form__edit_user--user_type">
            <Form.Label column sm="2" xs="4">
              {t('Type')}
            </Form.Label>
            <Col sm="8" xs="6">
              {userIsNew || (!userIsSelf && manageableUserTypes.includes(userObject.user_type)) ? (
                <Form.Control
                  key={`user_type-${userObject.user_type}`}
                  as="select"
                  value={currentUserType}
                  name="user_type"
                  onChange={e => this.setState({ currentUserType: e.target.value })}
                >
                  {manageableUserTypes.map(v => (
                    <option key={v} value={v}>{userTypeOptions[v]?.display_name || v}</option>
                  ))}
                </Form.Control>
              ) : (
                <Form.Control
                  plaintext={true}
                  readOnly={true}
                  key={`user_type-${userObject.user_type}`}
                  defaultValue={ userTypeOptions[userObject.user_type]?.display_name || userObject.user_type }
                />
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="form__edit_user--position">
            <Form.Label column sm="2" xs="4">
              {t('Position')}
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`position-${userObject.position}`}
                type="text"
                placeholder={t('Position')}
                name="position"
                defaultValue={ userObject.position || "" }
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="form__edit_user--location">
            <Form.Label column sm="2" xs="4">
              {t('user.Work_location')}
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`location-${userObject.location}`}
                type="text"
                placeholder={t('user.Work_location')}
                name="location"
                defaultValue={ userObject.location || "" }
                required
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="form__edit_user--department">
            <Form.Label column sm="2" xs="4">
              {t('Department')}
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`department-${userObject.department}`}
                type="text"
                placeholder={t('Department')}
                name="department"
                defaultValue={ userObject.department || "" }
                required
              />
            </Col>
          </Form.Group>
          {userIsNew && <Row>
            <Col xs="10">
              <Dropdown
                className="text-right"
                onToggle={this.toggleOptional}
                drop={showOptional ? 'up' : 'down'}
              >
                <Dropdown.Toggle
                  size="sm"
                  variant="outline-secondary"
                >
                  {t('Optional')}
                </Dropdown.Toggle>
              </Dropdown>
            </Col>
          </Row>}
          {showOptional && <Form.Group as={Row}>
            <Form.Label column sm="2" xs="4" htmlFor="form__edit_user--mobile">
              {t('user.Mobile_phone')}
            </Form.Label>
            <Col sm="2" xs="4">
              <Form.Control
                key={`country_code-${userObject.country_code}`}
                id="form__edit_user--mobile"
                type="text"
                name="country_code"
                placeholder={t('Code')}
                defaultValue={ userObject.country_code || "" }
              />
            </Col>
            <Col sm="4" xs="4">
              <Form.Control
                key={`mobile_phone-${userObject.mobile_phone}`}
                type="text"
                name="mobile_phone"
                placeholder="(0)999 999 999"
                defaultValue={ userObject.mobile_phone || "" }
              />
            </Col>
          </Form.Group>}
          {showOptional && <Form.Group as={Row} controlId="form__edit_user--linkedin">
            <Form.Label column sm="2" xs="4">
              {t('user.LinkedIn_profile')}
            </Form.Label>
            <Col sm="8" xs="6">
              <Form.Control
                key={`linkedin-${userObject.linkedin}`}
                type="text"
                name="linkedin"
                defaultValue={ userObject.linkedin || "" }
              />
            </Col>
          </Form.Group>}
          {!userIsSelf && selectableOrganisations.length > 0 && (
            <Form.Group
              as={Row}
              // hide form group if only one organisation is selectable
              className={selectableOrganisations.length === 1 ? 'd-none' : ''}
            >
              <Form.Label column sm="2" xs="4">
                {t('user.Select_Organisations')}
              </Form.Label>
              <Col sm="8" xs="6">
                {selectableOrganisations.map(({ id, name }) => (
                  <Form.Check
                    key={`organisations-${id}`}
                    id={`organisations-${id}`}
                    type="checkbox"
                    label={name}
                    name={`organisation_ids[${id}]`}
                    defaultChecked={
                      selectableOrganisations.length === 1
                        ? true
                        : previousUserOrganisationIds.includes(id)
                    }
                  />
                ))}
              </Col>
            </Form.Group>
          )}
          {showOptional && userIsNew && (
            <Fragment>
              <br/>
              <Title title={t('user.Email_sms_preferences')} loading={false} />
              <Form.Group controlId="form__edit_user--receive_daily_email">
                <Form.Check
                  key={`receive_daily_email-${userObject.receive_daily_email}`}
                  type="checkbox"
                  label={t('user.Daily_Update_Email')}
                  name="receive_daily_email"
                  defaultChecked={userObject.receive_daily_email}
                />
              </Form.Group>
              <Form.Group controlId="form__edit_user--receive_alert_email">
                <Form.Check
                  key={`receive_alert_email-${userObject.receive_alert_email}`}
                  type="checkbox"
                  label={t('user.Alarm_Channel_Notifications_via_Email')}
                  name="receive_alert_email"
                  defaultChecked={userObject.receive_alert_email}
                />
              </Form.Group>
              <Form.Group controlId="form__edit_user--receive_alert_sms">
                <Form.Check
                  key={`receive_alert_sms-${userObject.receive_alert_sms}`}
                  type="checkbox"
                  label={t('user.Alarm_Channel_Notifications_via_SMS')}
                  name="receive_alert_sms"
                  defaultChecked={userObject.receive_alert_sms}
                />
              </Form.Group>
              <Form.Group controlId="form__edit_user--mentions_via_email">
                <Form.Check
                  key="mentions_via_email"
                  type="checkbox"
                  label={t('user.Mentions_via_Email')}
                  name="preferences:email_mentions"
                  defaultChecked={userTags['preferences:email_mentions'] === true}
                />
              </Form.Group>
            </Fragment>
          )}
          <br/>
          <Form.Group className="text-right mb-chat-widget">
            <Button variant="success" type="submit" size="lg">
              {userIsNew ? t('Invite') : t('Update')}
            </Button>
          </Form.Group>
        </Form>}
      </UserPageLayout>
    );
  }
}

const mapStateToProps = (state, { userIsSelf, userIsNew, userId }) => {
  return {
    organisations: getOrganisations(state),
    manageableUserTypes: getManageableUserTypes(state),
    userIsNew,
    userIsSelf,
    userIsEditable: isUserEditable(state, { userIsSelf, userIsNew, userId }),
    userToEditId: userId,
    userToEdit: userId && getUser(state, userId),
    userTypeOptions: getUserTypeOptions(state),
    userTags: userId && getUserTags(state, userId),
  };
};

const mapDispatchToProps = {
  fetchUser,
  fetchUserWithId,
  fetchOrganisations,
  fetchUserPreferences,
  fetchUserDevices,
  fetchUserTypeOptions,
  submitUserDetails,
  submitUserWithIdDetails,
  submitNewUserDetails,
  submitUserPreferences,
};

export default withNavigationUserProps(
  connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(EditUser)
  )
);
