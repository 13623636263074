import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchUser, fetchUserPreferences } from '../../modules/user/actions';
import { fetchOrganisations, fetchOrganisationPreferences, fetchOrganisationWithId } from '../../modules/organisation/actions';

import { getOrganisation, getOrganisationState, getOrganisationTags, getOrganisationProductCodes } from '../../modules/organisation/selectors';
import { getUser, getUserTags, getUserToken, isUserLoggedIn } from '../../modules/user/selectors';
import { usePoll } from '../../hooks/usePoll';
import { getIntervalMilliSeconds } from '../../components/lib/utils';
import { fetchDeviceStatus } from '../../modules/equipment/actions';

const { REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES = 5 } = process.env;
const checkPeriods = getIntervalMilliSeconds(REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES);

function AuthProvider({
  children,
  userToken,
  userIsLoggedIn,
  user = {},
  userTags,
  organisation,
  organisationTags,
  organisationProductCodes,
  fetchUser,
  fetchOrganisations,
  fetchOrganisationWithId,
  fetchUserPreferences,
  fetchOrganisationPreferences,
  fetchDeviceStatus,
  onLogout,
  onLogin,
}) {

  const userId = user && user.id;
  const hasToken = !!userToken;

  /*
   * Begin UserLogin / AppLoad logic to populate redux state
   * when complete, the isUserLoggedIn selector --> userIsLoggedIn prop should be true
   */

  // if the token changes then reset the fetching state of everything here
  useEffect(() => {
    setFetchingUser(false);
    setFetchingUserTags(false);
    setFetchingOrganisation(false);
    setFetchingOrganisations(false);
    setFetchingOrganisationTags(false);
  }, [userToken]);

  // ensure user exists
  const [fetchingUser, setFetchingUser] = useState(false);
  useEffect(() => {
    if (hasToken && !userId && !fetchingUser) {
      setFetchingUser(true);
      fetchUser();
    }
    else if (userId) {
      setFetchingUser(false);
    }
  }, [hasToken, userId, fetchingUser, fetchUser]);

  // ensure user tags exist
  const [fetchingUserTags, setFetchingUserTags] = useState(false);
  useEffect(() => {
    if (hasToken && userId && !userTags && !fetchingUserTags) {
      setFetchingUserTags(true);
      fetchUserPreferences(user);
    }
    else if (userTags) {
      setFetchingUserTags(false);
    }
  }, [hasToken, user, userId, userTags, fetchingUserTags, fetchUserPreferences]);

  // ensure organisations exists
  const [fetchingOrganisations, setFetchingOrganisations] = useState(false);
  useEffect(() => {
    if (hasToken && userId && !organisation && !fetchingOrganisations) {
      setFetchingOrganisations(true);
      fetchOrganisations();
    }
    else if (organisation) {
      setFetchingOrganisations(false);
    }
  }, [hasToken, userId, organisation, fetchingOrganisations, setFetchingOrganisations]);

  // ensure organisation exists
  const [fetchingOrganisation, setFetchingOrganisation] = useState(false);
  useEffect(() => {
    if (hasToken && organisation && !organisationProductCodes && !fetchingOrganisation) {
      setFetchingOrganisation(true);
      fetchOrganisationWithId(organisation);
      fetchDeviceStatus();  // Fetch available device status on log in.
    } else if (organisationProductCodes) {
      setFetchingOrganisation(false);
    }
  }, [hasToken, organisation, organisationProductCodes, fetchingOrganisation, fetchOrganisationWithId]);

  // check organisation product codes regularly
  usePoll(() => {
    if(organisation) {
      fetchOrganisationWithId(organisation);
    }
  }, [organisation?.id, fetchOrganisationWithId], { skipFirstTime: true, interval: checkPeriods });

  // ensure organisation tags exist
  const [fetchingOrganisationTags, setFetchingOrganisationTags] = useState(false);
  useEffect(() => {
    if (hasToken && organisation && !organisationTags && !fetchingOrganisationTags) {
      setFetchingOrganisationTags(true);
      fetchOrganisationPreferences(organisation);
    }
    else if (organisationTags) {
      setFetchingOrganisationTags(false);
    }
  }, [hasToken, organisation, organisationTags, fetchingOrganisationTags, fetchUserPreferences]);

  // check organisation tags regularly
  usePoll(() => {
    if(organisation) {
      fetchOrganisationPreferences(organisation);
    }
  }, [organisation?.id, fetchOrganisationPreferences], { skipFirstTime: true, interval: checkPeriods });

  /*
   * End UserLogin / AppLoad logic to populate redux state
   */

  // provide lifecycle hooks
  useEffect(() => {
    if (userIsLoggedIn) {
      onLogin && onLogin();
    }
    else {
      onLogout && onLogout();
    }
  }, [userIsLoggedIn]);

  return children;
}

const mapStateToProps = state => {
  const organisation = getOrganisation(state);
  return {
    userToken: getUserToken(state),
    userIsLoggedIn: isUserLoggedIn(state),
    user: getUser(state),
    userTags: getUserTags(state),
    organisation,
    organisationState: organisation && getOrganisationState(state, organisation.id),
    organisationTags: getOrganisationTags(state),
    organisationProductCodes: getOrganisationProductCodes(state),
  };
};
const mapDispatchToProps = {
  fetchUser,
  fetchOrganisations,
  fetchOrganisationWithId,
  fetchUserPreferences,
  fetchOrganisationPreferences,
  fetchDeviceStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
