import { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { setDeviceSelectedStatus } from '../actions';
import { setLocalUserPreference } from '../../user/actions';
import { getDeviceStatusOptions } from '../selectors';
import { getUser } from '../../user/selectors';

function useDeviceStatusFilter() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const statusOptions = useSelector(getDeviceStatusOptions, shallowEqual);

  const filterAll = useCallback(() => {
    if(statusOptions?.length > 0) {
      dispatch(setDeviceSelectedStatus([...statusOptions]));
      dispatch(setLocalUserPreference(user, 'filtered_options', [...statusOptions]));
    };
  }, [statusOptions]);

  const filter = useCallback((options) => {
    if(Array.isArray(options)) {
      dispatch(setDeviceSelectedStatus([...options]));
      dispatch(setLocalUserPreference(user, 'filtered_options', [...options]));
    }
  }, []);

  return { filterAll, filter };
}

export default useDeviceStatusFilter;