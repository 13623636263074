import ReactEcharts from 'echarts-for-react';
import HomeCard from './HomeCard';
import { useEventImpactPreventedData } from '../../modules/organisation/hooks/useGroupEventsReport';
import { getChartLabelColor } from '../lib/utils';
import { useTranslation } from 'react-i18next';

function PreventedLosses() {
  const { t } = useTranslation();
  const eventImpactPrevented = useEventImpactPreventedData();
  if(!eventImpactPrevented) return null;

  const {_state_: { loading, error } = {}, items = []} = eventImpactPrevented || {};

  return (
    <HomeCard
      header={t('home.prevented-losses.header')}
      headerLink={{to: '/', text: t('home.all-equipment')}}
      loading={loading}
      error={error}
    >
      <ReactEcharts
        option={{
          grid: {
            top: 10,
            bottom: 80,
            left: 80,
            right: 10
          },
          tooltip: {
            trigger: 'axis',
            position: (pos) => ({
              left: pos[0] + 10,
              top: pos[1]
            })
          },
          legend: {
            textStyle: {
              fontSize: 10,
            },
            bottom: -5,
            data: [
              t('home.common.avoided-costs'),
              t('home.common.event-costs'),
            ]
          },
          xAxis: {
            data: items.map(i => i.group_name),
            axisLabel: {
              show: true,
              interval: items.length <= 15 ? 0 : 'auto',
              rotate: 30,
              fontWeight: 'normal',
            }
          },
          yAxis: [{
            type: 'value'
          }],
          color: ['green', 'red'].map(color => getChartLabelColor(color)),
          series: [{
            name: t('home.common.avoided-costs'),
            type: 'bar',
            stack: 'one',
            data: items.map(i => i.avoided_costs)
          }, {
            name: t('home.common.event-costs'),
            type: 'bar',
            stack: 'one',
            data: items.map(i => i.event_costs * -1)
          }]
        }}
      />
    </HomeCard>
  );
}

export default PreventedLosses;
