export function getAppVersion(state) {
  return state && state.app && state.app.version;
}

export function getAppPreviousVersion(state) {
  return state && state.app && state.app.previousVersion;
}

export function getAppBuildNumber(state) {
  return state && state.app && state.app.build;
}

export function getHeaderHeight(state) {
  return state?.app?.headerHeight || 0;
}

export function getSidebar(state) {
  return state?.app?.sidebar || {};
}