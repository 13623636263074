// Hourly current usage trend tile for Energy Dash.
import { memo } from 'react';
import ReactEcharts from 'echarts-for-react';
import HomeCard from './HomeCard';
import { getChartLabelColor } from '../lib/utils';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';

function CurrentUsageTrend() {

  const items = [];
  for(let i = 0; i <= 23; i++) {
    items.push({time: i + ':00', today: Math.floor(Math.random()*1000), yesterday: Math.floor(Math.random()*1000)});
  } // @TODO: dummy data.
  const { filterAll } = useDeviceStatusFilter();

  return (
    <HomeCard
      header="Hourly current usage trend"
      headerLink={{to: '/equipment/list?type=fitpower', text: 'All equipment', onClick: filterAll}}
    >
      <ReactEcharts
        option={{
          title: {
            text: ''
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['Today', 'Yesterday'],
            bottom: 0,
          },
          grid: {
            left: 50,
            top: 10,
          },
          color: ['blue', 'orange'].map(color => getChartLabelColor(color)),
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: items.map(item => item.time),
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: 'Today',
              type: 'line',
              stack: 'Total',
              data: items.map(item => item.today),
              smooth: .4,
            },
            {
              name: 'Yesterday',
              type: 'line',
              stack: 'Total',
              data: items.map(item => item.yesterday),
              smooth: .4,
            }
          ]
        }}
      />
    </HomeCard>
  );
}

export default memo(CurrentUsageTrend);