export const APP_LOAD = 'app/APP_LOAD';
export const FOOTER_SIZE = 'app/FOOTER_SIZE';
export const HEADER_HEIGHT = 'app/HEADER_HEIGHT';
export const REQUEST_APP_VERSION_CHECK = 'app/REQUEST_APP_VERSION_CHECK';
export const RECEIVE_APP_VERSION_CHECK = 'app/RECEIVE_APP_VERSION_CHECK';
export const APP_VERSION_CHECK_FAILURE = 'app/APP_VERSION_CHECK_FAILURE';
export const APP_EVENT = 'app/APP_EVENT';
export const APP_PAGE = 'app/APP_PAGE';

export const REQUEST_USER_ACTION_CREATE = 'app/REQUEST_USER_ACTION_CREATE';
export const RECEIVE_USER_ACTION_CREATE = 'app/RECEIVE_USER_ACTION_CREATE';
export const USER_ACTION_CREATE_FAILURE = 'app/USER_ACTION_CREATE_FAILURE';

export const ACTIVITY_TRACK = 'app/ACTIVITY_TRACK';
export const SET_SIDEBAR_WIDTH = 'app/SET_SIDEBAR_WIDTH';
export const SET_IS_DRAGGING = 'app/SET_IS_DRAGGING';
export const SET_SIDEBAR = 'app/SET_SIDEBAR';