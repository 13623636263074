import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDeviceAlarms, getDeviceAlarmListState } from '../selectors';
import { fetchDeviceAlarms } from '../actions';

function useDeviceAlarms(deviceId) {
  const dispatch = useDispatch();
  const deviceAlarms = useSelector(state => getDeviceAlarms(state, deviceId)) || [];
  const {loading, error, lastFetch} = useSelector(state => getDeviceAlarmListState(state, deviceId)) || {};
  const fetchDeviceAlarmsData = useCallback(() => {
    dispatch(fetchDeviceAlarms({id: deviceId}));
  }, [deviceId]);

  useEffect(() => {
    fetchDeviceAlarmsData();
  }, [deviceId]);

  return {
    loading,
    error,
    lastFetch,
    deviceAlarms,
  };
}

export default useDeviceAlarms;