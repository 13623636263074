import { useState, useEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { debounce } from '../lib/utils';

function useResizeObservedRef(initialValue) {

  const defaultOnErrorFn = useRef(window.onerror);

  useEffect(() => {
    window.onerror = (...args) => {
      if (args[0] === 'ResizeObserver loop limit exceeded') { //
        return true;
      }
      defaultOnErrorFn.current && defaultOnErrorFn.current(...args);
    };
    return () => {
      window.onerror = defaultOnErrorFn.current;
    };
  }, []);

  // set element
  const [elementRef, setElementRef] = useState(initialValue);

  // observe resize changes in element
  useEffect(() => {
    // set up observer
    const observer = new ResizeObserver(debounce(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200));
    // set up observer listener
    if (elementRef) {
      observer.observe(elementRef);
    }
    // clean up observer listener
    return () => {
      if (elementRef) {
        observer.unobserve(elementRef);
      }
    };
  }, [elementRef]);

  return setElementRef;
}

export default useResizeObservedRef;