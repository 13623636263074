import React, { useEffect, useCallback, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import moment from 'moment';

import { fetchGateways } from '../actions';
import { fetchDevices } from '../../equipment/actions';

import Table from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';

import {
  siteName,
  subAreaName,
  connectedDevices,
  name,
  type,
  ssid,
  serial,
  organisationName,
  gwLastHeard,
  gwLastHeardHoursWarning,
  gwLastHeardHoursDanger,
  actions,
} from '../columns';

import { getGatewayListState, getConnections } from '../selectors';
import { getActiveSubGroupId } from '../../organisation/selectors';
import { getDeviceListState } from '../../equipment/selectors';
import { ApiRequestCanceller } from '../../../lib/utils';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

function NoDataIndication() {
  const t = i18n.t;
  return t('screens.gateways.gateways-admin.no-gateways');
}

export const GatewayAdminTableToolbar = connect(getGatewayListState)(({
  loading,
  lastFetch,
  error,
  tableProps,
}) => {
  const { t } = useTranslation();
  return (
    <TableToolbar
      searchable
      title={t('screens.gateways.gateways-admin.title')}
      loading={loading}
      lastFetch={lastFetch}
      error={error}
      tableProps={tableProps}
    />
  );
});

export const defaultSorted = [{
  dataField: 'last_heard',
  order: 'desc',
}];

export const renderGatewayAdminTimeColours = (value) => {
  if (moment(value).add({ hours: gwLastHeardHoursWarning }).isAfter()) {
    return {
      backgroundColor: '#81c784'
    };
  } else if (moment(value).add({ hours: gwLastHeardHoursDanger }).isAfter()) {
    return {
      backgroundColor: '#fff176'
    };
  } else {
    return {
      backgroundColor: '#e57373'
    };
  }
};

export const columns = [
  siteName,
  subAreaName,
  name,
  type,
  ssid,
  serial,
  connectedDevices,
  organisationName,
  // add styles to timestamps
  {
    ...gwLastHeard,
    style: renderGatewayAdminTimeColours,
  },
  actions,
];

function GatewaysAdmin({
  loading,
  activeSubGroupId,
  connections = [],
  fetchDevices,
  fetchGateways,
}) {

  // fetch gateways on mount
  useEffect(() => {
    fetchGateways();
  }, [fetchGateways]);

  const dispatch = useDispatch();
  // fetch devices on group change
  useEffect(() => {
    const canceller = new ApiRequestCanceller();
    fetchDevices({}, canceller);
    return () => {
      dispatch(canceller.cancel());
    };
  }, [activeSubGroupId, fetchDevices]);

  const activeGateways = useMemo(() => {
    return connections
      // show only active gateways and devices
      .filter(([gateway, device]) => !gateway.archived && !device.archived)
      // derive gateways with connections from this
      .reduce((acc, [gateway, device, { last_heard }]) => {
        // create sparse array
        acc[gateway.id] = acc[gateway.id] || { ...gateway, _related: { devices: [] } };
        // add the device and related data
        acc[gateway.id]._related.devices.push({ ...device, last_heard });
        return acc;
      }, [])
      // filter sparse array down to relevant gateways
      .filter(Boolean);
  }, [connections]);

  const localisedColumns = useLocalisedColumns(columns);

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={useCallback(props => (
          <GatewayAdminTableToolbar tableProps={props} />
        ), [])}
        data={activeGateways}
        defaultSorted={defaultSorted}
        loading={loading}
        noDataIndication={NoDataIndication}
        columns={localisedColumns}
      />
    </Container>
  );
}

const mapStateToProps = state => ({
  activeSubGroupId: getActiveSubGroupId(state),
  connections: getConnections(state),
  loading: getGatewayListState(state).loading || getDeviceListState(state).loading,
});
const mapDispatchToProps = { fetchDevices, fetchGateways };

export default connect(mapStateToProps, mapDispatchToProps)(GatewaysAdmin);
