import { useHistory } from 'react-router-dom';
import AlarmImpactSettings from '../../alarm/components/AlarmImpactSettings';
import GroupPageLayout from "../components/GroupPageLayout";
import useGroup from '../hooks/useGroup';
import { canAccessGroupImpact } from '../components/GroupPageLayout';

function EditGroupImpact() {
  const history = useHistory();
  const { group } = useGroup();

  if(!canAccessGroupImpact(group)) {
    history.push('/group/config');
    return null;
  }

  return (
    <GroupPageLayout>
      <AlarmImpactSettings groupId={group.id} />
    </GroupPageLayout>
  );
}

export default EditGroupImpact;