import { Container, Row, Col } from 'react-bootstrap';
import Title from '../../../components/Title';
import CurrentConsumptionTrend from '../../../components/home/CurrentConsumptionTrend';
import CurrentUsageTrend from '../../../components/home/CurrentUsageTrend';
import FitPowerStatus from '../../../components/home/FitPowerStatus';
import HighestEnergy from '../../../components/home/HighestEnergy';
import NonReportingDevice from '../../../components/home/NonReportingDevice';
import StartStopTrend from '../../../components/home/StartStopTrend';
import './home.scss';

function HomeEnergyView() {
  return (
    <div className="home">
      <Container fluid>
        <Title
          title="Energy"
        />
        <Row noGutters>
          <Col xl={{span: 3, order: 1}} lg={6}>
            <HighestEnergy />
          </Col>
          <Col xl={{span: 6, order: 2}} lg={{span: 12, order: 'last'}}>
            <CurrentConsumptionTrend />
          </Col>
          <Col xl={{span: 3, order: 3}} lg={6}>
            <FitPowerStatus />
          </Col>
        </Row>
        <Row noGutters className="smaller-font-size-table-container">
          <Col xl={{span: 3, order: 1}} lg={6}>
            <CurrentUsageTrend />
          </Col>
          <Col xl={{span: 6, order: 2}} lg={{span: 12, order: 'last'}}>
            <StartStopTrend />
          </Col>
          <Col xl={{span: 3, order: 3}} lg={6}>
            <NonReportingDevice sensorType="fitpower" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeEnergyView;