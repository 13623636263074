
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// fix potentially mangled URLs (from AppCues)
// eg. `/equipment?virtual?appcue-197a8c7c-f5c5-46a7-b90d-30ad43a62966?hey_appcues`
// because AppCues don't seem to understand how URLs work
// the above URL will not track user progress of an AppCue flow correctly
// as AppCue's own query parameter check will not parse the UUID they applied
function fixAppCuesURLs(history) {

  // only fix if a "?" char is found in the search body (past the first "?" char)
  const searchWithoutPrefix = `${window.location.search}`.slice(1);
  if (searchWithoutPrefix.includes('?')) {

    // attempt to split out the correct keys for AppCues specific parameters
    // known cases are 'appcue', 'showappcue', 'hey_appcues', and 'appcuesTestContentId'
    // however there may be many more prefixed with 'appcue', 'appcue-', or 'appcues-'
    // also pre-empting the existence of 'something-app-cue' or 'something_app_cue'
    const correctSearchWithoutPrefix = searchWithoutPrefix
      // replace all `?appcue`-like keys with `&appcue`-like keys
      .replace(/\?&?((?:[a-z]+[_-]?)?app[_+]?cue)/gi, '&$1');

    // if the URL is not correct then replace the current page with this one
    // (don't history.push, as we don't want the current page in the browser history)
    if (correctSearchWithoutPrefix !== searchWithoutPrefix) {
      history.replace({
        search: `?${correctSearchWithoutPrefix}`,
      });
    }
  }

}

// note: if a Component mapStateToProps passes the query object directly
// then the Component will update on every location change due to the history listener.
// Components should import individual query props to avoid this.
function addLocationQuery(history) {

  // use URLSearchParams as the query parser
  const params = new URLSearchParams(history.location.search);

  // set an object only if query parameters are found
  const paramEntries = [...params.entries()];
  history.location.query = paramEntries.length
    // create a simple key/value string object of found parameters
    ? paramEntries.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
    // unset the query object if no params are present
    // this moves the concern of a default query object to the implementation of each usage
    : undefined;
}

// define update handler
function updateLocation() {
  fixAppCuesURLs(history);
  addLocationQuery(history);
}

// update immediately
updateLocation();

// and listen for changes
// ensure updates are handled on each page change
history.listen(updateLocation);

export default history;
