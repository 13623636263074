
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  IoIosTrash,
} from "react-icons/io";

import { archiveDevice } from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import Private from '../../../components/Private';
import { getDevice } from '../selectors';

function ArchiveDeviceModal({
  device,
  archiveDevice,
  onSuccess,
  onError,
  ...props
}) {

  const { t } = useTranslation();
  const onClick = useCallback(async () => {
    try {
      await archiveDevice(device);
      if (onSuccess) {
        onSuccess(device);
      }
    }
    catch(e) {
      if (onError) {
        onError(device);
      }
    }
  }, [archiveDevice, device]);

  return (
    <Private minUserType="Admin">
      <ConfirmModal
        confirmText={t('Archive')}
        body={t('components.equipment.archived-device-modal.archived-devices-continue')}
        {...props}
      >
        <Dropdown.Item onClick={onClick}>
          <IoIosTrash size="1.2em" /> {t('Archive')}
        </Dropdown.Item>
      </ConfirmModal>
    </Private>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
});
const mapDispatchToProps = { archiveDevice };

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveDeviceModal);
