import { Link, useHistory, useLocation } from "react-router-dom";

function EquipmentNameLink({ to, children, formatExtraData = {} }) {
  const history = useHistory();
  const location = useLocation();
  const { searchText = '' } = formatExtraData;

  return (
    <Link to={to} onClick={e => {
      e.stopPropagation();
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.set('s', searchText);
      history.push(`?${urlSearchParams.toString()}`);
    }}>
      {children}
    </Link>
  );
}

export default EquipmentNameLink;