import React from 'react';
import { Alert } from 'react-bootstrap';

import './appNotificationBar.scss';

export default function AppNotificationBar({
  floating,
  className,
  children,
  ...props
}) {

  return (
    <Alert
      {...props}
      className={
        [
          className,
          'notification-bar',
          `notification-bar--${floating ? 'floating' : 'non-floating'}`,
        ].filter(Boolean).join(' ')
      }
    >
      {children}
    </Alert>
  );
}
