import { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import bugsnag, {
  registerBeforeSendCallback,
  unregisterBeforeSendCallback,
} from '../../bugsnag';

import { getOrganisation } from '../../modules/organisation/selectors';
import { getUser, isUserLoggedIn } from '../../modules/user/selectors';

function BugSnagProvider({
  children,
  userIsLoggedIn,
  user = {},
  organisation = {},
}) {

  useEffect(() => {
    if (!userIsLoggedIn) {
      bugsnag.clearUser();
    }
  }, [userIsLoggedIn]);

  // bugsnag error context provider
  const beforeSendCallback = useCallback(report => {
    const { id, name, sub_domain } = organisation;
    const { metaData={} } = report;
    report.metaData = {
      ...metaData,
      organisation: { ...metaData.organisation, id, name, sub_domain },
    };
  }, []);

  // set BugSnag callbacks
  useEffect(() => {
    // register bugsnag report callback
    registerBeforeSendCallback(beforeSendCallback);
    // unregister bugsnag report callback
    return () => unregisterBeforeSendCallback(beforeSendCallback);
  }, []);

  // set User in BugSnag
  useEffect(() => {
    // capture either the initial user token fetch or the fully logged in user
    // and any time any of these props change
    if (user.email) {
      bugsnag.setUser(user.id && `${user.id}`, user.name, user.email);
    }
  }, [user.id, user.name, user.email]);

  return children;
}

const mapStateToProps = state => ({
  userIsLoggedIn: isUserLoggedIn(state),
  user: getUser(state),
  organisation: getOrganisation(state),
});

export default connect(mapStateToProps)(BugSnagProvider);
