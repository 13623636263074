import { Dispatch } from 'redux';
import { CALL_API, isApiCancelled} from '../../lib/apiMiddleware';
import { addToast } from '../../components/Toaster';
import { Alarm, ImageResponse, PostEvent, PostUserAlarm, AlarmResponse, Impact } from './types';
import { DeviceMappedFromAPI } from '../equipment/types/Device';
import * as ACTION_TYPES from './types/ActionTypes';
import * as AlarmAction from './types/Action';
import { ApiRequestCanceller } from '../../lib/utils';

export function requestBegin(): AlarmAction.RequestBeginAction {
  return {
    type: ACTION_TYPES.REQUEST_BEGIN,
  };
}

export function requestFail(): AlarmAction.RequestFailAction {
  return {
    type: ACTION_TYPES.REQUEST_FAIL,
  };
}

export function receiveAlarmsList(alarmType: string, view: string): AlarmAction.ReceiveAlarmsListAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARMS_LIST,
    alarmType,
    view,
  };
}

// alarms may be of type 'condition', 'threshold', 'user' or 'all'
export function fetchAlarms(options: {type: string, batch: boolean, view: string}, canceller: ApiRequestCanceller):
  (dispatch: Dispatch<AlarmAction.FetchAlarmsAction>) => AlarmAction.FetchAlarmsAction
{
  const { type = 'all', batch = true, view } = options || {};
  if (batch) return fetchAlarmsInBatch(options, canceller);
  const queryString = new URLSearchParams(view === 'event' ? { view }: { type }).toString();
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms${
        // append query string
        queryString ? `?${queryString}` : ''
      }`,
      requestAction: requestBegin(),
      successAction: receiveAlarmsList(type, view),
      errorAction: requestFail(),
    });
  };
}

export function fetchAlarmsInBatch({ type = 'all', view }: { type: string, batch: boolean, view: string}, canceller: ApiRequestCanceller) {
  const queryString = new URLSearchParams(view === 'event' ? { view }: { type }).toString();
  return (dispatch: any) => { // @TODO: Fix any type.
    const endpoint = `/alarms${queryString ? `?${queryString}` : ''}`;
    const dispatchOptions = {
      type: CALL_API,
      method: 'get',
      endpoint,
      params: {
        snap_page: 0,
      },
      requestAction: requestBegin(),
      successAction: { type: 'none' },
      errorAction: requestFail(),
      abortControllerId: canceller?.id,
    };

    async function successHandler({data}: {data: AlarmResponse}) {
      const alarms = data?._embedded?.alarms || [];
      // abort next requests
      if (canceller?.cancelled) return;
      const snap = data?._meta?.snapshot?.snap || '';
      const allData = data?._links?.all_data || [];
      return Promise.all(allData.map((v, i) => {
        if (i===0) return undefined;
        return dispatch({
          ...dispatchOptions,
          params: {
            ...dispatchOptions.params,
            snap_page: i,
            snap,
          },
          requestAction: null,
        });
      })).then(responses => {
        const allAlarms = alarms.concat(responses.flatMap(({ data }={}) => {
          return data?._embedded?.alarms || [];
        }));
        // send all alarms to reducer as if it were one large API response
        dispatch({
          ...receiveAlarmsList(type, view),
          response: { _embedded: { alarms: allAlarms } },
        });
      });
    }

    return dispatch(dispatchOptions).then(successHandler).catch((e: Error) => {
      if(!isApiCancelled(e)) throw e;
    });
  };
}

export function requestAlarm(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM,
    alarm,
  };
}

export function receiveAlarm(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM,
    alarm,
  };
}

export function alarmFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmFailureAction {
  return {
    type: ACTION_TYPES.ALARM_FAILURE,
    alarm,
  };
}

export function fetchAlarm(alarm: Pick<Alarm, 'id'>): (dispatch: Dispatch<AlarmAction.FetchAlarmAction>) => AlarmAction.FetchAlarmAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms/${alarm.id}`,
      requestAction: requestAlarm(alarm),
      successAction: receiveAlarm(alarm),
      errorAction: alarmFailure(alarm),
      params: {
        view: 'metadata'
      },
    });
  };
}

export function requestAlarmEvents(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmEventsAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_EVENTS,
    alarm,
  };
}

export function receiveAlarmEvents(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmEventsAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_EVENTS,
    alarm,
  };
}

export function alarmEventsFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmEventsFailureAction {
  return {
    type: ACTION_TYPES.ALARM_EVENTS_FAILURE,
    alarm,
  };
}

export function fetchAlarmEvents(alarm: Pick<Alarm, 'id'>): (dispatch: Dispatch<AlarmAction.FetchAlarmEventsAction>) => AlarmAction.FetchAlarmEventsAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms/${alarm.id}/events`,
      requestAction: requestAlarmEvents(alarm),
      successAction: receiveAlarmEvents(alarm),
      errorAction: alarmEventsFailure(alarm),
    });
  };
}

export function requestAlarmEventCreate(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmEventCreateAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_EVENTS_CREATE,
    alarm,
  };
}

export function receiveAlarmEventCreate(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmEventCreateAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_EVENTS_CREATE,
    alarm,
  };
}

export function alarmEventCreateFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmEventCreateFailureAction {
  return {
    type: ACTION_TYPES.ALARM_EVENTS_CREATE_FAILURE,
    alarm,
  };
}

// alarm_status may be: 'acknowledged', 'invalid', 'valid', 'closed'
// reason is the "valid" or "invalid" reason
// comment is free text
export function createAlarmEvent(alarm: Pick<Alarm, 'id'>, { alarm_status, reason, comment, due_date, can_close_as, notify = false }: PostEvent = {}):
  (dispatch: Dispatch<AlarmAction.CreateAlarmEventAction>) => AlarmAction.CreateAlarmEventAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/alarms/${alarm.id}/events`,
      params: notify ? { notify: 'true' } : {},
      data: { alarm_status, reason, comment, due_date, can_close_as },
      requestAction: requestAlarmEventCreate(alarm),
      successAction: receiveAlarmEventCreate(alarm),
      errorAction: alarmEventCreateFailure(alarm),
      successToast: alarm_status ? 'Status updated' : 'Comment added',
    });
  };
}

export function requestAlarmEventUpdate(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmEventUpdateAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_EVENTS_UPDATE,
    alarm,
  };
}

export function receiveAlarmEventUpdate(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmEventUpdateAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_EVENTS_UPDATE,
    alarm,
  };
}

export function alarmEventUpdateFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmEventUpdateFailureAction {
  return {
    type: ACTION_TYPES.ALARM_EVENTS_UPDATE_FAILURE,
    alarm,
  };
}

export function updateAlarmEvent(alarm: Pick<Alarm, 'id'>, event: {id: number}, { comment }: PostEvent={}):
  (dispatch: Dispatch<AlarmAction.UpdateAlarmEventAction>) => AlarmAction.UpdateAlarmEventAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/alarms/${alarm.id}/events/${event.id}`,
      data: { comment },
      requestAction: requestAlarmEventUpdate(alarm),
      successAction: receiveAlarmEventUpdate(alarm),
      errorAction: alarmEventUpdateFailure(alarm),
      successToast: 'Comment updated',
    });
  };
}

export function requestAlarmEventDelete(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmEventDeleteAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_EVENTS_DELETE,
    alarm,
  };
}

export function receiveAlarmEventDelete(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmEventDeleteAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_EVENTS_DELETE,
    alarm,
  };
}

export function alarmEventDeleteFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmEventDeleteFailureAction {
  return {
    type: ACTION_TYPES.ALARM_EVENTS_DELETE_FAILURE,
    alarm,
  };
}

export function deleteAlarmEvent(alarm: Pick<Alarm, 'id'>, event: {id: number}): (dispatch: Dispatch<AlarmAction.DeleteAlarmEventAction>) => AlarmAction.DeleteAlarmEventAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/alarms/${alarm.id}/events/${event.id}`,
      requestAction: requestAlarmEventDelete(alarm),
      successAction: receiveAlarmEventDelete(alarm),
      errorAction: alarmEventDeleteFailure(alarm),
      successToast: 'Comment deleted',
    });
  };
}

export function requestAlarmImages(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmImagesAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_IMAGES,
    alarm,
  };
}

export function receiveAlarmImages(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmImagesAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_IMAGES,
    alarm,
  };
}

export function alarmImagesFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmImagesFailureAction {
  return {
    type: ACTION_TYPES.ALARM_IMAGES_FAILURE,
    alarm,
  };
}

export function fetchAlarmImages(alarm: Pick<Alarm, 'id'>): (dispatch: Dispatch<AlarmAction.FetchAlarmImagesAction>) => AlarmAction.FetchAlarmImagesAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms/${alarm.id}/images`,
      requestAction: requestAlarmImages(alarm),
      successAction: receiveAlarmImages(alarm),
      errorAction: alarmImagesFailure(alarm),
    });
  };
}

export function requestAlarmSounds(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmSoundsAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_SOUNDS,
    alarm,
  };
}

export function receiveAlarmSounds(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmSoundsAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_SOUNDS,
    alarm,
  };
}

export function alarmSoundsFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmSoundsFailureAction {
  return {
    type: ACTION_TYPES.ALARM_SOUNDS_FAILURE,
    alarm,
  };
}

export function fetchAlarmSounds(alarm: Pick<Alarm, 'id'>, soundType='alarm'): (dispatch: any) => AlarmAction.FetchAlarmSoundsAction {
  return (dispatch) => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      // alarm audio is in /images for now, but this might change in the future
      endpoint: `/alarms/${alarm.id}/images`,
      requestAction: requestAlarmSounds(alarm),
      successAction: receiveAlarmSounds(alarm),
      errorAction: alarmSoundsFailure(alarm),
    }).then((response: {data: ImageResponse}) => {

      // count all sounds in the images response
      const sound = response &&
        response.data &&
        response.data._embedded &&
        response.data._embedded.images &&
        response.data._embedded.images.length &&
        response.data._embedded.images.find(({ name }) => name === `${soundType}_audio`);

      // if no sound image is found, throw a toast
      if (!sound) {
        addToast({ header: `No ${soundType} audio found` });
      }

      return response;
    });
  };
}

// fetch alarm event types options

export function requestAlarmValidReasons(): AlarmAction.RequestAlarmValidReasonsAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_VALID_REASONS
  };
}

export function receiveAlarmValidReasons(): AlarmAction.ReceiveAlarmValidReasonsAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_VALID_REASONS
  };
}

export function alarmValidReasonsFailure(): AlarmAction.AlarmValidReasonsFailureAction {
  return {
    type: ACTION_TYPES.ALARM_VALID_REASONS_FAILURE
  };
}

export function fetchAlarmValidReasons(): (dispatch: Dispatch<AlarmAction.FetchAlarmValidReasonsAction>) => AlarmAction.FetchAlarmValidReasonsAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/domains/validalarms`,
      requestAction: requestAlarmValidReasons(),
      successAction: receiveAlarmValidReasons(),
      errorAction: alarmValidReasonsFailure()
    });
  };
}

export function requestAlarmInvalidReasons(): AlarmAction.RequestAlarmInvalidReasonsAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_INVALID_REASONS
  };
}

export function receiveAlarmInvalidReasons(): AlarmAction.ReceiveAlarmInvalidReasonsAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_INVALID_REASONS
  };
}

export function alarmInvalidReasonsFailure(): AlarmAction.AlarmInvalidReasonsFailureAction {
  return {
    type: ACTION_TYPES.ALARM_INVALID_REASONS_FAILURE
  };
}

export function fetchAlarmInvalidReasons(): (dispatch: Dispatch<AlarmAction.FetchAlarmInvalidReasonsAction>) => AlarmAction.FetchAlarmInvalidReasonsAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/domains/invalidalarms`,
      requestAction: requestAlarmInvalidReasons(),
      successAction: receiveAlarmInvalidReasons(),
      errorAction: alarmInvalidReasonsFailure()
    });
  };
}

function requestAlarmCreate(): AlarmAction.RequestAlarmCreateAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_CREATE,
  };
}

function receiveAlarmCreate(): AlarmAction.ReceiveAlarmCreateAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_CREATE,
  };
}

function alarmCreateFailure(): AlarmAction.AlarmCreateFailureAction {
  return {
    type: ACTION_TYPES.ALARM_CREATE_FAILURE,
  };
}

export function createAlarm(device: Pick<DeviceMappedFromAPI, 'id'>, { eventName, eventDescription }: PostUserAlarm):
  (dispatch: Dispatch<AlarmAction.CreateAlarmAction>) => AlarmAction.CreateAlarmAction
{
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/devices/${device.id}/alarms`,
      data: { alarm_description: eventName, trigger_description: eventDescription },
      requestAction: requestAlarmCreate(),
      successAction: receiveAlarmCreate(),
      errorAction: alarmCreateFailure(),
      successToast: "Event created",
    });
  };
}


export function requestAlarmDelete(): AlarmAction.RequestAlarmDeleteAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_DELETE,
  };
}

export function receiveAlarmDelete(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmDeleteAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_DELETE,
    alarm,
  };
}

export function alarmDeleteFailure(): AlarmAction.AlarmDeleteFailureAction {
  return {
    type: ACTION_TYPES.ALARM_DELETE_FAILURE,
  };
}

export function deleteAlarm(alarm: Pick<Alarm, 'id'>): (dispatch: Dispatch<AlarmAction.DeleteAlarmAction>) => AlarmAction.DeleteAlarmAction
{
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/alarms/${alarm.id}`,
      requestAction: requestAlarmDelete(),
      successAction: receiveAlarmDelete(alarm),
      errorAction: alarmDeleteFailure(),
      successToast: 'Event deleted',
    });
  };
}

export function requestAlarmImpact(alarm: Pick<Alarm, 'id'>): AlarmAction.RequestAlarmImpactAction {
  return {
    type: ACTION_TYPES.REQUEST_ALARM_IMPACT,
    alarm,
  };
}

export function receiveAlarmImpact(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmImpactAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_IMPACT,
    alarm,
  };
}

export function alarmImpactFailure(alarm: Pick<Alarm, 'id'>): AlarmAction.AlarmImpactFailureAction {
  return {
    type: ACTION_TYPES.ALARM_IMPACT_FAILURE,
    alarm,
  };
}

export function fetchAlarmImpact(alarm: Pick<Alarm, 'id'>): (dispatch: Dispatch<AlarmAction.FetchAlarmImpactAction>) => AlarmAction.FetchAlarmImpactAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms/${alarm.id}/impactitems`,
      requestAction: requestAlarmImpact(alarm),
      successAction: receiveAlarmImpact(alarm),
      errorAction: alarmImpactFailure(alarm),
    });
  };
}

export function receiveAddAlarmImpact(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAddAlarmImpactAction {
  return {
    type: ACTION_TYPES.RECEIVE_ADD_ALARM_IMPACT,
    alarm,
  };
}
export function addAlarmImpact(alarm: Pick<Alarm, 'id'>, impact: Impact): (dispatch: Dispatch<AlarmAction.AddAlarmImpactAction>) => AlarmAction.AddAlarmImpactAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/alarms/${alarm.id}/impactitems`,
      data: impact,
      requestAction: requestAlarmImpact(alarm),
      successAction: receiveAddAlarmImpact(alarm),
      errorAction: alarmImpactFailure(alarm),
      successToast: 'Impact created.',
    });
  };
}

export function receiveUpdateAlarmImpact(alarm: Pick<Alarm, 'id'>, impact: Pick<Impact, 'id'>): AlarmAction.ReceiveUpdateAlarmImpactAction {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_ALARM_IMPACT,
    alarm,
    impact,
  };
}
export function updateAlarmImpact(alarm: Pick<Alarm, 'id'>, impact: Pick<Impact, 'id'>, data: Partial<Impact>): (dispatch: Dispatch<AlarmAction.UpdateAlarmImpactAction>) => AlarmAction.UpdateAlarmImpactAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/alarms/${alarm.id}/impactitems/${impact.id}`,
      data,
      requestAction: requestAlarmImpact(alarm),
      successAction: receiveUpdateAlarmImpact(alarm, impact),
      errorAction: alarmImpactFailure(alarm),
      successToast: 'Impact updated.',
    });
  };
}

export function receiveDeleteAlarmImpact(alarm: Pick<Alarm, 'id'>, impact: Pick<Impact, 'id'>): AlarmAction.ReceiveDeleteAlarmImpactAction {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_ALARM_IMPACT,
    alarm,
    impact,
  };
}
export function deleteAlarmImpact(alarm: Pick<Alarm, 'id'>, impact: Pick<Impact, 'id'>): (dispatch: Dispatch<AlarmAction.DeleteAlarmImpactAction>) => AlarmAction.DeleteAlarmImpactAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/alarms/${alarm.id}/impactitems/${impact.id}`,
      requestAction: requestAlarmImpact(alarm),
      successAction: receiveDeleteAlarmImpact(alarm, impact),
      errorAction: alarmImpactFailure(alarm),
      successToast: 'Impact deleted.',
    });
  };
}

export function receiveAlarmImpactSummary(alarm: Pick<Alarm, 'id'>): AlarmAction.ReceiveAlarmImpactSummaryAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALARM_IMPACT_SUMMARY,
    alarm,
  };
}

export function fetchAlarmImpactSummary(alarm: Pick<Alarm, 'id'>): (dispatch: Dispatch<AlarmAction.FetchAlarmImpactSummaryAction>) => AlarmAction.FetchAlarmImpactSummaryAction  {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/alarms/${alarm.id}/impactsummary`,
      requestAction: requestAlarmImpact(alarm),
      successAction: receiveAlarmImpactSummary(alarm),
      errorAction: alarmImpactFailure(alarm),
    });
  };
}

function requestChildAlarms(alarmId: number): AlarmAction.RequestChildAlarmsAction {
  return {
    type: ACTION_TYPES.REQUEST_CHILD_ALARMS,
    alarmId,
  };
}

function receiveChildAlarms(alarmId: number): AlarmAction.ReceiveChildAlarmsAction {
  return {
    type: ACTION_TYPES.RECEIVE_CHILD_ALARMS,
    alarmId,
  };
}

function childAlarmsFailure(alarmId: number): AlarmAction.ChildAlarmsFailureAction {
  return {
    type: ACTION_TYPES.CHILD_ALARMS_FAILURE,
    alarmId,
  };
}
export function fetchChildAlarms(alarmId: number): (dispatch: Dispatch<AlarmAction.FetchChildAlarmsAction>) => AlarmAction.FetchChildAlarmsAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/alarms',
      params: {
        type: 'all',
        parent: alarmId,
      },
      requestAction: requestChildAlarms(alarmId),
      successAction: receiveChildAlarms(alarmId),
      errorAction: childAlarmsFailure(alarmId),
    });
  };
}