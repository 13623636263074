
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withNavigationOrganisationProps from './withNavigationOrganisationProps';

// Components
import IoIosFactory from '../../../components/IoIosFactory';
import NotFound from '../../../components/NotFound';
import ProfilePageLayout from '../../../components/ProfilePageLayout';
import ImageUpload from '../../../components/form/ImageUpload';
import OrganisationNavbar from './OrganisationNavbar';
import OrganisationSidebar from './OrganisationSidebar';

import { isAuthorised } from '../../user/selectors';
import { getOrganisation, getOrganisationLogo, getOrganisations, getOrganisationState, } from '../selectors';
import {
  fetchOrganisationWithId,
  fetchOrganisationLogo,
  getLogoS3FileUrl,
} from '../actions';
import LoadingSpinner from '../../../components/LoadingSpinner';

function OrganisationPageLayout({
  organisation = {},
  organisationState = {},
  organisations = [],
  organisationIsNew,
  organisationId,
  organisationLogoUrl, // can be null if org has no logo, todo: denote in typescript
  children,
  fetchOrganisationWithId,
  fetchOrganisationLogo,
  getLogoS3FileUrl,
  userCanEditLogo,
}) {

  const { t } = useTranslation();
  const { loading } = organisationState;

  const fetchOrganisation = async (organisationId) => {
    try {
      // when this resolves, organisationLogoUrl will have a defined answer in the store
      // if this request is rejected the loading state is reset by the catch statement
      await fetchOrganisationWithId({ id: organisationId });
      // Organisation logo url is available in organisation entity. There's no need to fetch it again unless logo status has value other than 'available'.
      // await fetchOrganisationLogo({ id: organisationId });
    }
    catch(e) {}
  };

  useEffect(() => {
    // if we are attempting to load a organisation page, but the organisation is not yet in redux state
    // then attempt to fetch this organisation
    organisationId && fetchOrganisation(organisationId);
  }, [organisationId]);

  if (!organisationIsNew) {
    if (!organisationId) {
      // Unfetchable, URL path is probably something like /organisations/hi-there
      return <NotFound />;
    }
    else if (!organisation.id || !organisation.name) {  // Organisation always has id, need to look at name field to know if the org is available or not.
      // Also can't look at error. If you trigger an error on other operations, like updating an org, you will also be redirected to not found page, which is confusing.
      // organisationId makes sense, but user cannot see this organisation
      return <NotFound />;
    }
    else if (!organisations.find(({ id }) => id === organisationId)) {
      // organisationId makes sense, but user cannot see this organisation is this group
      return (
        <Redirect to="/organisations/admin" />
      );
    }
  }

  return (
    <ProfilePageLayout
      // show loading or logo if the org exists (not new)
      // and the user can either see an existing logo, or add in a new one
      // otherwise, default to an icon
      image={loading ? (
        <div className="img-thumbnail d-flex align-items-center justify-content-center">
          <LoadingSpinner />
        </div>
      ) : organisation.id && (organisationLogoUrl || userCanEditLogo) ? (
        <div className="img-thumbnail object-fit-contain">
          <ImageUpload
            image={organisationLogoUrl && ({
              src: organisationLogoUrl,
              alt: organisationLogoUrl.split('/').pop()
            })}
            getUploadS3FileUrlFromFile={async file => (
              getLogoS3FileUrl(organisation, {
                file_name: file.name,
              })
            )}
            buttonText={organisationLogoUrl ?
              t('components.organisations.organisation-page-layout.replace-logo') :
              t('components.organisations.organisation-page-layout.add-logo')}
            canEdit={!!userCanEditLogo}
            canAdd={organisation && !organisationLogoUrl}
            onSuccess={() => fetchOrganisationLogo(organisation)}
          />
        </div>
      ) : (
        <IoIosFactory className="img-thumbnail p-3" />
      )}
      navbar={<OrganisationNavbar />}
      sidebar={<OrganisationSidebar />}
    >
      <div className="my-4">
        {children}
      </div>
    </ProfilePageLayout>
  );
}

const mapStateToProps = (state, { organisationId }) => {
  const organisationLogo = organisationId && getOrganisationLogo(state, organisationId);
  return {
    organisations: getOrganisations(state),
    organisation: organisationId && getOrganisation(state, organisationId),
    organisationState: organisationId && getOrganisationState(state, organisationId),
    userCanEditLogo: isAuthorised(state, { minUserType: 'Admin' }),
    organisationLogoUrl: organisationLogo && organisationLogo.url,
    organisationLogoStatus: organisationLogo && organisationLogo.status,
  };
};
const mapDispatchToProps = {
  fetchOrganisationWithId,
  fetchOrganisationLogo,
  getLogoS3FileUrl,
};

export default withNavigationOrganisationProps(
  connect(mapStateToProps, mapDispatchToProps)(OrganisationPageLayout)
);
