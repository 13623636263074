import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { Form, Button, ButtonGroup } from 'react-bootstrap';
import { IoIosCheckbox } from 'react-icons/io';
import { t } from 'i18next';

import useUniqueId from '../hooks/useUniqueId';

import Table from './Table';
import {
  headerFormatter,
  nullFormatter,
} from './table/formatters';
import ConfirmModal from './ConfirmModal';
import Toolbar from './TableToolbar';
import { addToast } from './Toaster';

// todo: move this to helper as it is repeated multiple times in codebase
function SelectAllButton(props) {
  return (
    <Button size="sm" variant="outline-primary" {...props} />
  );
}

// todo: move this to helper as it is repeated multiple times in codebase
function showUnsavedChangesToast() {
  addToast({
    variant: 'warning',
    header: 'Remember to save your changes',
  });
}

function SelectAllButtons({
  userCanEdit,
  isItemDisabled,
  setAnnotatedList,
  metaKey,
  alsoSet: alsoSetMetaKeys = [],
  alsoUnset: alsoUnsetMetaKeys = alsoSetMetaKeys,
  // passed directly
  isDisabled,
}) {
  if (!userCanEdit) {
    return null;
  }
  else {
    return (
      <ButtonGroup>
        <SelectAllButton disabled={isDisabled} onClick={() => {
          setAnnotatedList(annotatedList => {
            return annotatedList.map(item => {
              const isDisabled = isItemDisabled && isItemDisabled(item);
              return {
                // copy item
                ...item,
                // override value if editable
                ...item._meta_.active && !isDisabled && {
                  _meta_: {
                    ...item._meta_,
                    [metaKey]: true,
                    ...alsoSetMetaKeys.reduce((acc, metaKey) => {
                      acc[metaKey] = true;
                      return acc;
                    }, {}),
                  },
                },
              };
            });
          });
          showUnsavedChangesToast();
        }}>
          {t('All')}
        </SelectAllButton>
        <SelectAllButton disabled={isDisabled} onClick={() => {
          setAnnotatedList(annotatedList => {
            return annotatedList.map(item => {
              const isDisabled = isItemDisabled && isItemDisabled(item);
              return {
                // copy item
                ...item,
                // override value if editable
                ...item._meta_.active && !isDisabled && {
                  _meta_: {
                    ...item._meta_,
                    [metaKey]: false,
                    ...alsoUnsetMetaKeys.reduce((acc, metaKey) => {
                      acc[metaKey] = false;
                      return acc;
                    }, {}),
                  },
                },
              };
            });
          });
          showUnsavedChangesToast();
        }}>
          {t('None')}
        </SelectAllButton>
      </ButtonGroup>
    );
  }
}

export const createSelectAllHeaderFormatter = (metaKey, {
  alsoSet: alsoSetMetaKeys = [],
  alsoUnset: alsoUnsetMetaKeys = alsoSetMetaKeys,
}={}) => ({ formatExtraData, ...props }, ...rest) => {
  return (
    <Fragment>
      {headerFormatter(props, ...rest)}
      <SelectAllButtons
        {...formatExtraData}
        metaKey={metaKey}
        alsoSet={alsoSetMetaKeys}
        alsoUnset={alsoUnsetMetaKeys}
      />
    </Fragment>
  );
};

export const createSelectAllFormatter = (metaKey, {
  alsoSet: alsoSetMetaKeys = [],
  alsoUnset: alsoUnsetMetaKeys = alsoSetMetaKeys,
}={}) => (value, item, ignore, formatExtraData={}) => {
  const { listIdKey, isItemDisabled } = formatExtraData;
  return (
    <SelectAllButtons
      {...formatExtraData}
      metaKey={metaKey}
      alsoSet={alsoSetMetaKeys}
      alsoUnset={alsoUnsetMetaKeys}
      // fake that every row except the select row is disabled
      isItemDisabled={({ [listIdKey]: id }) => id !== item[listIdKey]}
      // make the buttons themselves disabled
      isDisabled={!item._meta_.active || (
        // disable item via callback if specified
        isItemDisabled && isItemDisabled(item)
      )}
    />
  );
};

export const createCheckboxFormatter = (metaKey, {
  alsoSet: alsoSetMetaKeys = [],
  alsoUnset: alsoUnsetMetaKeys = alsoSetMetaKeys,
}={}) => (value, item, ignore, {
  userCanEdit,
  listIdKey,
  setAnnotatedList,
  isItemDisabled,
}={}) => {
  const id = item[listIdKey];
  return (userCanEdit || !item._meta_.active) ? (
    <Form.Check
      key={`${metaKey}-${id}`}
      type="checkbox"
      name={`${metaKey}[${id}]`}
      defaultChecked={!!value}
      onChange={e => {
        const value = !!(e.target && e.target.checked);
        setAnnotatedList(annotatedList => {
          return annotatedList.map(item => {
            return {
              // copy item
              ...item,
              // override value
              ...item[listIdKey] === id && {
                _meta_: {
                  ...item._meta_,
                  [metaKey]: value,
                  // set or unset other keys
                  ...(value ? alsoSetMetaKeys : alsoUnsetMetaKeys)
                    .reduce((acc, metaKey) => {
                      acc[metaKey] = value;
                      return acc;
                    }, {}),
                },
              },
            };
          });
        });
        showUnsavedChangesToast();
      }}
      disabled={!item._meta_.active || (
        // disable item via callback if specified
        isItemDisabled && isItemDisabled(item)
      )}
    />
  ) : value ? (
    // show the truthy value without making it editable
    <IoIosCheckbox size="1.1em"/>
  ) : (
    // show the falsy value without making it editable
    <Form.Check disabled style={{ marginLeft: 2 }} />
  );
};

// export sort functions to help deal with sorting with an 'active' item state
export function sortByActiveThenString(a, b, order, dataField, rowA, rowB) {
  const negative = order === 'asc' ? 1 : -1;
  return rowA._meta_.active !== rowB._meta_.active
    // prioritise sorting by activeness
    ? rowB._meta_.active - rowA._meta_.active
    // then sort by value
    : negative * a.localeCompare(b);
};
export function sortByActiveThenNumber(a=0, b=0, order, dataField, rowA, rowB) {
  const negative = order === 'asc' ? 1 : -1;
  return rowA._meta_.active !== rowB._meta_.active
    // prioritise sorting by activeness
    ? rowB._meta_.active - rowA._meta_.active
    // then sort by value
    : negative * (a - b);
};

function defaultSearchFilterFactory(searchText, searchKeys) {
  // create search text filter over all values in item object
  const lowercaseSearchText = `${searchText}`.toLowerCase();
  return (item={}) => {
    return (searchKeys || Object.keys(item)).find(key => {
      const value = item[key];
      return typeof value === 'string'
        ? `${value}`.toLowerCase().includes(lowercaseSearchText)
        : false;
    });
  };
}

function defaultEncode(item) {
  return item;
}

// by default return only 'included' items
function defaultParse(item) {
  return item && item._meta_ && item._meta_.included === true
    ? item
    : undefined;
}

export const included = {
  dataField: '_meta_.included',
  text: 'Show',
  headerFormatter: createSelectAllHeaderFormatter('included'),
  formatter: createCheckboxFormatter('included'),
  filterValue: nullFormatter,
  sort: false,
};

export default function TableListEdit({
  // table props
  title,
  defaultSorted,
  columns,
  noDataIndication,
  // list props
  list,
  listIdKey = 'id',
  isItemIncluded,
  encodeMeta,
  encode = defaultEncode,
  parse = defaultParse,
  // search props
  searchKeys,
  searchFilterFactory = defaultSearchFilterFactory,
  isItemDisabled,
  // formatting
  before,
  after,
  userCanEdit,
  loadingState = {},
  // actions
  onChange,
  onSubmit,
  requireConfirm,
  confirmButtonProps,
}) {

  const formId = useUniqueId('form');
  const searchInputId = useUniqueId('search');

  const [filter, setFilter] = useState('');

  // get users as annotated by current backend state
  const defaultList = useMemo(() => {
    return (list || []).map(item => {
      return encode({
        ...item,
        _meta_: {
          // set item to highlighted by default
          active: true,
          // set default checkboxes according to current backend state
          ...encodeMeta
            ? encodeMeta(item)
            // use default is item included test
            : { included: isItemIncluded && isItemIncluded(item) },
        },
      });
    });
  }, [list, encode, encodeMeta, isItemIncluded]);

  // get annotated users: the users with front-end changes applied by this component
  const [annotatedList, setAnnotatedList] = useState(defaultList);

  // apply filter to annotated users
  const applyFilter = useCallback(annotatedListOverride => {
    // filter current list state
    setAnnotatedList(annotatedList => {
      const isActive = searchFilterFactory(filter, searchKeys);
      return (annotatedListOverride || annotatedList).map(item => {
        return {
          ...item,
          _meta_: {
            ...item._meta_,
            active: !!isActive(item),
          },
        };
      });
    });
  }, [filter, searchFilterFactory, searchKeys]);

  // apply filter whenever the filter changes
  useEffect(() => applyFilter(), [applyFilter]);

  // reset these whenever the group changes
  useEffect(() => applyFilter(defaultList), [defaultList]);

  useEffect(() => {
    if(typeof onChange === 'function') {
      const selectedItems = annotatedList.map(parse).filter(Boolean);
      onChange(selectedItems);
    }
  }, [annotatedList]);

  const [submissionState, setSubmissionState] = useState({});
  const handleSubmit = useCallback(async e => {
    e.preventDefault();

    const selectedItems = annotatedList.map(parse).filter(Boolean);

    const submittedAt = Date.now();
    setSubmissionState({ submittedAt });
    try {
      await onSubmit(selectedItems);
      setSubmissionState(state => {
        return state.submittedAt === submittedAt
          ? { succeededAt: new Date() }
          : state;
      });
    }
    catch (error) {
      setSubmissionState(state => {
        return state.submittedAt === submittedAt
          ? { error: error.message || 'Error' }
          : state;
      });
    }
  }, [annotatedList, parse]);

  const renderItemCount = useCallback(() => {
    return filter
      ? t('components.common.filtered-count', {
        count: annotatedList.length,
        filteredCount: annotatedList.filter(({ _meta_ }) => !!_meta_.active).length,
      })
      : t('components.common.row-with-count', {
        count: annotatedList.length,
      });
  }, [filter, annotatedList]);

  const renderSearchBar = useCallback(() => {
    return (
      // copy style from output of default rendered search bar
      <label htmlFor={searchInputId} className="search-label">
        <input
          id={searchInputId}
          type="text"
          aria-label="enter text you want to search"
          className="form-control align-middle d-inline-block react-bootstrap-table2-search-header"
          placeholder={t('common.search')}
          // add custom onChange handler
          onChange={e => {
            // set text filter
            const filter = e.target.value;
            setFilter(filter);
          }}
        />
      </label>
    );
  }, [searchInputId, annotatedList]);

  const renderHeader = useCallback(props => {
    return (
      <Toolbar
        searchable
        renderItemCount={renderItemCount}
        renderSearchBar={renderSearchBar}
        title={title}
        loading={loadingState.submittedAt || submissionState.submittedAt}
        lastFetch={loadingState.succeededAt}
        error={loadingState.error || submissionState.error}
        tableProps={props}
      />
    );
  }, [loadingState, submissionState, renderItemCount, renderSearchBar]);

  // add extra data for columns, also apply hidden status to hide organisation field for standard orgs
  const columnsWithExtraData = useMemo(() => {
    return columns.map(column => ({
      ...column,
      // add state into functions
      // for the formatters:
      // - selectAllHeaderFormatter
      // - includeFormatter
      formatExtraData: {
        userCanEdit,
        setAnnotatedList,
        listIdKey,
        isItemDisabled,
      },
    }));
  }, [columns, listIdKey, userCanEdit, isItemDisabled]);

  return (
    <Form
      id={formId}
      className="form-container"
      onSubmit={handleSubmit}
    >
      {before}
      <Table
        rowClasses="text-muted"
        rowStyle={filter ? styles.filteredRows : styles.unfilteredRows}
        keyField={listIdKey}
        submit={submissionState}
        renderHeader={renderHeader}
        data={annotatedList}
        defaultSorted={defaultSorted}
        columns={columnsWithExtraData}
        noDataIndication={noDataIndication}
        loading={loadingState.submittedAt}
        striped={!filter}
        selectRow={useMemo(() => ({
          mode: 'checkbox',
          hideSelectColumn: true,
          selected: filter ? annotatedList.reduce((acc, item) => {
            if (item._meta_.active) {
              acc.push(item[listIdKey]);
            }
            return acc;
          }, []) : annotatedList.map(item => item[listIdKey]),
          classes: "text-reset",
          bgColor: filter ? 'white' : undefined,
        }), [listIdKey, annotatedList, filter])}
      />
      {after}
      <Form.Group className="text-right mt-3 mb-chat-widget">
        {requireConfirm ?
          <ConfirmModal
            confirmText={requireConfirm.confirmText}
            body={requireConfirm.body}
            header={requireConfirm.header}
            confirmButtonProps={confirmButtonProps}
          >
            <Button variant="success" type="button" onClick={handleSubmit} size="lg" disabled={!userCanEdit}>
              {t('Update')}
            </Button>
          </ConfirmModal> :
          <Button variant="success" type="submit" size="lg" disabled={!userCanEdit}>
            {t('Update')}
          </Button>}
      </Form.Group>
    </Form>
  );
}

const styles = {
  filteredRows: { backgroundColor: 'rgba(0,0,0,.05)' },
  unfilteredRows: {},
};
