import React from 'react';
import { Container } from 'react-bootstrap';

import withNavigationGroupProps from '../components/withNavigationGroupProps';
import EditGroupAccessGroups from '../components/EditGroupAccessGroups';
import EditGroupSummary from '../components/EditGroupSummary';

function EditGroupAccessGroupsPage(props) {
  return (
    <Container fluid>
      <EditGroupSummary {...props} />
      <EditGroupAccessGroups {...props} />
    </Container>
  );
}

export default withNavigationGroupProps(EditGroupAccessGroupsPage);
