
import React, { useMemo } from 'react';
import './aspectRatio.scss';

// expects ratio to be given as a fraction of width/height. eg. 4/3, 16/9, 1
export function AspectRatioImage({ children, ratio, ...props }) {
  // cache the computed padding ratio
  const containerStyle = useMemo(() => ({ paddingTop: `${100/ratio}%` }), [ratio]);
  return (
    // allow pass props for easy styling
    <div {...props}>
      <div className="img-aspect-ratio" style={containerStyle}>
        {children}
      </div>
    </div>
  );
}
