
import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, useMemo } from 'react';
import './map.scss';

import { isEqual } from 'lodash';
import ReactMapboxGl, {
  ZoomControl,
  Marker,
  Popup,
  Cluster,
  Source,
  Layer,
} from 'react-mapbox-gl';
import { defaultMarkerSrc } from './Markers';
import ErrorBoundary from './ErrorBoundary';
import log from '../lib/log';
import useResizeObservedRef from '../hooks/useResizeObservedRef';

const markerIconHeight = 40;
// set maxHeight and maxWidth instead of height because of IE11
// link: https://stackoverflow.com/questions/20637771/#34817775
const markerIconStyle = {
  maxWidth: markerIconHeight,
  maxHeight: markerIconHeight,
};

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

// see reference: https://github.com/alex3165/react-mapbox-gl/blob/master/docs/API.md
const Mapbox = ReactMapboxGl({
  accessToken,
  // turn off default zoom, we can't prevent the map receiving a double-click event
  // from inside the child components, so we handle the onDblClick event ourselves
  doubleClickZoom: false,
});

const defaultMapboxOptions = {
  // see reference: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#fitbounds
  fitBoundsOptions: { padding: 50 },
  containerStyle: { height: '100%' },
};
// cache the received Mapbox style spec object
let defaultMapboxStyle;
// use CameraOptions and AnimationOptions available to map.easeTo
// link: https://docs.mapbox.com/mapbox-gl-js/api/map/#map#easeto
const defaultEaseToOptions = {};

const zoomTheWorld = [0];
const zoomSinglePoint = [16];

function getValidPoints(points) {
  return points && points
    // filter to valid coordinates
    .filter(v => !isNaN(v.longitude) && !isNaN(v.latitude));
};

function getBounds(points) {
  // find bounds if points are valid
  const validPoints = getValidPoints(points);
  if (validPoints && validPoints.length > 1) {
    const longitudes = validPoints.map(({ longitude }) => parseFloat(longitude));
    const latitudes = validPoints.map(({ latitude }) => parseFloat(latitude));
    return [
      // set bottom left corner
      [Math.min(...longitudes), Math.min(...latitudes)],
      // set top right corner
      [Math.max(...longitudes), Math.max(...latitudes)],
    ];
  }
  if (validPoints && validPoints.length === 1) {
    const [validPoint] = validPoints;
    // set single point
    return [[validPoint.longitude, validPoint.latitude]];
  }
  // return empty bounds if they don't exist
  if (validPoints && validPoints.length === 0) {
    return [];
  }
}

// cluster library may fail to find a recently known cluster
function getLeavesSafely(getLeaves) {
  try {
    // this may fail
    return typeof getLeaves === 'function' ? getLeaves() : undefined;
  }
  catch(error) {
    // don't report unfound clusters (most likely stale data)
    if (error && error.message !== 'No cluster with the specified id.') {
      log.warn(error);
    }
  }
}

// get the bounds ([[y.min, x.min], [y.max, y.min]]) of a cluster
function getClusterBounds(getLeaves) {
  const leaves = getLeavesSafely(getLeaves);
  if (leaves) {
    const points = leaves.map(({ props }) => ({
      longitude: props.coordinates[0],
      latitude: props.coordinates[1],
    }));
    return getBounds(points);
  }
}

// get the "size" (diagonal length in degrees)
// be aware that degrees converts to metres differently at different latitudes:
// but this is appropriate as more extreme latitudes are more "zoomed in" when
// viewing a map with a Mercator projection, as this map is.
// this "size" is proportional to pixel distance on the Map.
function getClusterSize(getLeaves) {
  const bounds = getClusterBounds(getLeaves);
  if (bounds) {
    // return diagonal distance across the cluster bounds
    return Math.sqrt(
      Math.pow(bounds[0][0] - bounds[1][0], 2) +
      Math.pow(bounds[0][1] - bounds[1][1], 2)
    );
  }
}

// store a point matching function (as the point objects may change)
// eg. when images are attached to a device, the devicePoint object changes
// default match points by id
function defaultSelectedPointMatches(source) {
  return object => source && object && source.id === object.id;
}

/*
 * props:
 * points - Array of Objects containing 'longitude' and 'latitude' attributes
 */
function Map({
  // required
  points,
  // optional data
  boundingPoints = points,
  totalPointCount = points ? points.length : 0,
  lines = [],
  selectedPoint: givenSelectedPoint,
  // optional lifecycles
  onPointSelected,
  onPointUnselected,
  // optional behaviour
  updateBoundsKey,
  selectedPointMatches = defaultSelectedPointMatches,
  mapboxOptions = defaultMapboxOptions,
  easeToOptions = defaultEaseToOptions,
  // optional rendering
  NoDataIndication,
  renderPopup: CustomPopup,
  renderMarkerClusterBorder: MarkerClusterBorder,
  getMarkerProps,
  linesProps,
  ...givenProps
}) {

  // save initial options given to map, for reuse later
  const initialOptions = useRef({
    mapboxOptions,
    easeToOptions,
  });

  // get valid points first so that the map will start at the right zoom-level
  // instead of starting at a default and then zooming to the final calculated bounds.
  const validPoints = useMemo(() => getValidPoints(points), [points]);
  const validBoundingPoints = useMemo(() => getValidPoints(boundingPoints), [boundingPoints]);
  const [initialBounds] = useState(() => getBounds(validBoundingPoints));
  const [maximumBounds, setMaximumBounds] = useState(initialBounds);
  const [currentBounds, setCurrentBounds] = useState(initialBounds);

  // get map things
  const [map, setMap] = useState(null);
  const [mapboxStyle, setMapboxStyle] = useState(defaultMapboxStyle);

  // get interactive things
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState({});

  // update selected point if passed prop has changed
  useEffect(() => {
    if (givenSelectedPoint) {
      setSelectedPoint(givenSelectedPoint);
    }
  }, [givenSelectedPoint]);

  // on point selected handler
  const pointSelected = useCallback((point, cluster={}) => e => {

    // set the current selection
    setSelectedPoint(point);
    setSelectedCluster(cluster);

    // add custom hook functionality
    if (typeof onPointSelected === 'function') {
      onPointSelected(point, e);
    }
  }, [onPointSelected]);

  // on point unselected handler
  const pointUnselected = useCallback(e => {
    // unset the current selection
    setSelectedPoint(null);
    setSelectedCluster({});

    // add custom hook functionality
    if (typeof onPointUnselected === 'function') {
      onPointUnselected(e);
    }
  }, []);

  // fetch mapboxStyle if not yet cached in defaultMapboxStyle
  useEffect(() => {
    // fetch styles for satellite-streets-v9
    // but without the black background
    if (!mapboxStyle) {
      fetch(`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9?access_token=${
        accessToken
      }`)
        .then(response => response.json())
        .then(style => {
          // remove the black background layer
          style.layers = style.layers.filter(({ id }) => id !== 'background');
          // set style into the top-level let for future component instances to use
          defaultMapboxStyle = style;
          // set style into the current instance mapbox options
          setMapboxStyle(style);
        });
    }
  }, [mapboxStyle]);

  // update maximum allowable bounds if they have changed
  useEffect(() => {
    setMaximumBounds(currentBounds => {
      const newBounds = getBounds(validBoundingPoints);
      // check with deep equality
      return !isEqual(currentBounds, newBounds)
        ? newBounds
        // skip update
        : currentBounds;
    });
  }, [validBoundingPoints]);

  // recenter map on maximum bounds change
  // or if a new updateBoundsKey is given
  useEffect(() => {
    setCurrentBounds(currentBounds => {
      // if new bounds aren't equal, then change them
      return maximumBounds || currentBounds;
    });
  }, [maximumBounds, updateBoundsKey]);

  // recenter map on changed selected point
  useEffect(() => {
    if (selectedPoint) {
      const newBounds = getBounds([selectedPoint]);
      if (newBounds) {
        setCurrentBounds(newBounds);
      }
    }
  }, [selectedPoint]);

  // recenter map on changed selected cluster
  useEffect(() => {
    if (selectedCluster) {
      const newBounds = getClusterBounds(selectedCluster.getLeaves);
      if (newBounds) {
        setCurrentBounds(newBounds);
      }
    }
  }, [selectedCluster]);

  // when desired bounds change (or updateBoundsKey is given) then move the map
  // using layout effect allows the map to move before rendering the popup
  useLayoutEffect(() => {
    // if map is ready, move the current focus
    if (map && currentBounds) {
      // move to bounding area
      if (currentBounds.length > 1) {
        map.fitBounds(currentBounds, {
          ...initialOptions.current.mapboxOptions.fitBoundsOptions,
          linear: false, // false is like 'flyTo'
        });
      }
      // move to singular point
      else if (currentBounds.length === 1) {
        // fly to the point smoothly
        map.easeTo({
          center: currentBounds[0],
          ...initialOptions.current.easeToOptions,
        });
      }
      // or go to the default
      else if (currentBounds.length === 0) {
        map.easeTo({ zoom: 0 });
      }
    }
  }, [map, currentBounds, updateBoundsKey]);

  // maybe update or deselect cluster when selected point changes
  useEffect(() => {
    setSelectedCluster(selectedCluster => {
      if (selectedCluster && selectedCluster.getLeaves) {
        const leaves = getLeavesSafely(selectedCluster.getLeaves) || [];
        const points = leaves.map(({ props }) => props.pointData);
        // find if selected point is inside selected cluster
        const selectedIndex = points.findIndex(selectedPointMatches(selectedPoint));
        // update the selectedPointIndex if needed
        if (selectedIndex >= 0 && selectedCluster.selectedPointIndex !== selectedIndex) {
          return { ...selectedCluster, selectedPointIndex: selectedIndex };
        }
        // is selected point isn't here then unselect the cluster
        else if (selectedIndex === -1) {
          return {};
        }
      }
      // else ignore update
      return selectedCluster;
    });
  }, [selectedPoint, selectedPointMatches]);

  // Point rendering
  const renderPoint = useCallback((point, index) => {
    const { clusterData, ...markerProps }  = getMarkerProps ? getMarkerProps(point) : {};
    return (
      <Marker
        key={point.id || index}
        coordinates={[
          parseFloat(point.longitude),
          parseFloat(point.latitude),
        ]}
        onClick={pointSelected(point)}
        // add marker prop to be read by the cluster renderer
        clusterData={clusterData}
        pointData={point}
      >
        <img
          alt="device location"
          src={defaultMarkerSrc}
          // add more context if available
          {...markerProps}
          style={{
            ...markerIconStyle,
            ...markerProps.style,
          }}
        />
      </Marker>
    );
  }, [getMarkerProps, pointSelected]);

  // on cluster click handler
  // maybe cycle through points in the cluster
  const handleMarkerClusterClick = useCallback((getLeaves, key) => e => {
    const leaves = getLeavesSafely(getLeaves);
    // skip unfound cluster leaves, cluster is probably gone
    if (!leaves) {
      return;
    }
    // select points if they are very close to each other, and might not de-cluster
    // note: empirical, be very cautious about changing this value
    //       you might not be able to select some points if handled wrong
    if (getClusterSize(getLeaves) < 0.0001) {
      // increment the current cluster index (identified by key)
      // or created a new index
      const selectedPointIndex = selectedCluster.key === key
        ? (selectedCluster.selectedPointIndex + 1) % leaves.length
        : 0;
      // set new selected point
      const point = leaves[selectedPointIndex].props.pointData;
      const cluster = {
        getLeaves,
        key,
        selectedPointIndex,
        size: leaves.length,
      };
      setSelectedPoint(point);
      setSelectedCluster(cluster);

      // add custom hook functionality
      if (typeof onPointSelected === 'function') {
        onPointSelected(point, e);
      }
    }
    // if a cluster is clicked but not close enough to be 'active'
    // then allow its selection to trigger a resizing effect
    // (without a selected point)
    else {
      setSelectedPoint(null);
      setSelectedCluster({ getLeaves });
    }
  }, [selectedCluster, onPointSelected]);

  // MarkerCluster rendering
  const renderMarkerCluster = useCallback((coordinates=[], pointCount, getLeaves) => {

    const key = coordinates.join(',');
    const leaves = getLeavesSafely(getLeaves);
    // skip unfound cluster leaves, cluster is probably gone
    if (!leaves) {
      return null;
    }
    const points = leaves.map(({ props }) => props.pointData);
    const devicePoints = points.filter(({ type }) => type === 'device');

    // find correct offset on the world map
    // (when zoomed so far out that there are multiple renderings of the world)
    let offset;
    if (map) {

      const [lng] = coordinates;
      const mapBounds = map.getBounds();
      const mapLeft = mapBounds._ne.lng;
      const mapRight = mapBounds._sw.lng;
      const mapCenter = (mapLeft + mapRight) / 2;
      const mapWidth = map.transform.width;
      const worldWidthPx = mapWidth * 360 / Math.abs(mapRight - mapLeft);

      // if the map marker is too far on the left, move it
      if (lng < mapCenter - 180) {
        offset = [worldWidthPx, 0];
      }
      // if the map marker is too far on the right, move it
      else if (lng > mapCenter + 180) {
        offset = [-worldWidthPx, 0];
      }
    }

    return [
      devicePoints.length !== 1 ? (
        // show cluster if more than one device is present
        <Marker
          key={key}
          anchor="center"
          offset={offset}
          className="mapboxgl__cluster"
          coordinates={coordinates}
          onClick={handleMarkerClusterClick(getLeaves, key)}
        >
          {MarkerClusterBorder && (
            <MarkerClusterBorder
              className="mapboxgl__cluster__border"
              width="2rem"
              height="2rem"
              getLeaves={getLeaves}
              // total points on Map
              totalPoints={totalPointCount}
            />
          )}
          <span className="mapboxgl__cluster__count">
            {/* ignore special points in cluster text */}
            {leaves.filter(({ props }) => !props.pointData.ignoreInPointCount).length}
          </span>
        </Marker>
      ) : (
        // show 'regular' markers if only one device is in the cluster
        // but show gateway markers on top as they are smaller
        [...points].sort(a => a.type === 'gateway' ? 0 : -1).map(renderPoint)
      ),
      // show ghost points
      ...points
        .filter(point => {
          if (selectedPoint) {
            // show selected point
            return selectedPointMatches(selectedPoint)(point) ||
              // or points that are included in shown lines from the selection
              lines.find(([pointA, pointB]) => {
                return selectedPointMatches(point)(pointA) ||
                  selectedPointMatches(point)(pointB);
              });
          }
          // don't show ghost points when there is no selection
          else {
            return false;
          }
        })
        .map((point, index) => {
          const { clusterData, ...markerProps } = getMarkerProps(point);
          return (
            <Marker
              key={index}
              className="mapboxgl__cluster__selected-point"
              coordinates={[
                parseFloat(point.longitude),
                parseFloat(point.latitude),
              ]}
              onClick={handleMarkerClusterClick(getLeaves, key)}
            >
              <img
                alt="device location"
                src={defaultMarkerSrc}
                // add more context if available
                {...markerProps}
                style={{
                  ...markerIconStyle,
                  ...markerProps.style,
                }}
              />
            </Marker>
          );
        }),
    ];
  }, [
    map,
    lines,
    selectedCluster,
    selectedPoint,
    selectedPointMatches,
    totalPointCount,
    MarkerClusterBorder,
    getMarkerProps,
    handleMarkerClusterClick,
    renderPoint,
  ]);

  // find a matching selected point from possibly changed array
  const selectedPointMatch = selectedPoint && validPoints && validPoints.find(
    selectedPointMatches(selectedPoint)
  );

  return (
    <>
      {mapboxStyle && (
        <Mapbox
          // add default options
          {...mapboxOptions}
          // add computed style
          style={mapboxStyle}
          // fit the map to the initial points
          // subsequent moving around the map will be performed by a useEffect
          fitBounds={initialBounds && initialBounds.length > 1 ? initialBounds : undefined}
          center={initialBounds && initialBounds.length === 1 ? initialBounds[0] : undefined}
          zoom={initialBounds && initialBounds.length > 0 ? zoomSinglePoint : zoomTheWorld}
          renderChildrenInPortal={true}
          {...givenProps}
          // override any given props with required callbacks
          onStyleLoad={map => setMap(map)}
          onDblClick={(map, e) => {
            // only zoom in if the user did not originally click the zoom buttons
            const originalTarget = (e.originalEvent && e.originalEvent.target) || {};
            if (`${originalTarget.id || originalTarget.class}`.includes('zoom') === false) {
              map && map.flyTo({
                center: e.lngLat,
                zoom: map.style.z + 1,
              });
            }
          }}
        >
          <div
            // use this "click-background" to handle unselecting of points
            // by representing the background area you can click around the active elements
            className="map-click-background position-absolute w-100 h-100"
            onClick={pointUnselected}
            // add accessibility props
            onKeyPress={pointUnselected}
            role="none"
          />
          <ZoomControl
            onControlClick={(map, diff) => {
              // zoom out faster than zooming in
              map.zoomTo(map.style.z + (diff > 0 ? 1 : -2));
            }}
          />
          <Source id="lines" geoJsonSource={{
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: lines.map(([pointA, pointB, properties={}]) => ({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [
                    [pointA.longitude, pointA.latitude],
                    [pointB.longitude, pointB.latitude],
                  ],
                },
                properties,
              })),
            },
          }}>
          </Source>
          <Layer
            type="line"
            sourceId="lines"
            {...linesProps}
          />
          <Cluster
            // todo: try to ensure that 1 device + 1 gateway
            // does not equal a cluster, even when they are directly on each other
            // using clusterProperties
            // https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson-clusterProperties
            ClusterMarkerFactory={renderMarkerCluster}
            maxZoom={20}
          >
            {validPoints ? validPoints.map(renderPoint) : []}
          </Cluster>
          {CustomPopup && selectedPointMatch && (
            <Popup
              anchor="bottom"
              coordinates={[
                parseFloat(selectedPointMatch.longitude),
                parseFloat(selectedPointMatch.latitude),
              ]}
              offset={{
                bottom: [0, -markerIconHeight],
              }}
            >
              <CustomPopup
                point={selectedPointMatch}
                cluster={selectedCluster}
              />
            </Popup>
          )}
        </Mapbox>
      )}
      {NoDataIndication && validBoundingPoints && validBoundingPoints.length === 0 && (
        // if devices list is found and it is of zero length
        // then sit this message over the hidden map
        <div className="d-flex align-items-center justify-content-center mapboxgl-no-data-indication">
          <div className="position-relative m-4">
            <NoDataIndication />
          </div>
        </div>
      )}
    </>
  );
}

function FallbackComponent({ error }) {
  return (
    // if the Map component has an error then show this message
    <div className="d-flex align-items-center justify-content-center mapboxgl-no-data-indication">
      <div className="position-relative title">
        <h2>Could not load map</h2>
        <p>Your browser may be outdated. Please ensure you're using an updated browser.</p>
        {error && error.id && (
          <p>Error reference: <code>{error.id}</code></p>
        )}
      </div>
    </div>
  );
}

export default function WrappedMap({ containerClassName, ...props }) {
  const resizeObservedRef = useResizeObservedRef(null);
  // use window resize to trigger internal MapBox component logic
  // to ensure that the map area redraws when the container resizes

  // add error boundary
  return (
    <div
      ref={resizeObservedRef}
      className={`mapboxgl-map-container ${containerClassName}`.trim()}
    >
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Map {...props} />
      </ErrorBoundary>
    </div>
  );
}
