
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUserId, getUserPathString } from '../selectors';

const mapStateToProps = (state, { match }) => {

  // get vars from path location
  const userPathIdType = match && match.params && match.params['userEditType'];
  const userPathIdString = match && match.params && match.params['id'];
  const userPathId = (userPathIdString && parseInt(userPathIdString)) || undefined; // never NaN

  // define special user path cases
  // self has an alias, so you can reach yourself at /users/me or /users/[your_id]
  // we use getUserPathString to determine if user_id resolves to 'me'
  const userIsSelf = (userPathIdType || getUserPathString(state, userPathId)) === 'me';
  const userIsNew = userPathIdType === 'new';

  return {
    userIsSelf,
    userIsNew,
    userId: !userIsNew
      // existing user
      ? userIsSelf
        // current user's id as an integer
        ? getUserId(state)
        // other user as an integer if found
        : userPathId || undefined
      // new user
      : undefined,
    // pass the URL path identifier that components should reference
    userPathString: !userIsNew
      // existing user
      ? userIsSelf
        // current user (even if the user's numeric id was passed inside the URL)
        ? 'me'
        // other user as a string if found
        : userPathIdString || undefined
      // new user
      : 'new',
  };
};

export default compose(
  // pass router props to redux to make "match" available
  withRouter,
  connect(mapStateToProps),
);
