import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceFitpowerSamples } from '../../modules/equipment/actions';
import { getFirstSampleDateFromDevice, getDevice } from '../../modules/equipment/selectors';

function FitpowerSamplesContext({ deviceId, children: contextFunction }) {
  const now = Date.now();
  const dispatch = useDispatch();

  const device = useSelector(state => getDevice(state, deviceId));
  const deviceFirstSampleDate = useSelector(state => getFirstSampleDateFromDevice(state, device));
  const [allSamples, setAllSamples] = useState({});
  const allSamplesRef = useRef({});
  const cancellerRef = useRef({ cancelled: false });
  const [hasMore, setHasMore] = useState(false);

  const [allOverview, setAllOverview] = useState({});
  const allOverviewRef = useRef({});
  const [fetchingOverview, setFetchingOverview] = useState(false);
  const energyOverviewSamples = useMemo(() => {
    return (allOverview?.energy_overview || []).sort((a, b) => {
      return Date.parse(a.date) - Date.parse(b.date);
    });
  }, [allOverview]);

  const energyUseSamples = useMemo(() => {
    if(!allOverview?.energy_use) return null;
    const dailyEnergyUse = allOverview?.energy_use?.daily || [];
    const hourlyEnergyUse = allOverview?.energy_use?.hourly || [];
    const groupedHourlyEnergyUse = {};
    hourlyEnergyUse.forEach(data => {
      // const timestamp = new Date(data.date);
      const dateKey = data.date.split('T')[0];  // Just get the date part.
      const time = data.date.split('+')[0]; // Omit the timzone part.
      const hour = (new Date(time)).getHours();
      if(!groupedHourlyEnergyUse[dateKey]) {
        const initialEnergyUse = new Array(24); // Represent 24 hours a day.
        groupedHourlyEnergyUse[dateKey] = {date: dateKey, energy_use: initialEnergyUse.fill({kWh: 0, cost: 0})};
      }
      groupedHourlyEnergyUse[dateKey].energy_use[hour] = data.energy_use;
    });
    return {
      daily: dailyEnergyUse.map(data => ({
        date: moment(data.date).format('YYYY-MM-DD'),
        kWh: data?.energy_use?.kWh || 0,
        cost: data?.energy_use?.cost || 0,
      })).sort((a, b) => {
        return Date.parse(a.date) - Date.parse(b.date);
      }),
      hourly: Object.values(groupedHourlyEnergyUse).sort((a, b) => {
        return Date.parse(a.date) - Date.parse(b.date);
      }),
    };
  }, [allOverview]);

  // Handle measured data samples.
  const measuredDataSamples = useMemo(() => {
    return (allSamples?.measured_data || []).sort((a, b) => {
      return Date.parse(a.timestamp) - Date.parse(b.timestamp);
    });
  }, [allSamples]);
  const prevMeasuredDataSamples = useRef();
  const [measuredDataDateRange, setMeasuredDataDateRange] = useState({
    startTime: Date.now(),
    endTime: Date.now(),
  });
  useEffect(() => {
    if(!prevMeasuredDataSamples.current && measuredDataSamples.length > 0) {
      const firstSampleTimestamp = Date.parse(measuredDataSamples[0].timestamp);
      const lastSampleTimestamp = Date.parse(measuredDataSamples[measuredDataSamples.length - 1].timestamp);
      setMeasuredDataDateRange({
        startTime: firstSampleTimestamp,
        endTime: lastSampleTimestamp,
      });
      prevMeasuredDataSamples.current = measuredDataSamples;
    }
  }, [measuredDataSamples.length]);
  const firstMeasuredDataSampleTimestamp = useMemo(() => {
    return measuredDataSamples[0] && Date.parse(measuredDataSamples[0].timestamp);
  }, [measuredDataSamples.length]);
  const lastMeasuredDataSampleTimestamp = useMemo(() => {
    return measuredDataSamples[measuredDataSamples.length - 1] && Date.parse(measuredDataSamples[measuredDataSamples.length - 1].timestamp);
  }, [measuredDataSamples.length]);

  // Handle equipment state samples.
  const equipmentStateSamples = useMemo(() => {
    return (allSamples.equipment_states || []).sort((a, b) => Date.parse(a.timestamp) - Date.parse(b.timestamp));
  }, [allSamples]);
  const prevEquipmentStateSamples = useRef();
  const [equipmentStateDateRange, setEquipmentStateDateRange] = useState({
    startTime: Date.now(),
    endTime: Date.now(),
  });

  useEffect(() => {
    if(!prevEquipmentStateSamples.current && equipmentStateSamples.length > 0) {
      const firstSampleTimestamp = Date.parse(equipmentStateSamples[0].timestamp);
      const lastSampleTimestamp = Date.parse(equipmentStateSamples[equipmentStateSamples.length - 1].timestamp);
      setEquipmentStateDateRange({
        endTime: lastSampleTimestamp,
        startTime: firstSampleTimestamp,
      });
      prevEquipmentStateSamples.current = equipmentStateSamples;
    }
  }, [equipmentStateSamples.length]);
  const firstEquipmentStateSampleTimestamp = useMemo(() => {
    return equipmentStateSamples[0] && Date.parse(equipmentStateSamples[0].timestamp);
  }, [equipmentStateSamples.length]);
  const lastEquipmentStateSampleTimestamp = useMemo(() => {
    return equipmentStateSamples[equipmentStateSamples.length - 1] && Date.parse(equipmentStateSamples[equipmentStateSamples.length - 1].timestamp);
  }, [equipmentStateSamples.length]);

  // The timestamp of the first sample, which is the minimum timestamp for energy overview, equipment state and measured data.
  const firstSampleTimestamp = useMemo(() => {
    const firstEnergyOverviewTimestamp = Date.parse(energyOverviewSamples[0]?.date || deviceFirstSampleDate);
    const firstEquipmentStateTimestamp = Date.parse(equipmentStateSamples[0]?.timestamp || deviceFirstSampleDate);
    const firstMeausredDataTimestamp = Date.parse(measuredDataSamples[0]?.timestamp || deviceFirstSampleDate);
    return Math.min(firstEnergyOverviewTimestamp, firstEquipmentStateTimestamp, firstMeausredDataTimestamp);
  }, [allSamples, allOverview]);

  const selectableDateRange = useMemo(() => {
    // This is the maximum selectable date range for calendar's component.
    return {
      startTime: firstSampleTimestamp,
      endTime: now,
    };
  }, [firstSampleTimestamp]);

  const [stillFetching, setStillFetching] = useState(false);
  const [timezone, setTimezone] = useState(null);

  const onData = useCallback(({ id: deviceId }, data = {}, { stillFetching, hasMore }) => {
    const currentMeausredData = allSamplesRef?.current?.measured_data || [];
    const incomingMeasuredData = data.measured_data || [];

    const currentEquipmentStates = allSamplesRef?.current?.equipment_states || [];
    const incomingEquipmentStates = {};
    const incomingEquipmentStatesRaw = data.equipment_states;
    if(incomingEquipmentStatesRaw) {
      for(const [frequency, samples] of Object.entries(incomingEquipmentStatesRaw)) {
        for(const sample of samples) {
          const epoch = Math.floor(new Date(sample.timestamp)/1000);
          if(incomingEquipmentStates[epoch]) {
            incomingEquipmentStates[epoch] = {...incomingEquipmentStates[epoch], [frequency]: {state: sample.state}};
          } else {
            incomingEquipmentStates[epoch] = {id: sample.id, timestamp: sample.timestamp, [frequency]: { state: sample.state }};
          }
        }
      }
    }
    // Get samples data from API, manipulate data and concatenate with the existing data.
    const newAllSamples = {
      measured_data: [
        ...currentMeausredData,
        ...incomingMeasuredData.filter(newData => !currentMeausredData.some(currentData => currentData.timestamp === newData.timestamp))
      ],
      equipment_states: [
        ...currentEquipmentStates,
        ...Object.values(incomingEquipmentStates).filter(newData => !currentEquipmentStates.some(currentData => currentData.timestamp === newData.timestamp))
      ],
    };
    allSamplesRef.current = newAllSamples;
    setAllSamples(allSamplesRef.current);
    setStillFetching(stillFetching);
    setHasMore(hasMore);
  }, []);

  // Handle energy overview and usage data.
  const onOverviewData = useCallback(({id: deviceId}, data = {}, {timezone, stillFetching}) => {
    if(timezone) setTimezone(timezone);
    const newOverview = {
      energy_overview: [
        ...(allOverviewRef.current.energy_overview || []),
        ...(data?.energy_overview?.energy_overview || [])
      ],
      energy_use: {
        daily: [...(allOverviewRef.current?.energy_use?.daily || []), ...(data?.energy_overview?.energy_use?.daily || [])],
        hourly: [...(allOverviewRef.current?.energy_use?.hourly || []), ...(data?.energy_overview?.energy_use?.hourly || [])],
      }
    };
    allOverviewRef.current = newOverview;
    setAllOverview(newOverview);
    setFetchingOverview(stillFetching);
  }, []);

  const fetchNewData = useCallback((dateRange = {}) => {
    // dateRange should specify startTime, endTime and minimumTimePeriod.
    // minimumTimePeriod specifies how many days of data should be fetched initially.
    setStillFetching(true);
    cancellerRef.current.currentDeviceId = deviceId;
    dispatch(fetchDeviceFitpowerSamples(
      { id: deviceId },
      {
        canceller: cancellerRef.current,
        onData,
        dateRange,
      }
    ));
    if(cancellerRef.current.cancelled) setStillFetching(false);
  }, [deviceId, onData, allSamples]);

  const fetchOverviewData = useCallback(() => {
    setFetchingOverview(true);
    dispatch(fetchDeviceFitpowerSamples(
      { id: deviceId },
      {
        canceller: cancellerRef.current,
        onData: onOverviewData,
        sampleType: 'overview2'
      }
    ));
    if(cancellerRef.current.cancelled) setFetchingOverview(false);
  }, [deviceId]);

  useEffect(() => {
    cancellerRef.current.cancelled = false;
    allSamplesRef.current = {};
    setAllSamples({});
    prevMeasuredDataSamples.current = undefined;
    prevEquipmentStateSamples.current = undefined;
    fetchNewData({
      minimumTimePeriod: 1, // Set minimumTimePeriod as a minimum number so that it fetches the first page of samples only
    });
    return () => {
      cancellerRef.current.cancelled = true;
      setStillFetching(false);
    };
  }, [deviceId]);

  useEffect(() => {
    allOverviewRef.current = {};
    setAllOverview({});
    fetchOverviewData();
  }, [deviceId]);

  const handleSetMeasuredDataDateRange = useCallback((dateRange) => {
    setMeasuredDataDateRange(dateRange);
    if(dateRange.fetchData && (dateRange.startTime < firstMeasuredDataSampleTimestamp || dateRange.endTime > lastMeasuredDataSampleTimestamp)) {
      fetchNewData({...dateRange, minimumTimePeriod: 1});
    }
  }, [setMeasuredDataDateRange, firstMeasuredDataSampleTimestamp, lastMeasuredDataSampleTimestamp]);

  const handleSetEquipmentStateDateRange = useCallback((dateRange) => {
    setEquipmentStateDateRange(dateRange);
    if(dateRange.fetchData && (dateRange.startTime < firstEquipmentStateSampleTimestamp || dateRange.endTime > lastEquipmentStateSampleTimestamp)) {
      fetchNewData({...dateRange, minimumTimePeriod: 1});
    }
  }, [setEquipmentStateDateRange, firstEquipmentStateSampleTimestamp, lastEquipmentStateSampleTimestamp]);

  return contextFunction({
    fetchingOverview,
    stillFetching,
    hasMore,
    // dateRange,
    selectableDateRange,
    // maximumViewedDateRange,
    timezone,
    measuredDataSamples,
    measuredDataDateRange,
    setMeasuredDataDateRange: handleSetMeasuredDataDateRange,
    equipmentStateSamples,
    equipmentStateDateRange,
    setEquipmentStateDateRange: handleSetEquipmentStateDateRange,
    energyOverviewSamples,
    energyUseSamples,
    // setDateRange: handleSetDateRange,
    // fetchNewData: this.fetchNewData,
    // setMaximumViewedDateRange,
    // isOnDeviceId: this.isOnDeviceId,
  });
}

export default FitpowerSamplesContext;