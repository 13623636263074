import { Badge } from 'react-bootstrap';
import { MOVUS_BADGE_COLOURS } from '../lib/utils';

function ColourBadge({children, colour, ...props}) {
  const colours = MOVUS_BADGE_COLOURS[colour];
  return (
    <Badge style={{background: colours?.background, color: colours?.color}} {...props}>{children}</Badge>
  );
}

export default ColourBadge;