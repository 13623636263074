
import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';

import { getFormValues } from '../../../lib/utils';

import FormModal from '../../../components/FormModal';
import { useFocusableRef } from '../../../components/form/useFocusableRef';

import {
  createStreamingConfiguration,
  fetchStreamingConfiguration,
} from '../actions';

import { StreamingConfigurationFormGroups } from './form/StreamingConfigurationFormGroups';

const confirmButtonProps = {
  variant: 'success',
};

const addText = "Add Streaming Configuration";

function AddStreamingConfigurationFormModal({
  organisationId,
  createStreamingConfiguration,
  fetchStreamingConfiguration,
  children,
  ...props
}) {

  const [updatedDescription, setUpdatedDescription] = useState('');
  const [updatedEnabled, setUpdatedEnabled] = useState(false);
  const [updatedStreamType, setUpdatedStreamType] = useState('');
  const [updatedResourceType, setUpdatedResourceType] = useState('');
  const [updatedResourceLocation, setUpdatedResourceLocation] = useState('');
  const [updatedResourceKey, setUpdatedResourceKey] = useState('');

  const formRef = useRef(null);

  const [descriptionRef, setDescriptionShown] = useFocusableRef(null);
  const enabledRef = useRef(null);
  const streamTypeRef = useRef(null);
  const resourceTypeRef = useRef(null);
  const resourceLocationRef = useRef(null);
  const resourceKeyRef = useRef(null);

  // instead of having multiple handlers which do the same thing, look up functions based on element
  const mapElementToName = {
    description: setUpdatedDescription,
    stream_type: setUpdatedStreamType,
    resource_type: setUpdatedResourceType,
    resource_location: setUpdatedResourceLocation,
    resource_key: setUpdatedResourceKey,
  };

  const handleSubmit = useCallback(async e => {
    const data = getFormValues(e, formRef.current) || {};
    try {
      await createStreamingConfiguration({ id: organisationId }, data);
      await fetchStreamingConfiguration({ id: organisationId });
    }
    catch(err) {
      // allow error to prevent closing of the modal
      throw new Error(err);
    }
  }, [formRef.current, organisationId]);

  const handleEnabledChange = useCallback(e => {
    setUpdatedEnabled(e.target.checked);
  }, []);

  const handleTextChange = useCallback(e => {
    const name = mapElementToName[e.target.id];
    name(e.target.value);
  }, []);

  const show = () => {
    setDescriptionShown(true);
  };

  const close = () => {
    setDescriptionShown(false);

    setUpdatedDescription('');
    setUpdatedEnabled(false);
    setUpdatedStreamType('');
    setUpdatedResourceType('');
    setUpdatedResourceLocation('');
    setUpdatedResourceKey('');
  };

  return (
    <FormModal
      // set defaults
      header={addText}
      confirmText={addText}
      confirmButtonProps={confirmButtonProps}
      // add given props
      {...props}
      // override given props
      size="md"
      // stream/resource type and enabled are only required fields and these are forced to be set by
      // the form: therefore form is always valid
      valid={true}
      onShow={useCallback(() => {
        show();
      }, [])}
      onClose={useCallback(() => {
        close();
      }, [])}
      form={(
        <Form ref={formRef} onSubmit={handleSubmit}>
          <StreamingConfigurationFormGroups
            valid={true}
            handlers={{ handleTextChange, handleEnabledChange }}
            description={{ descriptionRef, updatedDescription, pristineDescription: true }}
            enabled={{ enabledRef, updatedEnabled, pristineEnabled: true }}
            streamType={{ streamTypeRef, updatedStreamType, pristineStreamType: true }}
            resourceType={{ resourceTypeRef, updatedResourceType, pristineResourceType: true }}
            resourceLocation={{ resourceLocationRef, updatedResourceLocation, pristineResourceLocation: true }}
            resourceKey={{ resourceKeyRef, updatedResourceKey, pristineResourceKey: true }}
          />
        </Form>
      )}
    >
      {children}
    </FormModal>
  );
}

const mapDispatchToProps = {
  createStreamingConfiguration,
  fetchStreamingConfiguration,
};

export default connect(null, mapDispatchToProps)(AddStreamingConfigurationFormModal);
