import { useTranslation } from 'react-i18next';

import { useGroupPendingEventsReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import HomeCard from './HomeCard';
import Table from '../Table';
import { equipmentNameLink, pendingEventStatusBadge, scheduledDate, latestComments } from './columns';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';

const columns = [
  equipmentNameLink,
  pendingEventStatusBadge,
  scheduledDate,
  latestComments,
];

function PendingEvents() {
  const { t } = useTranslation();
  const groupPendingEventsReport = useGroupPendingEventsReport();
  const localisedColumns = useLocalisedColumns(columns);
  if(!groupPendingEventsReport) return null;

  const {_state_: { loading, error } = {}, items = []} = groupPendingEventsReport || {};
  return (
    <HomeCard
      header={t('home.common.pending-action-events')}
      headerLink={{
        to: '/events?status=scheduled,in_progress',
        text: t('home.common.all-pending-action-events')
      }}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="alarm_id"
        loading={loading}
        columns={localisedColumns}
        condensed
      />
    </HomeCard>
  );
}

export default PendingEvents;
