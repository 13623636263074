import { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import { Button } from 'react-bootstrap';
import { IoIosCopy } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import { addToast } from '../../../components/Toaster';

function CopyDeviceSerialButton({ device }) {

  const { t}  = useTranslation();
  const copySerial = useCallback(() => {
    const result = copy(
      device.serial, { message: t('components.common.copy-button.message') }
    );
    if (result) {
      addToast(
        {
          variant: 'success',
          header: t('components.common.copy-button.header')
        });
    }
  }, [device.serial]);

  if(!device?.serial) return null;

  return (
    <Button
      size="sm"
      variant="outline-secondary"
      className="btn-xs btn-muted"
      onClick={copySerial}
    >
      <IoIosCopy /> {t('components.common.copy-button.text')}
    </Button>
  );
}

export default CopyDeviceSerialButton;
