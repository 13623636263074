
import React, { Fragment, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import {
  IoIosTrash,
} from "react-icons/io";

import { archiveUser } from '../actions';

import ConfirmModal from '../../../components/ConfirmModal';
import Private from '../../../components/Private';

import { getUser } from '../selectors';

function ArchiveUserModal({
  user,
  archiveUser,
  onSuccess,
  onError,
  ...props
}) {
  const { t } = useTranslation();
  const onClick = useCallback(async () => {
    try {
      await archiveUser(user);
      if (onSuccess) {
        onSuccess(user);
      }
    }
    catch(e) {
      if (onError) {
        onError(user);
      }
    }
  }, [archiveUser, user]);

  return (
    <Private minUserType="Admin">
      <ConfirmModal
        confirmText="Archive"
        body={(
          <Fragment>
            <p>{t('user.Archive_user_confirmation_message_line_one')}</p>
            <p>{t('user.Archive_user_confirmation_message_line_two')}</p>
            <p>{t('user.Archive_user_confirmation_message_line_three')}</p>
          </Fragment>
        )}
        {...props}
      >
        <Dropdown.Item onClick={onClick}>
          <IoIosTrash size="1.5em" /> {t('Archive')}
        </Dropdown.Item>
      </ConfirmModal>
    </Private>
  );
}

const mapStateToProps = (state, { userId }) => {
  return {
    user: getUser(state, userId),
  };
};
const mapDispatchToProps = { archiveUser };

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveUserModal);
