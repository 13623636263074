import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HomeCard from './HomeCard';
import { useGroupVibrationOrder } from '../../modules/organisation/hooks/useGroupStandardData';
import Table from '../Table';
import { equipmentNameLink, lastDataProcessed, utilisation, vibrationRms, } from './columns';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';
import { getDevices } from '../../modules/equipment/selectors';
import withNavigationDeviceProps from '../../modules/equipment/components/withNavigationDeviceProps';
import { addSensorTypeToSample, addVibrationRms } from '../../modules/equipment/utils';

const columns = [
  lastDataProcessed,
  equipmentNameLink,
  // todo: remove rms + mm2 -> DASH-1018
  vibrationRms,
  // vibration,
  utilisation,
];

function HighestVibratingEquipment({ devices }) {
  const { t } = useTranslation();
  const groupVibrationOrder = useGroupVibrationOrder();
  const { filterAll } = useDeviceStatusFilter();
  const localisedColumns = useLocalisedColumns(columns);

  if(!groupVibrationOrder) return null;

  const {_state_: {loading, error} = {}, items = []} = groupVibrationOrder || {};

  const rmsData = {};
  for (const x in devices) {
    const device = devices[x];
    rmsData[device.id] = {
      fitmachine_type: device.fitmachine_type,
      rms: device.rms,
      rms2: device.rms2,
    };
  }
  let updatedHighestDevices = [];
  for (const x in items) {
    const deviceId = items[x].device_id;
    const newItem = {...items[x], ...rmsData[deviceId]};
    const result = addSensorTypeToSample(rmsData[deviceId], newItem);
    updatedHighestDevices.push(result);
  }
  updatedHighestDevices = addVibrationRms(updatedHighestDevices);

  return (
    <HomeCard
      header={t("user.Highest_Vibrating_Equipment")}
      headerLink={{to: '/', text: t('home.all-equipment'), onClick: filterAll }}
      loading={loading}
      error={error}
    >
      <Table
        data={updatedHighestDevices}
        keyField="device_id"
        loading={loading}
        columns={localisedColumns}
        condensed
      />
    </HomeCard>
  );
}

const mapStateToProps = (state) => {
  return {
    devices: getDevices(state),
  };
};

export default withNavigationDeviceProps(
  connect(mapStateToProps)(HighestVibratingEquipment)
);
