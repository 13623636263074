
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import Title from '../../../components/Title';
import BasicModal from '../../../components/BasicModal';
import AlarmFFTImages from '../../alarm/components/AlarmFFTImages';

import { fetchDeviceInfo } from '../../equipment/actions';

import { getAlarm, getAlarmDevice } from '../selectors';
import { relativeDateFormatter } from '../../../components/table/formatters';
import { getDeviceHasProductCode } from '../../equipment/selectors';

function AlarmFFTModal({
  alarmId,
  device = {},
  alarm = {},
  hasFFT = false,
  fetchDeviceInfo,
  children,
  onShow,
}) {

  // todo: if requests are not deduplicated,
  // this should be fetched at the device page or alarm card level
  // to reduce the number of requests
  // fetch device level product_codes from device if not yet known
  useEffect(() => {
    if (device && device.id && !device.serial) {
      fetchDeviceInfo({ id: device.id });
    }
  }, [device.id]);

  // show nothing if the organisation has no access to FFT Charts
  if (!hasFFT) {
    return null;
  }

  return (
    <BasicModal
      size="lg"
      header={(
        <Title title="Alarm FFT" />
      )}
      subHeader={(
        <div>
          <p>
            {[
              alarm.site_name,
              alarm.site_subarea_name,
              alarm.equipment_name
            ].filter(Boolean).join(' - ')}
          </p>
          <p>
            <Link to={`/equipment/${alarm.device_id}`}>
              {device.serial}
            </Link>
            {' - '}
            {relativeDateFormatter(alarm.alarm_timestamp)}
          </p>
          <Alert variant="info">
            The red or yellow coloured line is the FFT of acceleration
            at the time of the alarm. The black shaded area represents
            the FitMachine learning data.
          </Alert>
        </div>
      )}
      body={(
        <AlarmFFTImages alarmId={alarmId} />
      )}
      onShow={onShow}
    >
      {children}
    </BasicModal>
  );
}

const mapStateToProps = (state, { alarmId }) => {
  const alarm = getAlarm(state, alarmId);
  return {
    alarm,
    hasFFT: getDeviceHasProductCode(state, alarm?.device_id, 'fftchart'),
    device: getAlarmDevice(state, alarmId),
  };
};

const mapDispatchToProps = { fetchDeviceInfo };

export default connect(mapStateToProps, mapDispatchToProps)(AlarmFFTModal);