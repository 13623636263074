import { CALL_API, isApiCancelled } from '../../lib/apiMiddleware';
import { getActiveGroupId, isTopLevelGroupActive } from '../organisation/selectors';
import { fetchGroupMembers } from '../organisation/actions';
import * as ACTION_TYPES from './types/ActionTypes';
import { SENSOR_TYPE } from './constants';

export function requestBegin(device) {
  return {
    type: ACTION_TYPES.REQUEST_BEGIN,
    device
  };
}

export function requestFail(device) {
  return {
    type: ACTION_TYPES.REQUEST_FAIL,
    device
  };
}

export function receiveCloudDeviceList(options) {
  return {
    type: ACTION_TYPES.RECEIVE_CLOUD_DEVICE_LIST,
    options,
  };
}

export function fetchDevices(options, canceller) {
  const { filter, search, forOrg=false, batch=true, sensorType = SENSOR_TYPE.FITMACHINE, includeChildren = false } = options || {};
  if(batch) return fetchDevicesInBatch(options, canceller);
  return (dispatch, getState) => {
    const state = getState();
    if (!forOrg && !isTopLevelGroupActive(state)) {
      return fetchGroupMembers({ id: getActiveGroupId(state) }, { sensorType, includeChildren })(dispatch, getState);
    }
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/devices',
      params: {
        'filter': filter || undefined,
        'search': search || undefined,
        'sensor_type': sensorType,
      },
      requestAction: requestBegin(),
      successAction: receiveCloudDeviceList(options),
      errorAction: requestFail()
    });
  };
}

export function fetchDevicesInBatch(options, canceller) {
  const { filter, search, forOrg = false, sensorType, includeChildren } = options || {};
  return (dispatch, getState) => {

    const state = getState();
    if (!forOrg && !isTopLevelGroupActive(state)) {
      return fetchGroupMembers({ id: getActiveGroupId(state) }, { sensorType, includeChildren })(dispatch, getState);
    }

    const dispatchOptions = {
      type: CALL_API,
      method: 'get',
      endpoint: '/devices',
      params: {
        'filter': filter || undefined,
        'search': search || undefined,
        'sensor_type':  sensorType,
        'snap_page': 0,
      },
      requestAction: requestBegin(),
      successAction: { type: 'none' },
      errorAction: requestFail(),
      abortControllerId: canceller?.id,
    };

    async function successHandler({data}) {
      const devices = data?._embedded?.devices || [];
      if(canceller?.cancelled) return;
      const snap = data?._meta?.snapshot?.snap;
      const allData = data?._links?.all_data || [];
      return Promise.all(allData.map((v, i) => {
        if(i === 0) return undefined; // Skip the first page as it's already fetched.
        return dispatch({
          ...dispatchOptions,
          params: {
            ...dispatchOptions.params,
            snap_page: i,
            snap,
          },
          requestAction: null,
        });
      })).then(responses => {
        const allDevices = devices.concat((responses || []).flatMap(({ data } = {}) => {
          return data?._embedded?.devices || [];
        }));
        dispatch({
          ...receiveCloudDeviceList(options),
          response: { _embedded: { devices: allDevices }},
        });
      });
    }

    return dispatch(dispatchOptions).then(successHandler).catch(e => {
      if(!isApiCancelled(e)) throw e;
    });
  };
}

export function receiveDeviceInfo(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_INFO,
    device
  };
}

export function fetchDeviceInfo(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}`,
      requestAction: requestBegin(device),
      successAction: receiveDeviceInfo(device),
      errorAction: requestFail(device)
    });
  };
}

export function requestDeviceTags(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_TAGS,
    device
  };
}

export function receiveDeviceTags(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_TAGS,
    device
  };
}

export function deviceTagsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_TAGS_FAILURE,
    device
  };
}

export function fetchDeviceTags(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/tags`,
      requestAction: requestDeviceTags(device),
      successAction: receiveDeviceTags(device),
      errorAction: deviceTagsFailure(device),
    });
  };
}

export function requestDeviceEvents(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_EVENTS,
    device
  };
}

export function receiveDeviceEvents(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_EVENTS,
    device
  };
}

export function deviceEventsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_EVENTS_FAILURE,
    device
  };
}

export function fetchDeviceEvents(device, { source='system', event_type }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/logs`,
      params: {
        'source': source || undefined,
        'event_type': event_type || undefined,
      },
      requestAction: requestDeviceEvents(device),
      successAction: receiveDeviceEvents(device),
      errorAction: deviceEventsFailure(device),
    });
  };
}

export function requestDeviceInteractiveFFT(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_INTERACTIVE_FFT,
    device
  };
}

export function receiveDeviceInteractiveFFT(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_INTERACTIVE_FFT,
    device
  };
}

export function deviceInteractiveFFTFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_INTERACTIVE_FFT_FAILURE,
    device
  };
}

export function fetchDeviceInteractiveFFT(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/quickref`,
      params: {
        'type': 'fft',
      },
      requestAction: requestDeviceInteractiveFFT(device),
      successAction: receiveDeviceInteractiveFFT(device),
      errorAction: deviceInteractiveFFTFailure(device),
    });
  };
}

export function requestDeviceWaterfallPlot(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_WATERFALL_PLOT,
    device
  };
}

export function receiveDeviceWaterfallPlot(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_WATERFALL_PLOT,
    device
  };
}

export function deviceWaterfallPlotFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_WATERFALL_PLOT_FAILURE,
    device
  };
}

export function fetchDeviceWaterfallPlot(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/quickref?type=waterfall`,
      requestAction: requestDeviceWaterfallPlot(device),
      successAction: receiveDeviceWaterfallPlot(device),
      errorAction: deviceWaterfallPlotFailure(device),
    });
  };
}

export function requestDeviceTimeSeriesChart(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_TS_CHART,
    device
  };
}

export function receiveDeviceTimeSeriesChart(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_TS_CHART,
    device
  };
}

export function deviceTimeSeriesChartFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_TS_CHART_FAILURE,
    device
  };
}

export function fetchDeviceTimeSeriesChart(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/quickref?type=tschart`,
      requestAction: requestDeviceTimeSeriesChart(device),
      successAction: receiveDeviceTimeSeriesChart(device),
      errorAction: deviceTimeSeriesChartFailure(device),
    });
  };
}

export function requestDeviceAlarms(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_ALARMS,
    device
  };
}

export function receiveDeviceAlarms(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_ALARMS,
    device
  };
}

export function deviceAlarmsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_ALARMS_FAILURE,
    device
  };
}

export function fetchDeviceAlarms(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      // return all types of alarms for alarm cards
      endpoint: `/devices/${device.id}/alarms?type=all`,
      requestAction: requestDeviceAlarms(device),
      successAction: receiveDeviceAlarms(device),
      errorAction: deviceAlarmsFailure(device),
    });
  };
}


export function requestDeviceAlarmEvents(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_ALARM_EVENTS,
    device
  };
}

export function receiveDeviceAlarmEvents(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_ALARM_EVENTS,
    device
  };
}

export function deviceAlarmEventsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_ALARM_EVENTS_FAILURE,
    device
  };
}

export function fetchDeviceAlarmEvents(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/alarms?view=event`,
      requestAction: requestDeviceAlarmEvents(device),
      successAction: receiveDeviceAlarmEvents(device),
      errorAction: deviceAlarmEventsFailure(device),
    });
  };
}

export function requestDeviceImages(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_IMAGES,
    device
  };
}

export function receiveDeviceImages(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_IMAGES,
    device
  };
}

export function deviceImagesFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_IMAGES_FAILURE,
    device
  };
}

export function fetchDeviceImages(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/images`,
      requestAction: requestDeviceImages(device),
      successAction: receiveDeviceImages(device),
      errorAction: deviceImagesFailure(device),
    });
  };
}

export function fetchDeviceInfoThenImages(device) {
  return dispatch => {
    return fetchDeviceInfo(device)(dispatch)
      .then(() => fetchDeviceImages(device)(dispatch))
      .catch(() => {}); // device probably doesn't exist
  };
}

export function getImageUploadS3FileUrl(device, data={}) {
  const {
    name, // type of image
    file_name = new Date().toISOString(), // default filename to current time
  } = data;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/devices/${device.id}/images`,
      data: { name, file_name },
      requestAction: { device, type: ACTION_TYPES.REQUEST_DEVICE_IMAGE_UPLOAD_S3_FILE_URL },
      successAction: { device, type: ACTION_TYPES.RECEIVE_DEVICE_IMAGE_UPLOAD_S3_FILE_URL },
      errorAction: { device, type: ACTION_TYPES.DEVICE_IMAGE_UPLOAD_S3_FILE_URL_FAILURE },
    });
  };
}

export function requestDeviceImageDelete(device, image) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_IMAGE_DELETE,
    device,
    image,
  };
}

export function receiveDeviceImageDelete(device, image) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_IMAGE_DELETE,
    device,
    image,
  };
}

export function deviceImageDeleteFailure(device, image) {
  return {
    type: ACTION_TYPES.DEVICE_IMAGE_DELETE_FAILURE,
    device,
    image,
  };
}

export function deleteDeviceImage(device, image) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/devices/${device.id}/images/${image.id}`,
      requestAction: requestDeviceImageDelete(device, image),
      successAction: receiveDeviceImageDelete(device, image),
      errorAction: deviceImageDeleteFailure(device, image),
    });
  };
}

export function requestDeviceErrors(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_ERRORS,
    device,
  };
}

export function receiveDeviceErrors(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_ERRORS,
    device,
  };
}

export function deviceErrorsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_ERRORS_FAILURE,
    device,
  };
}

export function fetchDeviceErrors(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/fmerrors`,
      requestAction: requestDeviceErrors(device),
      successAction: receiveDeviceErrors(device),
      errorAction: deviceErrorsFailure(device),
    });
  };
}

export function requestDeviceSamplesBegin(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_SAMPLES_BEGIN,
    device,
  };
}

export function requestDeviceSamplesFail(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_SAMPLES_FAIL,
    device,
  };
}

export function receiveDeviceSamples(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_SAMPLES,
    device,
  };
}

export function fetchDeviceSamples(device, { isCancelled, dateRange={}, onData } = {}) {
  return dispatch => {

    const { startTime, minimumDataPeriod: minimumTimePeriod=0 } = dateRange;

    const userCacheNamespace = `/devices/${device.id}/samples`;
    const dispatchOptions = {
      type: CALL_API,
      method: 'get',
      readCache: false,
      userCacheNamespace,
      endpoint: userCacheNamespace,
      requestAction: requestDeviceSamplesBegin(device),
      successAction: receiveDeviceSamples(device),
      errorAction: requestDeviceSamplesFail(device),
      errorToast: {
        header: 'Could not get chart data',
        timeout: false,
      },
    };

    // allow pages to recursively keep loading until outside of date range
    // or isCancelled returns a truthy value
    let latestSampleTime;
    let earliestSampleTime;

    function formatSampleTimeForAPI(time) {
      return (new Date(time).getTime()/1000).toFixed(1);
    }

    // set the time extents of the received data
    function setSampleDateRange(samples=[]) {
      if (samples[0]) {
        if (!latestSampleTime || new Date(samples[0].sample_time) > latestSampleTime) {
          latestSampleTime = new Date(samples[0].sample_time).getTime();
        }
      }
      if (samples[samples.length - 1]) {
        if (!earliestSampleTime ||
          new Date(samples[samples.length - 1].sample_time) < earliestSampleTime) {
          earliestSampleTime = new Date(samples[samples.length - 1].sample_time).getTime();
        }
      }
    }

    let cacheKeys;

    async function successHandler({ data, cache }={}) {

      // if the request was cancelled then don't handle changes from these samples
      if (isCancelled && isCancelled()) {
        return;
      }

      const samples = data && data._embedded && data._embedded.samples;
      // set sample times
      setSampleDateRange(samples);

      const nextLink = data && data._links && data._links.next;
      const withinDateRange = !!(startTime && earliestSampleTime > startTime);
      const withinTimePeriod = !minimumTimePeriod ||
        latestSampleTime - earliestSampleTime < minimumTimePeriod;

      const stillFetching = !!(nextLink && (withinDateRange || withinTimePeriod));

      if (onData) {
        // add data to context at maybe a throttled rate if we expect more requests
        // update even if an empty list was returned (might be end of pages to update immediately)
        onData(device, samples || [], { stillFetching, hasMore: !!nextLink });
      }

      // if there is a next page to deal with that matches our conditions then handle it
      if (stillFetching) {

        // fetch the cacheKeys if we don't have them yet,
        // the up-to-date cacheKeys don't need to be read each time (can be ~30ms after ~30 pages)
        // as the HTTP requests we are adding to the cache recursively aren't going to match
        // the requests for newer pages down the current request chain
        if (!cacheKeys) {
          cacheKeys = cache && await cache.keys();
        }
        // attempt to find a previous cache key that matches any page
        // continuation inside the current result set.
        // for example: we have 100 results, and a nextLink page to
        // continue from result 100, which has no cache,
        // but we do have 20 pages cached starting from result 99,
        // we should follow the cached page chain, instead of fetching
        // and storing another 20 pages
        const samplePages = samples.map(({ sample_time }) => {
          return formatSampleTimeForAPI(sample_time);
        });
        const foundCacheKey = cacheKeys && cacheKeys.find(({ url }) => {
          const queryParams = new URLSearchParams(new URL(url).search);
          const page = queryParams.get('page');
          // don't allow the same page to be fetched again
          if (parseInt(data.page) === parseInt(page)) {
            return false;
          }
          return samplePages.includes(page);
        });

        dispatch({
          ...dispatchOptions,
          userCacheNamespace,
          // if the cache has a key that matches a stored cache space
          // then use that as the next page, otherwise use nextLink
          endpoint: foundCacheKey
            ? `/devices/${device.id}/samples${new URL(foundCacheKey.url).search}`
            : `/devices/${device.id}/samples${new URL(nextLink).search}`,
          // read cache of next page depending if this page reaches past 4 days ago
          readCache: new Date() - new Date(earliestSampleTime)
            > 4 * 24 * 60 * 60 * 1000,
        }).then(successHandler);
      }
    }

    // start the request chain
    return dispatch(dispatchOptions).then(successHandler);
  };
}

// At the moment, fitpower samples are fetched all at one time.
export function fetchDeviceFitpowerSamplesOnce(device, { onData } = {}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      readCache: false,
      endpoint: `/devices/${device.id}/samples2`,
      requestAction: requestDeviceSamplesBegin(device),
      successAction: receiveDeviceSamples(device),
      errorAction: requestDeviceSamplesFail(device),
      errorToast: {
        header: 'Could not get chart data',
        timeout: false,
      },
    }).then(({ data }) => {
      if(onData) {
        const timezone = data?._embedded?.energy_overview?.timezone;
        onData(device, data?._embedded, { timezone });
      }
    });
  };
}

export function fetchDeviceFitpowerSamples(device, { canceller, dateRange={}, onData, sampleType = 'samples2' } = {}) {
  return dispatch => {

    const { startTime, minimumTimePeriod = 0 } = dateRange;

    const userCacheNamespace = `/devices/${device.id}/${sampleType}`;  // Endpoint to fetch fitpower samples.
    const dispatchOptions = {
      type: CALL_API,
      method: 'get',
      readCache: false,
      userCacheNamespace,
      endpoint: userCacheNamespace,
      requestAction: requestDeviceSamplesBegin(device),
      successAction: receiveDeviceSamples(device),
      errorAction: requestDeviceSamplesFail(device),
      errorToast: {
        header: 'Could not get chart data',
        timeout: false,
      },
    };

    // allow pages to recursively keep loading until outside of date range
    // or isCancelled returns a truthy value
    let latestSampleTime;
    let earliestSampleTime;

    // function formatSampleTimeForAPI(time) {
    //   return (new Date(time).getTime()/1000).toFixed(1);
    // }

    // set the time extents of the received data
    function setSampleDateRange(samplesData={}) {
      // console.log('+++++++++++++++++++set sample date range', samplesData, dateRange);
      const samples = samplesData.measured_data || [];
      if (samples[0]) {
        if (!latestSampleTime || Date.parse(samples[0].timestamp) > latestSampleTime) {
          latestSampleTime = Date.parse(samples[0].timestamp);
        }
      }
      if (samples[samples.length - 1]) {
        if (!earliestSampleTime ||
          Date.parse(samples[samples.length - 1].timestamp) < earliestSampleTime) {
          earliestSampleTime = Date.parse(samples[samples.length - 1].timestamp);
        }
      }
      // console.log(new Date(latestSampleTime));
      // console.log(new Date(earliestSampleTime));
      // console.log('------------------------------');
    }

    // let cacheKeys;

    async function successHandler({ data, cache }={}) {
      // if the request was cancelled then don't handle changes from these samples
      if (canceller?.cancelled || canceller?.currentDeviceId !== device.id) {
        return;
      }

      const samples = data._embedded; // A segment of samples for one page
      const timezone = data?._embedded?.energy_overview?.timezone;
      // set sample times
      setSampleDateRange(samples);

      const nextLink = data && data._links && data._links.next;
      const withinDateRange = !!(startTime && earliestSampleTime > startTime);
      const withinTimePeriod = !minimumTimePeriod ||
      latestSampleTime - earliestSampleTime < minimumTimePeriod;

      const stillFetching = nextLink && (withinTimePeriod || withinDateRange);

      if (onData) {
        // add data to context at maybe a throttled rate if we expect more requests
        // update even if an empty list was returned (might be end of pages to update immediately)
        onData(device, samples || [], { stillFetching, hasMore: !!nextLink, timezone });
      }

      // if there is a next page to deal with that matches our conditions then handle it
      if (stillFetching) {

        dispatch({
          ...dispatchOptions,
          userCacheNamespace,
          // if the cache has a key that matches a stored cache space
          // then use that as the next page, otherwise use nextLink
          endpoint: `/devices/${device.id}/${sampleType}/${new URL(nextLink).search}`,
          // read cache of next page depending if this page reaches past 4 days ago
          // readCache: new Date() - new Date(earliestSampleTime)
          //   > 4 * 24 * 60 * 60 * 1000,
          readCache: true,
        }).then(successHandler);
      }
    }

    // start the request chain
    return dispatch(dispatchOptions).then(successHandler);
  };
}

export function requestDeviceOverview(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_OVERVIEW,
    device
  };
}

export function receiveDeviceOverview(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_OVERVIEW,
    device
  };
}

export function deviceOverviewFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_OVERVIEW_FAILURE,
    device
  };
}

export function fetchDeviceOverview(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/overview`,
      requestAction: requestDeviceOverview(device),
      successAction: receiveDeviceOverview(device),
      errorAction: deviceOverviewFailure(device),
    });
  };
}

export function requestDeviceRuntime(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_RUNTIME,
    device
  };
}

export function receiveDeviceRuntime(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_RUNTIME,
    device
  };
}

export function deviceRuntimeFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_RUNTIME_FAILURE,
    device
  };
}

export function fetchDeviceRuntime(device, { view='summary' }={}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/runtime`,
      params: {
        'view': view || undefined,
      },
      requestAction: requestDeviceRuntime(device),
      successAction: receiveDeviceRuntime(device),
      errorAction: deviceRuntimeFailure(device),
    });
  };
}

export function requestDeviceNotificationThresholds(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_NOTIFICATION_THRESHOLDS,
    device
  };
}

export function receiveDeviceNotificationThresholds(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_NOTIFICATION_THRESHOLDS,
    device
  };
}

export function deviceNotificationThresholdsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_NOTIFICATION_THRESHOLDS_FAILURE,
    device
  };
}

export function fetchDeviceNotificationThresholds(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/alarmsettings`,
      requestAction: requestDeviceNotificationThresholds(device),
      successAction: receiveDeviceNotificationThresholds(device),
      errorAction: deviceNotificationThresholdsFailure(device),
    });
  };
}

export function requestDeviceNotificationThresholdCreate(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_NOTIFICATION_THRESHOLD_CREATE,
    device
  };
}

export function receiveDeviceNotificationThresholdCreate(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_CREATE,
    device
  };
}

export function deviceNotificationThresholdCreateFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_NOTIFICATION_THRESHOLD_CREATE_FAILURE,
    device
  };
}

export function requestDeviceNotificationThresholdUpdate(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_NOTIFICATION_THRESHOLD_UPDATE,
    device
  };
}

export function receiveDeviceNotificationThresholdUpdate(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_UPDATE,
    device
  };
}

export function deviceNotificationThresholdUpdateFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_NOTIFICATION_THRESHOLD_UPDATE_FAILURE,
    device
  };
}

export function saveDeviceNotificationThreshold(device, { id, ...payload }) {
  return dispatch => {
    if (id) {
      return dispatch({
        type: CALL_API,
        method: 'put',
        endpoint: `/devices/${device.id}/alarmsettings/${id}`,
        data: payload,
        requestAction: requestDeviceNotificationThresholdUpdate(device),
        successAction: receiveDeviceNotificationThresholdUpdate(device),
        errorAction: deviceNotificationThresholdUpdateFailure(device),
        successToast: 'Threshold updated',
      });
    }
    else {
      return dispatch({
        type: CALL_API,
        method: 'post',
        endpoint: `/devices/${device.id}/alarmsettings`,
        data: payload,
        requestAction: requestDeviceNotificationThresholdCreate(device),
        successAction: receiveDeviceNotificationThresholdCreate(device),
        errorAction: deviceNotificationThresholdCreateFailure(device),
        successToast: 'Threshold created',
      });
    }
  };
}

export function requestDeviceNotificationThresholdDelete(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_NOTIFICATION_THRESHOLD_UPDATE,
    device
  };
}

export function receiveDeviceNotificationThresholdDelete(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_UPDATE,
    device
  };
}

export function deviceNotificationThresholdDeleteFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_NOTIFICATION_THRESHOLD_UPDATE_FAILURE,
    device
  };
}

export function deleteDeviceNotificationThreshold(device, { id }) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/devices/${device.id}/alarmsettings/${id}`,
      requestAction: requestDeviceNotificationThresholdDelete(device),
      successAction: receiveDeviceNotificationThresholdDelete(device),
      errorAction: deviceNotificationThresholdDeleteFailure(device),
      successToast: 'Threshold deleted',
    });
  };
}

function receiveRecalibration(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_RECALIBRATION,
    device,
  };
}

export function recalibrate(device, {
  recalibrate = true,
  fitmachine_onboard_date = new Date().toISOString(),
  rms_running_cutoff,
} = {}) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}`,
      data: {
        recalibrate,
        fitmachine_onboard_date,
        rms_running_cutoff,
      },
      requestAction: requestBegin(device),
      successAction: receiveRecalibration(device),
      errorAction: requestFail(device),
      successToast: 'Recalibration started',
      errorToast: 'Recalibration failed',
    });
  };
}

export function recalibrateAndRefetch(device, payload) {
  return dispatch => {
    // call recalibrate
    return recalibrate(device, payload)(dispatch)
      // and then fetch info
      .then(() => fetchDeviceInfo(device)(dispatch));
  };
}

// submit device details
function requestEquipmentUpdate() {
  return {
    type: ACTION_TYPES.RECEIVE_EQUIPMENT_UPDATE
  };
}

function receiveEquipmentUpdate() {
  return {
    type: ACTION_TYPES.RECEIVE_EQUIPMENT_UPDATE
  };
}

function equipmentUpdateFail() {
  return {
    type: ACTION_TYPES.EQUIPMENT_UPDATE_FAIL
  };
}

export function submitEquipmentDetails(details) {
  const equipmentId = details.id;
  delete details.id;
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${equipmentId}`,
      data: details,
      requestAction: requestEquipmentUpdate(),
      successAction: receiveEquipmentUpdate(),
      errorAction: equipmentUpdateFail(),
      successToast: 'Device edited',
    });
  };
}
export function requestDeviceMuteStatusUpdate(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_MUTE_STATUS_UPDATE,
    device,
  };
}

export function receiveDeviceMuteStatusUpdate(device, time) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_MUTE_STATUS_UPDATE,
    device,
    mute_advisory_for: time,
  };
}

export function deviceMuteStatusUpdateFail(device) {
  return {
    type: ACTION_TYPES.DEVICE_MUTE_STATUS_UPDATE_FAIL,
    device,
  };
}

export function updateDeviceMuteStatus(device, { time, timeString }) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}/status`,
      data: {
        mute_advisory_for: parseInt(time)
      },
      requestAction: requestDeviceMuteStatusUpdate(device),
      successAction: receiveDeviceMuteStatusUpdate(device, time),
      errorAction: deviceMuteStatusUpdateFail(device),
      successToast: `Yellow notifications ${ time === 0 ? 'unmuted' : `muted for ${timeString}` }`,
      errorToast: `${ time === 0 ? 'Unmuting' : 'Muting' } failed`,
    });
  };
}

// export function receiveFirmwareVersion (device) {
//   return {
//     type: ACTION_TYPES.RECEIVE_FIRMWARE_VERSION,
//     device
//   };
// }

// export function fetchFirmwareVersion (device) {
//   return dispatch => {
//     dispatch({
//       type: CALL_BLE,
//       device,
//       command: COMMANDS.REQUEST_FIRMWARE_VERSION,
//       requestAction: requestBegin(device),
//       successAction: receiveFirmwareVersion(device),
//       errorAction: requestFail(device)
//     });
//   };
// }

// export function requestWifiProfiles (device) {
//   return {
//     type: ACTION_TYPES.REQUEST_WIFI_PROFILES,
//     device
//   };
// }

// export function receiveWifiProfiles (device) {
//   return {
//     type: ACTION_TYPES.RECEIVE_WIFI_PROFILES,
//     device
//   };
// }

// export function fetchWifiProfiles (device) {
//   return dispatch => {
//     dispatch({
//       type: CALL_BLE,
//       device,
//       command: COMMANDS.REQUEST_WIFI_PROFILES,
//       requestAction: requestWifiProfiles(device),
//       successAction: receiveWifiProfiles(device),
//       errorAction: requestFail(device)
//     });
//   };
// }

// export function requestWifiConnectivity (device) {
//   return {
//     type: ACTION_TYPES.REQUEST_WIFI_CONNECTIVITY,
//     device
//   };
// }

// export function receiveWifiConnectivity (device) {
//   return {
//     type: ACTION_TYPES.RECEIVE_WIFI_CONNECTIVITY,
//     device
//   };
// }

// export function fetchWifiConnectivity (device) {
//   return dispatch => {
//     dispatch({
//       type: CALL_BLE,
//       device,
//       command: COMMANDS.REQUEST_WIFI_CONNECTIVITY,
//       requestAction: requestWifiConnectivity(device),
//       successAction: receiveWifiConnectivity(device),
//       errorAction: requestFail(device)
//     });
//   };
// }

export function updateDraft(index, draft, overwrite) {
  return {
    type: ACTION_TYPES.UPDATE_DRAFT,
    index,
    draft,
    overwrite
  };
}

export function discardDraft(index) {
  return {
    type: ACTION_TYPES.DISCARD_DRAFT,
    index
  };
}

export function createTemplate(name, template) {
  return {
    type: ACTION_TYPES.CREATE_TEMPLATE,
    name,
    template
  };
}

export function deleteTemplate(name) {
  return {
    type: ACTION_TYPES.DELETE_TEMPLATE,
    name
  };
}

export function updateNearby(devices) {
  return {
    type: ACTION_TYPES.UPDATE_NEARBY,
    devices
  };
}

function requestArchiveDevice(device) {
  return {
    type: ACTION_TYPES.REQUEST_ARCHIVE_DEVICE,
    device
  };
}

function receiveArchiveDevice(device) {
  return {
    type: ACTION_TYPES.RECEIVE_ARCHIVE_DEVICE,
    device
  };
}

function archiveDeviceFailure(device) {
  return {
    type: ACTION_TYPES.ARCHIVE_DEVICE_FAILURE,
    device
  };
}

export function archiveDevice(device) {
  return dispatch => {
    return dispatch ({
      type: CALL_API,
      method: 'put',
      data: {
        archived: true,
      },
      endpoint: `/devices/${device.id}`,
      requestAction: requestArchiveDevice(device),
      successAction: receiveArchiveDevice(device),
      errorAction: archiveDeviceFailure(device),
      successToast: "Device Archived",
      errorToast: "Archiving Failed"
    });
  };
}

function requestUnarchiveDevice(device) {
  return {
    type: ACTION_TYPES.REQUEST_UNARCHIVE_DEVICE,
    device
  };
}

function receiveUnarchiveDevice(device) {
  return {
    type: ACTION_TYPES.RECEIVE_UNARCHIVE_DEVICE,
    device
  };
}

function unarchiveDeviceFailure(device) {
  return {
    type: ACTION_TYPES.UNARCHIVE_DEVICE_FAILURE,
    device
  };
}

export function unarchiveDevice(device) {
  return dispatch => {
    return dispatch ({
      type: CALL_API,
      method: 'put',
      data: {
        archived: false,
      },
      endpoint: `/devices/${device.id}`,
      requestAction: requestUnarchiveDevice(device),
      successAction: receiveUnarchiveDevice(device),
      errorAction: unarchiveDeviceFailure(device),
      successToast: "Device Unarchived",
      errorToast: "Unarchiving Failed",
    });
  };
}

export function requestDeviceWatchers(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_WATCHERS,
    device,
  };
}

export function receiveDeviceWatchers(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_WATCHERS,
    device,
  };
}

export function deviceWatchersFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_WATCHERS_FAILURE,
    device,
  };
}

export function fetchDeviceWatchers(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/watchers`,
      requestAction: requestDeviceWatchers(device),
      successAction: receiveDeviceWatchers(device),
      errorAction: deviceWatchersFailure(device),
    });
  };
}

export function receiveDeviceStatus() {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_STATUS,
  };
}

export function deviceStatusFailure() {
  return {
    type: ACTION_TYPES.DEVICE_STATUS_FAILURE,
  };
}

export function fetchDeviceStatus() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/domains/devicestatus',
      successAction: receiveDeviceStatus(),
      errorAction: deviceStatusFailure(),
    });
  };
}

export function setDeviceSelectedStatus(selected) {
  return {
    type: ACTION_TYPES.SET_DEVICE_SELECTED_STATUS,
    selected
  };
}

export function requestDeviceConditionCurves() {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_CONDITION_CURVES,
  };
}

export function receiveDeviceConditionCurves() {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_CONDITION_CURVES,
  };
}

export function deviceConditionCurvesFailure() {
  return {
    type: ACTION_TYPES.DEVICE_CONDITION_CURVES_FAILURE,
  };
}

export function fetchDeviceConditionCurves() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/domains/conditioncurves',
      requestAction: requestDeviceConditionCurves(),
      successAction: receiveDeviceConditionCurves(),
      errorAction: deviceConditionCurvesFailure(),
    });
  };
}

export function receiveUpdateDeviceTags(device) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_DEVICE_TAGS,
    device,
  };
}

export function updateDeviceTags(device, tags) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}/tags`,
      data: tags,
      requestAction: requestDeviceTags(device),
      successAction: receiveUpdateDeviceTags(device, tags),
      errorAction: deviceTagsFailure(device),
    });
  };
}

export function requestDeviceRoles() {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_ROLES,
  };
}

export function receiveDeviceRoles() {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_ROLES,
  };
}

export function deviceRolesFailure() {
  return {
    type: ACTION_TYPES.DEVICE_ROLES_FAILURE,
  };
}

export function fetchDeviceRoles() {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/domains/deviceroles',
      requestAction: requestDeviceRoles(),
      successAction: receiveDeviceRoles(),
      errorAction: deviceRolesFailure(),
    });
  };
}

export function requestDeviceImpactConfig(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_IMPACT_CONFIG,
    device,
  };
}

export function deviceImpactConfigFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_IMPACT_CONFIG_FAILURE,
    device,
  };
}

export function receiveDeviceImpactConfig(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_IMPACT_CONFIG,
    device,
  };
}

export function fetchDeviceImpactConfig(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/impactsettings`,
      requestAction: requestDeviceImpactConfig(device),
      successAction: receiveDeviceImpactConfig(device),
      errorAction: deviceImpactConfigFailure(device),
    });
  };
}

export function receiveAddDeviceImpactConfig(device) {
  return {
    type: ACTION_TYPES.RECEIVE_ADD_DEVICE_IMPACT_CONFIG,
    device,
  };
}

export function addDeviceImpactConfig(device, config) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'post',
      endpoint: `/devices/${device.id}/impactsettings`,
      data: config,
      requestAction: requestDeviceImpactConfig(device),
      successAction: receiveAddDeviceImpactConfig(device),
      errorAction: deviceImpactConfigFailure(device),
      successToast: 'Impact setting created.'
    });
  };
}

export function receiveUpdateDeviceImpactConfig(device, config, data) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_DEVICE_IMPACT_CONFIG,
    device,
    config,
    data,
  };
}

export function updateDeviceImpactConfig(device, config, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}/impactsettings/${config.id}`,
      data,
      requestAction: requestDeviceImpactConfig(device),
      successAction: receiveUpdateDeviceImpactConfig(device, config, data),
      errorAction: deviceImpactConfigFailure(device),
      successToast: 'Impact setting updated.'
    });
  };
}

export function receiveDeleteDeviceImpactConfig(device, config) {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_DEVICE_IMPACT_CONFIG,
    device,
    config,
  };
}

export function deleteDeviceImpactConfig(device, config) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/devices/${device.id}/impactsettings/${config.id}`,
      requestAction: requestDeviceImpactConfig(device),
      successAction: receiveDeleteDeviceImpactConfig(device, config),
      errorAction: deviceImpactConfigFailure(device),
      successToast: 'Impact setting deleted.'
    });
  };
}

export function requestDeviceImpactSummary(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_IMPACT_SUMMARY,
    device,
  };
}
export function receiveDeviceImpactSummary(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_IMPACT_SUMMARY,
    device,
  };
}
export function deviceImpactSummaryFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_IMPACT_SUMMARY_FAILURE,
    device,
  };
}
export function fetchDeviceImpactSummary(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/impactsummary`,
      requestAction: requestDeviceImpactSummary(device),
      successAction: receiveDeviceImpactSummary(device),
      errorAction: deviceImpactSummaryFailure(device),
    });
  };
}
function receiveDeviceLocale(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_LOCALE,
    device,
  };
}
function deviceLocaleFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_LOCALE_FAILURE,
    device,
  };
}
export function fetchDeviceLocale(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/locale`,
      successAction: receiveDeviceLocale(device),
      errorAction: deviceLocaleFailure(device),
    });
  };
}

function requestDeviceSettings(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_SETTINGS,
    device,
  };
}
function receiveDeviceSettings(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_SETTINGS,
    device,
  };
}
function deviceSettingsFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_SETTINGS_FAILURE,
    device,
  };
}
export function fetchDeviceSettings(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/settings`,
      requestAction: requestDeviceSettings(device),
      successAction: receiveDeviceSettings(device),
      errorAction: deviceSettingsFailure(device),
    });
  };
}

function receiveUpdateDeviceSettings(device, data) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_DEVICE_SETTINGS,
    device,
    data,
  };
}
export function updateDeviceSettings(device, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}/settings`,
      data,
      requestAction: requestDeviceSettings(device),
      successAction: receiveUpdateDeviceSettings(device, data),
      errorAction: deviceSettingsFailure(device),
      successToast: "Settings are updated."
    });
  };
}

function requestDeviceLinks(device) {
  return {
    type: ACTION_TYPES.REQUEST_DEVICE_LINKS,
    device,
  };
}
function deviceLinksFailure(device) {
  return {
    type: ACTION_TYPES.DEVICE_LINKS_FAILURE,
    device,
  };
}
function receiveDeviceLinks(device) {
  return {
    type: ACTION_TYPES.RECEIVE_DEVICE_LINKS,
    device,
  };
}
function receiveUpdateDeviceLinks(device, data) {
  return {
    type: ACTION_TYPES.RECEIVE_UPDATE_DEVICE_LINKS,
    device,
    data,
  };
}

export function fetchDeviceLinks(device) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: `/devices/${device.id}/links`,
      requestAction: requestDeviceLinks(device),
      successAction: receiveDeviceLinks(device),
      errorAction: deviceLinksFailure(device),
    });
  };
}
export function updateDeviceLinks(device, data) {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/devices/${device.id}/links`,
      data,
      requestAction: requestDeviceLinks(device),
      successAction: receiveUpdateDeviceLinks(device, data),
      errorAction: deviceLinksFailure(device),
      successToast: 'Device links are updated.'
    });
  };
}
