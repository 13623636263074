import { useEffect, useCallback, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDeviceLinks, updateDeviceLinks } from '../actions';
import { getDeviceLinks, getDeviceLinksState } from '../selectors';

function useDeviceLinks(deviceId, { fetch = false } = {}) {
  const dispatch = useDispatch();

  const links = useSelector(state => getDeviceLinks(state, deviceId));
  const { loading, error, lastFetch } = useSelector(state => getDeviceLinksState(state, deviceId)) || {};

  const fetchLinks = useCallback(() => {
    if(!deviceId) return;
    dispatch(fetchDeviceLinks({ id: deviceId }));
  }, [deviceId, dispatch]);

  const updateLinks = useCallback(async (data) => {
    await dispatch(updateDeviceLinks({ id: deviceId }, data));
    await dispatch(fetchDeviceLinks({ id: deviceId }));
  }, []);

  useEffect(() => {
    if(fetch && deviceId) {
      fetchLinks();
    }
  }, [fetch, deviceId]);

  return { loading, error, lastFetch, links, updateLinks };
}

export default useDeviceLinks;