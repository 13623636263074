import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { fetchAlarms } from '../actions';

import Table from '../../../components/Table';
import { useTableProps, NoDataIndication } from './Alarms';

import {
  siteName,
  subAreaName,
  equipmentName,
  organisationName,
} from '../../equipment/columns';

import {
  alarmDescription,
  alarmTimestamp,
  alarmStatusExtra as statusBadge,
  triggerDescription,
  transitionedToBadge,
  current,
} from '../columns';

import { getAlarms, getAlarmListState } from '../selectors';
import {
  getActiveSubGroupId,
  getOrganisationUserColumns,
} from '../../organisation/selectors';
import { fetchDevices } from '../../equipment/actions';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

const defaultSorted = [{
  dataField: 'alarm_timestamp',
  order: 'desc'
}];

const alarmTypeFilter = 'threshold';

const columnsArr = [
  alarmTimestamp,
  current,
  transitionedToBadge,
  alarmDescription,
  siteName,
  { ...subAreaName, dataField: 'site_subarea_name' },
  equipmentName,
  statusBadge,
  organisationName,
  triggerDescription,
];

function Alarms({
  activeSubGroupId,
  alarms=[],
  fetchAlarms,
  fetchDevices,
  loading,
  lastFetch,
  error,
  organisationUserColumns,
}) {

  const { refresh, renderHeader, filteredAlarms, columns } = useTableProps({
    alarmTypeFilter,
    activeSubGroupId,
    fetchAlarms,
    fetchDevices,
    loading,
    lastFetch,
    error,
    alarms,
    columnsArr,
    organisationUserColumns,
  });
  const history = useHistory();
  const localisedColumns = useLocalisedColumns(columns);

  return (
    <Container fluid>
      <Table
        pagination
        renderHeader={renderHeader}
        defaultSorted={defaultSorted}
        noDataIndication={NoDataIndication}
        loading={loading}
        columns={localisedColumns}
        data={filteredAlarms}
        refreshHandler={refresh}
        rowEvents = {{
          onClick: (e, alarm) => {
            if(window.getSelection().toString().length > 0) return;
            history.push(`/alarms/${alarm.id}`);
          }
        }}
        rowStyle={{
          cursor: 'pointer'
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const alarms = getAlarms(state);
  const { loading, lastFetch, error } = getAlarmListState(state);
  return {
    alarms,
    loading,
    lastFetch,
    error,
    activeSubGroupId: getActiveSubGroupId(state),
    organisationUserColumns: getOrganisationUserColumns(state),
  };
};

const mapDispatchToProps = { fetchAlarms, fetchDevices };

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
