import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchDevices, fetchDeviceImages } from '../actions';
import { fetchGateways } from '../../gateway/actions';
import { Container, Row, Col, Card, ListGroup, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosAlert, IoIosWarning, IoIosWifi } from 'react-icons/io';
import { Icon, Button as BlueprintButton } from '@blueprintjs/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import IoIosMotor from '../../../components/IoIosMotor';

import Bar from '../../../components/Bar';
import RunningStatus from '../components/RunningStatus';
import { AddEquipmentButton } from './EquipmentList';

import { addToast } from '../../../components/Toaster';
import Toolbar from '../../../components/TableToolbar';

import Map from '../../../components/Map';
import {
  greenMarkerSrc,
  yellowMarkerSrc,
  redMarkerSrc,
  greyMarkerSrc,
  gatewayMarkerSrc,
} from '../../../components/Markers';

import StatusIndicatorBadges, {
  StaleIndicatorBadge,
} from '../components/StatusIndicatorBadges';
import { isSuperAdmin, isAdmin } from '../../user/selectors';
import Private from '../../../components/Private';
import { getDeviceListState, getDevices } from '../selectors';
import { getConnections, getGatewayListState, getGateways } from '../../gateway/selectors';
import EmptyCard from '../../../components/EmptyCard';
import { getActiveSubGroupId, getCurrentOrganisationHasProductCode } from '../../organisation/selectors';
import BasicTooltip from '../../../components/BasicTooltip';
import { ApiRequestCanceller } from '../../../lib/utils';
import useIncludeSubGroup from '../../../hooks/useIncludeSubGroup';
import FolderTree from '../../../images/folder_tree1.png';

const seconds = 1000;
const minutes = 60 * seconds;
const hours = 60 * minutes;
const days = 24 * hours;
const thirtyDays = 30 * days;
const sevenDays = 7 * days;
const oneDay = days;
const zeroDays = 0;

function getGatewayMarkerProps() {
  return {
    alt: 'gateway location',
    clusterData: 'purple',
    src: gatewayMarkerSrc,
    // style as smaller than FitMachine markers
    // as gateways are often "positioned" at FitMachines
    style: {
      maxWidth: 32,
      maxHeight: 32,
    },
  };
}

// see reference:
// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-line-line-color
const mapLinesProps = {
  paint: {
    'line-color': '#A856AE',
    'line-width': 1.5,
    // props can be passed in from the "lines" prop
    // example https://web.archive.org/web/20201021161414/https://docs.mapbox.com/mapbox-gl-js/example/data-driven-lines/
    'line-opacity': ['get', 'line-opacity'],
  },
};

function getDeviceConditionMarkerProps({ data: device={} }) {
  // unknown values should be grey (probably null)
  if (typeof device.condition_overall !== 'number') {
    return {
      clusterData: 'grey',
      src: greyMarkerSrc,
      alt: `condition unknown: ${getDeviceTitle(device)}`,
    };
  }
  if (device.condition_overall <= 1/3) {
    return {
      clusterData: 'green',
      src: greenMarkerSrc,
      alt: `condition good: ${getDeviceTitle(device)}`,
    };
  }
  if (device.condition_overall <= 2/3) {
    return {
      clusterData: 'yellow',
      src: yellowMarkerSrc,
      alt: `condition warning: ${getDeviceTitle(device)}`,
    };
  }
  else {
    return {
      clusterData: 'red',
      src: redMarkerSrc,
      alt: `condition degraded: ${getDeviceTitle(device)}`,
    };
  }
}

function getMarkerProps(point) {
  if (isGatewayPoint(point)) {
    return getGatewayMarkerProps(point);
  }
  else {
    return getDeviceConditionMarkerProps(point);
  }
}

const getDeviceTitle = ({ site_name, sub_area_name, equipment_name }) => {
  return `${site_name} - ${sub_area_name} - ${equipment_name}`;
};

// adapted from https://codepen.io/smlsvnssn/pen/FolaA
function createSvgArc(r2, rad1, rad2, r1=0, x=0, y=0) {
  // large arcs require a different curve
  const largeArcFlag = Math.abs(rad2 - rad1) > Math.PI ? 1 : 0;
  return [
    // start at inner radius
    'M',
    x + Math.cos(rad2) * r1,
    y - Math.sin(rad2) * r1,
    // move linearly to outer radius
    'L',
    x + Math.cos(rad2) * r2,
    y - Math.sin(rad2) * r2,
    // move in an arc to the next radian
    'A',
    r2,
    r2,
    0,
    largeArcFlag,
    0, // move clockwise
    x + Math.cos(rad1 - 0.00001) * r2, // adjustment: ensure a complete circle
    y - Math.sin(rad1 - 0.00001) * r2, // *looks like* a complete circle
    // mover linearly to inner radius
    'L',
    x + Math.cos(rad1 - 0.00001) * r1, // adjustment: ensure a complete circle
    y - Math.sin(rad1 - 0.00001) * r1, // *looks like* a complete circle
    // move in an arc to the previous radian
    'A',
    r1,
    r1,
    0,
    largeArcFlag,
    1, // move anti-clockwise
    x + Math.cos(rad2) * r1,
    y - Math.sin(rad2) * r1,
  ].join(' ');
}

function getRadiansFromPercent(percent) {
  // start at 90deg then count clockwise (negative)
  return Math.PI * (1/2 - 2 * (percent || 0));
}

function MarkerClusterBorder({ getLeaves, totalPoints, ...svgProps }) {
  // collect clusterData form Markers inside the Cluster
  const leaves = getLeaves();
  // count only devices as points
  const pointCount = leaves.filter(leaf => isDevicePoint(leaf.props.pointData)).length;
  const gatewayPointCount = leaves.filter(leaf => isGatewayPoint(leaf.props.pointData)).length;
  const conditions = leaves.reduce((acc, { props }) => {
    acc[props.clusterData] += 1;
    return acc;
  }, { green: 0, yellow: 0, red: 0, grey: 0, purple: 0 });
  const { green, yellow, red, purple } = conditions;
  // inner radius of the visualisation colour
  const r0 = 30;
  // make radius dynamic based on the of points inside the cluster
  const r1 = 35 + 25 * Math.log(pointCount || 1) / Math.log(totalPoints);
  // make gateway radius dynamic based on the points inside the cluster
  const r2 = r1 + 5 + 10 * Math.log(gatewayPointCount || 1) / Math.log(totalPoints);
  // get all points to plot in radians
  const rad0 = getRadiansFromPercent(0/pointCount);
  const radG = getRadiansFromPercent(green/pointCount);
  const radY = getRadiansFromPercent((green + yellow)/pointCount);
  const radR = getRadiansFromPercent((green + yellow + red)/pointCount);
  const rad1 = getRadiansFromPercent(1);
  return (
    <svg {...svgProps} viewBox="-75 -75 150 150">
      <g>
        {/* draw purple circle of gateways */}
        {purple > 0 && <path d={createSvgArc(r2, rad0, rad1)} className="purple" fill="#a3a"/>}
        {/* draw indication pie of devices */}
        {rad0 !== radG && <path d={createSvgArc(r1, rad0, radG)} className="green" fill="#0f0"/>}
        {radG !== radY && <path d={createSvgArc(r1, radG, radY)} className="yellow" fill="#ff0"/>}
        {radY !== radR && <path d={createSvgArc(r1, radY, radR)} className="red" fill="#f00"/>}
        {radR !== rad1 && <path d={createSvgArc(r1, radR, rad1)} className="grey" fill="#999"/>}
        {/* draw white circle for text to be on top of (visible in print view) */}
        <circle cx="0" cy="0" r={r0} fill="#fff"/>
      </g>
    </svg>
  );
}

function DevicePopUp({
  point: { data: device={} },
  cluster = {},
  connectedPoints = [],
  setSelectedPoint,
  showConnections,
}) {
  const { selectedPointIndex, size } = cluster;
  const deviceConnections = connectedPoints.filter(([, devicePoint]) => {
    return devicePoint.data.id === device.id;
  }).sort(([, , relationA], [, , relationB]) => {
    const lastHeardEpochA = new Date(relationA.last_heard).getTime();
    const lastHeardEpochB = new Date(relationB.last_heard).getTime();
    // sort by most recent
    return !isNaN(lastHeardEpochA) && !isNaN(lastHeardEpochB)
      ? lastHeardEpochB - lastHeardEpochA
      : 0;
  });
  return (
    <Card style={{ width: '18rem' }}>
      {device.images && device.images.length > 0 ? (
        <div
          style={{
            height: '150px',
            background: 'transparent no-repeat center',
            backgroundSize: 'cover',
            backgroundImage: `url(${device.images[0].url})`
          }}
        >
        </div>
      ) : (
        <Card.Body className="text-center">
          <IoIosMotor className="img-thumbnail p-3" size="18em" />
        </Card.Body>
      )}
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Link to={`/equipment/${device.id}`}>
            {getDeviceTitle(device)}
          </Link>
        </ListGroup.Item>
        <ListGroup.Item>
          <Row>
            <Col xs="auto">
              <b>Condition</b>
            </Col>
            <Col className="text-right mb-2">
              Running: <RunningStatus value={device.running} />
              {' '}
              <StatusIndicatorBadges device={device} badgeComponents={[StaleIndicatorBadge]} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Bar conditionValue={device.condition_overall} thumbSizeValue={130} />
            </Col>
          </Row>
          {showConnections && (
            <div className="mt-3 mb-1">
              Connected access points: {
                // count the connectedPoints that include this device
                deviceConnections.length
              }
            </div>
          )}
          {showConnections && deviceConnections.length > 0 && (
            <ul>
              {deviceConnections.map(([gatewayPoint, , { last_heard }]) => {
                const lastHeardEpoch = new Date(last_heard).getTime();
                const { data: gateway } = gatewayPoint;
                return (
                  <li key={gateway.id}>
                    <BlueprintButton
                      icon="map-marker"
                      title="Location"
                      small
                      outlined
                      onClick={() => setSelectedPoint(gatewayPoint)}
                    /> <Link to={`/gateways/${gateway.id}`}>
                      {gateway.ssid || '[unnamed]'}
                    </Link> (last seen: {
                      // show last seen if known
                      !isNaN(lastHeardEpoch)
                        ? moment(lastHeardEpoch).fromNow()
                        : 'unknown'
                    })
                  </li>
                );
              })}
            </ul>
          )}
        </ListGroup.Item>
        {size > 1 && (
          <ListGroup.Item className="text-center">
            Equipment {selectedPointIndex + 1} of {size}. Click marker to cycle.
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
}

function GatewayPopup({
  point: { data: gateway={} },
  cluster={},
  connectedPoints,
  setSelectedPoint,
  showConnections,
}) {
  const { selectedPointIndex, size } = cluster;
  const gatewayConnections = connectedPoints.filter(([gatewayPoint]) => {
    return gatewayPoint.data.id === gateway.id;
  }).sort(([, , relationA], [, , relationB]) => {
    const lastHeardEpochA = new Date(relationA.last_heard).getTime();
    const lastHeardEpochB = new Date(relationB.last_heard).getTime();
    // sort by most recent
    return !isNaN(lastHeardEpochA) && !isNaN(lastHeardEpochB)
      ? lastHeardEpochB - lastHeardEpochA
      : 0;
  });

  // gatewayConnectionsObj is an object derived from gatewayConnections where its key is 'lastSeen' and value is an array of devices grouped by 'lastSeen'.
  const gatewayConnectionsObj = gatewayConnections.reduce((prev, current) => {
    const [, devicePoint, { last_heard }] = current;
    const { data: device } = devicePoint;
    const lastHeardEpoch = new Date(last_heard).getTime();
    const lastSeen = !isNaN(lastHeardEpoch) ? moment(lastHeardEpoch).fromNow() : 'unknown';
    return {
      ...prev,
      [lastSeen]: prev[lastSeen] ? [...prev[lastSeen], {
        id: device.id,
        equipment_name: device.equipment_name,
        devicePoint
      }] : [{
        id: device.id,
        equipment_name: device.equipment_name,
        devicePoint
      }]
    };
  }, {});
  return (
    <Card style={{ width: '18rem' }}>
      <Card.Body className="text-center">
        <IoIosWifi className="img-thumbnail" size="18em" />
      </Card.Body>
      <ListGroup variant="flush">
        <ListGroup.Item>
          Access Point: <Link to={`/gateways/${gateway.id}`}>
            {gateway.ssid || '[unnamed]'}
          </Link> ({gateway.mac || `id: ${gateway.id || 'none'}`})
          {showConnections && (
            <div className="mt-3 mb-1">
              Connected devices: {
                // count the connectedPoints that include this gateway
                gatewayConnections.length
              }
            </div>
          )}
          {showConnections && gatewayConnections.length > 0 && gatewayConnectionsObj && (
            Object.entries(gatewayConnectionsObj).map(([key, value], index) => {
              return (
                <ul key={index}>
                  last seen {key}:
                  {
                    value.map(device => (
                      <li key={device.id}>
                        <BlueprintButton
                          icon="map-marker"
                          title="Location"
                          small
                          outlined
                          onClick={() => setSelectedPoint(device.devicePoint)}
                        /> <Link to={`/devices/${device.id}`}>
                          {device.equipment_name || '[unnamed]'}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              );
            })
          )}
        </ListGroup.Item>
        {size > 1 && (
          <ListGroup.Item className="text-center">
            Equipment {selectedPointIndex + 1} of {size}. Click marker to cycle.
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
}

function NoDataIndication({ isAdmin, hasActiveSubGroup }) {
  const { t } = useTranslation();
  return isAdmin ? hasActiveSubGroup ? (
    // admin on org group
    <EmptyCard Icon={IoIosMotor}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          {t('common.started-adding-first-heading')}
        </EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.equipment-map.no-data.started-adding-first-body')}
        </EmptyCard.Text>
        <div className="text-center">
          <AddEquipmentButton />
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  ) : (
    // admin on sub group
    <EmptyCard Icon={IoIosMotor}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          {t('common.started-adding-group-heading')}
        </EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.equipment-map.no-data.started-adding-group-body')}
        </EmptyCard.Text>
        <div className="text-center">
          <LinkContainer to="/group/devices">
            <Button variant="primary">
              {t('common.manage-group-equipment')}
            </Button>
          </LinkContainer>
        </div>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  ) : (
    // user
    <EmptyCard Icon={IoIosMotor}>
      <EmptyCard.Body>
        <EmptyCard.Title>
          {t('common.no-equipment-heading')}
        </EmptyCard.Title>
        <EmptyCard.Text>
          {t('screens.equipment.equipment-map.no-data.no-equipment-body')}
        </EmptyCard.Text>
      </EmptyCard.Body>
      <EmptyCard.UniversityFooter />
    </EmptyCard>
  );
}

const ConnectedNoDataIndication = connect(state => ({
  isAdmin: isAdmin(state),
  hasActiveSubGroup: !!getActiveSubGroupId(state),
}))(NoDataIndication);

// add a Loading component
function Loading() {
  return 'Loading...';
}

const isDevicePoint = point => point.type === 'device';
const isGatewayPoint = point => point.type === 'gateway';
const matchDeviceOrGatewayPoints = source => object => {
  // return true if points match type and id
  return source && object &&
    (source.type === object.type) &&
    (source.data.id === object.data.id);
};

let searchId = 0;
function SearchBar({ searchProps: { searchText, onSearch }={} }) {
  const { t } = useTranslation();
  const id = useState(() => searchId+=1)[0];
  const onChange = useCallback(e => onSearch(e.target.value || ''), [onSearch]);
  return (
    <label htmlFor={`custom-search-bar-${id}`} className="search-label">
      <span id={`custom-search-bar-${id}-label`} className="sr-only">
        Search this table
      </span>
      <input
        id={`custom-search-bar-${id}`}
        type="text"
        aria-labelledby={`custom-search-bar-${id}-label`}
        className="form-control align-middle d-inline-block react-bootstrap-table2-search-header"
        placeholder={t('common.search')}
        value={searchText}
        onChange={onChange}
      />
    </label>
  );
}

function EquipmentMap(props) {

  const {
    activeSubGroupId,
    lastFetch,
    loading,
    error,
    devices,
    gateways,
    connections,
    maxDeviceCount,
    fetchDevices,
    fetchDeviceImages,
    fetchGateways,
    userIsSuperAdmin,
    hasNetworkFeature,
  } = props;
  const { includeSubGroup, setIncludeSubGroup } = useIncludeSubGroup('equipment_list');

  const { t } = useTranslation();
  const dispatch = useDispatch();
  // fetch devices upon mount, or group change
  useEffect(() => {
    const canceller = new ApiRequestCanceller();
    fetchDevices({ includeChildren: includeSubGroup }, canceller);
    return () => {
      dispatch(canceller.cancel());
    };
  }, [fetchDevices, activeSubGroupId, includeSubGroup]);

  // fetch gateways upon mount
  useEffect(() => {
    hasNetworkFeature && fetchGateways();
  }, [hasNetworkFeature, fetchGateways]);

  // collect devices and gateways into map points
  const points = useMemo(() => {
    return [
      // add devices
      ...(devices || []).filter(Boolean).map(device => ({
        // add required fields for map points
        latitude: parseFloat(device.latitude),
        longitude: parseFloat(device.longitude),
        type: 'device',
        data: device,
      })),
      // add gateways with gateway-device connections
      ...(gateways || []).filter(Boolean).map(gateway => ({
        // add required fields for map points
        latitude: parseFloat(gateway.latitude),
        longitude: parseFloat(gateway.longitude),
        type: 'gateway',
        // flag gateway points to not be counted in cluster point counts
        ignoreInPointCount: true,
        data: gateway,
      })),
    ];
  }, [devices, gateways]);

  // split points into valid and invalid points
  const { validPoints, invalidPoints } = useMemo(() => {
    return points.reduce((acc, point) => {
      // filter out points without valid latitudes or longitudes
      const { latitude, longitude } = point;
      const nLat = parseFloat(latitude);
      const nLong = parseFloat(longitude);
      if (!isNaN(nLat) && nLat >= -90 && nLat <= 90
        && !isNaN(nLong) && nLong >= -180 && nLong <= 180) {
        acc.validPoints.push(point);
      } else {
        acc.invalidPoints.push(point);
      }
      return acc;
    }, { validPoints: [], invalidPoints: [] });
  }, [points]);

  useEffect(() => {
    const invalidDevices = invalidPoints.filter(isDevicePoint);
    if (invalidDevices.length > 0) {
      addToast({
        header: 'Unable to map devices:',
        body: (
          <ul>
            {invalidDevices.map(({ data: device }) => (
              <li key={device.id}>
                <a href={`/equipment/${device.id}`}>
                  {getDeviceTitle(device)}
                </a>
              </li>
            ))}
          </ul>
        ),
        timeout: false,
      });
    }
    // update whenever invalid device id list updates
  }, [invalidPoints.filter(isDevicePoint).map(({ data={} }) => data.id).join('-')]);

  const [conditionFilterValue, setConditionFilterValue] = useState('all');
  const [runningFilterValue, setRunningFilterValue] = useState('all');
  const [searchText, setSearchText] = useState('');
  const onSearch = useCallback((value = '') => {
    setSearchText(value);
  }, []);

  const [networkMaxAge, setNetworkMaxAge] = useState(7 * days);

  // split valid points into valid device and gateway points
  const { devicePoints, gatewayPoints, connectedPoints } = useMemo(() => {
    const devicePoints = validPoints.filter(isDevicePoint);
    const gatewayPoints = networkMaxAge ? validPoints.filter(isGatewayPoint) : [];

    // derive connections from gatewayPoint relations and current devicePoints
    const connectedPoints = (connections || [])
      .map(([gateway, device, relation]) => {
        const devicePoint = devicePoints.find(({ data }) => data.id === device.id);
        const gatewayPoint = gatewayPoints.find(({ data }) => data.id === gateway.id);
        return devicePoint && gatewayPoint && [gatewayPoint, devicePoint, relation];
      })
      // filter to only found and validly plottable points
      .filter(Boolean)
      // filter to connection age restriction
      .filter(([, , { last_heard }]) => {
        // if data is not available then presume true
        if (!last_heard) {
          return true;
        }
        const lastHeardEpoch = new Date(last_heard).getTime();
        // do not display invalid dates
        if (!lastHeardEpoch || isNaN(lastHeardEpoch)) {
          return false;
        }
        // return true if difference if less than maximum
        return Date.now() - lastHeardEpoch < networkMaxAge;
      });

    // return all computed variables
    return {
      devicePoints,
      gatewayPoints,
      connectedPoints,
    };
  }, [validPoints, connections, networkMaxAge]);

  const [filteredDevicePoints, setFilteredDevicePoints] = useState(devicePoints);

  // each time the device list or search text changes, update the filtered devices list
  useEffect(() => {

    const filters = [];

    // add running filter first as it is the fastest
    if (runningFilterValue !== 'all') {
      const runningFilters = {
        'running': ({ data: device={} }) => device.running,
        'not-running': ({ data: device={} }) => device.running === false,
        'default': ({ data: device={} }) => !device.running && device.running !== false,
      };
      filters.push(runningFilters[runningFilterValue] || runningFilters.default);
    }

    // add condition filter
    if (conditionFilterValue !== 'all') {
      const conditionFilter = conditionFilterValue === 'danger'
        ? ({ data: device={} }) => device.condition_overall > 2/3
        : ({ data: device={} }) => device.condition_overall > 1/3;
      filters.push(conditionFilter);
    }

    // add string filter last as it is the slowest
    const lowerCaseValue = searchText.trim().toLowerCase();
    if (lowerCaseValue) {
      const stringFilter = ({ data: device={} }) => {
        return `${
          device.organisation_sub_domain
        }|${
          device.site_name
        }|${
          device.sub_area_name
        }|${
          device.equipment_name
        }`.toLowerCase().includes(lowerCaseValue);
      };
      filters.push(stringFilter);
    }

    // apply all filters
    const filteredDevicePoints = filters.reduce((acc, filter) => {
      return filter ? acc.filter(filter) : acc;
    }, devicePoints);

    // set new state
    setFilteredDevicePoints(filteredDevicePoints);
  }, [devicePoints, searchText, conditionFilterValue, runningFilterValue]);

  // put computation of tableProps behind a memoiser
  const getTableProps = useCallback(() => {
    return {
      dataSizeProps: {
        totalCount: devicePoints.length,
        filteredCount: filteredDevicePoints.length,
        itemName: 'equipment',
        itemsName: 'equipment',
      },
      searchProps: {
        searchText,
        onSearch,
        onClear: onSearch,
      },
    };
  }, [devicePoints, filteredDevicePoints, searchText, onSearch]);

  // when the computed table props will change, update the tableProps
  const [tableProps, setTableProps] = useState(getTableProps);
  useEffect(() => {
    setTableProps(getTableProps);
  }, [getTableProps]);

  const [selectedPoint, setSelectedPoint] = useState(null);

  // logic on point clicks
  const onPointSelected = useCallback(point => {
    setSelectedPoint(point);
    // asynchronously request get images of device based on id
    // but only if images are not yet loaded
    if (isDevicePoint(point) && point.data && !(
      point.data.images && point.data.images.length > 0
    )) {
      fetchDeviceImages(point.data);
    }
  }, [fetchDeviceImages]);

  // revert point selection on point un-click
  const onPointUnselected = useCallback(() => {
    setSelectedPoint(null);
  }, []);

  const [mapLines, setMapLines] = useState([]);

  // draw network lines
  useEffect(() => {
    // format the desired network connection lines
    const lines = connectedPoints
      // filter to selected point only if relevant
      .filter(([pointA, pointB]) => {
        // filter to only selected point
        if (selectedPoint) {
          const { type, data: { id } } = selectedPoint;
          // match either point A or B by type and id
          return (
            (pointA.type === type) && (pointA.data.id === id)
          ) || (
            (pointB.type === type) && (pointB.data.id === id)
          );
        }
        // allow all points by default
        else {
          return true;
        }
      })
      // map connectedPoints into <Map>[lines] format
      .map(([pointA, pointB, { last_heard }]) => {
        const lastHeardEpoch = new Date(last_heard).getTime();
        return [
          // start at pointA
          pointA,
          // go to pointB
          pointB,
          // line properties can be passed in third argument for <Map>[lines]
          // example https://web.archive.org/web/20201021161414/https://docs.mapbox.com/mapbox-gl-js/example/data-driven-lines/
          {
            'line-opacity': Math.sqrt(
              networkMaxAge && lastHeardEpoch
                // add linear opacity relation between max age and now
                // and ensure minimum opacity is 0 (not negative)
                ? Math.max(0, (
                  // and ensure future 'last_heard' values don't exceed now
                  1 - Math.max(0, Date.now() - lastHeardEpoch) / networkMaxAge
                ))
                // add line regardless of age
                : 1
            ),
          },
        ];
      });
    // set lines on map
    setMapLines(lines);
  }, [connectedPoints, selectedPoint, networkMaxAge, setMapLines]);

  const buttonGroups = useMemo(() => {
    const gatewayHeader = hasNetworkFeature && [
      <BasicTooltip key="network-header" text={t('screens.equipment.equipment-map.network-connections')}>
        <Icon
          iconSize="1.3em"
          icon="graph"
          title="Network"
          style={{ height: '1.4em', color: '#6c767d', marginRight: 4 }}
        />
      </BasicTooltip>,
    ];
    const networkText = 'screens.equipment.equipment-map.network-over-the-last-n-days';
    const daysWithCount = (day) => t('dayWithCount', {count: day});
    const gatewayText = (day) => `${t(networkText)} ${daysWithCount(day)}`;
    const gatewayButtons = hasNetworkFeature && [
      <BasicTooltip key="network-30-days" text={gatewayText(30)}>
        <Button
          onClick={() => setNetworkMaxAge(thirtyDays)}
          active={networkMaxAge === thirtyDays}
          variant="outline-secondary"
        >
          {t('dayWithCount', {count: 30})}
        </Button>
      </BasicTooltip>,
      <BasicTooltip key="network-7-days" text={gatewayText(7)}>
        <Button
          onClick={() => setNetworkMaxAge(sevenDays)}
          active={networkMaxAge === sevenDays}
          variant="outline-secondary"
        >
          {t('dayWithCount', {count: 7})}
        </Button>
      </BasicTooltip>,
      <BasicTooltip key="network-1-day" text={gatewayText(1)}>
        <Button
          onClick={() => setNetworkMaxAge(oneDay)}
          active={networkMaxAge === oneDay}
          variant="outline-secondary"
        >
          {t('dayWithCount', {count: 1})}
        </Button>
      </BasicTooltip>,
      <BasicTooltip key="network-none" text={t('screens.equipment.equipment-map.display-no-network-connections')}>
        <Button
          onClick={() => setNetworkMaxAge(zeroDays)}
          active={networkMaxAge === zeroDays}
          variant="outline-secondary"
        >
          {t('None')}
        </Button>
      </BasicTooltip>,
      <BasicTooltip
        key="include-sub-group"
        text={`${!includeSubGroup ? t('common.show'): t('common.hide')} ${t('screens.equipment.equipment-list.subgroup-equipment')}`}>
        <Button
          onClick={() => setIncludeSubGroup(!includeSubGroup)}
          active={includeSubGroup}
          variant="outline-secondary"
        >
          <Image
            className="equipment_running_status"
            src={FolderTree}
          />
        </Button>
      </BasicTooltip>
    ];
    if (!userIsSuperAdmin) {
      return [gatewayHeader, gatewayButtons];
    }
    const conditionButtons = [
      <Button
        key="condition-danger"
        onClick={() => setConditionFilterValue('danger')}
        active={conditionFilterValue === 'danger'}
        variant={conditionFilterValue === 'danger' ? 'danger' : 'outline-secondary'}
        className={conditionFilterValue === 'danger' ? 'text-white' : 'text-danger'}
      >
        <IoIosAlert size="1.4em"/>
      </Button>,
      <Button
        key="condition-warning"
        onClick={() => setConditionFilterValue('warning')}
        active={conditionFilterValue === 'warning'}
        variant={conditionFilterValue === 'warning' ? 'warning' : 'outline-secondary'}
        className={conditionFilterValue === 'warning' ? 'text-white' : 'text-warning'}
      >
        <IoIosWarning size="1.4em"/>
      </Button>,
      <Button
        key="condition-all"
        onClick={() => setConditionFilterValue('all')}
        active={conditionFilterValue === 'all'}
        variant={conditionFilterValue === 'all' ? 'success' : 'outline-secondary'}
      >
        All
      </Button>,
    ];
    const runningButtons = [
      <Button
        key="running-running"
        onClick={() => setRunningFilterValue('running')}
        active={runningFilterValue === 'running'}
        variant="outline-secondary"
      >
        <RunningStatus value={true} />
      </Button>,
      <Button
        key="running-not-running"
        onClick={() => setRunningFilterValue('not-running')}
        active={runningFilterValue === 'not-running'}
        variant="outline-secondary"
      >
        <RunningStatus value={false} />
      </Button>,
      <Button
        key="running-n/a"
        onClick={() => setRunningFilterValue('n/a')}
        active={runningFilterValue === 'n/a'}
        variant="outline-secondary"
      >
        N/A
      </Button>,
      <Button
        key="running-all"
        onClick={() => setRunningFilterValue('all')}
        active={runningFilterValue === 'all'}
        variant="outline-secondary"
      >
        All
      </Button>,
    ];

    return [gatewayHeader, gatewayButtons, conditionButtons, runningButtons];
  }, [hasNetworkFeature, networkMaxAge, userIsSuperAdmin, conditionFilterValue, runningFilterValue, includeSubGroup]);

  // combine points to show on map
  const visiblePoints = useMemo(() => {
    return [
      ...filteredDevicePoints,
      ...gatewayPoints,
    ];
  }, [filteredDevicePoints, gatewayPoints, selectedPoint, mapLines]);

  return (
    <div className="d-flex flex-column flex-grow-1">
      <div className="flex-grow-0">
        <Container fluid>
          <Toolbar
            searchable
            renderSearchBar={SearchBar}
            title={t('screens.equipment.equipment-map.header')}
            loading={loading}
            lastFetch={lastFetch}
            error={error}
            buttonGroups={buttonGroups}
            // wrap button groups inside a private Super Admin container
            ButtonGroupContainer={useCallback(props => {
              // wrap the buttons that don't start with "gateways"
              // with SuperAdmin only warnings
              if (
                props &&
                props.children &&
                props.children.props &&
                props.children.props.children &&
                props.children.props.children.props &&
                props.children.props.children.props.children &&
                props.children.props.children.props.children[0] &&
                props.children.props.children.props.children[0].key &&
                !props.children.props.children.props.children[0].key.startsWith('network-')
              ) {
                return (
                  <Private minUserType="Super Admin" {...props} />
                );
              }
              else {
                return props.children;
              }
            }, [])}
            tableProps={tableProps}
          />
        </Container>
      </div>
      <div className="d-flex flex-column flex-grow-1 position-relative">
        <Map
          containerClassName="d-flex flex-grow-1"
          // provides context for the map bounds
          // but hide if we are still loading and there are no points yet
          boundingPoints={loading && !devicePoints.length ? undefined: devicePoints}
          // add points to be shown
          points={loading && !visiblePoints.length ? undefined: visiblePoints}
          totalPointCount={maxDeviceCount}
          updateBoundsKey={activeSubGroupId}
          onPointSelected={onPointSelected}
          onPointUnselected={onPointUnselected}
          lines={mapLines}
          linesProps={mapLinesProps}
          renderPopup={useCallback(({ point, cluster }) => {
            const PopUp = isGatewayPoint(point) ? GatewayPopup : DevicePopUp;
            return point ? (
              <PopUp
                point={point}
                cluster={cluster}
                showConnections={hasNetworkFeature && !!networkMaxAge}
                connectedPoints={connectedPoints}
                setSelectedPoint={setSelectedPoint}
              />
            ) : null;
          }, [connectedPoints, hasNetworkFeature, networkMaxAge])}
          renderMarkerClusterBorder={MarkerClusterBorder}
          getMarkerProps={getMarkerProps}
          selectedPoint={selectedPoint}
          selectedPointMatches={matchDeviceOrGatewayPoints}
          // popup card height is about 280px high, so adjust animation offset
          easeToOptions={useMemo(() => ({ offset: [0, 140] }), [])}
          // if no devices are found then sit this message over the map
          NoDataIndication={loading ? Loading : ConnectedNoDataIndication}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const fmListState = getDeviceListState(state);
  const gwListState = getGatewayListState(state);
  const maxDevices = getDevices(state, { forOrg: true });
  const devices = getDevices(state);
  const gateways = getGateways(state);
  const devicesNotReady = fmListState.loading && !(devices && devices.length);
  const hasNetworkFeature = getCurrentOrganisationHasProductCode(state, 'network_centre');
  return {
    activeSubGroupId: getActiveSubGroupId(state),
    // loading is set if either list is undefined or fetching
    loading: !maxDevices || !devices || !gateways || fmListState.loading || gwListState.loading,
    lastFetch: fmListState.lastFetch || gwListState.lastFetch,
    error: fmListState.error || gwListState.error,
    devices,
    // delay showing of gateways until devices are ready to be shown
    gateways: hasNetworkFeature && !devicesNotReady ? gateways : undefined,
    connections: hasNetworkFeature && !devicesNotReady ? getConnections(state) : undefined,
    maxDeviceCount: maxDevices && maxDevices.length,
    userIsSuperAdmin: isSuperAdmin(state),
    hasNetworkFeature,
  };
};
const mapDispatchToProps = { fetchDevices, fetchDeviceImages, fetchGateways };

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentMap);
