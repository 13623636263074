
export const SELECT_ACTIVE_GROUP = 'organisations/SELECT_ACTIVE_GROUP';

export const  REQUEST_BEGIN = 'organisations/REQUEST_BEGIN';
export const  REQUEST_FAIL = 'organisations/REQUEST_FAIL';

export const  RECEIVE_ORGANISATION_LIST = 'organisations/RECEIVE_ORGANISATION_LIST';

export const REQUEST_GROUP_BEGIN = 'organisations/REQUEST_GROUP_BEGIN';
export const REQUEST_GROUP_FAIL = 'organisations/REQUEST_GROUP_FAIL';
export const REQUEST_ORGANISATION_BEGIN = 'organisations/REQUEST_ORGANISATION_BEGIN';
export const REQUEST_ORGANISATION_FAIL = 'organisations/REQUEST_ORGANISATION_FAIL';

export const  REQUEST_ORGANISATION_GROUPS = 'organisations/REQUEST_ORGANISATION_GROUPS';
export const  RECEIVE_ORGANISATION_GROUPS = 'organisations/RECEIVE_ORGANISATION_GROUPS';
export const ORGANISATION_GROUPS_FAILURE = 'organisations/ORGANISATION_GROUPS_FAILURE';

export const REQUEST_GROUP = 'organisations/REQUEST_GROUP';
export const RECEIVE_GROUP = 'organisations/RECEIVE_GROUP';
export const GROUP_FAILURE = 'organisations/GROUP_FAILURE';

export const REQUEST_CREATE_GROUP = 'organisations/REQUEST_CREATE_GROUP';
export const RECEIVE_CREATE_GROUP = 'organisations/RECEIVE_CREATE_GROUP';
export const CREATE_GROUP_FAILURE = 'organisations/CREATE_GROUP_FAILURE';

export const REQUEST_UPDATE_GROUP = 'organisations/REQUEST_UPDATE_GROUP';
export const RECEIVE_UPDATE_GROUP = 'organisations/RECEIVE_UPDATE_GROUP';
export const UPDATE_GROUP_FAILURE = 'organisations/UPDATE_GROUP_FAILURE';

export const REQUEST_DELETE_GROUP = 'organisations/REQUEST_DELETE_GROUP';
export const RECEIVE_DELETE_GROUP = 'organisations/RECEIVE_DELETE_GROUP';
export const DELETE_GROUP_FAILURE = 'organisations/DELETE_GROUP_FAILURE';

export const REQUEST_GROUP_MEMBERS = 'organisations/REQUEST_GROUP_MEMBERS';
export const RECEIVE_GROUP_MEMBERS = 'organisations/RECEIVE_GROUP_MEMBERS';
export const GROUP_MEMBERS_FAILURE = 'organisations/GROUP_MEMBERS_FAILURE';

export const REQUEST_GROUP_MEMBERS_AVAILABLE = 'organisations/REQUEST_GROUP_MEMBERS_AVAILABLE';
export const RECEIVE_GROUP_MEMBERS_AVAILABLE = 'organisations/RECEIVE_GROUP_MEMBERS_AVAILABLE';
export const GROUP_MEMBERS_AVAILABLE_FAILURE = 'organisations/GROUP_MEMBERS_AVAILABLE_FAILURE';

export const REQUEST_ADD_GROUP_USERS = 'organisations/REQUEST_ADD_GROUP_USERS';
export const RECEIVE_ADD_GROUP_USERS = 'organisations/RECEIVE_ADD_GROUP_USERS';
export const ADD_GROUP_USERS_FAILURE = 'organisations/ADD_GROUP_USERS_FAILURE';

export const REQUEST_DELETE_GROUP_USERS = 'organisations/REQUEST_DELETE_GROUP_USERS';
export const RECEIVE_DELETE_GROUP_USERS = 'organisations/RECEIVE_DELETE_GROUP_USERS';
export const DELETE_GROUP_USERS_FAILURE = 'organisations/DELETE_GROUP_USERS_FAILURE';

export const REQUEST_GROUP_ACCESS = 'organisations/REQUEST_GROUP_ACCESS';
export const RECEIVE_GROUP_ACCESS = 'organisations/RECEIVE_GROUP_ACCESS';
export const GROUP_ACCESS_FAILURE = 'organisations/GROUP_ACCESS_FAILURE';

export const REQUEST_ADD_GROUP_ACCESS = 'organisations/REQUEST_ADD_GROUP_ACCESS';
export const RECEIVE_ADD_GROUP_ACCESS = 'organisations/RECEIVE_ADD_GROUP_ACCESS';
export const ADD_GROUP_ACCESS_FAILURE = 'organisations/ADD_GROUP_ACCESS_FAILURE';

export const REQUEST_DELETE_GROUP_ACCESS = 'organisations/REQUEST_DELETE_GROUP_ACCESS';
export const RECEIVE_DELETE_GROUP_ACCESS = 'organisations/RECEIVE_DELETE_GROUP_ACCESS';
export const DELETE_GROUP_ACCESS_FAILURE = 'organisations/DELETE_GROUP_ACCESS_FAILURE';

export const REQUEST_ADD_GROUP_DEVICES = 'organisations/REQUEST_ADD_GROUP_DEVICES';
export const RECEIVE_ADD_GROUP_DEVICES = 'organisations/RECEIVE_ADD_GROUP_DEVICES';
export const ADD_GROUP_DEVICES_FAILURE = 'organisations/ADD_GROUP_DEVICES_FAILURE';

export const REQUEST_DELETE_GROUP_DEVICES = 'organisations/REQUEST_DELETE_GROUP_DEVICES';
export const RECEIVE_DELETE_GROUP_DEVICES = 'organisations/RECEIVE_DELETE_GROUP_DEVICES';
export const DELETE_GROUP_DEVICES_FAILURE = 'organisations/DELETE_GROUP_DEVICES_FAILURE';

export const REQUEST_GROUP_RUNTIME = 'organisations/REQUEST_GROUP_RUNTIME';
export const RECEIVE_GROUP_RUNTIME = 'organisations/RECEIVE_GROUP_RUNTIME';
export const GROUP_RUNTIME_FAILURE = 'organisations/GROUP_RUNTIME_FAILURE';

export const REQUEST_ORGANISATION_RUNTIME = 'organisations/REQUEST_ORGANISATION_RUNTIME';
export const RECEIVE_ORGANISATION_RUNTIME = 'organisations/RECEIVE_ORGANISATION_RUNTIME';
export const ORGANISATION_RUNTIME_FAILURE = 'organisations/ORGANISATION_RUNTIME_FAILURE';

export const REQUEST_ORGANISATION_TAGS = 'organisations/REQUEST_ORGANISATION_TAGS';
export const RECEIVE_ORGANISATION_TAGS = 'organisations/RECEIVE_ORGANISATION_TAGS';
export const ORGANISATION_TAGS_FAILURE = 'organisations/ORGANISATION_TAGS_FAILURE';

export const REQUEST_ORGANISATION_TOKENS = 'organisations/REQUEST_ORGANISATION_TOKENS';
export const RECEIVE_ORGANISATION_TOKENS = 'organisations/RECEIVE_ORGANISATION_TOKENS';
export const ORGANISATION_TOKENS_FAILURE = 'organisations/ORGANISATION_TOKENS_FAILURE';

export const REQUEST_ORGANISATION_TOKENS_CREATE = 'organisations/REQUEST_ORGANISATION_TOKENS_CREATE';
export const RECEIVE_ORGANISATION_TOKENS_CREATE = 'organisations/RECEIVE_ORGANISATION_TOKENS_CREATE';
export const ORGANISATION_TOKENS_CREATE_FAILURE = 'organisations/ORGANISATION_TOKENS_CREATE_FAILURE';

export const REQUEST_ORGANISATION_TOKENS_DELETE = 'organisations/REQUEST_ORGANISATION_TOKENS_DELETE';
export const RECEIVE_ORGANISATION_TOKENS_DELETE = 'organisations/RECEIVE_ORGANISATION_TOKENS_DELETE';
export const ORGANISATION_TOKENS_DELETE_FAILURE = 'organisations/ORGANISATION_TOKENS_DELETE_FAILURE';

export const REQUEST_ORGANISATION_TOKENS_EDIT = 'organisations/REQUEST_ORGANISATION_TOKENS_EDIT';
export const RECEIVE_ORGANISATION_TOKENS_EDIT = 'organisations/RECEIVE_ORGANISATION_TOKENS_EDIT';
export const ORGANISATION_TOKENS_EDIT_FAILURE = 'organisations/ORGANISATION_TOKENS_EDIT_FAILURE';

export const REQUEST_STREAMING_CONFIGURATIONS = 'organisations/REQUEST_STREAMING_CONFIGURATIONS';
export const RECEIVE_STREAMING_CONFIGURATIONS = 'organisations/RECEIVE_STREAMING_CONFIGURATIONS';
export const STREAMING_CONFIGURATIONS_FAILURE = 'organisations/STREAMING_CONFIGURATIONS_FAILURE';

export const REQUEST_STREAMING_CONFIGURATIONS_EDIT = 'organisations/REQUEST_STREAMING_CONFIGURATIONS_EDIT';
export const RECEIVE_STREAMING_CONFIGURATIONS_EDIT = 'organisations/RECEIVE_STREAMING_CONFIGURATIONS_EDIT';
export const STREAMING_CONFIGURATIONS_EDIT_FAILURE = 'organisations/STREAMING_CONFIGURATIONS_EDIT_FAILURE';

export const REQUEST_STREAMING_CONFIGURATIONS_TEST = 'organisations/REQUEST_STREAMING_CONFIGURATIONS_TEST';
export const RECEIVE_STREAMING_CONFIGURATIONS_TEST = 'organisations/RECEIVE_STREAMING_CONFIGURATIONS_TEST';
export const STREAMING_CONFIGURATIONS_TEST_FAILURE = 'organisations/STREAMING_CONFIGURATIONS_TEST_FAILURE';

export const REQUEST_STREAMING_CONFIGURATIONS_DELETE = 'organisations/REQUEST_STREAMING_CONFIGURATIONS_DELETE';
export const RECEIVE_STREAMING_CONFIGURATIONS_DELETE = 'organisations/RECEIVE_STREAMING_CONFIGURATIONS_DELETE';
export const STREAMING_CONFIGURATIONS_DELETE_FAILURE = 'organisations/STREAMING_CONFIGURATIONS_DELETE_FAILURE';

export const REQUEST_ORGANISATION_INFO = 'organisations/REQUEST_ORGANISATION_INFO';
export const RECEIVE_ORGANISATION_INFO = 'organisations/RECEIVE_ORGANISATION_INFO';
export const ORGANISATION_INFO_FAILURE = 'organisations/ORGANISATION_INFO_FAILURE';

export const REQUEST_CREATE_ORGANISATION = 'organisations/REQUEST_CREATE_ORGANISATION';
export const RECEIVE_CREATE_ORGANISATION = 'organisations/RECEIVE_CREATE_ORGANISATION';
export const CREATE_ORGANISATION_FAILURE = 'organisations/CREATE_ORGANISATION_FAILURE';

export const REQUEST_CREATE_CHILD_ORGANISATION = 'organisations/REQUEST_CREATE_CHILD_ORGANISATION';
export const RECEIVE_CREATE_CHILD_ORGANISATION = 'organisations/RECEIVE_CREATE_CHILD_ORGANISATION';
export const CREATE_CHILD_ORGANISATION_FAILURE = 'organisations/CREATE_CHILD_ORGANISATION_FAILURE';

export const REQUEST_UPDATE_ORGANISATION = 'organisations/REQUEST_UPDATE_ORGANISATION';
export const RECEIVE_UPDATE_ORGANISATION = 'organisations/RECEIVE_UPDATE_ORGANISATION';
export const UPDATE_ORGANISATION_FAILURE = 'organisations/UPDATE_ORGANISATION_FAILURE';

export const REQUEST_ARCHIVE_ORGANISATION = 'organisations/REQUEST_ARCHIVE_ORGANISATION';
export const RECEIVE_ARCHIVE_ORGANISATION = 'organisations/RECEIVE_ARCHIVE_ORGANISATION';
export const ARCHIVE_ORGANISATION_FAILURE = 'organisations/ARCHIVE_ORGANISATION_FAILURE';

export const REQUEST_ORGANISATION_LOGO = 'organisations/REQUEST_ORGANISATION_LOGO';
export const RECEIVE_ORGANISATION_LOGO = 'organisations/RECEIVE_ORGANISATION_LOGO';
export const ORGANISATION_LOGO_FAILURE = 'organisations/ORGANISATION_LOGO_FAILURE';

export const REQUEST_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL = 'organisations/REQUEST_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL';
export const RECEIVE_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL = 'organisations/RECEIVE_ORGANISATION_LOGO_UPLOAD_S3_FILE_URL';
export const ORGANISATION_LOGO_UPLOAD_S3_FILE_URL_FAILURE = 'organisations/ORGANISATION_LOGO_UPLOAD_S3_FILE_URL_FAILURE';

export const REQUEST_GROUP_IMPACT_CONFIG = 'organisations/REQUEST_GROUP_IMPACT_CONFIG';
export const GROUP_IMPACT_CONFIG_FAILURE = 'organisations/GROUP_IMPACT_CONFIG_FAILURE';
export const RECEIVE_GROUP_IMPACT_CONFIG = 'organisations/RECEIVE_GROUP_IMPACT_CONFIG';
export const RECEIVE_ADD_GROUP_IMPACT_CONFIG = 'organisations/RECEIVE_ADD_GROUP_IMPACT_CONFIG';
export const RECEIVE_UPDATE_GROUP_IMPACT_CONFIG = 'organisations/RECEIVE_UPDATE_GROUP_IMPACT_CONFIG';
export const RECEIVE_DELETE_GROUP_IMPACT_CONFIG = 'organisations/RECEIVE_DELETE_GROUP_IMPACT_CONFIG';

export const REQUEST_GROUP_IMPACT_SUMMARY = 'organisation/REQUEST_GROUP_IMPACT_SUMMARY';
export const GROUP_IMPACT_SUMMARY_FAILURE = 'organisation/GROUP_IMPACT_SUMMARY_FAILURE';
export const RECEIVE_GROUP_IMPACT_SUMMARY = 'organisation/RECEIVE_GROUP_IMPACT_SUMMARY';

export const RECEIVE_GROUP_LOCALE = 'organisation/RECEIVE_GROUP_LOCALE';
export const GROUP_LOCALE_FAILURE = 'organisation/GROUP_LOCALE_FAILURE';
export const RECEIVE_ORGANISATION_LOCALE = 'organisation/RECEIVE_ORGANISATION_LOCALE';
export const ORGANISATION_LOCALE_FAILURE = 'organisation/ORGANISATION_LOCALE_FAILURE';

export const REQUEST_GROUP_TAGS = 'organisations/REQUEST_GROUP_TAGS';
export const RECEIVE_GROUP_TAGS = 'organisations/RECEIVE_GROUP_TAGS';
export const GROUP_TAGS_FAILURE = 'organisations/GROUP_TAGS_FAILURE';

export const REQUEST_GROUP_REPORT_DATA = 'organisation/REQUEST_GROUP_REPORT_DATA';
export const RECEIVE_GROUP_REPORT_DATA = 'organisation/RECEIVE_GROUP_REPORT_DATA';
export const GROUP_REPORT_DATA_FAILURE = 'organisation/GROUOP_REPORT_DATA_FAILURE';

export const REQUEST_WEBHOOKS = 'organisations/REQUEST_WEBHOOKS';
export const RECEIVE_WEBHOOKS = 'organisations/RECEIVE_WEBHOOKS';
export const WEBHOOKS_FAILURE = 'organisations/WEBHOOKS_FAILURE';
export const RECEIVE_UPDATE_WEBHOOKS = 'organisations/RECEIVE_UPDATE_WEBHOOKS';