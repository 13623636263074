
import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Nav, Button, ButtonGroup, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { IoIosArrowBack, IoIosArrowForward, IoIosChatboxes } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { Icon } from '@blueprintjs/core';

import withNavigationDeviceProps from './withNavigationDeviceProps';

// Other
import log from '../../../lib/log';
import { addToast } from '../../../components/Toaster';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { isFitMachine, isFitPower } from '../utils';

import EquipmentDropdown from './EquipmentDropdown';
import {
  fetchDeviceInteractiveFFT,
  fetchDeviceWaterfallPlot,
  fetchDeviceTimeSeriesChart,
} from '../actions';
import {
  getDevices,
  getDevice,
  getDeviceHasProductCode,
  getDeviceHasTimeseriesData,
} from '../selectors';

function EquipmentNav({
  deviceId,
  device={},
  devices=[],
  deviceHasTimeseriesData,
  hasHDFFT,
  hasWaterfallPlot,
  hasTSChart,
  hasFitPower,
  fetchDeviceInteractiveFFT,
  fetchDeviceWaterfallPlot,
  fetchDeviceTimeSeriesChart,
  location,
}) {

  const { t } = useTranslation();
  // compute links when currentDeviceId changes
  const links = useMemo(() => {
    const length = devices.length;
    const index = devices.findIndex(x => x.id === device.id);
    if (length > 1 && index >= 0) {
      const prevLink = location.pathname.replace(deviceId, devices[(length + index - 1) % length].id);
      const nextLink = location.pathname.replace(deviceId, devices[(length + index + 1) % length].id);
      return {
        // normalise to length (+ 1 length to ensure no negative numbers)
        prevLink,
        nextLink,
      };
    }
    else {
      return null;
    }
  }, [device.id, devices]);

  // handle InteractiveFFT interactivity
  const [loadingInteractiveFFT, setLoadingInteractiveFFT] = useState(false);
  const popupFFT = useCallback(() => {
    setLoadingInteractiveFFT(true);
    fetchDeviceInteractiveFFT({ id: deviceId })
      .then(result => {
        if (result && result.data && result.data.url) {
          // note that this is considered bad practice and may be blocked by some browsers
          // especially if users have anti-popup settings in their browsers
          window.open(result.data.url, '_blank');
        }
        else {
          const e = new Error('Unexpected Interactive FFT response');
          e.data = result && result.data;
          throw e;
        }
      })
      .catch(e => {
        if (deviceHasTimeseriesData === false) {
          addToast({
            variant: 'warning',
            header: `FFTs are not available from ${device.fitmachine_type} devices at the moment`,
          });
        }
        else {
          addToast({
            variant: 'danger',
            header: 'This FFT is not available at the moment',
          });
        }
        // log error
        log.error(new Error('Interactive FFT error'), e, {
          // add device ids
          device,
          // specifically pass through more device context (but not all)
          'error-context': {
            device: Object.entries(device).reduce((acc, [key, value]) => {
              // don't pass through moment object or relations,
              // which are a relatively huge amount of data
              if (typeof value !== 'object') {
                acc[key] = value;
              }
              return acc;
            }, {}),
            // pass request data
            data: e && e.data,
          },
        });
      })
      .then(() => setLoadingInteractiveFFT(false));
  }, [deviceId, device]);

  // handle Waterfall Plot interactivity
  const [loadingWaterfall, setLoadingWaterfall] = useState(false);
  const popupWaterfall = useCallback(() => {
    setLoadingWaterfall(true);
    fetchDeviceWaterfallPlot({ id: deviceId })
      .then(result => {
        if (result && result.data && result.data.url) {
          // note that this is considered bad practice and may be blocked by some browsers
          // especially if users have anti-popup settings in their browsers
          window.open(result.data.url, '_blank');
        }
        else {
          const e = new Error('Unexpected Waterfall Plot response');
          e.data = result && result.data;
          throw e;
        }
      })
      .catch(e => {
        if (deviceHasTimeseriesData === false) {
          addToast({
            variant: 'warning',
            header: `Waterfall plots are not available from ${device.fitmachine_type} devices at the moment`,
          });
        }
        else {
          addToast({
            variant: 'danger',
            header: 'This waterfall plot is not available at the moment',
          });
        }
        // log error
        log.error(new Error('Waterfall Plot error'), e, {
          // add device ids
          device,
          // specifically pass through more device context (but not all)
          'error-context': {
            device: Object.entries(device).reduce((acc, [key, value]) => {
              // don't pass through moment object or relations,
              // which are a relatively huge amount of data
              if (typeof value !== 'object') {
                acc[key] = value;
              }
              return acc;
            }, {}),
            // pass request data
            data: e && e.data,
          },
        });
      })
      .then(() => setLoadingWaterfall(false));
  }, [deviceId, device]);

  // handle Waveform aka. Time Series chart
  const [loadingTSChart, setLoadingTSChart] = useState(false);
  const popupTSChart = useCallback(() => {
    setLoadingTSChart(true);
    fetchDeviceTimeSeriesChart({ id: deviceId })
      .then(result => {
        if (result && result.data && result.data.url) {
          window.open(result.data.url, '_blank');
        }
        else {
          const e = new Error('Unexpected Waterfall Plot response');
          e.data = result && result.data;
          throw e;
        }
      })
      .catch(e => {
        if (deviceHasTimeseriesData === false) {
          addToast({
            variant: 'warning',
            header: `Waterfall plots are not available from ${device.fitmachine_type} devices at the moment`,
          });
        }
        else {
          addToast({
            variant: 'danger',
            header: 'This Waveform chart is not available at the moment',
          });
        }
        // log error
        log.error(new Error('Waveform chart error'), e, {
          // add device ids
          device,
          // specifically pass through more device context (but not all)
          'error-context': {
            device: Object.entries(device).reduce((acc, [key, value]) => {
              // don't pass through moment object or relations,
              // which are a relatively huge amount of data
              if (typeof value !== 'object') {
                acc[key] = value;
              }
              return acc;
            }, {}),
            // pass request data
            data: e && e.data,
          },
        });
      })
      .then(() => setLoadingTSChart(false));
  }, [deviceId, device]);
  const [showDiagnostics, setShowDiagnostics] = useState(false);
  const isFMSensor = isFitMachine(device);
  const isFPSensor = isFitPower(device);

  return (
    <Nav as="ul" className="flex-grow-1">
      <Row className="small-gutters flex-grow-1">
        <Col xs="auto">
          {device.serial && !device.archived && (
            <Row className="small-gutters">
              <Col xs="auto" className="mb-1">
                <LinkContainer to={`/equipment/${device.id}/activity`} exact>
                  <Button size="md" variant="outline-secondary">
                    <IoIosChatboxes size="1.2em" /> <span>{t('components.equipment-nav.activity')}</span>
                  </Button>
                </LinkContainer>
              </Col>
              {/** Show fitmachine charts link if:
               * 1. device is monitored by fitmachine;
               * 2. device is monitored by fitpower as well as fitmachine.
               */}
              {(isFMSensor || device.related_ids?.fitmachine?.length > 0) && <Col xs="auto" className="mb-1">
                <LinkContainer to={`/equipment/${isFMSensor ? device.id : device.related_ids.fitmachine[0]}`} exact>
                  <Button size="md" variant="outline-secondary">
                    <Icon
                      iconSize="1.2em"
                      icon="timeline-line-chart"  // @TODO: Change icon for fitmachine
                      title="Charts"
                      style={styles.navBlueprintIcon}
                    /> <span>{t('components.equipment-nav.fitmachine-charts')}</span>
                  </Button>
                </LinkContainer>
              </Col>}
              {/** Show fitpower charts link if:
               * 1. device is monitored by fitpower;
               * 2. device is monitored by fitmachine as well as fitpower.
               */}
              {(isFPSensor || device.related_ids?.fitpower?.length > 0) && <Col xs="auto" className="mb-1">
                <LinkContainer to={`/equipment/${isFPSensor ? device.id : device.related_ids.fitpower[0]}/fitpower`} exact>
                  <Button size="md" variant="outline-secondary">
                    <Icon
                      iconSize="1.2em"
                      icon="timeline-line-chart"  // @TODO: Change icon for fitpower
                      title="Charts"
                      style={styles.navBlueprintIcon}
                    /> <span>{t('components.equipment-nav.fitpower-charts')}</span>
                  </Button>
                </LinkContainer>
              </Col>
              }
              {((hasHDFFT || hasWaterfallPlot || hasTSChart) && isFMSensor) && <Dropdown show={showDiagnostics} onToggle={() => setShowDiagnostics(!showDiagnostics)}>
                <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center">
                  <Icon
                    iconSize="1.2em"
                    icon="series-search"
                    style={styles.navBlueprintIcon}
                    title="Diagnostics"
                  />&nbsp;{t('components.equipment-nav.diagnostics')}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {hasHDFFT &&
                    <Dropdown.Item
                      disabled={loadingInteractiveFFT}
                      onClick={popupFFT}
                      className="d-flex align-items-center"
                    >
                      {loadingInteractiveFFT && <LoadingSpinner size={1.2} inline />}
                      <span>{t('components.equipment-nav.latest-fft')}</span>
                    </Dropdown.Item>}
                  {hasWaterfallPlot && (
                    <Dropdown.Item
                      disabled={loadingWaterfall}
                      onClick={popupWaterfall}
                      className="d-flex align-items-center"
                    >
                      {loadingWaterfall && <LoadingSpinner size={1.2} inline />}
                      <span>{t('components.equipment-nav.waterfall')}</span>
                    </Dropdown.Item>
                  )}
                  {hasTSChart && (
                    <Dropdown.Item
                      disabled={loadingTSChart}
                      onClick={popupTSChart}
                      className="d-flex align-items-center"
                    >
                      {loadingTSChart && <LoadingSpinner size={1.2} inline />}
                      <span>{t('components.equipment-nav.waveform')}</span>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>}
            </Row>
          )}
        </Col>
        {/*  when this column overflows, ensure is is right-aligned using ml-auto */}
        <Col xs="auto" className="ml-auto">
          <Row className="small-gutters">
            {links && (
              <Col xs="auto" className="mb-1 ml-auto">
                <ButtonGroup>
                  <LinkContainer to={links.prevLink}>
                    <Button variant="outline-secondary" size="md">
                      <IoIosArrowBack />
                    </Button>
                  </LinkContainer>
                  <LinkContainer to={links.nextLink}>
                    <Button variant="outline-secondary" size="md">
                      <IoIosArrowForward />
                    </Button>
                  </LinkContainer>
                </ButtonGroup>
              </Col>
            )}
            {!device.archived && (
              <Col xs="auto" className="mb-1 ml-auto">
                <EquipmentDropdown deviceId={device.id} />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Nav>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  const device = getDevice(state, deviceId);
  return {
    devices: getDevices(state, { sensorType: device?.sensor_type }),
    device,
    hasHDFFT: getDeviceHasProductCode(state, deviceId, 'hdfft'),
    hasWaterfallPlot: getDeviceHasProductCode(state, deviceId, 'waterfall'),
    hasTSChart: getDeviceHasProductCode(state, deviceId, 'tschart'),
    deviceHasTimeseriesData: getDeviceHasTimeseriesData(state, deviceId),
    hasFitPower: getDeviceHasProductCode(state, deviceId, 'fitpower'),
  };
};

const mapDispatchToProps = {
  fetchDeviceInteractiveFFT,
  fetchDeviceWaterfallPlot,
  fetchDeviceTimeSeriesChart,
};

export default withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(EquipmentNav)
);

const styles = {
  navBlueprintIcon: {
    width: '1.2em',
    verticalAlign: '-15%',
  },
};
