import { memo, useCallback } from 'react';
import { Link } from "react-router-dom";
import { IoIosClose } from 'react-icons/io';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useDeviceLinks from '../hooks/useDeviceLinks';
import { InfoCard, InfoCardItem } from '../../../components/InfoCard';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ConfirmModal from '../../../components/ConfirmModal';
import AddLinkModal from './AddLinkModal';
import { getPlaceholderImageForDevice } from '../utils';

function DeviceLinks({ device }) {
  const { t } = useTranslation();

  const { loading, links = [], updateLinks } = useDeviceLinks(device?.id, { fetch: true });

  const renderHeader = useCallback(() => {
    return (
      <span className="d-flex justify-content-between align-items-center">
        <span className="mr-1 d-flex">
          <span>{t('components.device-links.header')}</span>&nbsp;{loading && <LoadingSpinner size={1} />}
        </span>
        <AddLinkModal device={device} as="button" className="w-auto px-0" />
      </span>
    );
  }, [loading]);

  const handleDeleteLink = useCallback(async (serial) => {
    await updateLinks({
      links: [{
        serial, linked: false,
      }]
    });
  }, []);

  return (
    <InfoCard heading={renderHeader()} headerClassName="flex-grow-1">
      {links.map(link => {
        return (
          <InfoCardItem key={link.id}>
            <div className="d-flex align-items-center">
              <img
                src={getPlaceholderImageForDevice({ sensor_type: link.sensor_type })}
                alt="placeholder"
                className="mr-1"
                style={{height: '1.5em', marginLeft: '-10px'}}
              />
              <Link to={`/equipment/${link.id}/${link.sensor_type === 'fitpower' ? 'fitpower' : ''}`}>
                <div className="mb-1" style={{fontSize: '16px', lineHeight: 1}}>{link.title}</div>
                <div className="text-secondary" style={{ fontSize: '12px', lineHeight: 1 }}>{link.subtitle}</div>
              </Link>
              <ConfirmModal
                header={t('components.device-links.modal.header')}
                confirmText={t('components.device-links.modal.confirm-text')}
              >
                <Button
                  variant="light"
                  size="sm"
                  className="p-0"
                  onClick={() => { handleDeleteLink(link.serial); }}
                  disabled={loading}
                >
                  <IoIosClose size="1.8em" />
                </Button>
              </ConfirmModal>
            </div>
          </InfoCardItem>
        );
      })}
    </InfoCard>
  );
}

export default memo(DeviceLinks);
