import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWebhooks as updateWebhooksAction } from '../actions';
import { getOrganisationId } from '../selectors';

export default function useUpdateWebhooks() {
  const dispatch = useDispatch();
  const organisationId = useSelector(getOrganisationId);
  const [loading, setLoading] = useState(false);

  const updateWebhooks = useCallback(async (data) => {
    setLoading(true);
    if(data.data_format === 'none') data.data_format = null;
    await dispatch(updateWebhooksAction({id: organisationId}, data));
    setLoading(false);
  }, [organisationId]);

  return {
    loading,
    updateWebhooks,
  };
}