import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeviceSettings, updateDeviceSettings } from "../actions";
import { getDeviceSettings, getDeviceSettingsState } from "../selectors";

function useDeviceSettings(deviceId, { fetch = false } = {}) {
  const dispatch = useDispatch();

  const settings = useSelector(state => getDeviceSettings(state, deviceId));
  const { loading, error, lastFetch } = useSelector(state => getDeviceSettingsState(state, deviceId)) || {};

  const fetchSettings = useCallback(() => {
    dispatch(fetchDeviceSettings({id: deviceId}));
  }, [deviceId, dispatch]);

  const updateSettings = useCallback((data) => {
    dispatch(updateDeviceSettings({ id: deviceId }, data));
  }, [deviceId, dispatch]);

  useEffect(() => {
    if(fetch && deviceId) {
      fetchSettings();
    }
  }, [fetch, deviceId]);

  return { loading, error, lastFetch, settings, updateSettings };
}

export default useDeviceSettings;