export const REQUEST_BEGIN = 'devices/REQUEST_BEGIN';
export const REQUEST_FAIL = 'devices/REQUEST_FAIL';

export const RECEIVE_CLOUD_DEVICE_LIST = 'devices/RECEIVE_CLOUD_DEVICE_LIST';
export const RECEIVE_DEVICE_INFO = 'devices/RECEIVE_DEVICE_INFO';
export const RECEIVE_FIRMWARE_VERSION = 'devices/RECEIVE_FIRMWARE_VERSION';

export const REQUEST_DEVICE_INTERACTIVE_FFT = 'devices/REQUEST_DEVICE_INTERACTIVE_FFT';
export const RECEIVE_DEVICE_INTERACTIVE_FFT = 'devices/RECEIVE_DEVICE_INTERACTIVE_FFT';
export const DEVICE_INTERACTIVE_FFT_FAILURE = 'devices/DEVICE_INTERACTIVE_FFT_FAILURE';

export const REQUEST_DEVICE_WATERFALL_PLOT = 'devices/REQUEST_DEVICE_WATERFALL_PLOT';
export const RECEIVE_DEVICE_WATERFALL_PLOT = 'devices/RECEIVE_DEVICE_WATERFALL_PLOT';
export const DEVICE_WATERFALL_PLOT_FAILURE = 'devices/DEVICE_WATERFALL_PLOT_FAILURE';

export const REQUEST_DEVICE_TS_CHART = 'devices/REQUEST_DEVICE_TS_CHART';
export const RECEIVE_DEVICE_TS_CHART = 'devices/RECEIVE_DEVICE_TS_CHART';
export const DEVICE_TS_CHART_FAILURE = 'devices/DEVICE_TS_CHART_FAILURE';

export const REQUEST_DEVICE_EVENTS = 'devices/REQUEST_DEVICE_EVENTS';
export const RECEIVE_DEVICE_EVENTS = 'devices/RECEIVE_DEVICE_EVENTS';
export const DEVICE_EVENTS_FAILURE = 'devices/DEVICE_EVENTS_FAILURE';

export const REQUEST_DEVICE_TAGS = 'devices/REQUEST_DEVICE_TAGS';
export const RECEIVE_DEVICE_TAGS = 'devices/RECEIVE_DEVICE_TAGS';
export const DEVICE_TAGS_FAILURE = 'devices/DEVICE_TAGS_FAILURE';

export const REQUEST_DEVICE_ALARMS = 'devices/REQUEST_DEVICE_ALARMS';
export const RECEIVE_DEVICE_ALARMS = 'devices/RECEIVE_DEVICE_ALARMS';
export const DEVICE_ALARMS_FAILURE = 'devices/DEVICE_ALARMS_FAILURE';

export const REQUEST_DEVICE_ALARM_EVENTS = 'devices/REQUEST_DEVICE_ALARM_EVENTS';
export const RECEIVE_DEVICE_ALARM_EVENTS = 'devices/RECEIVE_DEVICE_ALARM_EVENTS';
export const DEVICE_ALARM_EVENTS_FAILURE = 'devices/DEVICE_ALARM_EVENTS_FAILURE';

export const REQUEST_DEVICE_IMAGES = 'devices/REQUEST_DEVICE_IMAGES';
export const RECEIVE_DEVICE_IMAGES = 'devices/RECEIVE_DEVICE_IMAGES';
export const DEVICE_IMAGES_FAILURE = 'devices/DEVICE_IMAGES_FAILURE';

export const REQUEST_DEVICE_IMAGE_DELETE = 'devices/REQUEST_DEVICE_IMAGE_DELETE';
export const RECEIVE_DEVICE_IMAGE_DELETE = 'devices/RECEIVE_DEVICE_IMAGE_DELETE';
export const DEVICE_IMAGE_DELETE_FAILURE = 'devices/DEVICE_IMAGE_DELETE_FAILURE';

export const REQUEST_DEVICE_IMAGE_UPLOAD_S3_FILE_URL = 'devices/REQUEST_DEVICE_IMAGE_UPLOAD_S3_FILE_URL';
export const RECEIVE_DEVICE_IMAGE_UPLOAD_S3_FILE_URL = 'devices/RECEIVE_DEVICE_IMAGE_UPLOAD_S3_FILE_URL';
export const DEVICE_IMAGE_UPLOAD_S3_FILE_URL_FAILURE = 'devices/DEVICE_IMAGE_UPLOAD_S3_FILE_URL_FAILURE';

export const REQUEST_DEVICE_ERRORS = 'devices/REQUEST_DEVICE_ERRORS';
export const RECEIVE_DEVICE_ERRORS = 'devices/RECEIVE_DEVICE_ERRORS';
export const DEVICE_ERRORS_FAILURE = 'devices/DEVICE_ERRORS_FAILURE';

export const REQUEST_DEVICE_SAMPLES_BEGIN = 'devices/REQUEST_DEVICE_SAMPLES_BEGIN';
export const REQUEST_DEVICE_SAMPLES_FAIL = 'devices/REQUEST_DEVICE_SAMPLES_FAIL';
export const RECEIVE_DEVICE_SAMPLES = 'devices/RECEIVE_DEVICE_SAMPLES';

export const REQUEST_DEVICE_OVERVIEW2 = 'devices/REQUEST_DEVICE_OVERVIEW2';
export const RECEIVE_DEVICE_OVERVIEW2 = 'devices/RECEIVE_DEVICE_OVERVIEW2';
export const DEVICE_OVERVIEW2_FAILURE = 'devices/DEVICE_OVERVIEW2_FAILURE';

export const REQUEST_DEVICE_OVERVIEW = 'devices/REQUEST_DEVICE_OVERVIEW';
export const RECEIVE_DEVICE_OVERVIEW = 'devices/RECEIVE_DEVICE_OVERVIEW';
export const DEVICE_OVERVIEW_FAILURE = 'devices/DEVICE_OVERVIEW_FAILURE';

export const REQUEST_DEVICE_RUNTIME = 'devices/REQUEST_DEVICE_RUNTIME';
export const RECEIVE_DEVICE_RUNTIME = 'devices/RECEIVE_DEVICE_RUNTIME';
export const DEVICE_RUNTIME_FAILURE = 'devices/DEVICE_RUNTIME_FAILURE';

export const REQUEST_DEVICE_NOTIFICATION_THRESHOLDS = 'devices/REQUEST_DEVICE_NOTIFICATION_THRESHOLDS';
export const RECEIVE_DEVICE_NOTIFICATION_THRESHOLDS = 'devices/RECEIVE_DEVICE_NOTIFICATION_THRESHOLDS';
export const DEVICE_NOTIFICATION_THRESHOLDS_FAILURE = 'devices/DEVICE_NOTIFICATION_THRESHOLDS_FAILURE';

export const REQUEST_DEVICE_NOTIFICATION_THRESHOLD_CREATE = 'devices/REQUEST_DEVICE_NOTIFICATION_THRESHOLD_CREATE';
export const RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_CREATE = 'devices/RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_CREATE';
export const DEVICE_NOTIFICATION_THRESHOLD_CREATE_FAILURE = 'devices/DEVICE_NOTIFICATION_THRESHOLD_CREATE_FAILURE';

export const REQUEST_DEVICE_NOTIFICATION_THRESHOLD_UPDATE = 'devices/REQUEST_DEVICE_NOTIFICATION_THRESHOLD_UPDATE';
export const RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_UPDATE = 'devices/RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_UPDATE';
export const DEVICE_NOTIFICATION_THRESHOLD_UPDATE_FAILURE = 'devices/DEVICE_NOTIFICATION_THRESHOLD_UPDATE_FAILURE';

export const REQUEST_DEVICE_NOTIFICATION_THRESHOLD_DELETE = 'devices/REQUEST_DEVICE_NOTIFICATION_THRESHOLD_DELETE';
export const RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_DELETE = 'devices/RECEIVE_DEVICE_NOTIFICATION_THRESHOLD_DELETE';
export const DEVICE_NOTIFICATION_THRESHOLD_DELETE_FAILURE = 'devices/DEVICE_NOTIFICATION_THRESHOLD_DELETE_FAILURE';

export const RECEIVE_DEVICE_RECALIBRATION = 'devices/RECEIVE_DEVICE_RECALIBRATION';

export const REQUEST_EQUIPMENT_UPDATE = 'equipments/REQUEST_EQUIPMENT_UPDATE';
export const RECEIVE_EQUIPMENT_UPDATE = 'equipments/RECEIVE_EQUIPMENT_UPDATE';
export const EQUIPMENT_UPDATE_FAIL = 'equipments/EQUIPMENT_UPDATE_FAIL';

export const REQUEST_DEVICE_MUTE_STATUS_UPDATE = 'devices/REQUEST_DEVICE_MUTE_STATUS_UPDATE';
export const RECEIVE_DEVICE_MUTE_STATUS_UPDATE = 'devices/RECEIVE_DEVICE_MUTE_STATUS_UPDATE';
export const DEVICE_MUTE_STATUS_UPDATE_FAIL = 'devices/DEVICE_MUTE_STATUS_UPDATE_FAIL';

// REQUEST_WIFI_PROFILES = 'devices/REQUEST_WIFI_PROFILES';
// RECEIVE_WIFI_PROFILES = 'devices/RECEIVE_WIFI_PROFILES';

// REQUEST_WIFI_CONNECTIVITY = 'devices/REQUEST_WIFI_CONNECTIVITY';
// RECEIVE_WIFI_CONNECTIVITY = 'devices/RECEIVE_WIFI_CONNECTIVITY';

export const UPDATE_DRAFT = 'devices/UPDATE_DRAFT';
export const DISCARD_DRAFT = 'devices/DISCARD_DRAFT';

export const CREATE_TEMPLATE = 'devices/CREATE_TEMPLATE';
export const DELETE_TEMPLATE = 'devices/DELETE_TEMPLATE';

export const UPDATE_NEARBY = 'devices/UPDATE_NEARBY';

export const REQUEST_ARCHIVE_DEVICE = 'devices/REQUEST_ARCHIVE_DEVICE';
export const RECEIVE_ARCHIVE_DEVICE = 'devices/RECEIVE_ARCHIVE_DEVICE';
export const ARCHIVE_DEVICE_FAILURE = 'devices/ARCHIVE_DEVICE_FAILURE';

export const REQUEST_UNARCHIVE_DEVICE = 'devices/REQUEST_UNARCHIVE_DEVICE';
export const RECEIVE_UNARCHIVE_DEVICE = 'devices/RECEIVE_UNARCHIVE_DEVICE';
export const UNARCHIVE_DEVICE_FAILURE = 'devices/UNARCHIVE_DEVICE_FAILURE';

export const REQUEST_DEVICE_WATCHERS = 'devices/REQUEST_DEVICE_WATCHERS';
export const RECEIVE_DEVICE_WATCHERS = 'devices/RECEIVE_DEVICE_WATCHERS';
export const DEVICE_WATCHERS_FAILURE = 'devices/DEVICE_WATCHERS_FAILURE';

export const REQUEST_DEVICE_STATUS = 'devices/REQUEST_DEVICE_STATUS';
export const RECEIVE_DEVICE_STATUS = 'devices/RECEIVE_DEVICE_STATUS';
export const DEVICE_STATUS_FAILURE = 'devices/DEVICE_STATUS_FAILURE';
export const SET_DEVICE_SELECTED_STATUS = 'devices/SET_DEVICE_SELECTED_STATUS';

export const REQUEST_DEVICE_CONDITION_CURVES = 'devices/REQUEST_DEVICE_CONDITION_CURVES';
export const RECEIVE_DEVICE_CONDITION_CURVES = 'devices/DEVICE_CONDITION_CURVES_SUCCESS';
export const DEVICE_CONDITION_CURVES_FAILURE = 'devices/DEVICE_CONDITION_CURVES_FAILURE';

export const RECEIVE_UPDATE_DEVICE_TAGS = 'devices/RECEIVE_UPDATE_DEVICE_TAGS';

export const REQUEST_DEVICE_ROLES = 'devices/REQUEST_DEVICE_ROLES';
export const RECEIVE_DEVICE_ROLES = 'devices/DEVICE_ROLES_SUCCESS';
export const DEVICE_ROLES_FAILURE = 'devices/DEVICE_ROLES_FAILURE';
export const REQUEST_DEVICE_IMPACT_CONFIG = 'devices/REQUEST_DEVICE_IMPACT_CONFIG';
export const DEVICE_IMPACT_CONFIG_FAILURE = 'devices/DEVICE_IMPACT_CONFIG_FAILURE';
export const RECEIVE_DEVICE_IMPACT_CONFIG = 'devices/RECEIVE_DEVICE_IMPACT_CONFIG';
export const RECEIVE_ADD_DEVICE_IMPACT_CONFIG = 'devices/RECEIVE_ADD_DEVICE_IMPACT_CONFIG';
export const RECEIVE_DELETE_DEVICE_IMPACT_CONFIG = 'devices/RECEIVE_DELETE_DEVICE_IMPACT_CONFIG';
export const RECEIVE_UPDATE_DEVICE_IMPACT_CONFIG = 'devices/RECEIVE_UPDATE_DEVICE_IMPACT_CONFIG';

export const REQUEST_DEVICE_IMPACT_SUMMARY = 'devices/REQUEST_DEVICE_IMPACT_SUMMARY';
export const RECEIVE_DEVICE_IMPACT_SUMMARY = 'devices/RECEIVE_DEVICE_IMPACT_SUMMARY';
export const DEVICE_IMPACT_SUMMARY_FAILURE = 'devices/DEVICE_IMPACT_SUMMARY_FAILURE';

export const RECEIVE_DEVICE_LOCALE = 'devices/RECEIVE_DEVICE_LOCALE';
export const DEVICE_LOCALE_FAILURE = 'devices/RECEIVE_DEVICE_FAILURE';

export const REQUEST_DEVICE_SETTINGS = 'devices/REQUEST_DEVICE_SETTINGS';
export const DEVICE_SETTINGS_FAILURE = 'devices/DEVICE_SETTINGS_FAILURE';
export const RECEIVE_DEVICE_SETTINGS = 'devices/RECEIVE_DEVICE_SETTINGS';
export const RECEIVE_UPDATE_DEVICE_SETTINGS = 'devices/RECEIVE_UPDATE_DEVICE_SETTINGS';

export const REQUEST_DEVICE_LINKS = 'devices/REQUEST_DEVICE_LINKS';
export const DEVICE_LINKS_FAILURE = 'devices/DEVICE_LINKS_FAILURE';
export const RECEIVE_DEVICE_LINKS = 'devices/RECEIVE_DEVICE_LINKS';
export const RECEIVE_UPDATE_DEVICE_LINKS = 'devices/RECEIVE_UPDATE_DEVICE_LINKS';