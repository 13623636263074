import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import PageLayout from '../components/PageLayout';
import Table from '../../../components/Table';
import NoDataIndication from '../components/NoDataIndication';
import { fetchTransactions } from '../actions';
import { getTransactionsState, getTransactions } from '../selectors';
import { formatNumber } from '../../../components/lib/utils';
import { AppStoreState } from '../../types';
import { StateProps as TransactionStateProps, Transaction } from '../types';
import { FetchTransactionsAction } from '../types/Action';
import { getActiveGroupId, getRootGroupId } from '../../organisation/selectors';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

interface StateProps {
  transactionsState: TransactionStateProps;
  transactions: Transaction[];
  activeGroupId?: number;
  rootGroupId?: number;
}
interface DispatchProps {
  fetchTransactions: (id: number) => (dispatch: Dispatch<FetchTransactionsAction>) => FetchTransactionsAction;
}

const mapStateToProps = (state: AppStoreState): StateProps => {
  return {
    transactionsState: getTransactionsState(state),
    transactions: getTransactions(state),
    activeGroupId: getActiveGroupId(state),
    rootGroupId: getRootGroupId(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchTransactions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

function Transactions({
  transactionsState,
  transactions,
  fetchTransactions,
  activeGroupId,
  rootGroupId,
}: Props) {

  const { t } = useTranslation();

  useEffect(() => {
    if (activeGroupId) {
      fetchTransactions(activeGroupId);
    } else if(rootGroupId) {
      fetchTransactions(rootGroupId);
    }
  }, [activeGroupId, rootGroupId]);

  return (
    <PageLayout
      title={t('screens.billing.transactions.title')}
      loading={transactionsState.loading}
      searchProps={{
        hide: true,
      }}
      tableProps={{
        dataSizeProps: {
          totalCount: transactions.length,
          filteredCount: transactions.length,
          itemName: 'row',
          itemsName: 'rows',
        }
      }}
    >
      <div>
        <Table
          keyField="id"
          pagination
          columns={useLocalisedColumns([
            {
              dataField: 'date',
              text: 'Date/Time',
              sort: true,
              formatter: (cell: string) => moment(cell).format('DD MMM YYYY')
            },
            { dataField: 'description', text: 'Description', sort: false },
            {
              dataField: 'monitored_hours',
              text: 'Monitored Hours',
              sort: false,
              formatter: (cell: number) => formatNumber(cell),
              align: 'right',
              headerAlign: 'right',
              classes: (cell: number) => {
                if (cell < 0) return 'color-movus-green';
                return '';
              }
            },
            {
              dataField: 'balance',
              text: 'Balance',
              sort: false,
              formatter: (cell: number) => formatNumber(cell),
              align: 'right',
              headerAlign: 'right',
              classes: (cell: number) => {
                if(cell < 0) return 'color-movus-green';
                return '';
              }
            },
          ])}
          data={transactions}
          noDataIndication={() => {
            return <NoDataIndication title={t('screens.billing.transactions.no-data')} />;
          }}
          loading={transactionsState.loading}
        />
      </div>
    </PageLayout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
