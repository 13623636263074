// Current consumption trend tile for Energy Dash.
import { useMemo, memo } from 'react';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import HomeCard from './HomeCard';
import { getChartLabelColor, roundNumber } from '../lib/utils';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';

function CurrentConsumptionTrend() {

  const items = [
    {date: "2023-01-01", equipment_cnt: 1693, current: 389364},
    {date: "2022-12-01", equipment_cnt: 1767, current: 661105},
    {date: "2022-11-01", equipment_cnt: 1719, current: 645408},
    {date: "2022-10-01", equipment_cnt: 1743, current: 651845},
    {date: "2022-09-01", equipment_cnt: 1732, current: 653666},
    {date: "2022-08-01", equipment_cnt: 1649, current: 638726},
    {date: "2022-07-01", equipment_cnt: 1516, current: 598374},
    {date: "2022-06-01", equipment_cnt: 1446, current: 550701},
    {date: "2022-05-01", equipment_cnt: 1388, current: 528998},
    {date: "2022-04-01", equipment_cnt: 1329, current: 489464},
    {date: "2022-03-01", equipment_cnt: 1296, current: 482625},
    {date: "2022-02-01", equipment_cnt: 1182, current: 435653}
  ];
  const itemsSortedByDate = items.sort((a, b) => a.date < b.date ? -1 : 1);
  const currentConsumed = useMemo(() => itemsSortedByDate.map(i => i.current), [items]);
  const equipmentCount = useMemo(() => itemsSortedByDate.map(i => i.equipment_cnt), [items]);
  const maxCurrentConsumed = roundNumber(Math.max(...currentConsumed));
  const maxEquipmentCount = roundNumber(Math.max(...equipmentCount));
  const { filterAll } = useDeviceStatusFilter();

  const chartOptions = {
    color: ['green', 'orange'].map(color => getChartLabelColor(color)),
    legend: {
      data: ['Current Consumed', 'Devices'],
      bottom: 0
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: [{
      type: 'category',
      data: itemsSortedByDate.map(i => moment(i.date).format('MMM-YY'))
    }],
    yAxis: [{
      type: 'value',
      name: 'Total Current Consumed',
      nameLocation: 'middle',
      nameTextStyle: {
        padding: [0, 0, 50, 0],
      },
      position: 'left',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333'
        }
      },
      min: 0,
      max: maxCurrentConsumed,
      interval: maxCurrentConsumed / 5,
    }, {
      type: 'value',
      name: 'Total Devices Monitored',
      nameLocation: 'middle',
      nameTextStyle: {
        padding: [40, 0, 0, 0],
      },
      position: 'right',
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333'
        }
      },
      min: 0,
      max: maxEquipmentCount,
      interval: maxEquipmentCount / 5,
    }],
    grid: {
      top: 20,
      left: 30,
      right: 30,
      containLabel: true,
    },
    series: [
      {
        name: 'Current Consumed',
        type: 'bar',
        data: currentConsumed,
      }, {
        name: 'Devices',
        type: 'line',
        data: equipmentCount,
        yAxisIndex: 1,
      }
    ]
  };

  return (
    <HomeCard
      header="Current consumption trend over time"
      headerLink={{to: '/equipment/list?type=fitpower', text: 'All equipment', onClick: filterAll}}
    >
      <ReactECharts
        // style={{height: 400}}
        option={chartOptions}
      />
    </HomeCard>
  );
}

export default memo(CurrentConsumptionTrend);