import { useState, useMemo, useCallback, useEffect, useRef, memo } from "react";
import moment from "moment";
import { Card, Row, Col, Button, OverlayTrigger, Popover, Form } from "react-bootstrap";
import ReactEcharts from "echarts-for-react";
import { GoDashboard } from 'react-icons/go';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { DayPickerSingleDateController } from 'react-dates';
import { formatCurrency } from "../lib/utils";
import { getDaysInMonth, oneDay } from "../../lib/utils";

const colours = {
  black: '#434348',  // black
  blue: '#004085', // blue
  lightBlue: '#93B1D0',
  red: '#EE220C',
};

const DAY = 'DAY';
const WEEK = 'WEEK';
const MONTH = 'MONTH';
const TOTAL = 'TOTAL';
const COLOURS = [
  colours.lightBlue,
  colours.blue,
];

// This is the date range picker controller for energy use chart.
function DateRangePicker({ range = DAY, onDateChange, maximumViewedDateRange }) {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    setSelectedDate(moment());
  }, [range]);

  const startDate = useMemo(() => {
    if(range === WEEK) {
      return moment(selectedDate).startOf('week');
    } else if(range === MONTH) {
      return moment(selectedDate).startOf('month');
    } else if(range === TOTAL) {
      return moment(maximumViewedDateRange.startDate);
    } else {
      return moment(selectedDate);
    }
  }, [range, selectedDate]);

  const endDate = useMemo(() => {
    if(range === WEEK) {
      return moment(selectedDate).endOf('week');
    } else if(range === MONTH) {
      return moment(selectedDate).endOf('month');
    } else if(range === TOTAL) {
      return moment(maximumViewedDateRange.endDate);
    } else {
      return moment(selectedDate);
    }
  }, [range, selectedDate]);

  const nextStartDate = useMemo(() => {
    if (range === WEEK) {
      return moment(startDate).add(1, 'weeks');
    } else if (range === MONTH) {
      return moment(startDate).add(1, 'months');
    } else {
      return moment(startDate).add(1, 'days');
    }
  }, [startDate]);

  const prevEndDate = useMemo(() => {
    if (range === WEEK) {
      return moment(endDate).subtract(1, 'weeks');
    } else if (range === MONTH) {
      return moment(endDate).subtract(1, 'months');
    } else {
      return moment(endDate).subtract(1, 'days');
    }
  }, [endDate]);

  const displayStartDate = useMemo(() => {
    return startDate.format('DD MMM YYYY');
  }, [startDate]);
  const displayEndDate = useMemo(() => {
    return endDate.format('DD MMM YYYY');
  }, [endDate]);

  const prevSelectedDate = useMemo(() => {
    if (range === WEEK) {
      return moment(selectedDate).subtract(1, 'weeks');
    } else if (range === MONTH) {
      return moment(selectedDate).subtract(1, 'months');
    } else {
      return moment(selectedDate).subtract(1, 'days');
    }
  }, [range, selectedDate]);

  const nextSelectedDate = useMemo(() => {
    if (range === WEEK) {
      return moment(selectedDate).add(1, 'weeks');
    } else if (range === MONTH) {
      return moment(selectedDate).add(1, 'months');
    } else {
      return moment(selectedDate).add(1, 'days');
    }
  }, [range, selectedDate]);

  const handleDateChange = useCallback((date) => {
    setSelectedDate(date);
  }, [selectedDate, startDate, endDate]);

  const handleMoveForward = useCallback(() => {
    // Move one day/week/month forward.
    setSelectedDate(nextSelectedDate);
  }, [nextSelectedDate]);

  const handleMoveBack = useCallback(() => {
    // Move one day/week/month back.
    setSelectedDate(prevSelectedDate);
  }, [prevSelectedDate]);

  useEffect(() => {
    onDateChange && onDateChange(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <OverlayTrigger
      placement="bottom"
      show={show}
      overlay={
        <Popover id="date-picker">
          <DayPickerSingleDateController
            date={selectedDate}
            onDateChange={handleDateChange}
            onOutsideClick={() => {
              setShow(false);
            }}
            keepOpenOnDateSelect={true}
            initialVisibleMonth={() => selectedDate}
            focused
            isOutsideRange={(date) => {
              return date.isBefore(moment(maximumViewedDateRange.startDate)) || date.isAfter(moment(maximumViewedDateRange.endDate).add(1, 'days'));
            }}
          />
        </Popover>
      }
      trigger="click"
    >
      <span className="d-inline-block">
        <div className="d-flex align-items-center">
          <Button
            variant="outline-secondary"
            disabled={range === TOTAL || prevEndDate.isBefore(maximumViewedDateRange.startDate)}
            onClick={handleMoveBack}
          >
            <AiOutlineArrowLeft />
          </Button>
          <Form.Control
            value={range === DAY ? displayStartDate : `${displayStartDate} - ${displayEndDate}`}
            style={{minWidth: '225px'}}
            onChange={() => {}}
            onClick={() => setShow(true)}
            aria-hidden="true"
            className="mx-1"
            disabled={range === TOTAL}
          />
          <Button
            variant="outline-secondary"
            disabled={range === TOTAL || nextStartDate.isAfter(moment(maximumViewedDateRange.endDate).add(1, 'days'))}
            onClick={handleMoveForward}
          >
            <AiOutlineArrowRight />
          </Button>
        </div>
      </span>
    </OverlayTrigger>
  );
}

const COMPARE_PERIOD = 2;

function EnergyUse({
  energyUseSamples = {},
  // ...props
}) {
  const chartRef = useRef(null);
  const [range, setRange] = useState(DAY);  // range is day/week/month.
  const [compare, setCompare] = useState(false);
  const [dateRange, setDateRange] = useState({
    startTime: moment(),
    endTime: moment(),
  });

  const samples = useMemo(() => {
    if(range === DAY) {
      const currentDaySamples = energyUseSamples.hourly?.find(sample => moment(sample.date).valueOf() === dateRange.startTime);
      return currentDaySamples?.energy_use || [];
    }
    const dailySamples = (energyUseSamples.daily || []).filter(sample => {
      const dayTimestamp = moment(sample.date).valueOf();
      return dayTimestamp >= dateRange.startTime && dayTimestamp <= dateRange.endTime;
    });
    if(range === WEEK) {
      if(dailySamples.length === 7) return dailySamples;
      const currentWeekSamples = new Array(7);
      for(let i = 0; i <= 6; i++) {
        const dayInWeek = moment(dateRange.startTime + i * oneDay).format('YYYY-MM-DD');
        // console.log(moment(dayInWeek).format('YYYY-MM-DD'));
        const foundData = dailySamples.find(sample => sample.date === dayInWeek);
        currentWeekSamples[i] = {
          date: dayInWeek,
          kWh: foundData?.kWh || 0,
          cost: foundData?.cost || 0,
        };
      }
      return currentWeekSamples;
    }
    if(range === MONTH) {
      const daysInTheMonth = getDaysInMonth(dateRange.startTime);
      if(dailySamples.length === daysInTheMonth) return dailySamples;
      const currentMonthSamples = new Array(daysInTheMonth);
      for(let i = 0; i <= daysInTheMonth - 1; i++) {
        const dayInMonth = moment(dateRange.startTime + i * oneDay).format('YYYY-MM-DD');
        const foundData = dailySamples.find(sample => sample.date === dayInMonth);
        currentMonthSamples[i] = {
          date: dayInMonth,
          kWh: foundData?.kWh || 0,
          cost: foundData?.cost || 0,
        };
      }
      return currentMonthSamples;
    }
    return dailySamples;
  }, [range, dateRange, energyUseSamples]);

  const comparableSamplesAndDate = useMemo(() => {
    if(!compare) return {};
    const comparableSamples = [];
    const dateData = [];
    for(let i = 0; i < COMPARE_PERIOD; i++) {
      if(range === DAY) {
        const currentDay = moment(dateRange.startTime);
        currentDay.subtract(i, 'days');
        const prevSamples = energyUseSamples.hourly?.find(sample => moment(sample.date).valueOf() === currentDay.valueOf());
        const prevSamplesEnergyUse = prevSamples?.energy_use || [];
        comparableSamples.unshift(prevSamplesEnergyUse.map(item => ({
          ...item,
          date: currentDay.format('YYYY-MM-DD'),
        })));
        dateData.unshift({
          startDate: currentDay,
          endDate: currentDay,
        });
      }
      if(range === WEEK) {
        const currentStartDay = moment(dateRange.startTime);
        const currentEndDay = moment(dateRange.endTime);
        currentStartDay.subtract(i, 'weeks');
        currentEndDay.subtract(i, 'weeks');
        let fullPrevSamples = [];
        const prevSamples = (energyUseSamples.daily || []).filter(sample => {
          const dayTimestamp = moment(sample.date).valueOf();
          return dayTimestamp >= currentStartDay && dayTimestamp <= currentEndDay;
        });
        if(prevSamples?.length === 7) {
          fullPrevSamples = prevSamples;
        } else {
          for(let i = 0; i < 7; i++) {
            const dayInWeek = moment(currentStartDay.valueOf() + i * oneDay).format('YYYY-MM-DD');
            const foundData = prevSamples.find(sample => sample.date === dayInWeek);
            fullPrevSamples[i] = {
              date: dayInWeek,
              kWh: foundData?.kWh || 0,
              cost: foundData?.cost || 0,
            };
          }
        }
        comparableSamples.unshift(fullPrevSamples);
        dateData.unshift({
          startDate: currentStartDay,
          endDate: currentEndDay,
        });
      }
      if(range === MONTH) {
        const currentStartDay = moment(dateRange.startTime);
        const currentEndDay = moment(dateRange.endTime);
        currentStartDay.subtract(i, 'months');
        currentEndDay.subtract(i, 'months');
        let fullPrevSamples = [];
        const prevSamples = (energyUseSamples.daily || []).filter(sample => {
          const dayTimestamp = moment(sample.date).valueOf();
          return dayTimestamp >= currentStartDay && dayTimestamp <= currentEndDay;
        });
        const daysInTheMonth = getDaysInMonth(currentStartDay.valueOf());
        if(prevSamples.length === daysInTheMonth) {
          fullPrevSamples = prevSamples;
        } else {
          for(let i = 0; i < daysInTheMonth; i++) {
            const dayInMonth = moment(currentStartDay.valueOf() + i * oneDay).format('YYYY-MM-DD');
            const foundData = prevSamples.find(sample => sample.date === dayInMonth);
            fullPrevSamples[i] = {
              date: dayInMonth,
              kWh: foundData?.kWh || 0,
              cost: foundData?.cost || 0,
            };
          }
        }
        comparableSamples.unshift(fullPrevSamples || []);
        dateData.unshift({
          startDate: currentStartDay,
          endDate: currentEndDay,
        });
      }
    }
    return { comparableSamples, dateData };
  }, [samples, range, compare]);

  const getTooltipText = useCallback((series = []) => {
    const { axisValue, dataIndex } = series[0] || {};
    let tooltipText = '';
    if(compare) {
      if(range === DAY) {
        tooltipText += `${axisValue}`;
        tooltipText += '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>';
      }
      if(range === WEEK) {
        tooltipText += `${moment(axisValue).format('dddd')}`;
        tooltipText += '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>';
      }
      for(let i = COMPARE_PERIOD - 1; i >= 0; i--) {
        const color = COLOURS[COMPARE_PERIOD - 1 - i];
        tooltipText += `<div style="display: inline-block; height: 10px; width: 10px; background: ${color}"></div> `;
        if(range === DAY) {
          const currentDay = moment(dateRange.startTime);
          currentDay.subtract(i, 'days');
          const foundSamples = energyUseSamples.hourly?.find(sample => moment(sample.date).valueOf() === currentDay.valueOf());
          const foundSamplesEnergyUse = foundSamples?.energy_use || [];
          tooltipText += `${currentDay.format('DD MMM YYYY')} Energy Use: ${foundSamplesEnergyUse[dataIndex]?.kWh || 0}kWh `;
          tooltipText += `Energy Cost: ${formatCurrency(foundSamplesEnergyUse[dataIndex]?.cost || 0)}<br />`;
        }
        if(range === WEEK) {
          const currentDay = moment(axisValue);
          currentDay.subtract(i, 'weeks');
          const foundSamples = energyUseSamples.daily?.find(sample => moment(sample.date).valueOf() === currentDay.valueOf());
          tooltipText += `${currentDay.format('DD MMM YYYY')} Energy Use: ${(foundSamples?.kWh || 0).toFixed(2)}kWh Energy Cost: ${formatCurrency(foundSamples?.cost || 0)}<br />`;
        }
        if(range === MONTH) {
          const currentDay = moment(axisValue);
          currentDay.subtract(i, 'month');
          const foundSamples =  energyUseSamples.daily?.find(sample => moment(sample.date).valueOf() === currentDay.valueOf());
          tooltipText += `${currentDay.format('DD MMM YYYY')} Energy Use: ${(foundSamples?.kWh || 0).toFixed(2)}kWh Energy Cost: ${formatCurrency(foundSamples?.cost || 0)}<br />`;
        }
      }
    } else {
      // Just the current sample data.
      const displayDate = range === DAY ? moment(dateRange.startTime).format('LL') + ' ' + axisValue : moment(axisValue).format('dddd, LL');
      tooltipText = (
        `${displayDate}${
          '<hr style="margin:2px 0 5px;border-top-color: #ccc;"/>'
        } Energy Use: ${(samples[dataIndex]?.kWh || 0).toFixed(2)} kWh<br />
        Energy Cost: ${formatCurrency(samples[dataIndex]?.cost || 0)}<br />
        `
      );
    }
    return tooltipText;
  }, [samples, range, dateRange, compare]);

  const maximumViewedDateRange = useMemo(() => {
    const hourlySamples = energyUseSamples.hourly || [];
    if(hourlySamples.length === 0) return {};
    return {
      startDate: hourlySamples[0].date,
      endDate: hourlySamples[hourlySamples.length - 1].date
    };
  }, [samples]);

  const option = useMemo(() => {
    const date = range === DAY ?
      moment(dateRange.startTime).format('DD MMM YYYY') :
      range === MONTH ?
        moment(dateRange.startTime).format('MMM YYYY') :
        `${moment(dateRange.startTime).format('DD MMM YYYY')} - ${moment(dateRange.endTime).format('DD MMM YYYY')}`;
    const nonCompareOption = {
      grid: {
        top: 50,
        bottom: 30,
        left: 55,
        right: 70,
      },
      legend: {
        top: 0,
        left: 50,
        right: 50,
        data: [date],
      },
      tooltip: {
        trigger: 'axis',
        formatter: series => getTooltipText(series),
      },
      xAxis: [{
        data: range === DAY ? samples.map((value, index) => {
          const hour = moment(dateRange.startTime).hour(index).format('ha');
          return hour;
        }) : samples.map(sample => sample.date),
        axisLabel: {
          formatter: (value) => {
            return range === DAY ? value : range === WEEK ? moment(value).format('dd') : value;
          },
        },
        type: 'category',
      }],
      yAxis: [{
        type: 'value',
        name: 'Energy Use (kWh)',
        nameLocation: 'center',
        nameRotate: 90,
        nameGap: 40,
        nameTextStyle: {
          color: colours.black,
        },
      },
      ],
      series: [{
        name: date,
        type: 'bar',
        data: samples.map(sample => sample.kWh),
      },
      ],
      color: [
        colours.blue
      ],
    };

    const { comparableSamples = [], dateData = [] } = comparableSamplesAndDate;

    const dateNames = dateData.map(({startDate, endDate}) => {
      if(range === MONTH) return startDate.format('MMM YYYY');
      if(range === WEEK) return  `${startDate.format('DD MMM YYYY')} - ${endDate.format('DD MMM YYYY')}`;
      if(range ===DAY) return startDate.format('DD MMM YYYY');
      return '';
    });
    const compareSamplesSeries = comparableSamples.map((samples, index) => ({
      name: dateNames[index],
      type: 'bar',
      data: samples.map(sample => sample.kWh),
      barGap: '0',
    }));
    const compareOption = {
      ...nonCompareOption,
      legend: {
        ...nonCompareOption.legend,
        data: dateNames,
      },
      series: compareSamplesSeries,
      color: COLOURS,
    };
    return compare ? compareOption : nonCompareOption;
  }, [samples, range, dateRange, comparableSamplesAndDate, compare]);

  useEffect(() => {
    const chartInstance = chartRef.current && chartRef.current.getEchartsInstance();
    if(chartInstance) {
      chartInstance.setOption(option, {
        notMerge: true,
        lazyUpdate: false,
        silent: true,
      });
    }
  }, [option]);

  return (
    <Card className="shadow-sm mt-2">
      <Card.Header className="pb-2 d-block">
        <Row className="small-gutters align-items-center">
          <Col xs="auto" className="mb-1">
            <h5><GoDashboard /> Energy Use</h5>
          </Col>
          <Col xs="auto" className="ml-auto">
            <Row className="small-gutters d-flex justify-content-end">
              <Col xs="auto" className="mb-1">
                <Button
                  variant={range === DAY ? "secondary" : "outline-secondary"}
                  onClick={() => setRange(DAY)}
                  className="mr-1"
                >
                  Day
                </Button>
                <Button
                  variant={range === WEEK ? "secondary" : "outline-secondary"}
                  onClick={() => setRange(WEEK)}
                  className="mr-1"
                >
                  Week
                </Button>
                <Button
                  variant={range === MONTH ? "secondary" : "outline-secondary"}
                  onClick={() => setRange(MONTH)}
                  className="mr-1"
                >
                  Month
                </Button>
                <Button
                  variant={compare ? "secondary" : "outline-secondary"}
                  onClick={() => setCompare(!compare)}
                  className="mr-1"
                >
                  Compare
                </Button>
              </Col>
              <Col xs="auto" className="mb-1">
                <DateRangePicker
                  range={range}
                  maximumViewedDateRange={maximumViewedDateRange}
                  onDateChange={(startDate, endDate) => {
                    startDate.startOf('day');
                    endDate.startOf('day');
                    setDateRange({
                      startTime: startDate.valueOf(),
                      endTime: endDate.valueOf(),
                    });
                  }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {/* <div className="text-center">{
          range === DAY ?
            moment(dateRange.startTime).format('DD MMM YYYY') :
            range === MONTH ?
              moment(dateRange.startTime).format('MMM YYYY') :
              `${moment(dateRange.startTime).format('DD MMM YYYY')} - ${moment(dateRange.endTime).format('DD MMM YYYY')}`}
        </div> */}
        <ReactEcharts option={option} ref={chartRef} />
      </Card.Body>
    </Card>
  );
}

export default memo(EnergyUse);