import React from 'react';

export default function IoIosMotor({ className, size="1em" }) {
  return (
    // add SVG similar to BluePrint icons
    <svg
      data-icon="motor"
      width={size}
      height={size}
      viewBox="7 6 46 45"
      fill="currentColor"
      className={className}
    >
      <desc>motor</desc>
      {/* path modified from base: https://thenounproject.com/search/?q=motor&i=3207223 */}
      {/* (shorter, less motor fins, motor fins shorter and more spaced out, rounded numbers) */}
      <path d="M 52.463 25.195 C 52.463 28.445 52.233 31.485 51.813 33.755 C 51.453 35.745 50.753 38.732 48.933 38.732 L 44.64 38.732 C 44.08 38.732 43.64 38.282 43.64 37.732 L 43.64 12.752 C 43.64 12.202 44.08 11.752 44.64 11.752 L 48.933 11.752 C 52.003 11.752 52.463 20.265 52.463 25.195 Z"></path>
      <path d="M 41.67 37.735 C 41.65 37.615 41.65 12.755 41.67 12.645 L 23.45 12.645 L 23.45 37.735 L 41.67 37.735 Z M 37.407 32.332 L 27.391 32.332 C 26.841 32.332 26.391 31.892 26.391 31.332 C 26.391 30.782 26.841 30.332 27.391 30.332 L 37.407 30.332 C 37.957 30.332 38.407 30.782 38.407 31.332 C 38.407 31.892 37.957 32.332 37.407 32.332 Z M 37.407 26.097 L 27.391 26.097 C 26.841 26.097 26.391 25.647 26.391 25.097 C 26.391 24.547 26.841 24.097 27.391 24.097 L 37.407 24.097 C 37.957 24.097 38.407 24.547 38.407 25.097 C 38.407 25.647 37.957 26.097 37.407 26.097 Z M 37.407 20.049 L 27.391 20.049 C 26.841 20.049 26.391 19.599 26.391 19.049 C 26.391 18.499 26.841 18.049 27.391 18.049 L 37.407 18.049 C 37.957 18.049 38.407 18.499 38.407 19.049 C 38.407 19.599 37.957 20.049 37.407 20.049 Z"></path>
      <path d="M 46.06 43.005 C 46.06 43.565 45.61 44.005 45.06 44.005 L 20.06 44.005 C 19.5 44.005 19.06 43.565 19.06 43.005 C 19.06 42.455 19.5 42.005 20.06 42.005 L 25.24 42.005 L 25.24 36.735 L 39.87 36.735 L 39.87 42.005 L 45.06 42.005 C 45.61 42.005 46.06 42.455 46.06 43.005 Z"></path>
      <path d="M 18.501 37.735 C 17.951 37.735 17.501 37.295 17.501 36.735 L 17.501 35.535 L 15.297 35.535 C 14.737 35.535 14.297 35.085 14.297 34.535 L 14.297 28.405 L 10.507 28.405 C 8.737 28.405 7.287 26.965 7.287 25.185 C 7.287 24.345 7.627 23.535 8.227 22.925 C 8.847 22.305 9.657 21.975 10.507 21.975 L 14.297 21.975 L 14.297 15.845 C 14.297 15.295 14.737 14.845 15.297 14.845 L 17.501 14.845 L 17.501 13.645 C 17.501 13.095 17.951 12.645 18.501 12.645 L 21.45 12.645 L 21.45 37.735 L 18.501 37.735 Z"></path>
    </svg>
  );
}
