import React from 'react';
import { Container } from 'react-bootstrap';

import withNavigationGroupProps from '../components/withNavigationGroupProps';
import EditGroupDevices from '../components/EditGroupDevices';
import EditGroupSummary from '../components/EditGroupSummary';

function EditGroupEquipment(props) {
  return (
    <Container fluid>
      <EditGroupSummary {...props} />
      <EditGroupDevices {...props} />
    </Container>
  );
}

export default withNavigationGroupProps(EditGroupEquipment);
