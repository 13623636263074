import { useParams } from "react-router";
import useDevice from "../hooks/useDevice";
import EquipmentPageLayout from "../components/EquipmentPageLayout";
import FitpowerSamplesContext from "../../../components/charts/FitpowerSamplesContext";
import { NoSampleData, DeviceNotFound } from "./EquipmentDetail";
import EnergyOverview from '../../../components/charts/EnergyOverview';
import EnergyUse from "../../../components/charts/EnergyUse";
import EquipmentStates from "../../../components/charts/EquipmentStates";
import FitpowerMeasuredData from "../../../components/charts/FitpowerMeasuredData";

function EquipmentFitpowerCharts() {
  const { id } = useParams();
  const { device } = useDevice(parseInt(id));

  if(!device) {
    return (
      <div className="my-4">
        <DeviceNotFound />
      </div>
    );
  }

  if(device.archived) {
    return (
      <div className="my-4">
        <NoSampleData />
      </div>
    );
  }

  return (
    <>
      <FitpowerSamplesContext deviceId={device.id} sampleType="energy_overview">
        {({energyOverviewSamples, equipmentStateSamples, measuredDataSamples, energyUseSamples, ...chartProps}) => {
          return (
            <>
              {energyOverviewSamples?.length > 0 && <EnergyOverview deviceId={device.id} samples={energyOverviewSamples} {...chartProps} /> }
              {equipmentStateSamples?.length > 0 && <EquipmentStates deviceId={device.id} samples={equipmentStateSamples} {...chartProps} /> }
              {energyUseSamples?.daily?.length > 0 && energyUseSamples?.hourly?.length > 0 && <EnergyUse deviceId={device.id} energyUseSamples={energyUseSamples} {...chartProps} />}
              <FitpowerMeasuredData deviceId={device.id} samples={measuredDataSamples} {...chartProps} />
            </>
          );
        }}
      </FitpowerSamplesContext>
    </>
  );
}

function EquipmentFitpowerDetail() {

  return (
    <EquipmentPageLayout>
      <EquipmentFitpowerCharts />
    </EquipmentPageLayout>
  );
}

export default EquipmentFitpowerDetail;