import { Dispatch } from 'redux';
import { CALL_API } from '../../lib/apiMiddleware';
import * as ACTION_TYPES from './types/ActionTypes';
import * as NotificationAction from './types/Action';
import { SetAllNotificationsPayload } from './types/Notification';

export function requestUnreadNotifications(): NotificationAction.RequestUnreadNotificationsAction {
  return {
    type: ACTION_TYPES.REQUEST_UNREAD_NOTIFICATIONS,
  };
}

export function requestUnreadNotificationsFailure(): NotificationAction.UnreadNotificationsFailureAction {
  return {
    type: ACTION_TYPES.UNREAD_NOTIFICATIONS_FAILURE,
  };
}

export function receiveUnreadNotifications(): NotificationAction.ReceiveUnreadNotificationsAction {
  return {
    type: ACTION_TYPES.RECEIVE_UNREAD_NOTIFICATIONS,
  };
}

export function fetchUnreadNotifications(): (dispatch: Dispatch<NotificationAction.FetchUnreadNotificationsAction>) => NotificationAction.FetchUnreadNotificationsAction {
  return dispatch => {
    return dispatch({
      type: 'api/CALL',
      method: 'get',
      endpoint: '/nc',
      requestAction: requestUnreadNotifications(),
      successAction: receiveUnreadNotifications(),
      errorAction: requestUnreadNotificationsFailure(),
    });
  };
}

export function requestAllNotifications(): NotificationAction.RequestAllNotificationsAction {
  return {
    type:ACTION_TYPES.REQUEST_ALL_NOTIFICATIONS,
  };
}

export function requestAllNotificationsFailure(): NotificationAction.AllNotificationsFailureAction {
  return {
    type: ACTION_TYPES.ALL_NOTIFICATIONS_FAILURE,
  };
}

export function receiveAllNotifications(): NotificationAction.ReceiveAllNotificationsAction {
  return {
    type: ACTION_TYPES.RECEIVE_ALL_NOTIFICATIONS,
  };
}

export function fetchAllNotifications(): (dispatch: Dispatch<NotificationAction.FetchAllNotificationsAction>) => NotificationAction.FetchAllNotificationsAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/nc/notifications',
      requestAction: requestAllNotifications(),
      successAction: receiveAllNotifications(),
      errorAction: requestAllNotificationsFailure(),
    });
  };
}

export function requestSetOneNotificationReadStatus(id: string, read: boolean): NotificationAction.RequestSetOneNotificationReadStatusAction {
  return {
    type: ACTION_TYPES.REQUEST_SET_ONE_NOTIFICATION_READ_STATUS,
    id,
    read,
  };
}
export function setOneNotificationReadStatusFailure(id: string, read: boolean): NotificationAction.SetOneNotificationReadStatusFailureAction {
  return {
    type: ACTION_TYPES.SET_ONE_NOTIFICATION_READ_STATUS_FAILURE,
    id,
    read,
  };
}
export function receiveSetOneNotificationReadStatus(id: string, read: boolean): NotificationAction.ReceiveSetOneNotificationReadStatusAction {
  return {
    type: ACTION_TYPES.RECEIVE_SET_ONE_NOTIFICATION_READ_STATUS,
    id,
    read,
  };
}
export function updateOneNotificationReadStatus(id: string, read: boolean): (dispatch: Dispatch<NotificationAction.UpdateOneNotificationReadStatusAction>) => NotificationAction.UpdateOneNotificationReadStatusAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: `/nc/notifications/${id}`,
      data: { unread: !read },
      requestAction: requestSetOneNotificationReadStatus(id, read),
      successAction: receiveSetOneNotificationReadStatus(id, read),
      errorAction: setOneNotificationReadStatusFailure(id, read),
    });
  };
}

export function requestDeleteOneNotification(id: string): NotificationAction.RequestDeleteOneNotificationAction {
  return {
    type: ACTION_TYPES.REQUEST_DELETE_ONE_NOTIFICATION,
    id
  };
}
export function deleteOneNotificationFailure(id: string): NotificationAction.DeleteOneNotificationFailureAction {
  return {
    type: ACTION_TYPES.DELETE_ONE_NOTIFICATION_FAILURE,
    id
  };
}
export function receiveDeleteOneNotification(id: string): NotificationAction.ReceiveDeleteOneNotificationAction {
  return {
    type: ACTION_TYPES.RECEIVE_DELETE_ONE_NOTIFICATION,
    id,
  };
}
export function deleteOneNotification(id: string): (dispatch: Dispatch<NotificationAction.DeleteOneNotificationAction>) => NotificationAction.DeleteOneNotificationAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'delete',
      endpoint: `/nc/notifications/${id}`,
      requestAction: requestDeleteOneNotification(id),
      successAction: receiveDeleteOneNotification(id),
      errorAction: deleteOneNotificationFailure(id),
      errorToast: false,
    });
  };
}

export function requestNotificationOptions(): NotificationAction.RequestNotificationOptionsAction {
  return {
    type: ACTION_TYPES.REQUEST_NOTIFICATION_OPTIONS,
  };
}
export function receiveNotificationOptions(): NotificationAction.ReceiveNotificationOptionsAction {
  return {
    type: ACTION_TYPES.RECEIVE_NOTIFICATION_OPTIONS,
  };
}
export function notificationOptionsFailure(): NotificationAction.NotificationOptionsFailureAction {
  return {
    type: ACTION_TYPES.NOTIFICATION_OPTIONS_FAILURE,
  };
}
export function fetchNotificationOptions(): (dispatch: Dispatch<NotificationAction.FetchNotificationOptionsAction>) => NotificationAction.FetchNotificationOptionsAction {
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'get',
      endpoint: '/domains/notifications',
      requestAction: requestNotificationOptions(),
      successAction: receiveNotificationOptions(),
      errorAction: notificationOptionsFailure(),
    });
  };
}

export function receiveSetAllNotifications(setData: SetAllNotificationsPayload): NotificationAction.ReceiveSetAllNotificationsAction {
  return {
    type: ACTION_TYPES.RECEIVE_SET_ALL_NOTIFICATIONS,
    setData,
  };
}

// Set all notifications as read or unread.
export function setAllNotificationsReadStatus(read: boolean): (dispatch: Dispatch<NotificationAction.SetAllNotificationsAction>) => NotificationAction.SetAllNotificationsAction {
  const data: SetAllNotificationsPayload = { unread: !read };
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: '/nc/notifications',
      data,
      requestAction: requestAllNotifications(),
      successAction: receiveSetAllNotifications(data),
      errorAction: requestAllNotificationsFailure(),
    });
  };
}

// Delete all notifications(readOnly is false) or all read notifications(readOnly is true).
export function deleteAllNotifications(readOnly: boolean = false): (dispatch: Dispatch<NotificationAction.SetAllNotificationsAction>) => NotificationAction.SetAllNotificationsAction {
  const data: SetAllNotificationsPayload = { deleted: true, read_only: readOnly };
  return dispatch => {
    return dispatch({
      type: CALL_API,
      method: 'put',
      endpoint: '/nc/notifications',
      data,
      requestAction: requestAllNotifications(),
      successAction: receiveSetAllNotifications(data),
      errorAction: requestAllNotificationsFailure(),
    });
  };
}