
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Badge } from 'react-bootstrap';

import ActivityCard from '../../app/components/ActivityCard';

import { relativeDateFormatter } from '../../../components/table/formatters';

import { getDevice, getDevicePlaceholderImage } from '../selectors';
import { isSuperAdmin } from '../../user/selectors';
import { getSensorName } from '../utils';

export function AlarmTypeBadge({ alarmType }) {
  switch(alarmType) {
    case 'normal': return <Badge variant="success">Normal</Badge>;
    case 'alarm': return <Badge variant="danger">Alarm</Badge>;
    case 'yellow': return <Badge variant="warning">Advisory</Badge>;
    case 'red': return <Badge variant="danger">Alert</Badge>;
    case 'user': return <Badge variant="warning">Manual Alarm</Badge>;
    default: return null;
  }
}

function DeviceActivityCard({
  device = {},
  alarm = {},
  image,
  DeviceLink = 'span',
  children,
  sideButton,
  ...props
}) {
  return (
    <ActivityCard
      {...props}
      title={(
        <Row className="small-gutters">
          {image && (
            <Col xs="auto">
              <img src={image} alt="Device" />
            </Col>
          )}
          <Col>
            <div className="d-flex align-items-start">
              <div>
                <DeviceLink to={`/devices/${device.id}`}>
                  {getSensorName(device)}
                </DeviceLink> on <Link to={`/equipment/${device.id}`}>
                  {device.equipment_name}
                </Link>
              </div>
              {alarm.is_current === true && <span className="badge border border-danger bg-danger text-white mx-1" style={{fontSize: '42%'}}>EVT</span>}
              {alarm.was_current === true && <span className="badge border border-danger text-danger mx-1" style={{fontSize: '42%'}} >EVT</span>}
            </div>
            <div className="subtitle">
              <DeviceLink to={`/devices/${device.id}`}>
                {device.serial}
              </DeviceLink>
            </div>
            {!!alarm.alarm_timestamp && (
              <div className="subtitle text-muted">
                <Link to={`/alarms/${alarm.id}`}>
                  {relativeDateFormatter(alarm.alarm_timestamp)}
                </Link>
              </div>
            )}
            {
              alarm.alarm_type &&
              <h4 className="mt-1">
                <AlarmTypeBadge alarmType={alarm.alarm_type} />
              </h4>
            }
          </Col>
          {sideButton &&
            <Col xs="auto" className="mb-1">
              {sideButton}
            </Col>
          }
        </Row>
      )}
    >
      {children}
    </ActivityCard>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    image: getDevicePlaceholderImage(state, deviceId),
    device: getDevice(state, deviceId),
    DeviceLink: isSuperAdmin(state) ? Link : 'span',
  };
};

const ConnectedDeviceCard = connect(mapStateToProps)(DeviceActivityCard);

export function FitMachineActivityCard(props) {
  return (
    <ConnectedDeviceCard {...props} />
  );
}
