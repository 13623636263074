
/*
 * Conversions
 */
const convertNumberByFactor = (factor, { shift=0, constant=0 }={}) => value => {
  if (typeof value !== 'number') {
    return NaN;
  }
  return factor * (value + shift) + constant;
};

// we use the most precise factors we can find
// the DB stores about single-float precision for most number values
// so a very accurate conversion is preferred when editing values
// in a different set of units than the units the DB value is saved in
export const convertMmToIn = convertNumberByFactor(1/25.4);
export const convertInToMm = convertNumberByFactor(25.4);
export const convertCelsiusToFarenheit = convertNumberByFactor(1.8, { constant: 32 });
export const convertFarenheitToCelsius = convertNumberByFactor(1/1.8, { shift: -32 });
export const convertKwToHp = convertNumberByFactor(1/0.746);
// Electrical horsepower is defined as exactly 746 W
export const convertHpToKw = convertNumberByFactor(0.746);
