import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import HomeCard from './HomeCard';
import { useGroupNonReporting } from '../../modules/organisation/hooks/useGroupStandardData';
import Table from '../Table';
import { lastDataHeard, equipmentNameLink } from './columns';
import { batteryScore, wifiScore } from '../../modules/equipment/columns';
import useDeviceStatusFilter from '../../modules/equipment/hooks/useDeviceStatusFilter';
import { SENSOR_TYPE } from '../../modules/equipment/constants';
import { formatTableColumn } from '../../lib/formatTableColumn';

const columns = [lastDataHeard, equipmentNameLink, batteryScore, wifiScore];

function NonReportingDevice({ sensorType }) {
  const { t } = useTranslation();
  const groupNonReporting = useGroupNonReporting();
  const { filter } = useDeviceStatusFilter();
  const sensorName = sensorType === SENSOR_TYPE.FITPOWER ? "FitPowers" : "FitMachines";
  // Check type as e.g. equipment/columns.js exports mm2 where text/tooltip are React components.
  // These cols don't have tooltips.
  const tColumns = columns.map(col => ({
    ...col,
    text: (typeof col.text === 'string' ? t(formatTableColumn(col.text)) : col.text),
    tooltip: (typeof col.text === 'string' ? t(formatTableColumn(col.text)) : col.text)
  }));

  if(!groupNonReporting) return null;
  const { _state_: {loading, error} = {}, items = []} = groupNonReporting;
  return (
    <HomeCard
      header={t('home.non-reporting-sensors', { sensorName: sensorName })}
      headerLink={{
        to: `/equipment/list?type=${sensorType}`,
        text: t('home.all-non-reporting-sensors', { sensorName: sensorName }),
        onClick: () => filter(['Non-reporting'])
      }}
      loading={loading}
      error={error}
    >
      <Table
        data={items}
        keyField="device_id"
        loading={loading}
        columns={tColumns}
        condensed
      />
    </HomeCard>
  );
}

export default memo(NonReportingDevice);
