
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import DevicePageLayout from '../components/DevicePageLayout';
import { fetchDeviceErrors } from '../actions';
import Table from '../../../components/Table';
import { relativeDateFormatterCreator } from '../../../components/table/formatters';
import Toolbar from '../../../components/TableToolbar';
import Private from '../../../components/Private';
import { getDevice, getDeviceFmerrors } from '../selectors';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

const t = i18n.t;

const unknownFitMachineErrorTimeFormatter = relativeDateFormatterCreator('N/A', {
  unexpectedExplanation:
    'This usually occurs when FitMachine cannot get the real time from MachineCloud',
});

const columns = [
  {
    dataField: 'reverse_order',
    text: 'Time',
    formatter: (order, log) => unknownFitMachineErrorTimeFormatter(log['error_time']),
    sort: true,
  },
  {
    dataField: 'error_code',
    text: 'Code',
    sort: true,
  },
  {
    dataField: 'error_name',
    text: 'Name',
    sort: true,
  },
  {
    dataField: 'error_string',
    text: 'Message',
    sort: true,
  },
];

const defaultSorted = [{
  dataField: 'reverse_order',
  order: 'desc'
}];

const noDataIndication = () => t('screens.equipment.device-errors.no-logs');

function DeviceErrors({ fetchDeviceErrors, device={}, deviceFmerrors=[] }) {

  const { t } = useTranslation();
  // handle logs state (as we need to insert a unique identifier for each row)
  // note: when a unique identifier is available from the API we can drop this component state
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const uniquelyIdentifiedLogs = deviceFmerrors.map((log, index, { length }) => {
      return {
        error_time: log.error_time,
        error_code: log.error_code,
        error_name: log.error_name,
        error_string: log.error_string,
        orderedTimestamp: `${log.errorTime}-${`${index}`.padStart(10, '0')}`,
        reverse_order: length - index,
      };
    });
    setLogs(uniquelyIdentifiedLogs);
  }, [deviceFmerrors]);

  const [loading, setLoading] = useState(true);

  // ensure that the refresh handler calls the specific device in question
  const getDeviceErrors = useCallback(async () => {
    if (!device.archived) {
      setLoading(true);
      await fetchDeviceErrors(device);
      setLoading(false);
    }
  }, [device.id, device.archived]);

  // when device is available (or changes), fetch the errors
  useEffect(() => {
    getDeviceErrors();
  }, [getDeviceErrors]);

  const renderHeader = useCallback(props => {
    return (
      <Toolbar
        searchable
        title={t('screens.equipment.device-errors.error-logs')}
        loading={loading}
        tableProps={props}
      />
    );
  }, [loading]);

  const localisedColumns = useLocalisedColumns(columns);

  // return archived status message
  if (device.archived) {
    return (
      <div className="my-4">
        <p>
          {t('screens.equipment.device-errors.archived')}
        </p>
      </div>
    );
  }

  return (
    <div className="my-4">
      <Private minUserType="Admin" className="d-block">
        <Table
          pagination
          data={logs}
          keyField="reverse_order"
          defaultSorted={defaultSorted}
          renderHeader={renderHeader}
          columns={localisedColumns}
          noDataIndication={noDataIndication}
          loading={logs.length > 1 ? false : loading}
          refreshHandler={getDeviceErrors}
        />
      </Private>
    </div>
  );
}

const mapStateToProps = (state, { deviceId }) => ({
  device: getDevice(state, deviceId),
  deviceFmerrors: getDeviceFmerrors(state, deviceId),
});
const mapDispatchToProps = { fetchDeviceErrors };

const ConnectedDeviceErrors = withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(DeviceErrors)
);

export default function DeviceErrorsPage() {
  return (
    <DevicePageLayout>
      <ConnectedDeviceErrors />
    </DevicePageLayout>
  );
}
