
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Col, Row} from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import withNavigationDeviceProps from '../components/withNavigationDeviceProps';

import Title from '../../../components/Title';
import DevicePageLayout from '../components/DevicePageLayout';
import UserLink from '../../user/components/UserLink';
import ActivityList from '../../app/components/ActivityList';
import { FitMachineActivityCard } from '../components/DeviceCard';

import { fetchDeviceErrors } from '../actions';
import {
  getDevice,
  getDeviceFmerrors,
} from '../selectors';
import { getSensorName } from '../utils';

function DeviceActivity({
  deviceId,
  device = {},
  deviceFmerrors = [],
  fetchDeviceErrors,
}) {

  const { t } = useTranslation();
  const sensorName = getSensorName(device);
  // handle logs state (as we need to insert a unique identifier for each row)
  // note: when a unique identifier is available from the API we can drop this component state
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const uniquelyIdentifiedLogs = deviceFmerrors.map((log, index, { length }) => {
      return {
        error_time: log.error_time,
        error_code: log.error_code,
        error_name: log.error_name,
        error_string: log.error_string,
        orderedTimestamp: `${log.errorTime}-${`${index}`.padStart(10, '0')}`,
        reverse_order: length - index,
      };
    });
    setLogs(uniquelyIdentifiedLogs);
  }, [deviceFmerrors]);

  const [loading, setLoading] = useState(true);

  // ensure that the refresh handler calls the specific device in question
  const getDeviceErrors = useCallback(async () => {
    if (!device.archived) {
      setLoading(true);
      await fetchDeviceErrors(device);
      setLoading(false);
    }
  }, [device.id, device.archived]);

  // when device is available (or changes), fetch the errors
  useEffect(() => {
    getDeviceErrors();
  }, [getDeviceErrors]);

  // return archived status message
  if (device.archived) {
    return (
      <Fragment>
        <p>
          {t('screens.equipment.device-activity.archived-equipment')}
        </p>
      </Fragment>
    );
  }

  return (
    <Row>
      <Col xs={12}>
        <ActivityList
          title={<Title title={t('common.activity')} loading={loading} />}
        >
          {logs.map(log => {
            return (
              <FitMachineActivityCard
                key={log.reverse_order}
                deviceId={deviceId}
                timestamp={log.error_time}
              >
                {t('screens.equipment.device-activity.error')} {log.error_code}{log.error_name && ` (${log.error_name})`}: {
                  log.error_string || t('screens.equipment.device-activity.unknown')
                }
              </FitMachineActivityCard>
            );
          })}
          <FitMachineActivityCard deviceId={deviceId} timestamp={device.installation_date}>
            {device.onboarded_by ? (
              <Fragment>
                <Trans
                  i18nKey="screens.equipment.device-activity.sensor-onboarded-by"
                  values={{sensorName: sensorName}}
                  components={{
                    userLink: <UserLink
                      userId={device.onboarded_by}
                      userName={device.onboarded_name}
                    />,
                  }}
                />
              </Fragment>
            ) : (t('screens.equipment.device-activity.sensor-onboarded', {sensorName: sensorName}))}
          </FitMachineActivityCard>
        </ActivityList>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, { deviceId }) => {
  return {
    device: getDevice(state, deviceId),
    deviceFmerrors: getDeviceFmerrors(state, deviceId),
  };
};
const mapDispatchToProps = { fetchDeviceErrors };

const ConnectedDeviceActivity = withNavigationDeviceProps(
  connect(mapStateToProps, mapDispatchToProps)(DeviceActivity)
);

export default function DeviceActivityPage() {
  return (
    <DevicePageLayout>
      <div className="my-4">
        <ConnectedDeviceActivity />
      </div>
    </DevicePageLayout>
  );
}
