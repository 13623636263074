import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import "./statusIndicatorBadges.scss";

export default function StatusSummaryIndicator(props) {
  const { status } = props;
  if(!status) return null;
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip>{status.description}</Tooltip>
      )}
    >
      <StatusSummaryBadge state={status.state} className="indicator-badge">
        <span>{status.caption}</span>
      </StatusSummaryBadge>
    </OverlayTrigger>
  );
}

const StatusSummaryBadge = (props) => {
  const mapStateToVariant = (state) => {
    const map = {
      ok: 'success',
      warning: 'warning',
      critical: 'danger',
      unknown: 'light'
    };
    return map[state] || 'light';
  };
  return <Badge variant={mapStateToVariant(props.state)} {...props}>{props.children}</Badge>;
};