import React, { Suspense, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import './app.scss';

import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingSpinner from './components/LoadingSpinner';
import { persistor } from './store/configureStore';
import { appLoad } from './modules/app/actions';

import AuthProvider from './lib/providers/AuthProvider';
import BugSnagProvider from './lib/providers/BugSnagProvider';

import { Router } from 'react-router-dom';
import Routes from './RoutesWithTracking';
import history from './history';

import { dropCache } from './lib/utils';
import Toaster from './components/Toaster';
import AppStatus from './components/AppStatus';

import { getLocalAnonymousPreference, getUserTags, isUserLoggedIn } from './modules/user/selectors';
import { setLanguageAndLocale } from './components/Internationalisation';

function App(props, { appLoad }) {

  useEffect(() => {
    if (props.userIsLoggedIn) {
      setLanguageAndLocale(props.language);
    }}, [props.language]);

  useEffect(() => {
    if (!props.userIsLoggedIn) {
      setLanguageAndLocale(props.anonymousLanguage);
    }}, [props.anonymousLanguage]);

  const handleLogout = useCallback(() => {
    dropCache();
  }, [dropCache]);

  return (
    <AppStatus>
      <Suspense fallback={<LoadingSpinner />}>
        <PersistGate
          loading={<LoadingSpinner />}
          persistor={persistor}
          // triggered action after app is loaded (and persisted state is rehydrated)
          onBeforeLift={appLoad}
        >
          <BugSnagProvider>
            <AuthProvider onLogout={handleLogout}>
              <Router history={history}>
                <Routes />
              </Router>
              <Toaster />
            </AuthProvider>
          </BugSnagProvider>
        </PersistGate>
      </Suspense>
    </AppStatus>
  );
}

const mapStateToProps = (state, { userId }) => {
  const userTags = getUserTags(state, userId);
  return {
    language: userTags ? userTags['preferences:language'] : null,
    anonymousLanguage: getLocalAnonymousPreference(state, 'language'),
    userIsLoggedIn: isUserLoggedIn(state),
  };
};

const mapDispatchToProps = { appLoad };

export default connect(mapStateToProps, mapDispatchToProps)(App);
