import { useSelector } from 'react-redux';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosWarning } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import LoadingSpinner from '../../../components/LoadingSpinner';
import { getHeaderHeight } from '../../../modules/app/selectors';

function EditEquipmentTitle({
  title,
  loading,
  error,
  lastFetch,
  editButton
}) {

  const { t } = useTranslation();
  const headerHeight = useSelector(getHeaderHeight);
  return (
    <Row
      className="title align-items-top py-2"
      style={headerHeight ? {position: 'sticky', top: headerHeight + 'px', background: '#f8f9fa', zIndex: 99} : {}}
    >
      <Col xs="auto">
        <h2 style={{marginBottom: 0}}>{title || t('common.editing-equipment')}</h2>
        <div className="text-muted" style={{height: '24px', fontSize: '12px'}}>
          {loading ? <LoadingSpinner inline size={2} /> :
            error ? (
              <OverlayTrigger placement="top" overlay={<Tooltip>{error}</Tooltip>}>
                <IoIosWarning size="2em" className="react-icon--inline"/>
              </OverlayTrigger>
            ) : (
              lastFetch ? `${t('common.last-updated')}: ${moment(lastFetch).local().calendar() || ''}` : ""
            )
          }
        </div>
      </Col>
      <Col xs="auto" className="ml-auto">
        {editButton}
      </Col>
    </Row>
  );
}

export default EditEquipmentTitle;
