
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { DownloadCSVLink } from '../DownloadDataLink';

import { convertToTimestamp } from './BaseChart';
import { capitaliseFirstChar } from '../../lib/utils';

import {
  getOrganisationRmsTitle,
  getOrganisationMm2Title,
} from '../../modules/organisation/selectors';

function DownloadChartCSVLink({
  // build data from required sample props
  samples=[],
  startTime,
  endTime,
  // remove redux props from downloadProps
  dispatch,
  // by default hide the RMS data series to avoid name clash
  // with alternate RMS field from MM2
  rmsTitle = '',
  mm2Title = '',
  namespace = '',
  // add given options
  children,
  ...downloadProps
}) {

  // delay calculation of selected rows to only when
  // the samples or date range changes
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(true);

  const computeFitPowerStatesHeadersAndRows = useCallback(() => {
    const headers = [{
      key: 'id', label: 'ID'
    }, {
      key: 'timestamp', label: 'Sample Time'
    }, {
      key: 'name', label: 'State'
    }, {
      key: 'value', label: 'Value'
    }];
    const rows = samples.filter(({ timestamp: sample_time }) => {
      const timestamp = convertToTimestamp(sample_time);
      return timestamp >= startTime && timestamp <= endTime;
    }).map(s => ({
      ...s,
      timestamp: (new Date(s.timestamp)).toString(),
      name: s.state?.name,
      value: s.state?.value,
    }));
    return { headers, rows };
  }, [samples, startTime, endTime]);

  const computeFitPowerMeasuredDataHeadersAndRows = useCallback(() => {
    const headers = [{
      key: 'id', label: 'ID'
    }, {
      key: 'timestamp', label: 'Sample Time',
    }, {
      key: 'measured_current', label: 'Measured Current',
    }, {
      key: 'relative_current', label: 'Relative Current',
    }];
    const rows = samples.filter(({ timestamp: sample_time }) => {
      const timestamp = convertToTimestamp(sample_time);
      return timestamp >= startTime && timestamp <= endTime;
    }).map(s => ({
      ...s,
      timestamp: (new Date(s.timestamp)).toString(),
    }));

    return { headers, rows };
  }, [samples, startTime, endTime]);

  const computeFitPowerEnergyOverviewHeadersAndRows = useCallback(() => {
    const headers = [{
      key: 'id', label: 'ID'
    }, {
      key: 'date', label: 'Date'
    }];
    for(let h = 0; h < 24; h++) {
      headers.push({
        key: h +'h',
        label: h + 'h',
      });
    }
    const rows = samples.filter(({ date: sample_time }) => {
      const timestamp = convertToTimestamp(sample_time);
      return timestamp >= startTime && timestamp <= endTime;
    }).map(s => {
      const energyOverviewState = {};
      s.energy_overview_state.forEach((v, i) => {
        energyOverviewState[i+'h'] = v;
      });
      return {
        ...s,
        ...energyOverviewState,
      };
    });
    return { headers, rows };
  }, [samples, startTime, endTime]);

  const computeHeadersAndRows = useCallback(() => {
    if(namespace.includes('fitpower')) {  // Generate charts for fitpower.
      return namespace.includes('measured-data') ?
        computeFitPowerMeasuredDataHeadersAndRows() :
        namespace.includes('equipment-states') ?
          computeFitPowerStatesHeadersAndRows() :
          namespace.includes('energy-overview') ?
            computeFitPowerEnergyOverviewHeadersAndRows() :
            { headers: [], rows: [] };
    }
    // get sample keys from samples with the most keys available
    const sampleWithMostKeys = samples.find(sample => {
      // return a sample with AI data
      return sample.condition_overall !== undefined;
    }) || samples[0] || {}; // or return the first available sample

    // get key units from sample with the most keys
    const units = {
      ...sampleWithMostKeys._units,
      equipment_power_usage: 'kWh/day',
      equipment_utilisation: '%',
      fitmachine_battery_voltage: 'V',
      fitmachine_signal_strength: 'RSSI',
    };

    // derive CSV headers from the sample with the most keys
    const headers = Object.entries(sampleWithMostKeys)
      // remove added timestamp values from output
      .filter(([key]) => key !== 'timestamp')
      // collect normal data columns
      .reduce((acc, [key, value]) => {
        // add each key that is not an object
        if (typeof value !== 'object') {
          // push the key and units for the key if found
          acc.push([key, units[key]]);
        }
        return acc;
      }, [])
      .sort(([a], [b]) => {
        const preferredOrder = ['sample_time', 'time', 'id'];
        const aIndex = preferredOrder.indexOf(a);
        const bIndex = preferredOrder.indexOf(b);
        return aIndex >= 0 && bIndex >=0
          ? aIndex - bIndex
          : aIndex >= 0
            ? -1
            : bIndex >=0
              ? 1
              : a.localeCompare(b);
      })
      .map(([key, units]) => {

        // define pre-defined labels
        const predefinedLabels = {
          rms: rmsTitle,
          rms2: mm2Title,
        };

        // if a predefined key exists, specifically use the given definition
        if (predefinedLabels.hasOwnProperty(key)) {
          // if label should be defined but is falsy then filter out the column
          if (!predefinedLabels[key]) {
            return null;
          }
        }

        // get predefined label or format label parts from key
        const labelWithoutUnits = predefinedLabels[key] || key
          .split('_') // split into words
          .map(word => word === 'id' ? 'ID' : word) // capitalise id as ID
          .map(capitaliseFirstChar) // use capital case
          .join(' ');

        // append units to label
        const label = `${labelWithoutUnits}${
          // optionally append units
          units ? ` (${units})` : ''
        }`;

        return { key, label };
      })
      // filter out columns that are specifically evaluated to not have a key
      .filter(Boolean);

    const rows = samples
      // filter data to the asked for date range
      .filter(({ sample_time }) => {
        const timestamp = convertToTimestamp(sample_time);
        return timestamp >= startTime && timestamp <= endTime;
      })
      // sort data reverse-chronologically by sample_time
      // start by reversing the array as that *should* make it ordered
      // and therefore reduce the sort function time
      .reverse()
      .sort((a, b) => -a.sample_time.localeCompare(b.sample_time));
    // console.log(headers, rows);
    return { headers, rows };
  }, [samples, rmsTitle, mm2Title, startTime, endTime]);

  useEffect(() => {
    // reset state
    setRows([]);
    setHeaders([]);
    // process data after tick
    const process = samples.length > 0;
    setLoading(process);
    if (process) {
      const timeout = setTimeout(() => {
        const { headers, rows } = computeHeadersAndRows();
        setRows(rows);
        setHeaders(headers);
        setLoading(false);
      });
      return () => clearTimeout(timeout);
    }
  }, [samples, computeHeadersAndRows]);

  return (
    <DownloadCSVLink
      // pass given props
      {...downloadProps}
      // add calculated props
      loading={loading || downloadProps.loading}
      headers={headers}
      rows={rows}
    >
      {children} {
        !loading && rows && rows.length === 0
          ? '(0 samples)'
          : ''
      }
    </DownloadCSVLink>
  );
}

const mapStateToProps = state => {
  return {
    rmsTitle: getOrganisationRmsTitle(state),
    mm2Title: getOrganisationMm2Title(state),
  };
};

export default connect(mapStateToProps)(DownloadChartCSVLink);
