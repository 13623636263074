import React, { Component } from 'react';
import { IoIosAlert } from 'react-icons/io';

import "./statusIndicator.scss";

function getColour(condition_overall) {
  if (condition_overall >= 2/3) {
    return 'red';
  }
  else if (condition_overall >= 1/3) {
    return 'yellow';
  }
  else if (condition_overall >= 0 && condition_overall !== null) {
    return 'green';
  }
  else {
    return 'grey';
  }
}

export function getDeviceConditionColour({ condition_overall }={}) {
  return getColour(condition_overall);
}

export default class StatusIndicator extends Component {

  render() {
    const colour = getColour(this.props.conditionValue);
    return this.props.warning ? (
      <IoIosAlert
        className={[
          `overall-status-indicator`,
          `overall-status-indicator__text`,
          `overall-status-indicator__text--${colour}`
        ].join(' ')}
        stroke="white"
        strokeWidth="15"
      />
    ) : (
      <span className={`overall-status-indicator overall-status-indicator--${colour}`}></span>
    );
  }

}
