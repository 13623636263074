import React, { Component } from 'react';

import './toaster.scss';

// note: when the following issue is closed
// https://github.com/react-bootstrap/react-bootstrap/issues/3464
// this component should be refactored

// only use one toaster at a time
let globalToaster;

export function addToast(props) {
  // add toast if the toaster is available
  if (globalToaster) {
    globalToaster.addToast(props);
  }
  // queue the toast for later if the toaster is unavailable
  else {
    setTimeout(() => addToast(props), 1000);
  }
}

export default class Toaster extends Component {

  constructor(props) {
    super(props);
    // declare the latest toaster as the global toaster
    globalToaster = this;
  }

  toastCount = 0;

  state = {
    loaf: [],
  };

  // see: https://getbootstrap.com/docs/4.2/utilities/colors/
  // for a list of variant colors
  addToast = ({ variant='danger', header, body, timeout=5000, dismissable=true }) => {
    // give the new toast a unique id
    const recentToast = globalToaster.state.loaf[0];  // Get the recent toast.
    if(recentToast && recentToast.header === header) return;  // If the new toast's message(header) is the same as the recent one, do nothing.
    this.toastCount += 1;
    const id = this.toastCount;
    this.setState(({ loaf }) => {
      // add the toast to the front of the list
      return {
        loaf: [
          {
            id,
            show: true,
            variant,
            // some errors may resolve to === null
            header: header || 'An unknown error occurred',
            body: body || '',
            dismissable,
          },
          ...loaf,
        ],
      };
    }, () => {
      // remove toast after a given time period
      if (timeout) {
        this.removeToast({ id }, timeout);
      }
    });
  };

  removeToast = async ({ id }, delayMs = 0) => {
    // wait the desired amount of time
    await new Promise(resolve => setTimeout(resolve, delayMs));
    // dim the toast
    this.setState(({ loaf }) => {
      // if the item is found then dim it
      return {
        loaf: loaf.map(toast => toast.id === id ? { ...toast, show: false } : toast),
      };
    });
    // just before the toast has dimmed, remove it
    // this is a little nicer looking than waiting for the dim to finish
    await new Promise(resolve => setTimeout(resolve, 150));
    this.setState(({ loaf }) => {
      return {
        loaf: loaf.filter(toast => toast.id !== id),
      };
    });
  };

  renderToast = props => {
    const { id, show=true, header, body, variant, dismissable=true } = props;
    const classes = ['toast'];
    if (show) {
      classes.push('show');
    }
    if (variant) {
      classes.push(`toast-${variant}`);
    }
    return (
      <div key={id} className={classes.join(' ')} role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <strong className="mr-auto">{header}</strong>
          {dismissable && (
            <button type="button" className="ml-2 mb-1 close" aria-label="Close" onClick={() => this.removeToast(props)}>
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        {body && <div className="toast-body">
          {body}
        </div>}
      </div>
    );
  };

  render() {
    const { loaf } = this.state;
    return (
      <div className="toaster" aria-live="polite" aria-atomic="true">
        {loaf.map(this.renderToast)}
      </div>
    );
  }
}
