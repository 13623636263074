import { useLocation } from 'react-router';

// Get the activity view from URL query ?view=...
// Its value is 'alarms'(original), 'comments' or 'events'.
function useActivityView() {
  const { query } = useLocation();
  const view = query?.view?.toLowerCase();

  return view === 'alarms' || view === 'comments' ? view : 'events';
}

export default useActivityView;