
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import OrganisationLogo from './components/OrganisationLogo';

import {
  headerFormatter,
  textFormatter,
  absoluteDateFormatter,
  absoluteDateFilter,
  securityFormatter,
  booleanFormatter,
} from '../../components/table/formatters';

import {
  stringSortFunc,
  timeSortFunc,
  stringSortValueWithHumanNumbersFunc,
  booleanSortFunc,
} from '../../components/table/utils';

export const groupName = {
  dataField: 'name',
  text: 'Name',
  headerFormatter,
  formatter: (name, { id }) => id ? name || "N/A" : "N/A",
  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const organisationName = {
  dataField: 'name',
  text: 'Name',
  headerFormatter,
  formatter: (name, { id }) => id ? (
    <Fragment>
      <Link to={`/organisations/${id}`}><OrganisationLogo organisationId={id} /></Link>
      {' '}
      <Link to={`/organisations/${id}`}>{name || "N/A"}</Link>
    </Fragment>
  ) : "N/A",

  filterValue: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
  sortValue: stringSortValueWithHumanNumbersFunc,
};

export const subDomain = {
  dataField: 'sub_domain',
  text: 'Subdomain',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const createdAt = {
  dataField: 'created_at',
  text: 'Date created',
  headerFormatter,
  formatter: absoluteDateFormatter,
  filterValue: absoluteDateFilter,
  sort: true,
  sortFunc: timeSortFunc,
};

export const parentOrganisation = {
  dataField: 'parent_organisation_id',
  text: 'Parent organisation',
  headerFormatter,
  formatter: (parent_organisation_id, { parent_organisation_name }) => {
    return parent_organisation_name
      ? (
        <Link to={`/organisations/${parent_organisation_id}`} onClick={(e) => e.stopPropagation()}>
          {parent_organisation_name || "N/A"}
        </Link>
      )
      : "N/A";
  },
  filterValue: (parent_organisation_id, { parent_organisation_name }) => {
    return parent_organisation_name || "N/A";
  },
  sort: true,
  sortValue: (parent_organisation_id, { parent_organisation_name }) => {
    return stringSortValueWithHumanNumbersFunc(parent_organisation_name);
  },
  sortFunc: stringSortFunc,
};

export const tokenDescription = {
  dataField: 'description',
  text: 'Description',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const token = {
  dataField: 'token',
  text: 'Token',
  headerFormatter,
  formatter: securityFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const streamingDescription = {
  dataField: 'description',
  text: 'Description',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const enabled = {
  dataField: 'enabled',
  text: 'Enabled',
  headerFormatter,
  formatter: booleanFormatter,
  sort: true,
  sortFunc: booleanSortFunc,
};

export const streamType = {
  dataField: 'stream_type',
  text: 'Stream Type',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const resourceType = {
  dataField: 'resource_type',
  text: 'Resource Type',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const resourceLocation = {
  dataField: 'resource_location',
  text: 'Resource Location',
  headerFormatter,
  formatter: textFormatter,
  sort: true,
  sortFunc: stringSortFunc,
};

export const resourceKey = {
  dataField: 'resource_key',
  text: 'Resource Key',
  headerFormatter,
  formatter: securityFormatter,  // contains AWS credentials
  sort: true,
  sortFunc: stringSortFunc,
};

export const endpoint = {
  dataField: 'endpoint',
  text: 'Endpoint',
  headerFormatter,
  sort: true,
};

export const webhook = {
  dataField: 'webhook',
  text: 'Webhook',
  headerFormatter,
  formatter: value => value.map((v, i) => (<div key={i}>{v.short_name} ({v.group}: {v.description})</div>))
};

export const destination = {
  dataField: 'data_format',
  text: 'Destination',
  headerFormatter,
  sort: true,
};