
import React from 'react';
import { connect } from 'react-redux';

import { getUserPreferenceValue } from '../../modules/user/selectors';
import { getNormalisedPropsSelector } from './utils/selectorUtils';
import { FormattedValue } from './utils/displayUtils';
import { convertMmToIn, convertInToMm } from './utils/converterUtils';

const displayProps = {
  default: {
    units: ' mm/s',
    digits: 2,
  },
  US: {
    units: ' in/s',
    digits: 3,
    convert: convertMmToIn,
    convertBack: convertInToMm,
  },
};

const mapStateToProps = state => ({
  ...displayProps.default,
  ...displayProps[getUserPreferenceValue(state, 'units_system')],
  ...displayProps[getUserPreferenceValue(state, 'units_vibration')],
});

// export selectors
export const getUserDisplayPreference = getNormalisedPropsSelector(mapStateToProps);

const ConnectedValue = connect(mapStateToProps)(FormattedValue);
const ConnectedUnits = connect(mapStateToProps)(({ units }) => units.trim());

// export a display component to render the preferred value with default units displayed
export default props => <ConnectedValue {...props} />;

// display just the units and not the value
export const Units = ConnectedUnits;
