
import React, { Fragment } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const formDescriptionText = "description";
const formEnabledText = "enabled";
const formStreamTypeText = "stream_type";
const formResourceTypeText = "resource_type";
const formResourceLocationText = "resource_location";
const formResourceKeyText = "resource_key";

export function StreamingConfigurationFormGroups({
  handlers,
  description,
  enabled,
  streamType,
  resourceType,
  resourceLocation,
  resourceKey,
}) {

  const { t } = useTranslation();
  const { handleTextChange, handleEnabledChange } = handlers;
  const { descriptionRef, pristineDescription, updatedDescription } = description;
  const { enabledRef, updatedEnabled } = enabled;
  const { streamTypeRef, pristineStreamType, updatedStreamType } = streamType;
  const { resourceTypeRef, pristineResourceType, updatedResourceType } = resourceType;
  const { resourceLocationRef, pristineResourceLocation, updatedResourceLocation } = resourceLocation;
  const { resourceKeyRef, pristineResourceKey, updatedResourceKey } = resourceKey;

  return (
    <Fragment>
      <Form.Group as={Row} controlId={formDescriptionText}>
        <Form.Label column sm={5}>
          {t('components.organisations.streaming-configuration-form-groups.stream-description')}
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            ref={descriptionRef}
            type="text"
            name={formDescriptionText}
            isInvalid={!pristineDescription && !updatedDescription}
            value={updatedDescription}
            onChange={handleTextChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={formEnabledText}>
        <Form.Label column sm={5}>
          {t('components.organisations.streaming-configuration-form-groups.enabled')}
        </Form.Label>
        <Col sm={7}>
          <Form.Check
            ref={enabledRef}
            type="checkbox"
            name={formEnabledText}
            isInvalid={false} // can be either true or false, both are valid
            defaultChecked={!!updatedEnabled}
            onChange={handleEnabledChange}
          >
          </Form.Check>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={formStreamTypeText}>
        <Form.Label column sm={5}>
          {t('components.organisations.streaming-configuration-form-groups.stream-type')}
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            ref={streamTypeRef}
            name={formStreamTypeText}
            isInvalid={!pristineStreamType &&!updatedStreamType}
            value={updatedStreamType}
            onChange={handleTextChange}
          >
            <option>condition_data</option>
            <option>event_data</option>
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={formResourceTypeText}>
        <Form.Label column sm={5}>
          {t('components.organisations.streaming-configuration-form-groups.resource-type')}
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            as="select"
            ref={resourceTypeRef}
            name={formResourceTypeText}
            isInvalid={!pristineResourceType &&!updatedResourceType}
            value={updatedResourceType}
            onChange={handleTextChange}
          >
            <option>sqs</option>
            <option>sns</option>
            <option>kinesis</option>
            <option>http</option>
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={formResourceLocationText}>
        <Form.Label column sm={5}>
          {t('components.organisations.streaming-configuration-form-groups.resource-location')}
        </Form.Label>
        <Col sm={7}>
          <Form.Control as="textarea"
            ref={resourceLocationRef}
            type="text"
            rows={3}
            style={{ minHeight: '2.4em' }}
            name={formResourceLocationText}
            isInvalid={!pristineResourceLocation &&!updatedResourceLocation}
            value={updatedResourceLocation}
            onChange={handleTextChange}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} controlId={formResourceKeyText}>
        <Form.Label column sm={5}>
          {t('components.organisations.streaming-configuration-form-groups.resource-key')}
        </Form.Label>
        <Col sm={7}>
          <Form.Control as="textarea"
            ref={resourceKeyRef}
            type="text"
            rows={3}
            style={{ minHeight: '2.4em' }}
            placeholder="Please ensure Resource Key is valid JSON"
            name={formResourceKeyText}
            isInvalid={!pristineResourceKey &&!updatedResourceKey}
            value={updatedResourceKey}
            onChange={handleTextChange}
          />
        </Col>
      </Form.Group>
    </Fragment>
  );
}
