import React from 'react';
import withNavigationUserProps from '../components/withNavigationUserProps';

import EditUserDevices from '../components/EditUserDevices';
import UserPageLayout from '../components/UserPageLayout';

function EditUserEquipment(props) {
  return (
    <UserPageLayout>
      <EditUserDevices {...props} />
    </UserPageLayout>
  );
}

export default withNavigationUserProps(EditUserEquipment);
