
import React from 'react';
import { Dropdown } from 'react-bootstrap';

const popoverConfigDisplayedOnTopWindow = {
  // allow the popper to appear outside of its scroll parent
  strategy: 'fixed',
};

export function DropdownTopWindowButton({ variant, size, bsPrefix, children, menuAlign, disabled, ...props }) {
  // pass props down as documented in react-bootsrap
  // link: https://react-bootstrap.github.io/components/dropdowns/#dropdown-button-props
  // but adding the popperConfig prop
  return (
    <Dropdown {...props}>
      <Dropdown.Toggle variant={variant} size={size} bsPrefix={bsPrefix} disabled = {disabled} />
      <Dropdown.Menu align={menuAlign} popperConfig={popoverConfigDisplayedOnTopWindow}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  );
}
