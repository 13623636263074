import { usePoll } from "../../../hooks/usePoll";
import useGroup from "./useGroup";
import { useGroupReportData } from './useGroupReportData';
import { getIntervalMilliSeconds } from "../../../components/lib/utils";

const { REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES = 5 } = process.env;
const checkPeriods = getIntervalMilliSeconds(REACT_APP_CHECK_ORGANISATION_INTERVAL_MINUTES);

export function useGroupConditionTrend() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'conditiontrend'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}

export function useGroupStatusSummary() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'statussummary'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}

export function useGroupEventsSummary() {
  const { group } = useGroup();
  const { reportData, fetchData } = useGroupReportData(group, {type: 'eventssummary'});
  usePoll(fetchData, [group.id], {interval: checkPeriods});
  return reportData;
}