import bugsnag, { scrubContext } from '../bugsnag';

/*
 * docs: https://docs.bugsnag.com/platforms/react-native/react-native/reporting-handled-errors/#severity
 *
 * By default, handled errors have a 'warning' severity and unhandled errors have 'error' severity.
 * We change this behaviour here so that app log 'error' maps to bugsnag 'error'.
 * Unhandled errors are still marked as 'Unhandled' in bugsnag,
 * so we aren't losing information by doing this, you can still filter to all unhandled errors.
 *
 */

function whitelistMetadata(report) {
  const metadata = report.metadata;
  // reset metadata object
  report.metadata = scrubContext(report.metadata);
  // allow error context to get passed through untouched
  if (metadata['error-context']) {
    report.metadata['error-context'] = scrubContext(metadata['error-context'], {
      whitelist: false,
    });
  }
}

function formatMetadata(report) {
  // ensure metadata object is available
  report.metadata = report.metadata || {};
  // whitelist filter incoming metadata
  whitelistMetadata(report);
  // prevent namespace clashes
  if (report.metadata.device) {
    report.metadata.fitmachine = report.metadata.device;
    delete report.metadata.device;
  }
  if (report.metadata.err) {
    report.metadata['error-origin'] = report.metadata.err;
    delete report.metadata.err;
  }
}

function formatError(error) {
  return typeof error === 'string' ? new Error(error) : error;
}

/*
 * error: *should* be of type Error, but accept strings because some libraries throw strings...
 * [errorContext]: may be an object of arbitrary information, an error, or a stringifiable message
 * [metadata]: an object of arbitrary information to be displayed in multiple tabs in BugSnag:
 * https://docs.bugsnag.com/platforms/react-native/react-native/#sending-diagnostic-data
 */
function customBugsnagNotification(errorOrString, errorContext, metadata, reportCallback) {
  const error = formatError(errorOrString);
  const report = {};
  // add standard metadata
  report.metadata = {
    ...report.metadata,
    ...metadata,
    // add error into metadata namespace instead of as arbitrary error-context
    ...errorContext instanceof Error // does not work across iframes
      ? { err: errorContext }
      : {
        // add everything else into error-context
        'error-context': typeof errorContext === 'object'
          // attempt to always populate error context.message with something readable
          ? { ...error.context, message: `${error.message || errorOrString}`, ...errorContext }
          : { ...error.context, message: `${errorContext || error.message || errorOrString}` },
      },
  };
  // add grouping hash if given
  // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#groupinghash
  if (errorContext && errorContext.groupingHash) {
    report.groupingHash = errorContext.groupingHash;
  }
  // make specific modifications
  reportCallback(report);
  // normalise the report
  formatMetadata(report);

  const { severity, groupingHash, metadata: metaData } = report;
  bugsnag.notify(error, {
    severity,
    groupingHash,
    // add correct capitalisation expected by the react package
    metaData,
  });
}

export function error(error, errorContext, metadata) {
  if (error) {
    customBugsnagNotification(error, errorContext, metadata, report => {
      report.severity = 'error';
    });
  }
}

export function warning(error, errorContext, metadata) {
  if (error) {
    customBugsnagNotification(error, errorContext, metadata, report => {
      report.severity = 'warning';
    });
  }
}
export const warn = warning;

export function info(error, errorContext, metadata) {
  if (error) {
    customBugsnagNotification(error, errorContext, metadata, report => {
      report.severity = 'info';
    });
  }
}

export default {
  error,
  warn,
  warning,
  info,
};
