import { useTranslation } from 'react-i18next';

import HomeCard from './HomeCard';
import Table from '../Table';
import { useReviewEventsManagementReport } from '../../modules/organisation/hooks/useGroupEventsReport';
import { equipmentNameLink, initialAlarmBadge, acknowledgedBy, latestComments } from './columns';
import useLocalisedColumns from '../../hooks/useLocalisedColumns';

const columns = [
  equipmentNameLink,
  initialAlarmBadge,
  acknowledgedBy,
  latestComments,
];

function PendingReviewEvents() {
  const { t } = useTranslation();
  const reviewEventsManagementReport = useReviewEventsManagementReport();
  const localisedColumns = useLocalisedColumns(columns);

  if(!reviewEventsManagementReport) return null;

  const {_state_: { loading, error } = {}, items = []} = reviewEventsManagementReport || {};
  return (
    <HomeCard
      header={t('home.pending-review-events.header')}
      headerLink={{
        to: '/events?status=new,acknowledged',
        text: t('home.pending-review-events.all-events-pending-review')
      }}
      loading={loading}
      error={error}
    >
      <div className="smaller-font-size-table-container">
        <Table
          data={items}
          keyField="alarm_id"
          loading={loading}
          columns={localisedColumns}
          condensed
        />
      </div>
    </HomeCard>
  );
}

export default PendingReviewEvents;
