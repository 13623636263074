import createReducer from '../../lib/createReducer';
import * as ACTION_TYPES from './types/ActionTypes';
import * as USER_ACTION_TYPES from '../user/types/ActionTypes';
import {
  BillingStoreState, PlanResponse, TransactionResponse, UsageResponse, InvoiceResponse
} from './types';

export const DEFAULT_STATE: BillingStoreState = {
  plan: {
    data: {},
    loading: false,
    lastFetch: null,
    error: null,
  },
  usage: {
    data: {},
    loading: false,
    lastFetch: null,
    error: null,
  },
  invoices: {
    data: [],
    loading: false,
    lastFetch: null,
    error: null,
  },
  transactions: {
    data: [],
    loading: false,
    lastFetch: null,
    error: null,
  },
};

export default createReducer(DEFAULT_STATE, {
  [USER_ACTION_TYPES.LOGOUT]: () => ({ ...DEFAULT_STATE }),
  [USER_ACTION_TYPES.TOKEN_EXPIRED]: () => ({ ...DEFAULT_STATE }),
  [ACTION_TYPES.GET_PLAN_REQUEST]: (state: BillingStoreState): BillingStoreState => {
    return {
      ...state,
      plan: {
        ...state.plan,
        loading: true,
      }
    };
  },
  [ACTION_TYPES.GET_PLAN_SUCCESS]: (state: BillingStoreState, payload: { response: PlanResponse }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      plan: {
        ...state.plan,
        data: response,
        error: null,
        loading: false,
        lastFetch: Date.now(),
      }
    };
  },
  [ACTION_TYPES.GET_PLAN_FAILURE]: (state: BillingStoreState, payload: { response: string }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      plan: {
        ...state.plan,
        loading: false,
        error: response,
      }
    };
  },

  [ACTION_TYPES.GET_USAGE_REQUEST]: (state: BillingStoreState): BillingStoreState => {
    return {
      ...state,
      usage: {
        ...state.usage,
        loading: true,
      }
    };
  },
  [ACTION_TYPES.GET_USAGE_SUCCESS]: (state: BillingStoreState, payload: { response: UsageResponse }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      usage: {
        ...state.usage,
        data: response,
        error: null,
        loading: false,
        lastFetch: Date.now(),
      }
    };
  },
  [ACTION_TYPES.GET_USAGE_FAILURE]: (state: BillingStoreState, payload: { response: string }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      usage: {
        ...state.usage,
        loading: false,
        error: response,
      }
    };
  },

  [ACTION_TYPES.GET_TRANSACTIONS_REQUEST]: (state: BillingStoreState): BillingStoreState => {
    return {
      ...state,
      transactions: {
        ...state.transactions,
        loading: true,
      }
    };
  },
  [ACTION_TYPES.GET_TRANSACTIONS_SUCCESS]: (state: BillingStoreState, payload: { response: TransactionResponse }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      transactions: {
        ...state.transactions,
        data: response._embedded?.transactions || [],
        error: null,
        loading: false,
        lastFetch: Date.now(),
      }
    };
  },
  [ACTION_TYPES.GET_TRANSACTIONS_FAILURE]: (state: BillingStoreState, payload: { response: string }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      transactions: {
        ...state.transactions,
        loading: false,
        error: response,
      }
    };
  },

  [ACTION_TYPES.GET_INVOICES_REQUEST]: (state: BillingStoreState): BillingStoreState => {
    return {
      ...state,
      invoices: {
        ...state.invoices,
        loading: true,
      }
    };
  },
  [ACTION_TYPES.GET_INVOICES_SUCCESS]: (state: BillingStoreState, payload: { response: InvoiceResponse }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      invoices: {
        ...state.invoices,
        data: response._embedded?.invoices || [],
        error: null,
        loading: false,
        lastFetch: Date.now(),
      }
    };
  },
  [ACTION_TYPES.GET_INVOICES_FAILURE]: (state: BillingStoreState, payload: { response: string }): BillingStoreState => {
    const { response } = payload;
    return {
      ...state,
      invoices: {
        ...state.invoices,
        loading: false,
        error: response,
      }
    };
  },
});