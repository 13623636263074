import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDomainData, getDomainState } from '../selectors';
import { isUserLoggedIn } from '../../user/selectors';
import {
  fetchDomainDataAccountStatus,
  fetchDomainDataUserTypes,
  fetchDomainDataNotificationOptions,
  fetchDomainDataDeviceStatus,
  fetchDomainDataDeviceRoles,
  fetchDomainDataConditionCurves,
  fetchDomainDataAlarmInvalidReasons,
  fetchDomainDataAlarmValidReasons,
  fetchDomainDataImpactCostCategories,
  fetchDomainDataGatewayTypes,
  fetchDomainDataIsoTable,
  fetchDomainDataThemes,
} from '../actions';
import { DomainKey } from '../types';

type OptionsType = {
  key?: DomainKey,
  cache?: boolean,
  fetch?: boolean,
};

export default function useDomain(options?: OptionsType) {
  const { key, cache = true, fetch = true } = options || {};
  const dispatch = useDispatch();
  const domainData = useSelector(getDomainData);
  const domainState = useSelector(getDomainState);
  const userIsLoggedIn = useSelector(isUserLoggedIn);

  const fetchAccountStatus = useCallback(() => {
    return dispatch(fetchDomainDataAccountStatus());
  }, [dispatch, fetchDomainDataAccountStatus]);

  const fetchUserTypes = useCallback(() => {
    return dispatch(fetchDomainDataUserTypes());
  }, [dispatch, fetchDomainDataUserTypes]);

  const fetchNotificationOptions = useCallback(() => {
    return dispatch(fetchDomainDataNotificationOptions());
  }, [dispatch, fetchDomainDataNotificationOptions]);

  const fetchDeviceStatus = useCallback(() => {
    return dispatch(fetchDomainDataDeviceStatus());
  }, [dispatch, fetchDomainDataDeviceStatus]);

  const fetchDeviceRoles = useCallback(() => {
    return dispatch(fetchDomainDataDeviceRoles());
  }, [dispatch, fetchDomainDataDeviceRoles]);

  const fetchConditionCurves = useCallback(() => {
    return dispatch(fetchDomainDataConditionCurves());
  }, [dispatch, fetchDomainDataConditionCurves]);

  const fetchAlarmInvalidReasons = useCallback(() => {
    return dispatch(fetchDomainDataAlarmInvalidReasons());
  }, [dispatch, fetchDomainDataAlarmInvalidReasons]);

  const fetchAlarmValidReasons = useCallback(() => {
    return dispatch(fetchDomainDataAlarmValidReasons());
  }, [dispatch, fetchDomainDataAlarmValidReasons]);

  const fetchImpactCostCategories = useCallback(() => {
    return dispatch(fetchDomainDataImpactCostCategories());
  }, [dispatch, fetchDomainDataImpactCostCategories]);

  const fetchGatewayTypes = useCallback(() => {
    return dispatch(fetchDomainDataGatewayTypes());
  }, [dispatch, fetchDomainDataGatewayTypes]);

  const fetchIsoTable = useCallback(() => {
    return dispatch(fetchDomainDataIsoTable());
  }, [dispatch, fetchDomainDataIsoTable]);

  const fetchThemes = useCallback(() => {
    return dispatch(fetchDomainDataThemes());
  }, [dispatch, fetchDomainDataThemes]);

  useEffect(() => {
    if( // If...
      !userIsLoggedIn|| // User not logged in
      !fetch || // Explicitly not want to fetch data on first loading
      (cache && key && domainData[key]) // Cached. In these cases, don't fetch data.
    ) return;
    switch(key) {
      case 'accountStatus': fetchAccountStatus(); break;
      case 'userTypes': fetchUserTypes(); break;
      case 'notificationOptions': fetchNotificationOptions(); break;
      case 'deviceStatus': fetchDeviceStatus(); break;
      case 'deviceRoles': fetchDeviceRoles(); break;
      case 'conditionCurves': fetchConditionCurves(); break;
      case 'alarmInvalidReasons': fetchAlarmInvalidReasons(); break;
      case 'alarmValidReasons': fetchAlarmValidReasons(); break;
      case 'impactCostCategories': fetchImpactCostCategories(); break;
      case 'gatewayTypes': fetchGatewayTypes(); break;
      case 'isotable': fetchIsoTable(); break;
      case 'themes': fetchThemes(); break;
    }
  }, []);

  return {
    domainData: key ? domainData[key] : domainData,
    domainState,
    fetchAccountStatus,
    fetchUserTypes,
    fetchNotificationOptions,
    fetchDeviceStatus,
    fetchDeviceRoles,
    fetchConditionCurves,
    fetchAlarmInvalidReasons,
    fetchAlarmValidReasons,
    fetchImpactCostCategories,
    fetchGatewayTypes,
    fetchIsoTable,
    fetchThemes,
  };
}
