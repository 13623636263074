import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { fetchUsers } from '../actions';

import Table, { itemCount } from '../../../components/Table';
import TableToolbar from '../../../components/TableToolbar';
import {
  userName,
  userEmail,
  userTypeDisplayName,
  department,
  embeddedOrganisations,
  embeddedOrganisationsLastActive,
  createdAt,
} from '../columns';
import UserDropdown from '../components/UserDropdown';

import { getUsers, getUserListState, isAdmin, getUserTypeOptionsState, } from '../selectors';
import {
  getActiveSubGroupId,
} from '../../organisation/selectors';
import { useColumnsWithVisibility } from '../../../components/table/utils';
import { ApiRequestCanceller } from '../../../lib/utils';
import useLocalisedColumns from '../../../hooks/useLocalisedColumns';

const defaultSorted = [{
  dataField: 'name',
  order: 'asc'
}];

const actions = {
  dataField: 'actions',
  text: 'Actions',
  formatter: (value, user) => (
    user && user.id ? (
      <div className="text-right">
        <UserDropdown userId={user && user.id} />
      </div>
    ) : "N/A"
  ),
  events: {
    onClick: (e) => e.stopPropagation()
  }
};

const columns = [
  userName,
  userEmail,
  userTypeDisplayName,
  embeddedOrganisations,
  department,
  embeddedOrganisationsLastActive,
  createdAt,
  actions,
];

function UsersAdmin({
  users = [],
  loading,
  lastFetch,
  error,
  activeSubgroupId,
  isAdmin,
  fetchUsers,
  userTypeOptionsState,
}) {
  const { t } = useTranslation();
  const localisedColumns = useLocalisedColumns(columns);
  const dispatch = useDispatch();
  // fetch users on component mount and on each group change
  useEffect(() => {
    const canceller = new ApiRequestCanceller();
    fetchUsers({}, canceller);
    // a group members list requires information from the top-level list
    // so fetch that as well if needed
    if (activeSubgroupId) {
      fetchUsers({ forOrg: true }, canceller);
    }
    return () => {
      dispatch(canceller.cancel());
    };
  }, [activeSubgroupId]);

  const [filteredUsers, setFilteredUsers] = useState(users);

  const search = useMemo(() => {
    return {
      afterSearch: setFilteredUsers,
    };
  }, [setFilteredUsers]);

  const ItemCount = useCallback(() => {
    return itemCount({
      dataSizeProps: {
        // count filtered (possible duplicate) users by number of unique ids
        filteredCount: [...new Set(filteredUsers.map(({ id }) => id))].length,
        totalCount: users.length,
        itemName: 'user',
        itemsName: 'users',
      },
    });
  }, [users, filteredUsers]); // recompute if any user objects are updated

  const Header = useCallback(props => {
    return (
      <TableToolbar
        searchable
        renderItemCount={ItemCount}
        title={isAdmin ? t('screens.users.users-admin.title-admin') : t('screens.users.users-admin.title-user')}
        loading={loading || userTypeOptionsState.loading}
        lastFetch={lastFetch}
        error={error}
        tableProps={props}
      />
    );
  }, [loading, lastFetch, error, ItemCount, userTypeOptionsState.loading]);

  const usersWithDuplicatesForOrganisations = useMemo(() => {
    return users
      // duplicate each user for each organisation that they are a apart of
      // so that we can show "User Memberships" data instead of just User data
      .reduce((list, user) => {
        const organisations = user._embedded && user._embedded.organisations;
        if (organisations && organisations.length > 1) {
          organisations.forEach(organisation => {
            list.push({
              ...user,
              // add key field to denote user memberships
              keyField: `${user.id}-${organisation.organisation_id}`,
              _embedded: {
                ...user._embedded,
                // show just one organisation per row
                organisations: [organisation],
              },
            });
          });
        }
        else {
          list.push({
            ...user,
            // add key field to denote user memberships
            keyField: user.id,
          });
        }
        return list;
      }, [])
      // add a duplicate embedded organisations_last_active for the last_active column to use
      .map(({ _embedded={}, ...user }={}) => ({
        ...user,
        _embedded: {
          ..._embedded,
          organisations_last_active: _embedded.organisations,
        },
      }));
  }, [users]); // recompute if any user objects are updated
  const history = useHistory();

  return (
    <Container fluid>
      <Table
        // key field is used to denote user memberships
        keyField="keyField"
        pagination
        search={search}
        renderHeader={Header}
        data={usersWithDuplicatesForOrganisations}
        defaultSorted={defaultSorted}
        // only show admin column for admins
        columns={useColumnsWithVisibility(localisedColumns, { actions: isAdmin })}
        noDataIndication={() => 'No users'}
        loading={loading || userTypeOptionsState.loading}
        rowEvents = {{
          onClick: (e, user) => {
            if(window.getSelection().toString().length > 0) return;
            history.push(`/users/${user.id}`);
          }
        }}
        rowStyle={{
          cursor: 'pointer'
        }}
      />
    </Container>
  );
}

const mapStateToProps = state => {
  const { loading, lastFetch, error } = getUserListState(state) || {};
  return {
    loading,
    lastFetch,
    error,
    users: getUsers(state),
    activeSubgroupId: getActiveSubGroupId(state),
    isAdmin: isAdmin(state),
    userTypeOptionsState: getUserTypeOptionsState(state),
  };
};
const mapDispatchToProps = { fetchUsers };

const ConnectedUsersAdmin = connect(mapStateToProps, mapDispatchToProps)(UsersAdmin);
export default ConnectedUsersAdmin;

export function UsersList(props) {

  // redirect admins for correct page history (/users/list vs /users/admin)
  // todo: stop admin vs user URLs (its not great to differentiate admin usage)
  if (useSelector(isAdmin)) {
    return (
      <Redirect to="/users/admin" />
    );
  }

  return (
    <ConnectedUsersAdmin {...props} isAdmin={false} />
  );
}
