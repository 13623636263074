import React from 'react';
import './loadingSpinner.scss';

export default function LoadingSpinner({ inline=false, size=4, delay=false }) {

  // make border width a bit thicker when the spinner gets smaller
  const borderWidth = Math.sqrt(size)/4;
  return (
    <div className={delay ? 'spinner fade-in' : 'spinner'} style={{
      height: `${size}em`,
      width: `${size}em`,
      lineHeight: `${size}em`,
      borderWidth: `${borderWidth}em`,
      display: inline ? 'inline-block' : 'block',
      // add customised animation delay in ms
      ...delay && delay === Number(delay) && {
        animationDelay: `${delay}ms`,
      },
    }}></div>
  );
}
