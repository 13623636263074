
import React from 'react';
import { Redirect } from 'react-router-dom';

// route all uncaught alarms to the condition alarms list
export default function AlarmsRouter() {
  return (
    <Redirect to="/alarms/list/condition" />
  );
};
